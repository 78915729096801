define("innowaze-ember2/routes/users/new", ["exports", "innowaze-ember2/routes/users/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    errorMessage: Ember.computed(function () {
      return this.locale.t("creation_error");
    }),
    model: function model() {
      return this.store.createRecord("user", {
        fields: {},
        field_types: {
          skills: "enum_tags",
          needed_skills: "enum_tags"
        },
        preload_tags: []
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.store.findAll("user-module").then(function (modules) {
        var userModules = {};
        modules.forEach(function (module) {
          userModules[module.name] = module;
        });
        controller.setProperties({
          userModules: userModules,
          userModuleNames: modules.mapBy("name")
        });
      });
    },
    _success: function _success() {
      this.notifications.success("L'utilisateur a été créé");
      this.transitionTo("users");
    }
  });

  _exports.default = _default;
});