define("innowaze-ember2/routes/challenges/edit-main", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _cleanCurrentModel, _setCurrentChallenge, _saveChallenge, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _cleanCurrentModel.default, _saveChallenge.default, _selectAutocomplete.default, {
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        availableKnowledges: this.store.findAll("knowledge"),
        availableDepartments: [],
        blind: model.get("defaultRoleIsBlind")
      });
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      save: function save() {
        var _this = this;

        this.saveCurrentChallenge().then(function (challenge) {
          return _this._success(challenge);
        }, function (errors) {
          return _this._error(errors);
        });
      },
      next: function next() {
        var _this2 = this;

        this.currentModel.set("step", 1);
        this.saveCurrentChallenge().then(function (challenge) {
          return _this2._stepSuccess(challenge);
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      deleteLogo: function deleteLogo() {
        this.currentModel.setProperties({
          logo_id: null,
          logo_url: null
        });
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      },
      setKnowledges: function setKnowledges(knowledges) {
        this.currentModel.set("knowledges", knowledges);
      },
      setDepartment: function setDepartment(department) {
        this.currentModel.set("department", department);
      },
      setPublicDepartments: function setPublicDepartments(departments) {
        this.currentModel.set("public_departments", departments);
      },
      setAuthorizedDepartments: function setAuthorizedDepartments(departments) {
        this.currentModel.set("authorized_departments", departments);
      },
      delete: function _delete() {
        var _this3 = this;

        if (!confirm(this.locale.t("challenges.show.confirm_delete_challenge"))) {
          return;
        }

        this.currentModel.destroyRecord().then(function () {
          // Remove delete challenge from challenge list
          _this3.get("session.availableChallenges").removeObject(_this3.currentModel); // Get first challenge if exists and set it as default one


          var firstChallenge = _this3.get("session.availableChallenges.firstObject");

          if (firstChallenge) {
            _this3.session.set("currentChallenge", firstChallenge);
          } // Redirect to challenge list


          _this3.transitionTo("challenges");

          _this3.notifications.success(_this3.locale.t("challenges.show.challenge_deleted"));
        });
      },
      duplicate: function duplicate() {
        var _this4 = this;

        if (!confirm(this.locale.t("challenges.show.confirm_duplicate"))) {
          return;
        }

        this.set("controller.actionInProgress", true);
        this.currentModel.duplicate().then(function () {
          return _this4._onDuplicateSuccess();
        }, function () {
          return _this4._onError();
        });
      },
      populate_experts: function populate_experts() {
        var _this5 = this;

        this.currentModel.populate_experts().then(function (experts) {
          return _this5._onPopulateExpertsSuccess(experts);
        }, function () {
          return _this5._onError();
        });
      },
      templatize: function templatize() {
        var _this6 = this;

        this.set("controller.actionInProgress", true);
        this.currentModel.templatize().then(function () {
          return _this6._onTemplatizeSuccess();
        }, function () {
          return _this6._onError();
        });
      },
      close: function close() {
        var _this7 = this;

        if (confirm(this.locale.t("challenges.show.confirm_close_challenge"))) {
          this.currentModel.close().then(function () {
            return _this7._onCloseSuccess();
          }, function () {
            return _this7._onError();
          });
        }
      },
      unclose: function unclose() {
        var _this8 = this;

        if (!confirm(this.locale.t("challenges.show.confirm_unclose_challenge"))) {
          return;
        }

        this.currentModel.unclose().then(function () {
          _this8.currentModel.set("closed", false); // Add unclosed challenge to the challenge list


          _this8.get("session.availableChallenges").addObject(_this8.currentModel);

          _this8.notifications.success(_this8.locale.t("challenges.show.unclosed_challenge"));
        });
      },
      setGroup: function setGroup(group) {
        this.currentModel.set("group", group);
        this.currentModel.set("challenge_group_id", group.id);
      },
      updateMilestonePositions: function updateMilestonePositions(milestones) {
        milestones.forEach(function (milestone, index) {
          milestone.set("position", index);
        });
        this.set("milestones", milestones);
      }
    },
    _onDuplicateSuccess: function _onDuplicateSuccess(challenge) {
      this.set("controller.actionInProgress", false);
      this.transitionTo("challenges");
      this.notifications.success(this.locale.t("challenges.show.duplicated_challenge"));
    },
    _onPopulateExpertsSuccess: function _onPopulateExpertsSuccess(experts) {
      this.notifications.success(this.locale.t("challenges.show.populate_experts", {
        count: experts.length
      }));
    },
    _onTemplatizeSuccess: function _onTemplatizeSuccess(challenge) {
      this.set("controller.actionInProgress", false);
      this.notifications.success(this.locale.t("challenges.show.update_model_message"));
    },
    _onCloseSuccess: function _onCloseSuccess(challenge) {
      // Remove delete challenge from challenge list
      this.get("session.availableChallenges").removeObject(this.currentModel); // Get first challenge if exists and set it as default one

      var firstChallenge = this.get("session.availableChallenges.firstObject");

      if (firstChallenge) {
        this.session.set("currentChallenge", firstChallenge);
      } // Redirect to challenge list


      this.transitionTo("challenges");
      this.notifications.success(this.locale.t("challenges.show.closed_challenge"));
    },
    _stepSuccess: function _stepSuccess(challenge) {
      this._success();

      if (this.currentModel.get("has_showcase")) {
        this.transitionTo("challenges.edit_showcase", challenge.id);
      } else {
        this.transitionTo("challenges.edit_home", challenge.id);
      }
    },
    _onError: function _onError(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.locale.t("error"), errors);
    }
  });

  _exports.default = _default;
});