define("innowaze-ember2/controllers/challenges/ideas-positioning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locale: Ember.inject.service(),
    displayColor: Ember.computed.or("session.isModerator", "model.display_color"),
    selectedAxis: Ember.computed("activeTools", function () {
      return this.activeTools.findBy("name", "accessibility") || this.activeTools[1];
    }),
    selectedOrdinate: Ember.computed("activeTools", function () {
      return this.activeTools.findBy("name", "stakes") || this.activeTools[0];
    }),
    activeTools: Ember.computed("model.activeIdeaTools", function () {
      return this.get("model.activeIdeaTools").map(function (tool) {
        if (tool.computed) {
          tool.set("title", tool.title + " ∑");
        }

        return tool;
      });
    }),
    activeToolsForAxis: Ember.computed("selectedOrdinate", "activeTools", function () {
      var _this = this;

      if (this.get("activeTools.length") > 1) {
        return this.activeTools.reject(function (tool) {
          return tool === _this.selectedOrdinate;
        });
      }

      return this.activeTools;
    }),
    activeToolsForOrdinate: Ember.computed("selectedAxis", "activeTools", function () {
      var _this2 = this;

      if (this.get("activeTools.length") > 1) {
        return this.activeTools.reject(function (tool) {
          return tool === _this2.selectedAxis;
        });
      }

      return this.activeTools;
    }),
    hasAtLeastOneAxis: Ember.computed("activeTools", function () {
      return this.activeTools.length > 0;
    }),
    anAxisIsComputed: Ember.computed("selectedAxis", "selectedOrdinate", function () {
      return this.selectedAxis.get("computed") || this.selectedOrdinate.get("computed");
    })
  });

  _exports.default = _default;
});