define("innowaze-ember2/mixins/routes/highlightComments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _tagClicked: function _tagClicked(tag, comments, scope) {
      // tag : clicked tag
      // comments : comments thread
      // scope : depending on where the mixin is called (this.get("controller") or this)
      var re = new RegExp("S*".concat(tag, "S*"), "g");
      var matchingComments = comments.filter(function (comment) {
        return comment.get("content").match(re);
      });
      var matchingParentIds = comments.filter(function (comment) {
        return comment.get("content").match(re) && !comment.get("is_parent");
      }).mapBy("parent_id");
      var commentsExcludingMatchingParents = comments.filter(function (comment) {
        return !matchingParentIds.includes(parseInt(comment.id));
      }); // Hide/show in clicking same tag

      comments.setEach("highlight", false);

      if (scope.get("selectedTag") === tag) {
        scope.set("selectedTag", null);
        comments.setEach("hidden", false);
      } else {
        scope.set("selectedTag", tag);
        commentsExcludingMatchingParents.setEach("hidden", true);
        matchingComments.setEach("highlight", true);
        matchingComments.setEach("hidden", false);
      }
    },
    _resetClicked: function _resetClicked(comments, scope) {
      comments.setEach("highlight", false);
      comments.setEach("hidden", false);
      scope.set("selectedTag", null);
    }
  });

  _exports.default = _default;
});