define("innowaze-ember2/components/reportings-histogram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "svg",
    classNames: "histogram-chart",
    attributeBindings: ["width", "height", "color", "margin"],
    didInsertElement: function didInsertElement() {
      this.svg = d3.select(this.$().get(0));
      this.updateChart();
    },
    updateChart: function updateChart() {
      var data = this.data;

      if (data) {
        var width;

        if (this.width) {
          width = Math.min(this.$().closest(".ideas-histogram-block").width() * 0.9, this.width);
        } else {
          width = this.$().closest(".ideas-histogram-block").width() * 0.98;
        }

        this.svg.attr("width", width);
        var height = this.height ? this.height : data.length * 60;
        var max = data.reduce(function (acc, group) {
          return acc > group.value ? acc : group.value;
        }, 0);
        var data_max = Math.max(max + 2, 2); // maximum of data to use

        var num_ticks = 3; // amount of tickmarks to use
        // margins

        var left_margin = this.margin ? this.margin : width * 0.84;
        var right_margin = 10;
        var top_margin = 30;
        var bottom_margin = 0;
        var x = d3.scale.linear().domain([0, data_max]).range([0, width - (left_margin + right_margin)]);
        var y = d3.scale.ordinal().domain(d3.range(data.length)).rangeBands([bottom_margin, height - top_margin], 0.5);
        var chart_top = height - y.rangeBand() / 2 - top_margin;
        var chart_bottom = bottom_margin * y.rangeBand() / 2;
        var chart_left = left_margin;
        var chart_right = width - right_margin; // Setup the SVG element and position it

        this.svg.selectAll("svg g").remove();
        var vis = this.svg.append("svg:g").attr("id", "barchart").attr("class", "barchart"); // Ticks

        var rules = vis.selectAll("g.rule").data(x.ticks(num_ticks)).enter().append("svg:g").attr("transform", function (d) {
          return "translate(".concat(chart_left + x(d), ")");
        });
        rules.append("svg:line").attr("class", "tick").attr("y1", chart_top).attr("y2", chart_top + 4).attr("stroke", "black");
        rules.append("svg:text").attr("class", "tick_label").attr("text-anchor", "middle").attr("font-size", "10px").attr("y", chart_top).text(function (d) {
          return d;
        });
        var bbox = vis.selectAll(".tick_label").node().getBBox();
        vis.selectAll(".tick_label").attr("transform", function (d) {
          return "translate(0, ".concat(bbox.height, ")");
        });
        var bars = vis.selectAll("g.bar").data(data).enter().append("svg:g").attr("class", "bar").attr("transform", function (d, i) {
          return "translate(".concat(left_margin - right_margin, ", ").concat(y(i), ")");
        });
        bars.append("svg:rect").attr("x", right_margin).attr("width", function (d) {
          return x(d.value);
        }).attr("height", y.rangeBand()).attr("class", "barchart"); // Labels

        var nb_chars = Math.round(width / 6.5);
        var labels = vis.selectAll("g.bar").append("svg:text").attr("class", "label").attr("font-size", "1em").attr("x", 0).attr("y", -2).attr("text-anchor", "right").text(function (d) {
          return d.label.length > nb_chars ? d.label.substring(0, nb_chars).substring(0, d.label.substring(0, nb_chars).lastIndexOf(" ")) : d.label;
        });
        labels = vis.selectAll("g.bar").append("svg:text").attr("class", "label").attr("x", 0).attr("y", 10).attr("text-anchor", "right").text(function (d) {
          return d.label.length > nb_chars ? d.label.substring(d.label.substring(0, nb_chars).lastIndexOf(" "), 2 * nb_chars) : "";
        });
        bbox = labels.node().getBBox();
        vis.selectAll(".label").attr("transform", function (d) {
          return "translate(".concat(right_margin - left_margin, ", ").concat(y.rangeBand() / 2 + bbox.height / 4, ")");
        });
        labels = vis.selectAll("g.bar").append("svg:text").attr("class", "value_text").attr("font-size", "12px").attr("x", function (d) {
          return x(d.value) + right_margin + 3;
        }).attr("y", function (d) {
          return y.rangeBand() / 2 + 4;
        }).attr("text-anchor", "right").text(function (d) {
          return d.value > 0 ? d.value.toString() : "";
        });
        bbox = labels.node().getBBox();
        vis.selectAll(".value").attr("transform", function (d) {
          return "translate(0, ".concat(y.rangeBand() / 2 + bbox.height / 4, ")");
        }); // Axes

        vis.append("svg:line").attr("class", "axes").attr("x1", chart_left).attr("x2", chart_left).attr("y1", chart_bottom).attr("y2", chart_top).attr("stroke", "black");
        vis.append("svg:line").attr("class", "axes").attr("x1", chart_left).attr("x2", chart_right).attr("y1", chart_top).attr("y2", chart_top).attr("stroke", "black");
      }
    },
    draw: Ember.observer("data.[]", function () {
      this.updateChart();
    })
  });

  _exports.default = _default;
});