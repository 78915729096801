define("innowaze-ember2/routes/concepts/blueocean", ["exports", "ramda/src/max", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveAsImage", "innowaze-ember2/mixins/routes/linkedDebateBadge", "innowaze-ember2/objects/reference"], function (_exports, _max, _setCurrentChallenge, _saveAsImage, _linkedDebateBadge, _reference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _saveAsImage.default, _linkedDebateBadge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        concept: this.store.findRecord("concept", params.concept_id, {
          adapterOptions: {
            challenge_id: params.challenge_id
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("blueoceanPublished", model.concept.get("blueocean_published"));
      controller.set("legends", [this.locale.t("non_existent"), this.locale.t("very_low"), this.locale.t("low"), this.locale.t("average"), this.locale.t("high"), this.locale.t("very_high")]);
      this.store.queryRecord("blueocean", {
        challenge_id: model.challenge.id,
        concept_id: model.concept.id
      }).then(function (data) {
        controller.setProperties({
          data: data,
          mainstreamReference: data.get("references").findBy("mainstream", true),
          linkedDebateBadges: data.get("badges")
        });
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        // Generated png data url
        var img = this._dataFromSVG();

        this.get("controller.data").set("png", img["dataUrl"]);
        this.get("controller.data").save({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id,
            concept_id: this.currentModel.concept.id
          }
        }).then(function (data) {
          _this.controller.setProperties({
            data: data,
            mainstreamReference: data.get("references").findBy("mainstream", true),
            linkedDebateBadges: data.get("badges")
          });

          _this.notifications.success("Changements enregistrés");
        });
      },
      linkBadge: function linkBadge() {
        var _this2 = this;

        var debate = this.currentModel.concept.get("debates").findBy("id", this.get("controller.currentBadge.debate_id"));
        var currentBadge = this.get("controller.currentBadge");
        currentBadge.setProperties({
          name: debate.get("question"),
          explanation: debate.get("explanation"),
          badgeable_type: "Concept",
          badgeable_id: this.currentModel.concept.id
        });
        currentBadge.save().then(function (data) {
          currentBadge.set("id", data.id);

          _this2.set("controller.currentBadge", null);

          _this2.send("toggleBadgeModal");
        });
      },
      addFactor: function addFactor() {
        // prevent to save factor without a name
        if (Ember.isBlank(this.get("controller.newFactor"))) {
          return;
        } // Create new factor


        var newFactor = Ember.Object.create({
          id: this.get("controller.data.factors.length") + 1,
          name: this.get("controller.newFactor"),
          position: this.get("controller.data.factors.length") + 1
        }); // Add new factor to X axis

        this.get("controller.data.factors").addObject(newFactor); // Create reference's points for reference

        var newPoints = this.get("controller.data.references").map(function (reference) {
          return Ember.Object.create({
            factor_id: newFactor.get("id"),
            reference_id: reference.get("id"),
            value: 0
          });
        });
        this.get("controller.data.curves").addObjects(newPoints); // Reset input

        this.set("controller.newFactor", null);
      },
      removeFactor: function removeFactor(factor) {
        if (confirm("Êtes-vous sûr de vouloir supprimer le facteur ?")) {
          this.get("controller.data").set("curves", this.get("controller.data.curves").rejectBy("factor_id", factor.get("id")));

          this._reorderFactors(this.get("controller.data.factors").without(factor));
        }
      },
      reorderFactors: function reorderFactors() {
        this._reorderFactors(this.get("controller.data.sortedFactors"));
      },
      sortFactorsByValueDifferences: function sortFactorsByValueDifferences() {
        var controller = this.controller;
        var versionPoints = controller.get("data.curves").filterBy("reference_id", controller.get("data.references").findBy("kind", "version").get("id"));
        var mainstreamReference = controller.get("data.references").findBy("mainstream", true);
        var mainstreamPoints = mainstreamReference ? controller.get("data.curves").filterBy("reference_id", mainstreamReference.get("id")) : false;

        var getDiff = function getDiff(factor) {
          return versionPoints.findBy("factor_id", factor.get("id")).get("value") - (mainstreamPoints ? mainstreamPoints.findBy("factor_id", factor.get("id")).get("value") : 0);
        };

        this._reorderFactors(controller.get("data.factors").slice().sort(function (a, b) {
          return getDiff(a) > getDiff(b) ? 1 : -1;
        }));
      },
      toggleReferenceModal: function toggleReferenceModal() {
        // Reset object / form
        this.controller.set("newReference", _reference.default.create({
          color: "#777777"
        }));
        this.controller.toggleProperty("referenceModalVisible");
      },
      editReference: function editReference(reference) {
        this.send("toggleReferenceModal");
        this.controller.set("newReference", reference);
      },
      addReference: function addReference() {
        // Prevent to save reference without name or color
        var newReference = this.get("controller.newReference");

        if (!newReference || Ember.isBlank(newReference.get("name")) || Ember.isBlank(newReference.get("color"))) {
          return;
        }

        if (!newReference.id) {
          newReference.setProperties({
            id: this.get("controller.data.references.length") ? this.get("controller.data.references").mapBy("id").reduce(_max.default) + 1 : 1,
            kind: newReference.get("kind") || "reference",
            active: true
          }); // Add new reference to references

          this.get("controller.data.references").addObject(newReference); // Create default points for new reference curve

          this.get("controller.data.curves").addObjects(this.get("controller.data.factors").map(function (factor) {
            return Ember.Object.create({
              factor_id: factor.get("id"),
              reference_id: newReference.get("id"),
              value: 0
            });
          }));
        } // hide form


        this.controller.toggleProperty("referenceModalVisible");
      },
      removeReference: function removeReference(reference) {
        if (confirm("Êtes-vous sûr de vouloir supprimer la référence ?")) {
          return this.get("controller.data").setProperties({
            references: this.get("controller.data.references").without(reference),
            curves: this.get("controller.data.curves").rejectBy("reference_id", reference.get("id"))
          });
        }
      }
    },
    _reorderFactors: function _reorderFactors(factors) {
      factors.forEach(function (factor, index) {
        factor.set("position", index + 1);
      });
      this.controller.set("data.factors", factors);
    }
  });

  _exports.default = _default;
});