define("innowaze-ember2/utils/prepareLabel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareLabel;

  function prepareLabel(data, label) {
    if (label.get("formula.items")) {
      label.set("formula.items", label.get("formula.items").map(function (item) {
        if (item.label) {
          item.label = Ember.get(data, "labels").findBy("object_id", item.label.object_id);
        }

        return Ember.Object.create(item);
      }));
    } else {
      label.set("formula.items", []);
    }

    if (label.get("formula.markets")) {
      label.set("formula.markets", label.get("formula.markets").map(function (market) {
        if (market.label) {
          market.label = Ember.get(data, "labels").findBy("object_id", market.label.object_id);
        }

        if (!market.elements) {
          market.elements = [];
        }

        market.elements = market.elements.map(function (element) {
          var flattenFormulaItems = [].concat.apply([], Ember.get(data, "formulas").mapBy("items"));
          element.item = flattenFormulaItems.findBy("id", element.item.id);
          return Ember.Object.create(element);
        });
        return Ember.Object.create(market);
      }));
    } else {
      label.set("formula.markets", []);
    }

    if (label.get("formula.selectedFormula")) {
      label.set("formula.selectedFormula", Ember.get(data, "formulas").findBy("id", label.get("formula.selectedFormula.id")));
    }

    if (label.get("formula.selectedValueProposal")) {
      label.set("formula.selectedValueProposal", Ember.get(data, "labels").findBy("id", label.get("formula.selectedValueProposal.id")));
    }

    return label;
  }
});