define("innowaze-ember2/components/modules/department-module", ["exports", "innowaze-ember2/configuration", "innowaze-ember2/components/modules/module"], function (_exports, _configuration, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    locale: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get("model.id")) {
        // The idea already exists : There is already a new department defined ...  although perhaps not filled
        this.set("model.new_department", Ember.Object.create(this.get("model.new_department")));
      } else if (this.get("model.author_departments") && this.get("model.author_departments").length > 0) {
        // The author has a department
        this.set("model.new_department", this.get("model.author_departments").get("firstObject"));
      } else if (this.get("session.currentUser.departments").length > 0) {
        // The current user has a department
        var mainDepartment = this.get("session.currentUser.departments").get("firstObject");
        this.set("model.new_department", Ember.Object.create(mainDepartment));
      } else {
        // The current user does not have a department yet
        this.set("model.new_department", Ember.Object.create(this.get("model.new_department")));
        this.get("model.new_department").setProperties({
          department_type: 0,
          sector: 0
        });
      }

      this.store.queryRecord("departmentModule", {
        name: "types"
      }).then(function (module) {
        // be sure that the component isn't destroyed before call set method
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        var available_types = module.get("params.list").sortBy("position").filterBy("visible", true);
        available_types.forEach(function (type) {
          type.title = _this.locale.t("departments.".concat(module.get("name"), ".").concat(type.name));
        });

        _this.set("availableTypes", available_types);
      });
    },
    completedDidChange: Ember.on("init", Ember.observer("model.new_department.name", "model.new_department.department_type", "model.new_department.lat", function () {
      var isNotCompleted = !this.get("model.new_department.name") || this.get("model.new_department.department_type") === 0 || !this.get("model.new_department.lat");
      this.set("module.isNotCompleted", isNotCompleted);
    })),
    actions: {
      filesUploaded: function filesUploaded(files) {
        this.get("model.new_department").setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      }
    },
    departmentTypeName: Ember.computed("availableTypes", "model.new_department.department_type", function () {
      if (this.availableTypes) {
        var type = this.availableTypes.findBy("id", this.get("model.new_department.department_type"));
        return type ? type.title : null;
      }
    }),
    addressIsDefined: Ember.computed.and("model.new_department.lat", "model.new_department.lng"),
    markers: Ember.computed("model.new_department.lat", "model.new_department.lng", function () {
      if (this.get("model.new_department.lat") && this.get("model.new_department.lng")) {
        return [Ember.Object.create({
          latitude: this.get("model.new_department.lat"),
          longitude: this.get("model.new_department.lng"),
          typology: 1
        })];
      }
    }),
    logoBackground: Ember.computed("department.logo_url", function () {
      var url = this.get("department.logo_url");
      return Ember.String.htmlSafe(url ? "background-image: url(".concat(_configuration.default.apiUrl).concat(url, "); border: none;") : "");
    })
  });

  _exports.default = _default;
});