define("innowaze-ember2/templates/concepts/modules/-domains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gL1Z6G7j",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"concept\",\"domains\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"ideas.domains\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[15,\"partials/domain_list\",[]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/modules/-domains.hbs"
    }
  });

  _exports.default = _default;
});