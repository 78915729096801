define("innowaze-ember2/components/content-with-tags", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["content"],
    sentenceParts: Ember.computed("text", function () {
      if (this.text) {
        return this.text.split(/(\S*#(?:\[[^\]]+\]|\S+))/).map(function (part, i, array) {
          // currentArray is the array before a #hashtag (index)
          var currentArray = (0, _toConsumableArray2.default)(array).splice(0, i + 1);
          var indexOfLastClosedSpan = array.indexOf(currentArray.reduce(function (acc, e) {
            return !e.includes("<span") && e.includes("</span") ? e : acc;
          }, null));
          var indexOfLastSpan = array.indexOf(currentArray.reduce(function (acc, e) {
            return e.includes("<span") && !e.includes("</span") ? e : acc;
          }, null));
          var flag = false; // highlight a "not hashtag" part if inside a <span></span>

          if (part.indexOf("#") !== 0 && i > indexOfLastSpan && indexOfLastSpan !== -1 && (indexOfLastClosedSpan >= i || indexOfLastClosedSpan === -1)) {
            part = "<span class=\"highlighted\">" + part;
          } // remove <span> tags of hashtag (if any) and highligth it if inside a <span></span>


          if (part.indexOf("#") === 0 && (part.includes("</span>") || i > indexOfLastSpan && indexOfLastSpan !== -1 && (indexOfLastClosedSpan >= i || indexOfLastClosedSpan === -1))) {
            part.replace(/<\/?span[^>]*>/g, "");
            flag = true;
          }

          return {
            isTag: part.indexOf("#") === 0,
            text: part,
            flag: flag
          };
        });
      }

      return [];
    }),
    actions: {
      tagClicked: function tagClicked(tag) {
        this.sendAction("tagClicked", tag);
      },
      toggleOrigin: function toggleOrigin() {
        this.sendAction("toggleOrigin", this);
      }
    }
  });

  _exports.default = _default;
});