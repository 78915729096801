define("innowaze-ember2/components/modules/purchasing-contacts-module", ["exports", "ramda/src/pathOr", "innowaze-ember2/utils/normalizeTemplate", "innowaze-ember2/components/modules/module"], function (_exports, _pathOr, _normalizeTemplate, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    template: Ember.computed(function () {
      return (0, _pathOr.default)([], ["params", "template"], this.challenge.getActiveIdeaModule("purchasing_contacts"));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get("model.purchasing_contacts")) {
        this.set("model.purchasing_contacts", this.template);
      }
    },
    completedDidChange: Ember.on("init", Ember.observer("model.purchasing_contacts", function () {
      // Compare actual value against template
      // We need to normalize the html strings beforehand: remove blank spaces between tags and replace single quotes with double quotes
      var template = (0, _normalizeTemplate.default)(this.get("module.params.template"));
      var purchasingContacts = this.get("model.purchasing_contacts") ? (0, _normalizeTemplate.default)(this.get("model.purchasing_contacts")) : "";
      this.set("module.isNotCompleted", template === purchasingContacts);
    })),
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.model.set(field, newContent);
      }
    }
  });

  _exports.default = _default;
});