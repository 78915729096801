define("innowaze-ember2/mixins/routes/socialConnect", ["exports", "innowaze-ember2/configuration", "innowaze-ember2/config/environment"], function (_exports, _configuration, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    providersMap: {
      google_oauth2: "google-oauth2",
      facebook: "facebook-oauth2",
      linkedin: "linked-in-oauth2",
      twitter: "twitter",
      saml: "saml",
      wsfed: "wsfed"
    },
    actions: {
      socialAuth: function socialAuth(provider) {
        var _this = this;

        this.torii.open(this.providersMap[provider]).then(function (authData) {
          var data;

          switch (provider) {
            case "twitter":
              return authData;

            case "saml":
              return authData;

            case "wsfed":
              return authData;

            default:
              data = {
                code: authData.authorizationCode,
                redirect_uri: "".concat(window.location.origin, "/torii/redirect.html")
              };
          }

          return _this.store.adapterFor("user").socialSignin(provider, data);
        }, function (error) {
          console.warn(error);
        }).then(function (user) {
          if (!user) {
            throw new Error("error");
          }

          _this._login(user, _this.get("controller.deeplink"));
        }, function (error) {
          return _this.notifications.error("Erreur d'identification");
        }).catch(function (error) {
          return _this.notifications.error(error);
        });
      }
    },
    _login: function _login(user, redirection) {
      var _this2 = this;

      localStorage.setItem("userEmail", user.email);

      if (_environment.default.enableTokenAuth) {
        localStorage.setItem("userToken", user.authentication_token);
      } // FIXME: we have to do an extra request because the API
      // does not give us the full user object when logging in


      this.store.queryRecord("user", {}).then(function (user) {
        _this2.session.set("currentUser", user);

        _this2.session.set("loggedIn", true);

        _this2.locale.setInitialLocale();

        if (redirection) {
          document.location.href = redirection;
        } else if (_configuration.default.subscriptionTargets.includes(_this2.get("router.currentRouteName"))) {
          _this2.replaceWith("challenges");
        } else {
          _this2.refresh();
        }
      }).catch(console.error);
    }
  });

  _exports.default = _default;
});