define("innowaze-ember2/routes/concepts", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        concepts: this.store.query("concept", {
          challenge_id: params.challenge_id
        })
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments); // In the component likes-selector current_user_given_points is the variable used for monitoring the total of points given by a user
      // The same variable is used when voting on ideas or for concepts


      controller.set("current_user_given_points", model.challenge.get("current_user_concepts_given_points"));
    }
  });

  _exports.default = _default;
});