define("innowaze-ember2/routes/guides", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/services/locale"], function (_exports, _objectSpread2, _locale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      return this.store.query("guide", {
        extended: true,
        locale: this.locale.currentLocale,
        role: 0
      });
    },
    handleLocaleChange: Ember.observer("locale.currentLocale", function () {
      if (_locale.default.currentLocale === "fr") {
        this.set("controller.tagsList", this.get("controller.tags_fr"));
      } else {
        this.set("controller.tagsList", this.get("controller.tags_en"));
      }

      this.refresh();
    }),
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      var tags_fr = [Ember.Object.create({
        name: "Savoirs",
        query: "savoir",
        selected: false
      }), Ember.Object.create({
        name: "Défis",
        query: "défi",
        selected: false
      }), Ember.Object.create({
        name: "Actualités",
        query: "actualité article news",
        selected: false
      }), Ember.Object.create({
        name: "Mots clés",
        query: "mot clé",
        selected: false
      }), Ember.Object.create({
        name: "Entités",
        query: "entité société",
        selected: false
      }), Ember.Object.create({
        name: "Calendrier",
        query: "calendrier événement",
        selected: false
      }), Ember.Object.create({
        name: "Univers",
        query: "univers",
        selected: false
      }), Ember.Object.create({
        name: "Pistes d’exploration",
        query: "piste exploration",
        selected: false
      }), Ember.Object.create({
        name: "Idées",
        query: "idée",
        selected: false
      }), Ember.Object.create({
        name: "Concepts",
        query: "concept",
        selected: false
      }), Ember.Object.create({
        name: "Evaluation/Jury",
        query: "évaluation jury",
        selected: false
      }), Ember.Object.create({
        name: "Domaines/Thématiques",
        query: "domaine thématique",
        selected: false
      }), Ember.Object.create({
        name: "Débats/Sondages",
        query: "débat sondage",
        selected: false
      }), Ember.Object.create({
        name: "Votes/gamification",
        query: "like gaming vote ludique jeu gamification",
        selected: false
      }), Ember.Object.create({
        name: "Utilisateur/rôles/persona/badges",
        query: "utilisateur user participant rôle persona badge",
        selected: false
      }), Ember.Object.create({
        name: "Inscription/menus/langues/reporting",
        query: "inscription login menu langue reporting",
        selected: false
      })];
      var tags_en = [Ember.Object.create({
        name: "Knowledge",
        query: "Knowledge",
        selected: false
      }), Ember.Object.create({
        name: "Challenge",
        query: "challenge",
        selected: false
      }), Ember.Object.create({
        name: "News",
        query: "news article",
        selected: false
      }), Ember.Object.create({
        name: "Tags",
        query: "tag keyword",
        selected: false
      }), Ember.Object.create({
        name: "Entities",
        query: "entity company",
        selected: false
      }), Ember.Object.create({
        name: "Calendar",
        query: "calendar event",
        selected: false
      }), Ember.Object.create({
        name: "Universes",
        query: "universe",
        selected: false
      }), Ember.Object.create({
        name: "Pathway",
        query: "pathway exploration",
        selected: false
      }), Ember.Object.create({
        name: "Ideas",
        query: "idea",
        selected: false
      }), Ember.Object.create({
        name: "Concepts",
        query: "concept",
        selected: false
      }), Ember.Object.create({
        name: "Assessment/Jury",
        query: "evaluation assessment jury",
        selected: false
      }), Ember.Object.create({
        name: "Domains/Topics",
        query: "domain topic thematic category",
        selected: false
      }), Ember.Object.create({
        name: "Debates/Polls",
        query: "debate poll",
        selected: false
      }), Ember.Object.create({
        name: "like/gamification",
        query: "like gaming game gamification",
        selected: false
      }), Ember.Object.create({
        name: "Users/roles/persona/badges",
        query: "user participant role persona badge",
        selected: false
      }), Ember.Object.create({
        name: "Signup/login/menus/languages/reporting",
        query: "inscription signup signin login menu language reporting",
        selected: false
      })];

      if (this.locale.currentLocale === "fr") {
        controller.set("tagsList", tags_fr);
      } else {
        controller.set("tagsList", tags_en);
      }

      controller.setProperties({
        model: model,
        matchingGuides: model,
        query: "",
        tags_en: tags_en,
        tags_fr: tags_fr
      });
      controller.reopen({
        role: Ember.computed("session.currentChallenge", "session.currentUser", function () {
          if (_this.get("session.currentUser.admin")) {
            return 40;
          }

          if (_this.get("session.currentChallenge")) {
            if (_this.session.isAdmin) {
              return 30;
            }

            if (_this.get("session.isModerator")) {
              return 20;
            }

            if (_this.get("session.isReviewer")) {
              return 10;
            }
          }

          return 0;
        })
      });
    },
    actions: {
      toggleTag: function toggleTag(tag) {
        this.get("controller.tagsList").rejectBy("name", tag.name).setEach("selected", false);
        tag.toggleProperty("selected");

        if (tag.selected) {
          this.set("controller.query", tag.query);
        } else {
          this.set("controller.query", "");
        }

        this.send("search");
      },
      search: function search() {
        var _this2 = this;

        var query = this.get("controller.query");
        this.store.query("guide", (0, _objectSpread2.default)({}, query && {
          query: query
        }, {
          extended: true,
          role: this.get("controller.role"),
          locale: this.locale.currentLocale
        })).then(function (guides) {
          _this2.set("controller.matchingGuides", guides);
        });
      },
      delete: function _delete(guide) {
        var _this3 = this;

        if (!confirm("Voulez-vous vraiment supprimer cette question ?")) {
          return;
        }

        guide.destroyRecord().then(function () {
          _this3.notifications.success("La question a été supprimée");
        });
      },
      toggle: function toggle(id) {
        var label = $("label[for='".concat(id, "']"));
        label.toggleClass("opened closed");
        label.next(".toggable").toggleClass("hide");
        $("[data-guide-id='".concat(id, "']")).toggleClass("hide");
      }
    }
  });

  _exports.default = _default;
});