define("innowaze-ember2/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    apiUrl: window.apiUrl,
    apiNamespace: "api",
    themes: ["theme-reboot", "theme-bounce", "theme-business", "theme-rci", "theme-idf", "theme-oxygen", "theme-trident", "theme-brut", "theme-neon", "theme-energie", "theme-aprr", "theme-solidarity"],
    publicInstanceTargets: ["pages.show", "newsInstance", "challenges.showcase", "challenges.index", "newsInstance.show", "newsDepartment.show", "universes.show"],
    publicPageSlugs: ["tos"],
    subscriptionTargets: ["signup", "emailConfirm", "passwordRecovery", "login", "passwordEdit"],
    defaultImagesPaths: {
      avatar: "/assets/images/default_avatar.png",
      smallAvatar: "/assets/images/default_avatar_small.png",
      extraSmallAvatar: "/assets/images/default_avatar_extra_small.png",
      idea: "/assets/images/default_idea.jpg",
      concept: "/assets/images/default_idea.jpg",
      department: "/assets/images/default_department.png",
      debate: "/assets/images/default_debate.jpg",
      knowledge: "/assets/images/the_library.jpg"
    }
  };
  _exports.default = _default;
});