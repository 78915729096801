define("innowaze-ember2/routes/departments/common", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _cleanCurrentModel, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var domainNamePattern = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _selectAutocomplete.default, {
    locale: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      var _this = this;

      model.set("department_fields", model.get("department_fields").map(function (item) {
        return Ember.Object.create(item);
      }));

      this._super.apply(this, arguments);

      this.store.findAll("department-module").then(function (active_modules) {
        controller.set("activeModules", active_modules);
        controller.activeModules.forEach(function (module) {
          controller.set("available_".concat(module.get("name")), []);

          if (module.get("params.list")) {
            module.get("params.list").forEach(function (e) {
              e.title = _this.locale.t("departments.".concat(module.get("name"), ".").concat(e.name));
              controller.get("available_".concat(module.get("name"))).pushObject(Ember.Object.create(e));
            });
          }
        });
        controller.set("activeVisibleModules", controller.activeModules.filterBy("visible", true).sortBy("position"));

        if (controller.available_skills) {
          _this.currentModel.set("skills", controller.available_skills.filter(function (skill) {
            return _this.currentModel.get("skill_ids") && model.get("skill_ids").includes(skill.id);
          }));
        }

        if (controller.available_technologies) {
          _this.currentModel.set("technologies", controller.available_technologies.filter(function (technology) {
            return _this.currentModel.get("technology_ids") && model.get("technology_ids").includes(technology.id);
          }));
        }
      });
      controller.set("fieldTypes", [{
        value: "string",
        label: this.locale.t("departments.fieldtypes.string")
      }, {
        value: "text",
        label: this.locale.t("departments.fieldtypes.text")
      }, {
        value: "boolean",
        label: this.locale.t("departments.fieldtypes.boolean")
      }]);
      controller.set("tagsToDisplay", this.currentModel.get("tags").toArray()); // Initialize selector for main_customers : (available_customers & selectedCustomers)

      controller.set("selectedCustomers", []);
      controller.set("available_customers", []);

      if (this.currentModel.get("main_customers")) {
        var mc = this.currentModel.get("main_customers").split(",");
        mc.forEach(function (client) {
          controller.selectedCustomers.pushObject({
            id: client,
            name: client
          });
          controller.available_customers.pushObject({
            id: client,
            name: client
          });
        });
      } // Initialize selector for geographical_presence : (available_scopes & selectedScopes)


      controller.set("selectedScopes", []);
      controller.set("available_scopes", []);

      if (this.currentModel.get("geographical_presence")) {
        var _mc = this.currentModel.get("geographical_presence").split(",");

        _mc.forEach(function (client) {
          controller.selectedScopes.pushObject({
            id: client,
            name: client
          });
          controller.available_scopes.pushObject({
            id: client,
            name: client
          });
        });
      } // Initialize selector for investors : (available_investors & selectedInvestors)


      controller.set("selectedInvestors", []);
      controller.set("available_investors", []);

      if (this.currentModel.get("investors")) {
        var _mc2 = this.currentModel.get("investors").split(",");

        _mc2.forEach(function (client) {
          controller.selectedInvestors.pushObject({
            id: client,
            name: client
          });
          controller.available_investors.pushObject({
            id: client,
            name: client
          });
        });
      } // Initialize selector for funded_companies : (available_funded_companies & selectedFundedCompanies)


      controller.set("selectedFundedCompanies", []);
      controller.set("available_funded_companies", []);

      if (this.currentModel.get("funded_companies")) {
        var _mc3 = this.currentModel.get("funded_companies").split(",");

        _mc3.forEach(function (client) {
          controller.selectedFundedCompanies.pushObject({
            id: client,
            name: client
          });
          controller.available_funded_companies.pushObject({
            id: client,
            name: client
          });
        });
      }

      controller.set("newDepartmentField", Ember.Object.create({
        field: "",
        field_type: controller.fieldTypes[0].value
      }));
      controller.reopen({
        "filteredDepartmentFields": Ember.computed("model.department_fields.[]", "model.department_fields.@each._destroy", function () {
          return model.get("department_fields").filter(function (department_fields) {
            return department_fields._destroy !== 1;
          }).sortBy("position");
        })
      });
    },
    redirect: function redirect(model) {
      if (!this.get("session.currentUser") || !this.get("session.currentUser.admin") && !this.get("session.currentUser.is_department_scout") && !model.get("admins").isAny("id", this.get("session.currentUser.id"))) {
        this.notifications.error("Vous n'êtes pas autorisé à modifier cette entité");
        this.transitionTo("departments");
      }
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo("departments");
      },
      setTechnologies: function setTechnologies(array) {
        this.currentModel.set("technologies", array);
        this.currentModel.set("technology_ids", array.mapBy("id"));
      },
      updateDescription: function updateDescription(newContent) {
        this.currentModel.set("description", newContent);
      },
      newCustomer: function newCustomer(input) {
        this.get("controller.available_customers").pushObject({
          name: input,
          id: input
        });
        this.get("controller.selectedCustomers").pushObject({
          name: input,
          id: input
        });
      },
      newScope: function newScope(input) {
        this.get("controller.available_scopes").pushObject({
          name: input,
          id: input
        });
        this.get("controller.selectedScopes").pushObject({
          name: input,
          id: input
        });
      },
      newInvestor: function newInvestor(input) {
        this.get("controller.available_investors").pushObject({
          name: input,
          id: input
        });
        this.get("controller.selectedInvestors").pushObject({
          name: input,
          id: input
        });
      },
      newFundedCompany: function newFundedCompany(input) {
        this.get("controller.available_funded_companies").pushObject({
          name: input,
          id: input
        });
        this.get("controller.selectedFundedCompanies").pushObject({
          name: input,
          id: input
        });
      },
      newEmailDomain: function newEmailDomain(name) {
        if (domainNamePattern.test(name)) {
          this.currentModel.get("email_domains").pushObject(Ember.Object.create({
            id: this.currentModel.get("email_domains").length,
            name: name
          }));
        } else {
          this.notifications.error(this.locale.t("invalid_domain"));
        }
      },
      setScheduledAt: function setScheduledAt(date) {
        this.currentModel.set("creation_date", date);
      },
      filesUploaded: function filesUploaded(files) {
        var file = files.get("firstObject");
        this.currentModel.set("logo_id", file.id);
        this.currentModel.set("logo_url", file.file_url);
      },
      documentsUploaded: function documentsUploaded(files) {
        this.currentModel.get("documents").pushObjects(Array.from(files).map(function (file) {
          return {
            id: file.id,
            name: file.file_name,
            file_name: file.file_name,
            url: file.file_url
          };
        }));
      },
      removeDocument: function removeDocument(doc) {
        this.currentModel.get("documents").removeObject(doc);
      },
      updateDocument: function updateDocument(id) {
        var _this$currentModel$ge = this.currentModel.get("documents").findBy("id", id),
            name = _this$currentModel$ge.name;

        if (!name) {
          return;
        }

        var adapter = this.store.adapterFor("application");
        adapter.ajax("".concat(adapter.host, "/").concat(adapter.namespace, "/uploads/").concat(id, ".json"), "PUT", {
          data: {
            name: name
          }
        });
      },
      newTag: function newTag(name) {
        var newTag = this.store.createRecord("tag", {
          id: name,
          name: name
        });
        this.get("controller.tagsToDisplay").pushObject(newTag);
        this.currentModel.get("tags").pushObject(newTag);
      },
      addDepartmentField: function addDepartmentField() {
        this.get("controller.newDepartmentField").set("localId", -1 * this.currentModel.get("department_fields").length);
        this.get("controller.newDepartmentField").set("position", this.get("controller.filteredDepartmentFields").length);
        this.currentModel.get("department_fields").pushObject(this.get("controller.newDepartmentField"));
        this.controller.set("newDepartmentField", Ember.Object.create({
          field: "",
          field_type: this.get("controller.fieldTypes")[0].value
        }));
      },
      updateFieldPositions: function updateFieldPositions() {
        this.get("controller.filteredDepartmentFields").forEach(function (field, index) {
          field.set("position", index);
        });
      },
      removeDepartmentField: function removeDepartmentField(field) {
        if (field.id) {
          this.currentModel.get("department_fields").findBy("id", field.id).set("_destroy", 1);
        } else {
          this.currentModel.get("department_fields").removeObject(field);
        }
      }
    }
  });

  _exports.default = _default;
});