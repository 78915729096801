define("innowaze-ember2/routes/news-department/edit", ["exports", "innowaze-ember2/routes/news-instance/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    templateName: "news/edit",
    successMessage: Ember.computed(function () {
      return this.locale.t("news.updated");
    }),
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    model: function model(_ref) {
      var news_id = _ref.news_id;
      return this.store.findRecord("news", news_id);
    }
  });

  _exports.default = _default;
});