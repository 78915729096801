define("innowaze-ember2/templates/challenges/modules/-quiz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0qZQfeM4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n  \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"challenges.quiz.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dashboard-primary-buttons\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"challenges.quiz\",[23,[\"model\",\"id\"]]],[[\"class\"],[\"button-primary\"]],{\"statements\":[[0,\"      \"],[1,[27,\"i18n\",[\"challenges.quiz.completion\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-quiz.hbs"
    }
  });

  _exports.default = _default;
});