define("innowaze-ember2/templates/departments/modules/-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZXs052Qx",
    "block": "{\"symbols\":[\"document\"],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[7,\"span\"],[9],[0,\"Documents\"],[10],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"document-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"documents\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"document-item\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"document-label-name\"],[9],[0,\"Name:\"],[10],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"class\",\"autoresize\",\"value\",\"focus-out\"],[\"document-name\",true,[22,1,[\"name\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateDocument\",[22,1,[\"id\"]]],null]]]],false],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"document-label-filename\"],[9],[0,\"File Name:\"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"document-filename\"],[9],[1,[27,\"truncate\",[[22,1,[\"file_name\"]],50],null],false],[10],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"delete\"],[3,\"action\",[[22,0,[]],\"removeDocument\",[22,1,[]]]],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[1,[27,\"file-upload\",null,[[\"multiple\",\"filesUploaded\"],[true,\"documentsUploaded\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-documents.hbs"
    }
  });

  _exports.default = _default;
});