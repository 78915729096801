define("innowaze-ember2/templates/components/modules/slides-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rk9frMYp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"editMode\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[7,\"span\"],[9],[1,[27,\"i18n\",[\"presentation\"],null],false],[10],[0,\"\\n    \"],[4,\"if\",[[23,[\"module\",\"control\"]]],null,{\"statements\":[[7,\"sup\"],[12,\"class\",[27,\"if\",[[23,[\"module\",\"isNotCompleted\"]],\"red\",\"green\"],null]],[9],[0,\" *\"],[10]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[15,\"partials/explanation\",[]],[0,\"\\n    \"],[1,[27,\"embed-slide\",null,[[\"slideUrl\",\"canEdit\"],[[23,[\"model\",\"slide_url\"]],true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"slide_url\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"embed-slide\",null,[[\"slideUrl\"],[[23,[\"model\",\"slide_url\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/modules/slides-module.hbs"
    }
  });

  _exports.default = _default;
});