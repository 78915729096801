define("innowaze-ember2/routes/challenges/awaiting-members", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      model.getMemberships().then(function (members) {
        controller.set("members", members);
      });

      if (model.get("awaiting_members.length") > 0) {
        controller.set("isLoading", true);
        this.store.query("user", {
          challenge_id: model.id,
          ids: model.get("awaiting_members").mapBy("user_id").sort()
        }).then(function (users) {
          controller.setProperties({
            awaiting_users: users.toArray(),
            isLoading: false
          });
        });
      }
    },
    actions: {
      grant: function grant() {
        var _this = this;

        var selectedUsers = this.get("controller.selectedUsers");
        this.currentModel.updateMembers(selectedUsers.mapBy("id"), true, false).then(function () {
          _this.get("controller.awaiting_users").removeObjects(selectedUsers);

          _this.notifications.success("Membres mis à jour");
        }, function () {
          _this.notifications.error("Impossible de mettre les membres à jour");
        });
      },
      reject: function reject() {
        var _this2 = this;

        var selectedUsers = this.get("controller.selectedUsers");
        this.currentModel.updateMembers(selectedUsers.mapBy("id"), false).then(function () {
          _this2.get("controller.awaiting_users").removeObjects(selectedUsers);

          _this2.notifications.success("Membres mis à jour");
        }, function () {
          _this2.notifications.error("Impossible de mettre les membres à jour");
        });
      }
    }
  });

  _exports.default = _default;
});