define("innowaze-ember2/routes/ideas/show", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/ideaAndConcept", "innowaze-ember2/mixins/routes/resetScroll", "innowaze-ember2/utils/formatEvaluation", "innowaze-ember2/utils/stripTags"], function (_exports, _setCurrentChallenge, _ideaAndConcept, _resetScroll, _formatEvaluation, _stripTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _ideaAndConcept.default, _resetScroll.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        idea: this.store.findRecord("idea", params.idea_id, {
          adapterOptions: {
            challenge_id: params.challenge_id
          },
          reload: true
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.challenge.get("deepl_translation")) {
        var lang = model.challenge.get("deepl_languages").findBy("id", this.get("locale.currentLocale"));

        if (lang && model.challenge.get("translation_coupling")) {
          controller.set("targetLanguage", lang);
          Ember.run.next(this, function () {
            this.send("translate", lang.id);
          });
        } else {
          controller.set("targetLanguage", {
            name: "None",
            id: "-"
          });
        }

        controller.setProperties({
          availableTranslationLanguages: model.challenge.get("deepl_languages"),
          translatedName: null,
          translatedDescription: null,
          translatedExtraModules: null,
          translatedComments: null
        });
      }

      controller.set("relatedIdeas", []);
    },
    redirect: function redirect(model, transition) {
      if (!model.challenge.get("current_profile_completion")) {
        this.transitionTo("users.editForChallenge", model.challenge.id, this.get("session.currentUser.id"), {
          queryParams: {
            from: JSON.stringify([transition.targetName, model.challenge.id, model.idea.id])
          }
        });
      }
    },
    actions: {
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.later("afterRender", function () {
          if (_this.get("controller.panel")) {
            $(document).foundation();
            $("#idea-tabs-" + _this.get("controller.panel")).trigger("click");
          }
        });
      },
      // Search for related ideas
      searchRelatedIdeas: function searchRelatedIdeas() {
        var _this2 = this;

        // striptag text from wysiwyg
        var descriptionWithoutTags = (0, _stripTags.default)(this.get("currentModel.idea.description")); // Don't need to search if main attributes are blank

        if (Ember.isBlank(this.get("currentModel.idea.name")) || Ember.isBlank(descriptionWithoutTags)) {
          return false;
        }

        var query = [this.get("currentModel.idea.name"), descriptionWithoutTags, this.get("currentModel.idea.typology")].join(" ") + this.get("currentModel.idea.tags").mapBy("name").join(" ");
        this.store.adapterFor("idea").rawSearch(this.get("currentModel.idea.challenge_id"), {
          query: query,
          paginate: false,
          extended: false,
          order: null
        }).then(function (json) {
          // Remove all draft ideas
          var valid_ideas = json.filter(function (elt) {
            return elt._source.state !== "draft";
          }); // ... and atoms of a molecule-idea

          if (_this2.get("currentModel.idea.parents")) {
            var parents_ids = _this2.get("currentModel.idea.parents").mapBy("id").map(String);

            valid_ideas = valid_ideas.filter(function (e) {
              return !parents_ids.includes(e._id);
            });
          }

          _this2.controller.set("relatedIdeas", valid_ideas);
        });
      },
      toggleIsMedian: function toggleIsMedian(toolName, isMedian) {
        var _this3 = this;

        this.currentModel.idea.toggleIsMedian(toolName, isMedian).then(function (json) {
          if (json.status) {
            _this3.notifications.success(_this3.locale.t("ideas.is_median_true"));
          } else {
            _this3.notifications.success(_this3.locale.t("ideas.is_median_false"));
          }
        });
      },
      convertToConcept: function convertToConcept() {
        var _this4 = this;

        this.currentModel.idea.convertToConcept().then(function () {
          return _this4._successConvert();
        }, function (errors) {
          return _this4._error(errors);
        });
      },
      createDepartment: function createDepartment() {
        var _this5 = this;

        this.currentModel.idea.createDepartment().then(function (department) {
          return _this5._onDepartmentSuccess(department);
        }, function (errors) {
          return _this5._error(errors);
        });
      },
      updateDepartment: function updateDepartment() {
        var _this6 = this;

        this.currentModel.idea.updateDepartment().then(function (department) {
          return _this6._onDepartmentSuccess(department);
        }, function (errors) {
          return _this6._error(errors);
        });
      },
      deleteEvaluation: function deleteEvaluation(evaluation) {
        var _this7 = this;

        if (!confirm(this.locale.t("ideas.show.remove_evaluation"))) {
          return;
        }

        this.store.adapterFor("idea").destroyEvaluation(evaluation.id).then(function () {
          _this7.get("controller.evaluations").removeObject(evaluation);

          _this7.notifications.success(_this7.locale.t("ideas.show.removed_evaluation"));
        });
      },
      setColor: function setColor(idea) {
        if (this.get("session.isModerator")) {
          var colors = ["red", "orange", "yellow", "green", "blue", "darkblue", "purple", "grey"];
          var i = colors.indexOf(idea.get("color"));

          if (i >= 0 && i <= colors.length) {
            i += 1;
          } else {
            i = 0;
          }

          idea.updateColor(colors[i]);
          idea.set("color", colors[i]);
        }
      },
      previousState: function previousState() {
        var _this8 = this;

        if (confirm(this.locale.t("ideas.show.previous_stage_alert"))) {
          this.currentModel.idea.previousState().then(function (idea) {
            return _this8._successState(idea);
          }, function (errors) {
            return _this8._error(errors);
          });
        }
      },
      nextState: function nextState() {
        var _this9 = this;

        this.currentModel.idea.nextState().then(function (idea) {
          return _this9._successState(idea);
        }, function (errors) {
          return _this9._error(errors);
        });
      },
      changeState: function changeState(event) {
        var _this10 = this;

        this.currentModel.idea.changeState(event).then(function (idea) {
          return _this10._successState(idea);
        }, function (errors) {
          return _this10._error(errors);
        });
      },
      changeEvaluation: function changeEvaluation(toolName, value, id) {
        if (id) {
          this.controller.set("activeAxis", id);
        }

        if (isNaN(toolName)) {
          // Standard axes
          this.currentModel.idea.set(toolName, value !== null ? value : 0);
          this.currentModel.idea.set(toolName + "_is_median", false);
        } else {
          // Custom axes
          var tool = this.currentModel.challenge.get("tools").findBy("name", toolName);
          var extraToolEvaluation = this.currentModel.idea.get("extra_tools").findBy("id", tool.id);

          if (extraToolEvaluation) {
            Ember.setProperties(extraToolEvaluation, {
              id: tool.id,
              value: value !== null ? value : 0,
              is_median: false
            });
          } else {
            this.currentModel.idea.get("extra_tools").pushObject({
              id: tool.id,
              value: value !== null ? value : 0,
              is_median: false
            });
          }
        }

        this.currentModel.idea.send("becomeDirty");
      },
      changeCurrentUserEvaluation: function changeCurrentUserEvaluation(toolName, value) {
        var percent = (0, _formatEvaluation.valueToPercent)(value, this.get("controller.minValue"), this.get("controller.maxValue"));

        if (isNaN(toolName)) {
          // Standard axes
          this.currentModel.idea.set("current_user_".concat(toolName), value !== null ? percent : 0);
          this.currentModel.idea.set("current_user_".concat(toolName, "_evaluated"), value !== null);
        } else {
          // Custom axes
          var tool = this.currentModel.challenge.get("tools").findBy("name", toolName);
          var extraToolEvaluation = this.currentModel.idea.get("current_user_extra_tools").findBy("id", tool.id);

          if (extraToolEvaluation) {
            Ember.setProperties(extraToolEvaluation, {
              id: tool.id,
              value: value !== null ? percent : 0,
              evaluated: value !== null
            });
          } else {
            this.currentModel.idea.get("current_user_extra_tools").pushObject({
              id: tool.id,
              value: value !== null ? percent : 0,
              evaluated: value !== null
            });
          }
        }

        this.currentModel.idea.send("becomeDirty");
      },
      saveSingleCurrentUserEvaluation: function saveSingleCurrentUserEvaluation(toolName, value) {
        var _this11 = this;

        var data = {};

        if (isNaN(toolName)) {
          if (toolName === "stakes") {
            toolName = "stake";
          }

          data[toolName] = {
            id: this.currentModel.idea.id,
            evaluated: value !== null ? true : false,
            value: value !== null ? value : 0
          };
        } else {
          data.extra_tools = {
            "0": {
              id: this.currentModel.challenge.get("tools").findBy("name", toolName).id,
              evaluated: value !== null,
              value: value !== null ? value : 0
            }
          };
        }

        this.currentModel.idea.updateCurrentUserEvaluation(data).then(function () {
          return _this11._success();
        }, function (errors) {
          return _this11._error(errors);
        });
      },
      saveCurrentUserEvaluations: function saveCurrentUserEvaluations() {
        var _this12 = this;

        var data = {};
        ["stakes", "accessibility", "strategy", "maturity"].forEach(function (tool) {
          var toolName = tool === "stakes" ? "stake" : tool;

          if (!_this12.get("controller.activeTools").findBy("name", tool)) {
            return;
          }

          if (_this12.currentModel.idea.get("current_user_".concat(tool)) !== null) {
            data[toolName] = {
              id: _this12.currentModel.idea.id,
              evaluated: _this12.currentModel.idea.get("current_user_".concat(tool, "_evaluated")),
              value: _this12.currentModel.idea.get("current_user_".concat(tool))
            };

            _this12.get("controller.activeTools").findBy("name", tool).set("evaluated", true);
          }
        });
        data.extra_tools = {};
        this.currentModel.idea.get("current_user_extra_tools").forEach(function (tool) {
          data.extra_tools[tool.id] = tool;

          _this12.get("controller.activeTools").findBy("id", tool.id).set("evaluated", true);
        });
        data.evaluation_description = this.currentModel.idea.get("current_user_evaluation_description");
        this.currentModel.idea.updateCurrentUserEvaluation(data).then(function () {
          return _this12._success();
        }, function (errors) {
          return _this12._error(errors);
        });
      },
      commentIdea: function commentIdea() {
        $("#idea-tabs-comments").trigger("click");
        this.set("controller.pushCommentButton", true);
      },
      commentAdminIdea: function commentAdminIdea() {
        $("#idea-tabs-admin").trigger("click");
        this.set("controller.pushAdminCommentButton", true);
      },
      assessIdea: function assessIdea() {
        $("#idea-tabs-evaluation").trigger("click");
      },
      synchronizeIdea: function synchronizeIdea() {
        var _this13 = this;

        this.currentModel.idea.synchronize().then(function (idea) {
          return _this13._successSynchronize(idea);
        }, function (errors) {
          return _this13._error(errors);
        });
      },
      changeChallenge: function changeChallenge() {
        var _this14 = this;

        var targetChallenge = this.get("controller.targetChallenge");

        if (!confirm(this.locale.t("ideas.show.challenge_changed_warning", {
          challenge: targetChallenge.get("name")
        }))) {
          this.set("controller.targetChallenge", null);
          return;
        }

        if (this.get("controller.targetChallenge") && targetChallenge.id !== this.currentModel.challenge.id) {
          this.currentModel.idea.changeChallenge(targetChallenge.id).then(function (idea) {
            return _this14._successChangeChallenge(idea);
          }, function (errors) {
            return _this14._error(errors);
          });
        }
      },
      translate: function translate(lang) {
        var _this15 = this;

        var language = this.currentModel.challenge.get("deepl_languages").findBy("id", lang);
        this.controller.set("targetLanguage", language);
        this.currentModel.idea.translate(lang).then(function (result) {
          if (result && result.status) {
            _this15.controller.setProperties({
              translatedName: result.name,
              translatedDescription: result.description,
              language: result.language,
              translatedExtraModules: result.extra_modules,
              translatedComments: result.comments
            });
          } else {
            _this15.controller.setProperties({
              translatedName: null,
              translatedDescription: null,
              language: null,
              translatedExtraModules: null,
              translatedComments: null
            });
          }
        });
      },
      sendByEmail: function sendByEmail() {
        var _this16 = this;

        this.store.adapterFor("idea").sendNotification(this.get("controller.emailRecipients").split(","), [this.currentModel.idea]).then(function () {
          // success
          _this16.notifications.success(_this16.locale.t("notifications_sent"));
        }, function () {
          // error
          _this16.notifications.error(_this16.locale.t("notifications_not_sent"));
        });
      }
    },
    _successSynchronize: function _successSynchronize(idea) {
      this.currentModel.idea.set("mirror_idea", idea);
      this.currentModel.idea.set("synchronized_at", idea.source_synchronized_at);
      this.notifications.success(this.locale.t("ideas.synchronized_idea"));
    },
    _successConvert: function _successConvert(idea) {
      this.currentModel.idea.set("no_concept", false);

      this._success();
    },
    _successChangeChallenge: function _successChangeChallenge(idea) {
      this.notifications.success(this.locale.t("ideas.show.challenge_changed"));
      this.transitionTo("challenges.show", this.currentModel.challenge.id);
      this.currentModel.idea.unloadRecord();
    },
    _onDepartmentSuccess: function _onDepartmentSuccess(department) {
      this.currentModel.idea.set("new_department", department);
      this.notifications.success(this.locale.t("ideas.show.department_created"));
    },
    _successIsMedian: function _successIsMedian() {
      this.notifications.success(this.locale.t("ideas.toogle_is_median"));
    },
    _successState: function _successState(idea) {
      this.currentModel.idea.setProperties({
        state: idea.state,
        state_name: idea.state_name,
        index_stage: idea.index_stage,
        all_states: idea.all_states,
        to_be_evaluated: idea.to_be_evaluated,
        published_at: idea.published_at,
        previous_state: idea.previous_state
      });
      this.set("controller.possible_state_events", idea.state_events.filterBy("can_fire", true));
    }
  });

  _exports.default = _default;
});