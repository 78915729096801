define("innowaze-ember2/templates/knowledges/-accreditations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3NpgEgf+",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"if\",[[23,[\"accredited_users\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"knowledges.show.accreditations\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"teammate small-block-grid-3 medium-block-grid-6 large-block-grid-9\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"accredited_users\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"picture\"],[9],[0,\"\\n          \"],[1,[27,\"avatar-img\",null,[[\"src\",\"size\"],[[22,1,[\"avatar\"]],\"small\"]]],false],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"idea-author\"],[9],[0,\"\\n            \"],[4,\"link-to\",[\"users.show\",[22,1,[\"id\"]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[1,[22,1,[\"screenname\"]],false]],\"parameters\":[]},null],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/knowledges/-accreditations.hbs"
    }
  });

  _exports.default = _default;
});