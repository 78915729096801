define("innowaze-ember2/router", ["exports", "innowaze-ember2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    didTransition: function didTransition() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(function () {
        $(document).foundation("reflow");

        if ($("#header .top-bar").hasClass("expanded")) {
          $(document).foundation("topbar", "toggle");
        } // an ad blocker could make this object undefined


        if (typeof ga !== "undefined") {
          ga("send", "pageview", {
            page: _this.url,
            title: _this.url
          });
        }
      });
    }
  });
  /* eslint-disable array-callback-return */

  Router.map(function () {
    this.route("login", {
      path: "/login"
    });
    this.route("logout", {
      path: "/logout"
    });
    this.route("signup", {
      path: "/signup"
    });
    this.route("emailConfirm", {
      path: "/email-confirm"
    });
    this.route("passwordRecovery", {
      path: "/password-recovery"
    });
    this.route("passwordEdit", {
      path: "/password-edit/:reset_password_token"
    });
    this.route("challenges", {
      path: "/"
    }, function () {});
    this.route("challenges.all", {
      path: "/challenges/all"
    });
    this.route("challenges.new", {
      path: "/challenges/new"
    });
    this.route("challenges.create", {
      path: "/challenges/create"
    });
    this.route("challenges.new_from_template", {
      path: "/challenges/new_from_template/:template_id"
    });
    this.route("challenges.show", {
      path: "/challenges/:challenge_id"
    });
    this.route("challenges.edit_main", {
      path: "/challenges/:challenge_id/edit/main"
    });
    this.route("challenges.edit_showcase", {
      path: "/challenges/:challenge_id/edit/showcase"
    });
    this.route("challenges.edit_home", {
      path: "/challenges/:challenge_id/edit/home"
    });
    this.route("challenges.edit_gaming", {
      path: "/challenges/:challenge_id/edit/gaming"
    });
    this.route("challenges.edit_users_format", {
      path: "/challenges/:challenge_id/edit/users_format"
    });
    this.route("challenges.edit_ideas_format", {
      path: "/challenges/:challenge_id/edit/ideas_format"
    });
    this.route("challenges.edit_ideas_workflow", {
      path: "/challenges/:challenge_id/edit/ideas_workflow"
    });
    this.route("challenges.edit_concepts_format", {
      path: "/challenges/:challenge_id/edit/concepts_format"
    });
    this.route("challenges.edit_concepts_workflow", {
      path: "/challenges/:challenge_id/edit/concepts_workflow"
    });
    this.route("challenges.dashboard", {
      path: "/challenges/:challenge_id/dashboard"
    });
    this.route("challenges.debates", {
      path: "/challenges/:challenge_id/debates"
    });
    this.route("challenges.awaiting_members", {
      path: "/challenges/:challenge_id/awaiting_members"
    });
    this.route("challenges.roles", {
      path: "/challenges/:challenge_id/roles"
    });
    this.route("challenges.rewards", {
      path: "/challenges/:challenge_id/rewards"
    });
    this.route("challenges.members", {
      path: "/challenges/:challenge_id/members"
    });
    this.route("challenges.experts", {
      path: "/challenges/:challenge_id/experts"
    });
    this.route("challenges.solution_subscriptions", {
      path: "/challenges/:challenge_id/solution_subscriptions"
    });
    this.route("challenges.documents", {
      path: "/challenges/:challenge_id/documents"
    });
    this.route("challenges.invitations", {
      path: "/challenges/:challenge_id/invitations"
    });
    this.route("challenges.ideas_positioning", {
      path: "/challenges/:challenge_id/ideas_positioning"
    });
    this.route("challenges.quiz", {
      path: "/challenges/:challenge_id/quiz"
    });
    this.route("challenges.emailings", {
      path: "/challenges/:challenge_id/emailings"
    });
    this.route("challenges.showcase", {
      path: "/challenges/:challenge_id/showcase"
    });
    this.route("reportings", {
      path: "/challenges/:challenge_id/reportings"
    }); // Pages

    this.route("pages.show", {
      path: "/pages/:slug"
    }); // IDEAS

    this.route("ideas", {
      path: "/challenges/:challenge_id/ideas"
    });
    this.route("ideas.edit", {
      path: "/challenges/:challenge_id/ideas/:idea_id/edit"
    });
    this.route("ideas.new", {
      path: "/challenges/:challenge_id/ideas/new"
    });
    this.route("ideas.show", {
      path: "/challenges/:challenge_id/ideas/:idea_id"
    });
    this.route("ideas.grouped_per_supertag", {
      path: "/challenges/:challenge_id/grouped_per_supertag"
    });
    this.route("ideas.clustering", {
      path: "/challenges/:challenge_id/clustering"
    });
    this.route("ideas.showClustering", {
      path: "/challenges/:challenge_id/ideas/:idea_id/clustering"
    });
    this.route("supertags", {
      path: "/challenges/:challenge_id/supertags"
    }); // CONCEPTS

    this.route("concepts", {
      path: "/challenges/:challenge_id/concepts"
    });
    this.route("concepts.show", {
      path: "/challenges/:challenge_id/concepts/:concept_id"
    });
    this.route("concepts.edit", {
      path: "/challenges/:challenge_id/concepts/:concept_id/edit"
    });
    this.route("concepts.new", {
      path: "/challenges/:challenge_id/concepts/new"
    });
    this.route("concepts.blueocean", {
      path: "/challenges/:challenge_id/concepts/:concept_id/blueocean"
    });
    this.route("concepts.canevas", {
      path: "/challenges/:challenge_id/concepts/:concept_id/canevas/:kind"
    }); // Domains for challenge

    this.route("challengeDomains", {
      path: "/challenges/:challenge_id/domains"
    });
    this.route("challengeDomains.new", {
      path: "/challenges/:challenge_id/domains/new"
    });
    this.route("challengeDomains.edit", {
      path: "/challenges/:challenge_id/domains/:domain_id/edit"
    }); // Debates for challenge

    this.route("challengeDebates.show", {
      path: "/challenges/:challenge_id/debates/:debate_id"
    });
    this.route("challengeDebates.new", {
      path: "/challenges/:challenge_id/debates/new"
    });
    this.route("challengeDebates.edit", {
      path: "/challenges/:challenge_id/debates/:debate_id/edit"
    });
    this.route("debates.show", {
      path: "/challenges/:challenge_id/concepts/:concept_id/debates/:debate_id"
    });
    this.route("debates.new", {
      path: "/challenges/:challenge_id/concepts/:concept_id/debates/new"
    });
    this.route("debates.edit", {
      path: "/challenges/:challenge_id/concepts/:concept_id/debates/:debate_id/edit"
    }); // Users

    this.route("users");
    this.route("users.show", {
      path: "/users/:user_id"
    });
    this.route("users.import", {
      path: "/users/import"
    });
    this.route("users.new", {
      path: "/users/new"
    });
    this.route("users.edit", {
      path: "/users/:user_id/edit"
    });
    this.route("users.editForChallenge", {
      path: "/challenges/:challenge_id/users/:user_id/edit"
    });
    this.route("preferences");
    this.route("user_modules");
    this.route("globalReporting", {
      path: "/global_reportings"
    });
    this.route("users.editPassword", {
      path: "/users/:user_id/edit_password"
    }); // TAGS

    this.route("tags");
    this.route("tags.new", {
      path: "/tags/new"
    });
    this.route("tags.edit", {
      path: "/tags/:tag_id/edit"
    }); // DOMAINS

    this.route("domains");
    this.route("domains.new", {
      path: "/domains/new"
    });
    this.route("domains.edit", {
      path: "/domains/:domain_id/edit"
    }); // NEWS

    this.route("news.new", {
      path: "/challenges/:challenge_id/news/new"
    });
    this.route("news.edit", {
      path: "/challenges/:challenge_id/news/:news_id/edit"
    });
    this.route("news.show", {
      path: "/challenges/:challenge_id/news/:news_id"
    });
    this.route("newsInstance", {
      path: "/news"
    });
    this.route("newsInstance.show", {
      path: "/news/:news_id"
    });
    this.route("newsInstance.new", {
      path: "/news/new"
    });
    this.route("newsInstance.edit", {
      path: "/news/:news_id/edit"
    });
    this.route("newsDepartment.new", {
      path: "/departments/:department_id/news/new"
    });
    this.route("newsDepartment.edit", {
      path: "/departments/:department_id/news/:news_id/edit"
    });
    this.route("newsDepartment.show", {
      path: "/departments/:department_id/news/:news_id"
    });
    this.route("newsDepartment", {
      path: "/departments/news"
    }); // KNOWLEDGES

    this.route("knowledges");
    this.route("knowledges.show", {
      path: "/knowledges/:knowledge_id"
    });
    this.route("knowledges.new", {
      path: "/knowledges/new"
    });
    this.route("knowledges.edit", {
      path: "/knowledges/:knowledge_id/edit"
    });
    this.route("configuration"); // GUIDES

    this.route("guides");
    this.route("guides.new", {
      path: "/guides/new"
    });
    this.route("guides.edit", {
      path: "/guides/:guide_id/edit"
    }); // PERSONAS

    this.route("personas");
    this.route("personas.new", {
      path: "/personas/new"
    });
    this.route("personas.edit", {
      path: "/personas/:persona_id/edit"
    }); // TAG TRIGGERS

    this.route("tagTriggers", {
      path: "/tag_triggers"
    });
    this.route("tagTriggers.new", {
      path: "/tag_triggers/new"
    });
    this.route("tagTriggers.edit", {
      path: "/tag_triggers/:tag_trigger_id/edit"
    }); // SURVEYS

    this.route("surveys", {
      path: "/challenges/:challenge_id/surveys"
    });
    this.route("surveys.new", {
      path: "/challenges/:challenge_id/surveys/new"
    });
    this.route("surveys.edit", {
      path: "/challenges/:challenge_id/surveys/:survey_id/edit"
    }); // Departments

    this.route("departments", {
      path: "/departments"
    });
    this.route("departments.show", {
      path: "/departments/:department_id"
    });
    this.route("departments.new", {
      path: "/departments/new"
    });
    this.route("departments.edit", {
      path: "/departments/:department_id/edit"
    }); // Pathways

    this.route("pathways", {
      path: "/challenges/:challenge_id/pathways"
    });
    this.route("pathways.new", {
      path: "/challenges/:challenge_id/pathways/new"
    });
    this.route("pathways.edit", {
      path: "/challenges/:challenge_id/pathways/:pathway_id/edit"
    });
    this.route("pathways.show", {
      path: "/challenges/:challenge_id/pathways/:pathway_id"
    }); // Pathways Debates

    this.route("pathwayDebates.new", {
      path: "/challenges/:challenge_id/pathways/:pathway_id/debates/new"
    });
    this.route("pathwayDebates.edit", {
      path: "/challenges/:challenge_id/pathways/:pathway_id/debates/:debate_id/edit"
    });
    this.route("pathwayDebates.show", {
      path: "/challenges/:challenge_id/pathways/:pathway_id/debates/:debate_id"
    }); // events

    this.route("events", {
      path: "/events"
    });
    this.route("events.new", {
      path: "/events/new"
    });
    this.route("events.edit", {
      path: "/events/:event_id/edit"
    }); // Email Templates

    this.route("emailTemplates");
    this.route("emailTemplates.new", {
      path: "/email_templates/new"
    });
    this.route("emailTemplates.edit", {
      path: "/email_templates/:email_template_id/edit"
    }); // Challenge Email Templates

    this.route("challengeEmailTemplates", {
      path: "/challenges/:challenge_id/email_templates"
    });
    this.route("challengeEmailTemplates.new", {
      path: "/challenges/:challenge_id/email_templates/new"
    });
    this.route("challengeEmailTemplates.edit", {
      path: "/challenges/:challenge_id/email_templates/:email_template_id/edit"
    }); // Challenge badges

    this.route("challengeBadges", {
      path: "/challenges/:challenge_id/badges"
    });
    this.route("challengeBadges.new", {
      path: "/challenges/:challenge_id/badges/new"
    });
    this.route("challengeBadges.edit", {
      path: "/challenges/:challenge_id/badges/:badge_id/edit"
    }); // Challenge Juries

    this.route("challengeJuries", {
      path: "/challenges/:challenge_id/juries"
    });
    this.route("challengeJuries.new", {
      path: "/challenges/:challenge_id/juries/new"
    });
    this.route("challengeJuries.edit", {
      path: "/challenges/:challenge_id/juries/:jury_id/edit"
    }); // Universes

    this.route("universes");
    this.route("universes.show", {
      path: "/universes/:universe_id"
    });
    this.route("universes.new", {
      path: "/universes/new"
    });
    this.route("universes.edit", {
      path: "/universes/:universe_id/edit"
    });
    this.route("not-found", {
      path: "/*"
    });
  });
  /* eslint-enable array-callback-return */

  var _default = Router;
  _exports.default = _default;
});