define("innowaze-ember2/serializers/user", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    store: Ember.inject.service(),
    attrs: {
      preload_tags: {
        deserialize: "records",
        serialize: false
      },
      badges: {
        deserialize: "records",
        serialize: false
      }
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.users) {
        return this._super.apply(this, arguments);
      }

      var result = this._super(store, primaryModelClass, payload.users, id, requestType);

      result.meta = result.meta || {};
      result.meta.total_results = payload.total_results;
      return result;
    },
    serialize: function serialize(snapshot, options) {
      var user = snapshot.record.getProperties("departments", "challenge_id", "default_universe_id", "digest_frequencies", "optout");
      user.email = snapshot.record.get("fields.email");
      user.extra_modules = [];
      var modules = snapshot.record.get("modules");
      Object.keys(modules).forEach(function (key) {
        if (modules[key].active && !isNaN(modules[key].name)) {
          user.extra_modules.push({
            id: modules[key].id,
            description: modules[key].description
          });
        }
      });

      if (user.departments) {
        var departments_field_datas = [];
        user.departments.forEach(function (department) {
          department = Ember.Object.create(department);

          if (department.get("department_fields")) {
            department.get("department_fields").forEach(function (field) {
              var field_data = {
                user_id: snapshot.record.get("id"),
                department_field_id: field.id,
                value: field.value
              };

              if (field.department_field_data_id) {
                field_data.id = field.department_field_data_id;
              }

              departments_field_datas.push(field_data);
            });
          }
        });
        user.departments = user.departments.map(function (department) {
          return {
            id: department.id
          };
        });
        user.department_field_datas_attributes = departments_field_datas;
      }

      user.user_module_field_datas_attributes = [];
      Object.keys(snapshot.record.get("fields")).forEach(function (field_name) {
        var value = snapshot.record.get("fields")[field_name];
        var plainValue = value && value.id ? value.id : value;
        var fieldData = {
          value: plainValue
        };

        if (snapshot.record.get("field_ids.".concat(field_name))) {
          fieldData.id = snapshot.record.get("field_ids")[field_name];
        } else {
          fieldData.user_module_field_id = options.userModuleFields[field_name];
        }

        user.user_module_field_datas_attributes.push(fieldData);
      });
      return {
        user: user
      };
    }
  });

  _exports.default = _default;
});