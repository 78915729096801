define("innowaze-ember2/models/solution", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    name: _emberData.default.attr("string"),
    level: _emberData.default.attr("string"),
    children: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    parent: _emberData.default.attr("string"),
    followed: _emberData.default.attr("boolean"),
    toggleFollow: function toggleFollow() {
      return this._callAdapter("toggleFollow");
    }
  });

  _exports.default = _default;
});