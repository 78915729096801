define("innowaze-ember2/templates/components/embed-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H5i6umwy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"user-agent\",[\"device.isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"target\",\"_blank\"],[12,\"href\",[21,\"safeSrc\"]],[9],[1,[27,\"font-icon\",[\"download\"],null],false],[0,\" \"],[1,[27,\"truncate\",[[23,[\"caption\"]],20],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"iframe\"],[12,\"src\",[28,[[21,\"safeSrc\"]]]],[12,\"width\",[21,\"pdfWidth\"]],[12,\"height\",[21,\"pdfHeight\"]],[9],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"subtitle\"],[9],[1,[21,\"caption\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/embed-pdf.hbs"
    }
  });

  _exports.default = _default;
});