define("innowaze-ember2/templates/homepage/-last-departments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fkd1lEe8",
    "block": "{\"symbols\":[\"department\"],\"statements\":[[7,\"div\"],[11,\"class\",\"homepage-last-departments\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"homepage-sponsors-title\"],[9],[1,[27,\"i18n\",[\"homepage.last_departments\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"homepage-sponsor-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"displayLastDepartments\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"homepage-last-department\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"currentUser\",\"id\"]]],null,{\"statements\":[[4,\"link-to\",[\"departments.show\",[22,1,[\"id\"]]],null,{\"statements\":[[0,\"              \"],[7,\"img\"],[12,\"src\",[28,[[23,[\"session\",\"apiUrl\"]],[22,1,[\"logo_native_url\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"img\"],[12,\"src\",[28,[[23,[\"session\",\"apiUrl\"]],[22,1,[\"logo_native_url\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"currentUser\",\"id\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"homepage-sponsor-join\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-chevron-circle-right\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"departments\"],null,{\"statements\":[[0,\"          \"],[1,[27,\"i18n\",[\"homepage.all_departments\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/homepage/-last-departments.hbs"
    }
  });

  _exports.default = _default;
});