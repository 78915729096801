define("innowaze-ember2/adapters/knowledge", ["exports", "innowaze-ember2/utils/escapeForES", "innowaze-ember2/adapters/application"], function (_exports, _escapeForES, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery() {
      return this._super.apply(this, arguments) + "/search";
    },
    grantAccess: function grantAccess(id, knowledge, users) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("knowledge"), "/").concat(id, "/grant_access.json"), "POST", {
        data: {
          knowledge: {
            users: users
          }
        }
      });
    },
    revokeAccess: function revokeAccess(id, knowledge, users) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("knowledge"), "/").concat(id, "/revoke_access.json"), "POST", {
        data: {
          knowledge: {
            users: users
          }
        }
      });
    },
    addFile: function addFile(id, knowledge, fileId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("knowledge"), "/").concat(id, "/add_file.json"), "POST", {
        data: {
          file_id: fileId
        }
      });
    },
    rawSearch: function rawSearch(options) {
      if (options.query) {
        options.query = (0, _escapeForES.default)(options.query);
      }

      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("knowledge"), "/raw_search.json"), "GET", {
        data: options
      });
    },
    getAccreditations: function getAccreditations(id, knowledge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("knowledge"), "/").concat(id, "/get_accreditations.json"), "GET");
    },
    translate: function translate(id, knowledge, language) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("knowledge"), "/").concat(id, "/translate.json?language=").concat(language), "GET");
    }
  });

  _exports.default = _default;
});