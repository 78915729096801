define("innowaze-ember2/templates/components/blue-ocean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m+OO4hhf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"canEdit\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"click\"],[\"submit\",[27,\"i18n\",[\"save\"],null],\"button-primary button-small blue-ocean-save\",[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"save\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/blue-ocean.hbs"
    }
  });

  _exports.default = _default;
});