define("innowaze-ember2/templates/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AzSrHyqV",
    "block": "{\"symbols\":[\"event\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"event-list page-module\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"currentUser\",\"admin\"]]],null,{\"statements\":[[4,\"link-to\",[\"events.new\"],[[\"class\"],[\"button-primary add-news button-small right\"]],{\"statements\":[[0,\"        \"],[1,[27,\"i18n\",[\"events.add\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"event\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"date\"],[9],[1,[27,\"formatted-date\",[[22,1,[\"starts_at\"]]],null],false],[10],[0,\"\\n        \"],[7,\"h5\"],[11,\"class\",\"news-title\"],[9],[1,[22,1,[\"title\"]],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[1,[22,1,[\"description\"]],true],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"currentUser\",\"admin\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"edit-module\"],[9],[7,\"a\"],[3,\"action\",[[22,0,[]],\"deleteEvent\",[22,1,[]]]],[9],[1,[27,\"i18n\",[\"events.delete\"],null],false],[10],[0,\"\\n            \"],[4,\"link-to\",[\"events.edit\",[22,1,[\"id\"]]],null,{\"statements\":[[1,[27,\"i18n\",[\"events.update\"],null],false]],\"parameters\":[]},null],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/events.hbs"
    }
  });

  _exports.default = _default;
});