define("innowaze-ember2/controllers/departments/common", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/mixins/controllers/googleMaps"], function (_exports, _toConsumableArray2, _googleMaps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_googleMaps.default, {
    news: [],
    typeIsInternal: Ember.computed.equal("model.department_type", 1),
    typeIsStartup: Ember.computed.equal("model.department_type", 2),
    typeIsInvestor: Ember.computed.equal("model.department_type", 20),
    canUpdateCrm: Ember.computed.or("session.isDepartmentScout", "session.currentUser.admin"),
    mainCustomersChanged: Ember.observer("selectedCustomers.[]", function () {
      this.set("model.displayed_main_customers", this.selectedCustomers.mapBy("id"));
    }),
    scopesChanged: Ember.observer("selectedScopes.[]", function () {
      this.set("model.displayed_geographical_presence", this.selectedScopes.mapBy("id"));
    }),
    investorsChanged: Ember.observer("selectedInvestors.[]", function () {
      this.set("model.displayed_investors", this.selectedInvestors.mapBy("id"));
    }),
    fundedCompaniesChanged: Ember.observer("selectedFundedCompanies.[]", function () {
      this.set("model.displayed_funded_companies", this.selectedFundedCompanies.mapBy("id"));
    }),
    typeChanged: Ember.observer("model.department_type", function () {
      if (!this.typeIsStartup) {
        this.model.set("development_stage", null);
      }

      if (!this.typeIsInvestor) {
        this.model.setProperties({
          investment_type: null,
          investor_type: null
        });
      }
    }),
    visibleNewsCount: Ember.computed("news.[]", function () {
      return this.news.filterBy("published", false).length + 3;
    }),
    sortedNews: Ember.computed("news.[]", function () {
      return [].concat((0, _toConsumableArray2.default)(this.news.filterBy("published", false)), (0, _toConsumableArray2.default)(this.news.filterBy("published", true)));
    })
  });

  _exports.default = _default;
});