define("innowaze-ember2/components/modules/address-module", ["exports", "innowaze-ember2/mixins/controllers/googleMaps", "innowaze-ember2/components/modules/module"], function (_exports, _googleMaps, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend(_googleMaps.default, {
    completedDidChange: Ember.on("init", Ember.observer("model.lat", "model.lng", function () {
      this.set("module.isNotCompleted", !this.get("model.lat") && !this.get("model.lng"));
    }))
  });

  _exports.default = _default;
});