define("innowaze-ember2/components/jury-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stateName: Ember.computed("jury", "challenge", function () {
      var _this = this;

      return this.challenge.state_machine_rules.find(function (rule) {
        return rule.to === _this.jury.state;
      }).to_name;
    })
  });

  _exports.default = _default;
});