define("innowaze-ember2/templates/components/pikaday-i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vp15BDw5",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"pikaday-input\",null,[[\"onSelection\",\"useUTC\",\"value\",\"format\",\"options\"],[[23,[\"onSelection\"]],true,[23,[\"value\"]],[23,[\"format\"]],[27,\"hash\",null,[[\"i18n\"],[[23,[\"i18n\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/pikaday-i18n.hbs"
    }
  });

  _exports.default = _default;
});