define("innowaze-ember2/components/domain-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["domain-card"],
    openModalWindow: function openModalWindow() {
      this.$(".reveal-modal").foundation("reveal", "open");
    },
    closeModalWindow: function closeModalWindow() {
      this.$(document).foundation("reveal", "close");
    },
    actions: {
      openModal: function openModal() {
        this.$(document).foundation("reveal");
        this.openModalWindow();
      },
      closeModal: function closeModal() {
        this.closeModalWindow();
      },
      toggleFollow: function toggleFollow() {
        var _this = this;

        this.domain.toggleFollow().then(function () {
          return _this.domain.toggleProperty("followed");
        });
      }
    }
  });

  _exports.default = _default;
});