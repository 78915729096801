define("innowaze-ember2/components/bubble-chart", ["exports", "innowaze-ember2/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    tagName: "svg",
    classNames: "bubble-chart",
    attributeBindings: ["width", "height"],
    didInsertElement: function didInsertElement() {
      this.addTooltip();
      this.svg().classed("graph-loader", true);

      if (window.previousId !== null && window.previousId !== this.elementId && window.previousChallengeId === this.get("challenge.id")) {
        this.createGradients();
        this.updateIdeas();
        this.createScale();
        this.svg().classed("graph-loader", false);
      }
    },
    draw: Ember.observer("content", "size", "color", function () {
      this.createGradients();
      this.updateIdeas();
      this.createScale();
      this.svg().classed("graph-loader", false);
    }),
    toggleLoader: Ember.observer("loader", function () {
      if (this.loader) {
        this.svg().selectAll("g.idea").remove();
        this.svg().selectAll("rect").remove();
      }

      this.svg().classed("graph-loader", this.loader);
    }),
    svg: function svg() {
      return d3.select("#" + this.elementId);
    },
    data: function data() {
      var sizeVar = this.size ? this.get("size.name") : null;
      var idSize = this.get("size.id");
      return d3.layout.pack().value(function (d) {
        if (sizeVar) {
          var size = d.data[sizeVar] || 0;

          if (!isNaN(sizeVar)) {
            var sizeRecord = d.data["extra_tools"].findBy("id", idSize);
            size = sizeRecord ? sizeRecord.value : 0;
          }

          return size === 0 ? 1 : size;
        }

        return 1;
      }).sort(function (a, b) {
        return d3.ascending(b.value, a.value);
      }).size([this.width, this.height]).nodes({
        name: "Bubble chart",
        children: this.content
      }).rejectBy("depth", 0);
    },
    addTooltip: function addTooltip() {
      return this.tooltip = $("<div>").addClass("tooltip").css("display", "none").insertBefore(this.$());
    },
    addLegend: function addLegend() {
      if (this.svg().selectAll("g.legend")[0].length) {
        return;
      }

      var legend = this.svg().append("svg:g");
      legend.attr("class", "legend").attr("transform", "translate(" + (this.width - 120) + ",10)");
      var iterable = Ember.String.w("lower low middle high higher");

      for (var i = 0; i < iterable.length; i++) {
        var level = iterable[i];
        legend.append("rect").attr("width", 20).attr("height", 10).attr("class", level).attr("transform", "translate(".concat(i * 20, ", 0)"));
      }

      return legend.append("text").attr("transform", "translate(0, 30)").text("Technical maturity");
    },
    updateIdeas: function updateIdeas() {
      this.svg().classed("graph-loader", true);
      window.previousId = this.elementId;
      window.previousChallengeId = this.get("challenge.id");
      this.svg().selectAll("g.idea").remove();
      this.svg().selectAll("rect").remove();
      this.svg().append("rect").attr("width", this.width).attr("height", this.height).style("fill", "transparent").on("click", this.ideaMouseout.bind(this));
      var nodes = this.svg().selectAll("g.idea").data(this.data());
      var g = nodes.enter().append("svg:g").attr("class", "idea").attr("transform", this.ideaTransform);
      g.append("circle").attr("r", this.ideaRadius).style("fill", function (idea) {
        return "url(#gradientRadial-" + idea.id + ")";
      }).classed("molecule", function (d) {
        return !d.get("root");
      }).classed("draft", function (d) {
        return d.get("private");
      }).classed("child", function (d) {
        return d.get("child");
      }).classed("root", function (d) {
        return d.get("molecule");
      });
      g.append("text").style("text-anchor", "middle").attr("class", "bubble_text").classed("draft", function (d) {
        return d.get("private");
      }).text(function (d) {
        if (d.r > 8) {
          return d.id;
        }
      });
      g.on("click", this.ideaMouseover.bind(this));
      return nodes.exit().remove();
    },
    ideaRadius: function ideaRadius(idea) {
      return idea.r;
    },
    ideaTransform: function ideaTransform(idea) {
      return "translate(" + idea.x + "," + idea.y + ")";
    },
    ideaSize: function ideaSize(idea) {
      if (idea.data[window.sizeVar] === 0) {
        return 1;
      }

      return idea.data[window.sizeVar];
    },
    ideaMouseover: function ideaMouseover(idea) {
      var _this = this;

      var content;
      var logo = idea.get("logo_url");
      content = "<header>";
      content += "<i class='fa fa-times right js-close'></i>";

      if (logo) {
        content += "<a href='/#/challenges/" + idea.get("challenge_id") + "/ideas/" + idea.id + "' target='_blank'><img src=" + _configuration.default.apiUrl + logo + " /></a>";
      }

      content += "<a href='/#/challenges/" + idea.get("challenge_id") + "/ideas/" + idea.id + "' target='_blank'><h1>" + idea.get("name") + "<small> - " + idea.id + " - </small></h1></a><h2>" + idea.get("author_name") + "</h2>";
      content += "</header><div style='max-height: 400px;overflow:scroll'>";
      content += idea.get("description");
      content += "</div></br><footer><a href='/#/challenges/" + idea.get("challenge_id") + "/ideas/" + idea.id + "' target='_blank'>" + this.locale.t("open_in_new_window") + "</a><footer>";
      return this.tooltip.html(content).css("display", "block").css("left", idea.x - 180 + "px").css("top", idea.y + 10 + "px").on("click", "i.js-close", function () {
        return _this.tooltip.css("display", "none");
      });
    },
    scaleMouseover: function scaleMouseover(d) {
      return this.scaleTooltip.html("<div>" + d + "</div>").css("display", "block").css("left", "40px").css("top", "60px");
    },
    ideaMouseout: function ideaMouseout(idea) {
      return this.tooltip.css("display", "none");
    },
    createGradients: function createGradients() {
      var _this2 = this;

      var fillColor = function fillColor(idea) {
        var color;

        switch (_this2.color.scale) {
          case 1:
            color = d3.scale.ordinal().domain([1, 2, 3, 4]).range(["orange", "yellow", "green", "blue"]);
            break;

          case 2:
            color = d3.scale.category20c();

            if (_this2.color.name === "first_domain") {
              window.domains = idea.get("all_domains");
              color.domain(idea.get("all_domains"));
            } else {
              window.states = idea.get("all_states");
              color.domain(idea.get("all_states"));
            }

            break;

          default:
            color = d3.scale.linear().domain([0, 25, 50, 75, 100]).range(["red", "orange", "yellow", "#9FF781", "#31B404"]);
        }

        if (!isNaN(_this2.color.name)) {
          var colorRecord = idea.data["extra_tools"].findBy("id", _this2.color.id);
          var colorValue = colorRecord ? colorRecord.value : 0;
          return color(colorValue);
        }

        if (idea.data[_this2.color.name] !== null) {
          return color(idea.data[_this2.color.name]);
        }

        return "#CCCCCC";
      };

      this.svg().selectAll("g.gradientContainer").remove();
      var gradientContainer = this.svg().append("g").attr("class", "gradientContainer");
      var gradientRadial = gradientContainer.selectAll("radialGradient").data(this.data()).enter().append("radialGradient").attr("cx", "50%").attr("cy", "50%").attr("r", "50%").attr("fx", "4%").attr("gradientUnits", "objectBoundingBox").attr("id", function (d) {
        return "gradientRadial-" + d.id;
      });
      gradientRadial.append("stop").attr("offset", "4%").attr("stop-color", function (d) {
        return d3.rgb(fillColor(d)).brighter(1);
      });
      gradientRadial.append("stop").attr("offset", "40%").attr("stop-color", function (d) {
        return fillColor(d);
      });
      return gradientRadial.append("stop").attr("offset", "100%").attr("stop-color", function (d) {
        return d3.rgb(fillColor(d)).darker(1.75);
      });
    },
    createScale: function createScale() {
      var color;
      var gradients;
      var y;

      switch (this.color.scale) {
        case 1:
          color = d3.scale.ordinal().domain([1, 2, 3, 4]).range(["orange", "yellow", "green", "blue"]);
          gradients = color.domain();
          break;

        case 2:
          if (this.color.name === "first_domain") {
            color = d3.scale.category20c();
            color.domain(window.domains);
            gradients = window.domains;
          } else {
            color = d3.scale.category20c();
            color.domain(window.states);
            gradients = window.states;
          }

          break;

        default:
          color = d3.scale.linear().domain([0, 25, 50, 75, 100]).range(["red", "orange", "yellow", "#9FF781", "#31B404"]);
          gradients = color.domain();
      }

      this.svg().selectAll("g.scaleContainer").remove();
      var scale = this.svg().append("g").attr("class", "scaleContainer");
      y = 0;

      if (this.color.name === "typology") {
        var results = [];

        for (var k = 0, len1 = gradients.length; k < len1; k++) {
          var gradient = gradients[k];
          y += 20;
          scale.append("rect").attr("x", 10).attr("y", y).attr("width", 20).attr("height", 20).attr("fill", color(gradient));
          results.push(scale.append("text").attr("x", 40).attr("y", y + 14).attr("fill", "#fff").attr("font-size", "10px").text(this.locale.t("ideas.type" + gradient)));
        }

        return results;
      }

      var results2 = [];

      for (var l = 0, len2 = gradients.length; l < len2; l++) {
        var _gradient = gradients[l];
        y += 20;
        scale.append("rect").attr("x", 10).attr("y", y).attr("width", 20).attr("height", 20).attr("fill", color(_gradient));
        results2.push(scale.append("text").attr("x", 40).attr("y", y + 14).attr("fill", "#fff").attr("font-size", "10px").text(_gradient));
      }

      return results2;
    }
  }); // ---
  // generated by coffee-script 1.9.2


  _exports.default = _default;
});