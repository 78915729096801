define("innowaze-ember2/adapters/canevas", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      if (query.challenge_id && query.concept_id && query.kind) {
        return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(query.challenge_id, "/concepts/").concat(query.concept_id, "/canevas.json?kind=").concat(query.kind), "GET");
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});