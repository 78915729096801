define("innowaze-ember2/components/modules/action-plan-module", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/components/modules/module"], function (_exports, _toConsumableArray2, _selectAutocomplete, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LT = -1;
  var EQ = 0;
  var GT = 1;

  function getPositionFilter(operator) {
    return function () {
      var _this = this;

      return this.get("model.todos").filter(function (todo) {
        var state = _this.get("challenge.uniqIdeasAvailableStates").findBy("id", todo.stage);

        if (state) {
          var position = state.position - 2;

          switch (operator) {
            case LT:
              return _this.get("model.index_stage") < position;

            case EQ:
              return _this.get("model.index_stage") === position;

            case GT:
              return _this.get("model.index_stage") > position;
          }
        }

        return null;
      });
    };
  }

  var _default = _module.default.extend(_selectAutocomplete.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.set("teammate-only", this.get("module.params.team"));
    },
    available_owners: [],
    sortedTodos: Ember.computed("model.todos.@each._destroy", function () {
      return this.get("model.todos").filter(function (todo) {
        return todo._destroy !== 1;
      }).sortBy("position");
    }),
    members: Ember.computed("model.author", "model.teammates", function () {
      return this.get("model.author") ? [].concat((0, _toConsumableArray2.default)(this.get("model.teammates").toArray()), [this.get("model.author")]) : this.get("model.teammates").toArray();
    }),
    pastTodos: Ember.computed("model.todos.@each", "model.index_stage", getPositionFilter(GT)),
    currentTodos: Ember.computed("model.todos.@each", "model.index_stage", getPositionFilter(EQ)),
    futureTodos: Ember.computed("model.todos.@each", "model.index_stage", getPositionFilter(LT)),
    completedDidChange: Ember.on("init", Ember.observer("model.todos.[]", function () {
      var _this2 = this;

      var notCompletedTodos = this.get("model.todos").filter(function (todo) {
        return todo.stage === _this2.get("model.state");
      });
      this.set("module.isNotCompleted", notCompletedTodos.length > 0);
    })),
    actions: {
      addTodo: function addTodo() {
        var value = this.newTodo;

        if (Ember.isBlank(value)) {
          return;
        }

        var nextState = this.get("challenge.state_machine_rules").get(this.get("model.index_stage") + 1);
        var defaultStage = nextState && nextState.to;
        this.get("model.todos").pushObject(Ember.Object.create({
          localId: this.get("model.todos").filter(function (m) {
            return m.localId;
          }).length + 1,
          name: value,
          user_id: null,
          owner: null,
          stage: defaultStage,
          due_date: null,
          late: false,
          completed: false,
          position: this.get("model.todos").length
        }));
        this.set("newTodo", null);
      },
      removeTodo: function removeTodo(todo) {
        if (todo.id) {
          this.get("model.todos").findBy("id", todo.id).set("_destroy", 1);
        } else {
          this.get("model.todos").removeObject(todo);
        }
      },
      updateTodoPositions: function updateTodoPositions() {
        this.set("model.todos", this.sortedTodos.map(function (todo, index) {
          todo.set("position", index);
          return todo;
        }));
      },
      setOwner: function setOwner(todo, owner) {
        todo.set("owner", owner);
        todo.set("user_id", owner.id);
      },
      setDueDate: function setDueDate(todo, date) {
        todo.set("due_date", date);
      },
      toggleActionCompleted: function toggleActionCompleted(todo) {
        this.model.toggleActionCompleted(todo).then(function () {
          Ember.set(todo, "completed", !todo.completed);
        });
      }
    }
  });

  _exports.default = _default;
});