define("innowaze-ember2/initializers/session", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/configuration", "innowaze-ember2/config/environment"], function (_exports, _objectSpread2, _configuration, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var session = Ember.Object.extend((0, _objectSpread2.default)({
      // Rights
      _warnForChallenge: function _warnForChallenge(func) {
        console.warn(func + " has been called without a currentChallenge.\nPlease check your usage:\nmaybe you forgot the setCurrentChallenge mixin\nor maybe you overwrote afterModel without a call to this._super(...arguments)");
      },
      roles: [{
        id: 0,
        title: "Participant"
      }, {
        id: 10,
        title: "Evaluateur"
      }, {
        id: 20,
        title: "Animateur"
      }, {
        id: 30,
        title: "Administrateur de défi"
      }, {
        id: 40,
        title: "Adminstrateur d'instance"
      }],
      apiUrl: _configuration.default.apiUrl,
      defaultImagesPaths: _configuration.default.defaultImagesPaths,
      loggedIn: false
    }, _environment.default.enableTokenAuth && {
      userToken: null,
      userTokenChange: Ember.observer("loggedIn", function () {
        this.set("userToken", localStorage.getItem("userToken"));
      })
    }, {
      isSuperAdmin: Ember.computed("currentUser", function () {
        return this.get("currentUser.is_super_admin");
      }),
      isDepartmentScout: Ember.computed("currentUser", function () {
        return this.get("currentUser.is_department_scout");
      }),
      // Roles at challenge level
      isAdmin: Ember.computed("currentUser", "currentChallenge", function () {
        if (this.currentChallenge) {
          return this._isProfil("isAdmin");
        }

        return false;
      }),
      isModerator: Ember.computed("currentUser", "currentChallenge", function () {
        if (this.currentChallenge) {
          return this._isProfil("isModerator");
        }

        return false;
      }),
      isReviewer: Ember.computed("currentUser", "currentChallenge", function () {
        if (this.currentChallenge) {
          return this._isProfil("isReviewer");
        }

        return false;
      }),
      isChampion: Ember.computed("currentUser", "currentChallenge", function () {
        if (this.currentChallenge) {
          return this._isProfil("isChampion");
        }

        return false;
      }),
      isDesigner: Ember.computed("isAdmin", "currentUser", "currentChallenge", function () {
        if (this.currentChallenge) {
          return this._isProfil("isDesigner");
        }

        return false;
      }),
      isCompetitor: Ember.computed("isAdmin", "currentUser", "currentChallenge", function () {
        if (this.currentChallenge) {
          return this._isProfil("isCompetitor");
        }

        return false;
      }),
      profileIsCompleted: Ember.computed("currentChallenge.current_profile_completion", function () {
        if (this.currentChallenge) {
          return this.get("currentChallenge.current_profile_completion");
        }

        return false;
      }),
      hideReporting: Ember.computed("isModerator", "isCompetitor", "configuration.dict.end_user", "currentChallenge.wants_ideation", function () {
        return !this.get("currentChallenge.wants_ideation") || this.isCompetitor || this.get("configuration.dict.end_user") && !this.isModerator;
      }),
      _isProfil: function _isProfil(computedName) {
        var challenge = this.currentChallenge;

        if (!challenge) {
          this._warnForChallenge(computedName);
        }

        var user = this.currentUser;
        return challenge && user && challenge.get("current_user_rights") && challenge.get("current_user_rights")["".concat(computedName)];
      },
      otherAvailableChallenges: Ember.computed("availableChallenges", "currentChallenge", function () {
        if (!this.availableChallenges || !this.currentChallenge) {
          return [];
        }

        return this.availableChallenges.without(this.currentChallenge);
      }),
      availableUniverses: [],
      sortedUniverses: Ember.computed("availableUniverses.[]", function () {
        return this.availableUniverses.sortBy("position");
      }),
      hasUniverses: Ember.computed("availableUniverses.[]", "configuration.dict.universes", function () {
        return this.get("configuration.dict.universes") && this.get("availableUniverses.length") > 0;
      }),
      orphanChallenges: Ember.computed("availableChallenges", "availableChallenges.[]", "currentChallenge.universes.[]", function () {
        if (!this.availableChallenges) {
          return [];
        }

        return this.availableChallenges.filter(function (challenge) {
          return challenge.get("universes").length === 0 && !challenge.get("closed");
        });
      })
    }));
    application.register("session:main", session);
    application.inject("adapter", "session", "session:main");
    application.inject("serializer", "session", "session:main");
    application.inject("route", "session", "session:main");
    application.inject("model", "session", "session:main");
    application.inject("controller", "session", "session:main");
    application.inject("component", "session", "session:main");
    application.inject("service:locale", "session", "session:main");
  }

  var _default = {
    name: "session",
    initialize: initialize
  };
  _exports.default = _default;
});