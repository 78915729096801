define("innowaze-ember2/routes/challenges/edit-showcase", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveChallenge"], function (_exports, _cleanCurrentModel, _setCurrentChallenge, _saveChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _setCurrentChallenge.default, _saveChallenge.default, {
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.saveCurrentChallenge().then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      },
      next: function next() {
        var _this2 = this;

        this.saveForNextStep(1).then(function () {
          return _this2._stepSuccess();
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.setProperties({
          showcase_banner_id: files[0].id,
          showcase_banner_url: files[0].file_url
        });
      },
      deleteShowcaseBanner: function deleteShowcaseBanner() {
        this.currentModel.setProperties({
          showcase_banner_id: null,
          showcase_banner_url: null
        });
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      }
    },
    _stepSuccess: function _stepSuccess() {
      this._success();

      this.transitionTo("challenges.edit_home", this.currentModel.id);
    }
  });

  _exports.default = _default;
});