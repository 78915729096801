define("innowaze-ember2/templates/departments/partials/-value-proposition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A6bNHhW1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"value_proposition\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"departments.value_proposition\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[1,[23,[\"model\",\"value_proposition\"]],true],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/partials/-value-proposition.hbs"
    }
  });

  _exports.default = _default;
});