define("innowaze-ember2/utils/emailPattern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = new RegExp(/^([\w'.+-]+)@([\w.-]+)\.([a-zA-Z.]{2,9})$/i);

  _exports.default = _default;
});