define("innowaze-ember2/templates/concepts/edit-modules/-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gaSxZ/dO",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"modules/custom-module\",null,[[\"module\",\"model\",\"challenge\",\"editMode\"],[[23,[\"module\"]],[23,[\"model\",\"concept\"]],[23,[\"model\",\"challenge\"]],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/edit-modules/-custom.hbs"
    }
  });

  _exports.default = _default;
});