define("innowaze-ember2/routes/challenges/solution-subscriptions", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _setCurrentChallenge, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        solutions: this.store.findAll("solution", {
          backgroundReload: false
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        availableOrganizations: model.solutions.filterBy("level", "organization").mapBy("name"),
        availableActivities: model.solutions.filterBy("level", "activity").mapBy("name"),
        availableSolutions: model.solutions.filterBy("level", "solution").mapBy("name"),
        organizationFilter: null,
        activityFilter: null,
        solutionFilter: null
      });
    },
    actions: {
      toggleFollow: function toggleFollow(_ref) {
        var _this = this;

        var id = _ref.id;
        var solution = this.store.peekRecord("solution", id);
        this.set("controller.isSaving", true);
        solution.toggleFollow().finally(function () {
          return _this.set("controller.isSaving", false);
        });
      }
    }
  });

  _exports.default = _default;
});