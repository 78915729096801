define("innowaze-ember2/templates/domains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oDgBXsB1",
    "block": "{\"symbols\":[\"domain\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"i18n\",[\"header.menu.global_domains\"],null],false],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"fat-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"banner\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"picture\"],[9],[1,[27,\"clearing-image\",null,[[\"object\"],[[22,1,[]]]]],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n          \"],[4,\"link-to\",[\"domains.edit\",[22,1,[]]],null,{\"statements\":[[1,[22,1,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"delete-domain\"],[3,\"action\",[[22,0,[]],\"delete\",[22,1,[]]]],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[10],[0,\"\\n  \"],[4,\"link-to\",[\"domains.new\"],[[\"class\"],[\"button-primary right\"]],{\"statements\":[[1,[27,\"i18n\",[\"domains.new.create\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/domains.hbs"
    }
  });

  _exports.default = _default;
});