define("innowaze-ember2/serializers/pathway", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      author: {
        deserialize: "records",
        serialize: false
      },
      teammates: {
        deserialize: "records",
        serialize: false
      },
      contributors: {
        deserialize: "records",
        serialize: false
      },
      debates: {
        deserialize: "records",
        serialize: false
      },
      comments: {
        deserialize: "records",
        serialize: false
      },
      tags: {
        deserialize: "records",
        serialize: false
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      if (snapshot.record.get("author")) {
        json.user_id = snapshot.record.get("author.id");
        delete json.author;
      }

      if (snapshot.record.get("teammates")) {
        json.teammates = snapshot.record.get("teammates").map(function (teammate) {
          return {
            id: teammate.id
          };
        });
      }

      if (snapshot.record.get("tags")) {
        json.tags = snapshot.record.get("tags").map(function (tag) {
          return {
            name: Ember.get(tag, "name")
          };
        });
      }

      delete json.logo_url;
      delete json.knowledge;
      return {
        pathway: json
      };
    }
  });

  _exports.default = _default;
});