define("innowaze-ember2/templates/components/textarea-wysiwyg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KcMIM8Zp",
    "block": "{\"symbols\":[],\"statements\":[[5,\"froala-editor\",[],[[\"@content\",\"@update\",\"@options\",\"@on-video-uploaded\",\"@on-image-uploaded\"],[[27,\"html-safe\",[[22,0,[\"value\"]]],null],[27,\"action\",[[22,0,[]],\"onChange\"],null],[22,0,[\"options\"]],[27,\"action\",[[22,0,[]],\"onVideoUploaded\"],null],[27,\"action\",[[22,0,[]],\"onImageUploaded\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/textarea-wysiwyg.hbs"
    }
  });

  _exports.default = _default;
});