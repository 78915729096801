define("innowaze-ember2/components/modules/main-info-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    completedDidChange: Ember.on("init", Ember.observer("model.name", "model.description", function () {
      this.set("module.isNotCompleted", !this.get("model.name") || !this.get("model.description"));
    })),
    actions: {
      filesUploaded: function filesUploaded(files) {
        this.model.setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.model.set(field, newContent);
      },
      toggleOrigin: function toggleOrigin() {
        this.toggleProperty("module.origin");
      }
    }
  });

  _exports.default = _default;
});