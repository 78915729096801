define("innowaze-ember2/mixins/routes/userListFilters", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      selectAll: function selectAll() {
        this.set("controller.allSelected", true);
        this.get("controller.matchingUsers").setEach("selected", true);
      },
      unselectAll: function unselectAll() {
        this.set("controller.allSelected", false);
        this.get("controller.matchingUsers").setEach("selected", false);
      },
      loadMore: function loadMore() {
        this.controller.incrementProperty("page");
        this.controller.set("isLoadingMore", true);

        this._loadData();
      },
      search: function search() {
        this.controller.setProperties({
          matchingUsers: [],
          page: 1
        });

        this._loadData();
      },
      sort: function sort(order) {
        this.controller.setProperties({
          order: order,
          matchingUsers: [],
          page: 1
        });

        this._loadData();
      },
      setTags: function setTags(tags) {
        this.controller.setProperties({
          page: 1,
          tags: tags,
          matchingUsers: []
        });

        this._loadData();
      },
      setDepartments: function setDepartments(departments) {
        this.controller.setProperties({
          page: 1,
          departments: departments,
          matchingUsers: []
        });

        this._loadData();
      },
      setChallenges: function setChallenges(challenges) {
        this.controller.setProperties({
          challenges: challenges,
          matchingUsers: [],
          page: 1
        });

        if (!challenges.length) {
          this.set("controller.roles", []);
        }

        this._loadData();
      },
      setRoles: function setRoles(selectedRoles) {
        this.controller.setProperties({
          selectedRoles: selectedRoles,
          isLoading: true,
          matchingUsers: [],
          page: 1
        });

        this._loadData();
      }
    },
    _prepareOptions: function _prepareOptions() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        paginate: true
      };
      var query = this.get("controller.query");
      var order = this.get("controller.order");
      var tags = this.get("controller.tags");
      var departments = this.get("controller.departments");
      var challenges = this.get("controller.challenges");
      var roles = this.get("controller.selectedRoles");
      var page = this.get("controller.page");
      return (0, _objectSpread2.default)({}, options, {}, query && {
        query: query
      }, {}, order && {
        order: order
      }, {}, tags && tags.length > 0 && {
        skills: tags.mapBy("name")
      }, {}, departments && departments.length > 0 && {
        departments: departments.mapBy("id")
      }, {}, challenges && challenges.length > 0 && {
        challenges: challenges.mapBy("id")
      }, {}, roles && roles.length > 0 && {
        roles: roles.mapBy("id")
      }, {}, page && {
        page: page
      }, {}, this.get("controller.no_challenge") && {
        challenges_count: 0
      }, {}, this.get("controller.never_connected") && {
        sign_in_count: 0
      }, {}, this.get("controller.anonymized") && {
        anonymized: true
      });
    },
    _assignRoles: function _assignRoles(options) {
      var _this = this;

      this.currentModel.getMemberships().then(function (members) {
        _this.controller.set("members", members);

        return _this.store.query("user", options).then(function (data) {
          var users = data.toArray().map(function (user) {
            var member = members.find(function (m) {
              return m.user_id.toString() === user.id;
            });

            if (member !== undefined) {
              user.setProperties({
                roles: member.roles,
                isMember: true
              });
            } else {
              user.setProperties({
                roles: [],
                isMember: false
              });
            }

            return user;
          });

          _this.set("controller.totalResults", data.get("meta.total_results"));

          return users;
        }).then(function (users) {
          _this.get("controller.matchingUsers").pushObjects(users.toArray());

          _this.controller.set("isLoadingMore", false);
        });
      });
    }
  });

  _exports.default = _default;
});