define("innowaze-ember2/templates/components/document-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8qZXBuS9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"report-modal\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"remove\"],[3,\"action\",[[22,0,[]],\"toggleDocumentEditForm\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[10],[0,\"\\n  \"],[7,\"form\"],[3,\"action\",[[22,0,[]],\"saveDocument\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fields\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"placeholder\"],[\"text\",[23,[\"name\"]],[27,\"i18n\",[\"challenges.documents.name\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-actions right\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"cancel\"],[3,\"action\",[[22,0,[]],\"toggleDocumentEditForm\"]],[9],[0,\"Annuler\"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"next-step\"],[11,\"type\",\"submit\"],[9],[0,\"Enregistrer\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/document-edit.hbs"
    }
  });

  _exports.default = _default;
});