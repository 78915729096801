define("innowaze-ember2/routes/ideas/grouped-per-supertag", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var supertagDataset = 2;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this._loadData(controller.get("clusters.firstObject"));
    },
    _loadData: function _loadData(cluster) {
      var _this = this;

      this.store.adapterFor("idea").groupedPerSupertag(this.currentModel.id, cluster.id).then(function (supertags) {
        _this.controller.setProperties({
          selectedCluster: cluster,
          ideas: [],
          supertags: supertags,
          supertag_names: supertags.mapBy("label"),
          loader: false,
          dataLoaded: true
        });

        var uid = 0;
        supertags.forEach(function (supertag) {
          // sometimes, an idea may appear in different clusters
          uid += 1;
          supertag.set("selected", false);
          var ideas = supertag.get("ideas").map(function (idea, index) {
            uid += index;
            idea.updated = false;
            idea.supertag = supertag.label;
            idea.uid = uid;
            return Ember.Object.create(idea);
          });
          supertag.set("ideas", ideas);

          _this.get("controller.ideas").pushObjects(ideas);
        });
      });
    },
    _success: function _success(cluster) {
      this._loadData(cluster);

      this.notifications.success(this.locale.t("ideas.grouped_per_supertags.update"));
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.locale.t("error"), errors);
    },
    actions: {
      displayCluster: function displayCluster(cluster) {
        cluster.toggleProperty("selected");
      },
      sortIdea: function sortIdea(event) {
        var uid = event.target.closest("[class^='idea-']").className.match(/(\bidea-\S+\b)/ig).pop().slice(5);
        var supertag = event.currentTarget.className.match(/(\blabel\S+\b)/ig).pop();
        this.get("controller.ideas").findBy("uid", parseInt(uid)).setProperties({
          supertag: supertag,
          updated: true
        });
      },
      dropInEmptyList: function dropInEmptyList(idea, options) {
        this.get("controller.supertags").findBy("label", idea.get("supertag")).get("ideas").removeObject(idea);
        this.get("controller.supertags").findBy("label", options.target.supertag).get("ideas").pushObject(idea);
        this.get("controller.ideas").findBy("id", idea.id).setProperties({
          supertag: options.target.supertag,
          updated: true
        });
      },
      changeCluster: function changeCluster(cluster) {
        this._loadData(cluster);

        this.controller.set("loader", true);
        this.controller.set("isSupertagDataset", cluster.id === supertagDataset);
      },
      updateSupertags: function updateSupertags() {
        var _this2 = this;

        this.controller.set("loader", true);
        var cluster = this.get("controller.selectedCluster");
        var ideas = this.get("controller.ideas").filterBy("updated", true).map(function (idea) {
          return idea.getProperties("id", "supertag");
        });
        this.store.adapterFor("challenge").updateSupertags(this.currentModel.id, cluster.id, ideas).then(function () {
          return _this2._success(cluster);
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      resetSupertags: function resetSupertags() {
        var _this3 = this;

        if (confirm(this.locale.t("supertags.reset_warning"))) {
          this.controller.set("loader", true);
          var cluster = this.get("controller.selectedCluster");
          this.store.adapterFor("challenge").updateSupertags(this.currentModel.id, cluster.id, null, true).then(function () {
            return _this3._success(cluster);
          }, function (errors) {
            return _this3._error(errors);
          });
        }
      }
    }
  });

  _exports.default = _default;
});