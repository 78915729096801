define("innowaze-ember2/models/universe", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    name: _emberData.default.attr("string"),
    short_description: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    admins: _emberData.default.hasMany("user", {
      async: false
    }),
    banner_height: _emberData.default.attr("number", {
      defaultValue: 100
    }),
    banner_url: _emberData.default.attr("string"),
    banner_id: _emberData.default.attr(),
    thumbnail_url: _emberData.default.attr("string"),
    thumbnail_id: _emberData.default.attr(),
    challenges: _emberData.default.hasMany("challenge", {
      async: false
    }),
    published: _emberData.default.attr(),
    position: _emberData.default.attr("number"),
    no_filters: _emberData.default.attr("boolean"),
    togglePublish: function togglePublish() {
      return this._callAdapter("togglePublish");
    },
    challengeCount: Ember.computed("challenges.[]", function () {
      return this.get("challenges.length");
    }),
    openChallenges: Ember.computed("challenges.[]", function () {
      return this.challenges.filterBy("closed", false);
    }),
    backgroundStyle: Ember.computed("thumbnail_url", function () {
      var background = this.thumbnail_url;

      if (!background) {
        return "";
      }

      return Ember.String.htmlSafe("background: linear-gradient(to bottom, rgba(0,0,0,0.75), rgba(0,0,0,0)), url(".concat(this.get("session.apiUrl")).concat(background, "); background-size: cover;"));
    }),
    bannerStyle: Ember.computed("banner_height", function () {
      return Ember.String.htmlSafe("height: ".concat(this.banner_height * 56 / 100, "vh"));
    })
  });

  _exports.default = _default;
});