define("innowaze-ember2/helpers/i18n", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    locale: Ember.inject.service(),
    handleLocaleChange: Ember.observer("locale.currentLocale", function () {
      this.recompute();
    }),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          key = _ref2[0];

      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (key === undefined || Object.values(params).any(function (p) {
        return p === undefined;
      })) {
        var _console;

        (_console = console).warn.apply(_console, ["i18n helper was called with an undefined param"].concat(Array.prototype.slice.call(arguments)));

        return "";
      }

      return this.locale.t(key, params);
    }
  });

  _exports.default = _default;
});