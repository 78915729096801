define("innowaze-ember2/helpers/gt", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gt = gt;
  _exports.default = void 0;

  // greater than
  // ex : {{#if (gt var1 var2 forceNumber=true)}}
  function gt(_ref, hash) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        left = _ref2[0],
        right = _ref2[1];

    if (hash.forceNumber) {
      if (typeof left !== "number") {
        left = Number(left);
      }

      if (typeof right !== "number") {
        right = Number(right);
      }
    }

    return left > right;
  }

  var _default = Ember.Helper.helper(gt);

  _exports.default = _default;
});