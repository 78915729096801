define("innowaze-ember2/templates/challenges/modules/-domains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lnGxUEB8",
    "block": "{\"symbols\":[\"domain\",\"domain\",\"domain\"],\"statements\":[[4,\"if\",[[23,[\"availableDomains\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"challenges.domains.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n\"],[4,\"if\",[[23,[\"domainWidget\"]]],null,{\"statements\":[[0,\"      \"],[7,\"ul\"],[11,\"class\",\"small-block-grid-2 large-block-grid-2\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"availableDomains\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[1,[27,\"domain-card\",null,[[\"domain\",\"challenge\"],[[22,3,[]],[23,[\"model\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"largeDomainWidget\"]]],null,{\"statements\":[[0,\"        \"],[7,\"ul\"],[11,\"class\",\"small-block-grid-2 medium-block-grid-4 large-block-grid-6\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"availableDomains\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[9],[1,[27,\"domain-card\",null,[[\"domain\",\"challenge\"],[[22,2,[]],[23,[\"model\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"ul\"],[11,\"class\",\"small-block-grid-2 medium-block-grid-4 large-block-grid-4\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"availableDomains\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[9],[1,[27,\"domain-card\",null,[[\"domain\",\"challenge\"],[[22,1,[]],[23,[\"model\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-domains.hbs"
    }
  });

  _exports.default = _default;
});