define("innowaze-ember2/controllers/users", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/controllers/userTags"], function (_exports, _objectSpread2, _userTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_userTags.default, {
    locale: Ember.inject.service(),
    queryParams: ["department", "challenge"],
    displayMode: "block-list",
    tags: [],
    department: null,
    challenge: null,
    challenges: [],
    departments: [],
    order: "created_at",
    matchingUsers: [],
    totalResults: 0,
    page: 1,
    knowledgesQueryParams: Ember.computed(function () {
      return (0, _objectSpread2.default)({}, !this.get("session.currentUser.admin") && {
        users: [this.get("session.currentUser.id")]
      }, {}, {
        private: true
      });
    }),
    selectedUsers: Ember.computed.filterBy("matchingUsers", "selected", true),
    selectedUserIds: Ember.computed.mapBy("selectedUsers", "id"),
    userDepartments: Ember.computed("availableDepartments.[]", "session.currentUser", function () {
      var _this = this;

      if (!this.get("session.currentUser")) {
        return [];
      }

      if (this.availableDepartments) {
        var customerDepartments = this.availableDepartments;

        if (this.get("session.currentUser.admin")) {
          return customerDepartments;
        }

        return customerDepartments.filter(function (department) {
          var userDepartment = _this.get("session.currentUser.departments").findBy("id", parseInt(department.id));

          return userDepartment && userDepartment.admin;
        });
      }
    }),
    availableRoles: Ember.computed(function () {
      return [{
        id: null,
        name: this.locale.t("participant")
      }, {
        id: "reviewer",
        name: this.locale.t("reviewer")
      }, {
        id: "designer",
        name: this.locale.t("designer")
      }, {
        id: "moderator",
        name: this.locale.t("moderator")
      }, {
        id: "admin",
        name: this.locale.t("administrator")
      }, {
        id: "blind",
        name: this.locale.t("blind")
      }];
    }),
    hideEmailToAll: Ember.computed("challenges", "departments", "tags", "no_challenge", "never_connected", "anonymized", "selectedUsers", "query", function () {
      var _this2 = this;

      return this.no_challenge || this.never_connected || this.anonymized || !Ember.isBlank(this.query) || ["challenges", "departments", "tags", "selectedUsers"].reduce(function (acc, filterName) {
        return acc + _this2.get(filterName).length;
      }, 0) > 0;
    }),
    canCallActions: Ember.computed("userDepartments.[]", "session.currentUser", function () {
      return this.get("session.currentUser.admin") || this.get("session.currentUser.departments").length > 0 || this.canToggleKnowledge;
    }),
    canAssignDepartment: Ember.computed("currentUser", function () {
      return this.get("session.currentUser.admin") || this.get("session.currentUser.departments").length > 0;
    }),
    canToggleKnowledge: Ember.computed.or("session.currentUser.admin", "session.currentUser.is_knowledger")
  });

  _exports.default = _default;
});