define("innowaze-ember2/routes/surveys", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    actions: {
      delete: function _delete(survey) {
        var _this = this;

        if (!confirm(this.locale.t("surveys.index.deletion_confirmation"))) {
          return;
        }

        this.store.findRecord("survey", survey.id, {
          backgroundReload: false,
          adapterOptions: {
            challenge_id: this.currentModel.get("id")
          }
        }).then(function (survey) {
          survey.destroyRecord({
            adapterOptions: {
              challenge_id: _this.currentModel.get("id")
            }
          }).then(function () {
            _this.notifications.success(_this.locale.t("surveys.index.deleted"));
          });
        });
      }
    }
  });

  _exports.default = _default;
});