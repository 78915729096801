define("innowaze-ember2/controllers/ideas/edit", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/utils/stripTags", "innowaze-ember2/utils/stripAccents", "innowaze-ember2/utils/normalizeScore"], function (_exports, _objectSpread2, _stripTags, _stripAccents, _normalizeScore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["domain"],
    domain: null,
    members: Ember.computed("model.idea.author", function () {
      return this.get("model.idea.author") ? [this.get("model.idea.author")] : [];
    }),
    relatedIdeasToDisplay: Ember.computed("relatedIdeas.[]", "notRelatedIdeas.[]", function () {
      var _this = this;

      this.relatedIdeas.forEach(function (e) {
        e._score = (0, _normalizeScore.default)(e.normalized, e._score, _this.get("model.idea.description")); // Avoid to recalculate normalized score twice.

        e.normalized = true;
      });
      return this.relatedIdeas.reject(function (idea) {
        return _this.notRelatedIdeas.includes(idea._id) || idea._score < 30;
      });
    }),
    // return best related idea
    bestRelatedIdea: Ember.computed("relatedIdeasToDisplay.[]", function () {
      return this.relatedIdeasToDisplay.filter(function (idea) {
        return idea._score > 160;
      }).get("firstObject");
    }),
    relatedKnowledgesToDisplay: Ember.computed("model.idea.knowledges.[]", "relatedKnowledges.[]", function () {
      var _this2 = this;

      if (this.get("model.idea.knowledges")) {
        return this.relatedKnowledges.reject(function (knowledge) {
          return _this2.get("model.idea.knowledges").findBy("id", knowledge._id);
        });
      }

      return [];
    }),
    relatedTagsToDisplay: Ember.computed("model.idea.tags.[]", "relatedTags.[]", function () {
      var _this3 = this;

      return this.relatedTags.reject(function (tag) {
        return _this3.get("model.idea.tags").findBy("name", tag.get("tag").toLowerCase());
      });
    }),
    searchCriteriaChanged: Ember.observer("model.idea.name", "model.idea.description", "model.idea.tags.[]", function () {
      var _this4 = this;

      if (this.routeIsReadyForAction) {
        // striptag text from wysiwyg
        var descriptionWithoutTags = (0, _stripTags.default)(this.get("model.idea.description")); // Don't need to search if main attributes are blank

        if (Ember.isBlank(this.get("model.idea.name")) || Ember.isBlank(descriptionWithoutTags)) {
          return false;
        } // ------------- HL 18jul.2015 --------- tags has been added to the query string instead of acting as a "must" in {query: query, tags:tags}


        var query = [this.get("model.idea.name"), descriptionWithoutTags, this.get("model.idea.typology")].join(" ") + this.get("model.idea.tags").mapBy("name").join(" "); // Search for related ideas unless current user is a competitor (blind)

        if (!this.get("session.isCompetitor")) {
          this.store.adapterFor("idea").rawSearch(this.get("model.idea.challenge_id"), {
            query: query,
            paginate: false,
            extended: false,
            order: null
          }).then(function (json) {
            // Remove all draft ideas
            var valid_ideas = json.filter(function (elt) {
              return elt._source.state !== "draft";
            }); // ... and atoms of a molecule-idea

            if (_this4.get("model.idea.parents")) {
              var parents_ids = _this4.get("model.idea.parents").mapBy("id").map(String);

              valid_ideas = valid_ideas.filter(function (e) {
                return !parents_ids.includes(e._id);
              });
            }

            _this4.set("relatedIdeas", valid_ideas);
          });
        } // Search for related knowledges


        if (this.get("model.challenge.activeIdeaModules").findBy("name", "knowledges")) {
          this.store.adapterFor("knowledge").rawSearch({
            query: query
          }).then(function (knowledges) {
            _this4.set("relatedKnowledges", knowledges.filter(function (elt) {
              return elt._score > 0.01;
            }));
          });
        } // Search for tags to be suggested


        if (arguments[1] !== "model.idea.tags.[]") {
          var matchingTags = [];
          var downcasedDescription = descriptionWithoutTags.toLowerCase();

          if (!this.availableTriggers) {
            return;
          }

          this.availableTriggers.forEach(function (trigger) {
            trigger.get("keywords").forEach(function (keyword) {
              var downcasedKeyword = keyword.toLowerCase();
              var nonAccentedKeyword = (0, _stripAccents.default)(downcasedKeyword);

              if (downcasedDescription.indexOf(downcasedKeyword) > -1 || downcasedDescription.indexOf(nonAccentedKeyword) > -1) {
                if (!matchingTags.includes(trigger)) {
                  matchingTags.push(trigger);
                }
              }
            });
          });
          this.set("relatedTags", matchingTags);
        }
      }
    }),
    activeModules: Ember.computed("model.challenge.ideas", "model.idea.index_stage", "model.idea.extra_modules", function () {
      var _this5 = this;

      var activeModules = this.get("model.challenge.ideas").filter(function (module) {
        return module.active;
      }).map(function (module) {
        return (0, _objectSpread2.default)({}, module, {
          is_displayed: _this5.get("model.idea.index_stage") >= module.index_stage
        });
      }); // Add extra_modules data

      if (this.get("model.idea.extra_modules")) {
        this.get("model.idea.extra_modules").forEach(function (extraModule) {
          var m = activeModules.findBy("id", extraModule.id);

          if (m) {
            Ember.set(m, "description", extraModule.description);
          }
        });
      }

      activeModules.forEach(function (m) {
        Ember.set(m, "always_visible", m.visible);
      });
      return activeModules;
    }),
    completionPercentage: Ember.computed("activeModules.@each.isNotCompleted", function () {
      var controlModules = this.activeModules.filter(function (module) {
        return module.control && module.is_displayed && !module.hide_in_form;
      });
      var completedControlModules = controlModules.filterBy("isNotCompleted");
      return Math.round((controlModules.length - completedControlModules.length) * 100 / controlModules.length);
    }),
    completionPercentageStyle: Ember.computed("completionPercentage", function () {
      return Ember.String.htmlSafe("width: ".concat(this.completionPercentage, "%"));
    })
  });

  _exports.default = _default;
});