define("innowaze-ember2/templates/users/edit-modules/-universe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MT53+pcf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"users.modules.universe.title\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"large-4 columns\"],[9],[0,\"\\n      \"],[1,[27,\"ember-select\",null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"selectedValue\"],[[23,[\"universeSelectOptions\"]],\"id\",\"name\",[23,[\"model\",\"default_universe_id\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/edit-modules/-universe.hbs"
    }
  });

  _exports.default = _default;
});