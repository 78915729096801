define("innowaze-ember2/routes/domains/new", ["exports", "innowaze-ember2/routes/domains/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    model: function model() {
      return this.store.createRecord("domain");
    },
    errorMessage: Ember.computed(function () {
      return this.locale.t("creation_error");
    }),
    successMessage: "Le domaine a été créé."
  });

  _exports.default = _default;
});