define("innowaze-ember2/mixins/routes/selectAutocomplete", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var timeout;
  var throttling = false;

  var _default = Ember.Mixin.create({
    populatedCollections: [],
    actions: {
      autocomplete: function autocomplete(_ref, filter) {
        var _this = this;

        var collectionToFilter = _ref.collectionToFilter,
            collectionToPopulate = _ref.collectionToPopulate,
            modelName = _ref.modelName,
            queryParams = _ref.queryParams;

        if (!collectionToPopulate) {
          console.warn("selectAutocomplete mixin warning: you have to set collectionToPopulate option");
          return;
        }

        this.populatedCollections.addObject(collectionToPopulate); // Sync autocomplete

        if (collectionToFilter && (Ember.typeOf(collectionToFilter) === "instance" || Ember.typeOf(collectionToFilter) === "array")) {
          if (filter) {
            var filtered = collectionToFilter.filter(function (d) {
              return d.get("name").toUpperCase().includes(filter.toUpperCase());
            });
            collectionToPopulate.addObjects(filtered);
          }

          return;
        } // Async autocomplete


        if (!modelName) {
          console.warn("selectAutocomplete mixin warning: you have to set modelName option");
          return;
        }

        if (throttling) {
          window.clearTimeout(timeout);
        }

        throttling = true;
        timeout = window.setTimeout(function () {
          if (filter.length > 1) {
            // In some cases, restrict elements to a specific challenge
            if (queryParams && "challenge" in queryParams) {
              queryParams.challenges = [queryParams.challenge];
              delete queryParams.challenge;
            } // In some cases, restrict elements to a specific roles for a challenge


            if (queryParams && "role" in queryParams) {
              queryParams.roles = queryParams.role.split(",");
              delete queryParams.role;
            }

            return _this.store.query(modelName, (0, _objectSpread2.default)({
              query: filter
            }, queryParams)).then(function (records) {
              if (_this.isDestroyed || _this.isDestroying) {
                return;
              }

              var filtered = records.filter(function (rec) {
                return !collectionToPopulate.includes(rec);
              });
              collectionToPopulate.pushObjects(filtered);
            });
          }

          throttling = false;
        }, 500);
      },
      willTransition: function willTransition() {
        this._cleanBeforeLeave();

        return true;
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._cleanBeforeLeave();
    },
    _cleanBeforeLeave: function _cleanBeforeLeave() {
      this.set("populatedCollections", []);
    }
  });

  _exports.default = _default;
});