define("innowaze-ember2/routes/challenges/emailings", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/mixins/routes/userListFilters"], function (_exports, _setCurrentChallenge, _selectAutocomplete, _userListFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _selectAutocomplete.default, _userListFilters.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        page: 1,
        tagsToDisplay: [],
        departmentsToDisplay: [],
        tags: [],
        departements: [],
        selectedRoles: [],
        challenges: [],
        matchingUsers: [],
        order: "created_at",
        all_participants: true,
        all_users: false,
        all_authors: true,
        all_evaluators: false,
        all_incomplete_profiles: false,
        last_ideas: false,
        last_comments: false,
        open_debates: false,
        last_concepts_comments: false,
        last_debates_comments: false,
        last_concept_debates_comments: false,
        isLoadingMore: true
      });
      this.store.adapterFor("email-template").getTemplates({
        challenge_id: model.get("id")
      }).then(function (templates) {
        controller.set("availableEmailTemplates", templates);
        controller.set("selectedTemplate", controller.get("availableEmailTemplates.firstObject"));
      });

      this._loadData();
    },
    resetController: function resetController() {
      this.controller.setProperties({
        selectedTemplate: null,
        title: ""
      });
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      send: function send() {
        if (this._controlMessage()) {
          if (this.get("controller.all_users")) {
            if (this.get("controller.message") && this.get("controller.message.length") >= 50) {
              $("#alertAllUsersModal").foundation("reveal", "open");
            } else {
              this.notifications.error(this.locale.t("challenges.emailings.no_content_message"));
            }
          } else {
            this._sendEmail();
          }
        }
      },
      confirmSend: function confirmSend() {
        this._sendEmail();

        $("#alertAllUsersModal").foundation("reveal", "close");
      },
      test: function test() {
        var _this = this;

        if (this._controlMessage()) {
          this.currentModel.emailing(false, false, false, false, false, [this.get("session.currentUser.id")], this.get("controller.message"), this.get("controller.title"), this.get("controller.last_ideas"), this.get("controller.last_comments"), this.get("controller.open_debates"), this.get("controller.last_concepts_comments"), this.get("controller.last_debates_comments"), this.get("controller.last_concept_debates_comments")).then(function () {
            _this.notifications.success(_this.locale.t("challenges.emailings.test_email_sent"));
          });
        }
      },
      cancel: function cancel() {
        this.transitionTo("challenges.show", this.currentModel.id);
      },
      saveTemplate: function saveTemplate() {
        var _this2 = this;

        if (!this.get("controller.availableEmailTemplates").mapBy("name").includes(this.get("controller.title"))) {
          if (this.get("controller.message") && this.get("controller.message").length >= 50) {
            var template = this.store.createRecord("email-template", {
              name: this.get("controller.title"),
              description: this.get("controller.message"),
              challenge_id: this.currentModel.id
            });
            template.save().then(function (template) {
              return _this2._success(template);
            }, function () {
              return _this2._error();
            });
          } else {
            this.notifications.error(this.locale.t("challenges.emailings.no_content_message"));
          }
        } else {
          this.notifications.error(this.locale.t("challenges.emailings.existing_name"));
        }
      }
    },
    _success: function _success(template) {
      this.get("controller.availableEmailTemplates").pushObject(template);
      this.notifications.success(this.locale.t("challenges.emailings.email_template_created"));
    },
    _error: function _error() {
      this.notifications.error(this.locale.t("challenges.emailings.email_template_error"));
    },
    _loadData: function _loadData() {
      this._assignRoles(this._prepareOptions({
        paginate: true,
        challenges: [this.currentModel.id]
      }));
    },
    _sendEmail: function _sendEmail() {
      var _this3 = this;

      this.currentModel.emailing(this.get("controller.all_participants"), this.get("controller.all_users"), this.get("controller.all_authors"), this.get("controller.all_evaluators"), this.get("controller.all_incomplete_profiles"), this.get("controller.selectedUsers").mapBy("id"), this.get("controller.message"), this.get("controller.title"), this.get("controller.last_ideas"), this.get("controller.last_comments"), this.get("controller.open_debates"), this.get("controller.last_concepts_comments"), this.get("controller.last_debates_comments"), this.get("controller.last_concept_debates_comments")).then(function () {
        _this3.notifications.success(_this3.locale.t("challenges.emailings.emails_sent"));
      });
    },
    _controlMessage: function _controlMessage() {
      if (Ember.isBlank(this.get("controller.title"))) {
        this.notifications.error(this.locale.t("challenges.emailings.no_title_message"));
        return false;
      } else if (!this.get("controller.all_participants") && !this.get("controller.all_users") && !this.get("controller.all_authors") && !this.get("controller.all_evaluators") && !this.get("controller.all_incomplete_profiles") && this.get("controller.selectedUsers.length") === 0) {
        this.notifications.error(this.locale.t("challenges.emailings.no_user_message"));
        return false;
      }

      return true;
    }
  });

  _exports.default = _default;
});