define("innowaze-ember2/routes/user-modules", ["exports", "innowaze-ember2/mixins/routes/isAdmin"], function (_exports, _isAdmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isAdmin.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      return this.store.findAll("user-module");
    },
    actions: {
      togglePrivate: function togglePrivate(module) {
        module.toggleProperty("private");
      },
      reorder: function reorder() {
        this.get("controller.sortedModules").forEach(function (module, index) {
          module.set("position", index + 1);
        });
      },
      save: function save(modules) {
        var _this = this;

        var promises = [];
        modules.forEach(function (module) {
          if (module.get("hasDirtyAttributes")) {
            promises.push(module.save());
          }
        });

        if (promises.length > 0) {
          Ember.RSVP.all(promises).then(function () {
            return _this._success();
          }, function () {
            return _this._error();
          });
        } else {
          this._success();
        }
      }
    },
    _success: function _success() {
      this.notifications.success(this.locale.t("user_modules.updated"));
    },
    _error: function _error() {
      this.notifications.error(this.locale.t("error"));
    }
  });

  _exports.default = _default;
});