define("innowaze-ember2/controllers/challenges/emailings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var segments = ["all_participants", "all_evaluators", "all_authors", "all_users", "all_incomplete_profiles"];

  var _default = Ember.Controller.extend({
    page: 1,
    matchingUsers: [],
    totalResults: 0,
    selectedUsers: Ember.computed.filterBy("matchingUsers", "selected", true),
    selectedCount: Ember.computed.alias("selectedUsers.length"),
    displaySearch: Ember.computed("all_participants", "all_users", "all_authors", "all_incomplete_profiles", "all_evaluators", function () {
      return !(this.all_participants || this.all_users || this.all_incomplete_profiles || this.all_evaluators || this.all_authors);
    }),
    displaySearchHandle: Ember.observer("displaySearch", function () {
      if (!this.displaySearch) {
        this.matchingUsers.setEach("selected", false);
      }
    }),
    _recipientsHandle: function _recipientsHandle(segment) {
      var _this = this;

      if (this.get(segment)) {
        segments.filter(function (s) {
          return s !== segment;
        }).forEach(function (s) {
          return _this.set(s, false);
        });

        if (segment !== "all_participants") {
          this.set("last_ideas", false);
        }
      }
    },
    _clearWidgets: function _clearWidgets() {
      this.set("last_ideas", false);
      this.set("last_comments", false);
      this.set("open_debates", false);
      this.set("last_concepts_comments", false);
      this.set("last_debates_comments", false);
      this.set("last_concept_debates_comments", false);
    },
    allParticipantsHandle: Ember.observer("all_participants", function () {
      this._recipientsHandle("all_participants");
    }),
    allUsersHandle: Ember.observer("all_users", function () {
      this._recipientsHandle("all_users");

      if (this.all_users) {
        this._clearWidgets();
      }
    }),
    allAuthorsHandle: Ember.observer("all_authors", function () {
      if (this.all_authors) {
        this._clearWidgets();
      }

      this._recipientsHandle("all_authors");
    }),
    allEvaluatorsHandle: Ember.observer("all_evaluators", function () {
      if (this.all_evaluators) {
        this._clearWidgets();
      }

      this._recipientsHandle("all_evaluators");
    }),
    allIncompleteProfilesHandle: Ember.observer("all_incomplete_profiles", function () {
      if (this.all_incomplete_profiles) {
        this._clearWidgets();
      }

      this._recipientsHandle("all_incomplete_profiles");
    }),
    emptyTemplates: Ember.computed.empty("availableEmailTemplates.[]"),
    templateDidChange: Ember.observer("selectedTemplate", function () {
      if (this.get("selectedTemplate.id") === "0") {
        this.set("title", "");
      } else {
        this.set("title", this.get("selectedTemplate.name"));
      }

      this.set("message", this.get("selectedTemplate.description"));
    })
  });

  _exports.default = _default;
});