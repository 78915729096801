define("innowaze-ember2/routes/tags/edit", ["exports", "innowaze-ember2/routes/tags/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    successMessage: "Le tag a été modifié.",
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    model: function model(params) {
      return this.store.findRecord("tag", params.tag_id);
    }
  });

  _exports.default = _default;
});