define("innowaze-ember2/routes/surveys/common", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    deactivate: function deactivate() {
      if (this.currentModel.survey.get("hasDirtyAttributes")) {
        this.currentModel.survey.rollbackAttributes();
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.survey.save({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id
          }
        }).then(function (result) {
          return _this._success(result);
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges.show", this.currentModel.challenge.get("id"));
      },
      addAnswer: function addAnswer() {
        this.currentModel.survey.get("available_answers").pushObject(Ember.Object.create({
          id: null,
          text: ""
        }));
      },
      removeAnswer: function removeAnswer(answer) {
        var answers = this.currentModel.survey.get("available_answers");

        if (answers.rejectBy("_destroy").length > 2) {
          Ember.set(answer, "_destroy", true);
        } else {
          this.notifications.error("Vous devez avoir au moins deux réponses possibles");
        }
      },
      filesUploaded: function filesUploaded(answer, files) {
        var _this2 = this;

        this.currentModel.survey.get("available_answers").some(function (current, index) {
          if (current === answer) {
            _this2.currentModel.survey.get("available_answers")[index].setProperties({
              picture_id: files[0].id,
              picture_url: files[0].file_url
            });

            return true;
          }

          return false;
        });
      }
    },
    _success: function _success(result) {
      this.transitionTo("surveys", result.get("challenge_id"));
      this.notifications.success(this.successMessage);
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.errorMessage, response.errors);
    }
  });

  _exports.default = _default;
});