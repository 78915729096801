define("innowaze-ember2/templates/challenges/modules/-savings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cQCdkmk9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dashboard-primary-buttons\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[1,[27,\"i18n\",[\"challenges.savings.title\"],null],false],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"savings\"],[9],[0,\"\\n      \"],[7,\"h2\"],[11,\"class\",\"amount\"],[9],[7,\"span\"],[9],[1,[23,[\"model\",\"total_savings\"]],false],[0,\" K€\"],[10],[10],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"selected amount\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-thumbs-up\"],[9],[10],[0,\"\\n        \"],[7,\"span\"],[9],[0,\" \"],[1,[23,[\"model\",\"selected_total_savings\"]],false],[0,\" K€\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-savings.hbs"
    }
  });

  _exports.default = _default;
});