define("innowaze-ember2/components/modules/partnership-module", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/components/modules/module"], function (_exports, _objectSpread2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    available_partnership_type: Ember.computed("model.partnership_type.[]", function () {
      var partnershipTypes = this.get("model.partnership_type").map(function (id) {
        return id.toString();
      });
      return this.get("module.params.partnership_type").map(function (type) {
        return (0, _objectSpread2.default)({}, type, {
          selected: partnershipTypes.includes(type.id)
        });
      });
    }),
    actions: {
      toggle: function toggle(type) {
        var model = this.model;
        var partnershipType = model.get("partnership_type").map(function (id) {
          return id.toString();
        });
        partnershipType = partnershipType.includes(type.id) ? partnershipType.filter(function (id) {
          return id !== type.id;
        }) : partnershipType.concat(type.id);
        model.set("partnership_type", partnershipType);
      }
    }
  });

  _exports.default = _default;
});