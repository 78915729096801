define("innowaze-ember2/templates/concepts/modules/-teammates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5FHCBl4w",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"concept\",\"teammates\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"concepts.team\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"teammate small-block-grid-3 medium-block-grid-6 large-block-grid-9\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"concept\",\"teammates\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"clss\",\"picture\"],[9],[0,\"\\n          \"],[7,\"img\"],[12,\"src\",[22,1,[\"avatar\"]]],[9],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"idea-author\"],[9],[0,\"\\n            \"],[1,[22,1,[\"screenname\"]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/modules/-teammates.hbs"
    }
  });

  _exports.default = _default;
});