define("innowaze-ember2/controllers/challenges/edit-concepts-format", ["exports", "innowaze-ember2/utils/sortByPosition"], function (_exports, _sortByPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedFormats: Ember.computed("model.concept_formats", function () {
      return this.get("model.concept_formats").sort(_sortByPosition.default);
    }),
    sortedTools: Ember.computed("model.concept_tools", function () {
      return this.get("model.concept_tools").sort(_sortByPosition.default);
    }),
    sortedAnalyses: Ember.computed("model.concept_analyses", function () {
      return this.get("model.concept_analyses").sort(_sortByPosition.default);
    })
  });

  _exports.default = _default;
});