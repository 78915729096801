define("innowaze-ember2/templates/components/similar-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z8+qjCAa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"data-dropdown\",[28,[\"drop-\",[27,\"unbound\",[[23,[\"idea\",\"_source\",\"id\"]]],null]]]],[11,\"data-options\",\"align:left;is_hover:true;\"],[9],[0,\"\\n  \"],[1,[21,\"grade\"],true],[0,\" \"],[1,[23,[\"idea\",\"_source\",\"name\"]],false],[0,\" \"],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[3,\"action\",[[22,0,[]],\"notRelated\",[23,[\"idea\"]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"data-dropdown-content\",\"\"],[12,\"id\",[28,[\"drop-\",[27,\"unbound\",[[23,[\"idea\",\"_source\",\"id\"]]],null]]]],[11,\"class\",\"f-dropdown content large\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"ideas.show\",[23,[\"idea\",\"_source\",\"challenge\",\"id\"]],[23,[\"idea\",\"_source\",\"id\"]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[0,\"    \"],[1,[23,[\"idea\",\"_source\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[1,[23,[\"idea\",\"_source\",\"description\"]],true],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/similar-idea.hbs"
    }
  });

  _exports.default = _default;
});