define("innowaze-ember2/templates/challenges/modules/-invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QaGH7JpT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dashboard-primary-buttons\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"Chaîne de solidarité\"],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n    \"],[7,\"img\"],[11,\"style\",\"width:50%;margin-bottom: 14px;\"],[11,\"src\",\"/assets/images/refer.png\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"challenges.invitations\",[23,[\"model\",\"id\"]]],[[\"class\"],[\"button-primary new-idea\"]],{\"statements\":[[0,\"      \"],[1,[23,[\"module\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-invitation.hbs"
    }
  });

  _exports.default = _default;
});