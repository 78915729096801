define("innowaze-ember2/controllers/challenges/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    availableTypologies: Ember.computed("session.configuration.image_paths", function () {
      var paths = this.get("session.configuration.image_paths");
      return ["image", "video", "audio", "document", "other"].map(function (type) {
        return Ember.Object.create({
          name: type,
          logo: paths[type],
          selected: false
        });
      });
    }),
    matchingUploads: []
  });

  _exports.default = _default;
});