define("innowaze-ember2/templates/homepage/-universes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1x8v6wDE",
    "block": "{\"symbols\":[\"univ\"],\"statements\":[[7,\"section\"],[11,\"class\",\"UniverseCards\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"session\",\"sortedUniverses\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"UniverseCard \",[27,\"if\",[[27,\"eq\",[[23,[\"session\",\"currentUser\",\"default_universe_id\"]],[22,1,[\"id\"]]],null],\"UniverseCard--current\",\"\"],null]]]],[9],[0,\"\\n\"],[4,\"link-to\",[\"universes.show\",[22,1,[\"id\"]]],[[\"class\",\"style\"],[\"UniverseCard__content\",[22,1,[\"backgroundStyle\"]]]],{\"statements\":[[0,\"        \"],[4,\"if\",[[27,\"eq\",[[23,[\"session\",\"currentUser\",\"default_universe_id\"]],[22,1,[\"id\"]]],null]],null,{\"statements\":[[7,\"i\"],[11,\"class\",\"fa fa-star UniverseCard__isCurrent\"],[9],[10]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"UniverseCard__title\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"UniverseCard__desc\"],[9],[1,[27,\"truncate\",[[22,1,[\"short_description\"]],150],null],true],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/homepage/-universes.hbs"
    }
  });

  _exports.default = _default;
});