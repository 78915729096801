define("innowaze-ember2/components/delayed-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      $(document).foundation("reveal");
    },
    modalId: Ember.computed("url", function () {
      var id = this.url.replace(/[/?=&.:]/g, "-");
      return "downloadModal".concat(id);
    }),
    lastUpdatedTitle: Ember.computed("lastUpdated", function () {
      return "last version: ".concat(moment(this.lastUpdated).fromNow());
    }),
    message: Ember.computed(function () {
      return "Vous allez recevoir le fichier sur votre email";
    }),
    actions: {
      open: function open() {
        var _this = this;

        this.store.adapterFor("application").ajax(this.url).catch(function () {
          return _this.set("message", "Vous n'avez pas le droit d'accéder à cette ressource");
        }).finally(function () {
          return $("#".concat(_this.modalId)).foundation("reveal", "open");
        });
      },
      cancel: function cancel() {
        $("#".concat(this.modalId)).foundation("reveal", "close");
      }
    }
  });

  _exports.default = _default;
});