define("innowaze-ember2/components/modules/stage-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    actions: {
      setDeploymentDate: function setDeploymentDate(date) {
        this.model.set("deployment_date", date);
      }
    }
  });

  _exports.default = _default;
});