define("innowaze-ember2/components/modules/replicators-module-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    replicating: Ember.computed("model.replicators.[]", function () {
      var _this = this;

      if (!this.get("model.replicators")) {
        return false;
      }

      return this.get("model.replicators").any(function (user) {
        return user.id === _this.get("session.currentUser.id");
      });
    }),
    actions: {
      toggleReplicate: function toggleReplicate(user) {
        var _this2 = this;

        this.model.toggleReplicate().then(function () {
          _this2.toggleProperty("replicating");

          if (_this2.replicating) {
            _this2.get("model.replicators").addObject(user);

            _this2.sendAction("onReplicate");
          } else {
            var replicator = _this2.get("model.replicators").findBy("id", user.id);

            _this2.get("model.replicators").removeObject(replicator);
          }
        }, function () {
          return _this2.notifications.error(_this2.locale.t("ideas.show.contribute_error"));
        });
      }
    }
  });

  _exports.default = _default;
});