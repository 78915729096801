define("innowaze-ember2/templates/departments/modules/-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lSLy/caS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"users.modules.skills.title\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"page-module \",[23,[\"view\",\"validity\"]]]]],[9],[0,\"\\n  \"],[1,[27,\"ember-selectize\",null,[[\"multiple\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\",\"content\",\"selection\",\"create-item\",\"update-filter\"],[true,\"content.id\",\"content.name\",[27,\"i18n\",[\"users.modules.skills.tags\"],null],[23,[\"tagsToDisplay\"]],[23,[\"model\",\"tags\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"newTag\"],null],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"autocomplete\",[27,\"hash\",null,[[\"collectionToPopulate\",\"modelName\"],[[23,[\"tagsToDisplay\"]],\"tag\"]]]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-tags.hbs"
    }
  });

  _exports.default = _default;
});