define("innowaze-ember2/components/concepts-canevas-label-value-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get("label.formula.selectedFormula")) {
        switch (this.get("label.kind")) {
          case "cost":
            this.set("label.formula.selectedFormula", this.get("costFormulas.firstObject"));
            break;

          case "revenue":
            this.set("label.formula.selectedFormula", this.get("revenueFormulas.firstObject"));
            break;

          case "investment":
            this.set("label.formula.selectedFormula", this.get("investmentFormulas.firstObject"));
            break;
        }
      }

      if (!this.get("label.formula.selectedValueProposal") && this.get("label.kind") === "revenue") {
        this.set("label.formula.selectedValueProposal", this.get("valueProposalLabels.firstObject"));
      }
    },
    actions: {
      saveValue: function saveValue() {
        this.sendAction.apply(this, ["saveValue"].concat(Array.prototype.slice.call(arguments)));
      },
      addFormulaItem: function addFormulaItem() {
        this.sendAction.apply(this, ["addFormulaItem"].concat(Array.prototype.slice.call(arguments)));
      },
      setFormulaItemValue: function setFormulaItemValue() {
        this.sendAction.apply(this, ["setFormulaItemValue"].concat(Array.prototype.slice.call(arguments)));
      },
      setFormulaItemPeriod: function setFormulaItemPeriod() {
        this.sendAction.apply(this, ["setFormulaItemPeriod"].concat(Array.prototype.slice.call(arguments)));
      },
      removeFormulaItem: function removeFormulaItem() {
        this.sendAction.apply(this, ["removeFormulaItem"].concat(Array.prototype.slice.call(arguments)));
      },
      addFormulaMarket: function addFormulaMarket() {
        this.sendAction.apply(this, ["addFormulaMarket"].concat(Array.prototype.slice.call(arguments)));
      },
      setFormulaMarketValue: function setFormulaMarketValue() {
        this.sendAction.apply(this, ["setFormulaMarketValue"].concat(Array.prototype.slice.call(arguments)));
      },
      setFormulaMarketPeriod: function setFormulaMarketPeriod() {
        this.sendAction.apply(this, ["setFormulaMarketPeriod"].concat(Array.prototype.slice.call(arguments)));
      },
      removeFormulaMarket: function removeFormulaMarket() {
        this.sendAction.apply(this, ["removeFormulaMarket"].concat(Array.prototype.slice.call(arguments)));
      },
      setFormula: function setFormula(formula) {
        this.set("label.formula.selectedFormula", formula);
      },
      setValueProposal: function setValueProposal(value) {
        this.set("label.formula.selectedValueProposal", value);
      }
    },
    needsSimpleForm: Ember.computed("label.kind", function () {
      return ["market", "driver"].includes(this.get("label.kind"));
    }),
    costFormulas: Ember.computed.filterBy("formulas", "kind", "cost"),
    revenueFormulas: Ember.computed.filterBy("formulas", "kind", "revenue"),
    investmentFormulas: Ember.computed.filterBy("formulas", "kind", "investment"),
    costLabels: Ember.computed.filter("labels", function (label) {
      return ["activity", "resource", "value_proposal", "other"].includes(label.get("kind"));
    }),
    marketLikeLabels: Ember.computed.filter("labels", function (label) {
      return ["market", "driver"].includes(label.get("kind"));
    }),
    investmentsLabels: Ember.computed.filter("labels", function (label) {
      return ["activity", "resource"].includes(label.get("kind"));
    }),
    valueProposalLabels: Ember.computed.filterBy("labels", "kind", "value_proposal"),
    marketLabels: Ember.computed.filterBy("labels", "kind", "market"),
    selectedFormula: Ember.computed.alias("label.formula.selectedFormula"),
    selectedValueProposal: Ember.computed.alias("label.formula.selectedValueProposal"),
    valueChanged: Ember.observer("label.value", function () {
      if (this.get("label.kind") === "market") {
        this.sendAction("marketValueChanged", this.label);
      }
    })
  });

  _exports.default = _default;
});