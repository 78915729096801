define("innowaze-ember2/routes/challenges/documents", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/utils/fileType"], function (_exports, _setCurrentChallenge, _fileType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id)
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        matchingUploads: [],
        didReachEndOfList: false,
        page: 1,
        query: ""
      });

      this._loadData();
    },
    actions: {
      sort: function sort(criteria) {
        this.controller.setProperties({
          order: criteria,
          matchingUploads: [],
          didReachEndOfList: false,
          page: 1,
          isLoading: true
        });

        this._loadData();
      },
      search: function search() {
        this.controller.setProperties({
          page: 1,
          didReachEndOfList: false,
          isLoading: true
        });

        this._loadData();
      },
      loadMore: function loadMore() {
        this.controller.incrementProperty("page");
        this.controller.set("isLoadingMore", true);

        this._loadData();
      },
      displayLink: function displayLink(link) {
        this.controller.set("link", "".concat(this.get("session.apiUrl")).concat(link));
        $("#linkModal").foundation("reveal", "open");
      },
      editDocument: function editDocument(file) {
        this.send("toggleDocumentEditForm");
        this.set("controller.currentDocument", file);
      },
      saveDocument: function saveDocument() {
        var _this = this;

        this.get("controller.currentDocument").save().then(function () {
          _this.send("toggleDocumentEditForm");
        });
      },
      deleteDocument: function deleteDocument(document) {
        var _this2 = this;

        if (!confirm(this.locale.t("challenges.show.confirm_delete_document"))) {
          return;
        }

        document.destroyRecord().then(function () {
          _this2.get("controller.matchingUploads").removeObject(document);

          _this2.notifications.success(_this2.locale.t("challenges.show.document_deleted"));
        });
      },
      filesUploaded: function filesUploaded(file) {
        var type = (0, _fileType.default)(file.file_name);
        var upload = this.store.createRecord("upload", {
          id: file.id,
          file_name: file.file_name,
          parent_name: this.get("controller.newDocumentName"),
          file_url: file.url,
          remote_url: file.remote_url,
          attached_to_challenge: true,
          visible_in_widget: true,
          size: file.size,
          is_image: type === "image",
          fileType: type
        });
        this.get("controller.matchingUploads").unshiftObject(upload);
        this.controller.set("documentFormVisible", false);
        this.controller.set("newDocumentName", "");
      },
      toggleDocumentForm: function toggleDocumentForm() {
        this.toggleProperty("controller.documentFormVisible");
      },
      toggleDocumentEditForm: function toggleDocumentEditForm() {
        this.toggleProperty("controller.documentEditFormVisible");
        this.set("controller.selectedDocument", null);
      },
      toggleVisibleInWidget: function toggleVisibleInWidget(upload) {
        upload.toggleVisibleInWidget().then(function () {
          upload.toggleProperty("visible_in_widget");
        });
      },
      selectTypology: function selectTypology(typology) {
        this.get("controller.availableTypologies").setEach("selected", false);
        this.controller.set("didReachEndOfList", false);

        if (this.get("controller.selectedTypology") === typology.name) {
          this.set("controller.selectedTypology", undefined);
        } else {
          this.set("controller.selectedTypology", typology.get("name"));
          typology.set("selected", true);
        }

        this.send("search");
      }
    },
    _loadData: function _loadData() {
      var _this3 = this;

      var options = {
        challenge: this.currentModel.challenge.id
      };

      if (this.get("controller.query")) {
        options.query = this.get("controller.query");
      }

      if (this.get("controller.order")) {
        options.order = this.get("controller.order");
      }

      if (this.get("controller.selectedTypology")) {
        options.type = this.get("controller.selectedTypology");
      }

      if (this.get("controller.page")) {
        options.page = this.get("controller.page");
      }

      this.store.query("upload", options).then(function (uploads) {
        if (uploads.get("length") < 10) {
          _this3.controller.set("didReachEndOfList", true);
        }

        if (_this3.get("controller.isLoadingMore")) {
          _this3.get("controller.matchingUploads").pushObjects(uploads.toArray());

          _this3.controller.set("isLoadingMore", false);
        } else {
          _this3.controller.setProperties({
            matchingUploads: uploads.toArray(),
            isLoading: false
          });
        }
      });
    }
  });

  _exports.default = _default;
});