define("innowaze-ember2/routes/concepts/common", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _setCurrentChallenge, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _selectAutocomplete.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    controllerName: "concepts/edit",
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("routeIsReadyForAction", false);
      controller.setProperties({
        availableTriggers: this.store.findAll("tag-trigger"),
        availableKnowledges: this.store.findAll("knowledge"),
        availableDomains: this.store.query("domain", {
          challenge_id: model.challenge.id
        }),
        tagsToDisplay: []
      });
      this.store.query("tag", {
        supertags: true,
        challenge_id: model.challenge.id
      }).then(function (supertag) {
        controller.set("tagsToDisplay", model.concept.get("tags").toArray().concat(supertag.toArray()).uniqBy("name"));
        controller.set("routeIsReadyForAction", true);
      });
    },
    resetController: function resetController(controller) {
      controller.set("routeIsReadyForAction", false);
    },
    deactivate: function deactivate() {
      if (this.currentModel.concept.get("hasDirtyAttributes")) {
        this.currentModel.concept.rollbackAttributes();
      }
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      addTag: function addTag(tag) {
        tag = tag.toLowerCase();
        var tagObject = this.store.peekAll("tag").findBy("name", tag);

        if (!tagObject) {
          tagObject = this.store.createRecord("tag", {
            name: tag,
            id: tag
          });
        }

        this.currentModel.concept.get("tags").pushObject(tagObject);
      },
      addKnowledge: function addKnowledge(knowledge) {
        var originalKnowledge = this.get("controller.availableKnowledges").findBy("id", knowledge._id);
        this.currentModel.concept.get("knowledges").addObject(originalKnowledge);
      },
      save: function save() {
        var _this = this;

        this.controller.set("saveDisabled", true);
        this.currentModel.concept.save({
          adapterOptions: {
            challenge_id: this.currentModel.concept.get("challenge_id")
          }
        }).then(function (data) {
          return _this._success(data.id);
        }, function (errors) {
          return _this._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("concepts.show", this.currentModel.challenge.id, this.currentModel.concept.id);
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.concept.setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      },
      documentsUploaded: function documentsUploaded(files) {
        var _this2 = this;

        files.forEach(function (file) {
          _this2.currentModel.concept.get("documents").pushObject({
            id: file.id,
            name: file.file_name,
            file_name: file.file_name,
            url: file.file_url
          });
        });
        this.currentModel.concept.send("becomeDirty");
      },
      removeDocument: function removeDocument(doc) {
        this.currentModel.concept.get("documents").removeObject(doc);
      },
      updateDocument: function updateDocument(id) {
        var _this$currentModel$co = this.currentModel.concept.get("documents").findBy("id", id),
            name = _this$currentModel$co.name;

        if (!name) {
          return;
        }

        var adapter = this.store.adapterFor("application");
        adapter.ajax("".concat(adapter.host, "/").concat(adapter.namespace, "/uploads/").concat(id, ".json"), "PUT", {
          data: {
            name: name
          }
        });
      },
      picturesUploaded: function picturesUploaded(files) {
        var pictures = this.currentModel.concept.get("pictures");

        for (var _i = 0, _Array$from = Array.from(files); _i < _Array$from.length; _i++) {
          var file = _Array$from[_i];
          pictures.pushObject(this.store.createRecord("upload", {
            id: file.id,
            name: file.file_name,
            url: file.file_url,
            badges: []
          }));
        }

        this.currentModel.concept.send("becomeDirty");
      },
      newTag: function newTag(input) {
        var newTag = this.store.createRecord("tag", {
          name: input,
          id: input
        });
        this.get("controller.tagsToDisplay").pushObject(newTag);
        this.currentModel.concept.get("tags").pushObject(newTag);
      },
      setKnowledges: function setKnowledges(knowledges) {
        this.currentModel.concept.set("knowledges", knowledges);
      },
      setDomains: function setDomains(domains) {
        this.currentModel.concept.set("domains", domains);
      },
      setTeammates: function setTeammates(teammates) {
        this.currentModel.concept.set("teammates", teammates);
      },
      setAuthor: function setAuthor(author) {
        this.currentModel.concept.set("author", author);
      },
      makeDirty: function makeDirty() {
        this.currentModel.concept.send("becomeDirty");
      }
    }
  });

  _exports.default = _default;
});