define("innowaze-ember2/templates/departments/partials/-presentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I3mylct8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"slide_url\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"embed-slide\",null,[[\"slideUrl\"],[[23,[\"model\",\"slide_url\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/partials/-presentation.hbs"
    }
  });

  _exports.default = _default;
});