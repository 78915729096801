define("innowaze-ember2/routes/challenge-badges/new", ["exports", "innowaze-ember2/routes/challenge-badges/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id, {
          backgroundReload: false
        }),
        badge: this.store.createRecord("badge", {
          challenge_id: params.challenge_id
        })
      });
    },
    _success: function _success() {
      this.transitionTo("challengeBadges", this.currentModel.badge.get("challenge_id"));
      this.notifications.success(this.locale.t("news.created"));
    }
  });

  _exports.default = _default;
});