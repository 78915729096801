define("innowaze-ember2/templates/users/edit-modules/-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nny/CjTA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"modules\",\"email\",\"private\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title has-tip\"],[12,\"title\",[28,[[27,\"i18n\",[\"users.modules.is_private\"],null]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-eye-slash\"],[9],[10],[0,\"\\n     \\n    \"],[1,[27,\"i18n\",[\"users.modules.email.title\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"displayRequired\"]]],null,{\"statements\":[[0,\"      \"],[7,\"sup\"],[11,\"class\",\"red\"],[9],[0,\" *\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title has-tip\"],[12,\"title\",[28,[[27,\"i18n\",[\"users.modules.is_public\"],null]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-eye\"],[9],[10],[0,\"\\n     \\n    \"],[1,[27,\"i18n\",[\"users.modules.email.title\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"displayRequired\"]]],null,{\"statements\":[[0,\"      \"],[7,\"sup\"],[11,\"class\",\"red\"],[9],[0,\" *\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"large-4 columns\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"Email\"],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"readonly\",\"placeholder\"],[\"email\",[23,[\"model\",\"fields\",\"email\"]],[23,[\"model\",\"id\"]],[27,\"i18n\",[\"users.modules.main_info.email\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/edit-modules/-email.hbs"
    }
  });

  _exports.default = _default;
});