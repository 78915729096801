define("innowaze-ember2/routes/preferences", ["exports", "innowaze-ember2/utils/getErrorArray"], function (_exports, _getErrorArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      return this.session.currentUser;
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.set("availableFrequencies", [{
        name: this.locale.t("digest_frequencies.never"),
        frequency: 0
      }, {
        name: this.locale.t("digest_frequencies.realtime"),
        frequency: 900
      }, {
        name: this.locale.t("digest_frequencies.daily"),
        frequency: 86400
      }, {
        name: this.locale.t("digest_frequencies.weekly"),
        frequency: 604800
      }, {
        name: this.locale.t("digest_frequencies.monthly"),
        frequency: 2592000
      }]);
      controller.set("availableDefaultFrequencies", [{
        name: this.locale.t("digest_frequencies.never"),
        frequency: 0
      }, {
        name: this.locale.t("digest_frequencies.realtime"),
        frequency: 900
      }, {
        name: this.locale.t("digest_frequencies.daily"),
        frequency: 86400
      }, {
        name: this.locale.t("digest_frequencies.weekly"),
        frequency: 604800
      }, {
        name: this.locale.t("digest_frequencies.monthly"),
        frequency: 2592000
      }, {
        name: this.locale.t("digest_frequencies.depending"),
        frequency: 999999
      }]);

      this._checkFrequencies(controller, model);

      var route = this;
      controller.reopen({
        oneFrequencyDidChange: Ember.observer("model.digest_frequencies.@each.frequency", "model.optout", function () {
          route._checkFrequencies(controller, model);
        }),
        selectedEventChanged: Ember.observer("selectedEventFrequency", function () {
          if (controller.selectedEventFrequency !== 999999) {
            model.get("eventNotifications").setEach("frequency", controller.selectedEventFrequency);
          }
        }),
        selectedContributionChanged: Ember.observer("selectedContributionFrequency", function () {
          if (controller.selectedContributionFrequency !== 999999) {
            model.get("contributionNotifications").setEach("frequency", controller.selectedContributionFrequency);
          }
        }),
        selectedModificationChanged: Ember.observer("selectedModificationFrequency", function () {
          if (controller.selectedModificationFrequency !== 999999) {
            model.get("modificationNotifications").setEach("frequency", controller.selectedModificationFrequency);
          }
        }),
        nothingChanged: Ember.observer("nothing", function () {
          if (controller.nothing) {
            model.get("digest_frequencies").setEach("frequency", 0);
            model.set("optout", true);
          }
        })
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function () {
          _this.notifications.success(_this.locale.t("users.edit.preferences_updated"));
        }).catch(function (response) {
          _this.notifications.errorWithList(_this.locale.t("modification_error"), (0, _getErrorArray.default)(response));
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      }
    },
    _checkFrequencies: function _checkFrequencies(c, m) {
      var eventFrequencies = m.get("eventNotifications").mapBy("frequency");
      var contributionFrequencies = m.get("contributionNotifications").mapBy("frequency");
      var modificationFrequencies = m.get("modificationNotifications").mapBy("frequency");
      var allFrequencies = m.digest_frequencies.mapBy("frequency");

      if (eventFrequencies.every(function (val, i, arr) {
        return val === arr[0];
      })) {
        c.set("selectedEventFrequency", eventFrequencies[0]);
      } else {
        c.set("selectedEventFrequency", 999999);
      }

      if (contributionFrequencies.every(function (val, i, arr) {
        return val === arr[0];
      })) {
        c.set("selectedContributionFrequency", contributionFrequencies[0]);
      } else {
        c.set("selectedContributionFrequency", 999999);
      }

      if (modificationFrequencies.every(function (val, i, arr) {
        return val === arr[0];
      })) {
        c.set("selectedModificationFrequency", modificationFrequencies[0]);
      } else {
        c.set("selectedModificationFrequency", 999999);
      }

      c.set("nothing", allFrequencies.every(function (val, i, arr) {
        return val === 0;
      }) && m.optout);
    }
  });

  _exports.default = _default;
});