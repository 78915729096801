define("innowaze-ember2/helpers/formatted-date", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    locale: Ember.inject.service(),
    handleLocaleChange: Ember.observer("locale.currentLocale", function () {
      this.recompute();
    }),
    compute: function compute(_ref, _ref2) {
      var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
          date = _ref3[0];

      var _ref2$format = _ref2.format,
          format = _ref2$format === void 0 ? "default" : _ref2$format;

      if (typeof date === "undefined" || !date) {
        return;
      }

      return this.locale.l("date.formats.".concat(format), date);
    }
  });

  _exports.default = _default;
});