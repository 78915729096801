define("innowaze-ember2/helpers/eq", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eq = eq;
  _exports.default = void 0;

  function eq(_ref, options) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        left = _ref2[0],
        right = _ref2[1];

    options = options || {
      coerce: false
    };
    /* eslint-disable eqeqeq */

    return options.coerce ? left == right : left === right;
    /* eslint-enable eqeqeq */
  }

  var _default = Ember.Helper.helper(eq);

  _exports.default = _default;
});