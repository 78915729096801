define("innowaze-ember2/components/document-list", ["exports", "innowaze-ember2/utils/fileType"], function (_exports, _fileType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    createDocument: function createDocument(document) {
      return Ember.Object.create({
        id: document.id,
        url: document.url,
        caption: document.name
      });
    },
    images: Ember.computed("documents.[]", function () {
      return this.documents.filter(function (document) {
        return (0, _fileType.default)(document.file_name) === "image";
      }).map(this.createDocument);
    }),
    pdfs: Ember.computed("documents.[]", function () {
      return this.documents.filter(function (document) {
        return /\.(pdf)$/i.test(document.file_name);
      }).map(this.createDocument);
    }),
    videos: Ember.computed("documents.[]", function () {
      return this.documents.filter(function (document) {
        return (0, _fileType.default)(document.file_name) === "video";
      }).map(this.createDocument);
    }),
    audios: Ember.computed("documents.[]", function () {
      return this.documents.filter(function (document) {
        return (0, _fileType.default)(document.file_name) === "audio";
      }).map(this.createDocument);
    }),
    otherDocuments: Ember.computed("documents.[]", "images", "pdfs", "videos", "audios", function () {
      var _this = this;

      return this.documents.filter(function (d) {
        return _this.images.findBy("id", d.id) === undefined;
      }).filter(function (d) {
        return _this.pdfs.findBy("id", d.id) === undefined;
      }).filter(function (d) {
        return _this.videos.findBy("id", d.id) === undefined;
      }).filter(function (d) {
        return _this.audios.findBy("id", d.id) === undefined;
      }).map(this.createDocument);
    })
  });

  _exports.default = _default;
});