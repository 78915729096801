define("innowaze-ember2/components/ember-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    content: [],
    selectedValue: null,
    nullValue: null,
    prompt: null,
    disabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.content) {
        this.set("content", []);
      }
    },
    actions: {
      change: function change() {
        var changeAction = this.action;
        var selectedIndex = this.$("select")[0].selectedIndex;
        var content = this.content;
        var contentIndex = this.prompt ? selectedIndex - 1 : selectedIndex;
        var optionValuePath = this.optionValuePath;
        var selectedValue;

        if (this.prompt && selectedIndex === 0) {
          selectedValue = this.nullValue;
        } else {
          selectedValue = optionValuePath ? content[contentIndex][optionValuePath] : content[contentIndex];
        }

        this.set("selectedValue", selectedValue);

        if (typeof changeAction === "function") {
          changeAction(selectedValue);
        }
      }
    }
  });

  _exports.default = _default;
});