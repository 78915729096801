define("innowaze-ember2/routes/news-department/new", ["exports", "innowaze-ember2/routes/news-instance/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    templateName: "news/new",
    model: function model(params) {
      return this.store.createRecord("news", {
        newsable_id: params.department_id,
        newsable_type: "Department"
      });
    },
    successMessage: Ember.computed(function () {
      return this.locale.t("news.created");
    }),
    errorMessage: Ember.computed(function () {
      return this.locale.t("creation_error");
    })
  });

  _exports.default = _default;
});