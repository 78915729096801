define("innowaze-ember2/mixins/controllers/userTags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    availableTags: Ember.computed("matchingUsers.[]", function () {
      return this.matchingUsers.reduce(function (tags, user) {
        tags.pushObjects(user.get("preload_tags").toArray());
        return tags;
      }, []);
    }),
    availableTagsById: Ember.computed("availableTags.[]", function () {
      /*
       * Formats the tags to index them by id and give just their name
       * in the form: { 42: "The Answer" }
       * To be able to display the tag names in the templates from the ids
       * in the User data (with the get helper)
       * {{get availableTagsById (concat "" tagId)}}
       *
       * Remember that the get helper accept only string, hence the concat
       */
      return this.availableTags.reduce(function (tags, tag) {
        tags[tag.id.toString()] = tag.get("name");
        return tags;
      }, {});
    })
  });

  _exports.default = _default;
});