define("innowaze-ember2/templates/departments/partials/-scope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XIbGGj8G",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"geographicalPresence\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"departments.scope\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"keyword-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"geographicalPresence\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[1,[22,1,[]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/partials/-scope.hbs"
    }
  });

  _exports.default = _default;
});