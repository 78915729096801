define("innowaze-ember2/components/words-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service("locale"),
    className: "words-cloud",
    attributeBindings: Ember.String.w("width height angle"),
    dataLoaded: false,
    draw: Ember.observer("data.[]", function () {
      if (this.dataLoaded) {
        d3.select("#" + this.elementId).selectAll("svg").remove();
        this.createCloud();
      }
    }),
    didInsertElement: function didInsertElement() {
      this.createCloud();
    },
    createCloud: function createCloud() {
      var containerWidth = $("#" + this.elementId).width();
      var width = this.width ? Math.min(containerWidth, this.width) : containerWidth;
      var height = this.height || width * 0.8;
      var fill = d3.scale.category20();
      var word_entries = this.data;
      var angle = this.angle || 90;
      var xScale = d3.scale.linear().domain([0, d3.max(word_entries, function (d) {
        return d.value;
      })]).range([10, 95]);
      var anchor = d3.select("#".concat(this.elementId));

      function draw(words) {
        anchor.append("svg").attr("width", width).attr("height", height).attr("class", "words-cloud").append("g").attr("transform", "translate(" + [width >> 1, height >> 1] + ")").selectAll("text").data(words).enter().append("text").style("font-size", function (d) {
          return xScale(d.value) + "px";
        }).style("font-family", "Impact").style("fill", function (d, i) {
          return fill(i);
        }).attr("text-anchor", "middle").attr("transform", function (d) {
          return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
        }).text(function (d) {
          return d.key;
        });
      }

      d3.layout.cloud().size([width, height]).timeInterval(20).words(word_entries).fontSize(function (d) {
        return xScale(+d.value);
      }).text(function (d) {
        return d.key;
      }).rotate(function () {
        return ~~(Math.random() * 2) * angle;
      }).rotate(function () {
        if (angle <= 45) {
          return (~~(Math.random() * 6) - 3) * angle;
        }

        return ~~(Math.random() * 2) * angle;
      }).font("Impact").on("end", draw).start();
      d3.layout.cloud().stop();
    }
  });

  _exports.default = _default;
});