define("innowaze-ember2/templates/users/modules/-uses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vtWVfvKe",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"useTypes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"user-profile__skills-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"users.modules.uses.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"uses keyword-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"useTypes\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"uses\"],[9],[0,\"\\n        \"],[7,\"img\"],[12,\"src\",[28,[[27,\"asset-path\",[[27,\"concat\",[\"/assets/images/uses/\",[22,1,[]],\".png\"],null]],null]]]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/modules/-uses.hbs"
    }
  });

  _exports.default = _default;
});