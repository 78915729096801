define("innowaze-ember2/components/state-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    classNames: "idea-state-selector",
    draft: Ember.computed.equal("model.state", "draft"),
    formulated: Ember.computed.equal("model.state", "formulated"),
    published: Ember.computed.equal("model.state", "published"),
    documented: Ember.computed.equal("model.state", "documented"),
    to_be_documented: Ember.computed.equal("model.state", "to_be_documented"),
    evaluated: Ember.computed.equal("model.state", "evaluated"),
    to_be_evaluated: Ember.computed.equal("model.state", "to_be_evaluated"),
    completed: Ember.computed.equal("model.state", "completed"),
    selected: Ember.computed.equal("model.state", "selected"),
    rejected: Ember.computed.equal("model.state", "rejected"),
    to_be_pitched: Ember.computed.equal("model.state", "to_be_pitched")
  });

  _exports.default = _default;
});