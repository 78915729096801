define("innowaze-ember2/components/molecule-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: "div",
    classNames: "molecule-details",
    didInsertElement: function didInsertElement() {
      this.rootElement = d3.select("#".concat(this.elementId));
      this.atomsInMolecule = this.get("molecule.root") ? this.get("molecule.children") : this.get("molecule.parents");
      this.addTitle();
      this.createSVG();
      this.createGradients();
      this.addTooltip();
    },
    addTitle: function addTitle() {
      return this.rootElement.insert("a").html("<h4><i class='icon-graph-list'></i>\n          ".concat(this.get("molecule.name"), "\n        </h4>")).attr("href", "/challenges/".concat(this.get("molecule.challenge_id"), "/ideas/").concat(this.get("molecule.id")));
    },
    addIdeasLinks: function addIdeasLinks() {
      var _this = this;

      this.atomsInMolecule.rejectBy("id", this.get("currentIdea.id")).forEach(function (idea) {
        _this.rootElement.text(idea.name).attr("href", "/challenges/".concat(idea.challenge_id, "/ideas/").concat(idea.id));
      });
    },
    addTooltip: function addTooltip() {
      var _this2 = this;

      this.tooltip = $("<div>").addClass("tooltip").css("display", "none").insertBefore(this.$());
      this.tooltip.on("click", "i.js-close", function () {
        return _this2.tooltip.css("display", "none");
      });
    },
    createSVG: function createSVG() {
      this.svg = this.rootElement.append("svg");
      this.svg.attr("width", this.width).attr("height", this.height);
      this.addPane();
      this.buildMolecule();
    },
    addPane: function addPane() {
      this.pane = this.svg.append("g").attr("class", "molecule");
      this.pane.append("rect").attr("width", this.width).attr("height", this.height);
    },
    buildMolecule: function buildMolecule() {
      var _this3 = this;

      var links = this.atomsInMolecule.without(this.atomsInMolecule[0]).map(function (atom, index) {
        return {
          source: 0,
          target: index + 1
        };
      });
      d3.layout.force().nodes(this.atomsInMolecule).links(links).size([this.width, this.height]).charge(-120).gravity(0.1).linkDistance(80).on("tick", this.tick).start();
      var linkNodes = this.pane.selectAll(".link").data(links);
      linkNodes.enter().insert("line", "g.idea").attr("class", "link");
      linkNodes.exit().remove();
      var nodes = this.pane.selectAll("g.idea").data(this.atomsInMolecule);
      var g = nodes.enter().append("svg:g").attr("class", "idea").attr("transform", this.ideaTransform).classed("main", function (d) {
        return _this3.get("currentIdea.id") === d.id;
      });
      g.append("circle").attr("r", this.ideaRadius).style("fill", function (idea) {
        return "url(#gradientRadial-".concat(idea.id, ")");
      });
      g.append("text").attr("dy", "-.8em").style("text-anchor", "middle").attr("class", function (d) {
        return d.index === 0 ? "bubble_text" : "tiny_bubble_text";
      }).text(function (d) {
        return d.name.substring(0, 8);
      });
      g.append("text").attr("dy", ".4em").attr("class", function (d) {
        return d.index === 0 ? "bubble_text" : "tiny_bubble_text";
      }).text(function (d) {
        return d.name.length > 8 ? d.name.substring(10, 18) : "";
      });
      g.append("text").attr("dy", "1.4em").attr("class", function (d) {
        return d.index === 0 ? "bubble_text" : "tiny_bubble_text";
      }).text(function (d) {
        return d.name.length > 18 ? d.name.substring(18, 26) : "";
      });
      g.on("click", this.showTooltip.bind(this));
      return nodes.exit().remove();
    },
    tick: function tick() {
      d3.selectAll(".link").attr("x1", function (d) {
        return d.source.x;
      }).attr("y1", function (d) {
        return d.source.y;
      }).attr("x2", function (d) {
        return d.target.x;
      }).attr("y2", function (d) {
        return d.target.y;
      });
      return d3.selectAll("g.idea").attr("transform", function (d) {
        return "translate(".concat(d.x, ", ").concat(d.y, ")");
      });
    },
    ideaRadius: function ideaRadius(idea) {
      return idea.index === 0 ? 40 : 26;
    },
    ideaTransform: function ideaTransform(idea) {
      return "translate(".concat(idea.x, ", ").concat(idea.y, ")");
    },
    showTooltip: function showTooltip(idea) {
      var content = "\n    <header>\n      <i class='fa fa-times right js-close'></i>\n      ".concat(idea.logo_url ? "<a href=\"/challenges/".concat(idea.challenge_id, "/ideas/").concat(idea.id, "\" target=\"_blank\"><img src=\"").concat(idea.logo_url, "\" /></a>") : "", "\n      <a href=\"/challenges/").concat(idea.challenge_id, "/ideas/").concat(idea.id, "\" target=\"_blank\">\n        <h1>").concat(idea.name, "<small> - ").concat(idea.id, " - </small></h1>\n      </a>\n      <h2>").concat(idea.author, "</h2>\n    </header>\n    <div style=\"max-height: 400px;overflow:scroll\">\n      ").concat(idea.description, "\n    </div>\n    </br>\n    <footer>\n      <a href=\"/challenges/").concat(idea.challenge_id, "/ideas/").concat(idea.id, "\" target=\"_blank\">\n        ").concat(this.locale.t("open_in_new_window"), "\n      </a>\n    <footer>");
      return this.tooltip.html(content).css("display", "block").css("left", "-200px");
    },
    createGradients: function createGradients() {
      var _this4 = this;

      var fillColor = function fillColor(d) {
        return _this4.get("currentIdea.id") === d.id ? "#B1C800" : "#ffffff";
      };

      this.svg.selectAll("g.gradientContainer").remove();
      var gradientContainer = this.svg.append("g").attr("class", "gradientContainer");
      var gradientRadial = gradientContainer.selectAll("radialGradient").data(this.atomsInMolecule).enter().append("radialGradient").attr("cx", "50%").attr("cy", "50%").attr("r", "50%").attr("fx", "4%").attr("gradientUnits", "objectBoundingBox").attr("id", function (d) {
        return "gradientRadial-".concat(d.id);
      });
      gradientRadial.append("stop").attr("offset", "4%").attr("stop-color", function (d) {
        return d3.rgb(fillColor(d)).brighter(1);
      });
      gradientRadial.append("stop").attr("offset", "40%").attr("stop-color", function (d) {
        return fillColor(d);
      });
      return gradientRadial.append("stop").attr("offset", "100%").attr("stop-color", function (d) {
        return d3.rgb(fillColor(d)).darker(1.75);
      });
    }
  });

  _exports.default = _default;
});