define("innowaze-ember2/templates/components/modules/export-html-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KBX+3LBW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"idea-primary-action\"],[11,\"target\",\"_blank\"],[12,\"href\",[28,[[23,[\"session\",\"apiUrl\"]],\"/ideas/\",[23,[\"model\",\"id\"]],\"/export_as_html\"]]],[12,\"download\",[28,[\"idea-\",[23,[\"model\",\"id\"]],\".pdf\"]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-file-text-o\"],[9],[10],[0,\"\\n    \"],[1,[27,\"i18n\",[\"ideas.show.as_html\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"html-template\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[1,[23,[\"module\",\"params\",\"comment\"]],false],[10],[0,\"\\n    \"],[7,\"a\"],[12,\"href\",[28,[[27,\"unbound\",[[23,[\"module\",\"params\",\"link\"]]],null]]]],[11,\"target\",\"_blank\"],[9],[0,\"click here\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/modules/export-html-module.hbs"
    }
  });

  _exports.default = _default;
});