define("innowaze-ember2/routes/pathways/edit", ["exports", "innowaze-ember2/routes/pathways/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("pathway", params.pathway_id, {
        adapterOptions: {
          challenge_id: params.challenge_id
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
    },
    _success: function _success() {
      this.transitionTo("pathways.show", this.currentModel.get("challenge_id"), this.currentModel.get("id"));
      this.notifications.success("La piste a été modifiée.");
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.locale.t("modification_error"), errors);
    }
  });

  _exports.default = _default;
});