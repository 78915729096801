define("innowaze-ember2/serializers/debate", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      comments: {
        embedded: "always"
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      ["available_answers"].forEach(function (attribute) {
        if (payload[attribute]) {
          payload[attribute] = payload[attribute].map(function (element) {
            return Ember.Object.create(element);
          });
        }
      });
      return this._super.apply(this, arguments);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments); // Override payload format to match the server's expectations


      return {
        debate: json,
        debatable_id: json.debatable_id,
        debatable_type: json.debatable_type
      };
    }
  });

  _exports.default = _default;
});