define("innowaze-ember2/serializers/challenge", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/typeof", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _toConsumableArray2, _typeof2, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var greedyAttrsByRouteName = {
    "challenges.edit_main": ["knowledges", "milestones", "department", "group", "authorized_departments", "public_departments", "dict", "universes"],
    "challenges.edit_showcase": [],
    "challenges.edit_home": ["modules"],
    "challenges.edit_gaming": ["actions", "rules", "liker_departments"],
    "challenges.edit_users_format": ["active_user_modules"],
    "challenges.edit_ideas_format": ["ideas", "tools", "mapSettings"],
    "challenges.edit_ideas_workflow": ["state_machine_rules"],
    "challenges.edit_concepts_format": ["concept_formats", "concept_tools", "concept_analyses"],
    "challenges.edit_concepts_workflow": [],
    "challenges.show": ["pictures"]
  };

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    locale: Ember.inject.service(),
    router: Ember.inject.service(),
    attrs: {
      surveys: {
        deserialize: "records",
        serialize: false
      },
      knowledges: {
        deserialize: "records"
      },
      debates: {
        deserialize: "records",
        serialize: false
      },
      pathways: {
        deserialize: "records",
        serialize: false
      },
      documents: {
        deserialize: "records",
        serialize: false
      },
      badges: {
        deserialize: "records",
        serialize: false
      },
      pictures: {
        embedded: "always"
      },
      universes: {
        deserialize: "records",
        serialize: false
      },
      current_user_juries: {
        deserialize: "records",
        serialize: false
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      ["milestones", "modules", "actions", "rules", "ideas", "tools", "concept_formats", "concept_tools", "concept_analyses", "domains_cloud", "state_machine_rules"].forEach(function (attribute) {
        if (payload[attribute]) {
          payload[attribute] = payload[attribute].map(function (element) {
            return Ember.Object.create(element);
          });
        }
      });
      ["modules", "ideas", "tools", "concept_formats", "concept_tools", "concept_analyses", "state_machine_rules"].forEach(function (attribute) {
        if (payload[attribute]) {
          payload[attribute] = payload[attribute].sortBy("position");
        }
      });
      return this._super.apply(this, arguments);
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the read-only attributes
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      if (snapshot.record.get("isNew")) {
        this._super.apply(this, arguments);

        return;
      } // do not serialize the useless greedy attributes


      if (!this._shouldSerializeGreedyAttr(key) || typeof snapshot.record.get(key) === "undefined") {
        return;
      }

      if (snapshot.changedAttributes()[key] || (0, _typeof2.default)(snapshot.record.get(key)) === "object") {
        this._super.apply(this, arguments);
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (snapshot.record.get("isNew")) {
        this._super.apply(this, arguments);

        return;
      } // do not serialize the useless greedy relationship


      if (!this._shouldSerializeGreedyAttr(relationship.key)) {
        return;
      }

      this._super.apply(this, arguments);
    },
    _shouldSerializeGreedyAttr: function _shouldSerializeGreedyAttr(key) {
      var _ref;

      return !(_ref = []).concat.apply(_ref, (0, _toConsumableArray2.default)(Object.values(greedyAttrsByRouteName))).includes(key) || greedyAttrsByRouteName[this.get("router.currentRouteName")].includes(key);
    },
    serialize: function serialize(_ref2, options) {
      var _this = this;

      var record = _ref2.record;

      var json = this._super.apply(this, arguments);

      if (json.knowledges) {
        json.knowledges = record.get("knowledges").map(function (knowledge) {
          return {
            id: knowledge.id
          };
        });
      }

      if (json.milestones) {
        delete json.milestones;
        json.milestones_attributes = record.get("milestones").map(function (milestone) {
          return milestone.id ? Ember.getProperties(milestone, "id", "name", "starts_on", "ends_on", "position", "description", "starts_on_title", "ends_on_title", "_destroy") : Ember.getProperties(milestone, "name", "starts_on", "ends_on", "position", "description", "starts_on_title", "ends_on_title");
        });
      }

      if (json.modules) {
        json.modules = record.get("modules").map(function (module) {
          return module.getProperties("id", "active", "position", "mapping", "stage", "explanation");
        });
      }

      if (json.actions) {
        json.actions = record.get("actions").map(function (action) {
          return action.getProperties("id", "active", "reward");
        });
      }

      if (json.rules) {
        json.rules = record.get("rules").map(function (rule) {
          return rule.getProperties("id", "active", "reward");
        });
      }

      if (json.active_user_modules) {
        delete json.active_user_modules;
        var currentUserModules = record.get("active_user_modules").map(function (module) {
          return module.user_module_id;
        });
        var mandatoryUserModules = record.get("mandatory_user_modules") || currentUserModules;
        var toAddUserModules = mandatoryUserModules.filter(function (module) {
          return !currentUserModules.includes(module);
        });
        var alreadySetUserModules = [];
        json.active_user_modules_attributes = record.get("active_user_modules").map(function (module) {
          var moduleId = module.user_module_id;

          if (!mandatoryUserModules.includes(moduleId) || alreadySetUserModules.includes(moduleId)) {
            module._destroy = 1;
          } else {
            alreadySetUserModules.push(moduleId);
            delete module._destroy;
          }

          return module;
        });
        toAddUserModules.forEach(function (module) {
          json.active_user_modules_attributes.push({
            user_module_id: module,
            validity: true
          });
        });
      }

      if (json.ideas) {
        json.ideas = record.get("ideas").map(function (idea) {
          return idea.getProperties("id", "active", "position", "explanation", "title", "visible", "control", "is_HTML", "team_only", "stage");
        });
      }

      if (json.tools) {
        json.tools = record.get("tools").map(function (tool) {
          var t = tool.getProperties("id", "active", "position", "title", "visible", "category", "computed", "computed_category", "evaluation_state");

          if (t.category === "" || t.category === null) {
            t.category = _this.locale.t("standard");
          }

          return t;
        });
      }

      if (json.concept_formats) {
        json.concept_formats = record.get("concept_formats").map(function (format) {
          return format.getProperties("id", "title", "active", "position", "visible", "stage");
        });
      }

      if (json.concept_tools) {
        json.concept_tools = record.get("concept_tools").map(function (tool) {
          var t = tool.getProperties("id", "active", "title", "visible", "category", "position");

          if (t.category === "" || t.category === null) {
            t.category = _this.locale.t("standard");
          }

          return t;
        });
      }

      if (json.concept_analyses) {
        json.concept_analyses = record.get("concept_analyses").map(function (analyse) {
          return analyse.getProperties("id", "title", "active", "position", "visible");
        });
      }

      delete json.department;
      json.department_id = record.get("department") ? record.get("department").id : null;

      if (json.group) {
        delete json.group;
        json.challenge_group_id = record.get("group").id;
      }

      if (json.authorized_departments) {
        json.authorized_departments = record.get("authorized_departments").map(function (department) {
          return {
            id: department.id
          };
        });
      }

      if (json.public_departments) {
        json.public_departments = record.get("public_departments").map(function (department) {
          return {
            id: department.id
          };
        });
      }

      if (json.liker_departments) {
        json.liker_departments = record.get("liker_departments").map(function (department) {
          return {
            id: department.id
          };
        });
      }

      return {
        challenge: json
      };
    }
  });

  _exports.default = _default;
});