define("innowaze-ember2/mixins/routes/cleanCurrentModel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    deactivate: function deactivate() {
      if (this.currentModel.get("hasDirtyAttributes")) {
        this.currentModel.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});