define("innowaze-ember2/components/survey-voting", ["exports", "ramda/src/fromPairs"], function (_exports, _fromPairs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    classNames: ["survey"],
    percentageStyle: Ember.computed("survey.available_answers.[]", function () {
      return (0, _fromPairs.default)(this.get("survey.available_answers").map(function (answer) {
        return [answer.id.toString(), Ember.String.htmlSafe("width:".concat(answer.percentage, "%"))];
      }));
    }),
    actions: {
      vote: function vote() {
        var _this = this;

        var selected = this.selected;
        var survey = this.survey;

        if (selected) {
          survey.vote(selected).then(function () {
            survey.set("voted", true);

            _this.notifications.success(_this.locale.t("surveys.notification"));

            _this.sendAction("reloadChallenge");
          }, function () {
            _this.notifications.error(_this.locale.t("surveys.error_message"));
          });
        } else {
          this.notifications.error(this.locale.t("surveys.validation"));
        }
      }
    }
  });

  _exports.default = _default;
});