define("innowaze-ember2/serializers/concept", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      comments: {
        deserialize: "records",
        serialize: false
      },
      author: {
        deserialize: "records",
        serialize: false
      },
      teammates: {
        deserialize: "records",
        serialize: false
      },
      contributors: {
        deserialize: "records",
        serialize: false
      },
      debates: {
        deserialize: "records",
        serialize: false
      },
      tags: {
        deserialize: "records",
        serialize: false
      },
      pictures: {
        embedded: "always"
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      delete json.can_next_state;
      delete json.can_previous_state;

      if (snapshot.record.get("tags")) {
        json.tags = snapshot.record.get("tags").map(function (tag) {
          return {
            name: tag.get ? tag.get("name") : tag.name
          };
        });
      }

      if (snapshot.record.get("knowledges")) {
        json.knowledges = snapshot.record.get("knowledges").map(function (knowledge) {
          return {
            id: knowledge.id
          };
        });
      }

      if (snapshot.record.get("domains")) {
        json.domains = snapshot.record.get("domains").map(function (domain) {
          return {
            id: domain.id
          };
        });
      }

      if (snapshot.record.get("author")) {
        json.user_id = snapshot.record.get("author.id");
        delete json.author;
      }

      delete json.author;

      if (snapshot.record.get("teammates")) {
        json.teammates = snapshot.record.get("teammates").map(function (teammate) {
          return {
            id: teammate.id
          };
        });
      }

      if (snapshot.record.get("documents")) {
        json.documents = snapshot.record.get("documents").map(function (document) {
          return {
            id: document.id
          };
        });
      }

      if (snapshot.record.get("modules")) {
        json.modules = snapshot.record.get("modules").filter(function (module) {
          return module.id;
        }).map(function (module) {
          return {
            id: module.id,
            description: module.description
          };
        });
      }

      if (snapshot.record.get("concept_analysis")) {
        json.analysis = snapshot.record.get("concept_analysis");
        delete json.concept_analysis;
      }

      return {
        concept: json
      };
    }
  });

  _exports.default = _default;
});