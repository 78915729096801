define("innowaze-ember2/templates/challenges/modules/-members-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GmnmuRZp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"session\",\"isCompetitor\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"counter\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"challenges.members_count.title\"],[[\"count\"],[[23,[\"model\",\"members_count\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-members-count.hbs"
    }
  });

  _exports.default = _default;
});