define("innowaze-ember2/templates/departments/modules/-presentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o0W3O7FF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n  \"],[7,\"span\"],[9],[1,[27,\"i18n\",[\"presentation\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[1,[27,\"embed-slide\",null,[[\"slideUrl\",\"canEdit\"],[[23,[\"model\",\"slide_url\"]],true]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-presentation.hbs"
    }
  });

  _exports.default = _default;
});