define("innowaze-ember2/templates/components/modules/risk-analysis-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "smuc9Np5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"editMode\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[23,[\"module\",\"title\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"module\",\"control\"]]],null,{\"statements\":[[0,\"      \"],[7,\"sup\"],[12,\"class\",[27,\"if\",[[23,[\"module\",\"isNotCompleted\"]],\"red\",\"green\"],null]],[9],[0,\" *\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"onchange\",\"table\",\"admin\"],[[23,[\"model\",\"risk_analysis\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"risk_analysis\"],null],true,true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[23,[\"module\",\"isNotCompleted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[23,[\"module\",\"title\"]],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-module main-info\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"idea-description\"],[9],[1,[23,[\"model\",\"risk_analysis\"]],true],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/modules/risk-analysis-module.hbs"
    }
  });

  _exports.default = _default;
});