define("innowaze-ember2/templates/challenges/modules/-pictures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h4n4IPa6",
    "block": "{\"symbols\":[\"picture\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"pictures\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"challenges.pictures.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"no-bullet\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"pictures\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"debates-image\"],[9],[0,\"\\n          \"],[1,[27,\"linked-debate-container\",null,[[\"image\",\"badges\",\"canEdit\",\"addLinkedDebateBadge\",\"deleteDroppable\",\"displayDebateForBadge\",\"deleteBadge\",\"src\"],[[22,1,[]],[22,1,[\"badges\"]],[23,[\"canEdit\"]],\"addLinkedDebateBadge\",\"deleteDroppable\",\"displayDebateForBadge\",\"deleteBadge\",[23,[\"session\",\"configuration\",\"image_paths\",\"draggable-debate\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"isAdmin\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"challenges.pictures.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"column large-4\"],[9],[0,\"\\n        \"],[1,[27,\"file-upload\",null,[[\"picture\",\"filesUploaded\",\"multiple\"],[[23,[\"newPicture\"]],\"picturesUploaded\",true]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-pictures.hbs"
    }
  });

  _exports.default = _default;
});