define("innowaze-ember2/templates/concepts/edit-modules/-slogan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5bSEH+oQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n  \"],[7,\"span\"],[9],[1,[23,[\"module\",\"title\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"module\",\"control\"]]],null,{\"statements\":[[0,\"    \"],[7,\"sup\"],[11,\"class\",\"red\"],[9],[0,\" *\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"model\",\"concept\",\"slogan\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/edit-modules/-slogan.hbs"
    }
  });

  _exports.default = _default;
});