define("innowaze-ember2/adapters/department-module", ["exports", "ember-inflector", "innowaze-ember2/adapters/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    },
    queryRecord: function queryRecord(store, type, query) {
      if (query.name) {
        return this.ajax("".concat(this.host, "/").concat(this.namespace, "/department_modules/query_by_name.json?name=").concat(query.name), "GET");
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});