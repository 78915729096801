define("innowaze-ember2/adapters/universe", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/adapters/application"], function (_exports, _defineProperty2, _objectSpread3, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      if (query.editable) {
        delete query.editable;
        return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("universe"), "/editable.json");
      }

      return this._super.apply(this, arguments);
    },
    togglePublish: function togglePublish(id, universe) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("universe"), "/").concat(id, ".json"), "PATCH", {
        data: {
          published: !universe.get("published")
        }
      });
    },
    reorder: function reorder(universes) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("universe"), "/set_order.json"), "POST", {
        data: {
          universes: universes.reduce(function (acc, universe) {
            return (0, _objectSpread3.default)({}, acc, (0, _defineProperty2.default)({}, universe.id, universe.get("position")));
          }, {})
        }
      });
    }
  });

  _exports.default = _default;
});