define("innowaze-ember2/templates/concepts/edit-modules/-domains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qg/0wbxO",
    "block": "{\"symbols\":[\"domain\"],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"ideas.modules.domains.domains\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"options\",\"selected\",\"searchField\",\"placeholder\",\"closeOnSelect\",\"loadingMessage\",\"onchange\"],[[23,[\"availableDomains\"]],[23,[\"model\",\"concept\",\"domains\"]],\"name\",[27,\"i18n\",[\"ideas.modules.domains.placeholder\"],null],false,\"loading...\",[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"setDomains\"],null]]],{\"statements\":[[0,\"    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/edit-modules/-domains.hbs"
    }
  });

  _exports.default = _default;
});