define("innowaze-ember2/models/debate", ["exports", "ember-data", "innowaze-ember2/configuration", "innowaze-ember2/models/application"], function (_exports, _emberData, _configuration, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    active: _emberData.default.attr("boolean"),
    answers_order: _emberData.default.attr(),
    answers_ranking: _emberData.default.attr(),
    available_answers: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    banner_id: _emberData.default.attr("number"),
    banner_url: _emberData.default.attr("string"),
    balanced: _emberData.default.attr("boolean"),
    canEdit: _emberData.default.attr("boolean"),
    canPostIdea: _emberData.default.attr("boolean"),
    challenge_id: _emberData.default.attr("number"),
    chosen_answer: _emberData.default.attr("number"),
    comments_count: _emberData.default.attr("number"),
    my_comments_count: _emberData.default.attr("number"),
    comments: _emberData.default.hasMany("comment", {
      async: false
    }),
    created_at: _emberData.default.attr("string"),
    current_user_has_already_commented: _emberData.default.attr("boolean"),
    debatable_id: _emberData.default.attr("number"),
    debatable_name: _emberData.default.attr("string"),
    debatable_type: _emberData.default.attr("string"),
    explanation: _emberData.default.attr("string"),
    idea_id: _emberData.default.attr("number"),
    opinion_id: _emberData.default.attr("number"),
    opinions_count: _emberData.default.attr("number"),
    question: _emberData.default.attr("string"),
    type: _emberData.default.attr("string"),
    updated_at: _emberData.default.attr("string"),
    visible_in_widget: _emberData.default.attr("boolean"),
    wants_ideation: _emberData.default.attr("boolean"),
    position: _emberData.default.attr("number"),
    isSurvey: Ember.computed.equal("type", "SurveyDebate"),
    isSorting: Ember.computed.equal("type", "CardsSortingDebate"),
    isMultipleChoices: Ember.computed.equal("type", "MultipleChoicesDebate"),
    hasOptions: Ember.computed.or("isSorting", "isSurvey", "isMultipleChoices"),
    isOpen: Ember.computed.equal("type", "OpenDebate"),
    isInsight: Ember.computed.equal("type", "InsightDebate"),
    isClaim: Ember.computed.equal("type", "ClaimDebate"),
    noGraphic: Ember.computed.or("isOpen", "isInsight"),
    hasPie: Ember.computed.or("isSurvey", "isClaim"),
    hasHistogram: Ember.computed.or("isSorting", "isMultipleChoices"),
    commentsTree: Ember.computed("comments.[]", function () {
      var _this = this;

      var rootComments = this.comments.filterBy("parent_id", null);
      rootComments.forEach(function (comment) {
        comment.set("children", _this.comments.filterBy("parent_id", parseInt(comment.get("id"))).sortBy("created_at"));
      });
      return rootComments;
    }),
    agreedComments: Ember.computed("comments.[]", "type", function () {
      var _this2 = this;

      if (this.type !== "ClaimDebate") {
        return null;
      }

      var rootComments = this.comments.filterBy("parent_id", null).filterBy("opinion", 1);
      rootComments.forEach(function (comment) {
        comment.set("children", _this2.comments.filterBy("parent_id", parseInt(comment.get("id"))).sortBy("created_at"));
      });
      return rootComments;
    }),
    agreedPercentage: Ember.computed("agreedComments", "opinions_count", function () {
      if (this.type !== "ClaimDebate") {
        return null;
      }

      return this.get("agreedComments.length") / this.opinions_count * 100 || 0;
    }),
    disagreedComments: Ember.computed("comments.[]", "type", function () {
      var _this3 = this;

      if (this.type !== "ClaimDebate") {
        return null;
      }

      var rootComments = this.comments.filterBy("parent_id", null).filterBy("opinion", -1);
      rootComments.forEach(function (comment) {
        comment.set("children", _this3.comments.filterBy("parent_id", parseInt(comment.get("id"))).sortBy("created_at"));
      });
      return rootComments;
    }),
    disagreedPercentage: Ember.computed("disagreedComments", "opinions_count", function () {
      if (this.type !== "ClaimDebate") {
        return null;
      }

      return this.get("disagreedComments.length") / this.opinions_count * 100 || 0;
    }),
    mixedPercentage: Ember.computed("agreedPercentage", "disagreedPercentage", function () {
      if (this.type !== "ClaimDebate") {
        return null;
      }

      return 100 - this.agreedPercentage - this.disagreedPercentage;
    }),
    debateWithVote: Ember.computed("type", function () {
      return ["ClaimDebate", "SurveyDebate", "CardsSortingDebate", "MultipleChoicesDebate"].includes(this.type);
    }),
    hasVoted: Ember.computed.and("debateWithVote", "current_user_has_already_commented"),
    canReply: Ember.computed("debateWithVote", "hasVoted", "canEdit", function () {
      return !this.debateWithVote || this.hasVoted || this.canEdit;
    }),
    backgroundBanner: Ember.computed("banner_url", function () {
      return Ember.String.htmlSafe("background-image: url(".concat(_configuration.default.apiUrl).concat(this.banner_url, ");background-size: cover;filter:brightness(80%);"));
    }),
    sortedAnswers: Ember.computed("available_answers", function () {
      return this.available_answers.sortBy("position");
    }),
    sortedAnswersRanking: Ember.computed("answers_ranking", "isSorting", function () {
      if (this.isSorting) {
        return this.answers_ranking.sortBy("score");
      }

      if (this.isMultipleChoices) {
        return this.answers_ranking.sortBy("value").reverse();
      }

      return this.answers_ranking;
    }),
    translate: function translate(language) {
      return this._callAdapter("translate", language);
    },
    tagsCloud: function tagsCloud(selectedMethod, selectedLocale) {
      return this._callAdapter("tagsCloud", selectedMethod, selectedLocale);
    },
    addHashtags: function addHashtags() {
      return this._callAdapter("addHashtags");
    },
    clearVerbatims: function clearVerbatims() {
      return this._callAdapter("clearVerbatims");
    },
    answersNetwork: function answersNetwork() {
      return this._callAdapter("answersNetwork");
    },
    wordsCooccurrences: function wordsCooccurrences() {
      return this._callAdapter("wordsCooccurrences");
    },
    toggleVisibleInWidget: function toggleVisibleInWidget() {
      return this._callAdapter("toggleVisibleInWidget");
    }
  });

  _exports.default = _default;
});