define("innowaze-ember2/models/news", ["exports", "ember-data", "innowaze-ember2/configuration", "innowaze-ember2/models/application"], function (_exports, _emberData, _configuration, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    locale: Ember.inject.service(),
    body: _emberData.default.attr("string"),
    canEdit: _emberData.default.attr("boolean"),
    challenge_id: _emberData.default.attr("number"),
    created_at: _emberData.default.attr("string"),
    department_name: _emberData.default.attr("string"),
    displayed: _emberData.default.attr("boolean"),
    domains: _emberData.default.hasMany("domain", {
      async: false
    }),
    is_ad: _emberData.default.attr("boolean"),
    logo_department_url: _emberData.default.attr("string"),
    newsable_id: _emberData.default.attr("number"),
    newsable_type: _emberData.default.attr("string"),
    picture_url: _emberData.default.attr("string"),
    picture_id: _emberData.default.attr("number"),
    published: _emberData.default.attr("boolean"),
    published_at: _emberData.default.attr(),
    requested: _emberData.default.attr("boolean"),
    small_picture_url: _emberData.default.attr("string"),
    subtitle: _emberData.default.attr("string"),
    tags: _emberData.default.hasMany("tag", {
      async: false
    }),
    title: _emberData.default.attr("string"),
    truncated_body: _emberData.default.attr("string"),
    truncated_subtitle: _emberData.default.attr("string"),
    updated_at: _emberData.default.attr("string"),
    isDepartment: Ember.computed.equal("newsable_type", "Department"),
    isChallenge: Ember.computed.equal("newsable_type", "Challenge"),
    isInstance: Ember.computed("newsable_type", function () {
      return Ember.isNone(this.newsable_type);
    }),
    togglePublish: function togglePublish() {
      return this._callAdapter("togglePublish");
    },
    requestToPublish: function requestToPublish() {
      return this._callAdapter("requestToPublish");
    },
    toggleAdvertize: function toggleAdvertize() {
      return this._callAdapter("toggleAdvertize");
    },
    publishText: Ember.computed("published", function () {
      if (this.published) {
        return this.locale.t("news.unpublish");
      }

      return this.locale.t("news.publish");
    }),
    publishNotificationText: Ember.computed("published", function () {
      if (this.published) {
        return this.locale.t("news.has_been_published");
      }

      return this.locale.t("news.has_been_unpublished");
    }),
    adNotificationText: Ember.computed("is_ad", function () {
      if (this.is_ad) {
        return this.locale.t("news.has_been_ad");
      }

      return this.locale.t("news.has_been_unad");
    }),
    backgroundStyle: Ember.computed("picture_url", "small_picture_url", function () {
      if (this.small_picture_url) {
        return Ember.String.htmlSafe("background-image: url(".concat(_configuration.default.apiUrl).concat(this.small_picture_url, ");"));
      }

      if (this.picture_url) {
        return Ember.String.htmlSafe("background-image: url(".concat(_configuration.default.apiUrl).concat(this.picture_url, ");"));
      }
    })
  });

  _exports.default = _default;
});