define("innowaze-ember2/templates/components/display-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XqMVgCPK",
    "block": "{\"symbols\":[\"event\"],\"statements\":[[7,\"div\"],[11,\"id\",\"warningModal\"],[11,\"data-reveal\",\"\"],[11,\"class\",\"reveal-modal\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"i18n\",[\"warning\"],null],false],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"ideas.show.draft_alert1\"],[[\"idea\"],[[27,\"name-for\",[[23,[\"challenge\"]],\"idea\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"ideas.show.draft_alert2\"],[[\"the_idea\",\"action\"],[[27,\"name-for\",[[23,[\"challenge\"]],\"the_idea\"],null],[27,\"name-for\",[[23,[\"challenge\"]],\"formulate_idea\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[9],[1,[27,\"i18n\",[\"not_now\"],null],false],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"button-group round even three-up right\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"possible_state_events\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"button tiny translucid\"],[3,\"action\",[[22,0,[]],\"changeState\",[22,1,[\"name\"]]]],[9],[0,\"\\n        \"],[1,[22,1,[\"on_name\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-reveal-modal\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/display-modal.hbs"
    }
  });

  _exports.default = _default;
});