define("innowaze-ember2/initializers/torii-custom", ["exports", "innowaze-ember2/torii/torii-saml-provider", "innowaze-ember2/torii/torii-saml-popup", "innowaze-ember2/torii/torii-wsfed-provider", "innowaze-ember2/torii/torii-wsfed-popup"], function (_exports, _toriiSamlProvider, _toriiSamlPopup, _toriiWsfedProvider, _toriiWsfedPopup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    app.register("torii-service:saml-popup", _toriiSamlPopup.default);
    app.register("torii-provider:saml", _toriiSamlProvider.default);
    app.register("torii-provider:wsfed", _toriiWsfedProvider.default);
    app.register("torii-service:wsfed-popup", _toriiWsfedPopup.default);
  }

  var _default = {
    name: "torii-custom",
    initialize: initialize
  };
  _exports.default = _default;
});