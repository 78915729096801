define("innowaze-ember2/templates/tag-triggers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CqXjha6d",
    "block": "{\"symbols\":[\"tagTrigger\",\"keyword\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"i18n\",[\"tag_triggers.title\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"tag_triggers.explanation\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"fat-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"banner\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"tagTriggers.edit\",[22,1,[]]],null,{\"statements\":[[0,\"            \"],[1,[22,1,[\"tag\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"ul\"],[11,\"class\",\"keyword-list\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"keywords\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"delete-user\"],[3,\"action\",[[22,0,[]],\"delete\",[22,1,[]]]],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"tagTriggers.new\"],null,{\"statements\":[[0,\"    \"],[1,[27,\"i18n\",[\"tag_triggers.add_tag\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/tag-triggers.hbs"
    }
  });

  _exports.default = _default;
});