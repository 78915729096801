define("innowaze-ember2/components/textarea-wysiwyg", ["exports", "innowaze-ember2/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ["data-cy"],
    isPublic: false,
    options: {
      placeholderText: "",
      heightMin: 200,
      attribution: false,
      toolbarButtons: [],
      toolbarSticky: false,
      imageUploadURL: _configuration.default.apiUrl + "/api/uploads.json",
      imageUploadParam: "files",
      videoMaxSize: 1024 * 1024 * 500,
      videoUploadURL: _configuration.default.apiUrl + "/api/uploads.json",
      videoUploadParam: "files",
      requestWithCredentials: true,
      pasteDeniedAttrs: ["class", "id", "style"],
      pasteDeniedTags: ["div"]
    },
    init: function init() {
      this._super.apply(this, arguments);

      var placeholder = this.placeholder;

      if (placeholder) {
        this.set("options.placeholderText", placeholder);
      }

      this.setProperties({
        "options.imageUploadParams": {
          type: "wysiwyg",
          is_public: this.isPublic
        },
        "options.videoUploadParams": {
          type: "wysiwyg",
          is_public: this.isPublic
        }
      });
      var toolbarButtons = [["bold", "italic", "underline", "|", "formatOL", "formatUL", "|", "outdent", "indent", "|", "align", "insertLink", "|", "insertImage", "insertVideo"]];

      if (this.get("session.isAdmin") || this.get("session.currentUser.admin") || this.table) {
        toolbarButtons[0].push("|", "insertTable");
      }

      if (this.get("session.isAdmin") || this.get("session.currentUser.admin") || this.admin) {
        toolbarButtons[0].push("|", "textColor", "backgroundColor", "paragraphFormat", "|", "html");
      }

      this.set("options.toolbarButtons", toolbarButtons);
    },
    actions: {
      onImageUploaded: function onImageUploaded(editor, response) {
        var imgURL = _configuration.default.apiUrl + JSON.parse(response).link;
        editor.image.insert(imgURL, false, null, editor.image.get(), response);
        return false;
      },
      onVideoUploaded: function onVideoUploaded(editor, response) {
        var videoURL = _configuration.default.apiUrl + JSON.parse(response).link;
        var code = "\n        <video controls width=\"640\" height=\"360\" preload=\"none\">\n          <source src=\"".concat(videoURL, "\" type=\"video/mp4\">\n          Your browser does not support the video tag.\n        </video>");
        editor.video.insert(code);
        editor.component.update(editor.html.get());
        return false;
      },
      onChange: function onChange(newValue) {
        this.set("value", newValue.toString());
        this.sendAction("onchange", newValue.toString());
      }
    }
  });

  _exports.default = _default;
});