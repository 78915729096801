define("innowaze-ember2/routes/universes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get("session.configuration.dict.universes")) {
        this.replaceWith("challenges");
      }
    },
    model: function model() {
      return this.store.query("universe", {
        editable: true
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set("hasUnsavedPosition", false);
    },
    actions: {
      togglePublish: function togglePublish(universe) {
        var _this = this;

        if (universe.get("challenges").length > 0 && universe.get("published") && !confirm(this.locale.t("universes.unpublished_confirmation"))) {
          return;
        }

        universe.togglePublish().then(function () {
          universe.toggleProperty("published");

          _this.notifications.success(_this.locale.t(universe.get("published") ? "universes.published" : "universes.unpublished"), {
            autoClear: true
          });
        });
      },
      delete: function _delete(universe) {
        var _this2 = this;

        if (!confirm(this.locale.t("universes.deletion_confirmation"))) {
          return;
        }

        universe.destroyRecord().then(function () {
          _this2.notifications.success(_this2.locale.t("universes.deleted"), {
            autoClear: true
          });
        });
      },
      reorder: function reorder() {
        this.get("controller.sortedUniverses").forEach(function (module, index) {
          module.set("position", index + 1);
        });
        this.set("controller.hasUnsavedPosition", true);
      },
      save: function save() {
        var _this3 = this;

        this.set("controller.hasUnsavedPosition", false);
        this.store.adapterFor("universe").reorder(this.get("controller.sortedUniverses")).then(function () {
          return _this3._success();
        }, function () {
          return _this3._error();
        });
      }
    },
    _success: function _success() {
      this.notifications.success(this.locale.t("challenges.domains.reorder_message"), {
        autoClear: true
      });
    },
    _error: function _error() {
      this.notifications.error(this.locale.t("error"), {
        autoClear: true
      });
    }
  });

  _exports.default = _default;
});