define("innowaze-ember2/components/hashtags-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    classNames: ["tags-cloud"],
    modalIsOpen: false,
    typeId: 0,
    locale: Ember.inject.service(),
    store: Ember.inject.service(),
    methods: [{
      id: "hashtag",
      name: "Hashtags"
    }, {
      id: "word",
      name: "Words"
    }, {
      id: "verbatim",
      name: "Verbatims"
    }],
    locales: [{
      id: "fr",
      name: "french"
    }, {
      id: "en",
      name: "english"
    }, {
      id: "de",
      name: "german"
    }],
    angles: [{
      id: null,
      name: "90°"
    }, {
      id: 45,
      name: "random"
    }],
    colors: [{
      id: "blank",
      name: "blank"
    }, {
      id: "black",
      name: "black"
    }],
    occurrences: [{
      id: 1,
      name: "1+"
    }, {
      id: 2,
      name: "2+"
    }, {
      id: 3,
      name: "3+"
    }, {
      id: 4,
      name: "4+"
    }, {
      id: 5,
      name: "5+"
    }, {
      id: 10,
      name: "10+"
    }],
    selectedLocale: "fr",
    selectedMethod: "hashtag",
    selectedOccurrence: 1,
    selectedAngle: null,
    selectedColor: "black",
    stopwordsArray: [],
    hashtagMethod: Ember.computed.equal("selectedMethod", "hashtag"),
    draw: Ember.observer("selectedLocale", "selectedMethod", function () {
      if (this.modalIsOpen) {
        this.set("dataLoaded", false);
        this.loadData();
      }
    }),
    filter: Ember.observer("selectedOccurrence", "selectedAngle", "stopwordsArray.[]", function () {
      this.filterWords();
    }),
    loadData: function loadData() {
      var _this = this;

      this.debate.tagsCloud(this.selectedMethod, this.selectedLocale).then(function (data) {
        _this.set("data", data);

        _this.filterWords();

        _this.set("dataLoaded", true);
      });
    },
    filterWords: function filterWords() {
      var _this2 = this;

      if (!this.data) {
        return true;
      }

      var filteredData = this.data.filter(function (datum) {
        return datum.value >= _this2.selectedOccurrence;
      });

      if (!this.hashtagMethod) {
        var stopwordsArray = this.stopwordsArray.mapBy("id");
        filteredData = filteredData.filter(function (datum) {
          return !stopwordsArray.includes(datum.key);
        });
      }

      this.set("filteredData", filteredData);
    },
    actions: {
      openModalWindow: function openModalWindow() {
        this.set("dataLoaded", false);
        this.set("modalIsOpen", true);
        this.loadData();
      },
      closeModalWindow: function closeModalWindow() {
        this.set("modalIsOpen", false);
      },
      newStopword: function newStopword(input) {
        this.stopwordsArray.pushObject({
          name: input,
          id: input
        });
      }
    }
  });

  _exports.default = _default;
});