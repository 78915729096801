define("innowaze-ember2/components/state-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deleteRule: function deleteRule(rule) {
        rule.set("to_delete", true);
      },
      toggleAdmin: function toggleAdmin(rule) {
        if (!rule.get("protected") || this.get("session.isSuperAdmin") || rule.get("to") === "published") {
          rule.toggleProperty("admin");
        }
      },
      toggleEvaluation: function toggleEvaluation(rule) {
        rule.toggleProperty("evaluation");
      },
      toggleRejectable: function toggleRejectable(rule) {
        rule.toggleProperty("rejectable");
      },
      toggleInvisible: function toggleInvisible(rule) {
        if (this.get("session.isAdmin")) {
          rule.toggleProperty("invisible");
        }
      },
      updateRulesOrder: function updateRulesOrder() {
        this.get("model.state_machine_rules").forEach(function (rule, index) {
          rule.set("position", index + 1);
        });
      }
    }
  });

  _exports.default = _default;
});