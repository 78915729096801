define("innowaze-ember2/routes/challenges/ideas-positioning", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveAsImage", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _objectSpread2, _setCurrentChallenge, _saveAsImage, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _saveAsImage.default, _selectAutocomplete.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    redirect: function redirect(model, transition) {
      if (model.get("activeIdeaTools").length < 2) {
        this.transitionTo("challenges.show", model.id);
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("colorVars", model.get("activeIdeaTools").concat([{
        title: this.locale.t("ideas.types"),
        name: "typology",
        scale: 1
      }, {
        title: this.locale.t("ideas.state"),
        name: "state_name",
        scale: 2
      }, {
        title: this.locale.t("ideas.domains"),
        name: "first_domain",
        scale: 2
      }, {
        title: this.locale.t("ideas.colors"),
        name: "colors",
        scale: 2
      }].map(function (color) {
        return Ember.Object.create(color);
      })));
      controller.set("sizeVars", model.get("activeIdeaTools").concat([Ember.Object.create({
        title: this.locale.t("none"),
        name: null
      })]));
      controller.setProperties({
        availableDomains: this.store.query("domain", {
          challenge_id: model.id
        }),
        availableCreators: this.store.adapterFor("user").ideaCreatorsSearch(model.id),
        tagsToDisplay: [],
        chartWidth: Math.min(window.innerWidth - 30, 1250),
        chartHeight: 600,
        calculationMode: "median",
        force_median: false,
        selectedColor: controller.colorVars.findBy("name", "first_domain"),
        selectedSize: controller.sizeVars.findBy("name", null)
      });

      this._loadData();
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          tags: [],
          query: "",
          tagsToDisplay: [],
          creators: [],
          states: [],
          color: undefined,
          domain: undefined,
          typology: undefined,
          ideasToPosition: []
        });
      }
    },
    actions: {
      search: function search() {
        this._loadData();
      },
      toggleMode: function toggleMode() {
        this.toggleProperty("controller.selectedMode");
      },
      selectTypology: function selectTypology(typology) {
        this.controller.set("typology", this.get("controller.typology") === typology ? undefined : typology);

        this._loadData();
      },
      selectColor: function selectColor(color) {
        this.controller.set("color", this.get("controller.color") === color ? undefined : color);

        this._loadData();
      },
      selectDomain: function selectDomain(domain) {
        if (this.get("controller.domain") === domain.id) {
          this.controller.set("domain", undefined);
        } else {
          this.controller.set("domain", domain.id);
        }

        this._loadData();
      },
      setStates: function setStates(states) {
        this.controller.set("states", states);

        this._loadData();
      },
      setCreators: function setCreators(creators) {
        this.controller.set("creators", creators);

        this._loadData();
      },
      setTags: function setTags(tags) {
        this.controller.set("tags", tags);

        this._loadData();
      },
      alertWithMedian: function alertWithMedian() {
        $("#medianPositioningModal").foundation("reveal", "open");
      },
      cancel: function cancel() {
        $("#medianPositioningModal").foundation("reveal", "close");
        this.set("controller.force_median", false);
      },
      positionWithMedian: function positionWithMedian() {
        var _this = this;

        var ideaIds = this.get("controller.ideasToPosition").mapBy("id").map(function (id) {
          return {
            id: parseInt(id)
          };
        });

        if (ideaIds.length > 0) {
          this.store.adapterFor("idea").positionWithMedian(this.currentModel.id, ideaIds, this.get("controller.force_median"), this.get("controller.calculationMode")).then(function () {
            $("#medianPositioningModal").foundation("reveal", "close");

            _this.notifications.success(_this.locale.t("challenges.ideas_positioning.updated"));

            _this.get("controller.ideasToPosition").setEach("changed", null);

            _this._loadData();

            _this.set("controller.force_median", false);
          });
        } else {
          this.notifications.error(this.locale.t("challenges.ideas_positioning.nothing_moved"));
        }
      },
      save: function save() {
        var _this2 = this;

        var ideas = this.get("controller.ideasToPosition").filterBy("changed", true).map(function (idea) {
          return idea.getProperties("id", "stakes", "stakes_is_median", "accessibility", "accessibility_is_median", "maturity", "maturity_is_median", "strategy", "strategy_is_median", "typology", "typology_is_median", "extra_tools");
        });

        if (ideas.length > 0) {
          this.store.adapterFor("idea").updateEvaluations(this.currentModel.id, ideas).then(function () {
            _this2.notifications.success(_this2.locale.t("challenges.ideas_positioning.updated"));

            _this2.get("controller.ideasToPosition").setEach("changed", null);
          });
        } else {
          this.notifications.error(this.locale.t("challenges.ideas_positioning.nothing_moved"));
        }
      }
    },
    _loadData: function _loadData() {
      var _this3 = this;

      var query = this.get("controller.query");
      var tags = this.get("controller.tags");
      var departments = this.get("controller.departments");
      var domain = this.get("controller.domain");
      var color = this.get("controller.color");
      var typology = this.get("controller.typology");
      var users = this.get("controller.creators");
      var states = this.get("controller.states");
      this.store.query("idea", (0, _objectSpread2.default)({}, {
        challenge_id: this.currentModel.id,
        extended: true,
        paginate: false,
        list: false
      }, {}, states && states.length && {
        states: states.mapBy("id")
      }, {}, query && {
        query: query
      }, {}, domain && {
        domain: domain
      }, {}, color && {
        color: color
      }, {}, typology && {
        typology: typology
      }, {}, tags && tags.length > 0 && {
        tags: tags.mapBy("name")
      }, {}, departments && departments.length > 0 && {
        departments: departments.mapBy("id")
      }, {}, users && users.length > 0 && {
        users: users.mapBy("id")
      })).then(function (data) {
        _this3.controller.set("ideasToPosition", data.toArray());
      });
    }
  });

  _exports.default = _default;
});