define("innowaze-ember2/utils/sortByPosition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortByPosition;

  /**
   * Sorter function for lists of Ember Object with a position value
   * It will suppose that a null value for position means that it
   * should be at the end of the list
   *
   * @param {Ember.Object} a
   * @param {Ember.Object} b
   *
   * @return {Number} 0, 1 or -1
   */
  function sortByPosition(a, b) {
    var aPosition = a.get("position") !== null ? a.get("position") : Infinity;
    var bPosition = b.get("position") !== null ? b.get("position") : Infinity;

    if (aPosition === bPosition) {
      return 0;
    }

    return aPosition > bPosition ? 1 : -1;
  }
});