define("innowaze-ember2/models/user", ["exports", "ember-data", "innowaze-ember2/configuration", "innowaze-ember2/models/application"], function (_exports, _emberData, _configuration, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var User = _application.default.extend({
    localeService: Ember.inject.service("locale"),
    admin: _emberData.default.attr("boolean"),
    admin_in_challenges: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    badges: _emberData.default.hasMany("badge", {
      async: false
    }),
    can_create_challenge: _emberData.default.attr("boolean"),
    can_create_knowledge: _emberData.default.attr("boolean"),
    challenges_count: _emberData.default.attr("number"),
    departments: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    designer_in_challenges: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    digest_frequencies: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    field_ids: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    field_types: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    fields: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    force_password_update: _emberData.default.attr("boolean"),
    ideas_count: _emberData.default.attr("number"),
    is_admin: _emberData.default.attr("boolean"),
    is_knowledger: _emberData.default.attr("boolean"),
    is_analyst: _emberData.default.attr("boolean"),
    is_super_admin: _emberData.default.attr("boolean"),
    is_department_scout: _emberData.default.attr("boolean"),
    is_universe_admin: _emberData.default.attr("boolean"),
    last_sign_in_at: _emberData.default.attr(),
    managed_departments: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    member_in_challenges: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    member_in_challenge_ids: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    moderator_in_challenges: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    module_list: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    modules: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    name_for_concepts: _emberData.default.attr("string"),
    name_for_ideas: _emberData.default.attr("string"),
    optout: _emberData.default.attr("boolean"),
    preload_tags: _emberData.default.hasMany("tag", {
      async: false
    }),
    profile_completed_in_challenges_ids: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    reviewer_in_challenges: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    sign_in_count: _emberData.default.attr("number"),
    default_universe_id: _emberData.default.attr("string"),
    anonymized_at: _emberData.default.attr("date"),
    fullname: Ember.computed("fields.first_name", "fields.last_name", function () {
      var first_name = this.get("fields.first_name");
      var last_name = this.get("fields.last_name");

      if (Ember.isEmpty(first_name) && Ember.isEmpty(last_name)) {
        return this.localeService.t("void");
      }

      return [first_name, last_name].join(" ");
    }),
    hasCustomAvatar: Ember.computed("fields.avatar.url", function () {
      return !!this.get("fields.avatar");
    }),
    avatar: Ember.computed("fields.avatar.url", function () {
      var avatar = this.get("fields.avatar");
      var avatarUrl = avatar ? _configuration.default.apiUrl + avatar.url : _configuration.default.defaultImagesPaths.avatar;
      return avatarUrl;
    }),
    smallAvatar: Ember.computed("fields.avatar.url", function () {
      var avatar = this.get("fields.avatar");
      var avatarUrl = avatar ? _configuration.default.apiUrl + avatar.small_url : _configuration.default.defaultImagesPaths.smallAvatar;
      return avatarUrl;
    }),
    screenname: Ember.computed("fullname", "fields.screen_name", "fields.email", function () {
      var fullname = this.fullname;
      var screen_name = this.get("fields.screen_name");

      if (Ember.isEmpty(screen_name)) {
        if (Ember.isEqual(fullname, this.localeService.t("void"))) {
          return this.localeService.t("anonymous");
        }

        return fullname;
      }

      return screen_name;
    }),
    eventNotifications: Ember.computed("digest_frequencies", function () {
      return this.digest_frequencies.filter(function (e) {
        return ["news_created", "debate_created"].includes(e.event_id);
      });
    }),
    contributionNotifications: Ember.computed("digest_frequencies", function () {
      return this.digest_frequencies.filter(function (e) {
        return ["idea_created", "concept_created"].includes(e.event_id);
      });
    }),
    modificationNotifications: Ember.computed("digest_frequencies", function () {
      return this.digest_frequencies.filter(function (e) {
        return ["comment_for_idea_created", "comment_for_concept_created", "comment_for_debate_created", "idea_updated", "concept_updated"].includes(e.event_id);
      });
    }),
    adminNotifications: Ember.computed("digest_frequencies", function () {
      return this.digest_frequencies.filter(function (e) {
        return e.event_id === "invitation_created" || e.event_id === "idea_evaluated";
      });
    }),
    bestPhone: Ember.computed("fields.phone", "fields.mobile", function () {
      return this.get("fields.mobile") || this.get("fields.phone") || this.localeService.t("nc");
    }),
    skills: Ember.computed("fields.skills", function () {
      var fields = this.get("fields.skills");
      return fields ? fields.split(",").map(Number) : [];
    }),
    skillNames: Ember.computed("fields.skills", "preload_tags", function () {
      var _this = this;

      var value = this.get("fields.skills");

      if (!value) {
        return [];
      }

      return value.split(",").map(function (id) {
        return _this.preload_tags.findBy("id", id);
      });
    }),
    socialTypes: Ember.computed("fields.social_types", function () {
      var fields = this.get("fields.social_types");
      return fields ? fields.split(",").map(Number) : [];
    }),
    useTypes: Ember.computed("fields.use_types", function () {
      var fields = this.get("fields.use_types");
      return fields ? fields.split(",").map(Number) : [];
    }),
    twitterUrl: Ember.computed("fields.twitter", function () {
      var name = this.get("fields.twitter");

      if (name && name.slice(0, 4) === "http") {
        name = name.split("/").slice(-1).toString();
      }

      return "https://twitter.com/" + name;
    }),
    facebookUrl: Ember.computed("fields.facebook", function () {
      var name = this.get("fields.facebook");

      if (name && name.slice(0, 4) === "http") {
        name = name.split("/").slice(-1).toString();
      }

      return "https://www.facebook.com/" + name;
    }),
    linkedinUrl: Ember.computed("fields.linkedin", function () {
      var name = this.get("fields.linkedin");

      if (name && name.slice(0, 4) === "http") {
        name = name.split("/").slice(-1).toString();
      }

      return "https://www.linkedin.com/in/" + name;
    }),
    instagramUrl: Ember.computed("fields.instagram", function () {
      var name = this.get("fields.instagram");

      if (name && name.slice(0, 4) === "http") {
        name = name.split("/").slice(-1).toString();
      }

      return "https://www.instagram.com/".concat(name, "/");
    }),
    googleUrl: Ember.computed("fields.google", function () {
      var name = this.get("fields.google");

      if (name && name.slice(0, 4) === "http") {
        name = name.split("/").slice(-1).toString();
      }

      return "https://plus.google.com/+" + name;
    }),
    pinterestUrl: Ember.computed("fields.pinterest", function () {
      var name = this.get("fields.pinterest");

      if (name && name.slice(0, 4) === "http") {
        name = name.split("/").slice(-1).toString();
      }

      return "https://fr.pinterest.com/explore/".concat(name, "/");
    }),
    atLeastOneSocialNetwork: Ember.computed("fields.{twitter,linkedin,google,facebook,instagram,pinterest}", "modules.social.private", "modules.social.field_list", function () {
      var _this2 = this;

      if (this.get("modules.social.private")) {
        return false;
      }

      if (!this.get("modules.social.field_list")) {
        return false;
      }

      return this.get("modules.social.field_list").any(function (field) {
        return _this2.get("fields.".concat(field));
      });
    }),
    hasPublicDepartmentModule: Ember.computed("modules.departments", "modules.departments.{invisible,private}", "departments", function () {
      return !!this.get("departments.length") && !!this.get("modules.departments") && !this.get("modules.departments.private") && !this.get("modules.departments.invisible");
    }),
    description: Ember.computed.alias("fields.description"),
    email: Ember.computed.alias("fields.email"),
    locale: Ember.computed.alias("fields.locale"),
    pseudo: Ember.computed.alias("fields.screen_name"),
    gender: Ember.computed.alias("fields.gender"),
    age: Ember.computed.alias("fields.age"),
    occupation: Ember.computed.alias("fields.occupation"),
    zipcode: Ember.computed.alias("fields.zipcode"),
    address: Ember.computed.alias("fields.address"),
    city: Ember.computed.alias("fields.city"),
    site: Ember.computed.alias("fields.site"),
    hasChallenge: Ember.computed("member_in_challenges", function () {
      return this.member_in_challenges && this.member_in_challenges.length > 0;
    }),
    toggleAdmin: function toggleAdmin() {
      return this._callAdapter("toggleAdmin");
    },
    setKnowledgerRole: function setKnowledgerRole() {
      return this._callAdapter("setKnowledgerRole");
    },
    removeKnowledgerRole: function removeKnowledgerRole() {
      return this._callAdapter("removeKnowledgerRole");
    },
    setDepartmentScoutRole: function setDepartmentScoutRole() {
      return this._callAdapter("setDepartmentScoutRole");
    },
    removeDepartmentScoutRole: function removeDepartmentScoutRole() {
      return this._callAdapter("removeDepartmentScoutRole");
    },
    setAnonymous: function setAnonymous() {
      return this._callAdapter("setAnonymous");
    },
    addNewTags: function addNewTags() {
      var _this3 = this;

      var waitingPromises = [];
      Object.keys(this.fields).forEach(function (field_name) {
        var value = _this3.fields[field_name];

        if (!value || _this3.get("field_types.".concat(field_name)) !== "enum_tags") {
          return;
        }

        waitingPromises = waitingPromises.concat(value.split(",").filter(function (element) {
          return !/[0-9]+/.test(element);
        }).map(function (element) {
          return _this3.store.createRecord("tag", {
            name: element
          }).save().then(function (tagData) {
            _this3.fields[field_name] = _this3.fields[field_name].split(",").map(function (skill) {
              return skill.toLowerCase() === tagData.get("name").toLowerCase() ? tagData.id : skill;
            }).join(",");
          });
        }));
      });
      return Promise.all(waitingPromises);
    }
  });

  var _default = User;
  _exports.default = _default;
});