define("innowaze-ember2/templates/departments/modules/-job-opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QcyOk4bS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"departments.job_opportunities\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"onchange\"],[[23,[\"model\",\"job_opportunities\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.job_opportunities\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-job-opportunities.hbs"
    }
  });

  _exports.default = _default;
});