define("innowaze-ember2/components/radar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "svg",
    className: "radar-chart",
    attributeBindings: ["width", "height", "color"],
    draw: Ember.observer("data.@each.value", function () {
      if (this.data) {
        this.createRadar();
      }
    }),
    didInsertElement: function didInsertElement() {
      this.draw();
    },
    createRadar: function createRadar() {
      d3.select(this.$().get(0)).selectAll("g").remove();
      var data = this.data;
      var width = this.width || $(".tabs-content").width();
      var margin = width * 0.4;
      var marginLeft = margin / 2;
      var containerWidth = (width - margin) * 0.8;
      $(".radar-container").css({
        minHeight: "".concat(containerWidth, "px"),
        marginLeft: "".concat(marginLeft, "px")
      });
      RadarChart.defaultConfig.w = containerWidth;
      RadarChart.defaultConfig.h = containerWidth;
      RadarChart.defaultConfig.maxValue = 100;
      RadarChart.defaultConfig.minValue = 0;
      RadarChart.defaultConfig.levels = this.levels || 10;
      RadarChart.defaultConfig.levelTick = true;
      RadarChart.defaultConfig.radius = 10;
      RadarChart.defaultConfig.showValue = true;
      RadarChart.draw("#".concat(this.elementId), data);
    }
  });

  _exports.default = _default;
});