define("innowaze-ember2/routes/email-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      return this.store.query("email-template", {});
    },
    actions: {
      delete: function _delete(template) {
        var _this = this;

        if (!confirm(this.locale.t("domains.index.deletion_confirmation"))) {
          return;
        }

        template.destroyRecord().then(function () {
          _this.notifications.success(_this.locale.t("domains.index.deleted"));
        });
      }
    }
  });

  _exports.default = _default;
});