define("innowaze-ember2/routes/concepts/new", ["exports", "innowaze-ember2/routes/concepts/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        concept: this.store.createRecord("concept", {
          challenge_id: params.challenge_id,
          documents: [],
          pictures: [],
          tags: [],
          modules: [],
          teammates: [],
          author: this.get("session.currentUser"),
          index_stage: 0
        })
      });
    },
    _success: function _success(id) {
      this.controller.set("saveDisabled", false);
      this.notifications.success(this.locale.t("ideas.created_idea"));
      this.transitionTo("concepts.show", this.currentModel.challenge.id, id);
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("creation_error"), errors);
    }
  });

  _exports.default = _default;
});