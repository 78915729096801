define("innowaze-ember2/mixins/routes/saveChallenge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    saveCurrentChallenge: function saveCurrentChallenge() {
      this.controller.set("saveDisabled", true);
      $(document).foundation();
      return this.currentModel.save();
    },
    saveForNextStep: function saveForNextStep(step) {
      var mask = this.currentModel.get("stepMask");
      var new_mask = mask.substring(0, step) + "1" + mask.substring(step + 2);
      this.currentModel.setProperties({
        stepMask: new_mask,
        step: parseInt(new_mask, 2)
      });
      return this.saveCurrentChallenge();
    },
    _success: function _success() {
      this.controller.set("saveDisabled", false);
      this.notifications.success(this.locale.t("challenges.edit.update_message"));
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("modification_error"), errors);
    }
  });

  _exports.default = _default;
});