define("innowaze-ember2/routes/password-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.Object.create({
        reset_password_token: params.reset_password_token,
        password: null,
        password_confirmation: null
      });
    },
    actions: {
      changePassword: function changePassword() {
        var _this = this;

        this.store.adapterFor("user").changePassword({
          user: {
            email: this.currentModel.email,
            reset_password_token: this.currentModel.get("reset_password_token"),
            password: this.currentModel.get("password"),
            password_confirmation: this.currentModel.get("password_confirmation")
          }
        }).then(function (user) {
          _this.notifications.success(_this.locale.t("devise.passwords.updated_not_active"));

          localStorage.setItem("userEmail", user.email);

          _this.store.queryRecord("user", {}).then(function (user) {
            _this.session.set("currentUser", user);

            _this.session.set("loggedIn", true);

            _this.replaceWith("challenges");
          }).catch(console.error);
        }, function (_ref) {
          var errors = _ref.errors;

          _this.notifications.errorWithList(_this.locale.t("creation_error"), errors);
        });
      }
    }
  });

  _exports.default = _default;
});