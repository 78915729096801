define("innowaze-ember2/routes/challenges/dashboard", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        routeIsReadyForAction: false,
        records: [],
        daysRecords: [],
        lastDate: null,
        daysCounter: null,
        dataLoaded: false
      });

      if (!model.get("hasStarted")) {
        return;
      }

      var today = new Date();
      var yesterday = today.setDate(today.getDate() - 1);
      var d = model.get("ends_on");

      if (yesterday < new Date(d)) {
        d = yesterday;
      }

      if (!controller.endDate) {
        controller.set("endDate", moment(d).format("YYYY-MM-DD"));
      }

      if (!controller.startDate) {
        controller.set("startDate", moment(model.get("starts_on")).format("YYYY-MM-DD"));
      }

      controller.set("selectedReport", controller.get("reports.firstObject.id"));
      controller.set("routeIsReadyForAction", true);

      this._loadData();
    },
    actions: {
      setStartDate: function setStartDate(date) {
        this.controller.set("startDate", moment(date).format("YYYY-MM-DD"));
      },
      setEndDate: function setEndDate(date) {
        this.controller.set("endDate", moment(date).format("YYYY-MM-DD"));
      },
      loadData: function loadData() {
        this._loadData();
      }
    },
    _loadData: function _loadData() {
      var _this = this;

      var datasetId = this.get("controller.selectedReport");
      this.currentModel.reportingKpiOverTime(datasetId, this.get("controller.startDate"), this.get("controller.endDate")).then(function (data) {
        _this.controller.setProperties({
          records: data.records,
          daysRecords: data.last_records,
          lastDate: data.date,
          daysCounter: data.counter,
          dataLoaded: true
        });
      }).catch(function (e) {
        _this.notifications.error("Une erreur s'est produite");
      });
    }
  });

  _exports.default = _default;
});