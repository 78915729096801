define("innowaze-ember2/controllers/ideas/show", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/controllers/evaluations", "innowaze-ember2/utils/formatEvaluation", "innowaze-ember2/utils/normalizeScore"], function (_exports, _objectSpread2, _evaluations, _formatEvaluation, _normalizeScore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_evaluations.default, {
    locale: Ember.inject.service(),
    queryParams: ["panel"],
    panel: null,
    idea: Ember.computed.alias("model.idea"),
    possible_state_events: Ember.computed.alias("model.idea.possible_state_events"),
    challenge: Ember.computed.alias("model.challenge"),
    displayColor: Ember.computed.or("session.isModerator", "model.challenge.display_color"),
    colorRedSelected: Ember.computed.equal("color", "red"),
    colorOrangeSelected: Ember.computed.equal("color", "orange"),
    colorYellowSelected: Ember.computed.equal("color", "yellow"),
    colorGreenSelected: Ember.computed.equal("color", "green"),
    colorBlueSelected: Ember.computed.equal("color", "blue"),
    colorDarkBlueSelected: Ember.computed.equal("color", "darkblue"),
    colorPurpleSelected: Ember.computed.equal("color", "purple"),
    colorGreySelected: Ember.computed.equal("color", "grey"),
    draft: Ember.computed.equal("model.idea.state", "draft"),
    published: Ember.computed.equal("model.idea.state", "published"),
    selected: Ember.computed.equal("model.idea.state", "selected"),
    rejected: Ember.computed.equal("model.idea.state", "rejected"),
    activeAxis: 0,
    relatedIdeas: [],
    // return best related idea
    relatedIdeasToDisplay: Ember.computed("relatedIdeas.[]", function () {
      var _this = this;

      this.relatedIdeas.forEach(function (e) {
        e._score = (0, _normalizeScore.default)(e.normalized, e._score, _this.get("model.idea.description")); // Avoid to recalculate normalized score twice.

        e.normalized = true;
      });
      return this.relatedIdeas.filter(function (idea) {
        return _this.get("model.idea.id") !== idea._id && idea._score >= 30;
      });
    }),
    similarIdeasIds: Ember.computed("relatedIdeasToDisplay", function () {
      if (this.relatedIdeasToDisplay) {
        var ids = this.relatedIdeasToDisplay.mapBy("_id");

        if (this.get("model.idea.root")) {
          // add idea itself on clustering page
          ids.push(this.get("model.idea.id"));
        } else {
          if (this.get("model.idea.parents")) {
            // add atoms of a molecule-idea in order to rebuild it if updating is necessary on clustering page
            var parents_ids = this.get("model.idea.parents").mapBy("id").map(String);
            ids.push(parents_ids);
          }
        }

        return ids;
      }
    }),
    hasTools: Ember.computed("activeTools", function () {
      return this.activeTools.length > 0;
    }),
    canViewTeamOnly: Ember.computed("canEdit", "session.isReviewer", function () {
      return this.canEdit || this.session.isReviewer;
    }),
    activeTools: Ember.computed("model.challenge.tools", "model.challenge.extra_tools", "model.idea.state", function () {
      var _this2 = this;

      return this.get("model.challenge.tools").filter(function (tool) {
        return tool.active;
      }).map(function (t) {
        var tool = Ember.Object.create(t); // case where tool is custom

        if (!isNaN(tool.name)) {
          var extraTool = _this2.get("model.idea.extra_tools").findBy("id", tool.id);

          if (extraTool) {
            // extra_tool only exists if this axis has been evaluated...
            Ember.setProperties(tool, {
              value: Math.floor(extraTool.value),
              evaluated: extraTool.evaluated,
              is_median: extraTool.is_median
            });
          } else {
            // ...otherwise, is_median should be intialized
            Ember.setProperties(tool, {
              evaluated: false,
              is_median: true
            });
          }
        } else {
          Ember.setProperties(tool, {
            value: Math.floor(_this2.get("model.idea").get(tool.name)),
            evaluated: _this2.get("model.idea").get("".concat(tool.name, "_evaluated")),
            is_median: _this2.get("model.idea").get("".concat(tool.name, "_is_median"))
          });
        }

        return tool;
      });
    }),
    activeReviewerTools: Ember.computed("model.challenge.tools", "model.challenge.extra_tools", "model.idea.state", function () {
      var _this3 = this;

      var tools = this.get("model.challenge.tools").filter(function (tool) {
        return tool.active && !tool.computed;
      }).map(function (t) {
        var tool = Ember.Object.create(t);

        var extraToolEval = _this3.get("model.idea.current_user_extra_tools").findBy("id", tool.id);

        if (extraToolEval) {
          Ember.setProperties(tool, {
            value: (0, _formatEvaluation.percentToValue)(extraToolEval.value, _this3.minValue, _this3.maxValue),
            evaluated: extraToolEval.evaluated
          });
        } else {
          Ember.setProperties(tool, {
            value: (0, _formatEvaluation.percentToValue)(_this3.get("model.idea").get("current_user_".concat(tool.name)), _this3.minValue, _this3.maxValue),
            evaluated: _this3.get("model.idea").get("current_user_".concat(tool.name, "_evaluated"))
          });
        }

        tool.set("to_evaluate", tool.evaluation_state === null || tool.evaluation_state === _this3.get("model.idea").get("state"));
        Ember.set(tool, "best_evaluations", _this3.get("model.idea.best_evaluations").findBy("name", tool.name));
        return tool;
      });
      return tools.filterBy("to_evaluate", true);
    }),
    selectedToolCategory: Ember.computed("availableToolCategories", function () {
      return this.availableToolCategories[0];
    }),
    selectedReviewerToolCategory: Ember.computed("availableReviewerToolCategories", function () {
      return this.availableReviewerToolCategories[0];
    }),
    radarData: Ember.computed("filteredActiveTools.@each.value", function () {
      var _this4 = this;

      var filtered_active_tools = this.filteredActiveTools.map(function (t) {
        var value = _this4.get("session.currentChallenge.evaluation_max") * t.value / 100;
        var display_value = Math.round(value * 10) / 10;
        return {
          axis: t.title,
          value: t.value,
          display_value: display_value
        };
      }).filter(function (t) {
        return typeof t.value !== "undefined";
      });
      return filtered_active_tools.length > 2 && this.get("model.challenge.display_radar_chart") ? [filtered_active_tools] : null;
    }),
    evaluationLevels: Ember.computed("session.currentChallenge.evaluation_min", "session.currentChallenge.evaluation_max", function () {
      if (this.get("session.currentChallenge.evaluation_max") <= 10) {
        return this.get("session.currentChallenge.evaluation_max") - this.get("session.currentChallenge.evaluation_min") || 100;
      }
    }),
    displayRadar: Ember.computed("radarData", "session.isReviewer", "session.isModerator", "model.idea.display_idea_evaluations", function () {
      return this.radarData && this.get("session.isReviewer") && !this.get("session.isModerator") && this.get("model.challenge.display_idea_evaluations");
    }),
    canReviewIdea: Ember.computed("session.isReviewer", "idea_author_can_evaluate", "model.idea.to_be_evaluated", function () {
      return (this.get("session.isReviewer") || this.get("model.challenge.idea_author_can_evaluate") && this.get("model.idea.canEdit")) && this.get("model.idea.to_be_evaluated");
    }),
    displayTools: Ember.computed("session.isModerator", "canReviewIdea", "model.idea.display_idea_evaluations", "hasEvaluations", function () {
      return this.get("session.isModerator") || this.canReviewIdea || this.get("model.idea.canEdit") && this.get("model.challenge.idea_author_can_evaluate");
    }),
    canConvertToConcept: Ember.computed.and("model.challenge.wants_concept", "model.idea.no_concept", "session.isModerator", "selected"),
    canChangeState: Ember.computed.or("model.idea.canEdit", "isTeammate"),
    canContribute: Ember.computed("model.challenge", function () {
      return !!this.get("model.challenge").getActiveIdeaModule("contributors");
    }),
    wantReplicate: Ember.computed("model.challenge", function () {
      return !!this.get("model.challenge").getActiveIdeaModule("replicators");
    }),
    canReplicate: Ember.computed("model.challenge", function () {
      // Double check if this is not a master (there is a master.id and the repliocation status)
      // Warning : Hide the replicate button if the solution idea-module is active
      return !!this.get("model.challenge").getActiveIdeaModule("replica") && !this.get("model.idea.master.id") && !this.get("model.idea.is_replica") && !this.get("model.challenge.hasSolutionIdeaModule");
    }),
    canCreateDepartment: Ember.computed("session.isAdmin", "model.idea.author_department_id", "draft", function () {
      return !this.draft && this.get("model.idea.new_department") && Object.keys(this.get("model.idea.new_department")).length > 0 && !this.get("model.idea.author_department_id") && this.get("session.currentUser.admin");
    }),
    canUpdateDepartment: Ember.computed.and("model.idea.author_department_id", "session.currentUser.admin"),
    isTeammate: Ember.computed("session.currentUser.id", "model.idea.teammates", function () {
      var _this5 = this;

      return this.get("model.idea.teammates").find(function (user) {
        return user.id === _this5.get("session.currentUser.id");
      }) !== undefined;
    }),
    ideaIsClosed: Ember.computed("session.isModerator", "session.isAdmin", "model.idea.rejected", function () {
      return this.get("model.idea.rejected") && !(this.get("session.isModerator") || this.get("session.isAdmin"));
    }),
    matchingExperts: [],
    expertsRequested: [],
    expertsToDisplay: Ember.computed("matchingExperts.[]", "expertsRequested.[]", function () {
      var _this6 = this;

      return this.matchingExperts.reject(function (user) {
        return _this6.expertsRequested.findBy("id", user.id);
      });
    }),
    activeModules: Ember.computed("model.challenge.ideas", "model.idea.index_stage", "model.idea.extra_modules", function () {
      var _this7 = this;

      var activeModules = this.get("model.challenge.ideas").filter(function (module) {
        return module.active;
      }).map(function (module) {
        return (0, _objectSpread2.default)({}, module, {
          is_displayed: _this7.get("model.idea.index_stage") >= module.index_stage
        });
      }); // Add extra_modules data

      if (this.get("model.idea.extra_modules")) {
        this.get("model.idea.extra_modules").forEach(function (extraModule) {
          var m = activeModules.findBy("id", extraModule.id);

          if (m) {
            Ember.set(m, "description", extraModule.description);
          }
        });
      } // Add extra_modules data from parents


      if (this.get("model.idea.parents")) {
        this.get("model.idea.parents").forEach(function (parent) {
          if (parent.extra_modules) {
            parent.extra_modules.forEach(function (extraModule) {
              var m = activeModules.findBy("id", extraModule.id);

              if (m) {
                if (!Ember.get(m, "parentDescriptions")) {
                  Ember.set(m, "parentDescriptions", []);
                }

                Ember.get(m, "parentDescriptions").pushObject({
                  author: parent.author,
                  name: parent.name,
                  score: parent.score,
                  description: parent.extra_modules.findBy("id", extraModule.id).description
                });
              }
            });
          }
        });
      }

      return activeModules;
    }),
    meteoIcon: Ember.computed("activeModules", "model.idea.meteo", function () {
      var meteo_module = this.activeModules.findBy("name", "meteo");

      if (!meteo_module) {
        return null;
      }

      var icons = meteo_module.params.icons.map(function (icon) {
        return (0, _objectSpread2.default)({}, icon);
      });
      return icons.findBy("id", this.get("model.idea.meteo"));
    })
  });

  _exports.default = _default;
});