define("innowaze-ember2/templates/concepts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OlO3m81S",
    "block": "{\"symbols\":[\"concept\"],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"small-12 columns\"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[0,\"\\n        \"],[1,[27,\"i18n\",[\"header.menu.concepts_list\"],[[\"concepts\"],[[27,\"name-for\",[[23,[\"model\",\"challenge\"]],\"concepts\",\"plural_concept\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"challenge-name\"],[9],[0,\"\\n        \"],[1,[23,[\"model\",\"challenge\",\"name\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"ul\"],[11,\"class\",\"fat-list small-block-grid-1 medium-block-grid-2 large-block-grid-4\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"concepts\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"visible\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"concept-card\",null,[[\"concept\",\"challenge\",\"current_user_given_points\"],[[22,1,[]],[23,[\"model\",\"challenge\"]],[23,[\"current_user_given_points\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts.hbs"
    }
  });

  _exports.default = _default;
});