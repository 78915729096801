define("innowaze-ember2/utils/computedPromise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedPromise;
  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  function computedPromise() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var func = args.pop();
    return Ember.computed.apply(void 0, args.concat([function () {
      return ObjectPromiseProxy.create({
        promise: func.apply(this)
      });
    }]));
  }
});