define("innowaze-ember2/adapters/linked-debate-badge", ["exports", "ember-inflector", "innowaze-ember2/adapters/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    }
  });

  _exports.default = _default;
});