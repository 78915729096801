define("innowaze-ember2/components/modules/light-scope-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.query("scope", this.get("module.params")).then(function (scopes) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.set("availableScopeHubs", scopes.filterBy("level", "Hub"));

        _this.set("availableScopeClusters", scopes.filterBy("level", "Cluster"));

        _this.set("availableScopeBLs", scopes.filterBy("level", "Bl"));
      });
    },
    showAllScopeClusters: false,
    completedDidChange: Ember.on("init", Ember.observer("model.scope_clusters.[]", "model.scope_BLs.[]", function () {
      this.set("module.isNotCompleted", this.get("model.scope_clusters").length === 0 || this.get("model.scope_BLs").length === 0);
    })),
    firstScopeClusters: Ember.computed("model.scope_clusters.[]", function () {
      return this.get("model.scope_clusters").slice(0, 10);
    }),
    hasMoreClusters: Ember.computed("model.scope_clusters.[]", function () {
      return this.get("model.scope_clusters").length > 10;
    }),
    actions: {
      clearHubs: function clearHubs() {
        this.model.set("scope_hubs", []);
      },
      clearClusters: function clearClusters() {
        this.model.set("scope_clusters", []);
      },
      clearBLs: function clearBLs() {
        this.model.set("scope_BLs", []);
      },
      moreScopeClusters: function moreScopeClusters() {
        this.toggleProperty("showAllScopeClusters");
      },
      removeHub: function removeHub(hub) {
        var e = this.get("model.scope_hubs").findBy("id", parseInt(hub.id));

        if (e) {
          this.get("model.scope_hubs").removeObject(e);
        }
      },
      removeBL: function removeBL(bl) {
        var e = this.get("model.scope_BLs").findBy("id", parseInt(bl.id));

        if (e) {
          this.get("model.scope_BLs").removeObject(e);
        }
      },
      removeCluster: function removeCluster(cluster) {
        var e = this.get("model.scope_clusters").findBy("id", parseInt(cluster.id));

        if (e) {
          this.get("model.scope_clusters").removeObject(e);
        }
      }
    }
  });

  _exports.default = _default;
});