define("innowaze-ember2/mixins/routes/linkedDebateBadge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("linkedDebateBadges", []);
    },
    actions: {
      addLinkedDebateBadge: function addLinkedDebateBadge(attrs) {
        this.send("toggleBadgeModal");
        this.set("controller.currentBadge", this.store.createRecord("linked-debate-badge", attrs));

        var picture = this._getModel().get("pictures").findBy("id", attrs.badgeable_id);

        if (picture) {
          Ember.get(picture, "badges").addObject(this.get("controller.currentBadge"));
        }

        if (this.get("controller.linkedDebateBadges")) {
          this.get("controller.linkedDebateBadges").addObject(this.get("controller.currentBadge"));
        }
      },
      toggleBadgeModal: function toggleBadgeModal() {
        this.controller.toggleProperty("badgeModalVisible");

        if (this.get("controller.currentBadge")) {
          // Deleting badge left unlinked in creation process
          var targetedPicture = this._getModel().get("pictures").findBy("id", this.get("controller.currentBadge.badgeable_id"));

          if (targetedPicture) {
            Ember.get(targetedPicture, "badges").removeObject(this.get("controller.currentBadge"));
          }

          this.get("controller.linkedDebateBadges").removeObject(this.get("controller.currentBadge"));
          this.set("controller.currentBadge", null);
        }
      },
      linkBadge: function linkBadge() {
        var _this = this;

        var currentBadge = this.get("controller.currentBadge");

        var debate = this._getModel().get("debates").findBy("id", currentBadge.get("debate_id"));

        currentBadge.set("name", debate.get("question"));
        currentBadge.set("explanation", debate.get("explanation"));
        currentBadge.save().then(function (data) {
          currentBadge.set("id", data.id);

          var targetedPicture = _this._getModel().get("pictures").findBy("id", _this.get("controller.currentBadge.badgeable_id"));

          Ember.get(targetedPicture, "badges").addObject(_this.get("controller.currentBadge"));

          _this.set("controller.currentBadge", null);

          _this.send("toggleBadgeModal");
        });
      },
      displayDebateForBadge: function displayDebateForBadge(badge) {
        if (!this._getModel().get("challenge_id")) {
          this.transitionTo("challengeDebates.show", this._getModel().get("id"), badge.get("debate_id"));
          return;
        }

        this.transitionTo("debates.show", this._getModel().get("challenge_id"), this._getModel().get("id"), badge.get("debate_id"));
      },
      deleteBadge: function deleteBadge(badge) {
        var _this2 = this;

        if (!confirm("Êtes-vous sûr de vouloir supprimer ce badge ?")) {
          return;
        }

        badge.destroyRecord().then(function () {
          var pic = _this2._getModel().get("pictures").findBy("id", badge.badgeable_id);

          if (pic) {
            Ember.get(pic, "badges").removeObject(badge);
          }

          _this2.get("controller.linkedDebateBadges").removeObject(badge);
        });
      },
      deleteDroppable: function deleteDroppable(picture) {
        var _this3 = this;

        if (confirm("Êtes-vous sûr de vouloir supprimer cette image ?")) {
          picture.destroyRecord().then(function () {
            _this3._getModel().get("pictures").removeObject(picture);
          });
        }
      }
    },
    _getModel: function _getModel() {
      if ("get" in this.currentModel) {
        return this.currentModel;
      }

      if (this.currentModel.concept) {
        return this.currentModel.concept;
      }
    }
  });

  _exports.default = _default;
});