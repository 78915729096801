define("innowaze-ember2/helpers/percentToValue", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "innowaze-ember2/utils/formatEvaluation"], function (_exports, _slicedToArray2, _formatEvaluation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.percentToValue = percentToValue;
  _exports.default = void 0;

  function percentToValue(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        value = _ref2[0],
        min = _ref2[1],
        max = _ref2[2];

    return (0, _formatEvaluation.percentToValue)(value, min, max);
  }

  var _default = Ember.Helper.helper(percentToValue);

  _exports.default = _default;
});