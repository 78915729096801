define("innowaze-ember2/components/display-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    attributeBindings: ["title", "message"],
    actions: {
      changeState: function changeState(event) {
        $("#warningModal").foundation("reveal", "close");
        this.sendAction("onChangeState", event);
      },
      cancel: function cancel() {
        $("#warningModal").foundation("reveal", "close");
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, function () {
        $(document).foundation("reveal");
        $("#warningModal").foundation("reveal", "open");
      });
    }
  });

  _exports.default = _default;
});