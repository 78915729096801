define("innowaze-ember2/serializers/comment", ["exports", "innowaze-ember2/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      delete json.author_id;
      delete json.avatar_url;
      delete json.canManage;
      delete json.created_at;
      delete json.is_my_comment;
      delete json.picture_name;
      delete json.picture_url;
      delete json.score;
      delete json.score_string;
      delete json.small_picture_url;
      return {
        comment: json
      };
    }
  });

  _exports.default = _default;
});