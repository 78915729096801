define("innowaze-ember2/routes/debates/common", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    deactivate: function deactivate() {
      if (this.currentModel.debate.get("hasDirtyAttributes")) {
        this.currentModel.debate.rollbackAttributes();
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.reopen({
        removeAvailableAnswersIfNotNeeded: Ember.observer("model.debate.type", function () {
          if (!model.debate.get("hasOptions")) {
            model.debate.get("available_answers").clear();
          }
        })
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        this.set("controller.saveDisabled", true);
        this.currentModel.debate.save().then(function () {
          return _this._success();
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this._redirection();
      },
      delete: function _delete() {
        var _this2 = this;

        if (confirm(this.locale.t("debates.edit.deletion_confirmation"))) {
          this.currentModel.debate.deleteRecord();
          this.currentModel.debate.save({
            adapterOptions: {
              debatable_id: this.currentModel.debate.get("debatable_id"),
              debatable_type: this.currentModel.debate.get("debatable_type")
            }
          }).then(function () {
            _this2.transitionTo("challenges.show", _this2.currentModel.challenge.id);

            _this2.notifications.success(_this2.locale.t("debates.edit.deleted"));
          });
        }
      },
      addAnswer: function addAnswer() {
        this.currentModel.debate.get("available_answers").addObject(Ember.Object.create({
          id: null,
          text: ""
        }));
      },
      removeAnswer: function removeAnswer(answer) {
        var answers = this.currentModel.debate.get("available_answers");

        if (answers.length > 2) {
          answer.set("_destroy", true);
        } else {
          this.notifications.error("Vous devez avoir au moins deux réponses possibles");
        }
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.debate.set("banner_id", files.get("firstObject").id);
        this.currentModel.debate.set("banner_url", files.get("firstObject").file_url);
      },
      deleteBanner: function deleteBanner() {
        this.currentModel.debate.setProperties({
          banner_id: null,
          banner_url: null
        });
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      }
    },
    _redirection: function _redirection() {
      var debatableType = this.currentModel.debate.get("debatable_type");
      var challengeId = this.currentModel.challenge.id;

      if (!this.currentModel.debate.get("id")) {
        this.transitionTo("challenges.show", challengeId);
      }

      switch (debatableType) {
        case "Challenge":
          this.transitionTo("challenges.debates", challengeId);
          break;

        case "Concept":
          this.transitionTo("concepts.show", challengeId, this.currentModel.debate.get("debatable_id"));
          break;

        case "Pathway":
          this.transitionTo("pathwayDebates.show", challengeId, this.currentModel.debate.get("debatable_id"), this.currentModel.debate.get("id"));
          break;
      }
    },
    _success: function _success() {
      this.set("controller.saveDisabled", false);

      this._redirection();

      this.notifications.success(this.successMessage);
    },
    _error: function _error(response) {
      this.set("controller.saveDisabled", false);
      this.notifications.errorWithList(this.errorMessage, response.errors);
    }
  });

  _exports.default = _default;
});