define("innowaze-ember2/controllers/user-modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedModules: Ember.computed("model", function () {
      return this.model.filterBy("invisible", false).sortBy("position");
    })
  });

  _exports.default = _default;
});