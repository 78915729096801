define("innowaze-ember2/routes/users/import", ["exports", "innowaze-ember2/utils/emailPattern"], function (_exports, _emailPattern) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model() {
      return [];
    },
    actions: {
      parseData: function parseData(data) {
        var _this = this;

        data.split(/\n|\r/).forEach(function (row) {
          var array = row.split(";");
          var user = {
            email: array[0].trim(),
            first_name: array[1],
            last_name: array[2]
          };

          if (user.email && user.email.match(_emailPattern.default)) {
            _this.currentModel.pushObject(user);
          }
        });
      },
      createUsers: function createUsers() {
        var _this2 = this;

        this.store.adapterFor("user").import(this.currentModel).then(function (data) {
          return _this2._success(data);
        }, function () {
          return _this2._error();
        });
      }
    },
    _success: function _success(data) {
      if (data.length > 0) {
        this.controllerFor("users").get("model").pushObjects(data);
      }

      this.currentModel.clear();
      this.transitionTo("users");
      this.notifications.success("Les utilisateurs ont été importés.");
    },
    _error: function _error() {
      this.notifications.error("Un problème empêche l'import des données.");
    }
  });

  _exports.default = _default;
});