define("innowaze-ember2/models/concept", ["exports", "ember-data", "innowaze-ember2/mixins/models/commentHandler", "innowaze-ember2/models/application"], function (_exports, _emberData, _commentHandler, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_commentHandler.default, {
    localeService: Ember.inject.service("locale"),
    allocated_budget: _emberData.default.attr("number"),
    author_departments: _emberData.default.attr(),
    author_site: _emberData.default.attr(),
    author_name: _emberData.default.attr("string"),
    author: _emberData.default.belongsTo("user", {
      async: false
    }),
    avatar_url: _emberData.default.attr("string"),
    blueocean_active: _emberData.default.attr("boolean"),
    blueocean_published: _emberData.default.attr(),
    budget: _emberData.default.attr("number"),
    can_next_state: _emberData.default.attr("boolean"),
    can_previous_state: _emberData.default.attr("boolean"),
    canevas: _emberData.default.attr(),
    challenge_id: _emberData.default.attr("number"),
    comments: _emberData.default.hasMany("comment", {
      async: false
    }),
    commentsCount: _emberData.default.attr("number"),
    contributors: _emberData.default.hasMany("user", {
      async: false
    }),
    concept_analysis: _emberData.default.attr(),
    concept_author_can_evaluate: _emberData.default.attr("boolean"),
    concept_expert_validation: _emberData.default.attr("boolean"),
    concept_management_committee_validation: _emberData.default.attr("boolean"),
    create_factors: _emberData.default.attr(),
    created_at: _emberData.default.attr("date"),
    current_user_evaluation_description: _emberData.default.attr("string"),
    current_user_given_points: _emberData.default.attr("number"),
    current_user_rating: _emberData.default.attr("number"),
    current_user_tools: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    debates: _emberData.default.hasMany("debate", {
      async: false
    }),
    description: _emberData.default.attr("string"),
    display_concept_evaluations_boxplot: _emberData.default.attr("boolean"),
    display_concept_evaluations: _emberData.default.attr("boolean"),
    documents: _emberData.default.attr(),
    domains: _emberData.default.attr(),
    eliminate_factors: _emberData.default.attr(),
    evaluation_description: _emberData.default.attr("string"),
    followed: _emberData.default.attr("boolean"),
    free_field: _emberData.default.attr(),
    has_blueocean: _emberData.default.attr("boolean"),
    idea_id: _emberData.default.attr("number"),
    idea_name: _emberData.default.attr("string"),
    image_url: _emberData.default.attr("string"),
    index_stage: _emberData.default.attr(),
    is_budget: _emberData.default.attr("boolean"),
    isAuthor: _emberData.default.attr("boolean"),
    keywords: _emberData.default.attr(),
    knowledges: _emberData.default.attr(),
    likes: _emberData.default.attr(),
    likesCount: _emberData.default.attr("number"),
    logo_url: _emberData.default.attr("string"),
    logo_id: _emberData.default.attr(),
    moderate_concepts: _emberData.default.attr("boolean"),
    modules: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    name: _emberData.default.attr("string"),
    next_state_name: _emberData.default.attr("string"),
    percent_budget: _emberData.default.attr("number"),
    previous_state_name: _emberData.default.attr("string"),
    priority_concepts: _emberData.default.attr("boolean"),
    private: _emberData.default.attr("boolean"),
    raise_factors: _emberData.default.attr(),
    reduce_factors: _emberData.default.attr(),
    slide_url: _emberData.default.attr(),
    slogan: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    summary: _emberData.default.attr(),
    tags: _emberData.default.hasMany("tag", {
      async: false
    }),
    teammates: _emberData.default.hasMany("user", {
      async: false
    }),
    tools: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    total_rating: _emberData.default.attr("number"),
    updated_at: _emberData.default.attr("date"),
    use_concept_vote: _emberData.default.attr("boolean"),
    user_id: _emberData.default.attr("number"),
    visible: _emberData.default.attr("boolean"),
    voteIsDisabled: _emberData.default.attr("boolean"),
    pictures: _emberData.default.hasMany("upload", {
      async: false
    }),
    draft: Ember.computed.equal("state", "draft"),
    formulated: Ember.computed.equal("state", "formulated"),
    published: Ember.computed.equal("state", "published"),
    documented: Ember.computed.equal("state", "documented"),
    to_be_documented: Ember.computed.equal("state", "to_be_documented"),
    evaluated: Ember.computed.equal("state", "evaluated"),
    to_be_evaluated: Ember.computed.equal("state", "to_be_evaluated"),
    completed: Ember.computed.equal("state", "completed"),
    selected: Ember.computed.equal("state", "selected"),
    rejected: Ember.computed.equal("state", "rejected"),
    to_be_pitched: Ember.computed.equal("state", "to_be_pitched"),
    hasPreviousState: Ember.computed.not("draft"),
    activeModules: Ember.computed("modules", function () {
      return this.modules.filter(function (module) {
        return module.active;
      });
    }),
    isBudget: Ember.computed("modules", "budget", "use_concept_vote", function () {
      return this.activeModules.findBy("name", "budget") && this.budget > 0 && this.use_concept_vote;
    }),
    activeTools: Ember.computed("tools", function () {
      return this.tools.filter(function (tool) {
        return tool.active;
      });
    }),
    accessibility: Ember.computed("activeTools", function () {
      return this.activeTools.findBy("name", "accessibility");
    }),
    stakes: Ember.computed("activeTools", function () {
      return this.activeTools.findBy("name", "stakes");
    }),
    evaluatedCount: Ember.computed("activeTools", function () {
      return this.activeTools.filter(function (tool) {
        return tool.evaluated;
      }).length;
    }),
    changed: Ember.observer("name", "description", function () {
      this.set("hasChanges", true);
    }),
    followText: Ember.computed("followed", function () {
      if (this.followed) {
        return this.localeService.t("concepts.show.unfollow");
      }

      return this.localeService.t("concepts.show.follow");
    }),
    previousState: function previousState() {
      return this._callAdapter("previousState");
    },
    nextState: function nextState() {
      return this._callAdapter("nextState");
    },
    reject: function reject() {
      return this._callAdapter("reject");
    },
    multipleEvaluations: function multipleEvaluations() {
      return this._callAdapter("multipleEvaluations");
    },
    updateRating: function updateRating(rating) {
      return this._callAdapter("updateRating", rating);
    },
    requestExpert: function requestExpert(userId) {
      return this._callAdapter("requestExpert", userId);
    },
    toggleFollow: function toggleFollow() {
      return this._callAdapter("toggleFollow");
    },
    toggleContribute: function toggleContribute() {
      return this._callAdapter("toggleContribute");
    },
    toggleIsMedian: function toggleIsMedian(toolName, isMedian) {
      return this._callAdapter("toggleIsMedian", toolName, isMedian);
    },
    addTeam: function addTeam(userId) {
      return this._callAdapter("addTeam", userId);
    },
    toggleAnalysis: function toggleAnalysis(kind, active) {
      return this._callAdapter("toggleAnalysis", kind, active);
    },
    updateCurrentUserEvaluation: function updateCurrentUserEvaluation(data) {
      return this._callAdapter("updateCurrentUserEvaluation", data);
    }
  });

  _exports.default = _default;
});