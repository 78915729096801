define("innowaze-ember2/components/news-module", ["exports", "innowaze-ember2/mixins/routes/toggleNewsPublish"], function (_exports, _toggleNewsPublish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_toggleNewsPublish.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    classNames: ["news-module", "page-module"],
    visible: 3,
    actions: {
      deleteNews: function deleteNews(news) {
        var _this = this;

        news.destroyRecord().then(function () {
          _this.notifications.success(_this.locale.t("challenges.show.news_deleted"));
        });
      },
      showAllNews: function showAllNews() {
        this.set("visible", this.get("news.length"));
      }
    },
    visibleNews: Ember.computed("news.[]", "visible", function () {
      var news = this.news || [];
      return news.slice(0, this.visible);
    }),
    allNewsVisible: Ember.computed("news.[]", "visible", function () {
      return this.get("news.length") <= this.visible;
    })
  });

  _exports.default = _default;
});