define("innowaze-ember2/templates/knowledges/modules/main-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OXjZ/v+P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"Links To Main Files\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"columns medium-12\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"medium-block-grid-4\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/assets/images/kit/presentation.svg\"],[11,\"class\",\"knowledge-kit-link\"],[9],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\"],[[23,[\"model\",\"presentation_url\"]],\"Enter URL to Project Presentation file\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/assets/images/kit/charter.svg\"],[11,\"class\",\"knowledge-kit-link\"],[9],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\"],[[23,[\"model\",\"charter_url\"]],\"Enter URL to Project Charter file\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/assets/images/kit/bcase.svg\"],[11,\"class\",\"knowledge-kit-link\"],[9],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\"],[[23,[\"model\",\"bcase_url\"]],\"Enter URL to Business case file\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/assets/images/kit/rex.svg\"],[11,\"class\",\"knowledge-kit-link\"],[9],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\"],[[23,[\"model\",\"rex_url\"]],\"Enter URL to Storyline-REX file\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/knowledges/modules/main-links.hbs"
    }
  });

  _exports.default = _default;
});