define("innowaze-ember2/adapters/tag", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      if (query.supertags) {
        delete query.supertags;
        return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("tag"), "/supertags.json");
      }

      return this._super.apply(this, arguments) + "/search.json";
    },
    searchTags: function searchTags(challenge_id, query) {
      return this.store.query("tag", query).then(function (tags) {
        return tags.map(function (tag) {
          tag.set("supertag", tag.get("supertags").some(function (supertag) {
            return supertag.challenge_id.toString() === challenge_id.toString();
          }));
          tag.send("pushedData");
          return tag;
        });
      });
    },
    setSupertag: function setSupertag(id, tag, challenge_id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/supertags.json"), "POST", {
        data: {
          tag_id: id,
          challenge_id: challenge_id
        }
      });
    },
    removeSupertag: function removeSupertag(id, tag, supertag_id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/supertags/").concat(supertag_id, ".json"), "DELETE");
    },
    mergeTags: function mergeTags(mergedTags, targetTag, clear) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("tag"), "/merge.json"), "POST", {
        data: {
          merged: mergedTags.mapBy("id"),
          target: targetTag.id,
          clear: clear
        }
      });
    }
  });

  _exports.default = _default;
});