define("innowaze-ember2/components/modules/domains-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    store: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.editMode) {
        this.store.query("domain", {
          challenge_id: this.get("challenge.id")
        }).then(function (domains) {
          // be sure that the component isn't destroyed before call set method
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.set("availableDomains", domains);
        });
      }
    },
    completedDidChange: Ember.on("init", Ember.observer("model.domains.[]", function () {
      this.set("module.isNotCompleted", !this.get("model.domains") || this.get("model.domains").length === 0);
    })),
    actions: {
      setDomains: function setDomains(domains) {
        this.model.set("domains", domains);
      }
    }
  });

  _exports.default = _default;
});