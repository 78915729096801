define("innowaze-ember2/templates/pathways", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PV/eKPBe",
    "block": "{\"symbols\":[\"pathway\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"i18n\",[\"pathways.index.title\"],null],false],[0,\" \"],[1,[23,[\"model\",\"challenge\",\"name\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"fat-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"pathways\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"banner\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"picture\"],[9],[0,\"\\n          \"],[1,[27,\"clearing-image\",null,[[\"object\"],[[22,1,[]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n          \"],[4,\"link-to\",[\"pathways.show\",[22,1,[\"challenge_id\"]],[22,1,[\"id\"]]],null,{\"statements\":[[1,[22,1,[\"name\"]],false]],\"parameters\":[]},null],[7,\"br\"],[9],[10],[0,\"\\n          \"],[1,[22,1,[\"description\"]],true],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"isAdmin\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\"],[11,\"class\",\"delete-pathway\"],[3,\"action\",[[22,0,[]],\"delete\",[22,1,[]]]],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"isAdmin\"]]],null,{\"statements\":[[4,\"link-to\",[\"pathways.new\",[23,[\"model\",\"challenge\",\"id\"]]],[[\"class\"],[\"button-primary right\"]],{\"statements\":[[0,\"      \"],[1,[27,\"i18n\",[\"pathways.index.new\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/pathways.hbs"
    }
  });

  _exports.default = _default;
});