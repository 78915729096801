define("innowaze-ember2/routes/tag-triggers/new", ["exports", "innowaze-ember2/routes/tag-triggers/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    errorMessage: Ember.computed(function () {
      return this.locale.t("creation_error");
    }),
    successMessage: "Le mot clé automatique a été créé.",
    model: function model() {
      return this.store.createRecord("tagTrigger");
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set("keywords", []);
    }
  });

  _exports.default = _default;
});