define("innowaze-ember2/controllers/concepts/edit", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/utils/stripTags"], function (_exports, _objectSpread2, _stripTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isAuthor: Ember.computed("session.currentuser", "model.concept.author", function () {
      return this.get("session.currentUser.id") === this.get("model.concept.author.id");
    }),
    canChangeTeam: Ember.computed("session.isModerator", "session.currentUser.id", "model.concept.user_id", function () {
      return this.get("session.isModerator") || this.isAuthor;
    }),
    author: Ember.computed("model.concept.author", function () {
      return this.get("model.concept.author") ? this.get("model.concept.author") : null;
    }),
    members: Ember.computed("model.concept.author", function () {
      return this.get("model.concept.author") ? [this.get("model.concept.author")] : [];
    }),
    membersIncludingAuthor: Ember.computed.alias("members"),
    membersExcludingAuthor: Ember.computed("members.[]", "model.concept.author", function () {
      return this.get("model.concept.author") ? this.members.rejectBy("id", this.get("model.concept.author.id")) : this.members;
    }),
    relatedKnowledges: [],
    relatedKnowledgesToDisplay: Ember.computed("model.concept.knowledges.[]", "relatedKnowledges.[]", function () {
      var _this = this;

      if (this.get("model.concept.knowledges")) {
        return this.relatedKnowledges.reject(function (knowledge) {
          return _this.get("model.concept.knowledges").findBy("id", knowledge._id);
        });
      }

      return [];
    }),
    relatedTags: [],
    relatedTagsToDisplay: Ember.computed("model.concept.tags.[]", "relatedTags.[]", function () {
      var _this2 = this;

      return this.relatedTags.reject(function (tag) {
        return _this2.get("model.concept.tags").findBy("name", tag.get("tag").toLowerCase());
      });
    }),
    activeFormats: Ember.computed("model.challenge.concept_formats", "model.concept.index_stage", function () {
      var _this3 = this;

      var activeFormats = this.get("model.challenge.concept_formats").filter(function (module) {
        return module.active;
      }).map(function (module) {
        return (0, _objectSpread2.default)({}, module, {
          is_displayed: _this3.get("model.concept.index_stage") >= module.index_stage
        });
      });

      if (this.get("model.concept.modules")) {
        this.get("model.concept.modules").forEach(function (extraModule) {
          var m = activeFormats.findBy("id", extraModule.id);

          if (m) {
            Ember.set(m, "description", extraModule.description);
          }
        });
      }

      return activeFormats;
    }),
    searchCriteriaChanged: Ember.observer("model.concept.name", "model.concept.description", "model.concept.tags.[]", "model.concept.hasChanges", function () {
      var _this4 = this;

      // Don't need to search if model hasn't been modified
      if (!this.get("model.concept.hasChanges") || !this.routeIsReadyForAction) {
        return false;
      } // striptag text from wysiwyg


      var descriptionWithoutTags = (0, _stripTags.default)(this.get("model.concept.description")); // Don't need to search if main attributes are blank

      if (Ember.isBlank(this.get("model.concept.name")) || Ember.isBlank(descriptionWithoutTags)) {
        return false;
      } // ------------- HL 18jul.2015 --------- tags has been added to the query string instead of acting as a "must" in {query: query, tags:tags}


      var query = [this.get("model.concept.name"), descriptionWithoutTags, this.get("model.concept.typology")].join(" ") + this.get("model.concept.tags").mapBy("name").join(" "); // Search for related knowledges

      if (this.activeFormats.findBy("name", "knowledges")) {
        this.store.adapterFor("knowledge").rawSearch({
          query: query
        }).then(function (knowledges) {
          _this4.set("relatedKnowledges", knowledges.filter(function (elt) {
            return elt._score > 0.01;
          }));
        });
      } // Search for tags to be suggested


      if (arguments[1] !== "model.concept.tags.[]") {
        var matchingTags = [];
        var downcasedDescription = descriptionWithoutTags.toLowerCase();

        if (!this.availableTriggers) {
          return;
        }

        this.availableTriggers.forEach(function (trigger) {
          trigger.get("keywords").forEach(function (keyword) {
            var downcasedKeyword = keyword.toLowerCase();

            var nonAccentedKeyword = _this4._stripVowelAccent(downcasedKeyword);

            if (downcasedDescription.indexOf(downcasedKeyword) > -1 || downcasedDescription.indexOf(nonAccentedKeyword) > -1) {
              if (!matchingTags.includes(trigger)) {
                matchingTags.push(trigger);
              }
            }
          });
        });
        this.set("relatedTags", matchingTags);
      }
    }),
    activeTools: Ember.computed("model.challenge.concept_tools", "model.concept.tools", function () {
      var _this5 = this;

      return this.get("model.challenge.concept_tools").filter(function (tool) {
        return tool.active;
      }).map(function (tool) {
        var evaluation = _this5.get("model.concept.tools").findBy("id", tool.id);

        if (evaluation) {
          Ember.setProperties(tool, {
            value: evaluation.value,
            evaluated: evaluation.evaluated
          });
        }

        return tool;
      });
    }),
    stakes: Ember.computed("activeTools", function () {
      var tool = this.activeTools.findBy("id", 1);
      return tool ? tool.value : 0;
    }),
    accessibility: Ember.computed("activeTools", function () {
      var tool = this.activeTools.findBy("id", 2);
      return tool ? tool.value : 0;
    }),
    _stripVowelAccent: function _stripVowelAccent(str) {
      var rExps = [{
        re: /[\xC0-\xC6]/g,
        ch: "A"
      }, {
        re: /[\xE0-\xE6]/g,
        ch: "a"
      }, {
        re: /[\xC8-\xCB]/g,
        ch: "E"
      }, {
        re: /[\xE8-\xEB]/g,
        ch: "e"
      }, {
        re: /[\xCC-\xCF]/g,
        ch: "I"
      }, {
        re: /[\xEC-\xEF]/g,
        ch: "i"
      }, {
        re: /[\xD2-\xD6]/g,
        ch: "O"
      }, {
        re: /[\xF2-\xF6]/g,
        ch: "o"
      }, {
        re: /[\xD9-\xDC]/g,
        ch: "U"
      }, {
        re: /[\xF9-\xFC]/g,
        ch: "u"
      }, {
        re: /[\xD1]/g,
        ch: "N"
      }, {
        re: /[\xF1]/g,
        ch: "n"
      }];
      rExps.forEach(function (el) {
        return str = str.replace(el.re, el.ch);
      });
      return str;
    }
  });

  _exports.default = _default;
});