define("innowaze-ember2/routes/universes/common", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _cleanCurrentModel, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get("session.configuration.dict.universes")) {
        this.replaceWith("challenges");
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("members", model.get("admins").toArray());
    },
    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function (result) {
          return _this._success(result);
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("universes");
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      thumbnailUploaded: function thumbnailUploaded(files) {
        var file = files.get("firstObject");
        this.currentModel.set("thumbnail_id", file.id);
        this.currentModel.set("thumbnail_url", file.file_url);
      },
      bannerUploaded: function bannerUploaded(files) {
        var file = files.get("firstObject");
        this.currentModel.set("banner_id", file.id);
        this.currentModel.set("banner_url", file.file_url);
      },
      deleteBanner: function deleteBanner() {
        this.currentModel.setProperties({
          banner_id: null,
          banner_url: null
        });
      }
    },
    _success: function _success() {
      this.notifications.success(this.successMessage);
      this.transitionTo("universes");
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.errorMessage, errors);
    }
  });

  _exports.default = _default;
});