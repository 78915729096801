define("innowaze-ember2/components/file-upload", ["exports", "innowaze-ember2/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ["data-cy"],
    hide: true,
    progress: 0,
    isPublic: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      var options = {
        dropZone: this.$(),
        pasteZone: null,
        dataType: "json",
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        formData: {
          is_public: this.isPublic
        },
        url: _configuration.default.apiUrl + "/api/uploads.json",
        done: function done(event, data) {
          var files = data.result;

          _this.sendAction("filesUploaded", files);
        },
        start: function start() {
          _this.setProperties({
            progress: 0,
            hide: false
          });
        },
        stop: function stop() {
          _this.set("hide", true);
        },
        progressall: function progressall(event, data) {
          _this.set("progress", parseInt(data.loaded / data.total * 100));
        }
      };
      this.$("input[type='file']").fileupload(options);
    },
    progressStyle: Ember.computed("progress", function () {
      return Ember.String.htmlSafe("width: ".concat(this.progress, "%"));
    }),
    backgroundStyle: Ember.computed("background", "width", "height", function () {
      var background = this.background;
      var width = this.width;
      var height = this.height;

      if (!background) {
        return Ember.String.htmlSafe("");
      } // Sometimes we have a domain name; sometimes we don't.
      // Sometimes we manipulate an Ember.String Object; sometimes we don't.


      background = background.string ? background.string.replace(/https?:/, "") : background.replace(/https?:/, "");

      var apiUrl = _configuration.default.apiUrl.replace(/https?:/, "");

      var index = background.indexOf(apiUrl);

      if (index !== -1) {
        background = background.substr(index + apiUrl.length);
      }

      return Ember.String.htmlSafe("background: url(".concat(!this.defaultPicture ? _configuration.default.apiUrl : "").concat(background, ") no-repeat;\n       border: none;\n       background-size: 100%;\n       background-position: center;\n       width: ").concat(width, ";\n       height: ").concat(height, ";"));
    }),
    actions: {
      removeUpload: function removeUpload() {
        this.sendAction("removeUpload");
      }
    }
  });

  _exports.default = _default;
});