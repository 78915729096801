define("innowaze-ember2/components/dot-graph", ["exports", "ramda/src/clamp"], function (_exports, _clamp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    classNames: ["graph"],
    classNameBindings: ["disabled"],
    xValue: 0,
    yValue: 0,
    disabled: false,
    xLabel: Ember.computed(function () {
      return this.locale.t("ideas.accessibility");
    }),
    yLabel: Ember.computed(function () {
      return this.locale.t("ideas.stakes");
    }),
    dot: null,
    // TODO manage draggable dot
    didInsertElement: function didInsertElement() {
      this.$(".dot").css({
        top: "".concat(100 - this.yValue, "%"),
        left: "".concat(this.xValue, "%")
      });
    },
    click: function click(event) {
      if (this.disabled) {
        return false;
      }

      var offset = this.$().offset();
      var constrain = (0, _clamp.default)(0, 100);
      var xValue = constrain(Math.floor((event.pageX - offset.left) * 100 / this.$().width()));
      var yValue = constrain(100 - Math.floor((event.pageY - offset.top) * 100 / this.$().height()));
      this.setProperties({
        xValue: xValue,
        yValue: yValue
      });
    },
    xValueChanged: Ember.observer("xValue", function () {
      this.$(".dot").css({
        left: "".concat(this.xValue, "%")
      });
    }),
    yValueChanged: Ember.observer("yValue", function () {
      this.$(".dot").css({
        top: "".concat(100 - this.yValue, "%")
      });
    })
  });

  _exports.default = _default;
});