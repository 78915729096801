define("innowaze-ember2/components/modules/solution-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    availableOrganizations: [],
    availableActivities: [],
    availableSolutions: [],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.findAll("solution", {
        backgroundReload: false
      }).then(function (solutions) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.set("allSolutions", solutions);

        _this.set("availableOrganizations", solutions.filterBy("level", "organization"));

        if (_this.model.solution_id) {
          var solution = _this.allSolutions.findBy("id", _this.model.solution_id.toString());

          var activity = _this.allSolutions.findBy("id", solution.parent);

          var organization = _this.allSolutions.findBy("id", activity.parent);

          _this.set("availableActivities", _this.allSolutions.filter(function (activity) {
            return organization.children.includes(parseInt(activity.id));
          }));

          _this.set("availableSolutions", _this.allSolutions.filter(function (solution) {
            return activity.children.includes(parseInt(solution.id));
          }));

          _this.set("solution", solution);

          _this.set("organization", organization);

          _this.set("selectedOrganization", organization.id);

          _this.set("activity", activity);

          _this.set("selectedActivity", activity.id);

          _this.set("model.solution_id", _this.model.solution_id.toString());
        }

        _this.completedDidChange();

        _this.addObserver("selectedOrganization", _this, _this.populateActivities);

        _this.addObserver("selectedActivity", _this, _this.populateSolutions);

        _this.addObserver("model.solution_id", _this, _this.completedDidChange);
      });
    },
    completedDidChange: function completedDidChange() {
      var isNotCompleted = this.get("model.solution_id") === null || this.get("model.solution_id") === undefined;
      this.set("module.isNotCompleted", isNotCompleted);
    },
    populateActivities: function populateActivities() {
      var organization = this.allSolutions.findBy("id", this.selectedOrganization);

      if (organization) {
        this.set("availableActivities", this.allSolutions.filter(function (activity) {
          return organization.children.includes(parseInt(activity.id));
        }));
      } else {
        this.set("availableActivities", []);
      }

      this.set("selectedActivity", null);
    },
    populateSolutions: function populateSolutions() {
      var activity = this.allSolutions.findBy("id", this.selectedActivity);

      if (activity) {
        this.set("availableSolutions", this.allSolutions.filter(function (solution) {
          return activity.children.includes(parseInt(solution.id));
        }));
      } else {
        this.set("availableSolutions", []);
      }

      this.set("solution", null);
      this.set("model.solution_id", null);
    }
  });

  _exports.default = _default;
});