define("innowaze-ember2/templates/components/google-maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UywuoxB6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"map-canvas\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/google-maps.hbs"
    }
  });

  _exports.default = _default;
});