define("innowaze-ember2/routes/signup", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/socialConnect"], function (_exports, _objectSpread2, _socialConnect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_socialConnect.default, {
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.Object.create({
        email: null,
        password: null,
        password_confirmation: null
      });
    },
    redirect: function redirect() {
      if (this.session.get("loggedIn")) {
        this.router.transitionTo("challenges");
      }
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set("showSSO", !!this.get("session.configuration.activePrivateSSO.length")); // Retrieve the extra fields to be added to the signup form

      this.store.query("userModuleField", {
        filter: "visible_on_signup"
      }).then(function (modules) {
        modules.setEach("value", null);
        controller.set("visibleFields", modules);
      });
    },
    actions: {
      signup: function signup() {
        var _this = this;

        var extraFields = this.get("controller.visibleFields").map(function (_ref) {
          var id = _ref.id,
              value = _ref.value;
          return {
            user_module_field_id: id,
            value: value
          };
        });
        this.store.adapterFor("user").signup((0, _objectSpread2.default)({}, this.controller.deeplink && {
          deeplink: this.controller.deeplink
        }, {
          user: {
            email: this.currentModel.email,
            password: this.currentModel.password,
            password_confirmation: this.currentModel.password_confirmation,
            self_register: 1,
            user_module_field_datas_attributes: extraFields
          }
        })).then(function () {
          _this.notifications.success(_this.locale.t("devise.registrations.signed_up_but_unconfirmed"), {
            autoClear: false
          });

          _this.router.transitionTo("login");
        }, function (_ref2) {
          var errors = _ref2.errors;

          _this.notifications.errorWithList(_this.locale.t("creation_error"), errors);
        });
      },
      toggleSigninSections: function toggleSigninSections() {
        this.toggleProperty("controller.showSSO");
      }
    }
  });

  _exports.default = _default;
});