define("innowaze-ember2/services/google-maps-api", ["exports", "innowaze-ember2/utils/computedPromise"], function (_exports, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    google: (0, _computedPromise.default)(function () {
      return this._getApi();
    }).readOnly(),
    _getConfig: function _getConfig() {
      return Ember.getOwner(this).resolveRegistration("config:environment")["googleMaps"];
    },
    _getApi: function _getApi() {
      if (typeof document === "undefined") {
        return Ember.RSVP.reject();
      }

      var google = window.google;

      if (google && google.maps) {
        return Ember.RSVP.resolve(google);
      }

      var config = this._getConfig();

      return this._buildGoogleMapsUrl(config).then(this._loadAndInitApi);
    },
    _buildGoogleMapsUrl: function _buildGoogleMapsUrl(config) {
      config = config || {};

      if (!config.key) {
        console.warn("You must provide either a Google Maps API key");
      }

      var src = config.baseUrl || "//maps.googleapis.com/maps/api/js";
      var params = [];
      var version = config.version;

      if (version) {
        params.push("v=" + encodeURIComponent(version));
      }

      var libraries = config.libraries;

      if (libraries && libraries.length) {
        params.push("libraries=" + encodeURIComponent(libraries.join(",")));
      }

      var key = config.key;

      if (key) {
        params.push("key=" + encodeURIComponent(key));
      }

      var protocol = config.protocol;

      if (protocol) {
        src = protocol + ":" + src;
      }

      src += "?" + params.join("&");
      return Ember.RSVP.resolve(src);
    },
    _loadAndInitApi: function _loadAndInitApi(src) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.initGoogleMap = Ember.run.bind(function () {
          resolve(window.google);
        });
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;

        s.onerror = function (error) {
          return reject(error);
        };

        document.body.appendChild(s);
        s.src = "".concat(src, "&callback=initGoogleMap");
      });
    }
  });

  _exports.default = _default;
});