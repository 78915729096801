define("innowaze-ember2/models/comment", ["exports", "ember-data", "innowaze-ember2/utils/fileType", "innowaze-ember2/models/application"], function (_exports, _emberData, _fileType, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    parent_id: _emberData.default.attr("number"),
    content: _emberData.default.attr("string"),
    admin: _emberData.default.attr("boolean"),
    author_id: _emberData.default.attr("number"),
    author_name: _emberData.default.attr("string"),
    author_is_moderator: _emberData.default.attr("boolean"),
    user_id: _emberData.default.attr("number"),
    created_at: _emberData.default.attr("string"),
    commentable_type: _emberData.default.attr("string"),
    commentable_id: _emberData.default.attr("number"),
    opinion_attributes: _emberData.default.attr(),
    answer_attributes: _emberData.default.attr(),
    answer_id: _emberData.default.attr(),
    answer_text: _emberData.default.attr("string"),
    answer: _emberData.default.attr("string"),
    idea_id: _emberData.default.attr("number"),
    idea_name: _emberData.default.attr("string"),
    is_my_comment: _emberData.default.attr("boolean"),
    is_parent: _emberData.default.attr("boolean"),
    score: _emberData.default.attr("number"),
    score_string: _emberData.default.attr("string"),
    selected_answers: _emberData.default.attr(),
    avatar_url: _emberData.default.attr("string"),
    tags: _emberData.default.attr(),
    opinion: _emberData.default.attr(),
    picture_id: _emberData.default.attr("number"),
    picture_name: _emberData.default.attr("string"),
    picture_url: _emberData.default.attr("string"),
    small_picture_url: _emberData.default.attr("string"),
    canManage: _emberData.default.attr("boolean"),
    remote_url: _emberData.default.attr("string"),
    vote_for_parent: _emberData.default.attr("number"),
    _fileType: Ember.computed("picture_name", function () {
      return (0, _fileType.default)(this.picture_name);
    }),
    isImage: Ember.computed.equal("_fileType", "image"),
    isVideo: Ember.computed.equal("_fileType", "video"),
    isAudio: Ember.computed.equal("_fileType", "audio"),
    isMedia: Ember.computed.or("isAudio", "isVideo", "isImage"),
    positive: Ember.computed.gt("score", 0),
    negative: Ember.computed.lt("score", 0),
    votedUpForParent: Ember.computed.equal("vote_for_parent", 1),
    votedDownForParent: Ember.computed.equal("vote_for_parent", -1),
    // For insight debate, keep content.lenght <= 300 characters
    commentSize: Ember.computed("content", function () {
      return 300 - this.content.length;
    }),
    contentCommentChanged: Ember.observer("content", function () {
      if (this.is_insight_debate && this.content) {
        return this.set("content", this.content.substring(0, 300));
      }
    }),
    rate: function rate(way) {
      return this._callAdapter("rate", way);
    }
  });

  _exports.default = _default;
});