define("innowaze-ember2/routes/challenge-domains", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id, {
          backgroundReload: false
        }),
        domains: this.store.query("domain", {
          challenge_id: params.challenge_id,
          with_globals: false
        })
      });
    },
    actions: {
      delete: function _delete(domain) {
        var _this = this;

        if (confirm(this.locale.t("domains.index.deletion_confirmation"))) {
          domain.deleteRecord();
          domain.save().then(function () {
            _this.controller.set("model.domains", _this.currentModel.domains.without(domain));

            _this.notifications.success(_this.locale.t("domains.index.deleted"));
          });
        }
      },
      updateDomainsOrder: function updateDomainsOrder() {
        this.currentModel.domains.forEach(function (domain, index) {
          domain.set("position", index + 1);
        });
      },
      reorder: function reorder() {
        var _this2 = this;

        this.set("controller.saveDisabled", true);
        var domains = this.currentModel.domains.map(function (domain) {
          return domain.getProperties("id", "name", "position");
        });
        this.currentModel.challenge.reorderDomains(domains).then(function () {
          return _this2._success();
        }, function (errors) {
          return _this2._error(errors);
        });
      }
    },
    _success: function _success() {
      this.set("controller.saveDisabled", false);
      this.notifications.success(this.locale.t("challenges.domains.reorder_message"));
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("error"), errors);
    }
  });

  _exports.default = _default;
});