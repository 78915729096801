define("innowaze-ember2/components/accordion-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "ul",
    classNames: ["molecule-accordion"],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().on("click", ".toggler", function (e) {
        var $target = $(e.currentTarget).parent();

        _this.$().find("li").not($target).removeClass("active");

        $target.toggleClass("active");
      });
    }
  });

  _exports.default = _default;
});