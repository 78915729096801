define("innowaze-ember2/controllers/challenges/debates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    challengeDebates: Ember.computed("model.debates.[]", function () {
      return this.model.debates.filterBy("debatable_type", "Challenge").sortBy("position");
    }),
    conceptDebates: Ember.computed("model.debates.[]", function () {
      return this.model.debates.filterBy("debatable_type", "Concept").sortBy("position");
    }),
    pathwayDebates: Ember.computed("model.debates.[]", function () {
      return this.model.debates.filterBy("debatable_type", "Pathway").sortBy("position");
    })
  });

  _exports.default = _default;
});