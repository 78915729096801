define("innowaze-ember2/components/modules/keywords-module", ["exports", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/components/modules/module"], function (_exports, _selectAutocomplete, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend(_selectAutocomplete.default, {
    store: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set("tagsToDisplay", this.get("model.tags").toArray());
      this.store.query("tag", {
        supertags: true,
        challenge_id: this.get("challenge.id")
      }).then(function (supertag) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.tagsToDisplay.pushObjects(supertag.toArray());
      });
    },
    completedDidChange: Ember.on("init", Ember.observer("model.tags.[]", function () {
      this.set("module.isNotCompleted", !this.get("model.tags") || this.get("model.tags").length === 0);
    })),
    tagsChanged: Ember.observer("model.tags.[]", function () {
      if (this.get("model.tags") && this.tagsToDisplay) {
        this.set("tagsToDisplay", this.tagsToDisplay.concat(this.get("model.tags").toArray().uniqBy("name")));
      }
    }),
    actions: {
      newTag: function newTag(input) {
        var newTag = this.store.push({
          data: {
            id: input,
            type: "tag",
            attributes: {
              name: input
            }
          }
        });
        this.tagsToDisplay.pushObject(newTag);
        this.get("model.tags").pushObject(newTag);
      }
    }
  });

  _exports.default = _default;
});