define("innowaze-ember2/components/modules/generalization-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    generalizationChanged: Ember.on("init", Ember.observer("model.generalization", "model.generalization_explanation", function () {
      var isNotCompleted = this.get("model.generalization") && !this.get("model.generalization_explanation"); // Check model.id to avoid trying to set data on a non-existing model

      if (this.get("model.id") && !this.get("model.generalization")) {
        this.model.set("generalization_explanation", null);
      }

      this.set("module.isNotCompleted", isNotCompleted);
    }))
  });

  _exports.default = _default;
});