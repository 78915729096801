define("innowaze-ember2/components/modules/meteo-module", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/components/modules/module"], function (_exports, _objectSpread2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var meteo = this.get("model.meteo");
      var icons = this.get("module.params.icons").map(function (icon) {
        return Ember.Object.create((0, _objectSpread2.default)({}, icon, {
          selected: icon.id === meteo
        }));
      });
      this.set("availableMeteoIcons", icons);
    },
    completedDidChange: Ember.on("init", Ember.observer("model.meteo", function () {
      this.set("module.isNotCompleted", !this.get("model.meteo"));
    })),
    actions: {
      selectIcon: function selectIcon(icon) {
        this.availableMeteoIcons.setEach("selected", false);
        icon.set("selected", true);
        this.model.set("meteo", icon.id);
      }
    }
  });

  _exports.default = _default;
});