define("innowaze-ember2/adapters/survey", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    vote: function vote(id, survey, answer_id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(survey.get("challenge_id"), "/").concat(this.pathForType("survey"), "/").concat(id, "/vote.json"), "POST", {
        data: {
          available_answer_id: answer_id
        }
      });
    }
  });

  _exports.default = _default;
});