define("innowaze-ember2/components/related-knowledge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grade: Ember.computed(function () {
      var score = this.get("knowledge._score");

      switch (true) {
        case score > 0.3:
          return "<span class='grade-green'>■■■■</span>";

        case score > 0.2:
          return "<span class='grade-gold'>■■■</span>";

        case score > 0.1:
          return "<span class='grade-orange'>■■</span>";

        case score > 0.05:
          return "<span class='grade-red'>■</span>";

        default:
          return "<span class='grade'></span>";
      }
    }),
    didInsertElement: function didInsertElement() {
      return this.$().foundation();
    },
    actions: {
      addKnowledge: function addKnowledge() {
        this.sendAction.apply(this, ["addKnowledge"].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});