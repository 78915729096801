define("innowaze-ember2/routes/challenge-domains/edit", ["exports", "innowaze-ember2/routes/domains/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    model: function model(params) {
      return this.store.findRecord("domain", params.domain_id);
    },
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    successMessage: "Le domaine a été mis à jour."
  });

  _exports.default = _default;
});