define("innowaze-ember2/serializers/configuration", ["exports", "innowaze-ember2/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(snapshot) {
      // Override payload format to match the server's expectations
      return {
        configuration: snapshot.record.getProperties("dict", "banner_id", "logo_id", "idea_picture_id", "concept_picture_id", "login_picture_id", "email_domains"),
        current_digest: snapshot.record.get("current_digest")
      };
    }
  });

  _exports.default = _default;
});