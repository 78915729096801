define("innowaze-ember2/controllers/challenges/solution-subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isSaving: false,
    sortedSolutions: Ember.computed("organizationFilter", "activityFilter", "solutionFilter", "onlyUnassignedFilter", "model.experts", function () {
      var _this = this;

      var solutions = this.model.solutions.filterBy("level", "solution").map(function (solution) {
        var id = solution.id,
            name = solution.name,
            followed = solution.followed;

        var activity = _this.model.solutions.find(function (s) {
          return s.id === solution.parent;
        });

        var organization = _this.model.solutions.find(function (s) {
          return s.id === activity.parent;
        });

        return {
          id: id,
          name: name,
          followed: followed,
          activity: activity.name,
          organization: organization.name
        };
      });

      if (this.organizationFilter) {
        solutions = solutions.filterBy("organization", this.organizationFilter);
      }

      if (this.activityFilter) {
        solutions = solutions.filterBy("activity", this.activityFilter);
      }

      if (this.solutionFilter) {
        solutions = solutions.filterBy("name", this.solutionFilter);
      }

      return solutions.sortBy("organization", "activity", "name");
    })
  });

  _exports.default = _default;
});