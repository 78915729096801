define("innowaze-ember2/controllers/challenges/edit-main", ["exports", "pluralize", "pluriel", "innowaze-ember2/controllers/challenges/edit-common"], function (_exports, _pluralize, _pluriel, _editCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var theFr = function theFr(word) {
    return ["a", "e", "i", "o", "u", "y"].includes(word.toLowerCase()[0]) ? "l'" + word : "le " + word;
  };

  var aEn = function aEn(word) {
    return ["a", "e", "i", "o", "u", "y"].includes(word.toLowerCase()[0]) ? "an " + word : "a " + word;
  };

  var _default = _editCommon.default.extend({
    displayAdminActions: true,
    locale: Ember.inject.service(),
    ideaDidChange: Ember.observer("model.dict.idea", function () {
      if (this.get("model.idea_reformulated")) {
        return;
      }

      var idea = this.get("model.dict.idea") || "";

      if (idea.length > 0) {
        if (this.locale.currentLocale === "fr") {
          this.set("model.dict.ideas", (0, _pluriel.default)(idea));
          this.set("model.dict.the_idea", theFr(idea));
          this.set("model.dict.an_idea", "un " + idea);
        } else {
          this.set("model.dict.ideas", (0, _pluralize.default)(idea));
          this.set("model.dict.the_idea", "the " + idea);
          this.set("model.dict.an_idea", aEn(idea));
        }
      } else {
        this.set("model.dict.ideas", null);
        this.set("model.dict.the_idea", null);
        this.set("model.dict.an_idea", null);
      }
    }),
    conceptDidChange: Ember.observer("model.dict.concept", function () {
      if (this.get("model.concept_reformulated")) {
        return;
      }

      var concept = this.get("model.dict.concept") || "";

      if (concept.length > 0) {
        if (this.locale.currentLocale === "fr") {
          this.set("model.dict.concepts", (0, _pluriel.default)(concept));
          this.set("model.dict.the_concept", theFr(concept));
          this.set("model.dict.a_concept", "un " + concept);
        } else {
          this.set("model.dict.concepts", (0, _pluralize.default)(concept));
          this.set("model.dict.the_concept", "the " + concept);
          this.set("model.dict.a_concept", aEn(concept));
        }
      } else {
        this.set("model.dict.concepts", null);
        this.set("model.dict.the_concept", null);
        this.set("model.dict.an_concept", null);
      }
    }),
    privateDidChange: Ember.observer("model.private", function () {
      if (this.get("model.private")) {
        this.model.set("requires_validation", false);
      } else {
        this.model.set("public_showcase", true);
        this.model.set("public_departments", null);
      }
    }),
    wantsIdeationDidChange: Ember.observer("model.wants_ideation", function () {
      if (!this.get("model.wants_ideation")) {
        this.model.set("no_homepage", false);
      }
    }),
    blindDidChange: Ember.observer("blind", function () {
      if (this.blind) {
        this.model.set("default_role", "blind");
      } else {
        this.model.set("default_role", null);
        this.model.set("authorized_departments", null);
      }
    }),
    deepLdidChange: Ember.observer("model.deepl_translation", function () {
      if (!this.get("model.deepl_translation")) {
        this.model.set("translation_coupling", false);
        this.model.set("translation_languages", null);
      }
    })
  });

  _exports.default = _default;
});