define("innowaze-ember2/templates/components/cost-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8BPex5X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"hidden-input\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"computedChecked\"]]]]],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"toggable-label\"],[9],[0,\"\\n          \"],[1,[21,\"name\"],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"computedChecked\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"row collapse\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"columns small-10\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"computedValue\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"columns small-2\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"postfix\"],[9],[0,\"€\"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"needsPeriod\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"ember-select\",null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"selectedValue\"],[[23,[\"periods\"]],\"value\",\"name\",[23,[\"computedPeriod\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/cost-item.hbs"
    }
  });

  _exports.default = _default;
});