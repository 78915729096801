define("innowaze-ember2/components/idea-clustering", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "innowaze-ember2/utils/flattenArray", "innowaze-ember2/configuration"], function (_exports, _slicedToArray2, _flattenArray, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: "svg",
    classNames: ["bubble-chart", "clustering"],
    attributeBindings: ["width", "height", "size", "color"],
    didInsertElement: function didInsertElement() {
      this.svg = d3.select("#".concat(this.elementId));
      this.atomsInMolecule = [];
      this.addModal();
      this.addRightPane();
      this.addLeftPane();
    },
    draw: Ember.observer("content.[]", "size", "color", function () {
      this.updateIdeas();
      this.buildMolecule();
      this.createGradients();
      this.createScale();
    }),
    restoreMolecule: Ember.observer("content.[]", "idea", function () {
      var _this = this;

      if (this.restoredIdea) {
        return;
      }

      this.rightPane.selectAll(".link").remove();
      this.rightPane.selectAll("g.idea").remove();

      if (this.idea && this.get("content.length")) {
        this.set("restoredIdea", true);
        var ideas = this.content;
        var parents = this.get("idea.parents");
        parents.forEach(function (parent) {
          var idea = ideas.findBy("id", parent.id.toString());

          if (idea) {
            _this.atomsInMolecule.push(idea);

            _this.$("#idea-".concat(idea.id)).css("visibility", "hidden");
          }
        });
        this.buildMolecule();
        $("input[name='name']").val(this.get("idea.name"));
        this.set("previousDescription", this.get("idea.description"));
        $("#previous").html(this.get("idea.description"));
      }
    }),
    containersWidth: Ember.computed("width", function () {
      return this.width / 2 - 10;
    }),
    data: function data() {
      var colorVar = this.get("color.name");
      var sizeVar = this.size ? this.get("size.name") : null;
      var idSize = this.get("size.id");

      if (this.content.length === 0) {
        return [];
      }

      return d3.layout.pack().value(function (d) {
        if (sizeVar) {
          var size = d.data[sizeVar] || 0;

          if (!isNaN(sizeVar)) {
            var sizeRecord = d.data["extra_tools"].findBy("id", idSize);
            size = sizeRecord ? sizeRecord.value : 0;
          }

          return size === 0 ? 1 : size;
        }

        return 1;
      }).sort(function (d) {
        return d.get(colorVar);
      }).size([this.containersWidth, this.height]).nodes(Ember.Object.create({
        name: "Bubble chart",
        children: this.content
      })).rejectBy("depth", 0);
    },
    addTooltip: function addTooltip() {
      var _this2 = this;

      this.tooltip = $("<div>").addClass("tooltip").css("display", "none").insertBefore(this.$());
      this.tooltip.on("click", "i.js-close", function () {
        return _this2.tooltip.css("display", "none");
      });
    },
    addModal: function addModal() {
      var _this3 = this;

      var content = "<form action=''>\n      <input name='name' type='text' placeholder=\"".concat(this.locale.t("ideas.clustering.name"), "\">\n      <textarea name='description' rows='10' placeholder=\"").concat(this.locale.t("ideas.clustering.description"), "\"></textarea>\n      <div id=\"previous\"></div>\n      <p><input type='submit' value=\"").concat(this.locale.t("save"), "\" class='right button-success'></p>\n      </form><a class='close-reveal-modal'>&#215;</a>");
      this.modal = $("<div>").attr("id", "saveMolecule").attr("data-reveal", "").addClass("reveal-modal small creation-form").html(content).insertBefore(this.$());
      this.modal.foundation();
      this.modal.find("form").on("submit", function (event) {
        event.preventDefault();
        var $target = $(event.currentTarget);
        var name = $target.find("input[name='name']").val();
        var description = $target.find("textarea[name='description']").val();

        if (_this3.restoredIdea) {
          description += "<hr>".concat(_this3.previousDescription);
        } else {
          description = "<p>".concat(description, "</p>");
        }

        if (Ember.isBlank(name)) {
          _this3.notifications.error(_this3.locale.t("ideas.clustering.name_alert"));
        } else if (Ember.isEmpty(_this3.atomsInMolecule)) {
          _this3.notifications.error(_this3.locale.t("ideas.clustering.atoms_alert"));
        } else {
          var challengeId = _this3.atomsInMolecule.get("firstObject.challenge_id");

          var idea = _this3.idea || _this3.store.createRecord("idea", {
            challenge_id: challengeId
          });

          var author_id = _this3.atomsInMolecule.get("firstObject.author_id");

          var domain_ids = (0, _flattenArray.default)(_this3.atomsInMolecule.mapBy("domain_ids")).uniq();
          var domains = domain_ids.map(function (id) {
            return {
              id: id
            };
          });

          var tags = _this3.atomsInMolecule.reduce(function (acc, idea) {
            return acc.pushObjects(idea.get("tags").toArray());
          }, []).uniq();

          var author_ids = _this3.atomsInMolecule.mapBy("author_id");

          var team_ids = (0, _flattenArray.default)(_this3.atomsInMolecule.mapBy("team_ids")).concat(author_ids).uniq().filter(function (id) {
            return id !== author_id;
          });
          var teammates = [];
          var promises = [];

          if (team_ids.length > 0) {
            promises.push(_this3.store.query("user", {
              ids: team_ids,
              challenge_id: challengeId
            }).then(function (users) {
              teammates = users;
            }));
          }

          Promise.all(promises).then(function () {
            idea.setProperties({
              name: name,
              description: description,
              parent_ids: _this3.atomsInMolecule.mapBy("id").uniq(),
              teammates: teammates,
              domains: domains,
              tags: tags
            });
            idea.save({
              adapterOptions: {
                challenge_id: challengeId
              }
            }).then(function () {
              _this3.notifications.success(_this3.locale.t("ideas.clustering.success"));

              _this3.modal.foundation("reveal", "close");

              if (!_this3.idea) {
                $target[0].reset();
              }
            });
          });
        }
      });
    },
    addLeftPane: function addLeftPane() {
      var _this4 = this;

      this.leftPane = this.svg.append("g").attr("class", "left-pane");
      this.leftPane.append("rect").attr("width", this.containersWidth).attr("height", this.height).on("click", function () {
        return _this4.tooltip.css("display", "none");
      });
      this.leftPane.append("text").text(this.locale.t("ideas.clustering.atoms")).attr("transform", "translate(10, 20)");
      this.addTooltip();
    },
    addRightPane: function addRightPane() {
      var _this5 = this;

      var marginLeft = this.containersWidth + 20;
      this.rightPane = this.svg.append("g").attr("class", "right-pane").attr("transform", "translate(".concat(marginLeft, ", 0)"));
      this.rightPane.append("rect").attr("width", this.containersWidth).attr("height", this.height).on("click", function () {
        return _this5.tooltip.css("display", "none");
      });
      this.rightPane.append("text").text(this.locale.t("ideas.clustering.molecules")).attr("transform", "translate(10, 20)");
      this.addSaveButton();
      this.addNewButton();
    },
    addSaveButton: function addSaveButton() {
      var _this6 = this;

      this.rightPane.append("text").text(this.locale.t("ideas.clustering.save")).style("text-anchor", "end").attr("class", "save").attr("transform", "translate(".concat(this.containersWidth - 8, ", 20)")).on("click", function () {
        return _this6.modal.foundation("reveal", "open");
      });
    },
    addNewButton: function addNewButton() {
      var _this7 = this;

      this.rightPane.append("text").text(this.locale.t("ideas.clustering.reset")).style("text-anchor", "end").attr("transform", "translate(".concat(this.containersWidth - 8, ", 40)")).on("click", function () {
        if (confirm(_this7.locale.t("ideas.clustering.reset_alert"))) {
          _this7.atomsInMolecule.forEach(function (atom) {
            $("#idea-".concat(atom.id)).css("visibility", "visible");
          });

          _this7.atomsInMolecule = [];

          _this7.buildMolecule();
        }
      });
    },
    addLegend: function addLegend() {
      if (this.leftPane.selectAll("g.legend")[0].length) {
        return;
      }

      var legend = this.leftPane.append("svg:g");
      legend.attr("class", "legend").attr("transform", "translate(".concat(this.containersWidth - 120, ", 10)"));
      ["lower", "low", "middle", "high", "higher"].forEach(function (level, index) {
        legend.append("rect").attr("width", 20).attr("height", 10).attr("class", level).attr("transform", "translate(".concat(index * 20, ", 0)"));
      });
      legend.append("text").style("text-anchor", "end").attr("transform", "translate(100, 30)").text(this.locale.t("ideas.clustering.scale"));
    },
    updateIdeas: function updateIdeas() {
      var _this8 = this;

      var nodes = this.leftPane.selectAll("g.idea").remove();
      nodes = this.leftPane.selectAll("g.idea").data(this.data()); // What to do when a new idea is added

      var g = nodes.enter().append("svg:g").attr("class", "idea").call(this.dragListener());
      g.append("circle");
      g.append("text").style("text-anchor", "middle").attr("class", "bubble_text").text(function (d) {
        return d.r > 8 ? d.id : "";
      });
      g.on("click", this.ideaClicked.bind(this)); // Entering / updating ideas

      nodes.attr("transform", this.ideaTransform).attr("id", function (d) {
        return "idea-".concat(d.id);
      }).style("visibility", function (d) {
        return _this8.atomsInMolecule.isAny("id", d.id) ? "hidden" : "visible";
      });
      nodes.select("circle").attr("r", this.ideaRadius).style("fill", function (idea) {
        return "url(#gradientRadial-".concat(idea.id, ")");
      }); // What to do when a existing idea is removed

      nodes.exit().remove();
    },
    // Handle idea drag'n'drop
    dragListener: function dragListener() {
      var _this9 = this;

      var container = this.$().get(0);
      var containerWidth = container.getAttribute("width");
      var containerHeight = container.getAttribute("height");
      var rightPane = $(container).find(".right-pane rect").get(0);
      return d3.behavior.drag().on("dragstart", function () {
        return _this9.tooltip.css("display", "none");
      }).on("drag", function (d) {
        var mouseX;
        var mouseY;
        var node = d3.select(this); // Track mouse moves

        var _d3$mouse = d3.mouse(container);

        var _d3$mouse2 = (0, _slicedToArray2.default)(_d3$mouse, 2);

        mouseX = _d3$mouse2[0];
        mouseY = _d3$mouse2[1];

        // Constrain idea group to container
        if (mouseX < d.r) {
          mouseX = d.r;
        }

        if (mouseY < d.r) {
          mouseY = d.r;
        }

        if (mouseX > containerWidth - d.r) {
          mouseX = containerWidth - d.r;
        }

        if (mouseY > containerHeight - d.r) {
          mouseY = containerHeight - d.r;
        } // Update idea position


        node.attr("transform", "translate(".concat(mouseX, ", ").concat(mouseY, ")"));
      }).on("dragend", function (d) {
        var atom = $("#idea-".concat(d.id));
        var centerX; // Detect in which pane idea has been dropped

        var _d3$mouse3 = d3.mouse(rightPane);

        var _d3$mouse4 = (0, _slicedToArray2.default)(_d3$mouse3, 1);

        centerX = _d3$mouse4[0];

        // eslint-disable-line prefer-const
        if (centerX - d.r > 0) {
          // Dropped in right pane
          if (!_this9.atomsInMolecule.includes(d)) {
            _this9.atomsInMolecule.push(d);

            atom.css("visibility", "hidden");

            _this9.buildMolecule();
          }
        }

        atom.attr("transform", "translate(".concat(d.x, ", ").concat(d.y, ")"));
      });
    },
    ideaRadius: function ideaRadius(idea) {
      return idea.r;
    },
    ideaTransform: function ideaTransform(idea) {
      return Ember.String.htmlSafe("translate(".concat(idea.x, ", ").concat(idea.y, ")"));
    },
    ideaClicked: function ideaClicked(idea) {
      if (!this.atomsInMolecule.includes(idea)) {
        var logo = idea.get("logo_url");
        var content = "<header>\n        <i class='fa fa-times right js-close'></i>\n        ".concat(logo ? "<img src=\"".concat(_configuration.default.apiUrl).concat(logo, "\" />") : "", "\n        <h1>").concat(idea.get("name"), "<small> - ").concat(idea.id, " - </small></h1>\n        <h2>").concat(idea.get("author_name"), "</h2>\n      </header>\n      <div style='max-height: 400px;overflow:scroll'>\n        ").concat(idea.get("description"), "\n      </div>\n      </br>\n      <footer>\n        <a href=\"/challenges/").concat(idea.get("challenge_id"), "/ideas/").concat(idea.id, "\" target=\"_blank\">\n          ").concat(this.locale.t("ideas.clustering.open"), "\n        </a>\n      </footer>");
        this.tooltip.html(content).css("display", "block").css("left", "".concat(idea.x - 180, "px")).css("top", "".concat(idea.y + 50, "px"));
      }
    },
    buildMolecule: function buildMolecule() {
      this.rightPane.selectAll(".link").remove();
      this.rightPane.selectAll("g.idea").remove();
      var links = this.atomsInMolecule.without(this.atomsInMolecule[0]).map(function (atom, index) {
        return {
          source: 0,
          target: index + 1
        };
      });
      d3.layout.force().nodes(this.atomsInMolecule).links(links).size([this.containersWidth, this.height]).charge(-1000).chargeDistance(500).gravity(0.3).linkDistance(this.containersWidth / 5).on("tick", this.tick).start();
      var linkNodes = this.rightPane.selectAll(".link").data(links);
      linkNodes.enter().insert("line", "g.idea").attr("class", "link");
      linkNodes.exit().remove(); // What to do when a new idea is added

      var nodes = this.rightPane.selectAll("g.idea").data(this.atomsInMolecule);
      var kernel = this.containersWidth / 10;
      var electron = this.containersWidth / 18;
      var g = nodes.enter().append("svg:g").attr("class", "idea").attr("tranform", this.ideaTransform);
      g.append("circle").style("fill", function (idea) {
        return "url(#gradientRadial-".concat(idea.id, ")");
      }).attr("r", function (d, i) {
        return i === 0 ? kernel : electron;
      });
      g.append("text").attr("dy", "-1em").style("text-anchor", "middle").attr("class", "bubble_text").text(function (d) {
        return d.get("name").substr(0, electron / 3);
      });
      g.append("text").attr("dy", "0.3em").style("text-anchor", "middle").attr("class", "bubble_text").text(function (d) {
        return d.get("name").substring(Math.round(electron / 3), 2 * (electron / 3));
      });
      g.append("text").attr("dy", "1.6em").style("text-anchor", "middle").attr("class", "bubble_text").text(function (d) {
        return d.get("name").substring(Math.round(2 * (electron / 3)), 3 * (electron / 3));
      });
      g.on("click", this.atomsInMoleculeClicked.bind(this)); // what to do when an existing idea is removed

      nodes.exit().remove();
    },
    tick: function tick() {
      d3.selectAll(".link").attr("x1", function (d) {
        return d.source.x;
      }).attr("y1", function (d) {
        return d.source.y;
      }).attr("x2", function (d) {
        return d.target.x;
      }).attr("y2", function (d) {
        return d.target.y;
      });
      d3.selectAll(".right-pane g.idea").attr("transform", function (d) {
        return "translate(".concat(d.x, ", ").concat(d.y, ")");
      });
    },
    atomsInMoleculeClicked: function atomsInMoleculeClicked(idea) {
      var _this10 = this;

      if (this.atomsInMolecule.includes(idea)) {
        var logo = idea.get("logo_url");
        var content = "<header>\n        ".concat(logo ? "<img src=\"".concat(_configuration.default.apiUrl).concat(logo, "\" />") : "", "\n        <h1>").concat(idea.get("name"), "<small> - ").concat(idea.id, " - </small></h1>\n        <h2>").concat(idea.get("author_name"), "</h2>\n      </header>\n      ").concat(idea.get("description"), "\n      <footer>\n        <a class='set-master'>").concat(this.locale.t("ideas.clustering.master"), " </a>\n        <br/>\n        <a class='remove-from-molecule'>").concat(this.locale.t("ideas.clustering.remove"), " </a>\n      </footer>\n      ");
        this.tooltip.html(content).css("display", "block").css("left", "".concat(this.containersWidth + 20 + idea.x - 180, "px")).css("top", "".concat(idea.y + 50, "px"));
        d3.select("div.tooltip a.set-master").on("click", function () {
          d3.event.stopPropagation();

          _this10.tooltip.css("display", "none");

          _this10.atomsInMolecule.removeObject(idea);

          _this10.atomsInMolecule.unshiftObject(idea); // Need this since classical .exit().remove() doesn't work as expected


          _this10.rightPane.selectAll("line.link").remove();

          _this10.rightPane.selectAll("g.idea").remove();

          _this10.buildMolecule();
        });
        d3.select("div.tooltip a.remove-from-molecule").on("click", function () {
          d3.event.stopPropagation();

          _this10.tooltip.css("display", "none");

          _this10.atomsInMolecule.removeObject(idea); // Need this since classical .exit().remove() doesn't work as expected


          _this10.rightPane.selectAll("line.link").remove();

          _this10.rightPane.selectAll("g.idea").remove();

          _this10.buildMolecule();

          $("#idea-".concat(idea.id)).css("visibility", "visible");
        });
      }
    },
    createGradients: function createGradients() {
      var _this11 = this;

      var fillColor = function fillColor(idea) {
        var color;

        switch (_this11.color.scale) {
          case 1:
            color = d3.scale.ordinal().domain([1, 2, 3, 4]).range(["orange", "yellow", "green", "blue"]);
            break;

          case 2:
            color = d3.scale.category20c();

            if (_this11.get("color.name") === "first_domain") {
              window.domains = idea.get("all_domains");
              color.domain(idea.get("all_domains"));
            } else {
              window.states = idea.get("all_states");
              color.domain(idea.get("all_states"));
            }

            break;

          default:
            color = d3.scale.linear().domain([0, 25, 50, 75, 100]).range(["red", "orange", "yellow", "#9FF781", "#31B404"]);
            break;
        }

        if (!isNaN(_this11.color.name)) {
          var colorRecord = idea.data["extra_tools"].findBy("id", _this11.color.id);
          var colorValue = colorRecord ? colorRecord.value : 0;
          return color(colorValue);
        }

        if (idea.data[_this11.color.name] !== null) {
          return color(idea.data[_this11.color.name]);
        }

        return "#CCCCCC";
      };

      this.svg.selectAll("g.gradientContainer").remove();
      var allIdeas = this.data().concat(this.atomsInMolecule);
      var gradientContainer = this.svg.append("g").attr("class", "gradientContainer");
      var gradientRadial = gradientContainer.selectAll("radialGradient").data(allIdeas).enter().append("radialGradient").attr("cx", "50%").attr("cy", "50%").attr("r", "50%").attr("fx", "4%").attr("gradientUnits", "objectBoundingBox").attr("id", function (d) {
        return "gradientRadial-".concat(d.id);
      });
      gradientRadial.append("stop").attr("offset", "4%").attr("stop-color", function (d) {
        return d3.rgb(fillColor(d)).brighter(1);
      });
      gradientRadial.append("stop").attr("offset", "40%").attr("stop-color", function (d) {
        return fillColor(d);
      });
      gradientRadial.append("stop").attr("offset", "100%").attr("stop-color", function (d) {
        return d3.rgb(fillColor(d)).darker(1.75);
      });
    },
    createScale: function createScale() {
      var _this12 = this;

      var color;
      var gradients;

      switch (this.get("color.scale")) {
        case 1:
          color = d3.scale.ordinal().domain([1, 2, 3, 4]).range(["orange", "yellow", "green", "blue"]);
          gradients = color.domain();
          break;

        case 2:
          if (this.get("color.name") === "first_domain") {
            color = d3.scale.category20c();
            color.domain(window.domains);
            gradients = window.domains;
          } else {
            color = d3.scale.category20c();
            color.domain(window.states);
            gradients = window.states;
          }

          break;

        default:
          color = d3.scale.linear().domain([0, 25, 50, 75, 100]).range(["red", "orange", "yellow", "#9FF781", "#31B404"]);
          gradients = color.domain();
      }

      this.svg.selectAll("g.scaleContainer").remove();
      var scale = this.svg.append("g").attr("class", "scaleContainer");
      var y = 8;

      if (this.get("color.name") === "typology") {
        gradients.forEach(function (gradient) {
          y += 20;
          scale.append("rect").attr("x", 10).attr("y", y).attr("width", 20).attr("height", 20).attr("fill", color(gradient));
          scale.append("text").attr("x", 40).attr("y", y + 14).attr("fill", "#fff").attr("font-size", "10px").text(_this12.locale.t("ideas.type".concat(gradient)));
        });
      } else {
        gradients.forEach(function (gradient) {
          y += 20;
          scale.append("rect").attr("x", 10).attr("y", y).attr("width", 20).attr("height", 20).attr("fill", color(gradient));
          scale.append("text").attr("x", 40).attr("y", y + 14).attr("fill", "#fff").attr("font-size", "10px").text(gradient);
        });
      }
    }
  });

  _exports.default = _default;
});