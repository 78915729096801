define("innowaze-ember2/components/modules/module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    classNameBindings: ["team_only"],
    team_only: Ember.computed(function () {
      return this.get("module.team_only");
    }),
    visibleDidChange: Ember.observer("module.visible", function () {
      var _this = this;

      if (this.get("module.visible")) {
        // Upon triggering of the observer, the DOM might not be done being modified,
        // and calculating the module position ultimately fails.
        // This hack forces JavaScript to wait before doing this calculation.
        window.setTimeout(function () {
          var h = $("#".concat(_this.elementId)).offset().top - 50;
          $("html, body").animate({
            scrollTop: h
          }, 1500);
        }, 0);
      }
    }),
    actions: {
      updateExplanationField: function updateExplanationField(field, newContent) {
        this.set("module.explanation", newContent);
      },
      changeIdeaModuleExplanation: function changeIdeaModuleExplanation(module, toggleExplanation) {
        var _this2 = this;

        toggleExplanation();
        this.challenge.updateIdeaModuleExplanation(module).then(function () {
          _this2.notifications.success(_this2.locale.t("challenges.show.explanation_changed"));
        }, function (_ref) {
          var errors = _ref.errors;

          _this2.notifications.errorWithList(_this2.locale.t("modification_error"), errors);
        });
      },
      save: function save() {
        this.sendAction.apply(this, ["save"].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});