define("innowaze-ember2/components/market-item-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    computedValue: Ember.computed("hostLabel.formula.markets.[]", {
      get: function get() {
        var _this = this;

        var label = this.get("hostLabel.formula.markets").findBy("label", this.marketLabel);

        if (label) {
          var element = label.get("elements").find(function (element) {
            return element.item.id.toString() === _this.get("item.id").toString();
          });
          return this.value || (element ? element.value : null);
        }

        return this.value;
      },
      set: function set(key, value) {
        if (value.toString().indexOf(",") >= 0) {
          value = value.replace(",", ".");
        }

        this.set("value", value);
        return value;
      }
    }),
    valueDidChange: Ember.observer("value", function () {
      if (!(this.hostLabel && this.marketLabel)) {
        return;
      }

      this.sendAction("setFormulaMarketValue", this.hostLabel, this.marketLabel, this.item, this.value);
    })
  });

  _exports.default = _default;
});