define("innowaze-ember2/templates/departments/modules/-desired-partnership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pj/CO7Ch",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"model\",\"typeIsInternal\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"departments.desired_partnership\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"placeholder\",\"onchange\"],[[23,[\"model\",\"desired_partnership_description\"]],[27,\"i18n\",[\"departments.desired_partnership_placeholder\"],null],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.desired_partnership_description\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-desired-partnership.hbs"
    }
  });

  _exports.default = _default;
});