define("innowaze-ember2/templates/challenges/modules/-latest-concept-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Nll1rpb",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"latest_concept_comments\"]]],null,{\"statements\":[[4,\"unless\",[[23,[\"model\",\"blind_concept_user\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"last-comments-block dashboard-module\"],[9],[0,\"\\n      \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n        \"],[1,[27,\"i18n\",[\"challenges.latest_concept_comments.title\"],[[\"concepts\"],[[27,\"name-for\",[[23,[\"model\"]],\"concepts\",\"plural_concept\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"comment-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"latest_concept_comments\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"concepts.show\",[23,[\"model\",\"id\"]],[22,1,[\"concept_id\"]]],[[\"class\"],[\"avatar\"]],{\"statements\":[[0,\"              \"],[1,[27,\"avatar-img\",null,[[\"src\",\"size\"],[[22,1,[\"avatar_url\"]],\"small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"concepts.show\",[23,[\"model\",\"id\"]],[22,1,[\"concept_id\"]]],[[\"class\"],[\"username\"]],{\"statements\":[[0,\"              \"],[1,[22,1,[\"author_name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[7,\"div\"],[11,\"class\",\"date\"],[9],[0,\"\\n              \"],[1,[27,\"formatted-date\",[[22,1,[\"created_at\"]]],null],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"comment\"],[9],[0,\"\\n              \"],[1,[22,1,[\"content\"]],false],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-latest-concept-comments.hbs"
    }
  });

  _exports.default = _default;
});