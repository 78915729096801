define("innowaze-ember2/routes/challenges/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.query("challenge", {
        closed: 1
      });
    },
    actions: {
      updateChallengesOrder: function updateChallengesOrder() {
        this.currentModel.forEach(function (challenge, index) {
          challenge.set("position", index + 1);
        });
      },
      reorder: function reorder() {
        var _this = this;

        this.set("controller.saveDisabled", true);
        this.store.adapterFor("challenge").reorderChallenges(this.get("controller.model").mapBy("id")).then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      }
    },
    _success: function _success() {
      this.set("controller.saveDisabled", false);
      this.notifications.success(this.locale.t("challenges.domains.reorder_message"));
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("error"), errors);
    }
  });

  _exports.default = _default;
});