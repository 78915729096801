define("innowaze-ember2/templates/components/fat-search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9HwSva39",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[12,\"class\",[28,[\"fat-searchbar \",[27,\"if\",[[23,[\"large\"]],\"large\"],null]]]],[3,\"action\",[[22,0,[]],\"submit\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"key-up\"],[[23,[\"value\"]],[23,[\"placeholder\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"onKeyUp\"],null]]]],false],[0,\"\\n  \"],[7,\"button\"],[11,\"type\",\"submit\"],[9],[0,\"\\n    \"],[1,[21,\"buttonText\"],false],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-search\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/fat-search-bar.hbs"
    }
  });

  _exports.default = _default;
});