define("innowaze-ember2/templates/departments/modules/-investor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fjTs2lDI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"model\",\"department_type\"]],20],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"departments.investor_information\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"columns medium-4\"],[9],[0,\"\\n        \"],[7,\"label\"],[9],[1,[27,\"i18n\",[\"departments.investment_ticket\"],null],false],[0,\" €\"],[10],[0,\"\\n        \"],[7,\"span\"],[9],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\"],[[23,[\"model\",\"investment_ticket\"]],[27,\"i18n\",[\"departments.amount_placeholder\"],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"columns medium-12\"],[9],[0,\"\\n        \"],[7,\"label\"],[9],[1,[27,\"i18n\",[\"departments.funded_companies\"],null],false],[10],[0,\"\\n        \"],[1,[27,\"ember-selectize\",null,[[\"multiple\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\",\"content\",\"selection\",\"create-item\"],[true,\"content.id\",\"content.name\",[27,\"i18n\",[\"users.modules.skills.tags\"],null],[23,[\"selectedFundedCompanies\"]],[23,[\"selectedFundedCompanies\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"newFundedCompany\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-investor.hbs"
    }
  });

  _exports.default = _default;
});