define("innowaze-ember2/templates/challenge-badges/-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iuLw4xH/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"form\"],[11,\"class\",\"creation-form\"],[3,\"action\",[[22,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"white-form\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"class\"],[[23,[\"model\",\"badge\",\"title\"]],\"Title\",\"title\"]]],false],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"news-illustration-form\"],[9],[0,\"\\n        \"],[7,\"label\"],[9],[0,\"Illustration \"],[7,\"small\"],[9],[0,\"(180x180px)\"],[10],[10],[0,\"\\n        \"],[1,[27,\"file-upload\",null,[[\"background\",\"filesUploaded\",\"isPublic\"],[[23,[\"model\",\"badge\",\"logo_url\"]],\"filesUploaded\",true]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[1,[27,\"textarea\",null,[[\"value\",\"placeholder\"],[[23,[\"model\",\"badge\",\"description\"]],\"Description\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-actions\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[9],[1,[27,\"i18n\",[\"cancel\"],null],false],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"next-step\"],[11,\"type\",\"submit\"],[9],[1,[27,\"i18n\",[\"save\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenge-badges/-form.hbs"
    }
  });

  _exports.default = _default;
});