define("innowaze-ember2/services/notifications", ["exports", "ember-cli-notifications/services/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _notifications.default.extend({
    isServiceFactory: true,
    errorWithList: function errorWithList(message, errors) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (Array.isArray(errors)) {
        var makeError = function makeError(error) {
          return "<li>".concat(error.detail, "</li>");
        };

        this.error("<div>".concat(message, "<ul>").concat(errors.map(makeError).join(""), "</ul></div>"), Object.assign(options, {
          htmlContent: true
        }));
      } else {
        var _console;

        (_console = console).warn.apply(_console, ["Notification.errorWithList was called without an errors array"].concat(Array.prototype.slice.call(arguments)));

        this.error(message, options);
      }
    }
  });

  _exports.default = _default;
});