define("innowaze-ember2/routes/departments/edit", ["exports", "innowaze-ember2/routes/departments/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    controllerName: "departments/common",
    model: function model(params) {
      return this.store.findRecord("department", params.department_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("departmentUsers", []);

      if (model.get("admins").length > 0) {
        controller.departmentUsers.pushObjects(model.get("admins").toArray());
      }
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      save: function save() {
        var _this = this;

        this.currentModel.set("admin_ids", this.currentModel.get("admins").map(function (admin) {
          return admin.id;
        }));
        this.currentModel.save().then(function (result) {
          return _this._onSuccess(result);
        }, function (errors) {
          return _this._onError(errors);
        });
      }
    },
    _onSuccess: function _onSuccess(department) {
      this.transitionTo("departments.show", department.id);
      this.notifications.success(this.locale.t("departments.updated"));
    },
    _onError: function _onError(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.locale.t("error"), errors);
    }
  });

  _exports.default = _default;
});