define("innowaze-ember2/adapters/pathway", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    toggleContribute: function toggleContribute(id, pathway) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(pathway.get("challenge_id"), "/").concat(this.pathForType("pathway"), "/").concat(id, "/toggle_contribute.json"), "POST");
    },
    addTeam: function addTeam(id, pathway, userId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(pathway.get("challenge_id"), "/").concat(this.pathForType("pathway"), "/").concat(id, "/add_team.json"), "POST", {
        data: {
          user_id: userId
        }
      });
    },
    addFile: function addFile(id, pathway, fileId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(pathway.get("challenge_id"), "/").concat(this.pathForType("pathway"), "/").concat(id, "/add_file.json"), "POST", {
        data: {
          file_id: fileId
        }
      });
    },
    translate: function translate(id, pathway, language) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(pathway.get("challenge_id"), "/").concat(this.pathForType("pathway"), "/").concat(id, "/translate.json?language=").concat(language), "GET");
    },
    convertToKnowledge: function convertToKnowledge(id, pathway) {
      var data = pathway.getProperties("name", "description", "logo_id", "user_id", "analysis", "exploration_field", "slide_url", "documents");
      data.tags = pathway.get("tags").toArray();
      data.teammates = pathway.get("teammates").toArray();
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(pathway.get("challenge_id"), "/").concat(this.pathForType("pathway"), "/").concat(id, "/convert_to_knowledge.json"), "POST", {
        data: {
          pathway: data
        }
      });
    }
  });

  _exports.default = _default;
});