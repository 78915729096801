define("innowaze-ember2/components/clearing-image", ["exports", "basiclightbox", "innowaze-ember2/configuration"], function (_exports, basicLightbox, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _firstOf: function _firstOf(array) {
      if (array.length === 0) {
        return null;
      }

      if (this.get(array[0])) {
        return this.get(array[0]);
      }

      return this._firstOf(array.slice(1));
    },
    url: Ember.computed("object.image_url", "object.avatar", "object.url", "object.file_url", "object.picture_url", function () {
      var url = this._firstOf(["object.image_url", "object.avatar", "object.url", "object.file_url", "object.picture_url"]);

      if (!url) {
        return "";
      }

      if (/^\/assets\/images\//.test(url)) {
        return url;
      }

      var apiUrlMatch = new RegExp(_configuration.default.apiUrl);
      return _configuration.default.apiUrl + url.replace(apiUrlMatch, "");
    }),
    thumbnail: Ember.computed("object.large_logo_url", "object.logo_url", "object.avatar", "object.url", "object.file_url", "object.small_picture_url", "object.picture_url", function () {
      var thumbnail = this._firstOf(["object.large_logo_url", "object.logo_url", "object.avatar", "object.url", "object.file_url", "object.small_picture_url", "object.picture_url"]);

      if (!thumbnail) {
        return "";
      }

      if (/^\/assets\/images\//.test(thumbnail)) {
        return thumbnail;
      }

      var apiUrlMatch = new RegExp(_configuration.default.apiUrl);
      return _configuration.default.apiUrl + thumbnail.replace(apiUrlMatch, "");
    }),
    actions: {
      open: function open() {
        if (this.url !== "") {
          basicLightbox.create("<img src=\"".concat(this.url, "\">")).show();
        }
      }
    }
  });

  _exports.default = _default;
});