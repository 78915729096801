define("innowaze-ember2/routes/challenge-email-templates", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id, {
          backgroundReload: false
        }),
        emailTemplates: this.store.query("email-template", {
          challenge_id: params.challenge_id,
          with_globals: false
        })
      });
    },
    actions: {
      delete: function _delete(template) {
        var _this = this;

        if (confirm(this.locale.t("domains.index.deletion_confirmation"))) {
          template.destroyRecord().then(function () {
            _this.notifications.success(_this.locale.t("domains.index.deleted"));
          });
        }
      }
    }
  });

  _exports.default = _default;
});