define("innowaze-ember2/routes/events/new", ["exports", "innowaze-ember2/routes/events/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    model: function model(params) {
      return this.store.createRecord("event");
    },
    actions: {
      cancel: function cancel() {
        this.transitionToRoute("challenges");
      }
    },
    _success: function _success() {
      this.transitionTo("events");
      this.notifications.success("L'événement a été créé.");
    }
  });

  _exports.default = _default;
});