define("innowaze-ember2/controllers/challenges/awaiting-members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    awaiting_users: [],
    awaitingCount: Ember.computed("awaiting_users.[]", function () {
      return this.get("awaiting_users.length");
    }),
    selectedUsers: Ember.computed.filterBy("awaiting_users", "selected", true),
    selectedCount: Ember.computed.alias("selectedUsers.length")
  });

  _exports.default = _default;
});