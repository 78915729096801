define("innowaze-ember2/helpers/parse-int", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parse_int = parse_int;
  _exports.default = void 0;

  function parse_int(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        numberish = _ref2[0];

    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      base: 10
    };
    return parseInt(numberish, options.base);
  }

  var _default = Ember.Helper.helper(parse_int);

  _exports.default = _default;
});