define("innowaze-ember2/templates/challenges/modules/-ideas-per-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SuWE3Ccl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"session\",\"isCompetitor\"]]],null,{\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"statesIsLoaded\"]],[23,[\"statesFunnel\",\"length\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"ideas-funnel-block dashboard-module\"],[9],[0,\"\\n      \"],[7,\"h5\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[1,[27,\"i18n\",[\"challenges.ideas_per_state.title\"],[[\"ideas\"],[[27,\"name-for\",[[23,[\"model\"]],\"ideas\",\"plural_idea\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"id\",\"funnel\"],[9],[0,\"\\n        \"],[1,[27,\"funnel-chart\",null,[[\"data\",\"fillType\",\"height\"],[[23,[\"statesFunnel\"]],\"gradient\",400]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-ideas-per-state.hbs"
    }
  });

  _exports.default = _default;
});