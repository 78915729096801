define("innowaze-ember2/routes/surveys/new", ["exports", "innowaze-ember2/routes/surveys/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    successMessage: "Le sondage a été créé.",
    errorMessage: Ember.computed(function () {
      return this.locale.t("creation_error");
    }),
    model: function model(params) {
      this.set("errorMessage", this.locale.t("creation_error"));
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        survey: this.store.createRecord("survey", {
          challenge_id: params.challenge_id,
          available_answers: [Ember.Object.create({
            id: null,
            text: ""
          }), Ember.Object.create({
            id: null,
            text: ""
          })]
        })
      });
    }
  });

  _exports.default = _default;
});