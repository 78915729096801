define("innowaze-ember2/routes/knowledges/edit", ["exports", "innowaze-ember2/routes/knowledges/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("knowledge", params.knowledge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("selectedCategory", controller.availableCategories.findBy("id", model.category));
    },
    _success: function _success(result) {
      this.transitionTo("knowledges.show", result.id);
      this.notifications.success(this.locale.t("knowledges.updated"));
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.locale.t("modification_error"), response.errors);
    }
  });

  _exports.default = _default;
});