define("innowaze-ember2/templates/components/modules/domains-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zf3753/l",
    "block": "{\"symbols\":[\"domain\"],\"statements\":[[4,\"if\",[[23,[\"editMode\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"ideas.modules.domains.domains\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"module\",\"control\"]]],null,{\"statements\":[[0,\"      \"],[7,\"sup\"],[12,\"class\",[27,\"if\",[[23,[\"module\",\"isNotCompleted\"]],\"red\",\"green\"],null]],[9],[0,\" *\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[15,\"partials/explanation\",[]],[0,\"\\n\\n\"],[4,\"power-select-multiple\",null,[[\"options\",\"selected\",\"searchField\",\"placeholder\",\"closeOnSelect\",\"loadingMessage\",\"onchange\"],[[23,[\"availableDomains\"]],[23,[\"model\",\"domains\"]],\"name\",[27,\"i18n\",[\"ideas.modules.domains.placeholder\"],null],false,\"loading...\",[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"setDomains\"],null]]],{\"statements\":[[0,\"      \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/modules/domains-module.hbs"
    }
  });

  _exports.default = _default;
});