define("innowaze-ember2/routes/challenge-juries", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id, {
          backgroundReload: false
        }),
        juries: this.store.query("jury", {
          challenge_id: params.challenge_id
        }),
        evaluators: this.store.query("user", {
          challenges: [params.challenge_id],
          roles: ["reviewer", "admin", "moderator"],
          paginate: false
        })
      });
    },
    actions: {
      deleteJury: function deleteJury(jury) {
        var _this = this;

        if (!confirm(this.locale.t("challenges.juries.deletion_confirmation"))) {
          return;
        }

        jury.destroyRecord({
          adapterOptions: {
            challenge_id: jury.get("challenge_id")
          }
        }).then(function () {
          _this.notifications.success(_this.locale.t("challenges.juries.deleted"));
        });
      },
      setUser: function setUser(_ref, ops) {
        var user = _ref.user,
            fromJury = _ref.fromJury;

        if (fromJury) {
          this._removeUser(fromJury, user);
        }

        var jury = ops.target.jury;

        if (jury.users.find(function (u) {
          return u.id === user.id;
        })) {
          return;
        }

        jury.users.addObject(user);
        jury.save({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id
          }
        });
      },
      removeUser: function removeUser() {
        this._removeUser.apply(this, arguments);
      }
    },
    _removeUser: function _removeUser(jury, user) {
      jury.users.removeObject(user);
      jury.save({
        adapterOptions: {
          challenge_id: this.currentModel.challenge.id
        }
      });
    }
  });

  _exports.default = _default;
});