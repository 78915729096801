define("innowaze-ember2/components/linked-debate-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["linked-debate-container"],
    didInsertElement: function didInsertElement() {
      if (this.table) {
        Ember.run.scheduleOnce("afterRender", this, function () {
          var _this = this;

          $(window).on("resize orientationchange", function () {
            return _this.setHeight;
          });
          this.setHeight();
        });
      }
    },
    setHeight: function setHeight() {
      this.set("tableHeight", this.$("#canvas").width() * this.ratio);
    },
    backgroundStyle: Ember.computed("background", function () {
      var background = this.background;

      if (!background) {
        return "";
      }

      return Ember.String.htmlSafe("background: white url(/assets/images/canvas_background/".concat(background, ") center center no-repeat; background-size: 100% auto;"));
    }),
    safeSrc: Ember.computed("src", function () {
      return Ember.String.htmlSafe(this.src);
    }),
    dragEnter: function dragEnter(event) {
      return event.preventDefault();
    },
    dragOver: function dragOver(event) {
      return event.preventDefault();
    },
    dragLeave: function dragLeave(event) {
      return event.preventDefault();
    },
    touchEnd: function touchEnd(event) {
      event.stopPropagation();

      if (event.target.classList.contains("static")) {
        var attrs = this._computePosition(event);

        if (attrs.left > 1 && attrs.top > 1) {
          if (this.image) {
            attrs = Ember.merge(attrs, {
              badgeable_id: this.get("image.id"),
              badgeable_type: "Upload"
            });
          }

          this.sendAction("addLinkedDebateBadge", attrs);
        }
      }
    },
    drop: function drop(event) {
      var attrs = this._computePosition(event);

      if (this.image) {
        attrs = Ember.merge(attrs, {
          badgeable_id: this.get("image.id"),
          badgeable_type: "Upload"
        });
      }

      this.sendAction("addLinkedDebateBadge", attrs);
    },
    _computePosition: function _computePosition(event) {
      var clientX;
      var clientY;

      if (event.type === "drop") {
        clientX = event.originalEvent.clientX - 18;
        clientY = event.originalEvent.clientY - 18;
      } else {
        clientX = event.originalEvent.changedTouches[0].clientX - 18;
        clientY = event.originalEvent.changedTouches[0].clientY - 18;
      }

      var offset = this.$().offset();
      var absoluteX = clientX - offset.left;
      var absoluteY = clientY - offset.top;

      if (/Safari|Firefox/g.test(navigator.userAgent)) {
        absoluteX += window.scrollX;
        absoluteY += window.scrollY;
      }

      return {
        left: absoluteX * 100 / this.$().width(),
        top: absoluteY * 100 / this.$().height()
      };
    },
    actions: {
      labelDroppedToCell: function labelDroppedToCell() {
        this.sendAction.apply(this, ["labelDroppedToCell"].concat(Array.prototype.slice.call(arguments)));
      },
      editLabel: function editLabel() {
        this.sendAction.apply(this, ["editLabel"].concat(Array.prototype.slice.call(arguments)));
      },
      removeLabel: function removeLabel() {
        this.sendAction.apply(this, ["removeLabel"].concat(Array.prototype.slice.call(arguments)));
      },
      selectForValueEdition: function selectForValueEdition() {
        this.sendAction.apply(this, ["selectForValueEdition"].concat(Array.prototype.slice.call(arguments)));
      },
      deleteDroppable: function deleteDroppable(image) {
        this.sendAction("deleteDroppable", image);
      },
      deleteBadge: function deleteBadge(image) {
        this.sendAction("deleteBadge", image);
      },
      displayDebateForBadge: function displayDebateForBadge(badge) {
        this.sendAction("displayDebateForBadge", badge);
      }
    }
  });

  _exports.default = _default;
});