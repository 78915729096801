define("innowaze-ember2/routes/ideas/clustering", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _objectSpread2, _setCurrentChallenge, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _selectAutocomplete.default, {
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id, {
        backgroundReload: false
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      var similarIdeas = transition.queryParams.similarIdeas;

      if (similarIdeas) {
        controller.set("query", similarIdeas.replace(/,/g, " "));
      }

      controller.setProperties({
        availableDomains: this.store.query("domain", {
          challenge_id: model.id
        }),
        availableCreators: [],
        tagsToDisplay: [],
        selectedColor: controller.colorVars.findBy("name", "state_name"),
        selectedSize: controller.sizeVars.findBy("name", "stakes")
      });

      this._searchIdeas(controller).then(function (r) {
        controller.set("availableCreators", r.reduce(function (acc, idea) {
          acc.pushObject({
            id: idea.get("author_id"),
            screenname: idea.get("author_name")
          });
          return acc;
        }, []).uniq());
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          query: null,
          tags: [],
          creators: [],
          states: [],
          domain: null,
          typology: null
        });
      }
    },
    _searchIdeas: function _searchIdeas(controller) {
      var options = {
        list: false,
        extended: true,
        clustering: true,
        // allows searching ideas with invisible states
        root: true,
        paginate: undefined,
        page: undefined,
        order: undefined,
        must: undefined,
        challenge_id: this.currentModel.id
      };
      var query = this.get("controller.query");
      var tags = this.get("controller.tags");
      var departments = this.get("controller.departments");
      var domain = this.get("controller.domain");
      var typology = this.get("controller.typology");
      var users = this.get("controller.creators");
      var states = this.get("controller.states");
      return this.store.query("idea", (0, _objectSpread2.default)({}, options, {}, states && states.length && {
        states: states.mapBy("id")
      }, {}, query && {
        query: query
      }, {}, domain && {
        domain: domain
      }, {}, typology && {
        typology: typology
      }, {}, tags && tags.length > 0 && {
        tags: tags.mapBy("name")
      }, {}, departments && departments.length > 0 && {
        departments: departments.mapBy("id")
      }, {}, users && users.length > 0 && {
        users: users.mapBy("id")
      })).then(function (result) {
        var ideas = result.toArray().filterBy("private", false);
        controller.setProperties({
          matchingIdeas: ideas
        });
        return ideas;
      });
    },
    actions: {
      search: function search() {
        this._searchIdeas(this.controller);
      },
      selectDomain: function selectDomain(domain) {
        this.get("controller.availableDomains").setEach("selected", false);

        if (this.get("controller.domain") === domain.get("id")) {
          this.controller.set("domain", undefined);
        } else {
          domain.set("selected", true);
          this.controller.set("domain", domain.get("id"));
        }

        this._searchIdeas(this.controller);
      },
      selectTypology: function selectTypology(typology) {
        if (this.get("controller.typology") === typology) {
          this.controller.set("typology", undefined);
        } else {
          this.controller.set("typology", typology);
        }

        this._searchIdeas(this.controller);
      },
      selectStates: function selectStates(states) {
        this.controller.set("states", states);

        this._searchIdeas(this.controller);
      },
      selectCreators: function selectCreators(creators) {
        this.controller.set("creators", creators);

        this._searchIdeas(this.controller);
      },
      selectTags: function selectTags(tags) {
        this.controller.set("tags", tags);

        this._searchIdeas(this.controller);
      }
    }
  });

  _exports.default = _default;
});