define("innowaze-ember2/templates/challenges/modules/-tagcloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5huRo/5f",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[4,\"unless\",[[23,[\"session\",\"isCompetitor\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"challenges.tagcloud.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"tags-cloud-block\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"no-bullet inline-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"tags_cloud\"]]],null,{\"statements\":[[4,\"font-size\",null,[[\"count\"],[[22,1,[\"count\"]]]],{\"statements\":[[4,\"link-to\",[\"ideas\",[23,[\"model\",\"id\"]],[27,\"query-params\",null,[[\"_tags\"],[[22,1,[\"id\"]]]]]],null,{\"statements\":[[0,\"              \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-tagcloud.hbs"
    }
  });

  _exports.default = _default;
});