define("innowaze-ember2/models/scope", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    level: _emberData.default.attr("string"),
    children: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    parent: _emberData.default.attr("string"),
    deployed: _emberData.default.attr("boolean")
  });

  _exports.default = _default;
});