define("innowaze-ember2/templates/homepage/-sponsors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a6ZaWzyo",
    "block": "{\"symbols\":[\"department\"],\"statements\":[[7,\"div\"],[11,\"class\",\"homepage-last-departments\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"homepage-sponsors-title\"],[9],[1,[27,\"i18n\",[\"homepage.sponsors\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"homepage-sponsor-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"displaySponsors\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"homepage-last-department\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"currentUser\",\"id\"]]],null,{\"statements\":[[4,\"link-to\",[\"departments.show\",[22,1,[\"id\"]]],null,{\"statements\":[[0,\"              \"],[7,\"img\"],[12,\"src\",[28,[[23,[\"session\",\"apiUrl\"]],[22,1,[\"logo_native_url\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"img\"],[12,\"src\",[28,[[23,[\"session\",\"apiUrl\"]],[22,1,[\"logo_native_url\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/homepage/-sponsors.hbs"
    }
  });

  _exports.default = _default;
});