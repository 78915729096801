define("innowaze-ember2/routes/ideas/show-clustering", ["exports", "innowaze-ember2/routes/ideas/clustering"], function (_exports, _clustering) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _clustering.default.extend({
    controllerName: "ideas/clustering",
    model: function model(params) {
      this.set("ideaId", params.idea_id);
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.store.findRecord("idea", this.ideaId, {
        adapterOptions: {
          challenge_id: model.id
        }
      }).then(function (idea) {
        return controller.set("idea", idea);
      });
    }
  });

  _exports.default = _default;
});