define("innowaze-ember2/components/department-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["banner", "department"],
    actions: {
      delete: function _delete(department) {
        this.sendAction("deleteDepartment", department);
      }
    }
  });

  _exports.default = _default;
});