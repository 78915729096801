define("innowaze-ember2/routes/knowledges/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("knowledge", params.knowledge_id, {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("accredited_users", []);

      if (this.get("session.configuration.dict.deepl_translation")) {
        var language = this.get("session.configuration.deepl_languages").findBy("id", "-");
        controller.setProperties({
          availableTranslationLanguages: this.get("session.configuration.deepl_languages"),
          translatedName: null,
          translatedAbstract: null,
          translatedDescription: null,
          translatedLessonsLearned: null,
          translatedImplementationConditions: null,
          translatedComments: null,
          targetLanguage: language
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("displayAccreditations", false);
      }
    },
    actions: {
      displayAccreditations: function displayAccreditations() {
        var _this = this;

        if (this.toggleProperty("controller.displayAccreditations")) {
          this.currentModel.getAccreditations().then(function (data) {
            _this.set("controller.accredited_users", data);
          });
        }
      },
      delete: function _delete() {
        var _this2 = this;

        if (!confirm(this.locale.t("knowledges.index.deletion_confirmation"))) {
          return;
        }

        this.currentModel.destroyRecord().then(function () {
          _this2.transitionTo("knowledges");

          _this2.notifications.success(_this2.locale.t("knowledges.index.deleted"));
        });
      },
      translate: function translate(lang) {
        var _this3 = this;

        var language = this.controller.availableTranslationLanguages.findBy("id", lang);
        this.controller.set("targetLanguage", language);
        this.currentModel.translate(lang).then(function (result) {
          if (result && result.status) {
            _this3.controller.setProperties({
              translatedName: result.name,
              translatedAbstract: result.abstract,
              translatedDescription: result.description,
              translatedLessonsLearned: result.lessons_learned,
              translatedImplementationConditions: result.implementation_conditions,
              language: result.language,
              translatedComments: result.comments
            });
          } else {
            _this3.controller.setProperties({
              translatedName: null,
              translatedAbstract: null,
              translatedDescription: null,
              translatedLessonsLearned: null,
              translatedImplementationConditions: null,
              language: null,
              translatedComments: null
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});