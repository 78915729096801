define("innowaze-ember2/routes/users/edit-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.Object.create({
        id: params.user_id,
        current_password: undefined,
        password: undefined,
        accept_cgu: false,
        password_confirmation: undefined
      });
    },
    actions: {
      savePassword: function savePassword() {
        var _this = this;

        var data = {
          user: this.currentModel.getProperties("current_password", "password", "password_confirmation", "accept_cgu")
        };

        if (!data.user.accept_cgu && this.get("session.currentUser.force_password_update") || data.user.current_password === undefined && !this.get("session.currentUser.force_password_update") || data.user.password === undefined || data.user.password_confirmation === undefined) {
          return false;
        }

        this.store.adapterFor("user").savePassword(this.currentModel.get("id"), data).then(function () {
          return _this._success();
        }).catch(function (response) {
          _this.notifications.errorWithList(_this.locale.t("modification_error"), response.errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      }
    },
    _success: function _success() {
      this.session.set("currentUser.force_password_update", false);
      this.notifications.success("Le mot de passe a été modifié.");
      this.transitionTo("login");
    }
  });

  _exports.default = _default;
});