define("innowaze-ember2/controllers/challenges/members", ["exports", "innowaze-ember2/mixins/controllers/userTags"], function (_exports, _userTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_userTags.default, {
    tags: [],
    departments: [],
    challenges: [],
    matchingUsers: [],
    totalResults: 0,
    order: undefined,
    no_email: false,
    selectedUsers: Ember.computed.filterBy("matchingUsers", "selected", true),
    noSelection: Ember.computed.empty("selectedUsers"),
    selectedCount: Ember.computed.alias("selectedUsers.length"),
    usersCount: Ember.computed.alias("members.length"),
    allSelected: Ember.computed("selectedCount", "matchingUsers.[]", function () {
      return this.selectedCount === this.get("matchingUsers.length") && this.selectedCount > 0;
    })
  });

  _exports.default = _default;
});