define("innowaze-ember2/models/tag", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    name: _emberData.default.attr("string"),
    supertag: _emberData.default.attr("boolean"),
    supertags: _emberData.default.attr(),
    isUpdating: _emberData.default.attr("boolean"),
    setSupertag: function setSupertag(challenge_id) {
      var _this = this;

      this.set("isUpdating", true);
      return this._callAdapter("setSupertag", challenge_id).then(function (supertag) {
        if (!_this.supertags) {
          _this.set("supertags", []);
        }

        _this.supertags.addObject(supertag);

        _this.setProperties({
          supertag: true,
          isUpdating: false
        });
      });
    },
    removeSupertag: function removeSupertag(supertag) {
      var _this2 = this;

      this.set("isUpdating", true);
      return this._callAdapter("removeSupertag", supertag.id).then(function () {
        _this2.supertags.removeObject(supertag);

        _this2.setProperties({
          supertag: false,
          isUpdating: false
        });
      });
    }
  });

  _exports.default = _default;
});