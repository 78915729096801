define("innowaze-ember2/utils/formatEvaluation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.valueToPercent = valueToPercent;
  _exports.percentToValue = percentToValue;

  function valueToPercent(value, min, max) {
    return (value - min) / (max - min) * 100;
  }

  function percentToValue(percent, min, max) {
    return Math.round(min + (max - min) * percent / 100);
  }
});