define("innowaze-ember2/components/modules/purchasing-levers-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    completedDidChange: Ember.on("init", Ember.observer("model.methodology", "model.strategic_lever", "model.innovation_index", "model.methodology_comment", function () {
      var hasMethodologyOrLever = this.get("model.methodology") && this.get("model.strategic_lever") && this.get("model.innovation_index") !== undefined;

      if (hasMethodologyOrLever) {
        this.set("hasMethodologyOrLever", true);
      }

      this.set("module.isNotCompleted", !hasMethodologyOrLever || this.get("model.methodology") === "Other" && !this.get("model.methodology_comment"));
    }))
  });

  _exports.default = _default;
});