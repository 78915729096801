define("innowaze-ember2/routes/tag-triggers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    model: function model() {
      return this.store.findAll("tagTrigger");
    },
    actions: {
      delete: function _delete(tagTrigger) {
        var _this = this;

        if (!confirm("Voulez-vous vraiment supprimer le tag automatique ?")) {
          return;
        }

        tagTrigger.destroyRecord().then(function () {
          _this.notifications.success("Le tag automatique a été supprimé.");
        });
      }
    }
  });

  _exports.default = _default;
});