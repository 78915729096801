define("innowaze-ember2/routes/knowledges", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _defineProperty2, _objectSpread3, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.availableCategories.setEach("selected", false);
      }
    },
    setupController: function setupController(controller, model, transition) {
      var _this = this;

      var domainParams = parseInt(transition.queryParams.domain);
      controller.setProperties({
        domainParams: domainParams,
        creators: [],
        categories: [],
        query: "",
        domain: "",
        availableDomains: [],
        tagsToDisplay: [],
        tags: [],
        page: 1,
        filtersHidden: true
      });
      this.store.adapterFor("user").knowledgeCreatorsSearch().then(function (users) {
        controller.set("availableCreators", users);
      });
      this.store.query("domain", {}).then(function (domains) {
        controller.set("availableDomains", domains);

        if (domainParams) {
          var domain = domains.findBy("id", domainParams.toString());
          domain.set("selected", true);
          controller.set("selectedDomain", domain.get("name"));
          controller.set("domain", domain.get("id"));
        }

        _this._search();
      });
    },
    actions: {
      toggleCategory: function toggleCategory(c) {
        var category = this.controller.availableCategories.findBy("id", c.id);

        if (category.selected) {
          this.controller.categories.removeObject(category);
        } else {
          this.controller.categories.pushObject(category);
        }

        this.controller.availableCategories.setEach("selected", false);
        this.controller.categories.setEach("selected", true);

        this._search();
      },
      search: function search() {
        this.controller.availableCategories.setEach("selected", false);
        this.controller.categories.setEach("selected", true);

        this._search();
      },
      loadMore: function loadMore() {
        this.controller.incrementProperty("page");
        this.controller.set("isLoadingMore", true);

        this._loadData();
      },
      delete: function _delete(knowledge) {
        var _this2 = this;

        if (!confirm(this.locale.t("knowledges.index.deletion_confirmation"))) {
          return;
        }

        knowledge.destroyRecord().then(function () {
          _this2.notifications.success(_this2.locale.t("knowledges.index.deleted"));
        });
      },
      selectDomain: function selectDomain(domain) {
        var controller = this.controller;
        controller.availableDomains.setEach("selected", false);

        if (controller.domain === domain.get("id")) {
          controller.set("selectedDomain", undefined);
          controller.set("domain", undefined);
        } else {
          controller.set("domain", domain.get("id"));
          controller.set("selectedDomain", domain.get("name"));
          domain.set("selected", true);
        }

        this.send("search");
      },
      toggle: function toggle(id) {
        var label = $("label[for='".concat(id, "']"));

        if (label.length === 0) {
          label = $("label[for='".concat(id, "-selectized']"));
        }

        label.toggleClass("opened closed");
        label.next(".toggable").toggleClass("hide");
      }
    },
    _loadData: function _loadData() {
      var _this3 = this;

      var options = this._getFilter();

      return this.store.query("knowledge", options).then(function (result) {
        if (_this3.get("controller.isLoadingMore")) {
          _this3.get("controller.matchingKnowledges").pushObjects(result.toArray());

          _this3.controller.set("isLoadingMore", false);
        } else {
          _this3.controller.setProperties({
            matchingKnowledges: result.toArray(),
            isLoading: false
          });
        }

        _this3.set("controller.totalResults", result.get("meta.total_results"));
      });
    },
    _search: function _search() {
      this.controller.setProperties({
        isLoading: true,
        page: 1
      });

      this._loadData();
    },
    _getFilter: function _getFilter() {
      var _this4 = this;

      var filters = ["query", "domain"].reduce(function (filters, filter) {
        if (_this4.get("controller.".concat(filter))) {
          return (0, _objectSpread3.default)({}, filters, (0, _defineProperty2.default)({}, filter, _this4.get("controller.".concat(filter)).trim()));
        }

        return filters;
      }, {});
      return (0, _objectSpread3.default)({}, filters, {
        tags: this.get("controller.tags").mapBy("name"),
        users: this.get("controller.creators").mapBy("id"),
        categories: this.get("controller.categories").mapBy("id"),
        paginate: true,
        page: this.get("controller.page")
      });
    }
  });

  _exports.default = _default;
});