define("innowaze-ember2/controllers/challenges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var sum = function sum(a, b) {
    return a + b;
  };

  var _default = Ember.Controller.extend({
    locale: Ember.inject.service(),
    queryParams: ["department", "sort", "order", "group", "universe"],
    firstLoad: true,
    nameFilter: "",
    department: null,
    group: null,
    universe: null,
    sort: "position",
    order: "desc",
    intervalId: null,
    orderReverse: Ember.computed("order", function () {
      return {
        users_count: this.sort === "users_count" ? this.order ? null : "desc" : null,
        ideas_count: this.sort === "ideas_count" ? this.order ? null : "desc" : null,
        concepts_count: this.sort === "concepts_count" ? this.order ? null : "desc" : null,
        comments_count: this.sort === "comments_count" ? this.order ? null : "desc" : null
      };
    }),
    actions: {
      toggleDisplayConcepts: function toggleDisplayConcepts(challenge) {
        challenge.toggleProperty("display_concepts");
      }
    },
    signupTitle: Ember.computed("session.configuration.dict.signup_title", function () {
      if (Ember.isBlank(this.get("session.configuration.dict.signup_title"))) {
        return this.locale.t("homepage.signup_title");
      }

      return this.get("session.configuration.dict.signup_title");
    }),
    signupSubTitle: Ember.computed("session.configuration.dict.signup_subtitle", function () {
      if (Ember.isBlank(this.get("session.configuration.dict.signup_subtitle"))) {
        return this.locale.t("homepage.signup_subtitle");
      }

      return this.get("session.configuration.dict.signup_subtitle");
    }),
    displayLastNews: Ember.computed("session.configuration.dict.last_news", "lastArticles", function () {
      var articles = this.lastArticles;
      return this.get("session.configuration.dict.last_news") && articles && articles.length;
    }),
    displayEvents: Ember.computed("session.configuration.dict.events", "events", function () {
      return this.get("session.configuration.dict.events") && this.events && this.get("events.length");
    }),
    latestNewsChanged: Ember.observer("lastArticles", function () {
      var _this = this;

      if (this.lastArticles.length > 1 && this.displayLastNews) {
        var intervalId = setInterval(function () {
          var active = _this.lastArticles.findBy("active", true);

          var index = _this.lastArticles.indexOf(active);

          var newIndex = index === _this.lastArticles.length - 1 ? 0 : index + 1;

          if (active !== null) {
            active.set("active", false);
          }

          _this.lastArticles[newIndex].set("active", true);
        }, 5000);
        this.set("intervalId", intervalId);
      }
    }),
    filteredChallenges: Ember.computed("universe", "department", "group", "nameFilter", "model.challenges.[]", function () {
      var _this2 = this;

      var universe = this.universe;
      var department = this.department;
      var group = this.group;
      var nameFilter = this.nameFilter;
      var filteredChallenges = this.get("model.challenges");

      if (universe === "instance") {
        filteredChallenges = filteredChallenges.filter(function (challenge) {
          return challenge.get("universes").length === 0;
        });
      } else if (universe && universe !== "all") {
        var universeId = parseInt(universe); // Display current universe's challenges and instance's challenges if it's the first time, page is visited

        filteredChallenges = filteredChallenges.filter(function (challenge) {
          return challenge.get("universes").map(function (universe) {
            return parseInt(universe.id);
          }).includes(universeId) || _this2.firstLoad && challenge.get("universes.length") === 0;
        });
      }

      if (department && department !== "all") {
        filteredChallenges = filteredChallenges.filterBy("department.id", parseInt(department));
      }

      if (group && group !== "all") {
        filteredChallenges = filteredChallenges.filterBy("challenge_group_id", parseInt(group));
      }

      if (nameFilter.length > 0) {
        var lcNameFilter = nameFilter.toLowerCase();
        filteredChallenges = filteredChallenges.filter(function (challenge) {
          return challenge.get("name").toLowerCase().includes(lcNameFilter);
        });
      }

      return filteredChallenges;
    }),
    sortedChallenges: Ember.computed.sort("filteredChallenges", function (a, b) {
      if (this.sort) {
        if (this.order) {
          return a.get(this.sort) - b.get(this.sort);
        }

        return b.get(this.sort) - a.get(this.sort);
      }

      return a.get("id") - b.get("id");
    }).property("sort", "order", "filteredChallenges", "model.challenges.[]"),
    challengeByUniverse: Ember.computed("sortedChallenges", function () {
      return this.sortedChallenges.reduce(function (groups, challenge) {
        if (challenge.get("universes.length") === 0) {
          groups.findBy("name", "plateforme").get("challenges").pushObject(challenge);
          return groups;
        }

        challenge.get("universes").forEach(function (universe) {
          var name = universe.get("name");

          if (!groups.findBy("name", name)) {
            groups.pushObject(Ember.Object.create({
              name: name,
              challenges: []
            }));
          }

          groups.findBy("name", name).get("challenges").pushObject(challenge);
        });
        return groups;
      }, [Ember.Object.create({
        name: "plateforme",
        challenges: []
      })]);
    }),
    totalUsers: Ember.computed("model.challenges", function () {
      var firstChallenge = this.get("model.challenges.firstObject");
      return firstChallenge ? firstChallenge.get("total_users") : 0;
    }),
    totalIdeas: Ember.computed("model.challenges", function () {
      return this.get("model.challenges").mapBy("ideas_count").reduce(sum, 0);
    }),
    totalActiveUsers: Ember.computed("model.challenges", function () {
      var firstChallenge = this.get("model.challenges.firstObject");
      return firstChallenge ? firstChallenge.get("total_active_users") : 0;
    }),
    totalComments: Ember.computed("model.challenges", function () {
      return this.get("model.challenges").mapBy("comments_count").reduce(sum, 0);
    }),
    lastArticles: Ember.computed("news", function () {
      if (!this.news) {
        return [];
      }

      return this.news.slice(0, 8).map(function (el, index) {
        el.set("active", index === 0);
        return el;
      });
    }),
    enableDefaultUniverse: Ember.computed("session.hasUniverses", "universe", "session.currentUser.default_universe_id", function () {
      return this.get("session.hasUniverses") && this.universe && this.universe !== "instance" // instance challenges pseudo univers
      && this.universe !== "all";
    }),
    universeName: Ember.computed("universe", function () {
      var universe = this.get("session.availableUniverses").findBy("id", this.universe);
      return universe ? universe.get("name") : "";
    }),
    hasDepartments: Ember.computed("departments", function () {
      return this.departments && this.departments.length > 1;
    }),
    backgroundBanner: Ember.computed("session.configuration.banner_url", function () {
      return Ember.String.htmlSafe("background-image: url(".concat(this.get("session.apiUrl")).concat(this.get("session.configuration.banner_url"), ");"));
    })
  });

  _exports.default = _default;
});