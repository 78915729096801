define("innowaze-ember2/mixins/routes/saveAsImage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      saveAsImage: function saveAsImage() {
        var _arguments = arguments;

        this._dataFromSVG().then(function (dataUrl) {
          var id = "innowaze-download-image-".concat(Math.round(Math.random() * 1000000));
          var a = document.createElement("a");

          if (typeof a.download !== "undefined") {
            a.id = id;
            a.setAttribute("href", dataUrl);
            a.setAttribute("download", "graph.png");
            document.body.appendChild(a);
            document.getElementById(id).click();
          } else {
            document.getElementById("downloadImageLink").href = dataUrl;
            $("#downloadImageModal").foundation("reveal", "open");
          }
        }, function () {
          return console.error("an error happened", _arguments);
        });
      }
    },
    _dataFromSVG: function _dataFromSVG() {
      var svg = document.getElementById("main").getElementsByTagName("svg")[0];
      var canvas = document.createElement("canvas");
      var svgData = new XMLSerializer().serializeToString(svg);
      var svgSize = svg.getBoundingClientRect();
      var data = btoa(unescape(encodeURIComponent(svgData)));
      canvas.width = svgSize.width;
      canvas.height = svgSize.height;
      var img = new Image();
      var ctx = canvas.getContext("2d", {
        alpha: true
      });
      return new Promise(function (resolve, reject) {
        img.addEventListener("load", function () {
          ctx.drawImage(img, 0, 0);
          ctx.globalAlpha = 0.5;
          resolve(canvas.toDataURL("image/png"));
        });
        img.addEventListener("error", reject);
        img.src = "data:image/svg+xml;base64,".concat(data);
      });
    }
  });

  _exports.default = _default;
});