define("innowaze-ember2/components/modules/contributors-module-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    contributing: Ember.computed("model.contributors.[]", function () {
      var _this = this;

      if (!this.get("model.contributors")) {
        return false;
      }

      return this.get("model.contributors").any(function (c) {
        return c.id === _this.get("session.currentUser.id");
      });
    }),
    contributeText: Ember.computed("contributing", function () {
      if (this.contributing) {
        return this.locale.t("ideas.show.uncontribute");
      }

      return this.locale.t("ideas.show.contribute");
    }),
    actions: {
      toggleContribute: function toggleContribute(user) {
        var _this2 = this;

        this.model.toggleContribute().then(function () {
          _this2.toggleProperty("contributing");

          if (_this2.contributing) {
            _this2.get("model.contributors").addObject(user);

            _this2.sendAction("onContribute");
          } else {
            var contributor = _this2.get("model.contributors").findBy("id", user.id);

            _this2.get("model.contributors").removeObject(contributor);
          }
        }, function () {
          return _this2.notifications.error(_this2.locale.t("ideas.show.contribute_error"));
        });
      }
    }
  });

  _exports.default = _default;
});