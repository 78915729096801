define("innowaze-ember2/utils/fileType", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fileType;

  function fileType(fileName) {
    var fileType = "other";

    if (/\.(gif|jpg|jpeg|tiff|png)(\?sha=.+)?$/i.test(fileName)) {
      fileType = "image";
    } else if (/\.(mp4|webm|ogv|m4v)(\?sha=.+)?$/i.test(fileName)) {
      fileType = "video";
    } else if (/\.(m4a|mp3|wav)(\?sha=.+)?$/i.test(fileName)) {
      fileType = "audio";
    } else if (/\.(ppt|pptx|doc|docx|xls|xlsx|pages|keynote|key|number|pdf)(\?sha=.+)?$/i.test(fileName)) {
      fileType = "document";
    }

    return fileType;
  }
});