define("innowaze-ember2/helpers/round-percentage", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundPercentage = roundPercentage;
  _exports.default = void 0;

  function roundPercentage(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        param = _ref2[0];

    if (!param) {
      return "0%";
    }

    return String(Math.round(param * 100)).concat("%");
  }

  var _default = Ember.Helper.helper(roundPercentage);

  _exports.default = _default;
});