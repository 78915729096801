define("innowaze-ember2/mixins/controllers/strongPassword", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    passwordChanged: Ember.observer("model.password", "model.password_confirmation", function () {
      var password = this.get("model.password");
      var passwordConfirmation = this.get("model.password_confirmation");
      var regex = new RegExp("(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[/#%+=?!@$%^&*_-])");
      var isPasswordValid = password && password.length >= 8 && regex.test(password);
      this.set("isPasswordValid", isPasswordValid);
      this.set("isPasswordConfirmationValid", isPasswordValid && passwordConfirmation === password);
    })
  });

  _exports.default = _default;
});