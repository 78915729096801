define("innowaze-ember2/components/reporting-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    classNames: ["tags-cloud"],
    modalIsOpen: false,
    typeId: 0,
    locale: Ember.inject.service(),
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.svg = d3.select(this.$().get(0));
    },
    draw: Ember.observer("type", function () {
      this.set("typeId", this.get("type.id"));
      this.set("dataLoaded", false);

      if (this.modalIsOpen) {
        this.loadData();
      }
    }),
    loadData: function loadData() {
      var _this = this;

      this.store.adapterFor("department").tagsCloud(this.typeId).then(function (data) {
        _this.set("data", data);

        _this.set("chartTitle", _this.locale.t("reportings.tags_cloud"));

        _this.set("dataLoaded", true);
      });
    },
    actions: {
      setCloudType: function setCloudType(type) {
        this.set("typeId", type.id);
      },
      openModalWindow: function openModalWindow() {
        this.set("modalIsOpen", true);
        this.loadData();
        this.$(".reveal-modal").foundation("reveal", "open");
      },
      closeModalWindow: function closeModalWindow() {
        this.$(document).foundation("reveal", "close");
      }
    }
  });

  _exports.default = _default;
});