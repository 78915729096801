define("innowaze-ember2/utils/getErrorArray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getErrorArray;

  /**
   * @param {Object} response
   *
   * @return {Object[]}
   */
  function getErrorArray(response) {
    var errors = [];

    if (response.responseJSON) {
      // Format server errors to match the notification service's expectations
      // in: { fieldName: ["error 1", "error 2"] }
      // out: [{ detail: "fieldName error 1" }, { detail: "fieldName error 2" }]
      errors = Object.keys(response.responseJSON.errors).reduce(function (acc, key) {
        return acc.concat(response.responseJSON.errors[key].map(function (error) {
          return {
            detail: key + " " + error
          };
        }));
      }, []);
    }

    return errors;
  }
});