define("innowaze-ember2/routes/events/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      setGroup: function setGroup(group) {
        this.currentModel.set("group.id", group);
      }
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.locale.t("event.show.comment_message_error"), errors);
    }
  });

  _exports.default = _default;
});