define("innowaze-ember2/objects/reference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    backgroundStyle: Ember.computed("color", function () {
      return Ember.String.htmlSafe("background-color: ".concat(this.color, "; border: 1px solid #999;"));
    })
  });

  _exports.default = _default;
});