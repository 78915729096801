define("innowaze-ember2/templates/components/modules/meteo-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n5PFnEWc",
    "block": "{\"symbols\":[\"icon\"],\"statements\":[[4,\"if\",[[23,[\"editMode\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[23,[\"module\",\"title\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"module\",\"control\"]]],null,{\"statements\":[[0,\"      \"],[7,\"sup\"],[12,\"class\",[27,\"if\",[[23,[\"module\",\"isNotCompleted\"]],\"red\",\"green\"],null]],[9],[0,\" *\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[15,\"partials/explanation\",[]],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"icons-list small-block-grid-3 medium-block-grid-6\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"availableMeteoIcons\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[28,[\"card has-tip \",[27,\"if\",[[22,1,[\"selected\"]],\"selected\",\"\"],null]]]],[12,\"title\",[22,1,[\"name\"]]],[3,\"action\",[[22,0,[]],\"selectIcon\",[22,1,[]]]],[9],[0,\"\\n            \"],[7,\"img\"],[12,\"src\",[28,[[27,\"asset-path\",[[27,\"concat\",[\"/assets/images/meteo/\",[22,1,[\"url\"]]],null]],null]]]],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/modules/meteo-module.hbs"
    }
  });

  _exports.default = _default;
});