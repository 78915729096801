define("innowaze-ember2/components/events-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ["events"],
    classNames: ["calendar-wrapper"],
    selectedDate: new Date(),
    d: new Date(),
    currMonth: Ember.computed("d", function () {
      return this.d.getMonth();
    }),
    currMonthName: Ember.computed("currMonth", function () {
      return moment().locale("fr").month(this.currMonth).format("MMMM");
    }),
    currYear: Ember.computed("d", function () {
      return this.d.getFullYear();
    }),
    currDay: Ember.computed("d", function () {
      return this.d.getDate();
    }),
    firstDayOfMonth: Ember.computed("currYear", "currMonth", function () {
      return new Date(this.currYear, this.currMonth, 1).getDay();
    }),
    lastDateOfMonth: Ember.computed("currYear", "currMonth", function () {
      return new Date(this.currYear, this.currMonth + 1, 0).getDate();
    }),
    lastDayOfLastMonth: Ember.computed("currYear", "currMonth", function () {
      var y = this.currYear;
      var m = this.currMonth;

      if (m === 0) {
        return new Date(y - 1, 11, 0).getDate();
      }

      return new Date(y, m, 0).getDate();
    }),
    visibleDays: Ember.computed("currYear", "currMonth", "events", "groups.@each.selected", function () {
      var _this = this;

      var i = 1;
      var trs = [];

      while (i <= this.lastDateOfMonth) {
        var events = void 0;
        var new_tr = false;
        var tds = [];
        var dow = new Date(this.currYear, this.currMonth, i).getDay(); // If Monday, start new row

        if (dow === 1) {
          new_tr = true; // If not Monday but first day of the month
          // it will write the last days from the previous month
        } else if (i === 1) {
          var padding = void 0;
          new_tr = true;

          if (dow === 0) {
            padding = 5;
          } else {
            padding = dow - 2;
          }

          var start = this.lastDayOfLastMonth - padding;

          for (var j = start, end = this.lastDayOfLastMonth, asc = start <= end; asc ? j <= end : j >= end; asc ? j++ : j--) {
            tds.push({
              class: "not-current",
              value: j
            });
          }
        } // Write the current day in the loop


        var chk = new Date();
        var chkY = chk.getFullYear();
        var chkM = chk.getMonth(); // Check if current date being generates has some events associated
        //

        if (this.events) {
          /* eslint-disable no-loop-func */
          events = this.events.filter(function (ev) {
            var selected = true;

            if (ev.get("group.id")) {
              var group = _this.groups.findBy("id", ev.get("group.id"));

              selected = group ? group.selected : true;
            }

            var current_date = new Date(_this.currYear, _this.currMonth, i);
            return selected && (moment(ev.get("starts_at")).isSame(current_date, "day") || moment(ev.get("starts_at")).isBefore(current_date, "day") && moment(ev.get("ends_at")).isSame(current_date, "day") || moment(ev.get("starts_at")).isBefore(current_date, "day") && moment(ev.get("ends_at")).isAfter(current_date, "day"));
          });
          /* eslint-enable no-loop-func */
        }

        var td_class = events && events.length ? " event" : "";

        if (chkY === this.currYear && chkM === this.currMonth && i === this.currDay) {
          tds.push({
            class: "today".concat(td_class),
            value: i,
            events: events
          });
        } else {
          tds.push({
            class: "normal".concat(td_class),
            value: i,
            events: events
          });
        } // If not Sunday, but last day of the selected month
        // it will write the next few days from the next month


        if (dow !== 0 && i === this.lastDateOfMonth) {
          var k = 1;

          for (var cdow = dow, asc1 = dow <= 6; asc1 ? cdow <= 6 : cdow >= 6; asc1 ? cdow++ : cdow--) {
            tds.push({
              class: "not-current".concat(td_class),
              value: k,
              events: events
            });
            k++;
          }
        }

        if (new_tr) {
          trs.push(tds);
        } else {
          [].push.apply(trs[trs.length - 1], tds);
        }

        i++;
      }

      return trs;
    }),
    displayedEvents: Ember.computed("selectedDate", "events", "groups.@each.selected", function () {
      var _this2 = this;

      if (this.events) {
        return this.events.filter(function (event) {
          var selected = true;

          if (event.get("group.id")) {
            var group = _this2.groups.findBy("id", event.get("group.id"));

            selected = group ? group.selected : true;
          }

          return selected && moment(event.get("starts_at")).isSame(_this2.selectedDate, "day");
        });
      }
    }),
    nextDate: Ember.computed("selectedDate", "events", "groups.@each.selected", function () {
      var _this3 = this;

      if (this.events) {
        var event = this.events.find(function (ev) {
          var selected = true;

          if (_this3.groups && ev.get("group")) {
            var group = _this3.groups.findBy("id", ev.get("group.id"));

            selected = group ? group.selected : true;
          }

          return selected && moment(ev.get("starts_at")).isAfter(_this3.selectedDate, "day");
        });
        return event ? event.get("starts_at") : undefined;
      }

      return undefined;
    }),
    nextEvents: Ember.computed("nextDate", "groups.@each.selected", function () {
      var _this4 = this;

      if (this.nextDate && this.events) {
        return this.events.filter(function (event) {
          var selected = true;

          if (_this4.groups && event.get("group")) {
            var group = _this4.groups.findBy("id", event.get("group.id"));

            selected = group ? group.selected : true;
          }

          return selected && moment(event.get("starts_at")).isSame(_this4.nextDate, "day");
        });
      }
    }),
    actions: {
      toggleGroup: function toggleGroup(group) {
        group.toggleProperty("selected");
      },
      unselectAllEvents: function unselectAllEvents() {
        this.groups.setEach("selected", false);
      },
      toggleDisplayChildren: function toggleDisplayChildren() {
        this.toggleProperty("displayChildren");
      },
      nextMonth: function nextMonth() {
        if (this.currMonth === 11) {
          this.set("d", new Date(this.currYear + 1, 0, this.currDay));
        } else {
          this.set("d", new Date(this.currYear, this.currMonth + 1, this.currDay));
        }
      },
      previousMonth: function previousMonth() {
        if (this.currMonth === 0) {
          this.set("d", new Date(this.currYear - 1, 11, this.currDay));
        } else {
          this.set("d", new Date(this.currYear, this.currMonth - 1, this.currDay));
        }
      },
      displayEvents: function displayEvents(events) {
        if (events && events.length) {
          this.set("selectedDate", moment(events[0].get("starts_at")).toDate());
        }
      }
    }
  });

  _exports.default = _default;
});