define("innowaze-ember2/templates/components/modules/contributors-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "10zxIwPK",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"if\",[[23,[\"editMode\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"contributors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"ideas.contributors\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"teammate small-block-grid-3 medium-block-grid-6 large-block-grid-9\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"contributors\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"picture\"],[9],[7,\"img\"],[12,\"src\",[22,1,[\"avatar\"]]],[9],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"idea-author\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"users.show\",[22,1,[\"id\"]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[0,\"                \"],[1,[22,1,[\"screenname\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"              \"],[4,\"if\",[[23,[\"canAddTeam\"]]],null,{\"statements\":[[7,\"a\"],[3,\"action\",[[22,0,[]],\"addTeam\",[22,1,[]]]],[9],[7,\"i\"],[11,\"class\",\"fa fa-plus-circle\"],[9],[10],[10]],\"parameters\":[]},null],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/modules/contributors-module.hbs"
    }
  });

  _exports.default = _default;
});