define("innowaze-ember2/templates/concepts/modules/-pictures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ItXJYwfR",
    "block": "{\"symbols\":[\"picture\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"concept\",\"pictures\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[23,[\"module\",\"title\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"no-bullet\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"concept\",\"pictures\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"debates-image\"],[9],[0,\"\\n          \"],[1,[27,\"linked-debate-container\",null,[[\"image\",\"badges\",\"canEdit\",\"addLinkedDebateBadge\",\"deleteDroppable\",\"displayDebateForBadge\",\"deleteBadge\",\"src\"],[[22,1,[]],[22,1,[\"badges\"]],[23,[\"canEditConcept\"]],\"addLinkedDebateBadge\",\"deleteDroppable\",\"displayDebateForBadge\",\"deleteBadge\",[23,[\"session\",\"configuration\",\"image_paths\",\"draggable-debate\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/modules/-pictures.hbs"
    }
  });

  _exports.default = _default;
});