define("innowaze-ember2/controllers/challenges/roles", ["exports", "innowaze-ember2/mixins/controllers/userTags"], function (_exports, _userTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_userTags.default, {
    totalResults: 0,
    page: 1,
    matchingUsers: [],
    selectedUsers: Ember.computed.filterBy("matchingUsers", "selected", true),
    selectedCount: Ember.computed.alias("selectedUsers.length"),
    tags: [],
    challenges: [],
    departments: [],
    selectedRoles: [],
    roles: Ember.computed.mapBy("members", "roles"),
    roleCount: function roleCount(pred) {
      return this.roles.filter(pred).length;
    },
    adminsCount: Ember.computed("members.[]", "members.@each.roles", function () {
      return this.roleCount(function (roles) {
        return roles.includes("admin");
      });
    }),
    moderatorsCount: Ember.computed("members.[]", "members.@each.roles", function () {
      return this.roleCount(function (roles) {
        return roles.includes("moderator");
      });
    }),
    reviewersCount: Ember.computed("members.[]", "members.@each.roles", function () {
      return this.roleCount(function (roles) {
        return roles.includes("reviewer");
      });
    }),
    designersCount: Ember.computed("members.[]", "members.@each.roles", function () {
      return this.roleCount(function (roles) {
        return roles.includes("designer");
      });
    }),
    expertsCount: Ember.computed("members.[]", "members.@each.roles", function () {
      return this.roleCount(function (roles) {
        return roles.includes("expert");
      });
    }),
    championsCount: Ember.computed("members.[]", "members.@each.roles", function () {
      return this.roleCount(function (roles) {
        return roles.includes("champion");
      });
    }),
    withoutRoleCount: Ember.computed("members.[]", "members.@each.roles", function () {
      return this.roleCount(Ember.isEmpty);
    })
  });

  _exports.default = _default;
});