define("innowaze-ember2/models/badge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    challenge_id: _emberData.default.attr(),
    challenge_name: _emberData.default.attr("string"),
    logo_url: _emberData.default.attr("string"),
    logo_id: _emberData.default.attr(),
    created_at: _emberData.default.attr()
  });

  _exports.default = _default;
});