define("innowaze-ember2/utils/escapeForES", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = escapeForES;

  function escapeForES(query) {
    return query.replace(/([!*+&|()[\]{}^~?:"/])/g, "\\$1");
  }
});