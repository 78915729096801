define("innowaze-ember2/helpers/module-tag", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.moduleTag = moduleTag;
  _exports.default = void 0;

  function moduleTag(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref2[0];

    return "module-".concat(value);
  }

  var _default = Ember.Helper.helper(moduleTag);

  _exports.default = _default;
});