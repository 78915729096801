define("innowaze-ember2/routes/pathways/show", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("pathway", params.pathway_id, {
        adapterOptions: {
          challenge_id: params.challenge_id
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (this.get("session.currentChallenge").get("deepl_translation")) {
        var lang = this.get("session.currentChallenge").get("deepl_languages").findBy("id", this.get("locale.currentLocale"));

        if (lang && this.get("session.currentChallenge").get("translation_coupling")) {
          controller.set("targetLanguage", lang);
          Ember.run.next(this, function () {
            this.send("translate", lang.id);
          });
        } else {
          controller.set("targetLanguage", {
            name: "None",
            id: "-"
          });
        }

        controller.setProperties({
          availableTranslationLanguages: this.get("session.currentChallenge").get("deepl_languages"),
          translatedName: null,
          translatedDescription: null,
          translatedExplorationField: null,
          translatedAnalysis: null,
          translatedComments: null,
          translatedDebates: null
        });
      }

      controller.reopen({
        no_knowledge: Ember.computed.none("model.knowledge"),
        canConvertToKnowledge: Ember.computed.and("no_knowledge", "session.currentUser.admin"),
        sortedDebates: Ember.computed("model.debates.[]", function () {
          return this.model.debates.sortBy("position");
        })
      });
    },
    actions: {
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.later("afterRender", function () {
          if (_this.get("controller.panel")) {
            $(document).foundation();
            $("#idea-tabs-" + _this.get("controller.panel")).trigger("click");
          }
        });
      },
      commentPathway: function commentPathway() {
        $("#pathway-tabs-comments").trigger("click");
        this.set("controller.pushCommentButton", true);
      },
      convertToKnowledge: function convertToKnowledge() {
        var _this2 = this;

        this.currentModel.convertToKnowledge().then(function (knowledge) {
          return _this2._successConvert(knowledge);
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      translate: function translate(lang) {
        var _this3 = this;

        var language = this.controller.availableTranslationLanguages.findBy("id", lang);
        this.controller.set("targetLanguage", language);
        this.currentModel.translate(lang).then(function (result) {
          if (result && result.status) {
            _this3.controller.setProperties({
              translatedName: result.name,
              translatedDescription: result.description,
              translatedExplorationField: result.exploration_field,
              translatedAnalysis: result.analysis,
              language: result.language,
              translatedComments: result.comments,
              translatedDebates: result.debates
            });

            if (_this3.currentModel.debates) {
              _this3.currentModel.debates.forEach(function (debate, index) {
                return debate.set("translatedQuestion", _this3.controller.translatedDebates[index].question);
              });
            }
          } else {
            _this3.controller.setProperties({
              translatedName: null,
              translatedDescription: null,
              translatedExplorationField: null,
              translatedAnalysis: null,
              language: null,
              translatedComments: null,
              translatedDebates: null
            });
          }
        });
      },
      delete: function _delete(pathway) {
        var _this4 = this;

        if (!confirm(this.locale.t("pathways.index.deletion_confirmation"))) {
          return;
        }

        pathway.destroyRecord({
          adapterOptions: {
            challenge_id: pathway.get("challenge_id")
          }
        }).then(function () {
          _this4.transitionTo("pathways", pathway.get("challenge_id"));

          _this4.notifications.success(_this4.locale.t("pathways.index.deleted"));
        });
      }
    },
    _successConvert: function _successConvert(knowledge) {
      this.currentModel.set("knowledge", knowledge);
      this.notifications.success(this.locale.t("ideas.updated_idea"));
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("error"), errors);
    }
  });

  _exports.default = _default;
});