define("innowaze-ember2/controllers/challenges/edit-ideas-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      // When a state has changed, apply this state to all evaluation tools of same category
      onSelectEvaluationState: function onSelectEvaluationState(tool, state) {
        this.get("model.tools").filterBy("category", tool.category).setEach("evaluation_state", state);
      }
    },
    defaultBadge: Ember.observer("model.display_default_list", function () {
      this.set("model.display_default_badge", !this.get("model.display_default_list"));
    }),
    defaultList: Ember.observer("model.display_default_badge", function () {
      this.set("model.display_default_list", !this.get("model.display_default_badge"));
    }),
    toolsHaveChanged: Ember.observer("model.tools.[]", "model.tools.@each.{active,computed,category,computed_category}", function () {
      var _this = this;

      var tools = this.get("model.tools");

      if (!tools) {
        return;
      }

      this.set("availableToolCategories", tools.filterBy("active", true).mapBy("category").uniq());
      this.set("usedToolCategories", tools.filterBy("computed", true).mapBy("computed_category").uniq());
      this.set("disabledToolCategories", tools.filterBy("computed", true).mapBy("category").uniq());
      this.set("enabledToolCategories", this.availableToolCategories.filter(function (item) {
        return !_this.disabledToolCategories.includes(item);
      }));
      tools.forEach(function (tool) {
        if (_this.usedToolCategories.includes(tool.category) || !_this.enabledToolCategories.without(tool.category).length) {
          tool.set("computedDisable", true);
          tool.set("computed_category", null);
        } else {
          tool.set("computedDisable", false);
        }

        tool.set("categoryDisable", _this.usedToolCategories.includes(tool.category));
      });
    }),
    challengeSynchronizationDisabled: Ember.observer("model.challenges_synchronization", function () {
      if (!this.get("model.challenges_synchronization")) {
        this.set("selectedChallenge", null);
      }
    }),
    pediluveDisabled: Ember.observer("pediluve", function () {
      if (!this.pediluve) {
        this.set("fullbathChallenge", null);
      }
    }),
    ideasModuleHaschanged: Ember.observer("model.ideas.@each.active", function () {
      if (this.get("model.ideas").findBy("name", "domains").get("active")) {
        this.set("showDomainFilterOption", true);
      } else {
        this.set("model.display_domain_filter", false);
      }
    }),
    displayMap: Ember.computed("model.ideas.@each.active", function () {
      return this.get("model.ideas").findBy("name", "address").active;
    }),
    mapSettings: Ember.computed.alias("model.mapSettings")
  });

  _exports.default = _default;
});