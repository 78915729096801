define("innowaze-ember2/components/challenge-milestones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentMilestones: Ember.computed("milestones.[]", "milestones.@each._destroy", function () {
      return this.milestones.filter(function (milestone) {
        return milestone._destroy !== 1;
      }).sortBy("position");
    }),
    actions: {
      addMilestone: function addMilestone() {
        var milestones = this.currentMilestones;
        var starts_on = null;

        if (milestones.length && milestones.get("lastObject").ends_on) {
          starts_on = moment(milestones.get("lastObject").ends_on).format("YYYY-MM-DD");
        }

        var milestone = Ember.Object.create({
          localId: milestones.filter(function (m) {
            return m.localId;
          }).length + 1,
          name: "",
          starts_on: starts_on,
          ends_on: null,
          position: milestones.length
        });
        this.milestones.pushObject(milestone);
      },
      removeMilestone: function removeMilestone(milestone) {
        if (milestone.id) {
          Ember.set(this.milestones.findBy("id", milestone.id), "_destroy", 1);
        } else {
          this.milestones.removeObject(milestone);
        }
      },
      updateMilestonePositions: function updateMilestonePositions() {
        this.sendAction("updateMilestonePositions", this.currentMilestones);
      },
      setStartDate: function setStartDate(milestone, date) {
        if (milestone.id) {
          Ember.set(this.milestones.findBy("id", milestone.id), "starts_on", date);
        } else {
          Ember.set(this.milestones.findBy("localId", milestone.localId), "starts_on", date);
        }
      },
      setEndDate: function setEndDate(milestone, date) {
        if (milestone.id) {
          Ember.set(this.milestones.findBy("id", milestone.id), "ends_on", date);
        } else {
          Ember.set(this.milestones.findBy("localId", milestone.localId), "ends_on", date);
        }
      }
    }
  });

  _exports.default = _default;
});