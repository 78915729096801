define("innowaze-ember2/components/cooccurrences-network", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: "answers-network",
    methods: [{
      id: "hashtag",
      name: "Hashtags"
    }, {
      id: "answer",
      name: "Answers"
    }],
    radius: 40,
    spacing: 60,
    charge: 30,
    gravity: 0.1,
    selectedMethod: "hashtag",
    mainCooccurences: Ember.computed("links", function () {
      var _this = this;

      if (this.links) {
        var mainLinks = this.links.sortBy("weight").reverse().slice(0, 5);
        mainLinks.forEach(function (link) {
          link.sourceName = _this.nodes.findBy("id", link.source).name;
          link.targetName = _this.nodes.findBy("id", link.target).name;
        });
        return mainLinks;
      }
    }),
    methodChanged: Ember.observer("selectedMethod", function () {
      if (this.modalIsOpen) {
        this.set("dataLoaded", false);
        this.loadData();
      }
    }),
    draw: Ember.observer("nodes", "radius", "spacing", function () {
      d3.select("#network").selectAll("svg").remove();

      if (this.nodes) {
        this.drawChart();
      }
    }),
    didInsertElement: function didInsertElement() {
      this.drawChart();
    },
    loadData: function loadData() {
      var _this2 = this;

      function cluster(data) {
        // detect communities with jsLouvain
        var nodeData = data.nodes.map(function (d) {
          return d.id;
        });
        var linkData = data.links.map(function (d) {
          return {
            source: d.source,
            target: d.target,
            weight: d.weight
          };
        });
        var community = jLouvain().nodes(nodeData).edges(linkData);
        var result = community();
        var defaultRadius = 8;
        data.nodes.forEach(function (node) {
          node.r = defaultRadius;
          node.cluster = result[node.id];
        });
        return data.nodes;
      }

      if (this.selectedMethod === "answer") {
        this.model.answersNetwork().then(function (data) {
          _this2.setProperties({
            nodes: cluster(data),
            links: data.links,
            dataLoaded: true
          });
        });
      }

      if (this.selectedMethod === "hashtag") {
        this.model.wordsCooccurrences().then(function (data) {
          _this2.setProperties({
            nodes: cluster(data),
            links: data.links,
            dataLoaded: true
          });
        });
      }
    },
    drawChart: function drawChart() {
      if (!this.nodes) {
        return;
      }

      var height = 500;
      var width = $("#network").width();
      var svg = d3.select("#network").append("svg").attr("width", width).attr("height", height);
      var nodes = this.nodes;
      var maxWeight = Math.max.apply(Math, (0, _toConsumableArray2.default)(this.links.mapBy("weight")));
      var maxScore = Math.max.apply(Math, (0, _toConsumableArray2.default)(nodes.mapBy("score")));
      var clusters = (0, _toConsumableArray2.default)(new Set(nodes.map(function (item) {
        return item.cluster;
      })));
      var edges = this.links.map(function (link) {
        return {
          source: nodes.filterBy("id", link.source)[0],
          target: nodes.filterBy("id", link.target)[0],
          weight: link.weight
        };
      });
      var dScale = d3.scale.linear().domain([0, maxWeight]).range([150, 1.5 * this.spacing]);
      var circleScale = d3.scale.linear().domain([0, maxScore]).range([5, this.radius]);
      var z = d3.scale.category10();
      var force = d3.layout.force().nodes(nodes).links(edges).size([width, 600]).distance(100).linkDistance(function (l) {
        return dScale(l.weight);
      }).charge(-this.charge).chargeDistance(function (l) {
        return dScale(l.weight);
      }).gravity(this.gravity);
      var link = svg.selectAll(".link").data(edges).enter().append("line").attr("class", "link").style("stroke", "#b7bcbd").style("stroke-width", function (d) {
        return Math.max(d.weight > 10 ? 10 : d.weight);
      });
      var node = svg.selectAll(".node").data(nodes).enter().append("g").attr("class", "node").call(force.drag);
      node.append("circle").style("stroke", "#fff").style("fill", function (d) {
        return z(d.cluster);
      }).attr("r", function (d) {
        return circleScale(d.score);
      });
      node.append("text").attr("dx", 12).attr("dy", ".35em").text(function (d) {
        return d.name + " (" + d.score + ")";
      }).style("font-size", "10px");
      force.start().on("tick", tick);

      function tick(e) {
        // Push different nodes in different directions for clustering.
        var k = clusters.map(function (x) {
          return (10 + x) * e.alpha;
        });
        nodes.forEach(function (o, i) {
          o.x += o.cluster & 2 ? k[o.cluster] : -k[o.cluster];
          o.y += o.cluster & 1 ? k[o.cluster] : -k[o.cluster];
        });
        link.attr("x1", function (d) {
          return d.source.x;
        }).attr("y1", function (d) {
          return d.source.y;
        }).attr("x2", function (d) {
          return d.target.x;
        }).attr("y2", function (d) {
          return d.target.y;
        });
        node.attr("transform", function (d) {
          return "translate(" + d.x + "," + d.y + ")";
        });
      }
    },
    actions: {
      openModalWindow: function openModalWindow() {
        this.set("dataLoaded", false);
        this.set("modalIsOpen", true);
        this.loadData();
      },
      closeModalWindow: function closeModalWindow() {
        this.set("modalIsOpen", false);
      }
    }
  });

  _exports.default = _default;
});