define("innowaze-ember2/templates/components/modules/replicators-module-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P1lhyt0B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"wantReplicate\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"page-module \",[27,\"if\",[[23,[\"replicating\"]],\"active\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"idea-primary-action contribute-idea-button\"],[3,\"action\",[[22,0,[]],\"toggleReplicate\",[23,[\"session\",\"currentUser\"]]]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-bell-o\"],[9],[10],[0,\"\\n      \"],[7,\"span\"],[9],[1,[27,\"i18n\",[\"ideas.show.replicate\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/modules/replicators-module-button.hbs"
    }
  });

  _exports.default = _default;
});