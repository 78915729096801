define("innowaze-ember2/adapters/comment", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    rate: function rate(id, comment, way) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/comments/").concat(id, "/rate.json"), "POST", {
        data: {
          way: way
        }
      });
    }
  });

  _exports.default = _default;
});