define("innowaze-ember2/instance-initializers/masonry-config", ["exports", "innowaze-ember2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var config = _environment.default['ember-masonry-grid'] || {};
    appInstance.register('config:masonry-config', config, {
      instantiate: false
    });
    appInstance.inject('service:masonry-config', 'config', 'config:masonry-config');
  }

  var _default = {
    name: 'masonry-config',
    initialize: initialize
  };
  _exports.default = _default;
});