define("innowaze-ember2/templates/concepts/modules/-knowledges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NC0778VS",
    "block": "{\"symbols\":[\"knowledge\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"concept\",\"knowledges\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"challenges.knowledges.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"associated-knowledges no-bullet\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"concept\",\"knowledges\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"fa fa-file\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"knowledges.show\",[22,1,[\"id\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/modules/-knowledges.hbs"
    }
  });

  _exports.default = _default;
});