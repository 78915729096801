define("innowaze-ember2/routes/email-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.Object.create({
        email: null
      });
    },
    redirect: function redirect() {
      if (this.session.get("loggedIn")) {
        this.router.transitionTo("challenges");
      }
    },
    actions: {
      resend: function resend() {
        var _this = this;

        this.store.adapterFor("user").confirmation({
          user: {
            email: this.currentModel.email
          }
        }, "POST").then(function () {
          _this.notifications.success(_this.locale.t("devise.confirmations.send_paranoid_instructions"), {
            autoClear: false
          });

          _this.router.transitionTo("login");
        }, function (_ref) {
          var errors = _ref.errors;

          _this.notifications.errorWithList(_this.locale.t("creation_error"), errors);
        });
      }
    }
  });

  _exports.default = _default;
});