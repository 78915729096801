define("innowaze-ember2/templates/challenges/modules/replications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hVEwzh+c",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"replication-counters\",null,[[\"model\",\"style\",\"background\",\"color\",\"savings\",\"footnote\"],[[23,[\"model\"]],[23,[\"model\",\"replication_counters\",\"params\",\"style\"]],[23,[\"model\",\"replication_counters\",\"params\",\"background\"]],[23,[\"model\",\"replication_counters\",\"params\",\"color\"]],[23,[\"model\",\"replication_counters\",\"params\",\"savings\"]],[23,[\"model\",\"replication_counters\",\"params\",\"footnote\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/replications.hbs"
    }
  });

  _exports.default = _default;
});