define("innowaze-ember2/routes/reportings", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _objectSpread2, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var domainsHistogram = 1;
  var departmentsHistogram = 2;
  var sitesHistogram = 3;
  var stateFunnel = 4;
  var personaHistogram = 5;
  var wordsCloud = 6;
  var likedIdeas = 7;
  var blScopesHistogram = 8;
  var sitesScopesHistogram = 9;
  var ownerDepartmentsHistogram = 10;
  var siteTreemap = 11;
  var yearHistogram = 12;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    locale: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var ideasName = this.currentModel.nameFor("ideas", "plural_idea");
      controller.set("invisible", false);
      controller.set("reports", [{
        id: domainsHistogram,
        title: this.locale.t("header.menu.themes")
      }, {
        id: departmentsHistogram,
        title: this.locale.t("header.menu.departments")
      }, {
        id: sitesHistogram,
        title: this.locale.t("ideas.sites")
      }, {
        id: stateFunnel,
        title: this.locale.t("ideas.states")
      }, {
        id: personaHistogram,
        title: this.locale.t("header.menu.personas")
      }, {
        id: wordsCloud,
        title: this.locale.t("header.menu.tags")
      }, {
        id: yearHistogram,
        title: Ember.String.capitalize(this.locale.t("reportings.years", {
          ideas: ideasName
        }))
      }]); // display most liked ideas only if hide_votes_ideas option is not set for the challenge

      if (!this.currentModel.get("hide_votes_ideas")) {
        controller.reports.pushObject({
          id: likedIdeas,
          title: Ember.String.capitalize(this.locale.t("reportings.liked_ideas", {
            ideas: ideasName
          }))
        });
      }

      var activeModules = model.get("ideas").filterBy("active", true);

      if ((!!activeModules.findBy("name", "savings") || !!activeModules.findBy("name", "benefits")) && this.get("session.currentUser.is_super_admin")) {
        controller.reports.pushObject({
          id: siteTreemap,
          title: this.locale.t("reportings.treemap")
        });
      }

      controller.set("selectedReport", controller.reports.get("firstObject"));
      var scope = activeModules.findBy("name", "scope");
      var ideaDepartment = activeModules.findBy("name", "idea_department");
      controller.set("isReplication", !!activeModules.findBy("name", "knowledges"));

      if (scope) {
        controller.set("availableHubs", [{
          id: 1,
          title: "All"
        }, {
          id: 2,
          title: "Hub E&C"
        }, {
          id: 3,
          title: "Hub EUI"
        }, {
          id: 4,
          title: "Hub IDD"
        }, {
          id: 5,
          title: "Hub EHO"
        }, {
          id: 6,
          title: "Hub AME"
        }, {
          id: 7,
          title: "Hub IDD"
        }, {
          id: 8,
          title: "Hub ORSAY"
        }, {
          id: 9,
          title: "Hub APAC"
        }, {
          id: 10,
          title: "Hub AMEI"
        }]);
        controller.set("selectedHub", controller.availableHubs.get("firstObject"));
        controller.reports.addObjects([{
          id: blScopesHistogram,
          title: "Business Lines"
        }, {
          id: sitesScopesHistogram,
          title: this.locale.t("reportings.countries")
        }]);
      }

      if (ideaDepartment) {
        controller.reports.addObject({
          id: ownerDepartmentsHistogram,
          title: this.locale.t("reportings.owner_departments", {
            ideas: ideasName
          })
        });
      }

      controller.set("isHub", !!scope);
      this.set("dataLoaded", false);

      this._loadData(controller.selectedReport.id, false, "All", controller.isReplication);

      var route = this;
      controller.reopen({
        isCloud: Ember.computed.equal("selectedReport.id", wordsCloud),
        isTreemap: Ember.computed.equal("selectedReport.id", siteTreemap),
        isFunnel: Ember.computed.equal("selectedReport.id", stateFunnel),
        isList: Ember.computed.equal("selectedReport.id", likedIdeas),
        isNotHistogram: Ember.computed.or("isCloud", "isTreemap", "isFunnel", "isList"),
        isHistogram: Ember.computed.not("isNotHistogram"),
        dataEmpty: Ember.computed.empty("data"),
        reportDidChange: Ember.observer("selectedReport.id", "selectedHub.id", "invisible", "isReplication", function () {
          this.set("dataLoaded", false);
          var replication = this.isReplication;
          var invisible = this.invisible;
          var dataset_id = this.selectedReport ? this.selectedReport.id : null;
          var hub = this.selectedHub ? this.selectedHub.title : "All";

          if (!dataset_id || !hub) {
            return false;
          }

          if (this.selectedReport) {
            Ember.run.next(route, function () {
              this._loadData(dataset_id, invisible, hub, replication);
            });
          }
        })
      });
    },
    actions: {
      loadData: function loadData(dataset_id) {
        this._loadData(dataset_id);
      }
    },
    _loadData: function _loadData(dataset_id, invisible, hub, replication) {
      var _this = this;

      var challengeId = this.currentModel.id;
      var ideasName = this.currentModel.nameFor("ideas", "plural_idea");
      var params = {
        invisible: invisible,
        hub: hub,
        replication: replication
      };

      switch (dataset_id) {
        case domainsHistogram:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "domain"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: replication,
              data: data,
              chartTitle: _this.locale.t("reportings.domains", {
                ideas: ideasName
              }),
              dataLoaded: true
            });
          });
          break;

        case departmentsHistogram:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "department"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: replication,
              data: data,
              chartTitle: _this.locale.t("reportings.departments", {
                ideas: ideasName
              }),
              dataLoaded: true
            });
          });
          break;

        case yearHistogram:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "year"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: replication,
              data: data,
              chartTitle: _this.locale.t("reportings.years", {
                ideas: ideasName
              }),
              dataLoaded: true
            });
          });
          break;

        case ownerDepartmentsHistogram:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "owner_department"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: replication,
              data: data,
              chartTitle: _this.locale.t("reportings.owner_departments", {
                ideas: ideasName
              }),
              dataLoaded: true
            });
          });
          break;

        case sitesHistogram:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "site"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: replication,
              data: data,
              chartTitle: _this.locale.t("reportings.sites", {
                ideas: ideasName
              }),
              dataLoaded: true
            });
          });
          break;

        case stateFunnel:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "state"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: false,
              funnel_data: data,
              chartTitle: _this.locale.t("challenges.ideas_per_state.title", {
                ideas: ideasName
              })
            });
          });
          break;

        case personaHistogram:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "persona"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: replication,
              data: data,
              chartTitle: _this.locale.t("reportings.personas", {
                ideas: ideasName
              }),
              dataLoaded: true
            });
          });
          break;

        case wordsCloud:
          this.store.adapterFor("challenge").tagsCloud(challengeId, invisible, hub, replication).then(function (data) {
            _this.controller.setProperties({
              hasDataset: false,
              data: data.reportings,
              chartTitle: _this.locale.t("reportings.tags_cloud"),
              dataLoaded: true
            });
          });
          break;

        case siteTreemap:
          this.controller.set("treemap_data", null);
          this.store.adapterFor("challenge").treemapIdeas(challengeId, invisible, hub, replication).then(function (data) {
            _this.controller.setProperties({
              hasDataset: false,
              treemap_data: data.reportings,
              chartTitle: _this.locale.t("reportings.treemap"),
              dataLoaded: true
            });
          });
          break;

        case likedIdeas:
          this.store.adapterFor("challenge").likedIdeas(challengeId, invisible, hub, replication).then(function (data) {
            _this.controller.setProperties({
              hasDataset: false,
              data: data.liked_ideas,
              chartTitle: _this.locale.t("reportings.liked_ideas", {
                ideas: ideasName
              })
            });
          });
          break;

        case blScopesHistogram:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "bl_scope"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: replication,
              data: data.reportings,
              chartTitle: _this.locale.t("reportings.bl_scope", {
                ideas: ideasName
              }),
              dataLoaded: true
            });
          });
          break;

        case sitesScopesHistogram:
          this.store.adapterFor("challenge").reportingIdeasPer(challengeId, (0, _objectSpread2.default)({}, params, {
            type: "country_scope"
          })).then(function (data) {
            _this.controller.setProperties({
              hasDataset: replication,
              data: data.reportings,
              chartTitle: _this.locale.t("reportings.country_scope", {
                ideas: ideasName
              }),
              dataLoaded: true
            });
          });
          break;
      }
    }
  });

  _exports.default = _default;
});