define("innowaze-ember2/components/select-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: "select-category",
    customArray: Ember.computed("categories.[]", function () {
      return this.categories.without(this.module.category);
    })
  });

  _exports.default = _default;
});