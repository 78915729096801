define("innowaze-ember2/controllers/challenges/edit-common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    departments: Ember.computed("session.currentUser.departments", function () {
      var currentUser = this.get("session.currentUser");

      if (currentUser.get("is_admin")) {
        return currentUser.get("managed_departments");
      }

      if (currentUser) {
        var departments = currentUser.get("departments").filterBy("admin", true).filterBy("customer", true);

        if (departments.length === 1) {
          this.set("model.department", departments[0]);
        }

        return departments;
      }

      return [];
    }),
    showDepartmentModule: Ember.computed("departments", "session.currentUser.is_admin", function () {
      return this.get("departments.length") > 1 || this.get("session.currentUser.is_admin");
    })
  });

  _exports.default = _default;
});