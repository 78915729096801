define("innowaze-ember2/templates/concepts/modules/-main-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VD9751Dl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"concepts.main\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module main-info\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"idea-description\"],[9],[0,\"\\n    \"],[1,[23,[\"model\",\"concept\",\"description\"]],true],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/modules/-main-info.hbs"
    }
  });

  _exports.default = _default;
});