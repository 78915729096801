define("innowaze-ember2/models/tag-trigger", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    tag: _emberData.default.attr("string"),
    keywords: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });

  _exports.default = _default;
});