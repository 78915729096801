define("innowaze-ember2/routes/news-department/news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: "newsDepartment/news",
    model: function model(_ref) {
      var department_id = _ref.department_id,
          news_id = _ref.news_id;
      return this.store.query("news", {
        department_id: department_id
      });
    }
  });

  _exports.default = _default;
});