define("innowaze-ember2/components/days-until", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    daysLeft: Ember.computed(function () {
      var endDate = moment(this.date).add(1, "days").utc().endOf("day");
      var today = moment().utc().startOf("day");
      var daysLeft = endDate.diff(today, "days");

      if (daysLeft < 0) {
        daysLeft = 0;
      }

      return daysLeft;
    }),
    daysLeftString: Ember.computed("daysLeft", function () {
      if (this.daysLeft > 1) {
        return this.daysLeft + " " + this.locale.t("challenges.index.days_remaining");
      }

      return this.daysLeft + " " + this.locale.t("challenges.index.day_remaining");
    })
  });

  _exports.default = _default;
});