define("innowaze-ember2/components/concept-canevas-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "td",
    classNameBindings: ["header_only"],
    attributeBindings: ["rowspan", "colspan", "style"],
    actions: {
      editLabel: function editLabel() {
        this.sendAction.apply(this, ["editLabel"].concat(Array.prototype.slice.call(arguments)));
      },
      removeLabel: function removeLabel() {
        this.sendAction.apply(this, ["removeLabel"].concat(Array.prototype.slice.call(arguments)));
      },
      selectForValueEdition: function selectForValueEdition() {
        this.sendAction.apply(this, ["selectForValueEdition"].concat(Array.prototype.slice.call(arguments)));
      }
    },
    dragEnter: function dragEnter(event) {
      if (this._linkedDebate(event)) {
        return;
      }

      if (!this.header_only) {
        event.preventDefault();
      }
    },
    dragOver: function dragOver(event) {
      if (!this.header_only) {
        event.preventDefault();

        if (!this._linkedDebate(event)) {
          event.stopPropagation();
          event.currentTarget.classList.add("hover");
        }
      }
    },
    dragLeave: function dragLeave(event) {
      if (!this.header_only) {
        event.preventDefault();

        if (!this._linkedDebate(event)) {
          event.stopPropagation();
          event.currentTarget.classList.remove("hover");
        }
      }
    },
    drop: function drop(event) {
      if (this._linkedDebate(event)) {
        return;
      }

      if (!this.header_only) {
        event.currentTarget.classList.remove("hover");
        var labelId = event.originalEvent.dataTransfer.getData("LabelId");
        this.sendAction("labelDroppedToCell", labelId, this.cell);
      }

      event.stopPropagation();
      event.preventDefault();
    },
    _linkedDebate: function _linkedDebate(event) {
      var el = event.originalEvent.dataTransfer.types;
      return el.includes("linkeddebate") || el.includes("linkedDebate");
    },
    style: Ember.computed("cell.border", function () {
      var border = this.get("cell.border") ? "border: ".concat(this.get("cell.border"), ";") : "";
      var background = this.get("cell.background") ? "background: ".concat(this.get("cell.background")) : "";
      var color = this.get("cell.color") ? "color: ".concat(this.get("cell.color")) : "";
      return Ember.String.htmlSafe([border, background, color].join(" "));
    }),
    rowspan: Ember.computed.alias("cell.rowspan"),
    colspan: Ember.computed.alias("cell.colspan"),
    header_only: Ember.computed.alias("cell.header_only"),
    content_only: Ember.computed.alias("cell.content_only")
  });

  _exports.default = _default;
});