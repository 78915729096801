define("innowaze-ember2/controllers/concepts/blueocean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    canEditConcept: Ember.computed("session.isModerator", "model.concept.user_id", "model.concept.teammates", function () {
      var currentUserId = this.get("session.currentUser.id");
      return this.get("session.isModerator") || this.get("model.concept.user_id") === currentUserId || this.get("model.concept.teammates").find(function (user) {
        return user.id === currentUserId;
      }) !== undefined;
    }),
    mainstreamReferenceDidChange: Ember.observer("mainstreamReference", function () {
      if (this.get("data.references")) {
        this.get("data.references").forEach(function (v) {
          return Ember.set(v, "mainstream", false);
        });
      }

      this.set("mainstreamReference.mainstream", true);
    }),
    toggleAnalysis: Ember.observer("blueoceanPublished", function () {
      this.get("model.concept").toggleAnalysis("blueocean", this.blueoceanPublished.toString());
    }),
    hasVersion: Ember.computed("data.references.@each.kind", function () {
      return this.get("data.references").any(function (item) {
        return item.get("kind") === "version";
      });
    }),
    mainstreableReferences: Ember.computed.filterBy("data.references", "kind", "reference"),
    availableReferenceKinds: [{
      id: "version",
      name: "Notre concept"
    }, {
      id: "reference",
      name: "Référence du marché"
    }]
  });

  _exports.default = _default;
});