define("innowaze-ember2/components/replication-counters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    tagName: "div",
    classNames: "replication",
    footnote: false,
    savings: true,
    widgetStyle: Ember.computed("color", "background", "style", function () {
      if (this.style) {
        return Ember.String.htmlSafe(this.style);
      }

      return Ember.String.htmlSafe("background-color: ".concat(this.background, "; color: ").concat(this.color));
    }),
    textStyle: Ember.computed("color", function () {
      if (this.color) {
        return Ember.String.htmlSafe("color: ".concat(this.color));
      }
    }),
    estimatedSavings: Ember.computed("model.replication_counters.estimated_savings", "locale", function () {
      return Math.round(this.model.replication_counters.estimated_savings).toLocaleString(this.locale.currentLocale);
    })
  });

  _exports.default = _default;
});