define("innowaze-ember2/templates/challenges/modules/-ideas-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/VmlA7mq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"counter\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"challenges.ideas_count.title\"],[[\"count\",\"ideas\"],[[23,[\"model\",\"ideas_count\"]],[27,\"name-for\",[[23,[\"model\"]],\"ideas\",\"plural_idea\"],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-ideas-count.hbs"
    }
  });

  _exports.default = _default;
});