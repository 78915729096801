define("innowaze-ember2/mixins/models/commentHandler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    addFile: function addFile(_ref) {
      var _this = this;

      var id = _ref.id,
          url = _ref.url,
          name = _ref.name;
      var document = Ember.Object.create({
        id: id,
        url: url,
        name: name
      });
      return this._callAdapter("addFile", document.get("id")).then(function () {
        _this.documents.pushObject(document);
      });
    },
    addComment: function addComment(commentData) {
      var _this2 = this;

      var comment = this.store.createRecord("comment", commentData);
      return comment.save().then(function (comment) {
        _this2.comments.pushObject(comment);
      });
    },
    deleteComment: function deleteComment(comment) {
      var _this3 = this;

      return comment.destroyRecord().then(function () {
        _this3.comments.removeObject(comment);
      });
    }
  });

  _exports.default = _default;
});