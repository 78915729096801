define("innowaze-ember2/components/file-reader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    files: [],
    didInsertElement: function didInsertElement() {
      var component = this;
      this.$("input[type='file']").fileupload({
        autoUpload: false,
        dropZone: this.$(),
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        drop: function drop(e, data) {
          component.send("change", e, data.files);
        },
        change: function change(e, data) {
          component.send("change", e, data.files);
        }
      });
    },
    actions: {
      change: function change(event, files) {
        var _this = this;

        if (files && files[0]) {
          var reader = new FileReader();

          reader.onload = function (e) {
            return _this.sendAction("parseData", e.srcElement.result);
          };

          reader.readAsText(files[0]);
          this.files.pushObject(files[0]);
        }
      }
    }
  });

  _exports.default = _default;
});