define("innowaze-ember2/adapters/opinion", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot, requestType) {
      if (requestType === "createRecord") {
        return "".concat(this.host, "/").concat(this.namespace, "/debates/").concat(snapshot.record.get("debate_id"), "/opinions.json");
      }

      return "".concat(this.host, "/").concat(this.namespace, "/debates/").concat(snapshot.record.get("debate_id"), "/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});