define("innowaze-ember2/templates/challenges/modules/-timeline-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dTa7Vfiv",
    "block": "{\"symbols\":[\"milestone\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"milestones\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"show-for-medium-up dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"challenges.timeline.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"timeline-fix\"],[9],[0,\" \\n\"],[4,\"each\",[[23,[\"model\",\"milestones\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[12,\"class\",[28,[[27,\"if\",[[22,1,[\"completed\"]],\"\",\"complete\"],null]]]],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"timestamp\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"author\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"starts_on_title\"]]],null,{\"statements\":[[0,\"                \"],[7,\"i\"],[11,\"class\",\"fa fa-check-square\"],[9],[0,\" \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"              \"],[1,[22,1,[\"starts_on_title\"]],false],[0,\" \\n            \"],[10],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"date\"],[9],[1,[27,\"formatted-date\",[[22,1,[\"starts_on\"]]],[[\"format\"],[\"long\"]]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"status\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"speech-bubble\"],[9],[0,\"\\n              \"],[7,\"h6\"],[9],[1,[27,\"truncate\",[[22,1,[\"name\"]],70],null],false],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-timeline-horizontal.hbs"
    }
  });

  _exports.default = _default;
});