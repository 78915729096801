define("innowaze-ember2/templates/debates/-open-debate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OFuOldvH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"not\",[[23,[\"model\",\"debate\",\"hasVoted\"]]],null],[27,\"or\",[[23,[\"model\",\"debate\",\"active\"]],[23,[\"session\",\"isModerator\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"form\"],[11,\"class\",\"debate-new-comment\"],[3,\"action\",[[22,0,[]],\"comment\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n    \"],[1,[27,\"textarea\",null,[[\"value\",\"placeholder\"],[[23,[\"newComment\"]],[27,\"i18n\",[\"debates.show.open_debate_placeholder\"],null]]]],false],[0,\"\\n    \"],[1,[27,\"file-upload\",null,[[\"pictureName\",\"filesUploaded\",\"removeUpload\"],[[23,[\"picture_name\"]],\"filesUploaded\",\"removeUpload\"]]],false],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"button-primary add-comment\"],[12,\"disabled\",[21,\"saveDisabled\"]],[11,\"type\",\"submit\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-comment\"],[9],[10],[0,\"\\n      \"],[1,[27,\"i18n\",[\"debates.show.discuss\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[15,\"debates/comments\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/debates/-open-debate.hbs"
    }
  });

  _exports.default = _default;
});