define("innowaze-ember2/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+LvoI19l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"Désolé, cette page n'existe pas ou a été déplacée\"],[10],[0,\"\\n\"],[4,\"link-to\",[\"challenges\"],[[\"class\"],[\"button-primary\"]],{\"statements\":[[0,\"    Retour à l'accueil\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});