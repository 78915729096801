define("innowaze-ember2/app", ["exports", "ember-load-initializers", "innowaze-ember2/config/environment", "innowaze-ember2/resolver", "innowaze-ember2/sentry"], function (_exports, _emberLoadInitializers, _environment, _resolver, _sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.Checkbox.reopen({
    attributeBindings: ["data-cy"]
  });
  Ember.LinkComponent.reopen({
    attributeBindings: ["data-cy", "style"]
  });
  Ember.TextField.reopen({
    attributeBindings: ["data-cy"]
  });
  (0, _sentry.default)(); // Quick polyfill to use jQuery 3 with legacy plugin

  $.fn.load = function (callback) {
    $(window).on("load", callback);
  }; // Unrecognized expression syntax namespace fix (https://ilikekillnerds.com/2014/07/foundation-v5-unrecognized-expression-syntax-namespace-fix/)


  Foundation.global.namespace = "";
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix); // workaround for iOS10/iOS11 touchmove behaviour (See https://github.com/timruffles/mobile-drag-drop/issues/77)

  try {
    window.addEventListener("touchmove", function () {}, {
      passive: false
    });
  } catch (e) {}

  var _default = App;
  _exports.default = _default;
});