define("innowaze-ember2/controllers/concepts/show", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/controllers/evaluations", "innowaze-ember2/utils/formatEvaluation"], function (_exports, _objectSpread2, _evaluations, _formatEvaluation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_evaluations.default, {
    locale: Ember.inject.service(),
    concept: Ember.computed.alias("model.concept"),
    challenge: Ember.computed.alias("model.challenge"),
    likeValue: Ember.computed("model.challenge.rules.[]", function () {
      var rule = this.get("model.challenge.rules").filter(function (rule) {
        return rule.active && rule.name === "like_value";
      });
      return rule[0] ? rule[0].reward : 0;
    }),
    allocatedBudget: Ember.computed("model.concept.total_rating", "likeValue", function () {
      return this.get("model.concept.total_rating") * this.likeValue;
    }),
    percentBudget: Ember.computed("model.concept.total_rating", "model.concept.budget", "allocatedBudget", function () {
      return this.get("model.concept.budget") > 0 ? this.allocatedBudget / this.get("model.concept.budget") : 1;
    }),
    visibleDebates: Ember.computed.filterBy("model.concept.debates", "visible_in_widget", true),
    activeReviewerTools: Ember.computed("model.challenge.concept_tools", "model.concept.current_user_tools", function () {
      var _this = this;

      return this.get("model.challenge.concept_tools").filter(function (tool) {
        return tool.active;
      }).map(function (t) {
        var tool = Ember.Object.create(t);

        var evaluation = _this.get("model.concept.current_user_tools").findBy("id", tool.id);

        if (evaluation) {
          Ember.setProperties(tool, {
            value: (0, _formatEvaluation.percentToValue)(evaluation.value, _this.minValue, _this.maxValue),
            evaluated: evaluation.evaluated
          });
        }

        return tool;
      });
    }),
    hasTools: Ember.computed("activeTools", function () {
      return this.activeTools.length > 0;
    }),
    activeTools: Ember.computed("model.challenge.concept_tools", "model.concept.tools", function () {
      var _this2 = this;

      return this.get("model.challenge.concept_tools").filter(function (tool) {
        return tool.active;
      }).map(function (t) {
        var tool = Ember.Object.create(t);

        var evaluation = _this2.get("model.concept.tools").findBy("id", tool.id);

        if (evaluation) {
          Ember.setProperties(tool, {
            value: Math.floor(evaluation.value),
            evaluated: evaluation.evaluated,
            is_median: evaluation.is_median
          });
        } else {
          // ...otherwise, is_median should be intialized
          Ember.setProperties(tool, {
            evaluated: false,
            is_median: true
          });
        }

        return tool;
      });
    }),
    displayTools: Ember.computed("session.isModerator", "session.isReviewer", "model.challenge.display_concept_evaluations", "canReviewConcept", "hasEvaluations", function () {
      return this.get("session.isModerator") || this.canReviewConcept || this.get("model.challenge.display_concept_evaluations") && this.hasEvaluations;
    }),
    canReviewConcept: Ember.computed("session.isReviewer", "model.concept.to_be_evaluated", function () {
      return this.get("session.isReviewer") && this.get("model.concept.to_be_evaluated");
    }),
    conceptIsClosed: Ember.computed("session.isModerator", "session.isAdmin", "model.idea.rejected", function () {
      return this.get("model.concept.rejected") && !(this.get("session.isModerator") || this.get("session.isAdmin"));
    }),
    selectedToolCategory: Ember.computed("availableToolCategories", function () {
      return this.availableToolCategories[0];
    }),
    selectedReviewerToolCategory: Ember.computed("availableReviewerToolCategories", function () {
      return this.availableReviewerToolCategories[0];
    }),
    radarData: Ember.computed("activeTools", "selectedToolCategory", function () {
      var filtered_active_tools = this.filteredActiveTools.map(function (t) {
        return {
          axis: t.title,
          value: t.value
        };
      }).filter(function (t) {
        return typeof t.value !== "undefined";
      });
      return filtered_active_tools.length > 2 ? [filtered_active_tools] : null;
    }),
    stakes: Ember.computed("activeTools", function () {
      var tool = this.activeTools.findBy("id", 1);
      return tool ? tool.value : 0;
    }),
    accessibility: Ember.computed("activeTools", function () {
      var tool = this.activeTools.findBy("id", 2);
      return tool ? tool.value : 0;
    }),
    canEditConcept: Ember.computed("session.isModerator", "model.concept.user_id", "model.concept.teammates", function () {
      var currentUserId = this.get("session.currentUser.id");
      var teammatesIds = this.get("model.concept.teammates").mapBy("id");
      return this.get("session.isModerator") || this.get("model.concept.user_id") === parseInt(currentUserId) || teammatesIds.includes(currentUserId);
    }),
    canSwitchStep: Ember.computed("model.concept.moderate_concepts", "session.isAuthor", "session.isModerator", "model.concept.state", function () {
      if (this.get("session.isModerator")) {
        return true;
      }

      switch (this.get("model.concept.state")) {
        case "draft":
          return this.get("session.isAuthor") || this.get("session.isModerator");

        case "formulated":
          if (this.moderate_concepts) {
            return this.get("session.isModerator");
          }

          return this.get("session.isAuthor") || this.get("session.isModerator");
      }
    }),
    nextStateForConcept: Ember.computed("model.concept.state", "model.challenge.moderate_concepts", "model.challenge.priority_concepts", "model.challenge.concept_expert_validation", "model.challenge.concept_management_committee_validation", function () {
      var challenge = this.get("model.challenge");
      var moderate_concepts = challenge.get("moderate_concepts");
      var expert_validation = challenge.get("concept_expert_validation");
      var priority_concepts = challenge.get("priority_concepts");
      var management_committee_validation = challenge.get("concept_management_committee_validation");

      switch (this.get("model.concept.state")) {
        case "draft":
        default:
          return challenge.nameFor("formulate_concept");

        case "formulated":
          if (moderate_concepts) {
            return challenge.nameFor("publish_concept");
          } else if (priority_concepts) {
            return challenge.nameFor("to_document_concept");
          } else if (expert_validation) {
            return challenge.nameFor("to_evaluate_concept");
          } else if (management_committee_validation) {
            return challenge.nameFor("to_pitch_concept");
          }

          return challenge.nameFor("select_concept");

        case "published":
          if (priority_concepts) {
            return challenge.nameFor("to_document_concept");
          } else if (expert_validation) {
            return challenge.nameFor("to_evaluate_concept");
          } else if (management_committee_validation) {
            return challenge.nameFor("to_pitch_concept");
          }

          return challenge.nameFor("select_concept");

        case "to_be_documented":
          return challenge.nameFor("document_concept");

        case "documented":
          if (expert_validation) {
            return challenge.nameFor("to_evaluate_concept");
          } else if (management_committee_validation) {
            return challenge.nameFor("to_pitch_concept");
          }

          return challenge.nameFor("select_concept");

        case "to_be_evaluated":
          return challenge.nameFor("evaluate_concept");

        case "evaluated":
          if (management_committee_validation) {
            return challenge.nameFor("to_pitch_concept");
          }

          return challenge.nameFor("select_concept");

        case "to_be_pitched":
          return challenge.nameFor("complete_concept");

        case "completed":
          return challenge.nameFor("select_concept");

        case "selected":
          return null;

        case "rejected":
          return null;
      }
    }),
    hideExperts: true,
    matchingExperts: [],
    expertsRequested: [],
    expertsToDisplay: Ember.computed("matchingExperts.[]", "expertsRequested.[]", function () {
      var _this3 = this;

      return this.matchingExperts.reject(function (user) {
        return _this3.expertsRequested.findBy("id", user.id);
      });
    }),
    activeFormats: Ember.computed("model.challenge.concept_formats", "model.concept.index_stage", function () {
      var _this4 = this;

      var activeFormats = this.get("model.challenge.concept_formats").filter(function (module) {
        return module.active;
      }).map(function (module) {
        return (0, _objectSpread2.default)({}, module, {
          is_displayed: _this4.get("model.concept.index_stage") >= module.index_stage
        });
      });

      if (this.get("model.concept.modules")) {
        this.get("model.concept.modules").forEach(function (extraModule) {
          var m = activeFormats.findBy("id", extraModule.id);

          if (m) {
            Ember.set(m, "description", extraModule.description);
          }
        });
      }

      return activeFormats;
    }),
    canContribute: Ember.computed("model.challenge", function () {
      return !!this.get("model.challenge").getActiveConceptModulesByName("contributors");
    })
  });

  _exports.default = _default;
});