define("innowaze-ember2/routes/challenges/debates", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    actions: {
      toggleVisibleInWidget: function toggleVisibleInWidget(debate) {
        debate.toggleVisibleInWidget().then(function () {
          debate.toggleProperty("visible_in_widget");
        });
      },
      updateChallengeDebatesOrder: function updateChallengeDebatesOrder() {
        this.get("controller.challengeDebates").forEach(function (module, index) {
          module.set("position", index + 1);
        });
        this.set("controller.hasChallengeUnsavedPosition", true);
      },
      updateConceptDebatesOrder: function updateConceptDebatesOrder() {
        this.get("controller.conceptDebates").forEach(function (module, index) {
          module.set("position", index + 1);
        });
        this.set("controller.hasConceptUnsavedPosition", true);
      },
      updatePathwayDebatesOrder: function updatePathwayDebatesOrder() {
        this.get("controller.pathwayDebates").forEach(function (module, index) {
          module.set("position", index + 1);
        });
        this.set("controller.hasPathwayUnsavedPosition", true);
      },
      saveChallengeDebatesOrder: function saveChallengeDebatesOrder() {
        var _this = this;

        this.set("controller.hasUnsavedPosition", false);
        this.store.adapterFor("debate").reorder(this.get("controller.challengeDebates")).then(function () {
          return _this._success();
        }, function () {
          return _this._error();
        });
      },
      saveConceptDebatesOrder: function saveConceptDebatesOrder() {
        var _this2 = this;

        this.set("controller.hasUnsavedPosition", false);
        this.store.adapterFor("debate").reorder(this.get("controller.conceptDebates")).then(function () {
          return _this2._success();
        }, function () {
          return _this2._error();
        });
      },
      savePathwayDebatesOrder: function savePathwayDebatesOrder() {
        var _this3 = this;

        this.set("controller.hasUnsavedPosition", false);
        this.store.adapterFor("debate").reorder(this.get("controller.pathwayDebates")).then(function () {
          return _this3._success();
        }, function () {
          return _this3._error();
        });
      }
    },
    _success: function _success() {
      this.notifications.success(this.locale.t("challenges.domains.reorder_message"), {
        autoClear: true
      });
    },
    _error: function _error() {
      this.notifications.error(this.locale.t("error"), {
        autoClear: true
      });
    }
  });

  _exports.default = _default;
});