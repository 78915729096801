define("innowaze-ember2/adapters/department", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      return this._super.apply(this, arguments) + "/search.json";
    },
    tagsCloud: function tagsCloud(type) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/departments/tags_cloud.json"), "GET", {
        data: {
          type: type
        }
      });
    },
    sendEmail: function sendEmail(title, message) {
      var all_members = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/departments/send_email.json"), "POST", {
        data: {
          options: options,
          title: title,
          all_members: all_members,
          message: message
        }
      });
    },
    updateCRM: function updateCRM(id, department) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/departments/").concat(id, "/update_crm.json"), "POST", {
        data: {
          crm: department.get("crm")
        }
      });
    },
    addUser: function addUser(id, department, users) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/departments/").concat(id, "/add_users.json"), "POST", {
        data: {
          users: users
        }
      });
    },
    removeUser: function removeUser(id, department, users) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/departments/").concat(id, "/remove_users.json"), "DELETE", {
        data: {
          users: users
        }
      });
    },
    addFile: function addFile(id, department, fileId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("department"), "/").concat(id, "/add_file.json"), "POST", {
        data: {
          file_id: fileId
        }
      });
    }
  });

  _exports.default = _default;
});