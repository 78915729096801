define("innowaze-ember2/templates/challenges/modules/-timeline-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+RRqmXz0",
    "block": "{\"symbols\":[\"milestone\"],\"statements\":[[7,\"div\"],[11,\"class\",\"show-for-medium-up dashboard-module\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"showcase\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"id\",\"timeline\"],[11,\"class\",\"section-timeline\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"container widget\"],[9],[0,\"\\n        \"],[7,\"h6\"],[11,\"class\",\"section-title small\"],[9],[1,[27,\"i18n\",[\"challenges.timeline.title\"],null],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"milestones-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"milestones\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"starts_on_title\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\"],[11,\"class\",\"milestone-date small\"],[9],[0,\"\\n                \"],[1,[27,\"formatted-date\",[[22,1,[\"starts_on\"]]],null],false],[7,\"br\"],[9],[10],[0,\"\\n                \"],[1,[22,1,[\"starts_on_title\"]],false],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[7,\"div\"],[11,\"class\",\"milestone\"],[9],[0,\"\\n              \"],[7,\"h3\"],[11,\"class\",\"milestone-name small\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n            \"],[10],[0,\"\\n\"],[4,\"if\",[[22,1,[\"ends_on_title\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\"],[11,\"class\",\"milestone-date small\"],[9],[0,\"\\n                \"],[1,[27,\"formatted-date\",[[22,1,[\"ends_on\"]]],null],false],[7,\"br\"],[9],[10],[0,\"\\n                \"],[1,[22,1,[\"ends_on_title\"]],false],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-timeline-vertical.hbs"
    }
  });

  _exports.default = _default;
});