define("innowaze-ember2/templates/components/avatar-img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zgmvOj/l",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"src\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[27,\"concat\",[[23,[\"session\",\"apiUrl\"]],[23,[\"src\"]]],null]],[12,\"data-caption\",[28,[[21,\"caption\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"size\"]],\"small\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[23,[\"session\",\"defaultImagesPaths\",\"smallAvatar\"]]],[12,\"data-caption\",[21,\"caption\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"size\"]],\"xsmall\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[23,[\"session\",\"defaultImagesPaths\",\"extraSmallAvatar\"]]],[12,\"data-caption\",[21,\"caption\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[23,[\"session\",\"defaultImagesPaths\",\"avatar\"]]],[12,\"data-caption\",[21,\"caption\"]],[9],[10],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/avatar-img.hbs"
    }
  });

  _exports.default = _default;
});