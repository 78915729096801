define("innowaze-ember2/routes/challenges/showcase", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/parallax", "innowaze-ember2/mixins/routes/requestAccess", "innowaze-ember2/utils/stripTags", "innowaze-ember2/configuration"], function (_exports, _objectSpread2, _setCurrentChallenge, _parallax, _requestAccess, _stripTags, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _parallax.default, _requestAccess.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    headData: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id, {
        adapterOptions: {
          public_version: true
        }
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (this.session.get("configuration.dict.public_pages")) {
        this.setProperties((0, _objectSpread2.default)({
          "headData.title": "".concat(model.get("name"), " | ").concat(this.get("session.configuration.dict.instance")),
          "headData.description": (0, _stripTags.default)(model.get("description"))
        }, model.get("banner_url") && {
          "headData.image": _configuration.default.apiUrl + model.get("banner_url")
        }, {}, model.get("showcase_banner_url") && {
          "headData.image": _configuration.default.apiUrl + model.get("showcase_banner_url")
        }));
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("isShowcase", true);
    },
    deactivate: function deactivate() {
      this.willDestroy();
    },
    actions: {
      didTransition: function didTransition() {
        this.tick();
      },
      scrollTo: function scrollTo(id) {
        this.controller.setProperties({
          briefActive: false,
          timelineActive: false,
          whyparticipateActive: false,
          domainsActive: false,
          organisationActive: false
        });
        this.controller.set("".concat(id.replace("-", ""), "Active"), true);
        $("#".concat(id)).get(0).scrollIntoView();
      }
    }
  });

  _exports.default = _default;
});