define("innowaze-ember2/controllers/universes/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    nameFilter: "",
    department: null,
    group: null,
    actions: {
      toggleDisplayConcepts: function toggleDisplayConcepts(challenge) {
        challenge.toggleProperty("display_concepts");
      }
    },
    bannerStyle: Ember.computed("model.banner_url", function () {
      return Ember.String.htmlSafe("background-image: url(".concat(this.get("session.apiUrl")).concat(this.get("model.banner_url"), ");").concat(this.get("model.bannerStyle")));
    }),
    hasDepartments: Ember.computed("departments", function () {
      return this.departments && this.departments.length > 1;
    }),
    filteredChallenges: Ember.computed("department", "group", "nameFilter", "model.challenges.[]", function () {
      var department = this.department;
      var group = this.group;
      var nameFilter = this.nameFilter;
      var filteredChallenges = this.get("model.challenges").filter(function (challenge) {
        return !challenge.get("closed");
      });

      if (department) {
        filteredChallenges = filteredChallenges.filterBy("department.id", parseInt(department));
      }

      if (group) {
        filteredChallenges = filteredChallenges.filterBy("challenge_group_id", parseInt(group));
      }

      if (nameFilter.length > 0) {
        var lcNameFilter = nameFilter.toLowerCase();
        filteredChallenges = filteredChallenges.filter(function (challenge) {
          return challenge.get("name").toLowerCase().includes(lcNameFilter);
        });
      }

      return filteredChallenges;
    }),
    filteredChallengesWithReplication: Ember.computed("filteredChallenges", function () {
      return this.filteredChallenges.filter(function (c) {
        return c.replication_counters;
      });
    })
  });

  _exports.default = _default;
});