define("innowaze-ember2/routes/challenges/edit-home", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveChallenge", "innowaze-ember2/utils/sortByPosition"], function (_exports, _toConsumableArray2, _cleanCurrentModel, _setCurrentChallenge, _saveChallenge, _sortByPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _setCurrentChallenge.default, _saveChallenge.default, {
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id, {
        backgroundReload: false
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        availableTopModules: model.get("availableModules").filterBy("mapping", 1).sortBy("position"),
        availableMainModules: model.get("availableModules").filterBy("mapping", 2).sortBy("position"),
        availableExtraModules: model.get("availableModules").filterBy("mapping", 3).sortBy("position"),
        availableLeftModules: model.get("availableModules").filterBy("mapping", 4).sortBy("position"),
        availableCenterModules: model.get("availableModules").filterBy("mapping", 5).sortBy("position"),
        availableRightModules: model.get("availableModules").filter(function (module) {
          return module.mapping === null || module.mapping === 6;
        }).sort(_sortByPosition.default)
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        this.savedModulePosition();
        this.saveCurrentChallenge().then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      },
      next: function next() {
        var _this2 = this;

        this.savedModulePosition();
        this.saveForNextStep(2).then(function () {
          return _this2._stepSuccess();
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      deleteBanner: function deleteBanner() {
        this.currentModel.setProperties({
          banner_id: null,
          banner_url: null
        });
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.setProperties({
          banner_id: files[0].id,
          banner_url: files[0].file_url
        });
      }
    },
    savedModulePosition: function savedModulePosition() {
      var _this3 = this;

      (0, _toConsumableArray2.default)(document.querySelectorAll("[data-mapping]")).forEach(function (list) {
        (0, _toConsumableArray2.default)(list.querySelectorAll("li")).forEach(function (item, index) {
          var module = _this3.currentModel.get("availableModules").findBy("id", parseInt(item.dataset.id));

          module.setProperties({
            position: index,
            mapping: parseInt(list.dataset.mapping)
          });
        });
      });
    },
    _stepSuccess: function _stepSuccess() {
      this._success();

      this.transitionTo("challenges.edit_gaming", this.currentModel.id);
    }
  });

  _exports.default = _default;
});