define("innowaze-ember2/components/modules/steering-committee-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    availableProjectYears: Ember.computed("model.project_year", function () {
      var actual_year = this.get("model.project_year");

      if (!actual_year) {
        var today = new Date();
        actual_year = today.getFullYear();
      }

      return [null, actual_year - 1, actual_year, actual_year + 1, actual_year + 2];
    }),
    completedDidChange: Ember.on("init", Ember.observer("model.steering_committee_description", "model.steering_committee_date", "model.project_year", function () {
      this.set("module.isNotCompleted", !this.get("model.steering_committee_description") || !this.get("model.steering_committee_date") || !this.get("model.project_year"));
    })),
    actions: {
      setCommitteeDate: function setCommitteeDate(date) {
        this.model.set("steering_committee_date", moment(date).format("YYYY-MM-DD"));
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.model.set(field, newContent);
      }
    }
  });

  _exports.default = _default;
});