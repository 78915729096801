define("innowaze-ember2/adapters/concept", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    previousState: function previousState(id, concept) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/previous_state.json"), "POST");
    },
    nextState: function nextState(id, concept) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/next_state.json"), "POST");
    },
    reject: function reject(id, concept) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/reject.json"), "POST");
    },
    toggleFollow: function toggleFollow(id, concept) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/toggle_follow.json"), "POST");
    },
    toggleContribute: function toggleContribute(id, concept) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/toggle_contribute.json"), "POST");
    },
    toggleIsMedian: function toggleIsMedian(id, concept, toolName, isMedian) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/toggle_is_median.json"), "POST", {
        data: {
          tool_name: toolName,
          is_median: isMedian
        }
      });
    },
    addTeam: function addTeam(id, concept, userId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/add_team.json"), "POST", {
        data: {
          user_id: userId
        }
      });
    },
    toggleAnalysis: function toggleAnalysis(id, concept, kind, active) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/toggle_analysis.json"), "POST", {
        data: {
          kind: kind,
          active: active
        }
      });
    },
    multipleEvaluations: function multipleEvaluations(id, concept) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/multiple_evaluations.json"), "GET");
    },
    requestExpert: function requestExpert(id, concept, userId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/request_expert.json"), "POST", {
        data: {
          user_id: userId
        }
      });
    },
    updateRating: function updateRating(id, concept, rating) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/update_rating.json"), "POST", {
        data: {
          rating: rating
        }
      });
    },
    addFile: function addFile(id, concept, fileId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/add_file.json"), "POST", {
        data: {
          file_id: fileId
        }
      });
    },
    updateCurrentUserEvaluation: function updateCurrentUserEvaluation(id, concept, data) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(concept.get("challenge_id"), "/").concat(this.pathForType("concept"), "/").concat(id, "/update_evaluation.json"), "POST", {
        data: data
      });
    },
    deleteOpinion: function deleteOpinion(debateId, opinionId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(debateId, "/opinions/").concat(opinionId, ".json"), "DELETE");
    }
  });

  _exports.default = _default;
});