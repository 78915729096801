define("innowaze-ember2/controllers/ideas/grouped-per-supertag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locale: Ember.inject.service(),
    loader: true,
    clusters: Ember.computed(function () {
      return [{
        id: 1,
        title: this.locale.t("header.menu.domains")
      }, {
        id: 2,
        title: this.locale.t("header.menu.supertags")
      }];
    }),
    elementDidMove: Ember.observer("ideas.@each.supertag", function () {
      var _this = this;

      this.supertag_names.forEach(function (label) {
        var supertag = _this.supertags.findBy("label", label);

        var ideas = _this.ideas.filterBy("supertag", label);

        supertag.set("groupedIdeasCounter", ideas.length);
      });
    })
  });

  _exports.default = _default;
});