define("innowaze-ember2/components/modules/agriculture-module", ["exports", "ramda/src/pathOr", "ramda/src/uniq", "innowaze-ember2/components/modules/module"], function (_exports, _pathOr, _uniq, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (this.editMode) {
        if (!this.get("model.land_activity")) {
          this.set("model.land_activity", this.available_land_activities[0]);
        }

        if (!this.get("model.land_administrator")) {
          this.set("model.land_administrator", this.available_land_administrators[0]);
        }

        if (!this.get("model.land_owner")) {
          this.set("model.land_owner", this.available_land_owners[0]);
        }

        if (!this.get("model.land_access")) {
          this.set("model.land_access", this.available_land_access[0]);
        }
      }
    },
    available_land_types: Ember.computed("model.land_production_type.[]", function () {
      var currentLandTypes = this.get("model.land_production_type") || [];
      return (0, _pathOr.default)([], ["params", "land_type"], this.challenge.getActiveIdeaModule("agriculture")).map(function (land_type) {
        if (currentLandTypes.includes(land_type.id)) {
          Ember.set(land_type, "selected", true);
        }

        return land_type;
      });
    }),
    available_land_activities: Ember.computed(function () {
      return (0, _pathOr.default)([], ["params", "land_activity"], this.challenge.getActiveIdeaModule("agriculture"));
    }),
    available_land_administrators: Ember.computed(function () {
      return (0, _pathOr.default)([], ["params", "land_administrator"], this.challenge.getActiveIdeaModule("agriculture"));
    }),
    available_land_owners: Ember.computed(function () {
      return (0, _pathOr.default)([], ["params", "land_owner"], this.challenge.getActiveIdeaModule("agriculture"));
    }),
    available_land_access: Ember.computed(function () {
      return (0, _pathOr.default)([], ["params", "land_access"], this.challenge.getActiveIdeaModule("agriculture"));
    }),
    actions: {
      setLandTypes: function setLandTypes(typeId, event) {
        var currentLandTypes = this.get("model.land_production_type") || [];

        if (event.target.checked) {
          this.model.set("land_production_type", (0, _uniq.default)(currentLandTypes.concat(typeId)));
        } else {
          this.model.set("land_production_type", currentLandTypes.filter(function (id) {
            return id !== typeId;
          }));
        }
      }
    }
  });

  _exports.default = _default;
});