define("innowaze-ember2/utils/normalizeScore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeScore;

  /**
   * Normalizes the score yielded by ElasticSearch, which depends on description length
   *
   * @param {Boolean} normalized
   * @param {Number} score
   * @param {String} [description]
   *
   * @return {Number}
   */
  function normalizeScore() {
    var normalized = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var score = arguments.length > 1 ? arguments[1] : undefined;
    var description = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
    var wordsCount = description.split(" ").length;
    return !normalized && wordsCount > 120 ? score / (wordsCount / 80) : score;
  }
});