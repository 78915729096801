define("innowaze-ember2/components/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    tagName: "svg",
    classNames: "bar-chart",
    attributeBindings: ["width", "height", "legend", "scale"],
    legend: true,
    draw: Ember.observer("records", "dataLoaded", function () {
      if (this.dataLoaded) {
        d3.select(this.$().get(0)).selectAll("g").remove();
        this.drawChart();
      }
    }),
    didInsertElement: function didInsertElement() {
      this.addTooltip();
      this.drawChart();
    },
    drawChart: function drawChart() {
      var _this = this;

      var parseDate = d3.time.format("%Y-%m-%d").parse;
      var date_format = d3.time.format("%Y-%m-%d");
      var color = d3.scale.ordinal().domain(["today_connected_users", "heavily_commented_ideas", "recently_connected_users", "medium_commented_ideas", "quite_recently_connected_users", "rarely_connected_users", "poorly_commented_ideas", "uncommented_ideas", "nomore_connected_users", "never_connected_users", "ideas", "idea_comments", "challenge_debate_comments", "full_contributors", "mix_contributors", "only_ideators", "only_commentators", "lurkers", "allocated_votes", "total_available_votes", "voting_users_available_votes", "other_state_ideas", "selected_ideas", "rejected_ideas", "implemented_ideas", "weekly_connected_users", "average_monthly_connected_users", "monthly_connected_users", "departments"]).range(["#4084BA", "#4084BA", "#76AED4", "#76AED4", "#A5C9DF", "#C9DBEE", "#C9DBEE", "#F6AF32", "#F6AF32", "#ED4631", "#4084BA", "#76AED4", "#A5C9DF", "#6C5CD3", "#C979D3", "#76AED4", "#80FF00", "#E5F9AD", "#4084BA", "#F6AF32", "#E5F9AD", "lightgrey", "green", "red", "yellow", "#A5C9DF", "#A5C9DF", "#A5C9DF", "orange"]);
      var records = this.records;
      var layers = d3.layout.stack().values(function (d) {
        return d.values;
      }).x(function (d) {
        return parseDate(d.date);
      }).y(function (d) {
        return d.count;
      });
      var stacked = layers(records);
      var nb_charts = stacked.length;
      var maxY = d3.max(stacked, function (d) {
        return d3.max(d.values, function (d) {
          return d.y0 + d.y;
        });
      });
      var margin = {
        top: 30,
        right: 40,
        bottom: 50,
        left: 80
      };
      var width;
      var height;

      if (this.width) {
        width = this.width - margin.left - margin.right;
      } else {
        width = parseInt($("#chart-container").css("width")) - margin.left - margin.right;
      }

      if (this.height) {
        height = this.height - margin.top - margin.bottom;
      } else {
        height = window.innerHeight - margin.top - margin.bottom1;
      }

      var dates = stacked[0].values.map(function (d) {
        return parseDate(d.date);
      });
      var min_date = Math.min.apply(Math, dates);
      var max_date = Math.max.apply(Math, dates);
      var w = width / dates.length / 2;
      var y_width = width + w / 2;
      var x = d3.time.scale().range([0, width]).domain([min_date, max_date]).nice(d3.time.month);
      var y = d3.scale.linear().rangeRound([height, 0]).domain([0, maxY]).nice();
      var xAxis = d3.svg.axis().scale(x).orient("bottom").ticks(this.scale === "month" ? d3.time.months : d3.time.weeks, this.scale === "month" ? 1 : 2).tickFormat(date_format);
      var yAxis = d3.svg.axis().scale(y).orient("right").ticks(5);
      var svg = d3.select(this.$().get(0)).attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(".concat(margin.left, ",").concat(margin.top, ")")); // draw axis

      svg.append("g").attr("class", "axis axis--x").attr("transform", "translate(0,".concat(height, ")")).call(xAxis).selectAll("text").style("text-anchor", "end").attr("dx", "-.8em").attr("dy", ".15em").attr("transform", "rotate(-65)");
      svg.append("g").attr("class", "axis axis--y").attr("transform", "translate(".concat(y_width, ",0)")).call(yAxis); // draw chart

      var layer = svg.selectAll(".layer").data(stacked, function (d) {
        return d.name;
      }).enter().append("g").attr("class", "layer").style("fill", function (d) {
        return color(d.name);
      });
      layer.selectAll("rect").data(function (d) {
        return d.values;
      }).enter().append("rect").attr("x", function (d) {
        return x(parseDate(d.date)) - w / 2;
      }).attr("y", function (d) {
        return y(d.y + d.y0);
      }).attr("height", function (d) {
        return y(d.y0) - y(d.y + d.y0);
      }).attr("width", w).on("mouseover", this.barMouseover.bind(this)).on("mouseout", this.barMouseout.bind(this)); // draw legend

      if (this.legend === true) {
        var legend = svg.append("g").attr("class", "legendContainer");
        var yLegend = 0;
        legend.append("rect").attr("x", 0 - margin.left).attr("y", yLegend).attr("width", 200).attr("height", 20 * nb_charts).attr("fill", "#fff").attr("fill-opacity", "0.4");
        records.forEach(function (chart) {
          legend.append("rect").attr("x", 0 - margin.left).attr("y", yLegend).attr("width", 20).attr("height", 20).attr("fill", color(chart.name));
          legend.append("text").attr("x", 30 - margin.left).attr("y", yLegend + 14).attr("font-size", "10px").text(_this.locale.t("reportings.statistics.".concat(chart.name), {
            ideas: _this.ideasName
          }));
          yLegend += 20;
        });
      }
    },
    barMouseover: function barMouseover(rect) {
      var parseDate = d3.time.format("%Y-%m-%d").parse;
      var date_format = d3.time.format("%d %b %Y");
      var content = "<div>\n      <span>".concat(date_format(parseDate(rect.date)), "</span>\n      <span> : </span>\n      <span>").concat(rect.count, "</span>\n    </div>");
      this.tooltip.html(content).css("display", "block");
    },
    barMouseout: function barMouseout(rect) {
      this.tooltip.css("display", "none").css("right", 0).css("top", 0);
    },
    addTooltip: function addTooltip() {
      this.tooltip = $("<div>").addClass("tooltip").width(160).css("display", "none").insertBefore(this.$());
    }
  });

  _exports.default = _default;
});