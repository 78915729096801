define("innowaze-ember2/helpers/is-number", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNumber = isNumber;
  _exports.default = void 0;

  function isNumber(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        param = _ref2[0];

    return !isNaN(param);
  }

  var _default = Ember.Helper.helper(isNumber);

  _exports.default = _default;
});