define("innowaze-ember2/components/embed-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["pdf-file"],
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, function () {
        this.set("width", this.$().parent("ul").width());
      });
    },
    safeSrc: Ember.computed("src", function () {
      // this is needed because Ember will not put anything in the src attribute
      // without this call to htmlSafe, the url will get prepended with `unsafe:/`
      // see http://stackoverflow.com/a/41068041
      return Ember.String.htmlSafe(this.src);
    }),
    pdfWidth: Ember.computed("width", "ratio", function () {
      if (this.width) {
        return this.width * this.ratio;
      }

      return Ember.String.htmlSafe("100%");
    }),
    pdfHeight: Ember.computed("pdfWidth", function () {
      return parseInt(this.pdfWidth) * 0.75;
    })
  });

  _exports.default = _default;
});