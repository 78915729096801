define("innowaze-ember2/templates/news-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OJpdtXpn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"headlines-title\"],[9],[1,[27,\"i18n\",[\"header.menu.news\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"columns small-12 large-4\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"toggle-filter\"],[3,\"action\",[[22,0,[]],\"showFilters\"]],[9],[0,\"\\n        \"],[7,\"i\"],[12,\"class\",[28,[\"fa \",[27,\"if\",[[23,[\"filtersHidden\"]],\"fa-angle-right\",\"fa-angle-left\"],null]]]],[9],[10],[0,\"\\n         \\n        \"],[7,\"span\"],[9],[0,\"\\n          \"],[1,[27,\"i18n\",[[27,\"if\",[[23,[\"filtersHidden\"]],\"ideas.index.show_filter\",\"ideas.index.hide_filter\"],null]],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"fat-search-bar\",null,[[\"value\",\"onSubmit\"],[[23,[\"query\"]],\"search\"]]],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"filtersHidden\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"large-3 medium-4 small-12 columns\"],[9],[15,\"news/filters\",[]],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"large-9 medium-8 small-12 columns with-filters\"],[9],[15,\"news/news-container\",[]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"filtersHidden\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"small-12 columns\"],[9],[15,\"news/news-container\",[]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/news-instance.hbs"
    }
  });

  _exports.default = _default;
});