define("innowaze-ember2/utils/formatUrl", ["exports", "@babel/runtime/helpers/esm/typeof", "innowaze-ember2/configuration"], function (_exports, _typeof2, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatUrl;

  /**
   * Generate adapterOption-aware URL
   * @see ApplicationAdapter
   *
   * @param {String} url
   * @param {Object} snapshot
   *
   * @return {String}
   */
  function formatUrl(url, snapshot) {
    var qs = Ember.get(snapshot, "adapterOptions");

    if ((0, _typeof2.default)(qs) !== "object") {
      return url;
    }

    if ("challenge_id" in qs && qs.challenge_id && !url.match(/(user|supertag)/)) {
      var prefix = "".concat(_configuration.default.apiUrl, "/").concat(_configuration.default.apiNamespace);
      url = url.replace(prefix, "".concat(prefix, "/challenges/").concat(qs.challenge_id));
      delete qs.challenge_id;
    }

    Object.keys(qs).forEach(function (key) {
      if (["", "undefined"].includes(String(qs[key]))) {
        delete qs[key];
      }
    });

    if (Object.keys(qs).length === 0) {
      return url;
    }

    return url + (url.includes("?") ? "&" : "?") + $.param(qs);
  }
});