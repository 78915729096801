define("innowaze-ember2/mixins/controllers/evaluations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    evaluationsLoaded: false,
    evaluationsCount: 0,
    evaluations: Ember.computed(function () {
      return Ember.A();
    }),
    hasBoxPlot: Ember.computed.and("evaluationsLoaded", "evaluations"),
    filteredActiveTools: Ember.computed("activeTools.[]", "selectedToolCategory", "activeAxis", function () {
      return this.activeTools.filterBy("category", this.selectedToolCategory);
    }),
    currentFilteredActiveTools: Ember.computed("filteredActiveTools", "activeAxis", function () {
      return this.filteredActiveTools.filterBy("id", this.activeAxis);
    }),
    filteredActiveReviewerTools: Ember.computed("activeReviewerTools", "selectedReviewerToolCategory", function () {
      return this.activeReviewerTools.filterBy("category", this.selectedReviewerToolCategory);
    }),
    filteredEvaluations: Ember.computed("evaluations.[]", "selectedToolCategory", "activeAxis", function () {
      return this.evaluations.filterBy("category", this.selectedToolCategory);
    }),
    currentFilteredEvaluations: Ember.computed("filteredEvaluations", "activeAxis", function () {
      return this.filteredEvaluations.filterBy("tool_id", this.activeAxis);
    }),
    filteredEvaluationsIsEmpty: Ember.computed.empty("filteredEvaluations"),
    availableToolCategories: Ember.computed("activeTools", function () {
      return this.activeTools.mapBy("category").uniq().sort();
    }),
    availableReviewerToolCategories: Ember.computed("activeReviewerTools", function () {
      return this.activeReviewerTools.mapBy("category").uniq().sort();
    }),
    hasEvaluations: Ember.computed("activeTools", function () {
      return this.activeTools.any(function (tool) {
        return tool.value > 0;
      });
    }),
    minValue: Ember.computed("session.currentChallenge.evaluation_min", function () {
      return this.get("session.currentChallenge.evaluation_min") || 0;
    }),
    maxValue: Ember.computed("session.currentChallenge.evaluation_max", function () {
      return this.get("session.currentChallenge.evaluation_max") || 100;
    }),
    step: Ember.computed("session.currentChallenge.evaluation_step", function () {
      return this.get("session.currentChallenge.evaluation_step") || 1;
    })
  });

  _exports.default = _default;
});