define("innowaze-ember2/routes/universes/show", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/requestAccess", "innowaze-ember2/configuration", "innowaze-ember2/mixins/routes/resetScroll"], function (_exports, _objectSpread2, _requestAccess, _configuration, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, _requestAccess.default, {
    locale: Ember.inject.service(),
    headData: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get("session.configuration.dict.universes")) {
        this.replaceWith("challenges");
      }
    },
    model: function model(params) {
      return this.store.findRecord("universe", params.universe_id);
    },
    afterModel: function afterModel(model) {
      if (this.session.get("configuration.dict.public_pages")) {
        this.setProperties({
          "headData.title": "".concat(model.get("name"), " | ").concat(this.get("session.configuration.dict.instance")),
          "headData.description": model.get("short_description"),
          "headData.image": _configuration.default.apiUrl + model.get("thumbnail_url")
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.set("disableUniverseBtn", false);
      this.store.findAll("department").then(function (departments) {
        controller.set("departments", [{
          id: null,
          name: _this.locale.t("departments.all_departments")
        }]);
        controller.departments.pushObjects(departments.filterBy("customer", true));
      });
      this.store.adapterFor("challenge").findGroups().then(function (groups) {
        controller.set("availableGroups", [{
          id: null,
          name: _this.locale.t("challenges.index.all_groups")
        }]);
        controller.availableGroups.pushObjects(groups.map(function (group) {
          return (0, _objectSpread2.default)({}, group, {
            id: group.id.toString()
          });
        }));
      });
    },
    actions: {
      toggleDefaultUniverse: function toggleDefaultUniverse(id) {
        var _this2 = this;

        this.set("controller.disableUniverseBtn", true);
        this.set("session.currentUser.default_universe_id", id);
        this.get("session.currentUser").save().finally(function () {
          return _this2.set("controller.disableUniverseBtn", false);
        });
      }
    }
  });

  _exports.default = _default;
});