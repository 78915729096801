define("innowaze-ember2/serializers/blueocean", ["exports", "ember-data", "innowaze-ember2/objects/reference", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _reference, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      badges: {
        embedded: "always"
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.id = 1;
      ["curves", "factors"].forEach(function (attribute) {
        if (payload[attribute]) {
          payload[attribute] = payload[attribute].map(function (element) {
            return Ember.Object.create(element);
          });
        }
      });

      if (payload.references) {
        payload.references = payload.references.map(function (ref) {
          ref.active = true;
          return _reference.default.create(ref);
        });
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});