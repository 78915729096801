define("innowaze-ember2/templates/email-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9/dJ79cx",
    "block": "{\"symbols\":[\"template\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"i18n\",[\"header.menu.email_templates\"],null],false],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"fat-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"banner\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n          \"],[4,\"link-to\",[\"emailTemplates.edit\",[22,1,[\"id\"]]],null,{\"statements\":[[1,[22,1,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,1,[\"action\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\"],[11,\"class\",\"label\"],[9],[1,[22,1,[\"action\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"delete-domain\"],[3,\"action\",[[22,0,[]],\"delete\",[22,1,[]]]],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"emailTemplates.new\"],[[\"class\"],[\"button-primary right\"]],{\"statements\":[[0,\"    \"],[1,[27,\"i18n\",[\"challenges.email_templates.new\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/email-templates.hbs"
    }
  });

  _exports.default = _default;
});