define("innowaze-ember2/models/label", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    analysis: _emberData.default.attr(),
    canevas_id: _emberData.default.attr("number"),
    color: _emberData.default.attr("string"),
    concept_id: _emberData.default.attr("number"),
    created_at: _emberData.default.attr("string"),
    created_by: _emberData.default.attr(),
    description: _emberData.default.attr("string"),
    exploration: _emberData.default.attr(),
    formula: _emberData.default.attr(),
    kind: _emberData.default.attr(),
    location: _emberData.default.attr(),
    name: _emberData.default.attr("string"),
    notes: _emberData.default.attr(),
    object_id: _emberData.default.attr(),
    questions: _emberData.default.attr(),
    ready: _emberData.default.attr(),
    scheduled_at: _emberData.default.attr(),
    table_data_id: _emberData.default.attr(),
    updated_at: _emberData.default.attr("string"),
    updated_by: _emberData.default.attr(),
    value: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return null;
      }
    }),
    isAction: Ember.computed.equal("kind", "action"),
    isSimple: Ember.computed.not("isAction"),
    humanValue: Ember.computed("value", function () {
      var value = this.value;

      if (value >= 1000000000) {
        return parseFloat((value / 1000000000).toFixed(2)) + "G";
      }

      if (value >= 1000000) {
        return parseFloat((value / 1000000).toFixed(2)) + "M";
      }

      if (value >= 1000) {
        return parseFloat((value / 1000).toFixed(2)) + "K";
      }

      return value.toString();
    }),
    computedValueDidChange: Ember.observer("formula.items.[]", "formula.items.@each.value", "formula.items.@each.period", "formula.selectedFormula", "formula.markets.[]", "formula.markets.@each.period", "formula.touched", function () {
      if (!this.ready) {
        return 0;
      }

      var value = this.get("formula.selectedValueProposal") ? 1 : 0;
      var selectedFormulaHasItems = this.get("formula.selectedFormula.items.length") > 0;

      if (this.get("formula.items")) {
        this.get("formula.items").forEach(function (item) {
          var itemValue = item.value || 0;
          var itemPeriod = (selectedFormulaHasItems ? 1 : item.period) || 1;
          value += itemValue * itemPeriod;
        });
      }

      var isFormulaUsingMarketShare = this.get("formula.selectedFormula.with_market_share"); // Dynamic formula to apply

      if (selectedFormulaHasItems || this.get("formula.selectedValueProposal")) {
        var selectedItemIds = this.get("formula.selectedFormula.items").mapBy("id");
        var marketValues = this.get("formula.markets").map(function (market) {
          var usersByPeriod;

          if (isFormulaUsingMarketShare) {
            usersByPeriod = market.get("label.value") * market.get("period");
          } else {
            usersByPeriod = market.get("period") ? value * market.get("period") : 0;
          } // Only use stored elements that belong to current selected
          // formula items


          var selectedElements = market.elements.filter(function (e) {
            return selectedItemIds.includes(e.item.id);
          });
          var values = selectedElements.map(function (element) {
            var marketValue = element.get("value");

            if (element.get("item.unit") === "%") {
              marketValue /= 100;
            }

            return marketValue;
          });

          if (values.length) {
            return values.reduce(function (previousValue, currentValue) {
              return previousValue * currentValue;
            }, usersByPeriod);
          }

          return 0;
        });
        var newValue = marketValues.reduce(function (acc, currentValue) {
          return acc += currentValue * value;
        }, 0);

        if (!isNaN(newValue) && newValue) {
          value = newValue;
        }
      }

      this.set("value", value);
    })
  });

  _exports.default = _default;
});