define("innowaze-ember2/components/progress-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "svg",
    className: ["progress-circle"],
    attributeBindings: ["width", "height", "color"],
    didInsertElement: function didInsertElement() {
      this.svg = d3.select("#".concat(this.elementId));
      var color = this.color;
      var radius = 60;
      var border = 5;
      var padding = 0;
      var boxSize = (radius + padding) * 2;
      this.arc = d3.svg.arc().startAngle(0).innerRadius(radius).outerRadius(radius - border);
      var svg = this.svg.append("svg").attr("width", boxSize).attr("height", boxSize);
      var defs = svg.append("defs");
      var filter = defs.append("filter").attr("id", "blur");
      filter.append("feGaussianBlur").attr("in", "SourceGraphic").attr("stdDeviation", "7");
      var g = svg.append("g").attr("transform", "translate(".concat(boxSize / 2, ", ").concat(boxSize / 2, ")"));
      var meter = g.append("g").attr("class", "progress-meter");
      meter.append("path").attr("class", "background").attr("fill", "#ccc").attr("fill-opacity", 0.5).attr("d", this.arc.endAngle(Math.PI * 2));
      this.foreground = meter.append("path").attr("class", "foreground").attr("fill", color).attr("fill-opacity", 1).attr("stroke", color).attr("stroke-width", 5).attr("stroke-opacity", 1);
      this.front = meter.append("path").attr("class", "foreground").attr("fill", color).attr("fill-opacity", 1);
      this.numberText = meter.append("text").attr("fill", "#000").attr("text-anchor", "middle").attr("dy", ".35em");
      this.updateProgress();
    },
    updateProgress: function updateProgress() {
      var progress = this.progress;
      var formatPercent = d3.format(".0%");
      this.foreground.attr("d", this.arc.endAngle(Math.PI * 2 * progress));
      this.front.attr("d", this.arc.endAngle(Math.PI * 2 * progress));
      this.numberText.text(formatPercent(progress));
    },
    progressDidChange: Ember.observer("progress", function () {
      this.updateProgress();
    })
  });

  _exports.default = _default;
});