define("innowaze-ember2/routes/pathways", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        pathways: this.store.query("pathway", {
          challenge_id: params.challenge_id
        })
      });
    },
    actions: {
      delete: function _delete(pathway) {
        var _this = this;

        if (!confirm(this.locale.t("pathways.index.deletion_confirmation"))) {
          return;
        }

        pathway.destroyRecord({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id
          }
        }).then(function () {
          _this.notifications.success(_this.locale.t("pathways.index.deleted"));
        });
      }
    }
  });

  _exports.default = _default;
});