define("innowaze-ember2/templates/components/linked-debate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sLl1VuC1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\"],[11,\"class\",\"s s-comment\"],[9],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"txt\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"challenges.pictures.link\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/linked-debate.hbs"
    }
  });

  _exports.default = _default;
});