define("innowaze-ember2/mixins/routes/requestAccess", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    actions: {
      requestAccess: function requestAccess(challenge) {
        var _this = this;

        // Ensure user has a completed profile for given challenge
        // before letting him request access
        if (challenge.get("current_profile_completion")) {
          challenge.requestAccess().then(function () {
            if (challenge.get("requires_validation")) {
              challenge.set("awaiting", true);
              $(document).foundation("reveal");
              $("#accessRequestModal").foundation("reveal", "open");
            } else {
              challenge.set("granted", true);

              _this.transitionTo("challenges.show", challenge.id);
            }
          });
        } else {
          if (!this.get("session.currentUser.id")) {
            window.location = "/signup?challenge_id=".concat(challenge.id);
            return;
          }

          this.transitionTo("users.editForChallenge", challenge.id, this.get("session.currentUser.id"));
          this.notifications.success(this.locale.t("challenges.access_request.profile_needs_completion"));
        }
      }
    }
  });

  _exports.default = _default;
});