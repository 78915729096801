define("innowaze-ember2/routes/ideas/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.idea.get("documents")) {
        model.idea.set("documents", model.idea.get("documents").filterBy("is_parent", false));
      } else {
        model.idea.set("documents", []);
      }

      controller.set("routeIsReadyForAction", false);
      controller.set("userHasSaved", false);
      controller.setProperties({
        relatedIdeas: [],
        relatedKnowledges: [],
        relatedTags: [],
        availableTriggers: this.store.findAll("tag-trigger")
      });
    },
    deactivate: function deactivate() {
      if (this.currentModel.idea.get("hasDirtyAttributes")) {
        this.currentModel.idea.rollbackAttributes();
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.userHasSaved || confirm(this.locale.t("ideas.edit.leaving"))) {
          return true;
        }

        transition.abort();
      },
      save: function save() {
        var _this = this;

        this.controller.set("saveDisabled", true);
        this.currentModel.idea.save({
          adapterOptions: {
            challenge_id: this.currentModel.idea.get("challenge_id")
          }
        }).then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      },
      toggleVisible: function toggleVisible(module) {
        var offset = $("#module-".concat(module.id)).offset();

        if (module.visible && offset) {
          var h = offset.top - 50;
          $("html, body").animate({
            scrollTop: h
          }, 1500);
        }

        this.get("controller.activeModules").forEach(function (m) {
          Ember.set(m, "visible", m.id === module.id || m.always_visible);
        });
      },
      notRelated: function notRelated(idea) {
        this.get("controller.notRelatedIdeas").pushObject(idea._id);
      },
      addTag: function addTag(tag) {
        tag = tag.toLowerCase();
        var tagObject = this.store.peekAll("tag").findBy("name", tag);

        if (!tagObject) {
          tagObject = this.store.createRecord("tag", {
            name: tag,
            id: tag
          });
        }

        this.currentModel.idea.get("tags").pushObject(tagObject);
      },
      addKnowledge: function addKnowledge(knowledge) {
        this.currentModel.idea.get("knowledges").addObject({
          id: knowledge._id,
          name: knowledge._source.name
        });
      }
    }
  });

  _exports.default = _default;
});