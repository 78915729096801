define("innowaze-ember2/components/document-upload", ["exports", "innowaze-ember2/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    hide: true,
    progress: 0,
    name: null,
    didInsertElement: function didInsertElement(e) {
      var _this = this;

      var options = {
        dropZone: this.$(),
        dataType: "json",
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        url: "".concat(_configuration.default.apiUrl, "/api/challenges/").concat(this.challengeId, "/documents.json"),
        submit: function submit(e, data) {
          var name = _this.name;
          data.formData = {
            name: name
          };

          if (!name) {
            _this.notifications.error(_this.locale.t("challenges.documents.name_must_be_set"));
          }

          return !!name;
        },
        done: function done(event, data) {
          var files = data.result;

          _this.sendAction("documentUploaded", files);
        },
        start: function start() {
          _this.setProperties({
            progress: 0,
            hide: false
          });
        },
        stop: function stop() {
          _this.set("hide", true);
        },
        progressall: function progressall(event, data) {
          _this.set("progress", parseInt(data.loaded / data.total * 100));
        }
      };
      this.$("input[type='file']").fileupload(options);
    },
    actions: {
      toggleDocumentForm: function toggleDocumentForm() {
        this.sendAction("toggleDocumentForm");
      }
    },
    progressStyle: Ember.computed("progress", function () {
      return "width: ".concat(this.progress, "%");
    }),
    background: Ember.computed("object.picture_url", function () {
      var url = this.get("object.picture_url");

      if (url) {
        return "background: url(".concat(_configuration.default.apiUrl).concat(url, ") no-repeat;border: none;");
      }

      return null;
    })
  });

  _exports.default = _default;
});