define("innowaze-ember2/torii/torii-wsfed-provider", ["exports", "torii/providers/oauth1"], function (_exports, _oauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-line import/no-unresolved
  var _default = _oauth.default.extend({
    name: "wsfed"
  });

  _exports.default = _default;
});