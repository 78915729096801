define("innowaze-ember2/components/horizontal-stacked-histogram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "svg",
    classNames: "histogram-chart",
    attributeBindings: Ember.String.w("width height color margin"),
    legend: false,
    draw: Ember.observer("data.[]", function () {
      if (this.dataLoaded) {
        d3.select("#" + this.elementId).selectAll("g").remove();
        this.drawChart();
      }
    }),
    didInsertElement: function didInsertElement() {
      this.drawChart();
    },
    drawChart: function drawChart() {
      if (!this.data) {
        return;
      }

      var series = this.data.map(function (d) {
        return d.name;
      });
      var left = this.margin ? this.margin : 260;
      var margins = {
        top: 20,
        left: left,
        right: 24,
        bottom: 20
      };
      var containerWidth = $("#" + this.elementId).closest(".ideas-histogram-block").width();
      var width = this.width ? Math.min(containerWidth, this.width) - margins.left - 40 : containerWidth - margins.left - 40;
      var height = this.height ? this.height : this.data[0].records.length * 25 + 20;
      var dataset = this.data.map(function (d) {
        var array = d.records.map(function (o, i) {
          return {
            y: o.value,
            x: o.label
          };
        });
        return array;
      });
      var stack = d3.layout.stack();
      stack(dataset);
      var clusters = dataset.map(function (group) {
        var array = group.map(function (d) {
          return {
            x: d.y,
            y: d.x,
            x0: d.y0
          };
        });
        return array;
      });
      var svg = d3.select("#" + this.elementId).append("svg").attr("width", containerWidth).attr("height", height + margins.top + margins.bottom).append("g").attr("transform", "translate(" + margins.left + "," + margins.top + ")");
      var xMax = d3.max(clusters, function (group) {
        return d3.max(group, function (d) {
          return d.x + d.x0;
        });
      });
      var xScale = d3.scale.linear(100).domain([0, Math.max(xMax, 10)]).range([0, width]);
      var categories = clusters[0].map(function (d) {
        return d.y;
      });
      var yScale = d3.scale.ordinal().domain(categories).rangeRoundBands([0, height], 0.1);
      var xAxis = d3.svg.axis().scale(xScale).tickSize(1).ticks(10).tickFormat(d3.format("d")).orient("bottom");
      var yAxis = d3.svg.axis().scale(yScale).tickSize(0.5).orient("left");
      var colours = d3.scale.ordinal().domain(["Parents", "Children", "Orphans"]).range(["#2E86C1", "#12CCFC", "#D5D8DC"]);
      var groups = svg.selectAll("g").data(clusters).enter().append("g").style("fill", function (d, i) {
        return colours(i);
      });
      groups.selectAll("rect").data(function (d) {
        return d;
      }).enter().append("rect").attr("x", function (d) {
        return xScale(d.x0);
      }).attr("y", function (d, i) {
        return yScale(d.y);
      }).attr("height", function (d) {
        return yScale.rangeBand();
      }).attr("width", function (d) {
        return 0;
      });
      groups.selectAll("rect").transition().duration(600).attr("width", function (d) {
        return xScale(d.x);
      });
      groups.selectAll("text").data(function (d) {
        return d;
      }).enter().append("text").attr("x", function (d) {
        return xScale(d.x0 + d.x) - 14;
      }).attr("y", function (d, i) {
        return yScale(d.y) + 18;
      }).text(function (d) {
        return d.x > 0 ? d.x : null;
      }).style("font-size", "10px").style("fill", "#FFF").style("fill-opacity", 0);
      groups.selectAll("text").transition().duration(1800).style("fill-opacity", 1);
      svg.append("g").attr("class", "x-axis").attr("transform", "translate(0," + height + ")").call(xAxis);
      svg.append("g").attr("class", "axis").call(yAxis);

      if (!this.legend) {
        return;
      }

      svg.append("rect").attr("fill", "transparent").attr("width", 160).attr("height", 30 * clusters.length).attr("x", 0).attr("y", height + 40);
      series.forEach(function (s, i) {
        svg.append("text").attr("fill", "black").attr("x", 30 + i * 180).attr("y", height + 75).text(s);
        svg.append("rect").attr("fill", colours(i)).attr("width", 20).attr("height", 20).attr("x", i * 180).attr("y", height + 60);
      });
    }
  });

  _exports.default = _default;
});