define("innowaze-ember2/routes/tag-triggers/edit", ["exports", "innowaze-ember2/routes/tag-triggers/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    successMessage: "Le mot clé automatique a été mis à jour.",
    model: function model(params) {
      return this.store.findRecord("tagTrigger", params.tag_trigger_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("keywords", model.get("keywords").map(function (name, id) {
        return Ember.Object.create({
          id: id,
          name: name
        });
      }));
    }
  });

  _exports.default = _default;
});