define("innowaze-ember2/templates/challenges/modules/-graphic-tags-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nzr0BOy1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"session\",\"isCompetitor\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"challenges.tagcloud.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n    \"],[1,[27,\"words-cloud\",null,[[\"data\"],[[23,[\"model\",\"graphic_tags_cloud\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-graphic-tags-cloud.hbs"
    }
  });

  _exports.default = _default;
});