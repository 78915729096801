define("innowaze-ember2/templates/components/delayed-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pvqQeUYB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[21,\"modalId\"]],[11,\"data-reveal\",\"\"],[11,\"class\",\"reveal-modal\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"button-primary right\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[9],[0,\"OK\"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"close-reveal-modal\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[21,\"url\"]],[12,\"download\",[21,\"fileName\"]],[3,\"action\",[[22,0,[]],\"open\"]],[9],[0,\"\\n    \"],[1,[27,\"capitalize\",[[23,[\"linkText\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"lastUpdated\"]]],null,{\"statements\":[[0,\"     \\n    \"],[7,\"a\"],[12,\"href\",[21,\"lastExport\"]],[12,\"download\",[21,\"fileName\"]],[12,\"title\",[21,\"lastUpdatedTitle\"]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-file\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/delayed-download.hbs"
    }
  });

  _exports.default = _default;
});