define("innowaze-ember2/components/blue-ocean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    classNames: ["blue-ocean"],
    attributeBindings: ["width", "height"],
    legends: Ember.computed(function () {
      return [this.locale.t("non_existent"), this.locale.t("very_low"), this.locale.t("low"), this.locale.t("average"), this.locale.t("high"), this.locale.t("very_high")];
    }),
    margin: 100,
    seriesDidChange: Ember.observer("data.references.@each.active", "data.references.@each.name", "data.references.@each.color", "data.curves.[]", function () {
      this.drawSeries();
    }),
    factorsPositionDidChange: Ember.observer("data.factors", "data.factors.[]", function () {
      this.drawSeries();
      this.drawXAxis();
    }),
    actions: {
      save: function save() {
        this.sendAction("save");
      }
    },
    didInsertElement: function didInsertElement() {
      // Make the drag work for iOS
      // see https://stackoverflow.com/questions/49500339/cant-prevent-touchmove-from-scrolling-window-on-ios
      window.addEventListener("touchmove", function () {}, {
        passive: false
      });
      this.addSVG();
      this.addContainers();
      this.drawYAxis();
      this.drawXAxis();
      this.drawSeries();
    },
    addSVG: function addSVG() {
      this.svg = d3.select(this.$().get(0)).insert("svg", "input");
      this.svg.attr({
        width: this.width * 1.2,
        height: this.height * 1.1,
        id: "svg"
      });
    },
    addContainers: function addContainers() {
      var margin = this.margin - 2;
      this.svg.append("g").attr({
        class: "y axis",
        transform: "translate(".concat(margin, ", 0)")
      });
      this.svg.append("g").attr("class", "x axis");
      this.svg.append("g").attr({
        class: "curves",
        transform: "translate(15, 0)"
      });
    },
    drawYAxis: function drawYAxis() {
      var _this = this;

      var yAxis = d3.svg.axis().scale(this.yScale()).tickFormat(function (d) {
        return _this.legends ? _this.legends[d] : null;
      }).orient("left");
      yAxis = this.svg.select("g.y.axis").style({
        fill: "#4f4f4f",
        "font-size": "16px",
        "font-family": "'Open Sans', Helvetica, Arial, sans-serif"
      }).call(yAxis);
      yAxis.select("path").style({
        fill: "none"
      });
    },
    drawXAxis: function drawXAxis() {
      var height = this.height;
      var xScale = this.xScale();
      var factors = this.svg.select("g.x.axis").selectAll("text").data(this.factors);
      factors.enter().append("svg:text").attr("text-anchor", "end").style({
        fill: "#4f4f4f",
        "font-size": "16px",
        "font-weight": "bold",
        "font-family": "'Open Sans', Helvetica, Arial, sans-serif"
      });
      factors.attr({
        x: function x(d) {
          return xScale(d.position);
        },
        y: height,
        transform: function transform(d) {
          return "rotate(-45 ".concat(xScale(d.position), ",").concat(height, ") translate(60, -50)");
        }
      }).text(function (d) {
        return d.get("name");
      });
      factors.exit().remove();
    },
    xScale: function xScale() {
      var margin = this.margin;
      var width = this.width;
      var extent = d3.extent(this.factors, function (d) {
        return d.position;
      });
      return d3.scale.linear().range([margin, width - margin]).domain(extent);
    },
    yScale: function yScale() {
      var margin = this.margin;
      var height = this.height;
      return d3.scale.linear().range([height - margin, margin]).domain([0, 5]);
    },
    drawCircles: function drawCircles() {
      var _this2 = this;

      var margin = this.margin;
      var height = this.height;
      var drawLines = this.drawLines.bind(this);
      var valueScale = d3.scale.linear().range([0, 5]).domain([height - margin, margin]);
      var drag = d3.behavior.drag().origin(Object).on("drag", function (d) {
        d3.event.sourceEvent.preventDefault();
        var newValue = Math.max(margin, Math.min(height - margin, d3.event.y));
        d.set("y", newValue);
        d.set("value", valueScale(newValue));
        d3.select(this).attr("cy", newValue);
        return drawLines();
      });
      var groups = this.svg.select("g.curves").selectAll("circle").data(this.activeCurves);
      groups.enter().append("circle");
      groups.style({
        stroke: function stroke(d) {
          return _this2.referenceById(d.reference_id).color;
        },
        "stroke-width": 2,
        fill: "rgba(255, 255, 255, 0.5)"
      }).attr({
        r: 10,
        cx: function cx(d) {
          return d.x;
        },
        cy: function cy(d) {
          return d.y;
        },
        id: function id(d) {
          return d.id;
        }
      });

      if (this.canEdit) {
        groups.call(drag);
      }

      groups.exit().remove();
    },
    drawLines: function drawLines() {
      var _this3 = this;

      var activeCurvesByReferences = this.references.map(function (reference) {
        return _this3.activeCurves.filterBy("reference_id", reference.id);
      }).filter(function (reference) {
        return reference.length;
      });
      var line = d3.svg.line().x(function (d) {
        return d.x;
      }).y(function (d) {
        return d.y;
      }).interpolate("cardinal");
      var lines = this.svg.select("g.curves").selectAll("path").data(activeCurvesByReferences); // Handle paths creation / updating / removing

      lines.enter().append("path");
      lines.attr({
        d: function d(_d) {
          var coordinates = _d.sort(function (a, b) {
            return _this3.indexForPoints(a) >= _this3.indexForPoints(b) ? 1 : -1;
          }).map(function (point) {
            return point.getProperties("x", "y");
          });

          return line(coordinates);
        }
      }).style({
        stroke: function stroke(d) {
          return _this3.referenceById(d.get("firstObject.reference_id")).color;
        },
        "stroke-dasharray": function strokeDasharray(d) {
          return _this3.referenceById(d.get("firstObject.reference_id")).kind === "version" ? 0 : 3;
        },
        "stroke-width": 2,
        "z-index": 9,
        fill: "none"
      });
      lines.exit().remove(); // Handle series name displaying / updating at the end of the paths
      // This update code is hackish

      var factors = this.factors;
      var curves = this.curves;
      var that = this;
      lines.attr("virtual", function () {
        d3.select(this.parentNode).selectAll("text").text(function (d) {
          var reference = that.referenceById(d.get("lastObject.reference_id"));
          return reference ? reference.name : "";
        }).attr("x", function (d) {
          var lastFactorId = factors.findBy("position", factors.length).get("id");
          var referenceId = d.get("lastObject.reference_id");
          var lastPoint = curves.filterBy("factor_id", lastFactorId).findBy("reference_id", referenceId);

          if (lastPoint) {
            return lastPoint.get("x") + 20;
          }

          return null;
        }).attr("y", function (d) {
          var lastFactorId = factors.findBy("position", factors.length).get("id");
          var referenceId = d.get("lastObject.reference_id");
          var lastPoint = curves.filterBy("factor_id", lastFactorId).findBy("reference_id", referenceId);

          if (lastPoint) {
            return lastPoint.get("y");
          }

          return null;
        });
        return null;
      });
      lines.enter().append("text").text(function (d) {
        return _this3.referenceById(d.get("lastObject.reference_id")).name;
      }).attr("x", function (d) {
        return d.get("lastObject.x") + 20;
      }).attr("y", function (d) {
        return d.get("lastObject.y");
      }).style({
        fill: "#4f4f4f",
        "font-size": "16px",
        "font-family": "'Open Sans', Helvetica, Arial, sans-serif"
      });
    },
    drawSeries: function drawSeries() {
      var _this4 = this;

      var xScale = this.xScale();
      var yScale = this.yScale(); // Set initial points' coordinates

      this.curves.map(function (point) {
        point.x = xScale(_this4.indexForPoints(point));
        point.y = yScale(point.value);
        return point;
      });
      this.drawLines();
      this.drawCircles();
    },
    // Get X axis index for serie point
    indexForPoints: function indexForPoints(point) {
      return this.factors.findBy("id", point.factor_id).position;
    },
    // Return reference matching given id
    referenceById: function referenceById(id) {
      return this.references.findBy("id", id);
    },
    factors: Ember.computed.alias("data.factors"),
    references: Ember.computed.alias("data.references"),
    curves: Ember.computed.alias("data.curves"),
    activeCurves: Ember.computed("data.curves.[]", "data.references.@each.active", function () {
      var _this5 = this;

      return this.get("data.curves").filter(function (curve) {
        return _this5.referenceById(curve.reference_id).active;
      });
    })
  });

  _exports.default = _default;
});