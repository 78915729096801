define("innowaze-ember2/templates/challenge-domains/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NwlfiNIS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"i18n\",[\"domains.new.title\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[15,\"domains/form\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenge-domains/new.hbs"
    }
  });

  _exports.default = _default;
});