define("innowaze-ember2/adapters/user", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    signup: function signup(data) {
      return this.ajax("".concat(this.host, "/").concat(this.pathForType("user"), ".json"), "POST", {
        data: data
      });
    },
    signin: function signin(data) {
      return this.ajax("".concat(this.host, "/").concat(this.pathForType("user"), "/sign_in.json"), "POST", {
        data: data
      });
    },
    socialSignin: function socialSignin(provider, data) {
      return this.ajax("".concat(this.host, "/").concat(this.pathForType("user"), "/auth/").concat(provider, "/callback"), "GET", {
        data: data
      });
    },
    logout: function logout() {
      return this.ajax("".concat(this.host, "/").concat(this.pathForType("user"), "/sign_out"), "DELETE");
    },
    confirmation: function confirmation(data, method) {
      return this.ajax("".concat(this.host, "/").concat(this.pathForType("user"), "/confirmation.json"), method, {
        data: data
      });
    },
    invitations: function invitations(data) {
      return this.ajax("".concat(this.host, "/invitations.json"), "POST", {
        data: data
      });
    },
    changePassword: function changePassword(data) {
      return this.ajax("".concat(this.host, "/").concat(this.pathForType("user"), "/password.json"), "PUT", {
        data: data
      });
    },
    recoverPassword: function recoverPassword(data) {
      return this.ajax("".concat(this.host, "/").concat(this.pathForType("user"), "/password.json"), "POST", {
        data: data
      });
    },
    toggleAdmin: function toggleAdmin(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/").concat(id, "/toggle_admin.json"), "PUT");
    },
    savePassword: function savePassword(id, data) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/").concat(id, "/update_password.json"), "PUT", {
        data: data
      });
    },
    sendEmail: function sendEmail(ids, title, message, challenge, all) {
      var options = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/send_email.json?user_ids=").concat(ids, "&all=").concat(all) + (challenge ? "&challenge=".concat(challenge) : ""), "POST", {
        data: {
          title: title,
          message: message,
          options: options
        }
      });
    },
    import: function _import(users) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/import.json"), "POST", {
        data: {
          users: users
        }
      });
    },
    knowledgeCreatorsSearch: function knowledgeCreatorsSearch() {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/find_knowledge_creators.json"), "GET");
    },
    ideaCreatorsSearch: function ideaCreatorsSearch(challenge_id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/find_creators.json"), "GET", {
        data: {
          challenge_id: challenge_id
        }
      });
    },
    batchDelete: function batchDelete(user_ids) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/destroy_multiple.json"), "POST", {
        data: {
          user_ids: user_ids
        }
      });
    },
    setKnowledgerRole: function setKnowledgerRole(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/").concat(id, "/set_knowledger_role.json"), "PUT");
    },
    removeKnowledgerRole: function removeKnowledgerRole(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/").concat(id, "/remove_knowledger_role.json"), "PUT");
    },
    setDepartmentScoutRole: function setDepartmentScoutRole(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/").concat(id, "/set_scout_role.json"), "PUT");
    },
    setAnonymous: function setAnonymous(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/").concat(id, "/set_anonymous.json"), "PUT");
    },
    removeDepartmentScoutRole: function removeDepartmentScoutRole(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/").concat(id, "/remove_scout_role.json"), "PUT");
    },
    persistRecord: function persistRecord(store, type, snapshot, url, method) {
      var _this = this;

      return store.findAll("user-module").then(function (modules) {
        var data = {};
        store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot, {
          includeId: true,
          userModuleFields: modules.reduce(function (obj, module) {
            return Object.assign(obj, module.get("fields"));
          }, {})
        });
        return _this.ajax(url, method, {
          data: data
        });
      });
    },
    queryRecord: function queryRecord(store, type, query) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/current.json"), "GET", {
        data: query
      });
    },
    // Overridden from RESTAdapter
    createRecord: function createRecord(store, type, snapshot) {
      return this.persistRecord(store, type, snapshot, this.buildURL(type.modelName, null, snapshot, "createRecord"), "POST");
    },
    // Overridden from JSONAPIAdapter
    updateRecord: function updateRecord(store, type, snapshot) {
      return this.persistRecord(store, type, snapshot, this.buildURL(type.modelName, snapshot.id, snapshot, "updateRecord"), "PATCH");
    },
    urlForQuery: function urlForQuery(query, modelName) {
      if (query.expert) {
        delete query.expert;
        return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/expert_search.json");
      }

      if (query.ids) {
        return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), ".json");
      }

      return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("user"), "/search.json");
    }
  });

  _exports.default = _default;
});