define("innowaze-ember2/components/user-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["banner", "user"],
    actions: {
      delete: function _delete(user) {
        this.sendAction("delete", user);
      },
      toggleAdmin: function toggleAdmin(user) {
        this.sendAction("toggleAdmin", user);
      }
    }
  });

  _exports.default = _default;
});