define("innowaze-ember2/templates/ideas/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IGkzsiiz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"ideas.edit.title\"],[[\"an_idea\"],[[23,[\"model\",\"challenge\",\"nameForAnIdeaAccusative\"]]]]],false],[0,\" :\\n      \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-diamond \",[27,\"unless\",[[23,[\"model\",\"idea\",\"is_master\"]],\"hide\"],null]]]],[9],[10],[0,\"\\n      \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-files-o \",[27,\"unless\",[[23,[\"model\",\"idea\",\"is_replica\"]],\"hide\"],null]]]],[9],[10],[0,\"\\n      \"],[1,[27,\"unbound\",[[23,[\"model\",\"idea\",\"name\"]]],null],false],[0,\"   \\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"large-9 small-12 columns\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel show-for-medium-up\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"ideas.new.draft_alert\"],[[\"idea\"],[[23,[\"model\",\"challenge\",\"nameForIdea\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[15,\"ideas/form\",[]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"large-3 small-12 columns sticky-sidebar\"],[9],[0,\"\\n  \"],[15,\"ideas/sidebar\",[]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/ideas/edit.hbs"
    }
  });

  _exports.default = _default;
});