define("innowaze-ember2/templates/supertags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2I8ivPU8",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"div\"],[11,\"class\",\"columns large-6 small-12\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"supertags.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"\\n    \"],[1,[23,[\"model\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"tags.new\"],[[\"class\"],[\"button-grey left\"]],{\"statements\":[[0,\"    \"],[1,[27,\"i18n\",[\"supertags.add_tag\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"p\"],[9],[0,\" \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"columns large-6 small-12\"],[9],[0,\"\\n    \"],[1,[27,\"fat-search-bar\",null,[[\"value\",\"onSubmit\",\"submitOnKeyUp\"],[[23,[\"query\"]],\"search\",true]]],false],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"supertags.explanation\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"loader banner fade\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"ul\"],[11,\"class\",\"keyword-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"matchingTags\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[12,\"class\",[28,[[27,\"if\",[[22,1,[\"supertag\"]],\"supertag\"],null]]]],[3,\"action\",[[22,0,[]],\"supertag\",[22,1,[]]]],[9],[0,\"\\n        \"],[1,[22,1,[\"name\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/supertags.hbs"
    }
  });

  _exports.default = _default;
});