define("innowaze-ember2/routes/departments/show", ["exports", "innowaze-ember2/mixins/routes/resetScroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    controllerName: "departments/common",
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("department", params.department_id, {
        reload: true
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("news", []);
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.setProperties({
        typeName: null,
        stageName: null,
        sectorName: null,
        investmentName: null,
        investorName: null
      });
      this.store.findAll("department-module").then(function (active_modules) {
        controller.set("activeModules", active_modules);
        controller.activeModules.forEach(function (module) {
          if (module.get("params.list")) {
            module.get("params.list").forEach(function (e) {
              e.title = _this.locale.t("departments.".concat(module.get("name"), ".").concat(e.name));
            });
            controller.set("available_".concat(module.get("name")), module.get("params.list"));
          }
        });
        controller.set("leftModules", controller.activeModules.filterBy("visible", true).filterBy("area", 1).sortBy("position"));
        controller.set("rightModules", controller.activeModules.filterBy("visible", true).filterBy("area", 2).sortBy("position"));

        if (controller.available_types) {
          var type = controller.available_types.findBy("id", model.department_type);

          if (type) {
            controller.set("typeName", type.title);
          }
        }

        if (controller.available_stages && model.development_stage > 0) {
          var stage = controller.available_stages.findBy("id", model.development_stage);

          if (stage) {
            controller.set("stageName", stage.title);
          }
        }

        if (controller.available_sectors && model.sector > 0) {
          var sector = controller.available_sectors.findBy("id", model.sector);

          if (sector) {
            controller.set("sectorName", sector.title);
          }
        }

        if (controller.available_investments && model.investment_type > 0) {
          var investment = controller.available_investments.findBy("id", model.investment_type);

          if (investment) {
            controller.set("investmentName", investment.title);
          }
        }

        if (controller.available_investors && model.investor_type > 0) {
          var investor = controller.available_investors.findBy("id", model.investor_type);

          if (investor) {
            controller.set("investorName", investor.title);
          }
        }

        if (model.get("skill_ids") && controller.available_skills) {
          controller.set("skillsNames", []);
          model.get("skill_ids").forEach(function (skill_id) {
            var name = controller.available_skills.findBy("id", skill_id).name;

            var title = _this.locale.t("departments.skills.".concat(name));

            controller.skillsNames.push(title);
          });
        }

        if (model.get("technology_ids") && controller.available_technologies) {
          controller.set("technologiesNames", []);
          model.get("technology_ids").forEach(function (technology_id) {
            var name = controller.available_technologies.findBy("id", technology_id).name;

            var title = _this.locale.t("departments.technologies.".concat(name));

            controller.technologiesNames.push(title);
          });
        }

        if (model.get("users_count") > 10) {
          controller.set("moreUsers", true);
        }
      });
      var options = {
        department_id: model.id,
        order: "published_at",
        disableElastic: true
      };
      this.store.query("news", options).then(function (news) {
        controller.set("news", news);
      });
    },
    actions: {
      toggleCRM: function toggleCRM() {
        this.toggleProperty("controller.crmDisabled");
      },
      updateCRM: function updateCRM() {
        this.currentModel.updateCRM().then(this.set("controller.crmDisabled", false), this.notifications.success(this.locale.t("departments.updated")));
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      delete: function _delete(department) {
        var _this2 = this;

        if (!confirm("".concat(this.locale.t("ideas.show.remove_idea"), " \xAB").concat(department.get("name"), "\xBB ?"))) {
          return;
        }

        department.destroyRecord().then(function () {
          _this2.transitionTo("departments");

          _this2.notifications.success(_this2.locale.t("ideas.show.removed_idea"));
        }, function () {
          _this2.notifications.error(_this2.locale.t("ideas.show.deletion_error"));
        });
      }
    }
  });

  _exports.default = _default;
});