define("innowaze-ember2/models/pathway", ["exports", "ember-data", "innowaze-ember2/mixins/models/commentHandler", "innowaze-ember2/configuration", "innowaze-ember2/models/application"], function (_exports, _emberData, _commentHandler, _configuration, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_commentHandler.default, {
    analysis: _emberData.default.attr("string"),
    author: _emberData.default.belongsTo("user", {
      async: false
    }),
    canManage: _emberData.default.attr("boolean"),
    canEdit: _emberData.default.attr("boolean"),
    challenge_id: _emberData.default.attr("number"),
    comments: _emberData.default.hasMany("comment", {
      async: false
    }),
    comments_count: _emberData.default.attr(),
    contributors: _emberData.default.hasMany("user", {
      async: false
    }),
    debates: _emberData.default.hasMany("debate", {
      async: false
    }),
    description: _emberData.default.attr("string"),
    documents: _emberData.default.attr(),
    exploration_field: _emberData.default.attr("string"),
    image_url: _emberData.default.attr("string"),
    knowledge: _emberData.default.attr(),
    logo_url: _emberData.default.attr("string"),
    logo_id: _emberData.default.attr(),
    name: _emberData.default.attr("string"),
    slide_url: _emberData.default.attr("string"),
    tags: _emberData.default.hasMany("tags", {
      async: false
    }),
    teammates: _emberData.default.hasMany("user", {
      async: false
    }),
    user_id: _emberData.default.attr(),
    backgroundImage: Ember.computed("logo_url", function () {
      return Ember.String.htmlSafe("background-image: url(".concat(_configuration.default.apiUrl).concat(this.logo_url, ");"));
    }),
    toggleContribute: function toggleContribute() {
      return this._callAdapter("toggleContribute");
    },
    addTeam: function addTeam(userId) {
      return this._callAdapter("addTeam", userId);
    },
    convertToKnowledge: function convertToKnowledge() {
      return this._callAdapter("convertToKnowledge");
    },
    translate: function translate(language) {
      return this._callAdapter("translate", language);
    }
  });

  _exports.default = _default;
});