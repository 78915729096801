define("innowaze-ember2/mixins/routes/toggleNewsPublish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    actions: {
      togglePublish: function togglePublish(news) {
        var _this = this;

        news.togglePublish().then(function (state) {
          news.toggleProperty("published");

          _this.notifications.success(news.get("publishNotificationText"));
        });
      }
    }
  });

  _exports.default = _default;
});