define("innowaze-ember2/components/modules/purchasing-smart-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    completedDidChange: Ember.on("init", Ember.observer("model.reported_in_smart", "model.smart_number", function () {
      if (this.get("model.reported_in_smart") === "1") {
        this.model.set("smart_number", null);
        this.set("module.isNotCompleted", false);
        return;
      }

      this.set("module.isNotCompleted", this.get("model.reported_in_smart") !== "2" || !this.get("model.smart_number"));
    }))
  });

  _exports.default = _default;
});