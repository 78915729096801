define("innowaze-ember2/helpers/and", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.and = and;
  _exports.default = void 0;

  function and(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        left = _ref2[0],
        right = _ref2[1];

    return !!left && !!right;
  }

  var _default = Ember.Helper.helper(and);

  _exports.default = _default;
});