define("innowaze-ember2/components/modules/challenge-synchronization-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    canSynchronize: Ember.computed.and("model.mirror_target_domain", "model.canEdit"),
    displaySynchronisationWidget: Ember.computed.and("model.mirror_challenge_name", "model.canEdit"),
    actions: {
      synchronizeIdea: function synchronizeIdea() {
        this.sendAction("synchronizeIdea");
      }
    }
  });

  _exports.default = _default;
});