define("innowaze-ember2/components/modules/slides-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    completedDidChange: Ember.on("init", Ember.observer("model.slide_url", function () {
      this.set("module.isNotCompleted", !this.get("model.slide_url"));
    }))
  });

  _exports.default = _default;
});