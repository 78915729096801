define("innowaze-ember2/torii/torii-saml-popup", ["exports", "torii/services/popup"], function (_exports, _popup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Implementation heavily relies on
  // https://github.com/Vestorly/torii/blob/master/addon/mixins/ui-service-mixin.js
  // eslint-disable-line import/no-unresolved
  var _default = _popup.default.extend({
    pollRemote: function pollRemote() {
      this.remote.postMessage("requestCredentials", "*");
    },
    open: function open(url, keys, options) {
      var _this = this;

      var lastRemote = this.remote;
      return new Ember.RSVP.Promise(function (resolve) {
        if (lastRemote) {
          _this.close();
        }

        _this.openRemote(url, "__torii_request", options);

        _this.schedulePolling();

        window.addEventListener("message", function (event) {
          resolve(event.data);
        });
      });
    }
  });

  _exports.default = _default;
});