define("innowaze-ember2/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JWi2KrWq",
    "block": "{\"symbols\":[],\"statements\":[[2,\" Primary Meta Tags \"],[0,\"\\n\"],[7,\"title\"],[9],[1,[23,[\"model\",\"title\"]],false],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"name\",\"title\"],[12,\"content\",[28,[[23,[\"model\",\"title\"]]]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"name\",\"description\"],[12,\"content\",[28,[[23,[\"model\",\"description\"]]]]],[9],[10],[0,\"\\n\\n\"],[2,\" Open Graph / Facebook \"],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:type\"],[11,\"content\",\"website\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:url\"],[12,\"content\",[28,[[23,[\"model\",\"url\"]]]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:title\"],[12,\"content\",[28,[[23,[\"model\",\"title\"]]]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:description\"],[12,\"content\",[28,[[23,[\"model\",\"description\"]]]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:image\"],[12,\"content\",[28,[[23,[\"model\",\"image\"]]]]],[9],[10],[0,\"\\n\\n\"],[2,\" Twitter \"],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"twitter:card\"],[11,\"content\",\"summary_large_image\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"twitter:url\"],[12,\"content\",[28,[[23,[\"model\",\"url\"]]]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"twitter:title\"],[12,\"content\",[28,[[23,[\"model\",\"title\"]]]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"twitter:description\"],[12,\"content\",[28,[[23,[\"model\",\"description\"]]]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"twitter:image\"],[12,\"content\",[28,[[23,[\"model\",\"image\"]]]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/head.hbs"
    }
  });

  _exports.default = _default;
});