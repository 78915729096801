define("innowaze-ember2/routes/supertags", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("isLoading", true);
      this.store.unloadAll("tag");
      this.store.adapterFor("tag").searchTags(model.id, {}).then(function (tags) {
        return controller.set("matchingTags", tags);
      }).then(function () {
        return controller.set("isLoading", false);
      });
    },
    actions: {
      search: function search(term) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this, _this._doSearch, resolve, reject, 500);
        });
      },
      supertag: function supertag(tag) {
        if (!tag.get("isUpdating")) {
          if (tag.get("supertag")) {
            var supertag = tag.get("supertags").findBy("tag_id", parseInt(tag.id));
            tag.removeSupertag(supertag);
          } else {
            tag.setSupertag(this.currentModel.id);
          }
        }
      }
    },
    _doSearch: function _doSearch(resolve, reject) {
      var _this2 = this;

      // To avoid trying to repopulate the store,
      // we remove all previously added tags
      this.store.unloadAll("tag");
      this.store.adapterFor("tag").searchTags(this.currentModel.id, {
        query: this.get("controller.query")
      }).then(function (tags) {
        _this2.set("controller.matchingTags", tags);

        resolve(tags);
      }, reject);
    }
  });

  _exports.default = _default;
});