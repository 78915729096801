define("innowaze-ember2/helpers/event-day", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          date = _ref2[0];

      if (typeof date === "undefined") {
        return;
      }

      return moment(date).locale("fr").format("dddd Do MMMM");
    }
  });

  _exports.default = _default;
});