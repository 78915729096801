define("innowaze-ember2/templates/users/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jA0F52fG",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"div\"],[11,\"class\",\"csv-import-module\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Import en lot d'utilisateurs\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"users.import.explanation\"],null],true],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"file-reader\",null,[[\"parseData\"],[\"parseData\"]]],false],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"small-block-grid-2 medium-block-grid-3 large-block-grid-4\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel\"],[11,\"data-cy\",\"user-import-item\"],[9],[0,\"\\n          \"],[7,\"ul\"],[9],[0,\"\\n            \"],[7,\"li\"],[9],[7,\"b\"],[9],[0,\"Email: \"],[10],[1,[22,1,[\"email\"]],false],[10],[0,\"\\n            \"],[7,\"li\"],[9],[7,\"b\"],[9],[1,[27,\"i18n\",[\"users.modules.main_info.first_name\"],null],false],[0,\": \"],[10],[1,[22,1,[\"first_name\"]],false],[10],[0,\"\\n            \"],[7,\"li\"],[9],[7,\"b\"],[9],[1,[27,\"i18n\",[\"users.modules.main_info.last_name\"],null],false],[0,\": \"],[10],[1,[22,1,[\"last_name\"]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"users\"],[[\"class\"],[\"button-light\"]],{\"statements\":[[0,\"      Annuler\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"button\"],[12,\"class\",[28,[\"button-primary right \",[27,\"if\",[[23,[\"usersEmpty\"]],\"hide\"],null]]]],[11,\"type\",\"submit\"],[3,\"action\",[[22,0,[]],\"createUsers\"]],[9],[0,\"\\n      Importer\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/import.hbs"
    }
  });

  _exports.default = _default;
});