define("innowaze-ember2/templates/users/modules/-persona", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f1FReM5M",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"persona\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"user-profile__persona\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"persona\",\"logo_url\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"user-profile__persona-picture big-rounded-picture\"],[9],[0,\"\\n        \"],[7,\"img\"],[12,\"src\",[27,\"concat\",[[23,[\"session\",\"apiUrl\"]],[23,[\"persona\",\"logo_url\"]]],null]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"user-profile__persona-title\"],[9],[0,\"\\n      Je suis \\n      \"],[1,[23,[\"persona\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"user-profile__persona-description\"],[9],[0,\"\\n      \"],[1,[23,[\"persona\",\"description\"]],true],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/modules/-persona.hbs"
    }
  });

  _exports.default = _default;
});