define("innowaze-ember2/controllers/challenges/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locale: Ember.inject.service(),
    reports: Ember.computed("model", function () {
      return [{
        id: 0,
        title: this.locale.t("reportings.statistics.challenge_activity")
      }, {
        id: 1,
        title: this.locale.t("reportings.statistics.ideas_production", {
          ideas: this.model.nameFor("ideas", "plural_idea")
        })
      }, {
        id: 5,
        title: this.locale.t("reportings.statistics.ideas_selection", {
          ideas: this.model.nameFor("ideas", "plural_idea")
        })
      }, {
        id: 6,
        title: this.locale.t("reportings.statistics.ideas_implementation", {
          ideas: this.model.nameFor("ideas", "plural_idea")
        })
      }, {
        id: 2,
        title: this.locale.t("reportings.statistics.users_connection")
      }, {
        id: 3,
        title: this.locale.t("reportings.statistics.users_profile")
      }, {
        id: 4,
        title: this.locale.t("reportings.statistics.votes_evolution")
      }];
    }),
    hasDaysRecords: Ember.computed("daysRecords", function () {
      return this.daysRecords.some(function (d) {
        return d.value;
      });
    }),
    reportDidChange: Ember.observer("selectedReport", "startDate", "endDate", function () {
      var _this = this;

      if (!this.routeIsReadyForAction) {
        return false;
      }

      this.set("dataLoaded", false);
      Ember.run.next(this, function () {
        _this.send("loadData");
      });
    })
  });

  _exports.default = _default;
});