define("innowaze-ember2/helpers/name-for", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nameFor = nameFor;
  _exports.default = void 0;

  // Invoke the nameFor function in a model.
  // This was made for the challenge model in mind
  function nameFor(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        model = _ref2[0],
        args = _ref2.slice(1);

    if (!model) {
      console.warn("calling name-for on", model, "with args", args);
      return "";
    }

    return model.nameFor.apply(model, (0, _toConsumableArray2.default)(args));
  }

  var _default = Ember.Helper.helper(nameFor);

  _exports.default = _default;
});