define("innowaze-ember2/serializers/survey", ["exports", "innowaze-ember2/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      return {
        survey: json
      };
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.available_answers) {
        payload.available_answers = payload.available_answers.map(function (answer) {
          return Ember.Object.create(answer);
        });
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});