define("innowaze-ember2/routes/personas/common", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel"], function (_exports, _cleanCurrentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function () {
          return _this._success();
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("personas");
      },
      filesUploaded: function filesUploaded(files) {
        var file = files.get("firstObject");
        this.currentModel.set("logo_id", file.id);
        this.currentModel.set("logo_url", file.file_url);
      }
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.errorMessage, response.errors);
    },
    _success: function _success() {
      this.transitionTo("personas");
      this.notifications.success(this.successMessage);
    }
  });

  _exports.default = _default;
});