define("innowaze-ember2/models/survey", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    active: _emberData.default.attr("boolean"),
    answers_count: _emberData.default.attr("number"),
    available_answers: _emberData.default.attr(),
    challenge_id: _emberData.default.attr("number"),
    chosen_answer: _emberData.default.attr("number"),
    good_answer: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    question: _emberData.default.attr("string"),
    voted: _emberData.default.attr("boolean"),
    vote: function vote(answer_id) {
      return this._callAdapter("vote", answer_id);
    }
  });

  _exports.default = _default;
});