define("innowaze-ember2/templates/news-departments/news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kObdzZd9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"headlines-title\"],[9],[1,[27,\"i18n\",[\"homepage.headlines\"],null],false],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"dashboard\"],[9],[0,\"\\n    \"],[1,[27,\"news-module\",null,[[\"news\",\"visible\",\"canEdit\",\"canPublish\"],[[23,[\"model\"]],10,[23,[\"session\",\"currentUser\",\"admin\"]],[23,[\"session\",\"currentUser\",\"admin\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/news-departments/news.hbs"
    }
  });

  _exports.default = _default;
});