define("innowaze-ember2/templates/challenges/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0tnpUAL7",
    "block": "{\"symbols\":[\"challenge\"],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"challenges.create_from_template\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"dashboard challenge-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"challenge\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"picture\"],[11,\"id\",\"firstStop\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"logo_url\"]]],null,{\"statements\":[[0,\"            \"],[7,\"img\"],[12,\"src\",[27,\"concat\",[[23,[\"session\",\"apiUrl\"]],[22,1,[\"logo_url\"]]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n          \"],[7,\"h3\"],[11,\"class\",\"title\"],[9],[0,\"\\n            \"],[1,[22,1,[\"name\"]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"description pedagogic\"],[9],[0,\"\\n            \"],[1,[22,1,[\"description\"]],true],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"meta\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"challenges.new_from_template\",[22,1,[\"id\"]]],[[\"class\"],[\"button-primary\"]],{\"statements\":[[0,\"            \"],[1,[27,\"i18n\",[\"challenges.create_from_this_template\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"challenges.new\"],[[\"class\"],[\"button-primary\"]],{\"statements\":[[0,\"      \"],[1,[27,\"i18n\",[\"challenges.create_from_scratch\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/create.hbs"
    }
  });

  _exports.default = _default;
});