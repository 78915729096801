define("innowaze-ember2/controllers/users/edit-password", ["exports", "innowaze-ember2/mixins/controllers/strongPassword"], function (_exports, _strongPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_strongPassword.default, {});

  _exports.default = _default;
});