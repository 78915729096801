define("innowaze-ember2/routes/challenge-juries/new", ["exports", "innowaze-ember2/routes/challenge-juries/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    model: function model(params) {
      return this.store.createRecord("jury", {
        challenge_id: params.challenge_id
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("users", []);

      if (model.get("users").length > 0) {
        controller.users.pushObjects(model.get("users").toArray());
      }
    },
    _success: function _success() {
      this.transitionTo("challengeJuries", this.currentModel.get("challenge_id"));
      this.notifications.success(this.locale.t("challenges.juries.created"));
    }
  });

  _exports.default = _default;
});