define("innowaze-ember2/components/modules/validation-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    isLoading: false,
    level1_expert_validation: null,
    level2_expert_validation: null,
    security_expert_validation: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._syncValidation();
    },
    hasRequiredExperts: Ember.computed.and("model.{primary_expert_name,level2_expert_name,security_expert_name}"),
    canValidLevel1: Ember.computed.or("model.is_primary_expert", "model.is_secondary_expert", "session.isChampion"),
    canValidLevel2: Ember.computed.or("model.is_level2_expert", "session.isChampion"),
    canValidSecurity: Ember.computed.or("model.is_security_expert", "session.isChampion"),
    canNotifyLevel2Expert: Ember.computed("session.isChampion", "model.is_primary_expert", "level1_expert_validation", function () {
      return (this.get("session.isChampion") || this.get("model.is_primary_expert")) && this.level1_expert_validation === 0;
    }),
    canSave: Ember.computed.or("canValidLevel1", "canValidLevel2", "canValidSecurity"),
    canPublish: Ember.computed("session.isChampion", "session.isAdmin", "model.{level1_expert_validation,level2_expert_validation,security_expert_validation}", function () {
      return this.get("session.isChampion") && (this.get("model.level1_expert_validation") === 1 || this.get("model.level2_expert_validation") === 1) && this.get("model.security_expert_validation") === 1;
    }),
    pendingSave: Ember.computed("model.{level1_expert_validation,level2_expert_validation,security_expert_validation}", "level1_expert_validation", "level2_expert_validation", "security_expert_validation", function () {
      return this.get("model.level1_expert_validation") !== this.level1_expert_validation || this.get("model.level2_expert_validation") !== this.level2_expert_validation || this.get("model.security_expert_validation") !== this.security_expert_validation;
    }),
    actions: {
      commentAdmin: function commentAdmin() {
        this.sendAction("onComment");
      },
      askForLevel1Validation: function askForLevel1Validation() {
        var _this = this;

        this.set("isLoading", true);
        this.model.askForValidation({
          type: 1
        }).then(function (_ref) {
          var datestamp = _ref.datestamp,
              ask_level2_validation_date = _ref.ask_level2_validation_date;

          _this.set("model.validation_date", datestamp);

          _this.notifications.success(_this.locale.t("ideas.modules.validation.expert_notify"));
        }).finally(function () {
          return _this.set("isLoading", false);
        });
      },
      askForLevel2Validation: function askForLevel2Validation() {
        var _this2 = this;

        this.set("isLoading", true);
        this.model.askForValidation({
          type: 2
        }).then(function (_ref2) {
          var datestamp = _ref2.datestamp,
              ask_level2_validation_date = _ref2.ask_level2_validation_date;

          _this2.set("model.ask_level2_validation_date", ask_level2_validation_date);

          _this2.notifications.success(_this2.model.level2_expert_name + " " + _this2.locale.t("ideas.modules.validation.level2_expert_notify"));
        }).finally(function () {
          return _this2.set("isLoading", false);
        });
      },
      updateValidation: function updateValidation() {
        var _this3 = this;

        if (!this.pendingSave) {
          return;
        }

        this.set("isLoading", true);
        this.model.updateExpertValidations({
          level1_expert_validation: this.level1_expert_validation,
          level2_expert_validation: this.level2_expert_validation,
          security_expert_validation: this.security_expert_validation
        }).then(function (data) {
          _this3._updateModel(data);

          _this3._syncValidation();

          _this3.notifications.success(_this3.locale.t("ideas.modules.validation.success"));
        }, function (errors) {
          return _this3._error(errors);
        }).finally(function () {
          return _this3.set("isLoading", false);
        });
      },
      resetValidation: function resetValidation() {
        var _this4 = this;

        this.set("isLoading", true);
        this.model.resetExpertValidations().then(function (data) {
          _this4._updateModel(data);

          _this4._syncValidation();
        }).finally(function () {
          return _this4.set("isLoading", false);
        });
      },
      publish: function publish() {
        var _this5 = this;

        if (!confirm(this.locale.t("ideas.show.challenge_changed_warning", {
          challenge: this.get("challenge.fullbath_challenge_name")
        }))) {
          return;
        }

        this.set("isLoading", true);
        this.model.changeChallenge(this.get("challenge.fullbath_challenge_id")).then(function () {
          _this5.notifications.success(_this5.locale.t("ideas.show.challenge_changed"));

          _this5.router.transitionTo("challenges.show", _this5.get("model.challenge_id"));

          _this5.model.unloadRecord();
        }, function (errors) {
          return _this5._error(errors);
        }).finally(function () {
          return _this5.set("isLoading", false);
        });
      }
    },
    _updateModel: function _updateModel(_ref3) {
      var level1_expert_validation = _ref3.level1_expert_validation,
          by_level1_expert = _ref3.by_level1_expert,
          level1_validation_date = _ref3.level1_validation_date,
          level2_expert_validation = _ref3.level2_expert_validation,
          by_level2_expert = _ref3.by_level2_expert,
          level2_validation_date = _ref3.level2_validation_date,
          security_expert_validation = _ref3.security_expert_validation,
          by_security_expert = _ref3.by_security_expert,
          security_validation_date = _ref3.security_validation_date,
          validation_date = _ref3.validation_date,
          ask_level2_validation_date = _ref3.ask_level2_validation_date;
      this.model.setProperties({
        level1_expert_validation: level1_expert_validation,
        level2_expert_validation: level2_expert_validation,
        security_expert_validation: security_expert_validation,
        by_level1_expert: by_level1_expert,
        level1_validation_date: level1_validation_date,
        by_level2_expert: by_level2_expert,
        level2_validation_date: level2_validation_date,
        by_security_expert: by_security_expert,
        security_validation_date: security_validation_date,
        validation_date: validation_date,
        ask_level2_validation_date: ask_level2_validation_date
      });
    },
    _syncValidation: function _syncValidation() {
      this.setProperties({
        level1_expert_validation: this.get("model.level1_expert_validation"),
        level2_expert_validation: this.get("model.level2_expert_validation"),
        security_expert_validation: this.get("model.security_expert_validation")
      });
    },
    _error: function _error(_ref4) {
      var errors = _ref4.errors;
      this.notifications.errorWithList(this.locale.t("modification_error"), errors);
    }
  });

  _exports.default = _default;
});