define("innowaze-ember2/sentry", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@sentry/browser", "@sentry/integrations/esm/ember", "innowaze-ember2/config/environment"], function (_exports, _objectSpread2, Sentry, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = startSentry;

  function startSentry() {
    Sentry.init((0, _objectSpread2.default)({}, _environment.default.sentry, {
      integrations: [new _ember.Ember()],
      beforeSend: function beforeSend(event, hint) {
        var error = hint.originalException; // ignore aborted route transitions from the Ember.js router

        if (error && error.name === "TransitionAborted") {
          return null;
        }

        return event;
      },
      beforeBreadcrumb: function beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category.startsWith("ui")) {
          var target = hint.event.target;
          var customName = target.dataset.sentry || target.name || target.tagName.toLowerCase() === "button" && target.innerText;
          var customMessage = "".concat(target.tagName.toLowerCase(), "(").concat(customName, ")");
          breadcrumb.message = customName ? customMessage : breadcrumb.message;
        }

        return breadcrumb;
      }
    }));
  }
});