define("innowaze-ember2/components/modules/benefits-module", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/components/modules/module"], function (_exports, _objectSpread2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var valueSum = function valueSum(acc, value) {
    return acc + parseFloat(value.amount || 0);
  };

  var _default = _module.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get("module.params.saving_records")) {
        var modelSavingRecords = this.get("model.saving_records");

        if (modelSavingRecords.length > 0) {
          this.set("savingSelectedYear", modelSavingRecords.sortBy("year").get("firstObject").year);
          this.set("saving_records", modelSavingRecords);
        } else {
          var year = new Date().getFullYear();
          this.set("savingSelectedYear", year);
          this.set("saving_records", this.get("module.params.saving_categories").reduce(function (acc, category) {
            return acc.concat([{
              category: category,
              year: year,
              amount: null
            }, {
              category: category,
              year: year + 1,
              amount: null
            }, {
              category: category,
              year: year + 2,
              amount: null
            }, {
              category: category,
              year: year + 3,
              amount: null
            }, {
              category: category,
              year: year + 4,
              amount: null
            }]);
          }, []));
        }
      }
    },
    available_benefits_type: Ember.computed("model.benefits_type.[]", function () {
      var benefitsTypes = this.get("model.benefits_type").map(function (id) {
        return id.toString();
      });
      return this.get("module.params.benefits_type").map(function (type) {
        return (0, _objectSpread2.default)({}, type, {
          selected: benefitsTypes.includes(type.id)
        });
      });
    }),
    availableYears: Ember.computed("savingSelectedYear", function () {
      var year = this.savingSelectedYear;

      if (!year) {
        return [];
      }

      return [year - 1, year, year + 1];
    }),
    displayedYears: Ember.computed("savingSelectedYear", function () {
      var year = this.savingSelectedYear;

      if (!year) {
        return [];
      }

      return [{
        selected: true,
        year: year
      }, {
        selected: false,
        year: year + 1
      }, {
        selected: false,
        year: year + 2
      }, {
        selected: false,
        year: year + 3
      }, {
        selected: false,
        year: year + 4,
        last: true
      }];
    }),
    groupedSavingRecords: Ember.computed("module.params.saving_categories", "saving_records", function () {
      var _this = this;

      return this.get("module.params.saving_categories").map(function (category) {
        return {
          header: category,
          records: _this.saving_records.filterBy("category", category).sortBy("year")
        };
      });
    }),
    computedSaving: Ember.computed("saving_records.@each.amount", function () {
      return this.saving_records.reduce(valueSum, 0);
    }),
    calculatedROI: Ember.computed("saving_records.@each.amount", "savingSelectedYear", "model.investments", function () {
      if (!this.saving_records) {
        return;
      }

      var year = this.savingSelectedYear;
      var savingRecords = this.saving_records;
      var modelInvestments = this.get("model.investments") || 0;
      var filteredRecordsCol1 = savingRecords.filterBy("year", year);
      var cumulatedCashFlowCol1 = filteredRecordsCol1.reduce(valueSum, -modelInvestments);
      var yearCashFlowCol1 = filteredRecordsCol1.reduce(valueSum, 0);
      var filteredRecordsCol2 = savingRecords.filterBy("year", year + 1);
      var cumulatedCashFlowCol2 = filteredRecordsCol2.reduce(valueSum, cumulatedCashFlowCol1);
      var yearCashFlowCol2 = filteredRecordsCol2.reduce(valueSum, 0);
      var filteredRecordsCol3 = savingRecords.filterBy("year", year + 2);
      var cumulatedCashFlowCol3 = filteredRecordsCol3.reduce(valueSum, cumulatedCashFlowCol2);
      var yearCashFlowCol3 = filteredRecordsCol3.reduce(valueSum, 0);
      var filteredRecordsCol4 = savingRecords.filterBy("year", year + 3);
      var cumulatedCashFlowCol4 = filteredRecordsCol4.reduce(valueSum, cumulatedCashFlowCol3);
      var yearCashFlowCol4 = filteredRecordsCol4.reduce(valueSum, 0);
      var filteredRecordsCol5 = savingRecords.filterBy("year", year + 4);
      var cumulatedCashFlowCol5 = filteredRecordsCol5.reduce(valueSum, cumulatedCashFlowCol4);
      var yearCashFlowCol5 = filteredRecordsCol5.reduce(valueSum, 0);
      var cumulCashFlowArray = [cumulatedCashFlowCol1, cumulatedCashFlowCol2, cumulatedCashFlowCol3, cumulatedCashFlowCol4, cumulatedCashFlowCol5];
      var yearCashFlowArray = [yearCashFlowCol1, yearCashFlowCol2, yearCashFlowCol3, yearCashFlowCol4, yearCashFlowCol5];
      var firstPositiveColumnIndex = cumulCashFlowArray.indexOf(cumulCashFlowArray.find(function (e) {
        return e >= 0;
      }));

      if (firstPositiveColumnIndex === 0) {
        return Math.round(modelInvestments / Math.abs(yearCashFlowArray[firstPositiveColumnIndex]) * 100) / 100;
      }

      if (firstPositiveColumnIndex > 0) {
        return Math.round((Math.abs(cumulCashFlowArray[firstPositiveColumnIndex - 1]) / yearCashFlowArray[firstPositiveColumnIndex] + firstPositiveColumnIndex) * 100) / 100;
      }

      return null;
    }),
    completedDidChange: Ember.on("init", Ember.observer("model.benefits_type.[]", "computedSaving", "model.turnover", "model.csr_explanation", "model.other_benefits_explanation", function () {
      var isNotCompleted = this.get("model.benefits_type").length === 0 || this.get("model.benefits_type").includes("1") && (!this.computedSaving || parseInt(this.computedSaving) === 0) || this.get("model.benefits_type").includes("2") && (!this.get("model.turnover") || parseInt(this.get("model.turnover")) === 0) || this.get("model.benefits_type").includes("3") && !this.get("model.csr_explanation") || this.get("model.benefits_type").includes("4") && !this.get("model.other_benefits_explanation");
      this.set("module.isNotCompleted", isNotCompleted);
    })),
    savingRecordsChanged: Ember.observer("saving_records.@each.{year,amount}", function () {
      this.model.setProperties({
        saving_records: this.saving_records,
        savings: this.computedSaving
      });
    }),
    calculatedROIChanged: Ember.observer("calculatedROI", function () {
      this.model.set("roi", this.calculatedROI);
    }),
    actions: {
      toggle: function toggle(type) {
        var model = this.model;
        var benefitsType = model.get("benefits_type").map(function (id) {
          return id.toString();
        });
        benefitsType = benefitsType.includes(type.id) ? benefitsType.filter(function (id) {
          return id !== type.id;
        }) : benefitsType.concat(type.id);
        model.setProperties({
          "benefits_type": benefitsType,
          benefitsTypeIsSavings: benefitsType.includes("1"),
          benefitsTypeIsTurnover: benefitsType.includes("2"),
          benefitsTypeIsCsr: benefitsType.includes("3"),
          benefitsTypeIsOther: benefitsType.includes("4")
        });
      },
      onChangeSavingYear: function onChangeSavingYear() {
        var year = this.savingSelectedYear;
        var savingRecords = this.saving_records;
        var oldYears = savingRecords.sortBy("year").mapBy("year").uniq();
        savingRecords.filterBy("year", oldYears[0]).setEach("year", year);
        savingRecords.filterBy("year", oldYears[1]).setEach("year", year + 1);
        savingRecords.filterBy("year", oldYears[2]).setEach("year", year + 2);
        savingRecords.filterBy("year", oldYears[3]).setEach("year", year + 3);
        savingRecords.filterBy("year", oldYears[4]).setEach("year", year + 4);
      }
    }
  });

  _exports.default = _default;
});