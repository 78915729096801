define("innowaze-ember2/routes/global-reporting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        workload: this.store.adapterFor("challenge").reportingWorkload(0),
        departments: this.store.adapterFor("challenge").departmentsStatistics(0)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        weekRecords: [model.workload.weekly_records],
        monthRecords: [model.workload.monthly_records],
        averageMonthRecords: [model.workload.average_monthly_records],
        departmentRecords: [model.departments]
      });
    }
  });

  _exports.default = _default;
});