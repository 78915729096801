define("innowaze-ember2/components/modules/past-future-module", ["exports", "ramda/src/pathOr", "innowaze-ember2/components/modules/module"], function (_exports, _pathOr, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    available_typologies: Ember.computed(function () {
      return (0, _pathOr.default)([], ["params", "typology"], this.challenge.getActiveIdeaModule("past_future"));
    })
  });

  _exports.default = _default;
});