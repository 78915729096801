define("innowaze-ember2/routes/challenges/edit-ideas-format", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveChallenge", "innowaze-ember2/utils/sortByPosition"], function (_exports, _toConsumableArray2, _cleanCurrentModel, _setCurrentChallenge, _saveChallenge, _sortByPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _setCurrentChallenge.default, _saveChallenge.default, {
    locale: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      // be sure that we have availableChallenges list if we need to set selectedChallenge on page load
      if (!this.get("session.availableChallenges")) {
        return this.store.findAll("challenge").then(function (challenges) {
          return _this.session.set("availableChallenges", challenges);
        });
      }
    },
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id, {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("sortedIdeas", model.get("ideas").sort(_sortByPosition.default));
      controller.set("sortedTools", model.get("tools").sort(_sortByPosition.default));

      if (model.get("mirror_challenge_id")) {
        controller.set("selectedChallenge", this.get("session.availableChallenges").findBy("id", model.get("mirror_challenge_id")));
      }

      if (model.get("fullbath_challenge_id")) {
        controller.set("fullbathChallenge", this.get("session.availableChallenges").findBy("id", model.get("fullbath_challenge_id")));
        controller.set("pediluve", true);
      }
    },
    actions: {
      save: function save() {
        var _this2 = this;

        if (!this._synchroAlert()) {
          return;
        }

        this.currentModel.set("mirror_challenge_id", this.get("controller.selectedChallenge.id"));

        if (this.get("controller.fullbathChallenge")) {
          this.currentModel.set("fullbath_challenge_id", this.get("controller.fullbathChallenge.id"));
        } else {
          this.currentModel.set("fullbath_challenge_id", null);
        }

        this._saveOrder();

        this.saveCurrentChallenge().then(function () {
          return _this2._success();
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      next: function next() {
        var _this3 = this;

        if (!this._synchroAlert()) {
          return;
        }

        this.currentModel.set("mirror_challenge_id", this.get("controller.selectedChallenge.id"));

        if (this.get("controller.fullbathChallenge")) {
          this.currentModel.set("fullbath_challenge_id", this.get("controller.fullbathChallenge.id"));
        } else {
          this.currentModel.set("fullbath_challenge_id", null);
        }

        this._saveOrder();

        this.saveForNextStep(5).then(function () {
          return _this3._stepSuccess();
        }, function (errors) {
          return _this3._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.setProperties({
          button_id: files[0].id,
          button_url: files[0].file_url
        });
      },
      deleteButtonImage: function deleteButtonImage() {
        this.currentModel.setProperties({
          button_id: null,
          button_url: null
        });
      },
      expand: function expand(module) {
        var custom = $("#" + module.id).parent();
        module.toggleProperty("unfolded");

        if (module.get("unfolded")) {
          custom.height(200);
        } else {
          custom.height(30);
        }
      },
      updateTemplate: function updateTemplate(module) {
        var _this4 = this;

        this.currentModel.updateIdeaModuleTemplate(module).then(function () {
          return _this4._onModuleTemplateSuccess();
        }, function (errors) {
          return _this4._onModuleTemplateError(errors);
        });
      },
      addNewIdea: function addNewIdea() {
        var value = this.get("controller.newIdea");

        if (!Ember.isBlank(value)) {
          this.controller.sortedIdeas.pushObject(Ember.Object.create({
            title: value,
            active: true,
            visible: false,
            control: false,
            is_HTML: true,
            team_only: false,
            position: 100
          }));
          this.controller.set("newIdea", null);
        }
      },
      addNewTool: function addNewTool() {
        var value = this.get("controller.newTool");

        if (!Ember.isBlank(value)) {
          this.controller.sortedTools.pushObject(Ember.Object.create({
            title: value,
            active: true,
            visible: true,
            control: false,
            category: this.locale.t("standard"),
            computed: false,
            computed_category: null,
            evaluation_state: null,
            computedDisable: this.get("controller.availableToolCategories").without(this.locale.t("standard")).length > 0,
            categoryDisable: this.get("controller.usedToolCategories").includes(this.locale.t("standard"))
          }));
          this.controller.set("newTool", null);
        }
      },
      sum: function sum(module) {
        module.toggleProperty("computed");
        module.set("computed_category", this.get("controller.enabledToolCategories").without(module.category)[0]);

        if (!module.computed) {
          module.set("computed_category", null);
        }
      },
      resetIdeasEvaluations: function resetIdeasEvaluations() {
        var _this5 = this;

        if (confirm(this.locale.t("challenges.edit_ideas.confirm_evaluations_reset"))) {
          this.currentModel.resetIdeasEvaluations().then(function () {
            return _this5.notifications.success(_this5.locale.t("challenges.edit_ideas.reset_evaluations_message"));
          }, function () {
            return _this5.notifications.error(_this5.locale.t("challenges.edit_ideas.reset_evaluations_error_message"));
          });
        }
      }
    },
    _saveOrder: function _saveOrder() {
      this.controller.sortedIdeas.forEach(function (item, index) {
        return item.set("position", index);
      });
      this.controller.sortedTools.forEach(function (item, index) {
        return item.set("position", index);
      });
      this.currentModel.setProperties({
        ideas: (0, _toConsumableArray2.default)(this.controller.sortedIdeas),
        tools: (0, _toConsumableArray2.default)(this.controller.sortedTools)
      });
    },
    _stepSuccess: function _stepSuccess() {
      this._success();

      this.transitionTo("challenges.edit_ideas_workflow", this.currentModel.id);
    },
    _synchroAlert: function _synchroAlert() {
      if (this.get("controller.selectedChallenge.id") === this.currentModel.get("mirror_challenge_id") || this.currentModel.get("mirror_challenge_id") === null) {
        return true;
      }

      return confirm(this.locale.t("challenges.edit_ideas.target_challenge_changed"));
    },
    _onModuleTemplateSuccess: function _onModuleTemplateSuccess() {
      this.notifications.success(this.locale.t("challenges.show.im_template_changed"));
    },
    _onModuleTemplateError: function _onModuleTemplateError(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.locale.t("error"), errors);
    }
  });

  _exports.default = _default;
});