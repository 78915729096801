define("innowaze-ember2/components/foundation-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Checkbox.extend({
    didInsertElement: function didInsertElement() {
      this.$(document).foundation(this.$());
    }
  });

  _exports.default = _default;
});