define("innowaze-ember2/routes/challenge-juries/common", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _cleanCurrentModel, _setCurrentChallenge, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _setCurrentChallenge.default, _selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("usersToDisplay", this.currentModel.get("users").toArray());
      controller.set("tagsToDisplay", this.currentModel.get("tags").toArray()); // there is always a first null element, so we take the second one

      if (this.session.currentChallenge.availableEvaluationStates.length === 2) {
        model.set("state", this.session.currentChallenge.availableEvaluationStates[1].id);
      } // Filter by domains


      this.store.query("domain", {
        challenge_id: model.get("challenge_id")
      }).then(function (domains) {
        controller.set("availableDomains", domains);
      }); // We get only clusters & Hubs as filters irrespective of what idea-module is selected (scope or light-scope)

      this.store.query("scope", {
        hubs: true,
        clusters: true
      }).then(function (scopes) {
        controller.set("availableScopes", scopes);
      });
      controller.set("ScopeTitle", "Clusters & Hubs"); // to be translated
    },
    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.save({
          adapterOptions: {
            challenge_id: this.currentModel.get("challenge_id")
          }
        }).then(function () {
          return _this._success();
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challengeJuries", this.currentModel.get("challenge_id"));
      }
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.locale.t("creation_error"), response.errors);
    }
  });

  _exports.default = _default;
});