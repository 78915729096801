define("innowaze-ember2/serializers/user-field-list", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/serializers/application"], function (_exports, _objectSpread2, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // This is needed because the server returns an object instead of an array
      var newPayload = Object.keys(payload).reduce(function (acc, key) {
        return acc.concat(payload[key].map(function (element) {
          return (0, _objectSpread2.default)({}, element, {
            type: key
          });
        }));
      }, []);
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});