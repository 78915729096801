define("innowaze-ember2/adapters/idea", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/utils/escapeForES", "innowaze-ember2/adapters/application"], function (_exports, _defineProperty2, _objectSpread3, _escapeForES, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    previousState: function previousState(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/previous_state.json"), "POST");
    },
    nextState: function nextState(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/next_state.json"), "POST");
    },
    changeState: function changeState(id, idea, event) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/change_state.json"), "POST", {
        data: {
          event: event
        }
      });
    },
    changeChallenge: function changeChallenge(id, idea, target) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/change_challenge.json"), "POST", {
        data: {
          target_id: target
        }
      });
    },
    updateColor: function updateColor(id, idea, color) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/update_color.json"), "POST", {
        data: {
          color: color
        }
      });
    },
    requestExpert: function requestExpert(id, idea, userId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/request_expert.json"), "POST", {
        data: {
          user_id: userId
        }
      });
    },
    toggleFollow: function toggleFollow(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/toggle_follow.json"), "POST");
    },
    toggleContribute: function toggleContribute(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/toggle_contribute.json"), "POST");
    },
    toggleReplicate: function toggleReplicate(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/toggle_replicate.json"), "POST");
    },
    addTeam: function addTeam(id, idea, userId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/add_team.json"), "POST", {
        data: {
          user_id: userId
        }
      });
    },
    convertToConcept: function convertToConcept(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/convert_to_concept.json"), "POST", {
        data: {
          idea: idea.serialize().idea
        }
      });
    },
    createDepartment: function createDepartment(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/create_department.json"), "POST", {
        data: {
          idea: idea.serialize().idea
        }
      });
    },
    updateDepartment: function updateDepartment(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/update_department.json"), "POST", {
        data: {
          idea: idea.serialize().idea
        }
      });
    },
    toggleIsMedian: function toggleIsMedian(id, idea, toolName, isMedian) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/toggle_is_median.json"), "POST", {
        data: {
          tool_name: toolName,
          is_median: isMedian
        }
      });
    },
    multipleEvaluations: function multipleEvaluations(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/multiple_evaluations.json"), "GET");
    },
    updateRating: function updateRating(id, idea, rating) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/update_rating.json"), "POST", {
        data: {
          rating: rating
        }
      });
    },
    reject: function reject(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/reject.json"), "POST");
    },
    addFile: function addFile(id, idea, fileId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/add_file.json"), "POST", {
        data: {
          file_id: fileId
        }
      });
    },
    sendNotification: function sendNotification(emails, ideas) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/notifications/").concat(this.pathForType("idea"), ".json"), "POST", {
        data: {
          emails: emails,
          idea_ids: ideas.mapBy("id")
        }
      });
    },
    destroyEvaluation: function destroyEvaluation(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/evaluations/").concat(id, ".json"), "DELETE");
    },
    synchronize: function synchronize(id, idea, mirror_target_domain_id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/synchronize_idea.json"), "POST", {
        data: {
          mirror_target_domain_id: mirror_target_domain_id
        }
      });
    },
    translate: function translate(id, idea, language) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/translate.json?language=").concat(language), "GET");
    },
    urlForQuery: function urlForQuery(query, modelName) {
      if (query.extended) {
        delete query.extended;
        return "".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(query.challenge_id, "/").concat(this.pathForType("idea"), "/extended_search.json");
      }

      return "".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(query.challenge_id, "/").concat(this.pathForType("idea"), "/search.json");
    },
    rawSearch: function rawSearch(challengeId) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // Build upon base options, and remove invalid values
      options = Object.keys(options).reduce(function (acc, key) {
        if (options[key] === null || typeof options[key] === "undefined" || options[key] === "") {
          delete acc[key];
          return acc;
        }

        return (0, _objectSpread3.default)({}, acc, (0, _defineProperty2.default)({}, key, key === "query" ? (0, _escapeForES.default)(options[key]) : options[key]));
      }, {
        list: true,
        page: 1,
        must: true,
        paginate: true,
        extended: true,
        order: "created_at"
      });
      var endpoint = options.extended ? "extended_search" : "search";
      delete options.extended;
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(challengeId, "/").concat(this.pathForType("idea"), "/").concat(endpoint, ".json?").concat($.param(options)), "GET");
    },
    updateTags: function updateTags(challengeId, ideas, tags) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(challengeId, "/").concat(this.pathForType("idea"), "/tags.json"), "PUT", {
        data: {
          idea_ids: ideas.mapBy("id"),
          tag_ids: tags.mapBy("id")
        }
      });
    },
    groupedPerSupertag: function groupedPerSupertag(challengeId, clusterId) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(challengeId, "/").concat(this.pathForType("idea"), "/grouped_per_supertag.json?type=").concat(clusterId), "GET").then(function (data) {
        return data.grouped_ideas.map(function (idea) {
          idea.challenge_id = data.challenge_id;
          idea.groupedIdeasCounter = idea.ideas.length;
          return Ember.Object.create(idea);
        });
      });
    },
    updateCurrentUserEvaluation: function updateCurrentUserEvaluation(id, idea, data) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/update_evaluation.json"), "POST", {
        data: data
      });
    },
    positionWithMedian: function positionWithMedian(challengeId, ideas, force, mode) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(challengeId, "/").concat(this.pathForType("idea"), "/position_with_median.json"), "PUT", {
        data: {
          ideas: ideas,
          force: force,
          mode: mode
        }
      });
    },
    updateEvaluations: function updateEvaluations(challengeId, ideas) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(challengeId, "/").concat(this.pathForType("idea"), "/update_evaluations.json"), "PUT", {
        data: {
          ideas: ideas
        }
      });
    },
    toggleDeployed: function toggleDeployed(id, idea, scope) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/toggle_deployed.json"), "POST", {
        data: {
          scope_id: scope.id
        }
      });
    },
    toggleActionCompleted: function toggleActionCompleted(id, idea, todo) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/toggle_action_completed.json"), "POST", {
        data: {
          todo_id: todo.id
        }
      });
    },
    askForValidation: function askForValidation(id, idea, params) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/ask_for_validation.json").concat(params ? "?type=" + params.type : ""), "POST");
    },
    updateExpertValidations: function updateExpertValidations(id, idea, validations) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/expert_validations.json"), "POST", {
        data: validations
      });
    },
    resetExpertValidations: function resetExpertValidations(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(idea.get("challenge_id"), "/").concat(this.pathForType("idea"), "/").concat(id, "/reset_expert_validation.json"), "POST");
    }
  });

  _exports.default = _default;
});