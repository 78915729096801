define("innowaze-ember2/models/department", ["exports", "ember-data", "innowaze-ember2/mixins/models/commentHandler", "innowaze-ember2/models/application"], function (_exports, _emberData, _commentHandler, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_commentHandler.default, {
    locale: Ember.inject.service(),
    address: _emberData.default.attr("string"),
    administrative_area_level_1: _emberData.default.attr(),
    administrative_area_level_2: _emberData.default.attr(),
    administrative_area_level_3: _emberData.default.attr(),
    administrative_area_level_4: _emberData.default.attr(),
    administrative_area_level_5: _emberData.default.attr(),
    admins: _emberData.default.hasMany("users", {
      async: false
    }),
    admin_ids: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    awards: _emberData.default.attr(),
    baseline: _emberData.default.attr("string"),
    business_model: _emberData.default.attr(),
    canEdit: _emberData.default.attr("boolean"),
    canPublish: _emberData.default.attr("boolean"),
    challenges_count: _emberData.default.attr("number"),
    city: _emberData.default.attr("string"),
    comments: _emberData.default.hasMany("comment", {
      async: false
    }),
    company_contacts: _emberData.default.attr(),
    country: _emberData.default.attr("string"),
    creation_date: _emberData.default.attr(),
    crm: _emberData.default.attr(),
    customer: _emberData.default.attr("boolean"),
    department_fields: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    department_type: _emberData.default.attr("number"),
    description: _emberData.default.attr("string"),
    desired_partnership_description: _emberData.default.attr(),
    development_stage: _emberData.default.attr(),
    differentiation: _emberData.default.attr(),
    displayed_main_customers: _emberData.default.attr(),
    displayed_investors: _emberData.default.attr(),
    displayed_geographical_presence: _emberData.default.attr(),
    displayed_fund_raising: _emberData.default.attr(),
    documents: _emberData.default.attr(),
    email: _emberData.default.attr(),
    email_domains: _emberData.default.attr(),
    fund_raising: _emberData.default.attr(),
    funded_companies: _emberData.default.attr(),
    geographical_presence: _emberData.default.attr(),
    investment_ticket: _emberData.default.attr(),
    investment_type: _emberData.default.attr(),
    investor_type: _emberData.default.attr(),
    investors: _emberData.default.attr(),
    job_opportunities: _emberData.default.attr(),
    lat: _emberData.default.attr(),
    last_turnover: _emberData.default.attr(),
    lng: _emberData.default.attr(),
    logo_url: _emberData.default.attr("string"),
    logo_native_url: _emberData.default.attr("string"),
    logo_id: _emberData.default.attr(),
    main_customers: _emberData.default.attr(),
    market_description: _emberData.default.attr(),
    naf: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    needs_coverage: _emberData.default.attr(),
    offering: _emberData.default.attr(),
    phone: _emberData.default.attr("string"),
    people_count: _emberData.default.attr(),
    previous_partnership: _emberData.default.attr(),
    previous_partnership_description: _emberData.default.attr(),
    published: _emberData.default.attr("boolean"),
    sector: _emberData.default.attr(),
    slide_url: _emberData.default.attr(),
    skill_ids: _emberData.default.attr(),
    skills: _emberData.default.attr(),
    siren: _emberData.default.attr("string"),
    sponsor: _emberData.default.attr("boolean"),
    street_number: _emberData.default.attr("string"),
    tags: _emberData.default.hasMany("tag", {
      async: false
    }),
    technologies: _emberData.default.attr(),
    technology_ids: _emberData.default.attr(),
    users: _emberData.default.hasMany("users", {
      async: false
    }),
    users_count: _emberData.default.attr("number"),
    value_proposition: _emberData.default.attr(),
    video_presentation_id: _emberData.default.attr(),
    web_url: _emberData.default.attr(),
    zipcode: _emberData.default.attr("string"),
    addUser: function addUser(users) {
      return this._callAdapter("addUser", users);
    },
    removeUser: function removeUser(users) {
      return this._callAdapter("removeUser", users);
    },
    updateCRM: function updateCRM() {
      return this._callAdapter("updateCRM");
    },
    mainCustomers: Ember.computed("main_customers", function () {
      if (this.main_customers) {
        return this.main_customers.split(",");
      }

      return null;
    }),
    geographicalPresence: Ember.computed("geographical_presence", function () {
      if (this.geographical_presence) {
        return this.geographical_presence.split(",");
      }

      return null;
    }),
    fundedCompanies: Ember.computed("funded_companies", function () {
      if (this.funded_companies) {
        return this.funded_companies.split(",");
      }

      return null;
    }),
    mainInvestors: Ember.computed("investors", function () {
      if (this.investors) {
        return this.investors.split(",");
      }

      return null;
    }),
    turnoverRange: Ember.computed("last_turnover", function () {
      if (this.last_turnover > 0) {
        var thresholdScale = d3.scale.threshold().domain([0, 500000, 2000000, 10000000, 50000000, 200000000, 500000000, 1500000000]).range(["undefined", "< 0,5 M€", "0,5 M€ - 2 M€", "2 M€ - 10 M€", "10 M€ - 50 M€", "50 M€ - 200 M€", "200 M€ - 500 M€", "500 M€ - 1 500 M€", "> 1 500 M€"]);
        return thresholdScale(this.last_turnover);
      }

      return null;
    }),
    peopleRange: Ember.computed("people_count", function () {
      var thresholdScale = d3.scale.threshold().domain([0, 10, 20, 50, 100, 200, 250]).range(["undefined", "< 10", "10-20", "20-50", "50-100", "100-200", "200-250", "> 250"]);
      return thresholdScale(this.people_count);
    })
  });

  _exports.default = _default;
});