define("innowaze-ember2/helpers/i18n-event", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    locale: Ember.inject.service(),
    handleLocaleChange: Ember.observer("locale.currentLocale", function () {
      this.recompute();
    }),
    compute: function compute(params) {
      var _params = (0, _slicedToArray2.default)(params, 3),
          eventId = _params[0],
          nameForIdeas = _params[1],
          nameForConcepts = _params[2];

      return Ember.String.capitalize(this.locale.t("digest_events." + eventId, {
        an_idea: nameForIdeas,
        a_concept: nameForConcepts
      }));
    }
  });

  _exports.default = _default;
});