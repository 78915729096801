define("innowaze-ember2/components/toggable-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["toggable-element"],
    hidden: true,
    actions: {
      toggle: function toggle() {
        this.toggleProperty("hidden");
      }
    }
  });

  _exports.default = _default;
});