define("innowaze-ember2/components/autocomplete-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    didInsertElement: function didInsertElement() {
      var keywords = this.keywords;
      this.$().textcomplete([{
        match: /(^|\s)#(\w*)$/,
        replace: function replace(value) {
          return "$1#".concat(value, " ");
        },
        search: function search(term, callback) {
          return callback(keywords.filter(function (keyword) {
            return keyword.indexOf(term) === 0;
          }));
        }
      }]);
    }
  });

  _exports.default = _default;
});