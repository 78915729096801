define("innowaze-ember2/routes/departments", ["exports", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var options = {
        query: "",
        order: "created_at",
        paginate: true,
        page: 1
      };
      return this.store.query("department", options).then(function (result) {
        _this.set("totalResults", result.get("meta.total_results"));

        return result;
      });
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      controller.setProperties({
        matchingDepartments: model.toArray(),
        totalResults: this.totalResults,
        filtersHidden: true,
        page: 1,
        tags: [],
        types: [],
        stages: [],
        skills: [],
        technologies: [],
        sectors: [],
        tagsToDisplay: [],
        order: "created_at",
        displayMode: "block-list"
      });
      this.store.findAll("department-module").then(function (active_modules) {
        controller.set("activeModules", active_modules);
        controller.activeModules.forEach(function (module) {
          controller.set("available_".concat(module.get("name")), []);

          if (module.get("params.list")) {
            module.get("params.list").forEach(function (e) {
              if (e.id > 0) {
                e.title = _this2.locale.t("departments.".concat(module.get("name"), ".").concat(e.name));
                controller.get("available_".concat(module.get("name"))).pushObject(Ember.Object.create(e));
              }
            });
          }
        });
      });

      if (this.get("session.currentUser.admin")) {
        this.store.adapterFor("email-template").getTemplates().then(function (templates) {
          controller.set("availableEmailTemplates", templates);
        }).catch(function () {});
      }
    },
    actions: {
      deleteDepartment: function deleteDepartment(department) {
        var _this3 = this;

        if (!confirm(this.locale.t("departments.confirm_deleting"))) {
          return;
        }

        department.destroyRecord().then(function () {
          _this3.controller.set("matchingDepartments", _this3.get("controller.matchingDepartments").without(department));

          _this3.notifications.success(_this3.locale.t("departments.removed"));
        });
      },
      showFilters: function showFilters() {
        this.controller.toggleProperty("filtersHidden");
      },
      setDisplayMode: function setDisplayMode(mode) {
        this.controller.set("displayMode", mode);
      },
      loadMore: function loadMore() {
        this.controller.incrementProperty("page");
        this.controller.set("isLoadingMore", true);

        this._loadData();
      },
      search: function search() {
        this.controller.setProperties({
          isLoading: true,
          page: 1
        });

        this._loadData();
      },
      sort: function sort(criteria) {
        this.controller.setProperties({
          order: criteria,
          matchingDepartments: [],
          page: 1,
          isLoading: true
        });
        this.toggleProperty("controller.asc");

        this._loadData();
      },
      setFilter: function setFilter(filter, value) {
        this.set("controller.".concat(filter), value);
        this.controller.setProperties({
          isLoading: true,
          page: 1
        });

        this._loadData();
      },
      sendEmail: function sendEmail(title, value) {
        var _this4 = this;

        var options = this.getOptions();
        var all_members = this.controller.all_members;
        this.store.adapterFor("department").sendEmail(title, value, all_members, options).then(function () {
          return _this4.notifications.success(_this4.locale.t("challenges.emailings.sent_message"));
        });
      }
    },
    _loadData: function _loadData() {
      var _this5 = this;

      var options = this.getOptions();
      return this.store.query("department", options).then(function (result) {
        if (_this5.get("controller.isLoadingMore")) {
          _this5.get("controller.matchingDepartments").pushObjects(result.toArray());

          _this5.controller.set("isLoadingMore", false);
        } else {
          _this5.controller.setProperties({
            matchingDepartments: result.toArray(),
            isLoading: false
          });
        }

        _this5.set("controller.totalResults", result.get("meta.total_results"));
      });
    },
    getOptions: function getOptions() {
      var options = {
        paginate: true
      };

      if (this.get("controller.query")) {
        options.query = this.get("controller.query");
      }

      if (this.get("controller.order")) {
        options.order = this.get("controller.order");
      }

      if (this.get("controller.tags")) {
        options.tags = this.get("controller.tags").mapBy("name");
      }

      if (this.get("controller.types")) {
        options.types = this.get("controller.types").mapBy("id");
      }

      if (this.get("controller.stages")) {
        options.stages = this.get("controller.stages").mapBy("id");
      }

      if (this.get("controller.skills")) {
        options.skills = this.get("controller.skills").mapBy("id");
      }

      if (this.get("controller.technologies")) {
        options.technologies = this.get("controller.technologies").mapBy("id");
      }

      if (this.get("controller.sectors")) {
        options.sectors = this.get("controller.sectors").mapBy("id");
      }

      if (this.get("controller.page")) {
        options.page = this.get("controller.page");
      }

      if (this.get("controller.asc")) {
        options.asc = this.get("controller.asc");
      }

      return options;
    }
  });

  _exports.default = _default;
});