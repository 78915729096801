define("innowaze-ember2/models/user-field-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created_at: _emberData.default.attr("string"),
    element: _emberData.default.attr("string"),
    position: _emberData.default.attr("number"),
    type: _emberData.default.attr("string"),
    updated_at: _emberData.default.attr("string"),
    user_module_field_id: _emberData.default.attr("number")
  });

  _exports.default = _default;
});