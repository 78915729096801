define("innowaze-ember2/templates/components/market-item-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UJKn/5fS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row collapse\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"columns small-10\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\"],[[23,[\"computedValue\"]],[23,[\"item\",\"name\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"columns small-2\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"postfix\"],[9],[0,\"\\n      \"],[1,[23,[\"item\",\"unit\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/market-item-input.hbs"
    }
  });

  _exports.default = _default;
});