define("innowaze-ember2/routes/debates/common-show", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/polling", "innowaze-ember2/mixins/routes/saveAsImage", "innowaze-ember2/mixins/routes/highlightComments"], function (_exports, _setCurrentChallenge, _polling, _saveAsImage, _highlightComments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _this = void 0;

  var handleCommentError = function handleCommentError() {
    _this.set("controller.saveDisabled", false);

    _this.notifications.error(_this.locale.t("comments.select_message"));
  };

  var _default = Ember.Route.extend(_polling.default, _saveAsImage.default, _setCurrentChallenge.default, _highlightComments.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    afterModel: function afterModel(_ref) {
      var debate = _ref.debate;

      if (debate.get("isMultipleChoices") && debate.get("answers_order")) {
        debate.get("available_answers").forEach(function (answer) {
          answer.set("selected", debate.get("answers_order").includes(parseInt(answer.get("id"))));
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.challenge) {
        this.session.set("currentChallenge", model.challenge);
      }

      if (model.challenge.get("deepl_translation")) {
        var lang = model.challenge.get("deepl_languages").findBy("id", this.get("locale.currentLocale"));

        if (lang && model.challenge.get("translation_coupling")) {
          controller.set("targetLanguage", lang);
          Ember.run.next(this, function () {
            this.send("translate", lang.id);
          });
        } else {
          controller.set("targetLanguage", {
            name: "None",
            id: "-"
          });
        }

        controller.setProperties({
          relatedIdeas: [],
          availableTranslationLanguages: model.challenge.get("deepl_languages"),
          translatedQuestion: null,
          translatedExplanation: null,
          translatedComments: null
        });
      }

      controller.reopen({
        histogram_height: Ember.computed("model.debate.sortedAnswersRanking", function () {
          return this.get("model.debate.sortedAnswersRanking").length * 48;
        }),
        chartIsDisplayed: Ember.computed.or("model.debate.hasVoted", "viewChart"),
        newCommentChanged: Ember.observer("newComment", function () {
          if (this.get("model.debate.type") === "InsightDebate") {
            this.setProperties({
              newCommentSize: 300 - (this.newComment ? this.newComment.length : 0),
              newComment: this.newComment ? this.newComment.substring(0, 300) : null
            });
          }
        }),
        translationChanged: Ember.observer("translatedComments", function () {
          var _this2 = this;

          if (this.translatedComments) {
            controller.set("canVerbatim", false);
            this.get("model.debate.comments").forEach(function (comment) {
              var translation = _this2.translatedComments.findBy("id", parseInt(comment.get("id")));

              if (translation) {
                comment.setProperties({
                  translatedContent: translation.content,
                  language: translation.language
                });
              } else {
                comment.set("translatedContent", comment.get("content"));
              }
            });
          }
        })
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          loopIsRunning: false,
          saveDisabled: false,
          newComment: null,
          commentParent: null,
          choice: null,
          picture_id: null,
          picture_url: null,
          picture_name: null,
          remote_url: null,
          commentFormVisible: false
        });
      }
    },
    actions: {
      toggleVisibleInWidget: function toggleVisibleInWidget(debate) {
        debate.toggleVisibleInWidget().then(function () {
          debate.toggleProperty("visible_in_widget");
        });
      },
      toggleVerbatim: function toggleVerbatim() {
        this.set("controller.translatedComments", null);
        this.set("controller.targetLanguage", {
          name: "None",
          id: "-"
        });
        this.controller.toggleProperty("canVerbatim");
      },
      deleteOpinion: function deleteOpinion() {
        var _this3 = this;

        this.store.adapterFor("concept").deleteOpinion(this.currentModel.debate.id, this.currentModel.debate.get("opinion_id")).then(function () {
          _this3._modelRefresh();
        });
      },
      comment: function comment() {
        this._createComment();
      },
      agree: function agree() {
        this.controller.set("choice", 1);

        this._createComment();
      },
      disagree: function disagree() {
        this.controller.set("choice", -1);

        this._createComment();
      },
      mixed: function mixed() {
        var _this4 = this;

        this.controller.set("choice", 0);
        this.store.createRecord("opinion", {
          debate_id: parseInt(this.currentModel.debate.id),
          choice: 0
        }).save().then(function (result) {
          _this4.currentModel.debate.incrementProperty("opinions_count");

          _this4.currentModel.debate.setProperties({
            current_user_has_already_commented: true,
            balanced: true,
            opinion_id: result.id
          });

          _this4.notifications.success(_this4.locale.t("comments.taken_into_account"));
        });
      },
      // Survey debate comments filtering
      filterAnswers: function filterAnswers(answer) {
        // Highlight current selected filter
        this.currentModel.debate.get("available_answers").setEach("selected", false);
        answer.set("selected", true);
        this.set("controller.nothingSelected", false); // Only show matching threads

        this.currentModel.debate.get("comments").setEach("hidden", false);
        this.currentModel.debate.get("comments").rejectBy("answer_id", answer.id).setEach("hidden", true);
      },
      clearFilter: function clearFilter(answer) {
        this.currentModel.debate.get("available_answers").setEach("selected", false);
        this.set("controller.nothingSelected", true);
        this.currentModel.debate.get("comments").setEach("hidden", false);
      },
      toggleViewChart: function toggleViewChart() {
        this.controller.toggleProperty("viewChart");
      },
      toggleComment: function toggleComment(comment) {
        if (comment.canManage) {
          comment.set("updating", true);
        }
      },
      updateComment: function updateComment(comment) {
        if (comment.get("canManage")) {
          comment.save().then(function () {
            comment.set("updating", false);
          });
        }
      },
      deleteComment: function deleteComment(comment) {
        var _this5 = this;

        if (!confirm(this.locale.t("comments.deletion_confirmation"))) {
          return;
        }

        comment.deleteRecord();

        if ("ClaimDebate" === this.currentModel.debate.get("type")) {
          this.currentModel.debate.decrementProperty("opinions_count");
        }

        comment.save().then(function () {
          if (comment.get("is_my_comment") && comment.get("parent_id") === null) {
            _this5.currentModel.debate.set("current_user_has_already_commented", false);
          }

          _this5.currentModel.debate.get("comments").removeObject(comment);

          _this5.currentModel.debate.decrementProperty("comments_count");

          _this5.notifications.success(_this5.locale.t("comments.comment_deleted"));

          _this5._modelRefresh();
        });
      },
      cancelComment: function cancelComment() {
        this.controller.setProperties({
          newComment: null,
          commentFormVisible: false,
          saveCommentDisabled: false
        });
      },
      translate: function translate(lang) {
        var _this6 = this;

        var language = this.currentModel.challenge.get("deepl_languages").findBy("id", lang);
        this.controller.set("targetLanguage", language);
        this.currentModel.debate.translate(lang).then(function (result) {
          if (result && result.status) {
            _this6.controller.setProperties({
              translatedQuestion: result.question,
              translatedExplanation: result.explanation,
              translatedComments: result.comments
            });
          } else {
            _this6.controller.setProperties({
              translatedQuestion: null,
              translatedExplanation: null,
              translatedComments: null
            });
          }
        });
      },
      rate: function rate(comment, way) {
        comment.rate(way).then(function (result) {
          comment.setProperties({
            score: result.score,
            score_string: result.score_string
          });
        });
      },
      sort: function sort(criteria) {
        this.currentModel.debate.set("comments", this.currentModel.debate.get("comments").sortBy(criteria).reverseObjects());
      },
      filesUploaded: function filesUploaded(files) {
        this.controller.setProperties({
          picture_id: files[0].id,
          picture_url: files[0].file_url,
          picture_name: files[0].file_name,
          remote_url: files[0].remote_url
        });
      },
      updateFiles: function updateFiles(comment, files) {
        comment.setProperties({
          picture_id: files[0].id,
          picture_url: files[0].file_url,
          picture_name: files[0].file_name,
          remote_url: files[0].remote_url
        });
      },
      removeUpload: function removeUpload() {
        this.controller.setProperties({
          picture_id: null,
          picture_url: null,
          picture_name: null,
          remote_url: null
        });
      },
      showCommentForm: function showCommentForm(parent) {
        this.controller.setProperties({
          commentFormVisible: true,
          commentParent: parent
        });
        this.send("removeUpload");
        $("html, body").animate({
          scrollTop: $(".comment-list").offset().top + $(".comment-list").outerHeight()
        });
      },
      updateSortableAnswers: function updateSortableAnswers() {
        this.currentModel.debate.get("available_answers").forEach(function (answer, index) {
          answer.set("position", index + 1);
        });
        this.currentModel.debate.set("answers_order", this.currentModel.debate.get("sortedAnswers").map(function (answer) {
          return answer.id;
        }));
      },
      tagClicked: function tagClicked(tag) {
        this._tagClicked(tag, this.currentModel.debate.get("comments"), this.controller);
      },
      resetClicked: function resetClicked() {
        this._resetClicked(this.currentModel.debate.get("comments"), this.controller);
      },
      addHashtags: function addHashtags() {
        var _this7 = this;

        if (confirm("Are you sure to generate the hashtags for this debate ?")) {
          this.currentModel.debate.addHashtags().then(function () {
            _this7._modelRefresh();

            _this7.notifications.success(_this7.locale.t("debates.show.hashtags_added"));
          });
        }
      },
      clearVerbatims: function clearVerbatims() {
        var _this8 = this;

        if (confirm("Are you sure to clear all verbatims for this debate ?")) {
          this.currentModel.debate.clearVerbatims().then(function () {
            _this8._modelRefresh();

            _this8.notifications.success(_this8.locale.t("debates.show.verbatims_cleared"));
          });
        }
      }
    },
    _modelRefresh: function _modelRefresh() {
      var _this9 = this;

      return this.store.findRecord("debate", this.currentModel.debate.id, {
        adapterOptions: {
          debatable_id: this.currentModel.debate.get("debatable_id"),
          debatable_type: this.currentModel.debate.get("debatable_type")
        },
        reload: true
      }).then(function (debate) {
        debate.rollbackAttributes();

        _this9.controller.set("model.debate", debate);
      });
    },
    _createComment: function _createComment() {
      var _this10 = this;

      this.set("controller.saveDisabled", true);
      var comment = this.store.createRecord("comment", {
        commentable_type: this.currentModel.debate.get("type"),
        commentable_id: this.currentModel.debate.get("id"),
        content: this.get("controller.newComment"),
        parent_id: this.get("controller.commentParent.id"),
        picture_id: this.get("controller.picture_id"),
        is_my_comment: true
      });

      if (comment.get("parent_id")) {
        comment.save().then(function (comment) {
          return _this10._success(comment);
        }, function (errors) {
          return _this10._error(errors);
        });
        return;
      }

      var debateType = comment.get("commentable_type");
      var canComment = !this.currentModel.debate.get("current_user_has_already_commented") || this.get("session.isModerator");

      if (debateType === "ClaimDebate") {
        comment.set("opinion_attributes", {
          choice: this.get("controller.choice")
        });
      } else if (debateType === "CardsSortingDebate") {
        if (!this.currentModel.debate.get("answers_order")) {
          handleCommentError();
          return;
        }

        comment.set("answer_attributes", {
          available_answer_id: this.currentModel.debate.get("available_answers.firstObject.id"),
          answers_order: this.currentModel.debate.get("answers_order")
        });
      } else if (debateType === "SurveyDebate" && canComment) {
        if (!this.get("controller.choice") && !this.get("session.isModerator")) {
          handleCommentError();
          return;
        }

        comment.set("answer_attributes", {
          available_answer_id: this.get("controller.choice")
        });
      } else if (debateType === "MultipleChoicesDebate" && canComment) {
        if (this.currentModel.debate.get("available_answers").filterBy("selected", true).length > 0) {
          comment.set("answer_attributes", {
            available_answer_id: this.currentModel.debate.get("available_answers.firstObject.id"),
            answers_order: this.currentModel.debate.get("available_answers").filterBy("selected", true).mapBy("id")
          });
        } else if (!this.get("session.isModerator")) {
          handleCommentError();
          return;
        }
      }

      comment.save().then(function (comment) {
        return _this10._success(comment);
      }, function (errors) {
        return _this10._error(errors);
      });
    },
    _success: function _success(comment) {
      var _this11 = this;

      this.controller.setProperties({
        saveDisabled: false,
        newComment: null,
        commentParent: null,
        choice: null,
        picture_id: null,
        picture_url: null,
        picture_name: null,
        remote_url: null,
        commentFormVisible: false
      });
      this.currentModel.debate.set("current_user_has_already_commented", true);

      if ("ClaimDebate" === this.currentModel.debate.get("type")) {
        this.currentModel.debate.incrementProperty("opinions_count");
      }

      this.currentModel.debate.incrementProperty("comments_count");
      this.currentModel.debate.get("comments").pushObject(comment);
      this.notifications.success(this.locale.t("comments.added"));

      this._modelRefresh().then(function () {
        if (!_this11.currentModel.debate.get("isMultipleChoices")) {
          return;
        }

        if (_this11.currentModel.debate.get("answers_order")) {
          _this11.currentModel.debate.get("available_answers").forEach(function (answer) {
            answer.set("selected", _this11.currentModel.debate.get("answers_order").includes(parseInt(answer.get("id"))));
          });
        }
      });
    },
    _error: function _error(_ref2) {
      var errors = _ref2.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("ideas.show.comment_message_error"), errors);
    }
  });

  _exports.default = _default;
});