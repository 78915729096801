define("innowaze-ember2/helpers/linkify", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkify = linkify;
  _exports.default = void 0;

  function linkify(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        content = _ref2[0],
        target = _ref2[1];

    if (!content) {
      return Ember.String.htmlSafe("");
    }

    var windowTarget = target === "blank" ? "_blank" : "_self";
    var urlRegex = /(?:^|\s)(["'])?(?:(?:(?:(?:https?|ftp|\w):)?\/\/)|(?:www.))(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?\1/ig;
    content = content.replace(/(\r\n|\n|\r)/gm, " <br /> ");
    content = content.replace(urlRegex, function (string) {
      return " <a href=\"".concat(string.trim(), "\" target=\"").concat(windowTarget, "\">").concat(string.trim(), "</a>");
    });
    return Ember.String.htmlSafe(content);
  }

  var _default = Ember.Helper.helper(linkify);

  _exports.default = _default;
});