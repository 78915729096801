define("innowaze-ember2/controllers/challenges/experts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isSavingExpert: false,
    sortedSolutions: Ember.computed("organizationFilter", "activityFilter", "solutionFilter", "onlyUnassignedFilter", "model.experts", function () {
      var solutions = this.model.experts;

      if (this.organizationFilter) {
        solutions = solutions.filterBy("organization", this.organizationFilter);
      }

      if (this.activityFilter) {
        solutions = solutions.filterBy("activity", this.activityFilter);
      }

      if (this.solutionFilter) {
        solutions = solutions.filterBy("name", this.solutionFilter);
      }

      if (this.onlyUnassignedFilter) {
        solutions = solutions.filterBy("hasRequiredExperts", false);
      }

      return solutions.sortBy("organization", "activity", "name");
    })
  });

  _exports.default = _default;
});