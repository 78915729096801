define("innowaze-ember2/templates/components/reporting-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WMxV3PBz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"medium reveal-modal\"],[11,\"data-reveal\",\"\"],[11,\"data-options\",\"close_on_background_click:false\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-reveal-modal\"],[3,\"action\",[[22,0,[]],\"closeModalWindow\"]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"text-center\"],[9],[1,[21,\"chartTitle\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"available_types\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"medium-4 columns\"],[9],[0,\"\\n        \"],[1,[27,\"ember-selectize\",null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\",\"selection\",\"select-value\"],[[23,[\"available_types\"]],\"content.id\",\"content.title\",[27,\"i18n\",[\"departments.department_type\"],null],[23,[\"type\"]],\"setCloudType\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"dataLoaded\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"words-cloud\",null,[[\"data\"],[[23,[\"data\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"loader\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"action-button-group\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"class\",[28,[\"button-grey \",[27,\"if\",[[23,[\"actionInProgress\"]],\"hide\"],null]]]],[3,\"action\",[[22,0,[]],\"openModalWindow\"]],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"reportings.tags_cloud\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/reporting-modal.hbs"
    }
  });

  _exports.default = _default;
});