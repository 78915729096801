define("innowaze-ember2/components/document-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleDocumentEditForm: function toggleDocumentEditForm() {
        this.sendAction("toggleDocumentEditForm");
      },
      saveDocument: function saveDocument() {
        this.sendAction("saveDocument");
      }
    }
  });

  _exports.default = _default;
});