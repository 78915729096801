define("innowaze-ember2/routes/challenges/quiz", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce("afterRender", this, function () {
        $("#survey_".concat(model.get("lastSurvey"))).show();
        $("#case_".concat(model.get("lastSurvey"))).addClass("selected");
      });
    },
    actions: {
      reloadChallenge: function reloadChallenge(id) {
        var _this = this;

        this.store.findRecord("challenge", this.currentModel.id, {
          reload: true
        }).then(function (challenge) {
          _this.controller.set("model", challenge);

          Ember.run.scheduleOnce("afterRender", _this, function () {
            $("#survey_".concat(id)).show();
          });
        });
      },
      displaySurvey: function displaySurvey(id) {
        $("li.quiz").hide();
        $("li.survey-case").removeClass("selected");
        $("#survey_".concat(id)).show();
        $("#case_".concat(id)).addClass("selected");
      }
    }
  });

  _exports.default = _default;
});