define("innowaze-ember2/templates/surveys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lguuv+Bn",
    "block": "{\"symbols\":[\"survey\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"i18n\",[\"surveys.index.title\"],null],false],[0,\" \"],[1,[21,\"name\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"fat-list edit-survey-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"surveys\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"banner\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"surveys.edit\",[23,[\"model\",\"id\"]],[22,1,[\"id\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,1,[\"name\"]],false],[0,\"\\n            \"],[7,\"i\"],[12,\"class\",[28,[\"fa \",[27,\"if\",[[22,1,[\"active\"]],\"fa-eye\",\"fa-eye-slash\"],null]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"br\"],[9],[10],[0,\"\\n          \"],[1,[22,1,[\"question\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"delete-survey\"],[3,\"action\",[[22,0,[]],\"delete\",[22,1,[]]]],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"surveys.new\",[23,[\"model\",\"id\"]]],[[\"class\"],[\"button-primary right\"]],{\"statements\":[[0,\"    \"],[1,[27,\"i18n\",[\"surveys.index.new\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/surveys.hbs"
    }
  });

  _exports.default = _default;
});