define("innowaze-ember2/templates/components/concept-canevas-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rrjh8qAD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"canEdit\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"edit\"],[11,\"title\",\"Edit\"],[3,\"action\",[[22,0,[]],\"editLabel\",[23,[\"label\"]]]],[9],[0,\"\\n    \"],[1,[23,[\"label\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"remove\"],[3,\"action\",[[22,0,[]],\"removeLabel\",[23,[\"label\"]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,[\"label\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"label\",\"description\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n      \"],[1,[23,[\"label\",\"description\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"hasValue\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"canEdit\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"value\"],[3,\"action\",[[22,0,[]],\"selectForValueEdition\",[23,[\"label\"]]]],[9],[0,\"\\n      \"],[1,[23,[\"label\",\"humanValue\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[0,\"\\n      \"],[1,[23,[\"label\",\"humanValue\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/concept-canevas-label.hbs"
    }
  });

  _exports.default = _default;
});