define("innowaze-ember2/serializers/department", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      tags: {
        embedded: "always"
      },
      users: {
        deserialize: "records",
        serialize: false
      },
      comments: {
        deserialize: "records",
        serialize: false
      },
      admins: {
        deserialize: "records",
        serialize: false
      }
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.departments) {
        return this._super.apply(this, arguments);
      }

      var result = this._super(store, primaryModelClass, payload.departments, id, requestType);

      result.meta = result.meta || {};
      result.meta.total_results = payload.total_results;
      return result;
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      if (snapshot.record.get("displayed_main_customers")) {
        json.main_customers = snapshot.record.get("displayed_main_customers").toString();
      }

      if (snapshot.record.get("displayed_geographical_presence")) {
        json.geographical_presence = snapshot.record.get("displayed_geographical_presence").toString();
      }

      if (snapshot.record.get("displayed_investors")) {
        json.investors = snapshot.record.get("displayed_investors").toString();
      }

      if (snapshot.record.get("displayed_funded_companies")) {
        json.funded_companies = snapshot.record.get("displayed_funded_companies").toString();
      }

      json.department_fields_attributes = json.department_fields;

      if (snapshot.record.get("documents")) {
        json.documents = snapshot.record.get("documents").map(function (document) {
          return {
            id: document.id
          };
        });
      }

      if (snapshot.record.get("tags")) {
        json.tags = snapshot.record.get("tags").map(function (tag) {
          return {
            name: Ember.get(tag, "name")
          };
        });
      }

      if (snapshot.record.get("skills")) {
        json.skills = snapshot.record.get("skills").map(function (skill) {
          return {
            id: Ember.get(skill, "id")
          };
        });
      }

      if (snapshot.record.get("technologies")) {
        json.technologies = snapshot.record.get("technologies").map(function (technology) {
          return {
            id: Ember.get(technology, "id")
          };
        });
      }

      delete json.department_fields;
      delete json.logo_url;
      delete json.logo_native_url;
      delete json.displayed_main_customers;
      delete json.displayed_geographical_presence;
      delete json.displayed_investors;
      delete json.displayed_funded_companies;
      delete json.skill_ids;
      delete json.technology_ids;
      return {
        department: json
      };
    }
  });

  _exports.default = _default;
});