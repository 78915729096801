define("innowaze-ember2/routes/surveys/edit", ["exports", "innowaze-ember2/routes/surveys/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    successMessage: "Le sondage a été modifié",
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        survey: this.store.findRecord("survey", params.survey_id, {
          adapterOptions: {
            challenge_id: params.challenge_id
          }
        })
      });
    }
  });

  _exports.default = _default;
});