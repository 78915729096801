define("innowaze-ember2/components/concept-canevas-label", ["exports", "ramda/src/isNil"], function (_exports, _isNil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["canevas-label"],
    attributeBindings: ["draggable", "style"],
    draggable: true,
    data: {
      dropdown: ""
    },
    dragStart: function dragStart(event) {
      var dataTransfer = event.originalEvent.dataTransfer;
      dataTransfer.setData("LabelId", this.get("label.id"));
    },
    touchStart: Ember.aliasMethod("click"),
    style: Ember.computed("label.color", function () {
      return Ember.String.htmlSafe("background-color: ".concat(this.get("label.color"), ";"));
    }),
    hasValue: Ember.computed("label.value", function () {
      return !(0, _isNil.default)(this.get("label.value"));
    }),
    actions: {
      removeLabel: function removeLabel(label) {
        this.sendAction("removeLabel", label);
      },
      editLabel: function editLabel(label) {
        this.sendAction("editLabel", label);
      },
      selectForValueEdition: function selectForValueEdition(label) {
        this.sendAction("selectForValueEdition", label);
      }
    }
  });

  _exports.default = _default;
});