define("innowaze-ember2/templates/departments/partials/-investor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oi2B0EgB",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"model\",\"department_type\"]],20],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"departments.funded_companies\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"columns medium-12\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"fundedCompanies\"]]],null,{\"statements\":[[0,\"          \"],[7,\"ul\"],[11,\"class\",\"keyword-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"fundedCompanies\"]]],null,{\"statements\":[[0,\"              \"],[7,\"li\"],[9],[0,\"\\n                \"],[1,[22,1,[]],false],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"columns medium-12\"],[9],[0,\"\\n        \"],[7,\"hr\"],[9],[10],[0,\"\\n        \"],[7,\"span\"],[9],[1,[27,\"i18n\",[\"departments.investment_ticket\"],null],false],[10],[0,\"\\n        \"],[7,\"span\"],[9],[0,\" : \"],[10],[0,\"\\n        \"],[7,\"span\"],[9],[1,[27,\"m-euro\",[[23,[\"model\",\"investment_ticket\"]]],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/partials/-investor.hbs"
    }
  });

  _exports.default = _default;
});