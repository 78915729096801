define("innowaze-ember2/serializers/canevas", ["exports", "ember-data", "innowaze-ember2/utils/prepareLabel", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _prepareLabel, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      badges: {
        embedded: "always"
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.id = 1;
      payload.labels = payload.labels.map(function (label) {
        return store.push({
          data: {
            id: label.id,
            type: "label",
            attributes: label
          }
        });
      }); // Pseudo label for revenue "none" case

      payload.labels.unshiftObject(store.push({
        data: {
          id: 0,
          type: "label",
          attributes: {
            object_id: 0,
            table_data_id: -1,
            formula: {
              items: null,
              markets: null
            },
            kind: "value_proposal",
            name: "None"
          }
        }
      }));

      if (payload.formulas) {
        payload.formulas = payload.formulas.map(function (formula) {
          formula.items = formula.items.map(function (item) {
            return Ember.Object.create(item);
          });
          return Ember.Object.create(formula);
        });
      }

      payload.labels.forEach(function (label) {
        return (0, _prepareLabel.default)(payload, label);
      });

      if (payload.table) {
        payload.table = payload.table.map(function (row) {
          return row.map(function (cell) {
            cell.labels = payload.labels.filterBy("table_data_id", cell.id);
            cell.labels.setEach("kind", cell.kind);
            return Ember.Object.create(cell);
          });
        });
      } // HACK: Mark labels are fully ready to avoid concurrency problems
      // when computing label values on page load


      payload.labels.setEach("ready", true);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});