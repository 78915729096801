define("innowaze-ember2/components/pikaday-i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var i18n = Ember.Object.create();

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    i18n: Ember.computed("locale.currentLocale", function () {
      moment.locale(this.get("locale.currentLocale"));
      return i18n.setProperties({
        months: moment.months().map(function (m) {
          return m.charAt(0).toUpperCase() + m.slice(1);
        }),
        weekdays: moment.weekdays(),
        weekdaysShort: moment.weekdaysShort()
      });
    }),
    format: Ember.computed("locale.currentLocale", function () {
      return this.get("locale.currentLocale") === "fr" ? "DD-MM-YYYY" : "YYYY-MM-DD";
    }),
    actions: {
      onSelection: function onSelection(date) {
        this.sendAction("onSelection", date);
      }
    }
  });

  _exports.default = _default;
});