define("innowaze-ember2/components/modules/purchasing-family-module", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/components/modules/module"], function (_exports, _objectSpread2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set("availablePurchasingTypes", this.get("module.params.purchasing_types").map(function (type) {
        return (0, _objectSpread2.default)({}, type, {
          id: String(type.id)
        });
      }));
      this.store.findAll("purchasingFamily").then(function (purchasingFamilies) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.set("availableHermesCategories", purchasingFamilies);
      });
    },
    purchasingFamily: Ember.computed("model.purchasing_family_id", "availableHermesCategories.[]", function () {
      var _this2 = this;

      var families = this.availableHermesCategories;

      if (!families) {
        return null;
      }

      return families.find(function (family) {
        return family.id === _this2.get("model.purchasing_family_id");
      });
    }),
    completedDidChange: Ember.on("init", Ember.observer("model.purchasing_family_id", "model.purchasing_type", function () {
      this.set("module.isNotCompleted", !this.get("model.purchasing_family_id") || !this.get("model.purchasing_type"));
    })),
    actions: {
      setFamily: function setFamily(id) {
        this.model.set("purchasing_family_id", id);
      }
    }
  });

  _exports.default = _default;
});