define("innowaze-ember2/components/highlight-verbatim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Don't show html tags when a user wants to update its comment
    // In this case however, the verbatim is lost
    stripTags: Ember.observer("comment.updating", function () {
      if (this.get("comment.updating")) {
        this.set("comment.content", this.get("comment.content").replace(/<\/?span[^>]*>/g, ""));
      }
    }),
    actions: {
      tagClicked: function tagClicked(tag) {
        this.sendAction("tagClicked", tag);
      },
      toggleOrigin: function toggleOrigin() {
        this.toggleProperty("comment.origin");
      }
    },
    mouseUp: function mouseUp() {
      var selection = this._getSelectedText();

      if (selection.length < 3 || !this.active) {
        return true;
      }

      var parent = this.$("span.highlighted:contains(" + selection.replace("(", "\\(").replace(")", "\\)") + ")");

      if (parent.html()) {
        this.set("comment.content", this.get("comment.content").replace(/<\/?span[^>]*>/g, ""));
      } else {
        // the `linkify`component removes all extra spaces within a comment, so we need to do the same before tp replace the selection
        var highlightedSelection = "<span class=\"highlighted\">".concat(selection, "</span>");
        var newComment = this.get("comment.content").replace(/\s+/g, " ").trim().replace(selection, highlightedSelection).replace(/#<span class="highlighted">/g, "#");
        this.set("comment.content", newComment);
      }

      this.sendAction("updateComment", this.comment);
      return true;
    },
    _getSelectedText: function _getSelectedText() {
      if (window.getSelection) {
        return window.getSelection().toString();
      }

      if (document.getSelection) {
        return document.getSelection();
      }

      if (document.selection) {
        return document.selection.createRange().text;
      }
    }
  });

  _exports.default = _default;
});