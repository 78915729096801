define("innowaze-ember2/routes/users/common", ["exports", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    controllerName: "users/common",
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.setProperties({
        availablePersonas: this.store.findAll("persona"),
        departmentsToDisplay: [],
        availableDepartments: [],
        tagsToDisplay: []
      });
      this.store.findAll("department").then(function (departments) {
        controller.set("availableDepartments", departments.filterBy("customer", false));
      });
      this.store.findAll("user-field-list").then(function (fields) {
        fields.map(function (field) {
          return field.get("type");
        }).uniq().forEach(function (field) {
          controller.set("".concat(field, "_list"), fields.filterBy("type", field).map(function (field) {
            return field.get("element");
          }));
        });
        controller.set("list_loaded", true);
      });
      this.set("observed", []);
      controller.set("tagsToDisplay", model.get("preload_tags"));
      Object.keys(model.get("field_types")).forEach(function (key) {
        if (model.get("field_types.".concat(key)) === "enum_tags") {
          _this.observed.pushObject(key);

          controller.addObserver("".concat(key, ".[]"), _this, "handleEnumTagChange");
          var value = model.get("fields.".concat(key));

          if (!value) {
            controller.set(key, []);
            return;
          }

          controller.set(key, value.split(",").map(function (id) {
            return model.get("preload_tags").findBy("id", id);
          }));
        }
      });
    },
    actions: {
      willTransition: function willTransition() {
        var _this2 = this;

        this.observed.forEach(function (key) {
          _this2.controller.removeObserver("".concat(key, ".[]"), _this2, "handleEnumTagChange");

          _this2.controller.removeObserver("".concat(key), _this2, "handleEnumTagChange");
        });
        return true;
      },
      save: function save() {
        var _this3 = this;

        this.currentModel.set("challenge_id", this.challenge_id);
        this.currentModel.addNewTags().then(function () {
          return _this3.currentModel.save();
        }).then(function () {
          return _this3._success();
        }).catch(function (response) {
          return _this3._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("users");
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      setDepartments: function setDepartments(departments) {
        this.currentModel.set("departments", departments);
      },
      newTag: function newTag(target, input) {
        var newTag = this.store.createRecord("tag", {
          name: input,
          id: input
        });
        this.get("controller.tagsToDisplay").pushObject(newTag);
        this.get("controller.".concat(target)).pushObject(newTag);
      },
      selectPersona: function selectPersona(persona) {
        this.currentModel.set("fields.persona", persona.id);
      },
      filesUploaded: function filesUploaded(files) {
        if (!this.currentModel.get("fields.avatar")) {
          this.currentModel.set("fields.avatar", Ember.Object.create({}));
        }

        this.currentModel.set("fields.avatar.id", files[0].id);
        this.currentModel.set("fields.avatar.url", files[0].file_url);
      }
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.errorMessage, response.errors);
    },
    handleEnumTagChange: function handleEnumTagChange(sender, key) {
      var field = key.split(".")[0];

      if (this.get("controller.".concat(field))) {
        this.currentModel.set("fields.".concat(field), this.get("controller.".concat(field)).map(function (obj) {
          return obj.id;
        }).join(","));
      }
    }
  });

  _exports.default = _default;
});