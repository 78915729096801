define("innowaze-ember2/routes/challenge-badges", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id, {
          backgroundReload: false
        }),
        badges: this.store.query("badge", {
          challenge_id: params.challenge_id
        })
      });
    },
    actions: {
      delete: function _delete(badge) {
        var _this = this;

        if (!confirm(this.locale.t("domains.index.deletion_confirmation"))) {
          return;
        }

        badge.destroyRecord({
          adapterOptions: {
            challenge_id: badge.get("challenge_id")
          }
        }).then(function () {
          _this.notifications.success(_this.locale.t("domains.index.deleted"));
        });
      }
    }
  });

  _exports.default = _default;
});