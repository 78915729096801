define("innowaze-ember2/components/modules/uses-module", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/components/modules/module"], function (_exports, _objectSpread2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var USETYPES = [{
    id: 1,
    name: "Chauffage"
  }, {
    id: 2,
    name: "Cuisson"
  }];

  var _default = _module.default.extend({
    availableUseTypes: Ember.computed("model.fields.use_types", function () {
      var array = this.get("model.useTypes");
      return USETYPES.map(function (type) {
        return Ember.Object.create((0, _objectSpread2.default)({}, type, {
          selected: array.includes(type.id)
        }));
      });
    }),
    actions: {
      toggle: function toggle(type) {
        var useTypes = this.get("model.useTypes");
        useTypes = useTypes.includes(type.id) ? useTypes.filter(function (id) {
          return id !== type.id;
        }) : useTypes.filter(function (id) {
          return id !== 0;
        }).concat(type.id);
        this.model.set("fields.use_types", useTypes.join());
      }
    }
  });

  _exports.default = _default;
});