define("innowaze-ember2/components/react-when-visible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.handle = this.isVisible.bind(this);

      if (this.$()) {
        this.$(document).on("scroll", this.handle);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$(document).off("scroll", this.handle);
    },
    handle: null,
    visible: false,
    isVisible: function isVisible() {
      var rect = this.$()[0].getBoundingClientRect();

      if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
        this.visible = true;
        this.$(document).off("scroll", this.handle);

        if (this.onVisible) {
          this.onVisible();
        }
      }
    }
  });

  _exports.default = _default;
});