define("innowaze-ember2/components/modules/awards-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var current_year = new Date().getFullYear();
      this.set("awardsAvailableYears", [null, current_year, current_year - 1, current_year - 2, current_year - 3, current_year - 4]);
    },
    completedDidChange: Ember.on("init", Ember.observer("model.awards_year", "model.awards_category", function () {
      this.set("module.isNotCompleted", !this.get("model.awards_year") || !this.get("model.awards_category"));
    }))
  });

  _exports.default = _default;
});