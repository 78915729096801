define("innowaze-ember2/routes/concepts/edit", ["exports", "innowaze-ember2/routes/concepts/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        concept: this.store.findRecord("concept", params.concept_id, {
          adapterOptions: {
            challenge_id: params.challenge_id
          }
        })
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          evaluationsCount: 0,
          evaluationsLoaded: false,
          evaluations: []
        });
      }
    },
    _success: function _success() {
      this.set("controller.saveDisabled", false);
      this.transitionTo("concepts.show", this.currentModel.concept.get("challenge_id"), this.currentModel.concept.get("id"));
      this.notifications.success(this.locale.t("ideas.updated_idea"));
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("modification_error"), {}, errors);
    }
  });

  _exports.default = _default;
});