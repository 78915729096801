define("innowaze-ember2/components/comments-thread", ["exports", "innowaze-ember2/mixins/routes/highlightComments"], function (_exports, _highlightComments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_highlightComments.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isRating: false,
    // Props of the Component
    //
    // comments : array of all comments associated to the Object (including children)
    // model : the commented model
    // objectType : could be Idea, Concept, Pathway or Knowledge
    // canEdit : if the participant has the right to update the object and subsequently the right to update or delete a comment
    // keywords : all keywords that can be used as hashtags in a comment
    // commentAction : trigger showCommentForm when this prop changes
    // onCommentAdded : event dispatched after comment success
    // admin : Boolean to specify if it's a admin comment thread
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get("comments.length") > 0) {
        this.comments.setEach("highlight", false);
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.commentAction) {
        this.send("showCommentForm");
      }
    },
    commentsTree: Ember.computed("comments.[]", "translatedComments", function () {
      var _this = this;

      if (this.translatedComments) {
        this.comments.forEach(function (comment) {
          var translation = _this.translatedComments.findBy("id", parseInt(comment.get("id")));

          if (translation) {
            comment.setProperties({
              translatedContent: translation.content,
              language: translation.language
            });
          } else {
            comment.set("translatedContent", comment.get("content"));
          }
        });
      }

      var rootComments = this.comments.filterBy("parent_id", null);
      rootComments.forEach(function (comment) {
        comment.set("children", _this.comments.filterBy("parent_id", parseInt(comment.get("id"))).sortBy("created_at"));
      });
      return rootComments;
    }),
    triggerComment: Ember.observer("commentAction", function () {
      this.rerender();

      if (this.commentAction) {
        this.send("showCommentForm");
      }
    }),
    actions: {
      showCommentForm: function showCommentForm(parent) {
        var _this2 = this;

        this.set("commentFormVisible", true);
        this.set("commentParent", parent);
        this.send("removeUpload");

        if (this.$(".comment-list").offset()) {
          $("html, body").animate({
            scrollTop: this.$(".comment-list").offset().top + this.$(".comment-list").outerHeight()
          });
        }

        if (this.parentObject) {
          Ember.run.next(this, function () {
            return _this2.parentObject.set("pushCommentButton", false);
          });
        }
      },
      comment: function comment() {
        var _this3 = this;

        if (!this.newComment) {
          return;
        }

        this.set("saveDisabled", true);
        this.model.addComment({
          admin: !!this.admin,
          commentable_type: this.objectType,
          commentable_id: this.model.id,
          content: this.newComment,
          parent_id: this.get("commentParent.id"),
          picture_id: this.picture_id,
          is_my_comment: true,
          picture_url: this.picture_url,
          picture_name: this.picture_name,
          remote_url: this.remote_url
        }).then(function (comment) {
          return _this3._success(comment);
        }, function (errors) {
          return _this3._error(errors);
        });
      },
      cancelComment: function cancelComment() {
        this.set("newComment", null);
        this.set("commentFormVisible", false);
        this.set("saveDisabled", false);
      },
      deleteComment: function deleteComment(comment) {
        var _this4 = this;

        if (!confirm(this.locale.t("ideas.show.remove_comment"))) {
          return;
        }

        this.model.deleteComment(comment).then(function () {
          _this4.notifications.success(_this4.locale.t("ideas.show.removed_comment"));
        });
      },
      tagClicked: function tagClicked(tag) {
        this._tagClicked(tag, this.comments, this);
      },
      resetClicked: function resetClicked() {
        this._resetClicked(this.comments, this);
      },
      toggleComment: function toggleComment(comment) {
        if (comment.get("canManage")) {
          comment.set("updating", true);
        }
      },
      updateComment: function updateComment(comment) {
        if (!comment.get("canManage")) {
          return;
        }

        comment.save();
        comment.set("updating", false);
      },
      rate: function rate(comment, way) {
        var _this5 = this;

        if (this.isRating) {
          return false;
        }

        this.set("isRating", true);
        comment.rate(way).then(function (res) {
          comment.set("score", res.score);
          comment.set("score_string", res.score_string);
        }).finally(function () {
          return _this5.set("isRating", false);
        });
      },
      toggleOrigin: function toggleOrigin(comment) {
        comment.toggleProperty("origin");
      },
      toggleComments: function toggleComments() {
        this.toggleProperty("hideComments");
      },
      toggleEvalComments: function toggleEvalComments() {
        this.toggleProperty("hideEvalComments");
      },
      sort: function sort(criteria) {
        this.set("comments", this.comments.sortBy(criteria).reverseObjects());
      },
      filesUploaded: function filesUploaded(files) {
        this.setProperties({
          picture_id: files.get("firstObject").id,
          picture_url: files.get("firstObject").file_url,
          picture_name: files.get("firstObject").file_name,
          remote_url: files.get("firstObject").remote_url
        });
      },
      removeUpload: function removeUpload() {
        this.setProperties({
          picture_id: null,
          picture_url: null,
          picture_name: null,
          remote_url: null
        });
      },
      addFileToRecord: function addFileToRecord(comment) {
        var _this6 = this;

        if (this.get("model.documents").findBy("id", comment.get("picture_id"))) {
          return;
        }

        var document = {
          id: comment.get("picture_id"),
          url: comment.get("remote_url"),
          name: comment.get("picture_name")
        };
        this.model.addFile(document).then(function () {
          _this6.notifications.success(_this6.locale.t("pathways.show.file_added"));
        });
      }
    },
    _success: function _success(comment) {
      this.set("newComment", null);
      this.set("commentFormVisible", false);
      this.sendAction("onCommentAdded");
      this.set("saveDisabled", false);
      this.notifications.success(this.locale.t("ideas.show.comment_message"));
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("error"), errors);
    }
  });

  _exports.default = _default;
});