define("innowaze-ember2/routes/login", ["exports", "innowaze-ember2/mixins/routes/socialConnect"], function (_exports, _socialConnect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_socialConnect.default, {
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var data;
      var method;
      var action;
      var _transition$queryPara = transition.queryParams,
          confirmation_token = _transition$queryPara.confirmation_token,
          invitation_token = _transition$queryPara.invitation_token,
          email = _transition$queryPara.email,
          deeplink = _transition$queryPara.deeplink;

      if (confirmation_token) {
        method = "GET";
        action = "confirmation";
        data = {
          confirmation_token: confirmation_token
        };
      } else if (invitation_token && email) {
        action = "invitations";
        data = {
          id: invitation_token,
          email: email
        };
      }

      if (!action) {
        return;
      }

      return this.store.adapterFor("user")[action](data, method).then(function (user) {
        _this._login(user, deeplink ? deeplink : "/");
      }, function (_ref) {
        var errors = _ref.errors;
        transition.abort();

        _this.replaceWith("login");

        _this.notifications.errorWithList("", errors, {
          autoClear: false
        });
      });
    },
    model: function model(params) {
      return Ember.Object.create({
        email: null,
        password: null
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set("showSSO", !!this.get("session.configuration.activePrivateSSO.length"));
    },
    redirect: function redirect() {
      if (this.session.get("loggedIn")) {
        var previousTransition = this.get("session.previousTransition");

        if (previousTransition) {
          this.set("session.previousTransition", null);
          previousTransition.retry();
        } else {
          // Default back to homepage
          this.router.transitionTo("challenges");
        }
      }
    },
    actions: {
      login: function login() {
        var _this2 = this;

        var submit = document.querySelector(".devise-form.signin [type='submit']");
        submit.setAttribute("disabled", true);
        this.store.adapterFor("user").signin({
          user: {
            email: this.currentModel.email,
            password: this.currentModel.password,
            remember_me: 1
          }
        }).then(function (user) {
          return _this2._login(user, _this2.get("controller.deeplink"));
        }, function (error) {
          submit.removeAttribute("disabled");

          _this2.notifications.error(error);
        });
      },
      toggleSigninSections: function toggleSigninSections() {
        this.toggleProperty("controller.showSSO");
      }
    }
  });

  _exports.default = _default;
});