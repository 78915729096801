define("innowaze-ember2/mixins/routes/parallax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ChallengeShowcaseCurrentFrameTicking = false;

  var ChallengeShowcaseCurrentFrame = function ChallengeShowcaseCurrentFrame() {
    var scrollSection = document.querySelector(".scroll-section");
    var navs = document.querySelectorAll(".js-toggle-navbar");
    var header = document.querySelector(".challengeHeader").clientHeight;
    var parallaxed = document.querySelectorAll(".parallax");

    var tick = function tick() {
      if (scrollSection && navs.length > 0) {
        if (scrollSection.scrollTop > header) {
          navs[0].classList.add("visible");
          navs[1].classList.remove("visible");
        } else {
          navs[0].classList.remove("visible");
          navs[1].classList.add("visible");
        }
      }

      for (var _i = 0, _Array$from = Array.from(parallaxed); _i < _Array$from.length; _i++) {
        var parallax = _Array$from[_i];
        parallax.style.transform = "translateY(".concat(scrollSection.scrollTop / 2, "px)");
      }

      if (ChallengeShowcaseCurrentFrameTicking) {
        window.requestAnimationFrame(tick);
      }
    };

    var cb = scrollSection && header && navs && parallaxed ? tick : ChallengeShowcaseCurrentFrame;
    window.requestAnimationFrame(cb);
  };

  var _default = Ember.Mixin.create({
    willDestroy: function willDestroy() {
      ChallengeShowcaseCurrentFrameTicking = false;
    },
    tick: function tick() {
      ChallengeShowcaseCurrentFrameTicking = true;
      window.requestAnimationFrame(ChallengeShowcaseCurrentFrame);
    }
  });

  _exports.default = _default;
});