define("innowaze-ember2/templates/departments/modules/-innovation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2OqBoE3X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"departments.value_proposition\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"onchange\"],[[23,[\"model\",\"value_proposition\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.value_proposition\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"departments.offering\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"onchange\"],[[23,[\"model\",\"offering\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.offering\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"departments.business_model\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"onchange\"],[[23,[\"model\",\"business_model\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.business_model\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-innovation.hbs"
    }
  });

  _exports.default = _default;
});