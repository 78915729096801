define("innowaze-ember2/templates/concepts/-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XXJeeW2T",
    "block": "{\"symbols\":[\"module\",\"shown\",\"toggle\"],\"statements\":[[7,\"form\"],[11,\"class\",\"creation-form new-challenge-general\"],[3,\"action\",[[22,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"activeFormats\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"is_displayed\"]]],null,{\"statements\":[[4,\"toggable-element\",null,[[\"hidden\"],[[27,\"mut\",[[22,1,[\"visible\"]]],null]]],{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[22,2,[]],\"show\",\"hide\"],null]]]],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"close-module\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[3,\"action\",[[22,0,[]],[22,3,[]]]],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[15,[27,\"if\",[[27,\"is-number\",[[22,1,[\"name\"]]],null],\"concepts/edit-modules/-custom\",[27,\"concat\",[\"concepts/edit-modules/-\",[27,\"dasherize\",[[22,1,[\"name\"]]],null]],null]],null],[1,2,3]],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-actions\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"cancel\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"next-step\"],[12,\"disabled\",[21,\"saveDisabled\"]],[11,\"type\",\"submit\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"save\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/-form.hbs"
    }
  });

  _exports.default = _default;
});