define("innowaze-ember2/templates/components/content-with-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4EPpYa1H",
    "block": "{\"symbols\":[\"part\"],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"flag\"]],[27,\"not\",[[23,[\"origin\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"class\",\"flag\"],[12,\"src\",[28,[[27,\"asset-path\",[[27,\"concat\",[\"/assets/images/flag/\",[23,[\"flag\"]],\".png\"],null]],null]]]],[3,\"action\",[[22,0,[]],\"toggleOrigin\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"origin\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"fa fa-undo\"],[3,\"action\",[[22,0,[]],\"toggleOrigin\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,[\"sentenceParts\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"isTag\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"class\",[28,[\"tag \",[27,\"if\",[[22,1,[\"flag\"]],\"highlighted\"],null]]]],[3,\"action\",[[22,0,[]],\"tagClicked\",[22,1,[\"text\"]]]],[9],[0,\"\\n      \"],[1,[22,1,[\"text\"]],true],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"linkify\",[[22,1,[\"text\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/content-with-tags.hbs"
    }
  });

  _exports.default = _default;
});