define("innowaze-ember2/models/knowledge", ["exports", "ember-data", "innowaze-ember2/configuration", "innowaze-ember2/mixins/models/commentHandler", "innowaze-ember2/utils/fileType", "innowaze-ember2/models/application"], function (_exports, _emberData, _configuration, _commentHandler, _fileType, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_commentHandler.default, {
    name: _emberData.default.attr("string"),
    abstract: _emberData.default.attr("string"),
    private: _emberData.default.attr("boolean"),
    tags: _emberData.default.hasMany("tag", {
      async: false
    }),
    domains: _emberData.default.hasMany("domain", {
      async: false
    }),
    accreditations: _emberData.default.hasMany("user", {
      async: false
    }),
    description: _emberData.default.attr("string"),
    documents: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    comments: _emberData.default.hasMany("comment", {
      async: false
    }),
    isKit: _emberData.default.attr("boolean"),
    screenname: _emberData.default.attr("string"),
    canView: _emberData.default.attr("boolean"),
    avatar: _emberData.default.attr("string"),
    accreditor_name: _emberData.default.attr("string"),
    accreditor_logo_url: _emberData.default.attr("string"),
    accreditations_count: _emberData.default.attr(),
    lessons_learned: _emberData.default.attr(),
    implementation_conditions: _emberData.default.attr(),
    canEdit: _emberData.default.attr("boolean"),
    ideas: _emberData.default.attr(),
    presentation_url: _emberData.default.attr("string"),
    charter_url: _emberData.default.attr("string"),
    bcase_url: _emberData.default.attr("string"),
    rex_url: _emberData.default.attr("string"),
    category: _emberData.default.attr(),
    icon: _emberData.default.attr("string"),
    user_id: _emberData.default.attr(),
    admins: _emberData.default.hasMany("user", {
      async: false
    }),
    banner: Ember.computed("documents.[]", function () {
      var image = this.documents.filter(function (document) {
        return (0, _fileType.default)(document.file_name) === "image";
      }).get("firstObject");
      return image ? "".concat(_configuration.default.apiUrl).concat(image.url) : "".concat(_configuration.default.defaultImagesPaths.knowledge);
    }),
    background_banner: Ember.computed("banner", function () {
      return Ember.String.htmlSafe("background-image: url(".concat(this.banner, ");background-position: center;  background-repeat: no-repeat;"));
    }),
    grantAccess: function grantAccess(users) {
      return this._callAdapter("grantAccess", users);
    },
    revokeAccess: function revokeAccess(users) {
      return this._callAdapter("revokeAccess", users);
    },
    getAccreditations: function getAccreditations() {
      return this._callAdapter("getAccreditations");
    },
    translate: function translate(language) {
      return this._callAdapter("translate", language);
    }
  });

  _exports.default = _default;
});