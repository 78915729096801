define("innowaze-ember2/components/send-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: "span",
    classNames: ["email-icon"],
    value: "",
    title: "",
    department: false,
    user: false,
    count: null,
    option: null,
    didInsertElement: function didInsertElement() {
      if (this.templates) {
        this.set("selectedTemplate", this.get("templates.firstObject"));
      }
    },
    openModalWindow: function openModalWindow() {
      this.$(".reveal-modal").foundation("reveal", "open");
    },
    closeModalWindow: function closeModalWindow() {
      this.$(document).foundation("reveal", "close");
    },
    reset: function reset() {
      this.set("selectedTemplate", this.get("templates.firstObject"));
    },
    emptyTemplates: Ember.computed.empty("templates.[]"),
    templateDidChange: Ember.observer("selectedTemplate", function () {
      if (parseInt(this.get("selectedTemplate.id")) === 0) {
        this.set("title", "");
      } else {
        this.set("title", this.get("selectedTemplate.subject") || this.get("selectedTemplate.name"));
      }

      this.set("value", this.get("selectedTemplate.description"));
    }),
    actions: {
      openModal: function openModal() {
        this.$(document).foundation("reveal");
        this.openModalWindow();
      },
      closeModal: function closeModal() {
        this.reset();
      },
      send: function send() {
        var _this = this;

        var title = this.title;
        var value = this.value;

        if (Ember.isBlank(title)) {
          this.notifications.error(this.locale.t("challenges.emailings.no_title_message"));
          return false;
        }

        if (Ember.isBlank(value)) {
          this.notifications.error(this.locale.t("challenges.emailings.no_message"), {
            autoClear: false
          });
          return false;
        }

        if (this.department) {
          var alert = this.option ? "users.send_to_all_members" : "users.send_to_all_admins";

          if (confirm(this.locale.t(alert, {
            count: this.count
          }))) {
            this.sendAction("onSend", title, value);
            this.closeModalWindow();
          }

          return true;
        }

        if (this.user) {
          if (confirm(this.locale.t("users.send_to_all_confirmation", {
            count: this.count
          }))) {
            this.sendAction("onSend", title, value);
            this.closeModalWindow();
          }

          return true;
        }

        this.closeModalWindow();
        this.store.adapterFor("user").sendEmail(this.users, title, value, this.challenge, this.all).then(function () {
          return _this.notifications.success(_this.locale.t("challenges.emailings.sent_message"));
        });
        this.sendAction("onSend");
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.set(field, newContent);
      }
    }
  });

  _exports.default = _default;
});