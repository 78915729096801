define("innowaze-ember2/routes/users/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    controllerName: "users/common",
    model: function model(params) {
      return this.store.findRecord("user", params.user_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      if (this.get("session.currentUser.id") === model.id || this.get("session.isAdmin")) {
        Object.keys(model.get("modules")).forEach(function (module) {
          model.set("modules.".concat(module, ".private"), false);
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.store.findAll("persona").then(function (personas) {
        controller.setProperties({
          availablePersonas: personas,
          persona: model.get("fields.persona") ? personas.findBy("id", model.get("fields.persona")) : null
        });
      });
      Object.keys(model.get("field_types")).forEach(function (key) {
        if (model.get("field_types.".concat(key)) === "enum_tags") {
          var value = model.get("fields.".concat(key));

          if (!value) {
            controller.set(key, []);
            return;
          }

          controller.set(key, value.split(",").map(function (id) {
            return model.get("preload_tags").findBy("id", id);
          }));
        }
      });
    },
    actions: {
      removeNotifications: function removeNotifications() {
        var _this = this;

        this.currentModel.get("digest_frequencies").setEach("frequency", 0);
        this.currentModel.set("optout", true);
        this.currentModel.save().then(function () {
          return _this._success();
        }).catch(function (response) {
          _this.notifications.errorWithList(_this.locale.t("modification_error"), response.errors);
        });
      },
      setAnonymous: function setAnonymous() {
        var _this2 = this;

        if (!confirm(this.locale.t("users.show.anonymous_confirm"))) {
          return;
        }

        this.currentModel.setAnonymous().then(function () {
          _this2.notifications.success(_this2.locale.t("users.show.anonymous_success"));

          _this2.transitionTo("users");
        }).catch(function (response) {
          _this2.notifications.errorWithList(_this2.locale.t("users.show.anonymous_error"), response.errors);
        });
      }
    },
    _success: function _success() {
      this.notifications.success("notifications supprimées");
    }
  });

  _exports.default = _default;
});