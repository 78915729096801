define("innowaze-ember2/templates/challenges/modules/-pathways", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1PTqAkKP",
    "block": "{\"symbols\":[\"pathway\",\"pathway\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"pathways\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dashboard-module liked-ideas-block\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[1,[27,\"i18n\",[\"header.menu.pathways\"],null],false],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n\"],[4,\"if\",[[23,[\"smallPathwayWidget\"]]],null,{\"statements\":[[0,\"      \"],[7,\"ul\"],[11,\"class\",\"medium-block-grid-1\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"pathways\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"idea-card-container\"],[9],[0,\"\\n            \"],[15,\"challenges/modules/pathway-card\",[2]],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"ul\"],[11,\"class\",\"small-block-grid-1 medium-block-grid-3\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"pathways\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"idea-card-container\"],[9],[0,\"\\n            \"],[15,\"challenges/modules/pathway-card\",[1]],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-pathways.hbs"
    }
  });

  _exports.default = _default;
});