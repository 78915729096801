define("innowaze-ember2/templates/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3yzmrwBI",
    "block": "{\"symbols\":[\"module\"],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"i18n\",[\"users.new.title\"],null],false],[10],[0,\"\\n\\n  \"],[7,\"form\"],[11,\"class\",\"creation-form new-challenge-general\"],[3,\"action\",[[22,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"userModuleNames\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[28,[[22,1,[]]]]],[9],[0,\"\\n        \"],[15,[27,\"concat\",[\"users/edit-modules/\",[22,1,[]]],null],[1]],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-actions\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"cancel\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[9],[0,\"\\n        \"],[1,[27,\"i18n\",[\"cancel\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"next-step\"],[11,\"type\",\"submit\"],[9],[0,\"\\n        \"],[1,[27,\"i18n\",[\"users.save\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/new.hbs"
    }
  });

  _exports.default = _default;
});