define("innowaze-ember2/mixins/routes/ideaAndConcept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      this.modelName = this._getModelName(model);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("hideEvalComments", true);
      controller.set("hideKnowledges", true);
      controller.set("hideInfo", true);
      controller.set("hideExperts", true);
      model[this.modelName].multipleEvaluations().then(function (data) {
        controller.setProperties({
          evaluationsCount: data.evaluations_count,
          evaluationsLoaded: true,
          evaluations: data.data
        });
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          panel: null,
          evaluationsCount: 0,
          evaluationsLoaded: false,
          evaluations: []
        });
      }
    },
    actions: {
      toggleExperts: function toggleExperts() {
        var _this = this;

        var value = this.get("controller.hideExperts");

        if (!value) {
          this.toggleProperty("controller.hideExperts");
          return;
        }

        var skills = this.currentModel[this.modelName].get("tags").mapBy("name");

        if (skills.length === 0) {
          return;
        }

        this.store.query("user", {
          skills: skills,
          expert: true
        }).then(function (users) {
          _this.toggleProperty("controller.hideExperts");

          _this.set("controller.matchingExperts", users);

          users.forEach(function (user) {
            user.set("isChallengeMember", user.get("member_in_challenge_ids").includes(parseInt(_this.currentModel.challenge.id)));
          });
        });
      },
      requestExpert: function requestExpert(user) {
        var _this2 = this;

        this.currentModel[this.modelName].requestExpert(user.id).then(function () {
          _this2.get("controller.expertsRequested").pushObject(user);

          _this2.notifications.success("La demande d'expertise a été envoyée");
        }, function () {
          return _this2.notifications.error("Impossible d'envoyer la demande");
        });
      },
      save: function save() {
        var _this3 = this;

        if (this.get("session.isModerator")) {
          this.controller.set("saveDisabled", true);
          this.currentModel[this.modelName].save({
            adapterOptions: {
              challenge_id: this.currentModel.challenge.id
            }
          }).then(function () {
            return _this3._success();
          }, function (errors) {
            return _this3._error(errors);
          });
        }
      },
      toggleInfo: function toggleInfo() {
        this.toggleProperty("controller.hideInfo");
      },
      toggleEvalComments: function toggleEvalComments() {
        this.toggleProperty("controller.hideEvalComments");
      },
      toggleFollow: function toggleFollow() {
        var _this4 = this;

        this.currentModel[this.modelName].toggleFollow().then(function () {
          return _this4.currentModel[_this4.modelName].toggleProperty("followed");
        }, function () {
          _this4.notifications.error(_this4.locale.t("Impossible de changer le suivi"));
        });
      },
      follow: function follow() {
        if (!this.currentModel[this.modelName].get("followed")) {
          this.send("toggleFollow");
        }
      },
      delete: function _delete() {
        $("#confirmDeleteModal").foundation("reveal", "open");
      },
      forceDelete: function forceDelete(record, word) {
        var _this5 = this;

        if (word !== this.locale.t("ideas.show.remove_word")) {
          this.controller.set("remove_word", null);
          return;
        }

        this.controller.set("remove_word", null);
        $("#confirmDeleteModal").foundation("reveal", "close");
        record.destroyRecord({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id
          }
        }).then(function () {
          if (_this5.modelName === "idea") {
            _this5.transitionTo("ideas", _this5.currentModel.challenge.id);
          } else {
            _this5.transitionTo("challenges.show", _this5.currentModel.challenge.id);
          }

          _this5.notifications.success(_this5.locale.t("".concat(_this5.modelName, "s.show.removed_idea")));
        });
      },
      cancelDelete: function cancelDelete() {
        this.controller.set("remove_word", null);
        $("#confirmDeleteModal").foundation("reveal", "close");
      },
      onCommentAdded: function onCommentAdded() {
        if (!this.currentModel[this.modelName].get("followed")) {
          this.send("toggleFollow");
        }
      },
      reject: function reject() {
        var _this6 = this;

        this.currentModel[this.modelName].reject().then(function (data) {
          return _this6.currentModel[_this6.modelName].set("state", data.state);
        });
      }
    },
    _success: function _success() {
      this.set("controller.saveDisabled", false);
      this.notifications.success(this.locale.t("ideas.updated_idea"));
      this.refresh();
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("modification_error"), errors);
    },
    _getModelName: function _getModelName(model) {
      if (typeof model === "undefined") {
        console.warn("__getModelName method need defined model parameter");
        return;
      }

      if (model.concept) {
        return "concept";
      } else if (model.idea) {
        return "idea";
      }
    }
  });

  _exports.default = _default;
});