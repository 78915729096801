define("innowaze-ember2/models/dict", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    signature: _emberData.default.attr("string"),
    gac: _emberData.default.attr("string"),
    public_token: _emberData.default.attr("string"),
    backlink: _emberData.default.attr("string"),
    tos: _emberData.default.attr("string"),
    editorial: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    login_text: _emberData.default.attr("string"),
    theme: _emberData.default.attr("string"),
    title: _emberData.default.attr("string"),
    subtitle: _emberData.default.attr("string"),
    instance: _emberData.default.attr("string"),
    badge: _emberData.default.attr("boolean"),
    last_news: _emberData.default.attr("boolean"),
    about: _emberData.default.attr("string"),
    public_pages: _emberData.default.attr("boolean"),
    events: _emberData.default.attr("boolean"),
    signup_title: _emberData.default.attr("string"),
    signup_subtitle: _emberData.default.attr("string"),
    department_headlines: _emberData.default.attr("boolean"),
    last_departments: _emberData.default.attr("boolean"),
    sponsors: _emberData.default.attr("boolean"),
    end_user: _emberData.default.attr("boolean"),
    email_header: _emberData.default.attr("string"),
    email_footer: _emberData.default.attr("string"),
    universes: _emberData.default.attr("boolean"),
    hide_filters: _emberData.default.attr("boolean"),
    one_challenge: _emberData.default.attr("boolean"),
    deepl_translation: _emberData.default.attr("boolean"),
    translation_languages: _emberData.default.attr("string"),
    compact_challenge_card: _emberData.default.attr("boolean")
  });

  _exports.default = _default;
});