define("innowaze-ember2/routes/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      return this.store.findAll("event");
    },
    actions: {
      deleteEvent: function deleteEvent(event) {
        var _this = this;

        if (confirm(this.locale.t("events.show.confirm_delete_event"))) {
          event.destroyRecord().then(function () {
            _this.notifications.success(_this.locale.t("events.show.event_deleted"));
          });
        }
      }
    }
  });

  _exports.default = _default;
});