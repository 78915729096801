define("innowaze-ember2/routes/challenges/members", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/mixins/routes/userListFilters"], function (_exports, _setCurrentChallenge, _selectAutocomplete, _userListFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _selectAutocomplete.default, _userListFilters.default, {
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        tagsToDisplay: [],
        matchingUsers: [],
        departmentsToDisplay: [],
        page: 1,
        isLoadingMore: true
      });

      this._loadData();
    },
    actions: {
      add: function add() {
        var _this = this;

        var userIds = this.get("controller.selectedUsers").mapBy("id").filter(function (id) {
          return _this.controller.get("members").findBy("user_id", id) === undefined;
        });

        if (userIds.length) {
          this.currentModel.updateMembers(userIds, true, this.get("controller.no_email")).then(function () {
            _this.get("controller.selectedUsers").setEach("isMember", true);

            userIds.forEach(function (id) {
              _this.controller.get("members").addObject({
                user_id: id,
                roles: []
              });
            });

            _this.notifications.success("Membres mis à jour");
          }, function () {
            return _this.notifications.error("Impossible de mettre à jour les membres");
          });
        }
      },
      remove: function remove() {
        var _this2 = this;

        var userIds = this.get("controller.selectedUsers").mapBy("id");

        if (userIds.length) {
          this.currentModel.removeMembers(userIds).then(function () {
            _this2.get("controller.selectedUsers").setEach("isMember", false);

            _this2.get("controller.selectedUsers").setEach("roles", null);

            _this2.controller.get("members").removeObjects(_this2.controller.get("members").filter(function (member) {
              return userIds.includes(member.user_id.toString());
            }));

            _this2.notifications.success("Membres mis à jour");
          }, function () {
            return _this2.notifications.error("Impossible de mettre à jour les membres");
          });
        }
      }
    },
    _loadData: function _loadData() {
      this._assignRoles(this._prepareOptions());
    }
  });

  _exports.default = _default;
});