define("innowaze-ember2/routes/challenge-email-templates/common", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.reopen({
        emailTemplate: Ember.computed.alias("model.emailTemplate")
      });
    },
    deactivate: function deactivate() {
      if (this.currentModel.emailTemplate.get("hasDirtyAttributes")) {
        this.currentModel.emailTemplate.rollbackAttributes();
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.emailTemplate.save().then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      },
      cancel: function cancel() {
        return this.transitionTo("challengeEmailTemplates", this.currentModel.challenge.id);
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      }
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.locale.t("event.show.comment_message_error"), errors);
    }
  });

  _exports.default = _default;
});