define("innowaze-ember2/components/search-expert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: "search-expert",
    hideExperts: true,
    matchingExperts: [],
    locale: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      toggleExperts: function toggleExperts() {
        var _this = this;

        var value = this.hideExperts;

        if (!value) {
          this.toggleProperty("hideExperts");
          return;
        }

        var skills = this.tags.mapBy("name");

        if (skills.length === 0) {
          return;
        }

        this.store.query("user", {
          skills: skills,
          expert: true
        }).then(function (users) {
          _this.toggleProperty("hideExperts");

          _this.set("matchingExperts", users);
        });
      }
    }
  });

  _exports.default = _default;
});