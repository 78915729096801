define("innowaze-ember2/templates/challenges/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fiCkNUo7",
    "block": "{\"symbols\":[\"challenge\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"header.menu.challenges\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n\"],[4,\"sortable-objects\",null,[[\"tagName\",\"class\",\"sortableObjectList\",\"sortEndAction\"],[[23,[\"ul\"]],\"fat-list large sortable\",[23,[\"model\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateChallengesOrder\"],null]]],{\"statements\":[[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[4,\"draggable-object\",null,[[\"tagName\",\"isSortable\",\"content\"],[\"li\",true,[22,1,[]]]],{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[28,[\"banner \",[27,\"if\",[[22,1,[\"closed\"]],\"fade\"],null]]]],[9],[0,\"\\n          \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-lock \",[27,\"if\",[[22,1,[\"private\"]],\"private\",\"hide\"],null]]]],[9],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"picture\"],[9],[0,\"\\n            \"],[7,\"img\"],[12,\"src\",[27,\"concat\",[[23,[\"session\",\"apiUrl\"]],[22,1,[\"logo_url\"]]],null]],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"challenges.edit_main\",[22,1,[\"id\"]]],null,{\"statements\":[[0,\"              \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"  \"],[7,\"p\"],[9],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"button-grey\"],[3,\"action\",[[22,0,[]],\"reorder\"]],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"challenges.domains.reorder\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/all.hbs"
    }
  });

  _exports.default = _default;
});