define("innowaze-ember2/templates/components/embed-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RJ9IVqR9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"canEdit\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"input\",null,[[\"placeholder\",\"value\"],[[27,\"i18n\",[\"cut_paste\"],null],[23,[\"slideUrl\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"secureUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"iframe\"],[11,\"sandbox\",\"allow-same-origin allow-scripts allow-popups allow-forms\"],[12,\"src\",[21,\"secureUrl\"]],[11,\"frameborder\",\"0\"],[11,\"width\",\"100%\"],[11,\"height\",\"640\"],[11,\"allowfullscreen\",\"true\"],[11,\"mozallowfullscreen\",\"true\"],[11,\"webkitallowfullscreen\",\"true\"],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/embed-slide.hbs"
    }
  });

  _exports.default = _default;
});