define("innowaze-ember2/templates/challenges/modules/-ideas-per-department", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8MrE5es",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"session\",\"isCompetitor\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"departmentsIsLoaded\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"ideas-histogram-block dashboard-module\"],[9],[0,\"\\n      \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n        \"],[1,[27,\"i18n\",[\"challenges.ideas_per_department.title\"],[[\"ideas\"],[[27,\"name-for\",[[23,[\"model\"]],\"ideas\",\"plural_idea\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"widget-histogram\"],[9],[0,\"\\n        \"],[1,[27,\"horizontal-stacked-histogram\",null,[[\"data\",\"color\",\"dataLoaded\"],[[23,[\"departments\"]],\"#96C219\",[23,[\"departmentsIsLoaded\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-ideas-per-department.hbs"
    }
  });

  _exports.default = _default;
});