define("innowaze-ember2/routes/users/edit", ["exports", "innowaze-ember2/routes/users/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    model: function model(params) {
      return this.store.findRecord("user", params.user_id);
    },
    _success: function _success() {
      this.notifications.success(this.locale.t("users.edit.profile_updated"));
      this.transitionTo("users.show", this.currentModel.id);
    }
  });

  _exports.default = _default;
});