define("innowaze-ember2/templates/concepts/edit-modules/-free-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yv0lEqJM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"white-form\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[23,[\"module\",\"title\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"placeholder\",\"onchange\"],[[23,[\"model\",\"concept\",\"free_field\"]],[27,\"i18n\",[\"ideas.modules.custom.description_placeholder\"],null],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.concept.free_field\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/edit-modules/-free-field.hbs"
    }
  });

  _exports.default = _default;
});