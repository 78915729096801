define("innowaze-ember2/controllers/users/common", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/mixins/controllers/googleMaps"], function (_exports, _toConsumableArray2, _googleMaps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_googleMaps.default, {
    locale: Ember.inject.service(),
    departmentsWithCustomFields: Ember.computed("model.departments.[]", function () {
      return this.get("model.departments").filter(function (department) {
        return department.department_fields.length > 0;
      });
    }),
    addressDidChange: Ember.observer("model.street_number", "model.address", "model.city", "model.zipcode", "model.country", function () {
      Ember.run.once(this, "addressChange");
    }),
    universeSelectOptions: Ember.computed("session.availableUniverses.[]", function () {
      return [{
        id: null,
        name: this.locale.t("none")
      }].concat((0, _toConsumableArray2.default)(this.get("session.availableUniverses").toArray()));
    })
  });

  _exports.default = _default;
});