define("innowaze-ember2/components/cost-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    periods: [{
      name: "day",
      value: 365
    }, {
      name: "month",
      value: 12
    }, {
      name: "year",
      value: 1
    }],
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.period) {
        this.set("period", this.periods[0].value);
      }
    },
    name: Ember.computed.alias("itemLabel.name"),
    computedChecked: Ember.computed("hostLabel.formula.items.[]", {
      get: function get() {
        return this.get("hostLabel.formula.items").isAny("label", this.itemLabel);
      },
      set: function set(key, value) {
        this.set("checked", value);
        return value;
      }
    }),
    computedValue: Ember.computed("hostLabel.formula.items.[]", {
      get: function get() {
        var item = this.get("hostLabel.formula.items").findBy("label", this.itemLabel);
        return item ? item.get("value") : null;
      },
      set: function set(key, value) {
        if (value.toString().indexOf(",") >= 0) {
          value = value.replace(",", ".");
        }

        this.set("value", value);
        return value;
      }
    }),
    computedPeriod: Ember.computed("hostLabel.formula.items.[]", {
      get: function get() {
        var item = this.get("hostLabel.formula.items").findBy("label", this.itemLabel);
        return item ? item.get("period") : null;
      },
      set: function set(key, value) {
        this.set("period", value);
        return value;
      }
    }),
    needsPeriod: Ember.computed("computedChecked", "hostLabel.formula.selectedFormula.items.[]", function () {
      return this.computedChecked && this.get("hostLabel.formula.selectedFormula.items.length") === 0;
    }),
    checkedDidChange: Ember.observer("checked", function () {
      if (this.checked) {
        this.sendAction("addFormulaItem", this.hostLabel, this.itemLabel);
        this.sendAction("setFormulaItemValue", this.hostLabel, this.itemLabel, this.value);
        this.sendAction("setFormulaItemPeriod", this.hostLabel, this.itemLabel, this.period);
      } else {
        this.sendAction("removeFormulaItem", this.hostLabel, this.itemLabel);
      }
    }),
    valueDidChange: Ember.observer("value", function () {
      if (!(this.hostLabel && this.itemLabel)) {
        return;
      }

      this.sendAction("setFormulaItemValue", this.hostLabel, this.itemLabel, this.value);
    }),
    periodDidChange: Ember.observer("period", function () {
      if (!(this.hostLabel && this.itemLabel)) {
        return;
      }

      this.sendAction("setFormulaItemPeriod", this.hostLabel, this.itemLabel, this.period);
    })
  });

  _exports.default = _default;
});