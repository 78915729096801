define("innowaze-ember2/components/formula-value-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "ul",
    relatedLabels: Ember.computed("labels.[]", function () {
      return this.labels.filterBy("formula.selectedFormula", this.formula);
    })
  });

  _exports.default = _default;
});