define("innowaze-ember2/components/fat-search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    buttonText: "",
    submitOnKeyUp: false,
    placeholder: Ember.computed(function () {
      return this.locale.t("ideas.index.search_placeholder");
    }),
    actions: {
      submit: function submit() {
        this.sendAction("onSubmit");
      },
      onKeyUp: function onKeyUp() {
        if (this.submitOnKeyUp) {
          this.send("submit");
        }
      }
    }
  });

  _exports.default = _default;
});