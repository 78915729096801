define("innowaze-ember2/routes/challenges/edit-concepts-format", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveChallenge"], function (_exports, _cleanCurrentModel, _setCurrentChallenge, _saveChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _setCurrentChallenge.default, _saveChallenge.default, {
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.saveCurrentChallenge().then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      },
      next: function next() {
        var _this2 = this;

        this.saveForNextStep(7).then(function () {
          return _this2._stepSuccess();
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      addNewFormat: function addNewFormat() {
        if (this.get("controller.newFormat")) {
          this.currentModel.get("concept_formats").pushObject(Ember.Object.create({
            title: this.get("controller.newFormat"),
            active: true,
            visible: true
          }));
          this.controller.set("newFormat", null);
        }
      },
      updateFormatsOrder: function updateFormatsOrder() {
        this.get("controller.sortedFormats").forEach(function (format, index) {
          format.set("position", index + 1);
        });
      },
      addNewTool: function addNewTool() {
        if (this.get("controller.newTool")) {
          this.currentModel.get("concept_tools").pushObject(Ember.Object.create({
            title: this.get("controller.newTool"),
            active: true,
            visible: true,
            category: "Standard"
          }));
          this.controller.set("newTool", null);
        }
      },
      updateToolsOrder: function updateToolsOrder() {
        this.get("controller.sortedTools").forEach(function (tool, index) {
          tool.set("position", index + 1);
        });
      },
      updateAnalysesOrder: function updateAnalysesOrder() {
        this.get("controller.sortedAnalyses").forEach(function (tool, index) {
          tool.set("position", index + 1);
        });
      }
    },
    _stepSuccess: function _stepSuccess() {
      this._success();

      this.transitionTo("challenges.edit_concepts_workflow", this.currentModel.id);
    }
  });

  _exports.default = _default;
});