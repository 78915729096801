define("innowaze-ember2/routes/knowledges/new", ["exports", "innowaze-ember2/routes/knowledges/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord("knowledge");
    },
    _success: function _success(result) {
      this.transitionTo("knowledges.show", result.id);
      this.notifications.success("Le savoir a été créé.");
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.locale.t("creation_error"), response.errors);
    }
  });

  _exports.default = _default;
});