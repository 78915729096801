define("innowaze-ember2/routes/challenges/rewards", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/mixins/routes/userListFilters"], function (_exports, _setCurrentChallenge, _selectAutocomplete, _userListFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _selectAutocomplete.default, _userListFilters.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        matchingUsers: [],
        tags: [],
        challenges: [],
        departments: [],
        order: "created_at",
        page: 1,
        badgesSelectorHidden: true,
        tagsToDisplay: [],
        departmentsToDisplay: [],
        isLoadingMore: true
      });

      this._loadData();
    },
    actions: {
      setBadges: function setBadges(badges) {
        this.set("controller.selectedBadges", badges);
      },
      removeBadge: function removeBadge(badge, user) {
        var userId = user.get("id");
        var newBadges = user.get("badges").without(badge);
        this.currentModel.removeBadge(userId, badge).then(function () {
          return user.set("badges", newBadges);
        });
      },
      updateBadges: function updateBadges() {
        var _this = this;

        var userIds = this.get("controller.selectedUsers").mapBy("id");
        var badgeIds = this.get("controller.selectedBadges");
        this.toggleProperty("controller.badgesSelectorHidden");
        this.currentModel.updateBadges(userIds, badgeIds).then(function () {
          _this.get("controller.matchingUsers").filter(function (user) {
            return userIds.includes(user.get("id"));
          }).forEach(function (u) {
            return u.get("badges").pushObjects(badgeIds);
          });

          _this.notifications.success("Badges updated");
        });
      }
    },
    _loadData: function _loadData() {
      var _this2 = this;

      var options = this._prepareOptions();

      return this.store.query("user", options).then(function (result) {
        _this2.set("controller.totalResults", result.get("meta.total_results"));

        _this2.get("controller.matchingUsers").pushObjects(result.toArray());

        _this2.controller.set("isLoadingMore", false);
      });
    }
  });

  _exports.default = _default;
});