define("innowaze-ember2/adapters/configuration", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-inflector", "innowaze-ember2/utils/formatUrl", "innowaze-ember2/adapters/application"], function (_exports, _objectSpread2, _emberInflector, _formatUrl, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _emberInflector.default.inflector.uncountable("configuration");

  var _default = _application.default.extend({
    // Override Build URL to be able to pass query parameters to the request through formatURL helper
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType !== "findRecord") {
        snapshot.adapterOptions = (0, _objectSpread2.default)({}, snapshot.adapterOptions, {
          current_digest: this.session.get("configurationDigest")
        });
      }

      return (0, _formatUrl.default)(this._super(modelName), snapshot);
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (payload) {
        payload["current_digest"] = headers["x-configuration-digest"];
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});