define("innowaze-ember2/routes/knowledges/common", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _cleanCurrentModel, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("tagsToDisplay", this.currentModel.get("tags").toArray());
      this.store.query("domain", {}).then(function (domains) {
        controller.set("availableDomains", domains);
      });
      controller.set("availableCategories", [Ember.Object.create({
        id: 1,
        name: "kit",
        title: this.locale.t("knowledges.categories.kit"),
        icon: "medkit",
        selected: false
      }), Ember.Object.create({
        id: 2,
        name: "market",
        title: this.locale.t("knowledges.categories.market"),
        icon: "binoculars",
        selected: false
      }), Ember.Object.create({
        id: 3,
        name: "exploration",
        title: this.locale.t("knowledges.categories.exploration"),
        icon: "flask",
        selected: false
      })]);
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      save: function save() {
        var _this = this;

        this.currentModel.set("category", this.get("controller.selectedCategory.id"));
        this.currentModel.save().then(function (result) {
          return _this._success(result);
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("knowledges");
      },
      documentsUploaded: function documentsUploaded(files) {
        this.currentModel.get("documents").pushObjects(Array.from(files).map(function (file) {
          return {
            id: file.id,
            name: file.file_name,
            file_name: file.file_name,
            url: file.file_url
          };
        }));
      },
      removeDocument: function removeDocument(doc) {
        this.currentModel.get("documents").removeObject(doc);
      },
      updateDocument: function updateDocument(id) {
        var _this$currentModel$ge = this.currentModel.get("documents").findBy("id", id),
            name = _this$currentModel$ge.name;

        if (!name) {
          return;
        }

        var adapter = this.store.adapterFor("application");
        adapter.ajax("".concat(adapter.host, "/").concat(adapter.namespace, "/uploads/").concat(id, ".json"), "PUT", {
          data: {
            name: name
          }
        });
      },
      newTag: function newTag(name) {
        var newTag = this.store.createRecord("tag", {
          id: name,
          name: name
        });
        this.currentModel.get("tags").pushObject(newTag);
        this.get("controller.tagsToDisplay").pushObject(newTag);
      }
    }
  });

  _exports.default = _default;
});