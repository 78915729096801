define("innowaze-ember2/adapters/upload", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery() {
      return this._super.apply(this, arguments) + "/search";
    },
    toggleVisibleInWidget: function toggleVisibleInWidget(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/uploads/").concat(id, "/toggle_visible_in_widget.json"), "PUT");
    }
  });

  _exports.default = _default;
});