define("innowaze-ember2/templates/challenge-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cau1Zi2/",
    "block": "{\"symbols\":[\"badge\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"Badges\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"fat-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"badges\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"banner\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"logo_url\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"picture\"],[9],[7,\"img\"],[12,\"src\",[28,[[23,[\"session\",\"apiUrl\"]],[22,1,[\"logo_url\"]]]]],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n          \"],[4,\"link-to\",[\"challengeBadges.edit\",[22,1,[\"challenge_id\"]],[22,1,[\"id\"]]],null,{\"statements\":[[1,[22,1,[\"title\"]],false]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"delete-domain\"],[3,\"action\",[[22,0,[]],\"delete\",[22,1,[]]]],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[10],[0,\"\\n  \"],[4,\"link-to\",[\"challengeBadges.new\",[23,[\"model\",\"challenge\",\"id\"]]],[[\"class\"],[\"button-primary right\"]],{\"statements\":[[0,\"Create a badge\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenge-badges.hbs"
    }
  });

  _exports.default = _default;
});