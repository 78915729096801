define("innowaze-ember2/templates/challenge-debates/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C4lFWb5X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"debates.edit.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[15,\"challengeDebates/form\",[]],[0,\"\\n\"],[10]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenge-debates/edit.hbs"
    }
  });

  _exports.default = _default;
});