define("innowaze-ember2/models/department-module", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    active: _emberData.default.attr("boolean"),
    area: _emberData.default.attr(),
    name: _emberData.default.attr("string"),
    params: _emberData.default.attr(),
    position: _emberData.default.attr(),
    title: _emberData.default.attr("string"),
    visible: _emberData.default.attr("boolean")
  });

  _exports.default = _default;
});