define("innowaze-ember2/controllers/knowledges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["domain"],
    locale: Ember.inject.service(),
    availableCategories: Ember.computed(function () {
      return [Ember.Object.create({
        id: 1,
        name: "kit",
        title: this.locale.t("knowledges.categories.kit"),
        icon: "medkit",
        selected: false
      }), Ember.Object.create({
        id: 2,
        name: "market",
        title: this.locale.t("knowledges.categories.market"),
        icon: "binoculars",
        selected: false
      }), Ember.Object.create({
        id: 3,
        name: "exploration",
        title: this.locale.t("knowledges.categories.exploration"),
        icon: "flask",
        selected: false
      }), Ember.Object.create({
        id: 0,
        name: "standard",
        title: this.locale.t("knowledges.categories.standard"),
        icon: "file-o",
        selected: false
      })];
    })
  });

  _exports.default = _default;
});