define("innowaze-ember2/templates/tag-triggers/-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xquT/vQT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"small-12 columns\"],[9],[0,\"\\n  \"],[7,\"form\"],[11,\"class\",\"creation-form tagTriggers\"],[3,\"action\",[[22,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"white-form\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"class\"],[[23,[\"model\",\"tag\"]],\"Nom du tag\",\"title\"]]],false],[0,\"\\n      \"],[1,[27,\"ember-selectize\",null,[[\"multiple\",\"optionValuePath\",\"optionLabelPath\",\"content\",\"selection\",\"create-item\",\"placeholder\"],[true,\"content.id\",\"content.name\",[23,[\"keywords\"]],[23,[\"keywords\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"newKeyword\"],null],\"Choisissez les déclencheurs\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-actions\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[9],[0,\"Annuler\"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"next-step\"],[11,\"type\",\"submit\"],[9],[0,\"Enregistrer\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/tag-triggers/-form.hbs"
    }
  });

  _exports.default = _default;
});