define("innowaze-ember2/models/expert", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    primary_expert: _emberData.default.belongsTo("user", {
      async: false
    }),
    secondary_expert: _emberData.default.belongsTo("user", {
      async: false
    }),
    level2_expert: _emberData.default.belongsTo("user", {
      async: false
    }),
    security_expert: _emberData.default.belongsTo("user", {
      async: false
    }),
    challenge_id: _emberData.default.attr("string"),
    solution_id: _emberData.default.attr("string"),
    organization: _emberData.default.attr("string"),
    activity: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    hasRequiredExperts: Ember.computed("primary_expert", "level2_expert", "security_expert", function () {
      return !!this.primary_expert && !!this.level2_expert && !!this.security_expert;
    })
  });

  _exports.default = _default;
});