define("innowaze-ember2/templates/challenges/modules/-current-user-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EBwFV+k5",
    "block": "{\"symbols\":[\"rank\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n  \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"challenges.current_user_contribution.title\"],null],false],[0,\" :\\n    \"],[1,[23,[\"model\",\"current_user_score\"]],false],[0,\"\\n    points\\n  \"],[10],[0,\"\\n  \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"small-12 column\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"contributions-scale no-bullet\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"quartiles\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[12,\"class\",[28,[\"performance \",[27,\"if\",[[22,1,[\"current\"]],\"current\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"current\"]]],null,{\"statements\":[[0,\"            \"],[7,\"i\"],[11,\"class\",\"fa fa-play cursor\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"div\"],[11,\"class\",\"name\"],[9],[0,\"\\n            \"],[1,[22,1,[\"name\"]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"i\"],[12,\"class\",[28,[\"fa \",[22,1,[\"icon\"]]]]],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"span\"],[11,\"class\",\"footnote\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"challenges.current_user_contribution.footnote\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-current-user-performance.hbs"
    }
  });

  _exports.default = _default;
});