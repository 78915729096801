define("innowaze-ember2/services/locale", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/typeof"], function (_exports, _objectSpread2, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getI18n: function getI18n() {
      // Work around race condition
      return this.I18n || window.I18n;
    },
    currentLocale: Ember.computed(function () {
      return this.getI18n().currentLocale();
    }),
    updateLocale: function updateLocale(lang) {
      this.getI18n().locale = lang;
      this.set("currentLocale", lang);
    },
    t: function t(key, options) {
      // Work around "subject.hasOwnProperty is not a function"
      if ((0, _typeof2.default)(options) === "object") {
        options = (0, _objectSpread2.default)({}, options);
      }

      return this.getI18n().t(key, options);
    },
    l: function l(format, date) {
      return this.getI18n().l(format, date);
    },
    setInitialLocale: function setInitialLocale(currentUser) {
      var availableLocales = this.get("session.configuration.available_locales");
      var localeCookie = Cookies.get("locale");
      var user = currentUser || this.get("session.currentUser");

      if (localeCookie && availableLocales.includes(localeCookie)) {
        this.updateLocale(localeCookie);
      } else if (user && user.get("locale") && availableLocales.includes(user.get("locale"))) {
        this.updateLocale(user.get("locale"));
      } else {
        this.updateLocale(this.get("session.configuration.default_locale"));
      }
    },
    reloadTranslations: function reloadTranslations(translations) {
      var locale = this.getI18n().locale;
      this.getI18n().translations = translations; // Force update

      this.updateLocale("");
      this.updateLocale(locale);
    }
  });

  _exports.default = _default;
});