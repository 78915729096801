define("innowaze-ember2/initializers/assets-map", ["exports", "fetch", "innowaze-ember2/services/assetMap"], function (_exports, _fetch, _assetMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (window.location.hostname.match(/openlab.syn|localhost|^192\.168\./)) {
      application.register("service:assetMap", _assetMap.default);
      return;
    }

    application.deferReadiness();
    (0, _fetch.default)("/assets/assetMap.json").then(function (response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response;
    }).then(function (resp) {
      return resp.json();
    }).then(function (json) {
      // @see https://medium.com/@ruslanzavacky/ember-cli-fingerprinting-and-dynamic-assets-797a298d8dc6
      _assetMap.default.reopen({
        map: json.assets
      });

      application.register("service:assetMap", _assetMap.default);
    }).catch(console.error).finally(function () {
      return application.advanceReadiness();
    });
  }

  var _default = {
    name: "assets-map",
    initialize: initialize
  };
  _exports.default = _default;
});