define("innowaze-ember2/routes/universes/new", ["exports", "innowaze-ember2/routes/universes/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    locale: Ember.inject.service(),
    controllerName: "universes/edit",
    errorMessage: Ember.computed(function () {
      return this.locale.t("creation_error");
    }),
    successMessage: Ember.computed(function () {
      return this.locale.t("universes.creation_success");
    }),
    model: function model() {
      return this.store.createRecord("universe");
    }
  });

  _exports.default = _default;
});