define("innowaze-ember2/controllers/challenges/invitations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locale: Ember.inject.service(),
    emails: [],
    availableRoles: Ember.computed(function () {
      return [{
        id: null,
        name: this.locale.t("participant")
      }, {
        id: "reviewer",
        name: this.locale.t("reviewer")
      }, {
        id: "designer",
        name: this.locale.t("designer")
      }, {
        id: "moderator",
        name: this.locale.t("moderator")
      }];
    }),
    role: Ember.computed(function () {
      return this.get("availableRoles.firstObject");
    })
  });

  _exports.default = _default;
});