define("innowaze-ember2/controllers/departments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    listDisplay: Ember.computed.equal("displayMode", "list"),
    blockDisplay: Ember.computed.equal("displayMode", "block-list")
  });

  _exports.default = _default;
});