define("innowaze-ember2/routes/concepts/show", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/linkedDebateBadge", "innowaze-ember2/mixins/routes/ideaAndConcept", "innowaze-ember2/utils/formatEvaluation"], function (_exports, _setCurrentChallenge, _linkedDebateBadge, _ideaAndConcept, _formatEvaluation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _linkedDebateBadge.default, _ideaAndConcept.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        concept: this.store.findRecord("concept", params.concept_id, {
          adapterOptions: {
            challenge_id: params.challenge_id
          },
          reload: true
        })
      });
    },
    actions: {
      previousState: function previousState() {
        var _this = this;

        this.currentModel.concept.previousState().then(function (data) {
          _this.currentModel.concept.setProperties({
            state: data.state,
            next_state_name: data.next_state_name,
            can_next_state: data.can_next_state,
            previous_state_name: data.previous_state_name,
            can_previous_state: data.can_previous_state
          });
        });
      },
      nextState: function nextState() {
        var _this2 = this;

        this.currentModel.concept.nextState().then(function (data) {
          _this2.currentModel.concept.setProperties({
            state: data.state,
            next_state_name: data.next_state_name,
            can_next_state: data.can_next_state,
            previous_state_name: data.previous_state_name,
            can_previous_state: data.can_previous_state
          });
        });
      },
      toggleIsMedian: function toggleIsMedian(toolName, isMedian) {
        var _this3 = this;

        this.currentModel.concept.toggleIsMedian(toolName, isMedian).then(function (json) {
          if (json.status) {
            _this3.notifications.success(_this3.locale.t("ideas.is_median_true"));
          } else {
            _this3.notifications.success(_this3.locale.t("ideas.is_median_false"));
          }
        });
      },
      changeCurrentUserEvaluation: function changeCurrentUserEvaluation(toolName, value) {
        var percent = (0, _formatEvaluation.valueToPercent)(value, this.get("controller.minValue"), this.get("controller.maxValue"));
        var conceptTool = this.currentModel.challenge.get("concept_tools").findBy("name", toolName);
        var userTool = this.currentModel.concept.get("current_user_tools").findBy("id", conceptTool.id);

        if (userTool) {
          Ember.setProperties(userTool, {
            id: conceptTool.id,
            value: value !== null ? percent : 0,
            evaluated: value !== null
          });
        } else {
          this.currentModel.concept.get("current_user_tools").pushObject({
            id: conceptTool.id,
            value: value !== null ? percent : 0,
            evaluated: value !== null
          });
        }

        this.currentModel.concept.send("becomeDirty");
      },
      changeEvaluation: function changeEvaluation(toolName, value) {
        var conceptTool = this.currentModel.challenge.get("concept_tools").findBy("name", toolName);
        var tool = this.currentModel.concept.get("tools").findBy("id", conceptTool.id);

        if (tool) {
          Ember.setProperties(tool, {
            id: conceptTool.id,
            value: value !== null ? value : 0,
            is_median: false
          });
        } else {
          this.currentModel.concept.get("tools").pushObject({
            id: conceptTool.id,
            value: value !== null ? value : 0,
            is_median: false
          });
        }

        this.currentModel.concept.send("becomeDirty");
      },
      saveSingleCurrentUserEvaluation: function saveSingleCurrentUserEvaluation(toolName, value) {
        var data = {
          tools: {
            "0": {
              id: this.currentModel.challenge.get("concept_tools").findBy("name", toolName).id,
              evaluated: value !== null,
              value: value !== null ? value : 0
            }
          }
        };

        this._updateCurrentUserEvaluation(data);
      },
      saveCurrentUserEvaluations: function saveCurrentUserEvaluations() {
        var _this4 = this;

        var data = {};
        data.tools = {};
        this.currentModel.concept.get("current_user_tools").forEach(function (tool) {
          data.tools[tool.id] = tool;

          _this4.get("controller.activeTools").findBy("id", tool.id).set("evaluated", true);
        });
        data.evaluation_description = this.currentModel.concept.get("current_user_evaluation_description");

        this._updateCurrentUserEvaluation(data);
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.concept.setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      }
    },
    _updateCurrentUserEvaluation: function _updateCurrentUserEvaluation(data) {
      var _this5 = this;

      this.currentModel.concept.updateCurrentUserEvaluation(data).then(function () {
        return _this5._success();
      }, function (errors) {
        return _this5._errors(errors);
      });
    }
  });

  _exports.default = _default;
});