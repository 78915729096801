define("innowaze-ember2/components/font-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    attributeBindings: ["style"],
    style: Ember.computed("count", function () {
      var count = this.count;

      if (count < 2) {
        return Ember.String.htmlSafe("font-size: 10px; color: grey;");
      }

      if (count < 4) {
        return Ember.String.htmlSafe("font-size: 12px; color: #96C219");
      }

      if (count < 8) {
        return Ember.String.htmlSafe("font-size: 14px; color: #257398");
      }

      if (count < 16) {
        return Ember.String.htmlSafe("font-size: 16px; color: #257398");
      }

      return Ember.String.htmlSafe("font-size: 18px; color: #257398");
    })
  });

  _exports.default = _default;
});