define("innowaze-ember2/serializers/knowledge", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      tags: {
        deserialize: "records",
        serialize: false
      },
      domains: {
        embedded: "always"
      },
      comments: {
        deserialize: "records",
        serialize: false
      },
      admins: {
        deserialize: "records",
        serialize: false
      },
      accreditations: {
        deserialize: "records",
        serialize: false
      }
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.knowledges) {
        return this._super.apply(this, arguments);
      }

      var result = this._super(store, primaryModelClass, payload.knowledges, id, requestType);

      result.meta = result.meta || {};
      result.meta.total_results = payload.total_results;
      return result;
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      if (snapshot.record.get("tags")) {
        json.tags = snapshot.record.get("tags").map(function (tag) {
          return {
            name: Ember.get(tag, "name")
          };
        });
      }

      if (snapshot.record.get("admins")) {
        json.admin_ids = snapshot.record.get("admins").map(function (admin) {
          return admin.id;
        });
      } // Override payload format to match the server's expectations


      return {
        knowledge: json
      };
    }
  });

  _exports.default = _default;
});