define("innowaze-ember2/templates/pathways/-teammates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GFALgrC3",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"teammates\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"ideas.team\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"teammate small-block-grid-3 medium-block-grid-6 large-block-grid-9\"],[9],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"picture\"],[9],[7,\"img\"],[12,\"src\",[23,[\"model\",\"author\",\"avatar\"]]],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"idea-author\"],[9],[0,\"\\n          \"],[1,[23,[\"model\",\"author\",\"screenname\"]],false],[0,\"\\n          \"],[1,[27,\"send-email\",null,[[\"users\",\"user_name\",\"challenge\"],[[23,[\"user_id\"]],[23,[\"model\",\"author\",\"screenname\"]],[23,[\"model\",\"challenge_id\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"teammates\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"picture\"],[9],[7,\"img\"],[12,\"src\",[22,1,[\"avatar\"]]],[9],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"idea-author\"],[9],[1,[22,1,[\"screenname\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/pathways/-teammates.hbs"
    }
  });

  _exports.default = _default;
});