define("innowaze-ember2/components/likes-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["rating-module"],
    type: false,
    liked: Ember.computed.gt("idea.current_user_rating", 0),
    currentUserRating: Ember.computed("idea.current_user_rating", function () {
      return this.get("idea.current_user_rating");
    }),
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    actions: {
      likes: function likes() {
        if (this.likeDisabled) {
          return false;
        }

        var currentValue;
        this.set("likeDisabled", true);
        var newValue = currentValue = this.currentRating();

        if (!this.liked) {
          this.set("liked", true);
          newValue += 1;
        } else {
          this.set("liked", false);
          newValue -= 1; // Ensure user doesn't set negative rating

          if (currentValue === 0) {
            return false;
          }
        }

        this.set("startAnimation", true);
        this.updateRating(newValue);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Retrieve gaming rules


      var likePerIdeaRule;
      var pointsPerUser;

      if (this.type === "idea") {
        likePerIdeaRule = this.challenge.get("rules").filterBy("active", true).findBy("name", "likes_per_idea");
        pointsPerUser = this.challenge.get("rules").filterBy("active", true).findBy("name", "points_per_user");
      } else if (this.type === "concept") {
        likePerIdeaRule = this.challenge.get("rules").filterBy("active", true).findBy("name", "likes_per_concept");
        pointsPerUser = this.challenge.get("rules").filterBy("active", true).findBy("name", "points_per_user_for_concept");
      }

      if (likePerIdeaRule) {
        this.likePerIdeaLimit = likePerIdeaRule.reward;
      }

      if (pointsPerUser) {
        this.pointsPerUserLimit = pointsPerUser.reward;
      } // Set user rating


      this.$valueSpan = this.$().find(".modifier .value");
      this.$valueSpan.text(this.get("idea.current_user_rating")); // Set global rating

      this.$globalSpan = this.$().find(".result .value");
      this.$globalSpan.text(this.get("idea.total_rating")); // Bind actions (increase / decrease)

      this.bindButtons();
    },
    // Increase / decrease user rating
    bindButtons: function bindButtons() {
      var _this = this;

      this.$().find(".button-less, .button-more").click(function (event) {
        if (_this.likeDisabled) {
          return false;
        }

        var currentValue;
        var $button = $(event.target);

        var newValue = currentValue = _this.currentRating();

        var canUpdateRating = true; // User wants to increase rating

        if ($button.hasClass("button-more")) {
          newValue += 1;
          var givenPoints = _this.currentPoints;
          var userLeftPoints = _this.pointsPerUserLimit - givenPoints;
          var leftPoints = userLeftPoints - 1; // First ensure user doesn't exceed points per user limits

          if (_this.pointsPerUserLimit) {
            if (userLeftPoints <= 0) {
              _this.notifications.error(_this.locale.t("challenges.likes_quota"));

              canUpdateRating = false;
            }
          } // Then, if user limit is not exceeded, ensure user doesn't exceed like per idea limits


          if (canUpdateRating && _this.likePerIdeaLimit && newValue > _this.likePerIdeaLimit) {
            _this.notifications.error(_this.locale.t("challenges.likes_limit", {
              count: _this.likePerIdeaLimit
            }));

            canUpdateRating = false;
          } // If there is no error, send notification on leftPoints


          if (canUpdateRating && !Number.isNaN(userLeftPoints)) {
            _this.notifications.success(_this.locale.t("challenges.likes_remaining", {
              count: leftPoints
            }));
          }
        } // User wants to decrease rating


        if ($button.hasClass("button-less")) {
          newValue -= 1;

          if (currentValue === 0) {
            return false;
          }

          var _givenPoints = _this.currentPoints;

          var _userLeftPoints = _this.pointsPerUserLimit - _givenPoints;

          var _leftPoints = _userLeftPoints + 1; // send notification on left points


          if (!Number.isNaN(_userLeftPoints)) {
            _this.notifications.success(_this.locale.t("challenges.likes_remaining", {
              count: _leftPoints
            }));
          }
        } // Request API for update only if needed


        if (canUpdateRating) {
          _this.set("likeDisabled", true);

          _this.updateRating(newValue);
        }
      });
    },
    // Get current UI value for user rating
    currentRating: function currentRating() {
      return parseInt(this.$valueSpan.html());
    },
    // Get current UI value for global rating
    currentGlobalRating: function currentGlobalRating() {
      return parseInt(this.$globalSpan.html());
    },
    // Request API for user rating update
    updateRating: function updateRating(newValue) {
      this.idea.updateRating(newValue).then(this._updateSuccess.bind(this), this._updateError.bind(this));
    },
    _updateSuccess: function _updateSuccess(data) {
      var diff = data.rating - this.currentRating();
      this.incrementProperty("currentPoints", diff);
      this.$valueSpan.text(data.rating);
      var userLeftPoints = this.pointsPerUserLimit - this.currentPoints; // eslint-disable-line no-unused-vars

      this.$globalSpan.text(this.currentGlobalRating() + diff);
      this.idea.set("total_rating", this.currentGlobalRating());
      this.idea.set("current_user_rating", data.rating);

      if (this.type === "idea") {
        this.get("session.currentChallenge").set("remaining_points_for_ideas_for_current_user", userLeftPoints);
      }

      this.set("likeDisabled", false);
      this.set("startAnimation", false);
    },
    // On update error, notify user
    _updateError: function _updateError(error) {
      this.set("likeDisabled", false);
      this.set("startAnimation", false);
      this.notifications.error(this.locale.t("error"));
    }
  });

  _exports.default = _default;
});