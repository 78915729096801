define("innowaze-ember2/routes/domains/common", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge"], function (_exports, _cleanCurrentModel, _setCurrentChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function () {
          return _this._success();
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this._transitionToList();
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      }
    },
    _transitionToList: function _transitionToList() {
      if (this.currentModel.get("challenge_id")) {
        this.transitionTo("challengeDomains", this.currentModel.get("challenge_id"));
      } else {
        this.transitionTo("domains");
      }
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.errorMessage, response.errors);
    },
    _success: function _success() {
      this.controller.set("saveDisabled", false);
      this.notifications.success(this.successMessage);

      this._transitionToList();
    }
  });

  _exports.default = _default;
});