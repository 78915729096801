define("innowaze-ember2/routes/configuration", ["exports", "innowaze-ember2/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    assetMap: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model() {
      return this.store.findRecord("configuration", 1);
    },
    redirect: function redirect() {
      if (!this.get("session.currentUser.admin")) {
        this.router.transitionTo("challenges");
      }
    },
    setTokenForModel: function setTokenForModel(model) {
      model.set("dict.public_token", Math.random().toString(36).slice(-12));
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var locale = this.locale;

      if (!model.get("dict.public_token")) {
        this.setTokenForModel(model);
      }

      controller.set("themes", _configuration.default.themes);
      controller.reopen({
        tosWithDefault: Ember.computed("model.dict.tos", {
          get: function get() {
            var tos = model.get("dict.tos");

            if (Ember.isBlank(tos)) {
              return locale.t("pages.contents.tos");
            }

            return tos;
          },
          set: function set(key, value) {
            model.set("dict.tos", value);
            return value;
          }
        }),
        aboutWithDefault: Ember.computed("model.dict.about", {
          get: function get() {
            var about = model.get("dict.about");

            if (Ember.isBlank(about)) {
              return locale.t("pages.contents.about");
            }

            return about;
          },
          set: function set(key, value) {
            model.set("dict.about", value);
            return value;
          }
        }),
        editorialWithDefault: Ember.computed("model.dict.editorial", {
          get: function get() {
            var editorial = model.get("dict.editorial");

            if (Ember.isBlank(editorial)) {
              return locale.t("pages.contents.editorial");
            }

            return editorial;
          },
          set: function set(key, value) {
            model.set("dict.editorial", value);
            return value;
          }
        }),
        loginTextWithDefault: Ember.computed("model.dict.login_text", {
          get: function get() {
            var loginText = model.get("dict.login_text");

            if (Ember.isBlank(loginText)) {
              return locale.t("pages.contents.login_text");
            }

            return loginText;
          },
          set: function set(key, value) {
            model.set("dict.login_text", value);
            return value;
          }
        }),
        signupTitle: Ember.computed("model.dict.signup_title", {
          get: function get() {
            var signupTitle = model.get("dict.signup_title");

            if (Ember.isBlank(signupTitle)) {
              return locale.t("homepage.signup_title");
            }

            return signupTitle;
          },
          set: function set(key, value) {
            model.set("dict.signup_title", value);
            return value;
          }
        }),
        signupSubTitle: Ember.computed("model.dict.signup_subtitle", {
          get: function get() {
            var signupSubTitle = model.get("dict.signup_subtitle");

            if (Ember.isBlank(signupSubTitle)) {
              return locale.t("homepage.signup_subtitle");
            }

            return signupSubTitle;
          },
          set: function set(key, value) {
            model.set("dict.signup_subtitle", value);
            return value;
          }
        }),
        toggleHaschanged: Ember.observer("model.dict.universes", function () {
          if (this.get("model.dict.universes")) {
            this.model.set("dict.hide_filters", false);
          }
        }),
        toggleDeeplHaschanged: Ember.observer("model.dict.deepl_translation", function () {
          if (!this.get("model.dict.deepl_translation")) {
            this.model.set("dict.translation_languages", null);
          }
        })
      });
    },
    deactivate: function deactivate() {
      if (this.currentModel.get("hasDirtyAttributes")) {
        this.currentModel.rollbackAttributes();
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function () {
          return _this._success();
        }, function (error) {
          // If configuration was already updated by another user, ask for overwrite confirmation
          if (_this.currentModel.get("errors.current_digest").length > 0) {
            $("#confirmUpdateModal").foundation("reveal", "open");
          }
        });
      },
      forceUpdate: function forceUpdate() {
        var _this2 = this;

        $("#confirmUpdateModal").foundation("reveal", "close");
        this.currentModel.save({
          adapterOptions: {
            force: true
          }
        }).then(function () {
          return _this2._success();
        });
      },
      cancelUpdate: function cancelUpdate() {
        $("#confirmUpdateModal").foundation("reveal", "close"); // refresh replays route hook except setup record so we use it to reload the model

        this.refresh();
      },
      toggleSection: function toggleSection(id) {
        $(".section").addClass("hide");
        $("#".concat(id)).removeClass("hide");
      },
      bannerUploaded: function bannerUploaded(files) {
        this.currentModel.set("banner_id", files.get("firstObject").id);
        this.currentModel.set("banner_url", files.get("firstObject").file_url);
      },
      deleteBanner: function deleteBanner() {
        this.currentModel.set("banner_id", null);
        this.currentModel.set("banner_url", null);
      },
      logoUploaded: function logoUploaded(files) {
        this.currentModel.set("logo_id", files.get("firstObject").id);
        this.currentModel.set("logo_url", files.get("firstObject").file_url);
      },
      deleteLogo: function deleteLogo() {
        this.currentModel.set("logo_id", null);
        this.currentModel.set("logo_url", null);
      },
      loginUploaded: function loginUploaded(files) {
        this.currentModel.set("login_picture_id", files.get("firstObject").id);
        this.currentModel.set("login_picture_url", files.get("firstObject").file_url);
      },
      deleteLoginImage: function deleteLoginImage() {
        this.currentModel.set("login_picture_id", null);
        this.currentModel.set("login_picture_url", null);
      },
      ideaUploaded: function ideaUploaded(files) {
        this.currentModel.set("idea_picture_id", files.get("firstObject").id);
        this.currentModel.set("idea_picture_url", files.get("firstObject").file_url);
      },
      deleteIdeaImage: function deleteIdeaImage() {
        this.currentModel.set("idea_picture_id", null);
        this.currentModel.set("idea_picture_url", null);
      },
      conceptUploaded: function conceptUploaded(files) {
        this.currentModel.set("concept_picture_id", files.get("firstObject").id);
        this.currentModel.set("concept_picture_url", files.get("firstObject").file_url);
      },
      deleteConceptImage: function deleteConceptImage() {
        this.currentModel.set("concept_picture_id", null);
        this.currentModel.set("concept_picture_url", null);
      },
      generateToken: function generateToken() {
        this.setTokenForModel(this.currentModel);
      },
      newEmailDomain: function newEmailDomain(name) {
        this.currentModel.get("email_domains").pushObject(Ember.Object.create({
          id: this.currentModel.get("email_domains").length,
          name: name
        }));
      },
      loadThemeStylesheet: function loadThemeStylesheet(theme) {
        this.assetMap.loadThemeStyleSheet(theme);
      }
    },
    _success: function _success() {
      this.currentModel.set("hasDirtyAttributes", false);
      this.notifications.success("La configuration a été enregistrée.");
    }
  });

  _exports.default = _default;
});