define("innowaze-ember2/components/modules/contributors-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    canAddTeam: Ember.computed.or("canEdit", "model.canEdit"),
    actions: {
      addTeam: function addTeam(user) {
        var _this = this;

        this.model.addTeam(user.id).then(function () {
          _this.get("model.contributors").removeObject(user);

          if (!_this.get("model.teammates").findBy("id", user.id)) {
            _this.get("model.teammates").addObject(user);
          }
        });
      }
    }
  });

  _exports.default = _default;
});