define("innowaze-ember2/components/linked-debate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "a",
    classNames: ["linked-debate", "static"],
    attributeBindings: ["draggable"],
    draggable: "true",
    touchMove: function touchMove(event) {
      event.preventDefault();
    },
    dragStart: function dragStart(event) {
      var imageTag = document.getElementById("draggable-debate-dragimage");
      var dataTransfer = event.originalEvent.dataTransfer;
      dataTransfer.setData("linkedDebate", "true");
      event.dataTransfer.setDragImage(imageTag, 18, 18);
    }
  });

  _exports.default = _default;
});