define("innowaze-ember2/templates/departments/partials/-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZG7P3Vm6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"departments.description\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"customer\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"partner i fa fa-hand-peace-o\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"title\"],[9],[1,[27,\"i18n\",[\"departments.type\"],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[27,\"if\",[[23,[\"typeName\"]],\"selection-box\",\"hide\"],null]],[9],[1,[21,\"typeName\"],false],[10],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"eq\",[[23,[\"model\",\"department_type\"]],2],null],[23,[\"stageName\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[12,\"class\",[27,\"if\",[[23,[\"typeName\"]],\"selection-box-simple\",\"hide\"],null]],[9],[1,[21,\"stageName\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"model\",\"department_type\"]],20],null]],null,{\"statements\":[[4,\"if\",[[23,[\"investmentName\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"selection-box-simple\"],[9],[1,[21,\"investmentName\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"investorName\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"selection-box-simple\"],[9],[1,[21,\"investorName\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[23,[\"model\",\"description\"]],true],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/partials/-description.hbs"
    }
  });

  _exports.default = _default;
});