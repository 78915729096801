define("innowaze-ember2/components/treemap-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ["width", "height"],
    didInsertElement: function didInsertElement() {
      d3.select("#".concat(this.elementId)).selectAll("svg").remove();
      this.addTooltip();
      this.drawChart();
    },
    addTooltip: function addTooltip() {
      return this.tooltip = $("<div>").addClass("tooltip").css("display", "none").insertBefore(this.$());
    },
    ideaMouseover: function ideaMouseover(idea) {
      var _this = this;

      var content = "<header>" + "<a><h1>" + idea.name + " - " + Math.round(idea.size * 100) / 100 + " K€<small> - " + idea.id + " - </small></h1></a></header>";
      return this.tooltip.html(content).css("display", "block").css("left", idea.dx + 10 + "px").css("top", idea.dy / 2 + 10 + "px").on("click", "i.js-close", function () {
        return _this.tooltip.css("display", "none");
      });
    },
    ideaMouseout: function ideaMouseout(idea) {
      return this.tooltip.css("display", "none");
    },
    drawChart: function drawChart() {
      var w = $("#".concat(this.elementId)).width() - 80;
      var h = this.height - 180;
      var x = d3.scale.linear().range([0, w]);
      var y = d3.scale.linear().range([0, h]);
      var color = d3.scale.category20c();
      var treemap = d3.layout.treemap().round(false).size([w, h]).sticky(true).value(function (d) {
        return d.size;
      });
      var svg = d3.select("#".concat(this.elementId)).append("g").attr("class", "chart").style("width", w + "px").style("height", h + "px").append("svg:svg").attr("width", w).attr("height", h).append("svg:g").attr("transform", "translate(.5,.5)");
      var root = this.data;
      var node = root;
      var nodes = treemap.nodes(root).filter(function (d) {
        return !d.children;
      });
      var cell = svg.selectAll("g").data(nodes).enter().append("svg:g").attr("class", "cell").attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      }).on("click", function (d) {
        return zoom(node === d.parent ? root : d.parent);
      });
      cell.append("svg:rect").attr("width", function (d) {
        return d.dx - 1;
      }).attr("height", function (d) {
        return d.dy - 1;
      }).style("fill", function (d) {
        return color(d.parent.name);
      });
      cell.append("svg:text").attr("x", function (d) {
        return d.dx / 2;
      }).attr("y", function (d) {
        return d.dy / 2;
      }).attr("dy", ".35em").attr("text-anchor", "middle").text(function (d) {
        return d.name;
      }).style("opacity", function (d) {
        d.w = this.getComputedTextLength();
        return d.dx > d.w ? 1 : 0;
      });
      cell.on("mouseover", this.ideaMouseover.bind(this));
      cell.on("mouseout", this.ideaMouseout.bind(this));
      d3.select("#".concat(this.elementId)).on("click", function () {
        zoom(root);
      });

      function zoom(d) {
        var kx = w / d.dx;
        var ky = h / d.dy;
        x.domain([d.x, d.x + d.dx]);
        y.domain([d.y, d.y + d.dy]);
        var t = svg.selectAll("g.cell").transition().duration(d3.event.altKey ? 7500 : 750).attr("transform", function (d) {
          return "translate(" + x(d.x) + "," + y(d.y) + ")";
        });
        t.select("rect").attr("width", function (d) {
          return kx * d.dx - 1;
        }).attr("height", function (d) {
          return ky * d.dy - 1;
        });
        t.select("text").attr("x", function (d) {
          return kx * d.dx / 2;
        }).attr("y", function (d) {
          return ky * d.dy / 2;
        }).style("opacity", function (d) {
          return kx * d.dx > d.w ? 1 : 0;
        });
        node = d;
        d3.event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});