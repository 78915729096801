define("innowaze-ember2/routes/challenge-debates/show", ["exports", "innowaze-ember2/routes/debates/common-show"], function (_exports, _commonShow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _commonShow.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        debate: this.store.findRecord("debate", params.debate_id, {
          adapterOptions: {
            debatable_id: params.challenge_id,
            debatable_type: "Challenge"
          },
          reload: true
        })
      });
    }
  });

  _exports.default = _default;
});