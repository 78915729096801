define("innowaze-ember2/routes/debates/edit", ["exports", "innowaze-ember2/routes/debates/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    successMessage: "Le débat a été modifié.",
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        debate: this.store.findRecord("debate", params.debate_id, {
          adapterOptions: {
            debatable_id: params.concept_id,
            debatable_type: "Concept"
          }
        })
      });
    }
  });

  _exports.default = _default;
});