define("innowaze-ember2/components/innowaze-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "input",
    type: "radio",
    attributeBindings: ["name", "type", "checked", "disabled"],
    checked: Ember.computed(function () {
      return this.value === this.selection;
    }),
    click: function click() {
      this.set("selection", this.value);
      $("label.theme-thumb").removeClass("selected");
      $("input[type='radio'][name='theme']:checked").closest("label").addClass("selected");

      if (this.onchange) {
        this.sendAction("onchange", this.value);
      }
    }
  });

  _exports.default = _default;
});