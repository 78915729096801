define("innowaze-ember2/components/eval-axis", ["exports", "innowaze-ember2/utils/formatEvaluation"], function (_exports, _formatEvaluation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    classNames: ["eval-axis"],
    label: null,
    value: 0,
    rangeIndex: 0,
    evaluated: false,
    disabled: false,
    reviewing: false,
    lastEvaluations: Ember.computed("best-ideas", function () {
      if (!this["best-ideas"]) {
        return [];
      }

      return this.get("best-ideas.ideas");
    }),
    minValue: Ember.computed("session.currentChallenge.evaluation_min", function () {
      if (!this.reviewing) {
        return 0;
      }

      return this.get("session.currentChallenge.evaluation_min") || 0;
    }),
    maxValue: Ember.computed("session.currentChallenge.evaluation_max", function () {
      if (!this.reviewing) {
        return 100;
      }

      return this.get("session.currentChallenge.evaluation_max") || 100;
    }),
    step: Ember.computed("session.currentChallenge.evaluation_step", function () {
      if (!this.reviewing) {
        return 1;
      }

      return this.get("session.currentChallenge.evaluation_step") || 1;
    }),
    percent: Ember.computed("value", function () {
      return (0, _formatEvaluation.valueToPercent)(this.value, this.minValue, this.maxValue);
    }),
    displayValue: Ember.computed("value", function () {
      if (this.reviewing) {
        return this.value;
      }

      var max = this.get("session.currentChallenge.evaluation_max") || 100;
      var value = max * this.value / 100;
      return Math.round(value * 10) / 10;
    }),
    displayMinValue: Ember.computed("session.currentChallenge.evaluation_min", function () {
      return this.get("session.currentChallenge.evaluation_min") || 0;
    }),
    displayMaxValue: Ember.computed("session.currentChallenge.evaluation_max", function () {
      return this.get("session.currentChallenge.evaluation_max") || 100;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.evaluated) {
        this.set("evaluated", false);
      }

      if (this.increments) {
        this._snapToIncrements(this.value);
      } else {
        this.set("value", this._controlValue(this.value));
      }
    },
    valueChanged: Ember.observer("value", function () {
      if (this.increments) {
        this._snapToIncrements(this.value);
      } else {
        this.set("value", this._controlValue(this.value));
      }

      this.sendAction("changed", this["tool-name"], this.value, this["tool-id"]);
    }),
    actions: {
      evaluate: function evaluate() {
        this.set("evaluated", true);
        this.sendAction("save", this["tool-name"], this.value);
      },
      revertEvaluation: function revertEvaluation() {
        this.setProperties({
          evaluated: false,
          value: this.minValue
        });
        this.sendAction("save", this["tool-name"], null);
      },
      toggleIsMedian: function toggleIsMedian() {
        if (!this.get("session.isModerator")) {
          return false;
        }

        this.toggleProperty("is_median");
        this.sendAction("onIsMedian", this["tool-name"], this.is_median);
      }
    },
    rangeStyle: Ember.computed("value", function () {
      return Ember.String.htmlSafe("width: ".concat(this.percent, "%"));
    }),
    minStyle: Ember.computed("best-ideas", function () {
      if (this["best-ideas"] && this.get("best-ideas.score_min") !== null) {
        return Ember.String.htmlSafe("left: ".concat(this.get("best-ideas.score_min"), "%"));
      }

      return Ember.String.htmlSafe("display:none");
    }),
    maxStyle: Ember.computed("best-ideas", function () {
      if (this["best-ideas"] && this.get("best-ideas.score_max") !== null) {
        return Ember.String.htmlSafe("left: ".concat(this.get("best-ideas.score_max"), "%"));
      }

      return Ember.String.htmlSafe("display:none");
    }),
    q25Style: Ember.computed("best-ideas", function () {
      if (this["best-ideas"] && this.get("best-ideas.score_q25") !== null) {
        return Ember.String.htmlSafe("left: ".concat(this.get("best-ideas.score_q25"), "%"));
      }

      return Ember.String.htmlSafe("display:none");
    }),
    q50Style: Ember.computed("best-ideas", function () {
      if (this["best-ideas"] && this.get("best-ideas.score_q50") !== null) {
        return Ember.String.htmlSafe("left: ".concat(this.get("best-ideas.score_q50"), "%"));
      }

      return Ember.String.htmlSafe("display:none");
    }),
    q75Style: Ember.computed("best-ideas", function () {
      if (this["best-ideas"] && this.get("best-ideas.score_q75") !== null) {
        return Ember.String.htmlSafe("left: ".concat(this.get("best-ideas.score_q75"), "%"));
      }

      return Ember.String.htmlSafe("display:none");
    }),
    rangeClass: Ember.computed("percent", function () {
      if (this.percent <= 25) {
        return "low";
      }

      if (this.percent <= 50) {
        return "medium-low";
      }

      if (this.percent <= 75) {
        return "medium-high";
      }

      return "high";
    }),
    _controlValue: function _controlValue(value) {
      if (!value || value < this.minValue) {
        return this.minValue;
      }

      if (value > this.maxValue) {
        return this.maxValue;
      }

      return value;
    },
    _snapToIncrements: function _snapToIncrements(value) {
      // Round up to nearest 25%, not exceeding 100%, unless value is 0
      var rangeIndex = value > 0 ? Math.min(Math.ceil(value / 25), 4) : 0;
      this.set("value", rangeIndex * 25);
      this.set("rangeIndex", rangeIndex);
      this.set("rangeExplanation", this.locale.t("ideas.range_explanation_".concat(rangeIndex + 1)));
    }
  });

  _exports.default = _default;
});