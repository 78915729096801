define("innowaze-ember2/components/innowaze-application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["currentRoute"],
    currentRoute: Ember.computed("currentRouteName", function () {
      return "page-".concat(this.currentRouteName);
    })
  });

  _exports.default = _default;
});