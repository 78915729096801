define("innowaze-ember2/components/challenges/ideas-relative-positioning", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ramda/src/clamp", "innowaze-ember2/configuration"], function (_exports, _slicedToArray2, _clamp, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    tagName: "svg",
    classNames: "ideas-positioning",
    attributeBindings: ["width", "height"],
    padding: 100,
    ideasUpdated: Ember.observer("ideas.[]", "axis", "ordinate", "color", "size", "onlyText", function () {
      if (this.ideas) {
        this.addLegend();
        this.createGradients();
        this.addIdeas();
      }
    }),
    svg: function svg() {
      return d3.select("#" + this.elementId);
    },
    didInsertElement: function didInsertElement() {
      /* global Foundation */
      Foundation.global.namespace = ""; // https://foundation.zurb.com/forum/posts/2112-foundation-5100-syntax-error-in-js

      this.drawAxes();
      this.addTooltip();
    },
    drawAxes: function drawAxes() {
      var _this = this;

      this.svg().append("rect").attr("width", this.width).attr("height", this.height).style("fill", "transparent").on("click", function () {
        return _this.ideaMouseout();
      });
      var middleOfX = this.width / 2;
      var middleOfY = this.height / 2; // X axis

      this.svg().append("line").attr("x1", 0).attr("y1", middleOfY).attr("x2", this.width).attr("y2", middleOfY).attr("class", "axis").attr("stroke", "#d7d7d7"); // Y axis

      this.svg().append("line").attr("x1", middleOfX).attr("y1", 0).attr("x2", middleOfX).attr("y2", this.height).attr("class", "axis").attr("stroke", "#d7d7d7");
    },
    addTooltip: function addTooltip() {
      var _this2 = this;

      this.tooltip = $("<div>").addClass("tooltip").css("display", "none").insertBefore(this.$());
      this.tooltip.on("click", "i.js-close", function () {
        return _this2.tooltip.css("display", "none");
      });
    },
    addLegend: function addLegend() {
      this.svg().selectAll("text.legend").remove();
      this.svg().append("text").attr("font-size", "12px").attr("class", "legend").style("font-style", "italic").attr("stroke", "orange").attr("text-anchor", "end").attr("transform", "translate(".concat(this.width / 2 + 4, ", 40) rotate(-90)")).text(this.get("ordinate.title") + " →");
      this.svg().append("text").attr("font-size", "12px").attr("class", "legend").attr("stroke", "orange").attr("text-anchor", "end").attr("transform", "translate(".concat(this.width - 40, ", ").concat(this.height / 2 + 4, ")")).text(this.get("axis.title") + " →");
    },
    addIdeas: function addIdeas() {
      var _this3 = this;

      var group;
      var containerWidth = this.width;
      var containerHeight = this.height;
      var xName = this.get("axis.name");
      var yName = this.get("ordinate.name");
      var xID = this.get("axis.id");
      var yID = this.get("ordinate.id");
      this.svg().selectAll("g.idea").remove(); // Use public ideas only

      var ideas = this.svg().selectAll("g.idea").data(this.ideas.filter(function (idea) {
        return !idea.get("private");
      })); // Create group for idea

      if (this.freeze) {
        group = ideas.enter().append("g").attr("class", "idea").attr("transform", function (d) {
          var x_value;

          if (!isNaN(xName)) {
            var tool = d.data["extra_tools"].findBy("id", xID);
            x_value = tool ? tool.value : 0;
          } else {
            x_value = d.data[xName];
          }

          var y_value;

          if (!isNaN(yName)) {
            var _tool = d.data["extra_tools"].findBy("id", yID);

            y_value = _tool ? _tool.value : 0;
          } else {
            y_value = d.data[yName];
          }

          var x = containerWidth * x_value / 100;
          var y = containerHeight - containerHeight * y_value / 100;
          return "translate(".concat(x, ", ").concat(y, ")");
        });
      } else {
        group = ideas.enter().append("g").attr("class", "idea").attr("transform", function (d) {
          var x_value;

          if (!isNaN(xName)) {
            var tool = d.data["extra_tools"].findBy("id", xID);
            x_value = tool ? tool.value : 0;
          } else {
            x_value = d.data[xName];
          }

          var y_value;

          if (!isNaN(yName)) {
            var _tool2 = d.data["extra_tools"].findBy("id", yID);

            y_value = _tool2 ? _tool2.value : 0;
          } else {
            y_value = d.data[yName];
          }

          var x = containerWidth * x_value / 100;
          var y = containerHeight - containerHeight * y_value / 100;
          return "translate(".concat(x, ", ").concat(y, ")");
        }).call(this.dragListener());
      }

      group.on("click", this.ideaMouseover.bind(this)); // Add circles and texts to group

      var sizeVar = this.get("size.name");

      if (this.onlyText) {
        group.append("circle").attr("fill-opacity", "0.6").attr("r", function (d) {
          if (sizeVar) {
            if (d.data[sizeVar] === 0) {
              return 6;
            }

            return _this3._coordinate_value(d, _this3.size) / 6;
          }

          return 6;
        });
      } else {
        group.append("circle").attr("stroke", "#fff").attr("fill-opacity", "0.6").attr("r", function (d) {
          if (sizeVar) {
            if (d.data[sizeVar] === 0) {
              return 10;
            }

            return _this3._coordinate_value(d, _this3.size);
          }

          return 26;
        });
      }

      if (this.onlyText) {
        group.append("text").attr("dy", "-2em").attr("dx", "1em").style("text-anchor", "middle").style("font-style", "italic").attr("class", "bubble_text").attr("text-anchor", "middle").attr("font-size", "12px");
        group.append("text").attr("dy", "-2em").attr("dx", "4em").style("text-anchor", "middle").style("stroke", "orange").attr("class", "bubble_text").attr("text-anchor", "middle").attr("font-size", "12px");
      } else {
        group.append("text").attr("dy", "-0.4em").style("text-anchor", "middle").attr("class", "bubble_text").attr("text-anchor", "middle").attr("font-size", "12px");
        group.append("text").attr("dy", "1.2em").style("text-anchor", "middle").attr("class", "bubble_text").attr("text-anchor", "middle").attr("font-size", "12px");
      }

      group.append("text").style("text-anchor", "middle").attr("class", "flow_text").attr("dy", "2em").attr("font-size", "12px"); // Update circle classes and text contents

      ideas.selectAll("circle").style("fill", function (idea) {
        return "url(#gradientRadial-".concat(idea.id, ")");
      });

      if (this.onlyText) {
        ideas.select("text:nth-of-type(3)").attr("id", function (d) {
          return "id_".concat(d.id);
        }).attr("dy", function (d) {
          return textFlow(d.get("name"), document.getElementById("id_".concat(d.id)), 100, 55, 15, false);
        });
      }

      ideas.select("text:nth-of-type(1)").text(function (d) {
        return d.id;
      });
      ideas.select("text:nth-of-type(2)").text(function (d) {
        return "".concat(d.get("likesCount"), " \u2661");
      });
    },
    _coordinate_value: function _coordinate_value(idea, axis) {
      var axis_name = axis.name.toString();
      var value;

      if (!isNaN(axis_name)) {
        var tool = idea.data["extra_tools"].findBy("id", axis.id);
        value = tool ? tool.value : 0;
      } else {
        value = idea.get(axis_name);
      }

      return value;
    },
    ideaMouseover: function ideaMouseover(idea) {
      var axis = this.axis;
      var ordinate = this.ordinate;
      var content = "\n      <header>\n        <i class=\"fa fa-times right js-close\" />\n        ".concat(idea.get("logo_url") ? "<a href=\"/challenges/".concat(idea.get("challenge_id"), "/ideas/").concat(idea.id, "\" target=\"_blank\">\n        <img src=\"").concat(_configuration.default.apiUrl).concat(idea.get("logo_url"), "\" />\n      </a>") : "", "\n        <a href=\"/challenges/").concat(idea.get("challenge_id"), "/ideas/").concat(idea.id, "\" target=\"_blank\">\n          <h1>").concat(idea.get("name"), "</h1>\n        </a>\n        <h2>").concat(idea.get("author_name"), "</h2>\n      </header>\n      <p>\n        <ul>\n          <li>").concat(this.get("axis.title"), " : ").concat(this._coordinate_value(idea, axis), "</li>\n          <li>").concat(this.get("ordinate.title"), " : ").concat(this._coordinate_value(idea, ordinate), "</li>\n        </ul>\n        ").concat(idea.get("description"), "\n      </p>\n      <footer>\n        <a href=\"/challenges/").concat(idea.get("challenge_id"), "/ideas/").concat(idea.id, "\" target=\"_blank\">\n          ").concat(this.locale.t("open_in_new_window"), "\n        </a>\n      </footer>\n    ");
      this.tooltip.html(content).css("display", "block");
    },
    ideaMouseout: function ideaMouseout(idea) {
      this.tooltip.css("display", "none");
    },
    dragListener: function dragListener() {
      var _this4 = this;

      var container = this.$().get(0);
      this.$(container).attr("viewBox", "0 0 " + this.width + " 600");
      var containerWidth = this.width;
      var containerHeight = this.height;
      return d3.behavior.drag().on("drag", function (d) {
        var node = d3.select(this);
        var mouseX;
        var mouseY; // Track mouse moves

        var _d3$mouse = d3.mouse(container);

        var _d3$mouse2 = (0, _slicedToArray2.default)(_d3$mouse, 2);

        mouseX = _d3$mouse2[0];
        mouseY = _d3$mouse2[1];
        // Constrain idea group to container
        mouseX = (0, _clamp.default)(0, containerWidth, mouseX);
        mouseY = (0, _clamp.default)(0, containerHeight, mouseY);
        node.attr("transform", "translate(".concat(mouseX, ", ").concat(mouseY, ")"));
      }).on("dragend", function (d) {
        var mouseX;
        var mouseY;

        var _d3$mouse3 = d3.mouse(container);

        var _d3$mouse4 = (0, _slicedToArray2.default)(_d3$mouse3, 2);

        mouseX = _d3$mouse4[0];
        mouseY = _d3$mouse4[1];

        // eslint-disable-line prefer-const
        var xName = _this4.get("axis.name");

        var yName = _this4.get("ordinate.name");

        var xValue = (0, _clamp.default)(0, 100, Math.round(mouseX / containerWidth * 100));
        var yValue = (0, _clamp.default)(0, 100, Math.round(100 - mouseY / containerHeight * 100));

        var idea = _this4.ideas.findBy("id", d.id);

        if (!isNaN(xName)) {
          var axisId = _this4.get("axis.id");

          var tool = idea.get("extra_tools").findBy("id", axisId) || idea.get("extra_tools").pushObject({
            id: axisId
          });
          tool.value = xValue;
          tool.is_median = false;
        } else {
          idea.set(xName, xValue);
          idea.set("".concat(xName, "_is_median"), false);
        }

        if (!isNaN(yName)) {
          var ordinateId = _this4.get("ordinate.id");

          var _tool3 = idea.get("extra_tools").findBy("id", ordinateId) || idea.get("extra_tools").pushObject({
            id: ordinateId
          });

          _tool3.value = yValue;
          _tool3.is_median = false;
        } else {
          idea.set(yName, yValue);
          idea.set("".concat(yName, "_is_median"), false);
        }

        idea.changed = true;
      });
    },
    createGradients: function createGradients() {
      var _this5 = this;

      var fillColor = function fillColor(idea) {
        if (_this5.get("color.name") === "typology") {
          var color = d3.scale.ordinal().domain([1, 2, 3, 4]).range(["orange", "yellow", "green", "blue"]);
          return color(idea.data[_this5.get("color.name")]);
        } else if (_this5.get("color.name") === "first_domain") {
          var _color = d3.scale.category20c();

          _color.domain(idea.get("all_domains"));

          return _color(idea.data[_this5.get("color.name")]);
        } else if (_this5.get("color.name") === "state_name") {
          var _color2 = d3.scale.category20c();

          _color2.domain(idea.get("all_states"));

          return _color2(idea.data[_this5.get("color.name")]);
        }

        return idea.get("color") ? idea.get("color") : "#FFFFFF";
      };

      this.svg().selectAll("g.gradientContainer").remove();
      var gradientContainer = this.svg().append("g").attr("class", "gradientContainer");
      var gradientRadial = gradientContainer.selectAll("radialGradient").data(this.ideas).enter().append("radialGradient").attr("cx", "50%").attr("cy", "50%").attr("r", "50%").attr("fx", "4%").attr("gradientUnists", "objectBoundingBox").attr("id", function (d) {
        return "gradientRadial-".concat(d.id);
      });
      gradientRadial.append("stop").attr("offset", "4%").attr("stop-color", function (d) {
        return d3.rgb(fillColor(d)).brighter(1);
      });
      gradientRadial.append("stop").attr("offset", "40%").attr("stop-color", function (d) {
        return fillColor(d);
      });
      gradientRadial.append("stop").attr("offset", "100%").attr("stop-color", function (d) {
        return d3.rgb(fillColor(d)).darker(1.75);
      });
    }
  });

  _exports.default = _default;
});