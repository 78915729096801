define("innowaze-ember2/templates/departments/partials/-framework", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IYCSTE0M",
    "block": "{\"symbols\":[\"technology\",\"skill\"],\"statements\":[[4,\"if\",[[23,[\"activeModules\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"Classification\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"sectorName\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\"],[9],[1,[27,\"i18n\",[\"departments.sector\"],null],false],[10],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"keyword-list\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[0,\"\\n          \"],[1,[21,\"sectorName\"],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"skillsNames\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\"],[9],[1,[27,\"i18n\",[\"departments.skills_title\"],null],false],[10],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"keyword-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"skillsNames\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"technologiesNames\"]]],null,{\"statements\":[[0,\"      \"],[7,\"hr\"],[9],[10],[0,\"\\n      \"],[7,\"label\"],[9],[1,[27,\"i18n\",[\"departments.technologies_title\"],null],false],[10],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"keyword-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"technologiesNames\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/partials/-framework.hbs"
    }
  });

  _exports.default = _default;
});