define("innowaze-ember2/routes/guides/new", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel"], function (_exports, _cleanCurrentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord("guide");
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function () {
          return _this._success();
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("guides");
      }
    },
    _success: function _success() {
      this.transitionTo("guides");
      this.notifications.success("Le guide a été créé.");
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.locale.t("creation_error"), response.errors);
    }
  });

  _exports.default = _default;
});