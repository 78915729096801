define("innowaze-ember2/routes/news-instance", ["exports", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var queryParams = controller.queryParams;
        queryParams.forEach(function (param) {
          return controller.set(param, null);
        });
        controller.set("page", 1);
      }
    },
    setupController: function setupController(controller, model, transition) {
      var _this = this;

      var domainParams = parseInt(transition.queryParams.domain);
      controller.set("domainParams", domainParams);
      controller.set("isReadyToLoadMore", false);
      controller.set("matchingNews", []);
      controller.set("query", "");
      controller.set("domain", undefined);
      controller.set("availableDomains", []);
      controller.set("tagsToDisplay", []);
      controller.set("tags", []);
      controller.set("canPublish", this.get("session.currentUser.admin"));
      controller.set("filtersHidden", true);
      controller.set("disableElastic", true);
      controller.set("page", 1);
      this.store.query("domain", {}).then(function (domains) {
        controller.set("availableDomains", domains);

        if (domainParams) {
          var domain = domains.findBy("id", domainParams.toString());
          domain.set("selected", true);
          controller.set("selectedDomain", domain.get("name"));
          controller.set("domain", domain.get("id"));
          controller.set("disableElastic", false);
        }

        controller.set("page", 1);

        _this._search();
      });
    },
    actions: {
      deleteNews: function deleteNews(news) {
        var _this2 = this;

        news.destroyRecord().then(function () {
          _this2.controller.set("matchingNews", _this2.get("controller.matchingNews").without(news));

          _this2.notifications.success(_this2.locale.t("news.removed"));

          _this2._search();
        });
      },
      showFilters: function showFilters() {
        this.controller.toggleProperty("filtersHidden");
      },
      search: function search() {
        this.set("controller.page", 1);
        this.set("controller.matchingNews", []);
        this.set("controller.disableElastic", false);

        this._search();
      },
      loadMore: function loadMore() {
        // avoid to search more for nothing
        if (this.get("controller.matchingNews").length > 0 && this.get("controller.isReadyToLoadMore")) {
          this.controller.incrementProperty("page");
          this.controller.set("isLoadingMore", true);

          this._search();
        }
      },
      selectDomain: function selectDomain(domain) {
        var controller = this.controller;
        controller.availableDomains.setEach("selected", false);

        if (controller.domain === domain.get("id")) {
          controller.set("selectedDomain", undefined);
          controller.set("domain", undefined);
        } else {
          controller.set("domain", domain.get("id"));
          controller.set("selectedDomain", domain.get("name"));
          domain.set("selected", true);
        }

        this.send("search");
      },
      toggle: function toggle(id) {
        var label = $("label[for='".concat(id, "']"));

        if (label.length === 0) {
          label = $("label[for='".concat(id, "-selectized']"));
        }

        label.toggleClass("opened closed");
        label.next(".toggable").toggleClass("hide");
      }
    },
    _search: function _search() {
      var _this3 = this;

      this.set("controller.isReadyToLoadMore", false);
      var options = {
        disableElastic: this.get("controller.disableElastic"),
        paginate: true,
        query: this.get("controller.query"),
        tags: this.get("controller.tags").mapBy("name"),
        domain: this.get("controller.domain"),
        newsable_type: ["Department", "Instance"],
        order: "published_at"
      };

      if (this.get("controller.page")) {
        options.page = this.get("controller.page");
      }

      return this.store.query("news", options).then(function (result) {
        _this3.set("controller.totalResults", result.get("meta.total_results"));

        _this3.get("controller.matchingNews").pushObjects(result.toArray());

        _this3.controller.set("isLoadingMore", false);

        _this3.set("controller.isReadyToLoadMore", true);
      });
    }
  });

  _exports.default = _default;
});