define("innowaze-ember2/templates/components/select-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x4cDxl95",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ember-select\",null,[[\"content\",\"selectedValue\"],[[23,[\"customArray\"]],[23,[\"module\",\"computed_category\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/select-category.hbs"
    }
  });

  _exports.default = _default;
});