define("innowaze-ember2/routes/challenges/roles", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/mixins/routes/userListFilters"], function (_exports, _setCurrentChallenge, _selectAutocomplete, _userListFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _selectAutocomplete.default, _userListFilters.default, {
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        tagsToDisplay: [],
        matchingUsers: [],
        departmentsToDisplay: [],
        selectedRoles: [],
        isLoadingMore: true
      });

      this._loadData();
    },
    actions: {
      updateRoles: function updateRoles() {
        var _this = this;

        this._checkRolesConsistency();

        var userIds = this.get("controller.selectedUsers").mapBy("id");
        var roleIds = this.get("controller.selectedRoles").mapBy("id");
        this.currentModel.updateRoles(userIds, roleIds).then(function () {
          _this.controller.get("members").filter(function (member) {
            return userIds.includes(member.user_id.toString());
          }).setEach("roles", roleIds);

          _this.get("controller.matchingUsers").filter(function (user) {
            return userIds.includes(user.id);
          }).setEach("roles", roleIds);
        }).then(function () {
          _this.notifications.success("Droits mis à jour");
        });
      },
      removeRole: function removeRole(role, user) {
        var _this2 = this;

        var newRoles = user.get("roles").without(role);
        this.currentModel.updateRoles([user.id], newRoles).then(function () {
          user.set("roles", newRoles);

          _this2.controller.get("members").filter(function (member) {
            return member.user_id.toString() === user.id;
          }).setEach("roles", newRoles);
        });
      },
      setSelectedRoles: function setSelectedRoles(roles) {
        this.controller.set("selectedRoles", roles);
      }
    },
    _checkRolesConsistency: function _checkRolesConsistency() {
      var selectedRoles = this.get("controller.selectedRoles");
      var admin = selectedRoles.findBy("id", "admin");
      var moderator = selectedRoles.findBy("id", "moderator");
      var reviewer = selectedRoles.findBy("id", "reviewer");
      var blind = selectedRoles.findBy("id", "blind");

      if (admin) {
        this.controller.set("selectedRoles", [admin]);
      } else if (moderator && blind) {
        this.controller.set("selectedRoles", selectedRoles.without(blind));
      } else if (reviewer && blind) {
        this.controller.set("selectedRoles", selectedRoles.without(blind));
      }
    },
    _loadData: function _loadData() {
      this._assignRoles(this._prepareOptions({
        paginate: true,
        challenges: [this.currentModel.id]
      }));
    }
  });

  _exports.default = _default;
});