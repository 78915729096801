define("innowaze-ember2/components/embed-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    secureUrl: Ember.computed("slideUrl", function () {
      var slideUrl = String(this.slideUrl);

      if (slideUrl.includes("https://docs.google.com/") || slideUrl.includes("https://drive.google.com/") || slideUrl.includes("http://r.office.microsoft.com") || slideUrl.includes("//www.slideshare.net/slideshow/embed_code")) {
        return slideUrl;
      }

      return null;
    }),
    textDidChange: Ember.observer("slideUrl", function () {
      this.set("secureUrl", null);

      if (this.slideUrl) {
        var elt = this.slideUrl;

        if (elt.includes("https://docs.google.com/")) {
          this.set("secureUrl", elt.substring(elt.search("https://docs.google.com/a/")).replace("\"", "").replace("pub?", "embed?"));
        } else if (elt.includes("https://drive.google.com/")) {
          this.set("secureUrl", elt.substring(elt.search("https://drive.google.com/")).replace("\"", ""));
        } else if (elt.includes("http://r.office.microsoft.com")) {
          this.set("secureUrl", elt.substring(elt.search("http://r.office.microsoft.com")).replace("\"", ""));
        } else if (elt.includes("//www.slideshare.net/slideshow/embed_code")) {
          this.set("secureUrl", elt.substring(elt.search("//www.slideshare.net/slideshow/embed_code")).replace("\"", ""));
        } else {
          this.set("secureUrl", null);
        }

        this.set("slideUrl", this.secureUrl);
      }
    })
  });

  _exports.default = _default;
});