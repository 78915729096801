define("innowaze-ember2/components/modules/social-module", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/components/modules/module"], function (_exports, _objectSpread2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SOCIALTYPES = [{
    id: 0,
    name: "nothing"
  }, {
    id: 1,
    name: "twitter"
  }, {
    id: 2,
    name: "google+"
  }, {
    id: 3,
    name: "linkedin"
  }, {
    id: 4,
    name: "facebook"
  }, {
    id: 5,
    name: "instagram"
  }, {
    id: 6,
    name: "pinterest"
  }];

  var _default = _module.default.extend({
    availableSocialTypes: Ember.computed("model.fields.social_types", function () {
      var array = this.get("model.socialTypes");
      return SOCIALTYPES.map(function (type) {
        return Ember.Object.create((0, _objectSpread2.default)({}, type, {
          selected: array.includes(type.id)
        }));
      });
    }),
    actions: {
      toggle: function toggle(type) {
        var socialType = this.get("model.socialTypes");

        if (type.id === 0) {
          if (socialType.includes(0)) {
            this.model.set("fields.social_types", "");
          } else {
            this.model.set("fields.social_types", "0");
          }

          return;
        }

        socialType = socialType.includes(type.id) ? socialType.filter(function (id) {
          return id !== type.id;
        }) : socialType.filter(function (id) {
          return id !== 0;
        }).concat(type.id);
        this.model.set("fields.social_types", socialType.join());
      }
    }
  });

  _exports.default = _default;
});