define("innowaze-ember2/routes/users/edit-for-challenge", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/routes/users/common"], function (_exports, _toConsumableArray2, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    model: function model(params, transition) {
      this.set("challenge_id", params.challenge_id);

      if (transition.queryParams.from) {
        try {
          this.set("from", JSON.parse(transition.queryParams.from));
        } catch (e) {
          console.warn(e);
        }
      }

      return this.store.findRecord("user", params.user_id, {
        adapterOptions: {
          challenge_id: params.challenge_id
        }
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.set("displayRequired", true);
      this.store.findRecord("challenge", this.challenge_id).then(function (challenge) {
        _this.session.set("currentChallenge", challenge);
      });
      this.store.adapterFor("challenge").getUsersModules(this.challenge_id).then(function (modules) {
        controller.set("neededModules", modules.user_modules);
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("displayRequired", false);
      }
    },
    actions: {
      closeModal: function closeModal() {
        this.transitionTo("challenges");
      }
    },
    _success: function _success() {
      var _this2 = this;

      this.currentModel.reload();
      this.store.findRecord("challenge", this.challenge_id, {
        reload: true
      }).then(function (challenge) {
        _this2.session.set("currentChallenge", challenge);

        challenge.requestAccess().then(function () {
          if (challenge.get("requires_validation")) {
            challenge.set("awaiting", true);
            $("#accessRequestModal").foundation("reveal", "open");
          } else {
            challenge.set("granted", true);

            _this2.notifications.success(_this2.locale.t("users.edit.profile_updated"));

            if (_this2.from) {
              _this2.transitionTo.apply(_this2, (0, _toConsumableArray2.default)(_this2.from));

              return;
            }

            _this2.transitionTo("challenges.show", challenge.id);
          }
        });
      });
    }
  });

  _exports.default = _default;
});