define("innowaze-ember2/templates/concepts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N1d54Zi8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"concepts.new.title\"],[[\"a_concept\"],[[23,[\"model\",\"challenge\",\"nameForAConceptAccusative\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"large-9 small-12 columns\"],[9],[0,\"\\n  \"],[15,\"concepts/form\",[]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"large-3 small-12 columns\"],[9],[0,\"\\n  \"],[15,\"concepts/sidebar\",[]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/new.hbs"
    }
  });

  _exports.default = _default;
});