define("innowaze-ember2/routes/challenges/edit-users-format", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveChallenge"], function (_exports, _cleanCurrentModel, _setCurrentChallenge, _saveChallenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _setCurrentChallenge.default, _saveChallenge.default, {
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id, {
        reload: true
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        this.saveCurrentChallenge().then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      },
      next: function next() {
        var _this2 = this;

        this.saveForNextStep(4).then(function () {
          return _this2._stepSuccess();
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      toggleVisibility: function toggleVisibility(module) {
        if (this.currentModel.get("mandatory_user_modules").includes(module.id)) {
          this.currentModel.get("mandatory_user_modules").removeObject(module.id);
        } else {
          this.currentModel.get("mandatory_user_modules").addObject(module.id);
        }
      }
    },
    _stepSuccess: function _stepSuccess() {
      this._success();

      if (this.currentModel.get("wants_ideation")) {
        this.transitionTo("challenges.edit_ideas_format", this.currentModel.id);
      } else if (this.currentModel.get("wants_concept")) {
        this.transitionTo("challenges.edit_concepts_format", this.currentModel.id);
      } else {
        this.transitionTo("challenges");
      }
    }
  });

  _exports.default = _default;
});