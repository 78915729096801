define("innowaze-ember2/helpers/includes", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.includes = includes;
  _exports.default = void 0;

  function includes(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        array = _ref2[0],
        element = _ref2[1];

    if (!Array.isArray(array)) {
      Ember.Logger.warn("includes helper has been called on a non array: ", array);
      return null;
    }

    return array.map(function (item) {
      return item.toString();
    }).includes(element.toString());
  }

  var _default = Ember.Helper.helper(includes);

  _exports.default = _default;
});