define("innowaze-ember2/routes/news/show", ["exports", "innowaze-ember2/mixins/routes/toggleNewsPublish"], function (_exports, _toggleNewsPublish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_toggleNewsPublish.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    templateName: "news/show",
    model: function model(params) {
      return this.store.findRecord("news", params.news_id);
    },
    actions: {
      deleteNews: function deleteNews(news) {
        var _this = this;

        if (!confirm(this.locale.t("challenges.show.confirm_delete_news"))) {
          return;
        }

        news.destroyRecord().then(function () {
          _this.transitionTo("challenges.show", news.get("newsable_id"));

          _this.notifications.success(_this.locale.t("challenges.show.news_deleted"));
        });
      }
    }
  });

  _exports.default = _default;
});