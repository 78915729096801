define("innowaze-ember2/adapters/news", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      var disableElastic = query.disableElastic;
      delete query.disableElastic;
      return this._super.apply(this, arguments) + (disableElastic ? "" : "/search");
    },
    buildURL: function buildURL(type, id, snapshot, requestType) {
      if (requestType === "createRecord") {
        var _type = snapshot.record.get("newsable_type");

        var _id = snapshot.record.get("newsable_id");

        if (_type === "Challenge") {
          return "".concat(this.host, "/").concat(this.namespace, "/news.json?challenge_id=").concat(_id);
        }

        if (_type === "Department") {
          return "".concat(this.host, "/").concat(this.namespace, "/news.json?department_id=").concat(_id);
        }

        return "".concat(this.host, "/").concat(this.namespace, "/news.json");
      }

      return this._super.apply(this, arguments);
    },
    togglePublish: function togglePublish(id, news) {
      var url = this._getUrlByType(id, news, "toggle_publish");

      return this.ajax(url, "POST");
    },
    toggleAdvertize: function toggleAdvertize(id, news) {
      var url = this._getUrlByType(id, news, "toggle_advertize");

      return this.ajax(url, "POST");
    },
    requestToPublish: function requestToPublish(id, news) {
      var url = this._getUrlByType(id, news, "request_to_publish");

      return this.ajax(url, "POST");
    },
    _getUrlByType: function _getUrlByType(id, news, endpoint) {
      var type = news.get("newsable_type");
      var newsable_id = news.get("newsable_id");

      if (type === "Challenge" && newsable_id) {
        return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("news"), "/").concat(id, "/").concat(endpoint, ".json?challenge_id=").concat(newsable_id);
      } else if (type === "Department" && newsable_id) {
        return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("news"), "/").concat(id, "/").concat(endpoint, ".json?department_id=").concat(newsable_id);
      }

      return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("news"), "/").concat(id, "/").concat(endpoint, ".json");
    }
  });

  _exports.default = _default;
});