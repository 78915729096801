define("innowaze-ember2/components/idea-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setColor: function setColor(idea) {
        this.sendAction("setColor", idea);
      }
    }
  });

  _exports.default = _default;
});