define("innowaze-ember2/models/opinion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    debate_id: _emberData.default.attr("number"),
    comment_id: _emberData.default.attr("number"),
    choice: _emberData.default.attr("number")
  });

  _exports.default = _default;
});