define("innowaze-ember2/utils/stripTags", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stripTags;
  // HTML tags which can contain unwanted text
  var blackList = ["video", "picture"];

  function stripTags(s) {
    if (typeof s !== "string") {
      console.warn("stripTags util need string parameter");
      return "";
    }

    var tmp = document.createElement("div");
    tmp.innerHTML = s;
    blackList.reduce(function (acc, tag) {
      return [].concat((0, _toConsumableArray2.default)(acc), (0, _toConsumableArray2.default)(tmp.getElementsByTagName(tag)));
    }, []).forEach(function (elt) {
      return elt.parentNode.removeChild(elt);
    });
    return tmp.textContent;
  }
});