define("innowaze-ember2/controllers/challenges/new", ["exports", "innowaze-ember2/controllers/challenges/edit-common"], function (_exports, _editCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _editCommon.default.extend({});

  _exports.default = _default;
});