define("innowaze-ember2/routes/tags", ["exports", "ember-data", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _emberData, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model() {
      return this.store.query("tag", {});
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("query", "");
      controller.set("tagsToDisplay", []);
      controller.reopen({
        "matchingTags": Ember.computed("model.[]", "query", function () {
          var _this = this;

          if ("" === this.query.trim()) {
            return this.model;
          }

          return _emberData.default.PromiseArray.create({
            promise: this.store.query("tag", {
              query: this.query
            }).then(function (tags) {
              var tagsId = tags.map(function (tag) {
                return tag.id.toString();
              });
              return _this.model.filter(function (item) {
                return tagsId.includes(item.get("id").toString());
              });
            })
          });
        })
      });
    },
    actions: {
      merge: function merge() {
        var _this2 = this;

        if (this.get("controller.mergedTags.length") > 1 && this.get("controller.targetTag")) {
          if (confirm("".concat(this.locale.t("tags.merge_alert"), " ").concat(this.get("controller.targetTag.name")))) {
            this.store.adapterFor("tag").mergeTags(this.get("controller.mergedTags"), this.get("controller.targetTag"), this.get("controller.deleteOldTags")).then(function (tags) {
              _this2.controller.setProperties({
                model: tags,
                mergedTags: null,
                deleteOldTags: false,
                targetTag: null
              });

              _this2.notifications.success(_this2.locale.t("tags.success"));
            });
          }
        } else if (this.get("controller.mergedTags.length") <= 1) {
          this.notifications.error(this.locale.t("tags.alert1"));
        } else {
          this.notifications.error(this.locale.t("tags.alert2"));
        }
      },
      cancel: function cancel() {
        this.controller.setProperties({
          mergedTags: null,
          deleteOldTags: false,
          targetTag: null
        });
      },
      delete: function _delete(tag) {
        var _this3 = this;

        if (confirm("Voulez-vous vraiment supprimer le tag ?")) {
          tag.deleteRecord();
          tag.save().then(function () {
            _this3.set("controller.model", _this3.get("controller.model").without(tag));

            _this3.notifications.success("Le tag a été supprimé.");
          });
        }
      },
      setMergedTags: function setMergedTags(tags) {
        this.controller.set("mergedTags", tags);
      },
      setTargetTag: function setTargetTag(tag) {
        this.controller.set("targetTag", tag);
      }
    }
  });

  _exports.default = _default;
});