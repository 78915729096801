define("innowaze-ember2/templates/departments/partials/-desired_partnership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B8hpMt+P",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"desired_partnership_description\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"departments.desired_partnership\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[1,[23,[\"model\",\"desired_partnership_description\"]],true],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/partials/-desired_partnership.hbs"
    }
  });

  _exports.default = _default;
});