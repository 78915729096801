define("innowaze-ember2/adapters/user-module", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-inflector", "innowaze-ember2/adapters/application"], function (_exports, _defineProperty2, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BatchUpdatesAdapterMixin = {
    batchUpdatesTimeout: 50,
    batchUpdatesPathForType: function batchUpdatesPathForType(type) {
      return this.pathForType(type);
    },
    batchUpdatesBuildUrl: function batchUpdatesBuildUrl(type) {
      var container = this.batchUpdatesPathForType(type);
      return "".concat(this.host, "/").concat(this.namespace, "/").concat(container, "/update_all.json");
    },
    _batchUpdates_handle: null,
    _batchUpdates_queue: [],
    _batchUpdates_resolvers: [],
    _batchUpdates: function _batchUpdates(type) {
      var _this = this;

      var container = this.batchUpdatesPathForType(type);
      var records = (0, _defineProperty2.default)({}, container, this._batchUpdates_queue);
      this._batchUpdates_queue = [];
      this.ajax(this.batchUpdatesBuildUrl(type), "PUT", {
        data: records
      }).then(function () {
        _this._batchUpdates_resolvers.forEach(function (p) {
          return p.resolve();
        });

        _this._batchUpdates_resolvers = [];
      }, function () {
        _this._batchUpdates_resolvers.forEach(function (p) {
          return p.reject();
        });

        _this._batchUpdates_resolvers = [];
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this2 = this;

      var record = Object.assign({}, snapshot._attributes, {
        id: snapshot.id
      });

      if (this._batchUpdates_handle) {
        clearTimeout(this._batchUpdates_handle);
      }

      this._batchUpdates_queue.push(record);

      this._batchUpdates_handle = setTimeout(this._batchUpdates.bind(this, type.modelName), this.batchUpdatesTimeout);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._batchUpdates_resolvers.push({
          resolve: resolve,
          reject: reject
        });
      });
    }
  };

  var _default = _application.default.extend(BatchUpdatesAdapterMixin, {
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    }
  });

  _exports.default = _default;
});