define("innowaze-ember2/adapters/challenge", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    locale: Ember.inject.service(),
    router: Ember.inject.service(),
    reorderChallenges: function reorderChallenges(challenge_ids) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenges"), "/reorder.json"), "PUT", {
        data: {
          challenges: challenge_ids
        }
      });
    },
    shouldReloadRecord: function shouldReloadRecord(store, snapshot) {
      var storedChallenge = store.peekRecord("challenge", snapshot.id);
      return !(storedChallenge && storedChallenge.get("full_model"));
    },
    updateIdeaModuleExplanation: function updateIdeaModuleExplanation(id, challenge, module) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/update_explanation_idea_module.json"), "POST", {
        data: {
          module_id: module.id,
          explanation: module.explanation,
          locale: this.locale.currentLocale
        }
      });
    },
    updateStateInWidget: function updateStateInWidget(id, challenge, module, state) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/update_state_in_widget.json"), "POST", {
        data: {
          module_id: module.id,
          state: state
        }
      });
    },
    resetIdeasVotes: function resetIdeasVotes(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reset_ideas_votes.json"), "POST");
    },
    resetIdeasEvaluations: function resetIdeasEvaluations(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reset_ideas_evaluations.json"), "POST");
    },
    resetConceptsVotes: function resetConceptsVotes(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reset_concepts_votes.json"), "POST");
    },
    updateMembers: function updateMembers(id, challenge, user_ids, granted, no_email) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/members.json"), "PUT", {
        data: {
          user_ids: user_ids,
          granted: granted,
          no_email: no_email
        }
      });
    },
    removeMembers: function removeMembers(id, challenge, user_ids) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/remove_members.json"), "DELETE", {
        data: {
          user_ids: user_ids
        }
      });
    },
    updateRoles: function updateRoles(id, challenge, user_ids, roles) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/roles.json"), "PUT", {
        data: {
          user_ids: user_ids,
          roles: roles
        }
      });
    },
    emailing: function emailing(id, challenge, all_participants, all_users, all_authors, all_evaluators, all_incomplete_profiles, user_ids, message, title, last_ideas, last_comments, open_debates, last_concepts_comments, last_concept_debates_comments, last_debates_comments) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/emailing.json"), "POST", {
        data: {
          all_participants: all_participants,
          all_users: all_users,
          all_authors: all_authors,
          all_evaluators: all_evaluators,
          all_incomplete_profiles: all_incomplete_profiles,
          user_ids: user_ids,
          message: message,
          title: title,
          last_ideas: last_ideas,
          last_comments: last_comments,
          open_debates: open_debates,
          last_concepts_comments: last_concepts_comments,
          last_concept_debates_comments: last_concept_debates_comments,
          last_debates_comments: last_debates_comments
        }
      });
    },
    exportIdeasCSV: function exportIdeasCSV(id, challenge) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      delete options.extended;
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/ideas_csv_search.json"), "GET", {
        data: options
      });
    },
    requestAccess: function requestAccess(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/request_access.json"), "POST");
    },
    findTemplates: function findTemplates() {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenge_templates.json"), "GET");
    },
    getHotIdeas: function getHotIdeas(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/hot_ideas.json"), "GET");
    },
    getMemberships: function getMemberships(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/get_memberships.json"), "GET");
    },
    reorderDomains: function reorderDomains(id, challenge, domains) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reorder_domains.json"), "POST", {
        data: {
          domains: domains.mapBy("id")
        }
      });
    },
    updateSupertags: function updateSupertags(id, datasetId, ideas, reset) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(id, "/update_supertags.json").concat(reset ? "?reset=1" : ""), "PUT", {
        data: {
          dataset_id: datasetId,
          ideas: ideas
        }
      });
    },
    updateModuleExplanation: function updateModuleExplanation(id, challenge, module) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/update_explanation_module.json"), "POST", {
        data: {
          module_id: module.id,
          explanation: module.explanation,
          locale: this.locale.currentLocale
        }
      });
    },
    updateIdeaModuleTemplate: function updateIdeaModuleTemplate(id, challenge, module) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/update_idea_module_template.json"), "POST", {
        data: {
          module_id: module.id,
          template: module.params.template
        }
      });
    },
    saveInvitation: function saveInvitation(id, challenge, emails, message, role) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/invitations.json"), "POST", {
        data: {
          invitation: {
            emails: emails,
            message: message,
            role: role
          }
        }
      });
    },
    getUsersModules: function getUsersModules(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/users_modules.json"), "GET");
    },
    reportingIdeasPer: function reportingIdeasPer(id, params) {
      var action = "ideas_per_" + params["type"];
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reportings/").concat(action, ".json"), "GET", {
        data: params
      });
    },
    treemapIdeas: function treemapIdeas(id, invisible, hub, replication) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reportings/ideas_treemap_per_savings.json"), "GET", {
        data: {
          hub: hub,
          invisible: invisible,
          replication: replication
        }
      });
    },
    tagsCloud: function tagsCloud(id, invisible, hub, replication) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reportings/tags_cloud.json"), "GET", {
        data: {
          hub: hub,
          invisible: invisible,
          replication: replication
        }
      });
    },
    likedIdeas: function likedIdeas(id, invisible, hub, replication) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/liked_ideas.json"), "GET", {
        data: {
          hub: hub,
          invisible: invisible
        }
      });
    },
    reportingKpiOverTime: function reportingKpiOverTime(id, challenge, dataset_id, start_date, end_date) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reportings/statistics.json"), "GET", {
        data: {
          dataset_id: dataset_id,
          start_date: start_date,
          end_date: end_date,
          locale: this.locale.currentLocale
        }
      });
    },
    findInvitations: function findInvitations(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/invitations.json"), "GET").then(function (invitation) {
        return Ember.Object.create(invitation);
      });
    },
    duplicate: function duplicate(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/duplicate.json"), "POST");
    },
    populate_experts: function populate_experts(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/populate_experts.json"), "POST");
    },
    templatize: function templatize(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/templatize.json"), "POST");
    },
    close: function close(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/close.json"), "POST");
    },
    unclose: function unclose(id, challenge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/unclose.json"), "POST");
    },
    reportingWorkload: function reportingWorkload(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reportings/workload.json"), "GET");
    },
    departmentsStatistics: function departmentsStatistics(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/reportings/departments_statistics.json"), "GET");
    },
    getChallenge: function getChallenge(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, ".json"), "GET");
    },
    removeBadge: function removeBadge(id, challenge, userId, badge) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/remove_badge.json"), "DELETE", {
        data: {
          user_id: userId,
          badge_id: badge.id
        }
      });
    },
    updateBadges: function updateBadges(id, challenge, userIds, badges) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("challenge"), "/").concat(id, "/badges.json"), "PUT", {
        data: {
          user_ids: userIds,
          badge_ids: badges.mapBy("id")
        }
      });
    },
    findGroups: function findGroups() {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenge_groups.json"), "GET");
    }
  });

  _exports.default = _default;
});