define("innowaze-ember2/models/linked-debate-badge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    debate_id: _emberData.default.attr(),
    badgeable_type: _emberData.default.attr("string"),
    badgeable_id: _emberData.default.attr("string"),
    concept_id: _emberData.default.attr(),
    left: _emberData.default.attr(),
    top: _emberData.default.attr(),
    name: _emberData.default.attr("string"),
    explanation: _emberData.default.attr("string"),
    style: Ember.computed("left", "top", function () {
      return Ember.String.htmlSafe("position:absolute;left:".concat(this.left, "%;top:").concat(this.top, "%;"));
    })
  });

  _exports.default = _default;
});