define("innowaze-ember2/models/blueocean", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    badges: _emberData.default.hasMany("linked-debate-badge", {
      async: false
    }),
    curves: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    factors: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    references: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    sortedFactors: Ember.computed("factors.[]", function () {
      return this.factors.sortBy("position");
    })
  });

  _exports.default = _default;
});