define("innowaze-ember2/templates/components/market-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DE3jjNLr",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"table\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"hidden-input\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"computedChecked\"]]]]],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"toggable-label\"],[12,\"style\",[28,[\"background-color: \",[27,\"unbound\",[[23,[\"marketLabel\",\"color\"]]],null],\"; color: #fff;\"]]],[9],[0,\"\\n          \"],[1,[21,\"name\"],false],[0,\" (\"],[1,[23,[\"marketLabel\",\"humanValue\"]],false],[0,\")\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"computedChecked\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"td\"],[9],[0,\"\\n          \"],[1,[27,\"market-item-input\",null,[[\"item\",\"hostLabel\",\"marketLabel\",\"setFormulaMarketValue\"],[[22,1,[]],[23,[\"hostLabel\"]],[23,[\"marketLabel\"]],\"setFormulaMarketValue\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"    \"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"computedChecked\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"ember-select\",null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"selectedValue\",\"action\"],[[23,[\"periods\"]],\"value\",\"name\",[23,[\"computedPeriod\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"setPeriod\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/market-item.hbs"
    }
  });

  _exports.default = _default;
});