define("innowaze-ember2/templates/departments/partials/-crm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cw1dMDsg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"or\",[[23,[\"session\",\"currentUser\",\"admin\"]],[23,[\"session\",\"isDepartmentScout\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"crm-title\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[1,[27,\"i18n\",[\"departments.crm\"],null],false],[10],[0,\"\\n    \"],[7,\"a\"],[3,\"action\",[[22,0,[]],\"toggleCRM\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-pencil-square-o\"],[9],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"crm\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"crmDisabled\"]],\"hide\",\"page-module crm-box\"],null]],[9],[0,\"\\n      \"],[1,[23,[\"model\",\"crm\"]],true],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"crmDisabled\"]],\"page-module remark-form\",\"hide\"],null]],[9],[0,\"\\n    \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"placeholder\",\"onchange\"],[[23,[\"model\",\"crm\"]],[27,\"i18n\",[\"departments.description_placeholder\"],null],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.crm\"],null]]]],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"button tiny button-primary right\"],[3,\"action\",[[22,0,[]],\"updateCRM\"]],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"save\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/partials/-crm.hbs"
    }
  });

  _exports.default = _default;
});