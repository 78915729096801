define("innowaze-ember2/controllers/knowledges/new", ["exports", "innowaze-ember2/controllers/knowledges/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({});

  _exports.default = _default;
});