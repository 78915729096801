define("innowaze-ember2/routes/challenge-juries/edit", ["exports", "innowaze-ember2/routes/challenge-juries/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    model: function model(params) {
      return this.store.findRecord("jury", params.jury_id, {
        adapterOptions: {
          challenge_id: params.challenge_id
        }
      });
    },
    _success: function _success() {
      this.transitionTo("challengeJuries", this.currentModel.get("challenge_id"));
      this.notifications.success(this.locale.t("challenges.juries.updated"));
    }
  });

  _exports.default = _default;
});