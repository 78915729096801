define("innowaze-ember2/templates/components/social-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cDB8MMUO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"share__button\"],[11,\"target\",\"_blank\"],[12,\"href\",[21,\"twitterShareUrl\"]],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-twitter\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"a\"],[11,\"class\",\"share__button\"],[11,\"target\",\"_blank\"],[12,\"href\",[21,\"facebookShareUrl\"]],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-facebook\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"a\"],[11,\"class\",\"share__button\"],[11,\"target\",\"_blank\"],[12,\"href\",[21,\"linkedInShareUrl\"]],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-linkedin\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/social-share.hbs"
    }
  });

  _exports.default = _default;
});