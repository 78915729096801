define("innowaze-ember2/templates/departments/modules/-customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DoL/hxqN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"model\",\"typeIsInternal\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[1,[27,\"i18n\",[\"departments.main_customers\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[1,[27,\"ember-selectize\",null,[[\"multiple\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\",\"content\",\"selection\",\"create-item\"],[true,\"content.id\",\"content.name\",[27,\"i18n\",[\"users.modules.skills.tags\"],null],[23,[\"selectedCustomers\"]],[23,[\"selectedCustomers\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"newCustomer\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-customers.hbs"
    }
  });

  _exports.default = _default;
});