define("innowaze-ember2/templates/components/document-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TObMhvGY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"report-modal\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"remove\"],[3,\"action\",[[22,0,[]],\"toggleDocumentForm\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fields\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"placeholder\"],[\"text\",[23,[\"name\"]],[27,\"i18n\",[\"challenges.documents.name\"],null]]]],false],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"file-upload-module\"],[9],[7,\"input\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"filename\"],[9],[1,[27,\"i18n\",[\"file_upload\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"loading \",[21,\"hide\"]]]],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"style\",[21,\"progressStyle\"]],[11,\"class\",\"buffer\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/document-upload.hbs"
    }
  });

  _exports.default = _default;
});