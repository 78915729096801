define("innowaze-ember2/routes/logout", ["exports", "innowaze-ember2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    beforeModel: function beforeModel(params) {
      localStorage.removeItem("userEmail");
      this.session.set("currentUser", null);

      if (_environment.default.enableTokenAuth) {
        localStorage.removeItem("userToken");
      }

      this.session.set("loggedIn", false);

      if (this.session.get("configuration.dict.public_pages")) {
        this.router.transitionTo("challenges");
        return;
      }

      this.router.transitionTo("login");
    }
  });

  _exports.default = _default;
});