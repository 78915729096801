define("innowaze-ember2/adapters/email-template", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-inflector", "innowaze-ember2/adapters/application"], function (_exports, _objectSpread2, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    },
    getTemplates: function getTemplates() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var p = (0, _objectSpread2.default)({}, params);
      this.store.unloadAll("email-template");
      return this.store.query("email-template", params).then(function (templates) {
        var templatesArray = [];

        if (templates.get("length") > 0) {
          templatesArray.addObjects(templates);
          var attrs = {};
          attrs.challenge_id = p.challenge_id ? p.challenge_id : null;
          templatesArray.addObject(_this.store.createRecord("email-template", (0, _objectSpread2.default)({
            id: 0,
            name: "Aucun",
            description: "<p></p>"
          }, attrs)));
        }

        return templatesArray.sortBy("id");
      });
    }
  });

  _exports.default = _default;
});