define("innowaze-ember2/routes/challenge-debates/new", ["exports", "innowaze-ember2/routes/debates/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    successMessage: "Le débat a été créé.",
    errorMessage: Ember.computed(function () {
      return this.locale.t("creation_error");
    }),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        debate: this.store.createRecord("debate", {
          debatable_id: params.challenge_id,
          debatable_type: "Challenge",
          active: false,
          visible_in_widget: true,
          available_answers: [],
          comments: [],
          comments_count: 0
        })
      });
    }
  });

  _exports.default = _default;
});