define("innowaze-ember2/helpers/asset-path", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    assetMap: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          name = _ref2[0];

      return this.assetMap.resolve(name);
    }
  });

  _exports.default = _default;
});