define("innowaze-ember2/controllers/challenges/rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedUsers: Ember.computed.filterBy("matchingUsers", "selected", true),
    noSelection: Ember.computed.empty("selectedUsers.[]"),
    selectedCount: Ember.computed("selectedUsers.[]", function () {
      return this.get("selectedUsers.length");
    }),
    revelancyFilterActive: Ember.computed.equal("order", undefined),
    scoreFilterActive: Ember.computed.equal("order", "score"),
    createdAtFilterActive: Ember.computed.equal("order", "created_at")
  });

  _exports.default = _default;
});