define("innowaze-ember2/templates/users/modules/-udm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MeIvds/a",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"fields\",\"udm_member\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[28,[[27,\"asset-path\",[[27,\"concat\",[\"/assets/images/\",[23,[\"type\"]],\"udm-logo.png\"],null]],null]]]],[9],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/modules/-udm.hbs"
    }
  });

  _exports.default = _default;
});