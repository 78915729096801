define("innowaze-ember2/routes/pages/show", ["exports", "innowaze-ember2/utils/stripTags"], function (_exports, _stripTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    headData: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("page", params.slug);
    },
    afterModel: function afterModel(model) {
      if (this.session.get("configuration.dict.public_pages")) {
        this.setProperties({
          "headData.title": "".concat(model.get("title"), " | ").concat(this.get("session.configuration.dict.instance")),
          "headData.description": (0, _stripTags.default)(model.get("body"))
        });
      }
    }
  });

  _exports.default = _default;
});