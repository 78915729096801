define("innowaze-ember2/templates/components/search-expert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ezxsiyGI",
    "block": "{\"symbols\":[\"expert\",\"skill\"],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"idea-primary-action\"],[3,\"action\",[[22,0,[]],\"toggleExperts\"]],[9],[1,[27,\"i18n\",[\"search_expert\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"hideExperts\"]],\"hide\"],null]],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"comment-list\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"matchingExperts\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"matchingExperts\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"avatar\"],[9],[0,\"\\n              \"],[7,\"img\"],[12,\"src\",[28,[[22,1,[\"avatar\"]]]]],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"username\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"users.show\",[22,1,[\"id\"]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[0,\"                \"],[1,[22,1,[\"fullname\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"              \"],[1,[27,\"send-email\",null,[[\"users\"],[[22,1,[\"id\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"],[4,\"if\",[[22,1,[\"skillNames\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\"],[11,\"class\",\"tags\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"skillNames\"]]],null,{\"statements\":[[0,\"                  \"],[7,\"span\"],[11,\"class\",\"skill\"],[9],[1,[22,2,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"              \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[1,[27,\"i18n\",[\"ideas.show.no_expert\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/search-expert.hbs"
    }
  });

  _exports.default = _default;
});