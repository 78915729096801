define("innowaze-ember2/templates/components/concept-canevas-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZYXb0Jtl",
    "block": "{\"symbols\":[\"label\"],\"statements\":[[4,\"if\",[[23,[\"content_only\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n    \"],[1,[23,[\"cell\",\"content\"]],true],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"caneva-icon \",[23,[\"cell\",\"icon\"]]]]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"\\n      \"],[1,[23,[\"cell\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n    \"],[1,[23,[\"cell\",\"content\"]],true],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[23,[\"header_only\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"cell\",\"labels\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"concept-canevas-label\",null,[[\"label\",\"canEdit\",\"draggable\",\"removeLabel\",\"editLabel\",\"selectForValueEdition\"],[[22,1,[]],[23,[\"canEdit\"]],[23,[\"canEdit\"]],\"removeLabel\",\"editLabel\",\"selectForValueEdition\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/concept-canevas-cell.hbs"
    }
  });

  _exports.default = _default;
});