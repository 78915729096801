define("innowaze-ember2/serializers/guide", ["exports", "innowaze-ember2/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      return {
        guide: json
      };
    }
  });

  _exports.default = _default;
});