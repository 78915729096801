define("innowaze-ember2/routes/email-templates/new", ["exports", "innowaze-ember2/routes/email-templates/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    model: function model(params) {
      return this.store.createRecord("email-template");
    },
    _success: function _success() {
      this.transitionTo("emailTemplates");
      this.notifications.success("Le modèle de courrier a été créé");
    }
  });

  _exports.default = _default;
});