define("innowaze-ember2/templates/tags/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Id3hD8Dm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"Modification du mot clé : \"],[1,[23,[\"model\",\"name\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,\"tags/form\",[]],[0,\"\\n\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/tags/edit.hbs"
    }
  });

  _exports.default = _default;
});