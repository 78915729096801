define("innowaze-ember2/templates/email-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ocftl2GP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"devise-form\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Resend confirmation instructions\"],[10],[0,\"\\n      \"],[7,\"form\"],[3,\"action\",[[22,0,[]],\"resend\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[7,\"label\"],[11,\"for\",\"user_email\"],[9],[0,\"Email\"],[10],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"id\",\"value\",\"type\",\"autofocus\",\"placeholder\",\"required\"],[\"user_email\",[23,[\"model\",\"email\"]],\"email\",\"true\",\"Email\",\"true\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[9],[1,[27,\"input\",null,[[\"type\",\"value\"],[\"submit\",\"Resend confirmation\"]]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[4,\"link-to\",[\"login\"],[[\"class\"],[\"signin-access\"]],{\"statements\":[[1,[27,\"i18n\",[\"devise.shared.already_account\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"      \"],[4,\"link-to\",[\"signup\"],[[\"class\"],[\"signup-access\"]],{\"statements\":[[1,[27,\"i18n\",[\"devise.shared.register\"],null],true]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/email-confirm.hbs"
    }
  });

  _exports.default = _default;
});