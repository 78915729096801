define("innowaze-ember2/controllers/concepts/canevas", ["exports", "ramda/src/sum"], function (_exports, _sum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    labelModalVisible: false,
    canEditConcept: Ember.computed("session.isModerator", "model.concept.user_id", "model.concept.teammates", function () {
      var currentUserId = this.get("session.currentUser.id");
      return this.get("session.isModerator") || this.get("model.concept.user_id") === currentUserId || this.get("model.concept.teammates").find(function (user) {
        return user.id === currentUserId;
      }) !== undefined;
    }),
    unusedLabels: Ember.computed("data.labels.[]", "data.labels.@each.table_data_id", function () {
      if (this.get("data.labels")) {
        return this.get("data.labels").rejectBy("table_data_id");
      }

      return [];
    }),
    hasBalance: Ember.computed("data.table", function () {
      var flattenCells = [].concat.apply([], this.get("data.table"));
      var kinds = flattenCells.mapBy("kind");
      return kinds.includes("cost") && kinds.includes("revenue");
    }),
    balance: Ember.computed("data.labels.@each.value", function () {
      return this.revenuesSum - this.costsSum;
    }),
    revenuesSum: Ember.computed("data.labels.@each.value", function () {
      var labels = this.get("data.labels") || [];
      return (0, _sum.default)(labels.filterBy("kind", "revenue").mapBy("value"));
    }),
    costsSum: Ember.computed("data.labels.@each.value", function () {
      var labels = this.get("data.labels") || [];
      return (0, _sum.default)(labels.filterBy("kind", "cost").mapBy("value"));
    }),
    investmentsSum: Ember.computed("data.labels.@each.value", function () {
      var labels = this.get("data.labels") || [];
      return (0, _sum.default)(labels.filterBy("kind", "investment").mapBy("value"));
    }),
    payback: Ember.computed("investmentsSum", "balance", function () {
      return Math.round(this.investmentsSum * 100 / this.balance) / 100;
    }),
    labelFormulas: Ember.computed.mapBy("data.labels", "formula"),
    usedCostFormulas: Ember.computed("data.formulas.[]", "labelFormulas.@each.selectedFormula", function () {
      var _this = this;

      var formulas = this.get("data.formulas") || [];
      return formulas.filter(function (formula) {
        return formula.get("kind") === "cost" && _this.labelFormulas.isAny("selectedFormula", formula);
      });
    }),
    usedRevenueFormulas: Ember.computed("data.formulas.[]", "labelFormulas.@each.selectedFormula", function () {
      var _this2 = this;

      var formulas = this.get("data.formulas") || [];
      return formulas.filter(function (formula) {
        return formula.get("kind") === "revenue" && _this2.labelFormulas.isAny("selectedFormula", formula);
      });
    }),
    usedInvestmentFormulas: Ember.computed("data.formulas.[]", "labelFormulas.@each.selectedFormula", function () {
      var _this3 = this;

      var formulas = this.get("data.formulas") || [];
      return formulas.filter(function (formula) {
        return formula.get("kind") === "investment" && _this3.labelFormulas.isAny("selectedFormula", formula);
      });
    }),
    toggleAnalysis: Ember.observer("canvasPublished", function () {
      this.get("model.concept").toggleAnalysis(this.kind, this.canvasPublished.toString());
    }),
    convertCommaToPoint: Ember.observer("data.labels.@each.value", function () {
      if (!this.get("data.labels")) {
        return;
      }

      return this.get("data.labels").forEach(function (label) {
        var value = label.get("value");

        if (value && value.toString().indexOf(",") >= 0) {
          return label.set("value", value.replace(",", "."));
        }
      });
    })
  });

  _exports.default = _default;
});