define("innowaze-ember2/components/debate-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "i",
    classNames: ["fa"],
    classNameBindings: ["icon"],
    type: "OpenDebate",
    icon: Ember.computed("type", function () {
      switch (this.type) {
        case "ClaimDebate":
          return "fa-thumbs-up";

        case "SurveyDebate":
          return "fa-list-ul";

        case "InsightDebate":
          return "fa-stethoscope";

        case "CardsSortingDebate":
          return "fa-sort";

        case "MultipleChoicesDebate":
          return "fa-check";

        case "Ideas":
          return "fa-lightbulb-o";

        case "OpenDebate":
        default:
          return "fa-comments";
      }
    })
  });

  _exports.default = _default;
});