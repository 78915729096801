define("innowaze-ember2/components/modules/features-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    locale: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set("selectors", []);
      var selector_values = this.get("model.selectors");
      this.get("module.params.selectors").forEach(function (selector, index) {
        var i = 0;
        var items = Ember.A();
        items.addObject(Ember.Object.create({
          id: undefined,
          title: _this.locale.t("ideas.modules.features.undefined")
        }));

        while (i < parseInt(selector)) {
          i += 1;
          items.addObject(Ember.Object.create({
            id: i,
            title: _this.locale.t("ideas.modules.features.selector".concat(index, ".item").concat(i))
          }));
        }

        _this.selectors.addObject(Ember.Object.create({
          title: _this.locale.t("ideas.modules.features.selector".concat(index, ".title")),
          availableItems: items,
          value: selector_values[index],
          selectedItem: items.findBy("id", selector_values[index])
        }));
      });
    },
    completedDidChange: Ember.on("init", Ember.observer("selectors.@each.value", "locale.currentLocale", function () {
      this.set("module.isNotCompleted", this.selectors.filterBy("value", undefined).length > 0 || this.selectors.filterBy("value", null).length > 0);
      this.set("model.selectors", this.selectors.mapBy("value"));

      if (this.get("locale.currentLocale") !== this.lang) {
        this._loadTranslations();
      }
    })),
    _loadTranslations: function _loadTranslations() {
      var _this2 = this;

      this.set("lang", this.get("locale.currenLocale"));
      this.selectors.forEach(function (selector, index) {
        selector.set("title", _this2.locale.t("ideas.modules.features.selector".concat(index, ".title")));
        selector.availableItems.forEach(function (item, i) {
          if (i > 0) {
            item.set("title", _this2.locale.t("ideas.modules.features.selector".concat(index, ".item").concat(i)));
          } else {
            item.set("title", _this2.locale.t("ideas.modules.features.undefined"));
          }
        });
      });
    }
  });

  _exports.default = _default;
});