define("innowaze-ember2/models/email-template", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    name: _emberData.default.attr("string"),
    subject: _emberData.default.attr("string"),
    action: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    challenge_id: _emberData.default.attr()
  });

  _exports.default = _default;
});