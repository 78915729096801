define("innowaze-ember2/templates/news/-news-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IMmE4/By",
    "block": "{\"symbols\":[\"item\",\"index\",\"grid\"],\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"news-grid page-module\"],[9],[0,\"\\n\"],[4,\"masonry-grid\",null,[[\"items\",\"customLayout\",\"itemSelector\",\"percentPosition\",\"columnWidth\"],[[23,[\"matchingNews\"]],true,\".grid-item\",true,\".grid-sizer\"]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"grid-sizer\"],[9],[10],[0,\"\\n\"],[4,\"masonry-item\",null,[[\"item\",\"grid\"],[[22,1,[]],[22,3,[]]]],{\"statements\":[[0,\"        \"],[1,[27,\"news-banner\",null,[[\"news_item\",\"canEdit\",\"canPublish\",\"deleteNews\"],[[22,1,[]],[23,[\"session\",\"currentUser\",\"admin\"]],[23,[\"session\",\"currentUser\",\"admin\"]],\"deleteNews\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2,3]},null],[4,\"if\",[[23,[\"isLoadingMore\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"loader\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[27,\"or\",[[27,\"eq\",[[23,[\"matchingNews\",\"length\"]],[23,[\"totalResults\"]]],null],[27,\"not\",[[23,[\"isReadyToLoadMore\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[9],[1,[27,\"react-when-visible\",null,[[\"onVisible\"],[[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"loadMore\"],null]]]],false],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\" \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/news/-news-container.hbs"
    }
  });

  _exports.default = _default;
});