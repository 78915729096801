define("innowaze-ember2/components/donut-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    tagName: "svg",
    classNames: "donut-chart",
    attributeBindings: ["width", "height", "legend"],
    legend: false,
    draw: Ember.observer("records", "dataLoaded", function () {
      if (this.dataLoaded) {
        d3.select(this.$().get(0)).selectAll("g").remove();
        this.drawChart();
      }
    }),
    didInsertElement: function didInsertElement() {
      this.drawChart();
    },
    drawChart: function drawChart() {
      var _this = this;

      var margin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      };
      var width;
      var height;

      if (this.width) {
        width = this.width - margin.left - margin.right;
      } else {
        width = parseInt(this.$().css("width")) - margin.left - margin.right;
      }

      if (this.height) {
        height = this.height - margin.top - margin.bottom;
      } else {
        height = window.innerHeight - margin.top - margin.bottom;
      }

      var radius = Math.min(width, height) / 2;
      var color = d3.scale.ordinal().domain(["today_connected_users", "heavily_commented_ideas", "recently_connected_users", "medium_commented_ideas", "quite_recently_connected_users", "rarely_connected_users", "poorly_commented_ideas", "uncommented_ideas", "nomore_connected_users", "never_connected_users", "ideas", "idea_comments", "challenge_debate_comments", "full_contributors", "mix_contributors", "only_ideators", "only_commentators", "lurkers", "allocated_votes", "total_available_votes", "voting_users_available_votes", "other_state_ideas", "selected_ideas", "rejected_ideas", "implemented_ideas"]).range(["#4084BA", "#4084BA", "#76AED4", "#76AED4", "#A5C9DF", "#C9DBEE", "#C9DBEE", "#F6AF32", "#F6AF32", "#ED4631", "#4084BA", "#76AED4", "#A5C9DF", "#6C5CD3", "#C979D3", "#76AED4", "#80FF00", "#E5F9AD", "#4084BA", "#F6AF32", "#E5F9AD", "lightgrey", "green", "red", "yellow"]);
      var arc = d3.svg.arc().outerRadius(radius - 10).innerRadius(radius - 70);
      var pie = d3.layout.pie().value(function (d) {
        return d.value;
      });
      var svg = d3.select(this.$().get(0)).attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(".concat(width / 2, ", ").concat((height + radius - 60) / 2, ")"));
      var data = this.data;
      var counter = this.counter;
      var g = svg.selectAll(".arc").data(pie(data)).enter().append("g").attr("class", "arc");
      g.append("path").attr("d", arc).style("fill", function (d) {
        return color(d.data.label);
      });
      g.append("text").attr("transform", function (d) {
        return "translate(".concat(arc.centroid(d), ")");
      }).attr("dy", ".35em").attr("dx", -10).attr("font-size", "10px").text(function (d) {
        return d.endAngle - d.startAngle > 0 ? (Math.round(1000 * (d.endAngle - d.startAngle) / (2 * Math.PI)) / 10).toString() + "%" : "";
      });

      if (counter) {
        g.append("text").attr("dy", 0).attr("dx", 0).attr("font-size", "20px").attr("text-anchor", "middle").html(function (d) {
          return counter;
        });
      } // draw legend


      if (this.legend === true) {
        var legend = svg.append("g").attr("class", "legendContainer");
        var yLegend = 0;
        data.forEach(function (chart) {
          legend.append("rect").attr("x", -radius).attr("y", yLegend - 1.5 * radius).attr("width", 20).attr("height", 20).attr("fill", color(chart.label));
          legend.append("text").attr("x", 40 - radius).attr("y", yLegend + 14 - 1.5 * radius).attr("font-size", "10px").text(_this.locale.t("reportings.statistics.".concat(chart.label)));
          yLegend += 20;
        });
      }
    }
  });

  _exports.default = _default;
});