define("innowaze-ember2/routes/news-instance/show", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/toggleNewsPublish", "innowaze-ember2/configuration", "innowaze-ember2/utils/stripTags"], function (_exports, _objectSpread2, _toggleNewsPublish, _configuration, _stripTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_toggleNewsPublish.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    headData: Ember.inject.service(),
    templateName: "news/show",
    model: function model(params) {
      return this.store.findRecord("news", params.news_id);
    },
    afterModel: function afterModel(model) {
      if (this.session.get("configuration.dict.public_pages")) {
        this.setProperties((0, _objectSpread2.default)({
          "headData.title": "".concat(model.get("title"), " | ").concat(this.get("session.configuration.dict.instance")),
          "headData.description": (0, _stripTags.default)(model.get("truncated_body"))
        }, model.get("picture_url") && {
          "headData.image": _configuration.default.apiUrl + model.get("picture_url")
        }));
      }
    },
    actions: {
      deleteNews: function deleteNews(news) {
        var _this = this;

        if (!confirm(this.locale.t("challenges.show.confirm_delete_news"))) {
          return;
        }

        news.destroyRecord().then(function () {
          _this.transitionTo("newsInstance");

          _this.notifications.success(_this.locale.t("challenges.show.news_deleted"));
        });
      }
    }
  });

  _exports.default = _default;
});