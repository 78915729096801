define("innowaze-ember2/components/social-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["share"],
    facebookShareUrl: Ember.computed(function () {
      return "http://www.facebook.com/sharer.php?u=".concat(encodeURIComponent(window.location.href));
    }),
    twitterShareUrl: Ember.computed(function () {
      return "https://twitter.com/share?url=".concat(encodeURIComponent(window.location.href));
    }),
    linkedInShareUrl: Ember.computed(function () {
      return "http://www.linkedin.com/shareArticle?mini=true&url=".concat(encodeURIComponent(window.location.href));
    })
  });

  _exports.default = _default;
});