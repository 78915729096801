define("innowaze-ember2/templates/components/modules/replicators-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vqsJpI22",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"if\",[[23,[\"editMode\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"replicators\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-bell-o\"],[9],[10],[0,\" \"],[1,[27,\"i18n\",[\"ideas.replicators\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"page-module replicators\"],[9],[0,\"\\n        \"],[7,\"ul\"],[11,\"class\",\"teammate small-block-grid-3 medium-block-grid-6 large-block-grid-9\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"replicators\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[11,\"class\",\"picture\"],[9],[7,\"img\"],[12,\"src\",[22,1,[\"avatar\"]]],[9],[10],[0,\"\\n              \"],[7,\"div\"],[11,\"class\",\"idea-author\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"users.show\",[22,1,[\"id\"]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[0,\"                  \"],[1,[22,1,[\"screenname\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[22,1,[\"site\"]]],null,{\"statements\":[[0,\"                  \"],[7,\"span\"],[9],[0,\"- \"],[1,[22,1,[\"site\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/modules/replicators-module.hbs"
    }
  });

  _exports.default = _default;
});