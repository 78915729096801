define("innowaze-ember2/models/configuration", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    available_locales: _emberData.default.attr(),
    banner_id: _emberData.default.attr("number"),
    banner_url: _emberData.default.attr("string"),
    concept_picture_id: _emberData.default.attr("number"),
    concept_picture_url: _emberData.default.attr("string"),
    csrf: _emberData.default.attr(),
    csrf_tag: _emberData.default.attr(),
    default_locale: _emberData.default.attr(),
    dict: (0, _attributes.fragment)("dict"),
    email_domains: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    idea_picture_id: _emberData.default.attr("number"),
    idea_picture_url: _emberData.default.attr("string"),
    image_paths: _emberData.default.attr(),
    languages: _emberData.default.attr(),
    last_users_csv_export: _emberData.default.attr(),
    last_users_csv_export_date: _emberData.default.attr(),
    last_ideas_csv_export: _emberData.default.attr(),
    last_ideas_csv_export_date: _emberData.default.attr(),
    locale: _emberData.default.attr(),
    login_picture_id: _emberData.default.attr("number"),
    login_picture_url: _emberData.default.attr("string"),
    logo_id: _emberData.default.attr("number"),
    logo_url: _emberData.default.attr("string"),
    providers: _emberData.default.attr(),
    deepl_languages: _emberData.default.attr(),
    privateSSO: ["saml", "yammer", "wsfed"],
    hasSSO: Ember.computed("providers", function () {
      return this.providers.length;
    }),
    socialSSO: Ember.computed("providers", function () {
      var _this = this;

      return this.providers.filter(function (p) {
        return !_this.privateSSO.includes(p);
      });
    }),
    activePrivateSSO: Ember.computed("providers", function () {
      var _this2 = this;

      return this.providers.filter(function (p) {
        return _this2.privateSSO.includes(p);
      });
    }),
    localeService: Ember.inject.service("locale"),
    gac: Ember.computed.alias("dict.gac"),
    backlink: Ember.computed.alias("dict.backlink"),
    title: Ember.computed("dict.title", function () {
      return this.get("dict.title") ? this.get("dict.title") : this.localeService.t("challenges.index.title");
    }),
    subtitle: Ember.computed("dict.subtitle", function () {
      return this.get("dict.subtitle") ? this.get("dict.subtitle") : this.localeService.t("challenges.index.subtitle");
    })
  });

  _exports.default = _default;
});