define("innowaze-ember2/components/funnel-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ["width", "height", "bottomPinch", "isCurved", "curveHeight", "fillType", "isInverted", "dynamicArea", "minHeight", "animation", "label"],
    tagName: "svg",
    width: 300,
    height: 400,
    bottomWidth: 1 / 3,
    bottomPinch: 1,
    isCurved: true,
    curveHeight: 20,
    fillType: "solid",
    isInverted: false,
    hoverEffects: false,
    dynamicArea: true,
    minHeight: false,
    animation: false,
    label: {
      fontSize: "10px",
      fill: "#fff"
    },
    draw: Ember.observer("data.[]", function () {
      if (this.data.length > 0) {
        d3.select("#" + this.elementId).selectAll("g").remove();
        this.chart();
        this.setPath();
      }
    }),
    didInsertElement: function didInsertElement() {
      this.svg = d3.select(this.$().get(0));
      this.set("width", $("#funnel").width());
      Ember.run.scheduleOnce("afterRender", this, function () {
        this.chart();
        this.setPath();
      });
    },
    makePaths: function makePaths() {
      var area;
      var bottomBase;
      var count;
      var dx;
      var dy;
      var height;
      var i;
      var nextHeight;
      var nextLeftX;
      var nextRightX;
      var prevHeight;
      var prevLeftX;
      var prevRightX;
      var ratio;
      var topBase;
      var totalArea;
      var totalCount;
      var paths = [];
      dx = this.dx;
      dy = this.dy;
      prevLeftX = 0;
      prevRightX = this.width;
      prevHeight = 0;

      if (this.isInverted) {
        prevLeftX = this.bottomLeftX;
        prevRightX = this.width - this.bottomLeftX;
      }

      nextLeftX = 0;
      nextRightX = 0;
      nextHeight = 0;
      var middle = this.width / 2;

      if (this.isCurved) {
        prevHeight = 10;
      }

      topBase = this.width;
      bottomBase = 0;
      totalArea = this.height * (this.width + this.bottomWidth) / 2;
      var slope = 2 * this.height / (this.width - this.bottomWidth);

      if (this.minHeight) {
        height = this.height - this.minHeight * this.data.length;
        totalArea = height * (this.width + this.bottomWidth) / 2;
      }

      totalCount = 0;
      count = 0;
      i = 0;

      while (i < this.data.length) {
        totalCount += this.data[i]["value"];
        i++;
      }

      i = 0;

      while (i < this.data.length) {
        count = this.data[i]["value"];

        if (this.dynamicArea) {
          ratio = count / totalCount;
          area = ratio * totalArea;

          if (this.minHeight !== false) {
            area += this.minHeight * (this.width + this.bottomWidth) / 2;
          }

          bottomBase = Math.sqrt((slope * topBase * topBase - 4 * area) / slope);
          dx = topBase / 2 - bottomBase / 2;
          dy = area * 2 / (topBase + bottomBase);

          if (this.isCurved) {
            dy -= this.curveHeight / this.data.length;
          }

          topBase = bottomBase;
        }

        if (this.bottomPinch > 0) {
          if (!this.isInverted) {
            if (i >= this.data.length - this.bottomPinch) {
              dx = 0;
            }
          } else {
            if (!this.dynamicArea) {
              dx = this.dx;
            }

            dx = i < this.bottomPinch ? 0 : dx;
          }
        }

        nextLeftX = prevLeftX + dx;
        nextRightX = prevRightX - dx;
        nextHeight = prevHeight + dy;

        if (this.isInverted) {
          nextLeftX = prevLeftX - dx;
          nextRightX = prevRightX + dx;
        }

        if (this.isCurved) {
          paths.push([[prevLeftX, prevHeight, "M"], [middle, prevHeight + (this.curveHeight - 10), "Q"], [prevRightX, prevHeight, ""], [nextRightX, nextHeight, "L"], [nextRightX, nextHeight, "M"], [middle, nextHeight + this.curveHeight, "Q"], [nextLeftX, nextHeight, ""], [prevLeftX, prevHeight, "L"]]);
        } else {
          paths.push([[prevLeftX, prevHeight, "M"], [prevRightX, prevHeight, "L"], [nextRightX, nextHeight, "L"], [nextLeftX, nextHeight, "L"], [prevLeftX, prevHeight, "L"]]);
        }

        prevLeftX = nextLeftX;
        prevRightX = nextRightX;
        prevHeight = nextHeight;
        i++;
      }

      return paths;
    },
    chart: function chart() {
      var hexExpression;
      var i;
      var colorScale = d3.scale.category10();
      i = 0;

      while (i < this.data.length) {
        hexExpression = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

        if (!("2" in this.data[i]) || !hexExpression.test(this.data[i][2])) {
          this.data[i][2] = colorScale(i);
        }

        i++;
      }

      var n = this.data.length;
      this.set("bottomLeftX", (this.width - this.bottomWidth) / 2);
      this.set("dx", this.bottomPinch > 0 ? this.bottomLeftX / (n - this.bottomPinch) : this.bottomLeftX / n);
      return this.set("dy", this.isCurved ? (this.height - this.curveHeight) / n : this.height / n);
    },
    setPath: function setPath() {
      this.set("paths", this.makePaths());

      if (this.fillType === "gradient") {
        this._defineColorGradients(this.svg);
      }

      if (this.isCurved) {
        this._drawTopOval(this.svg, this.paths);
      }

      this._drawSection(0);
    },
    _drawSection: function _drawSection(index) {
      var self;

      if (index === this.data.length) {
        return;
      }

      var group = this.svg.append("g");

      var path = this._getSectionPath(group, index);

      path.data(this._getSectionData(index));

      if (this.animation) {
        self = this;
        path.transition().duration(this.animation).ease("linear").attr("fill", this._getColor(index)).attr("d", this._getPathDefinition(index)).each("end", function () {
          self._drawSection(index + 1);
        });
      } else {
        path.attr("fill", this._getColor(index)).attr("d", this._getPathDefinition(index));

        this._drawSection(index + 1);
      }

      if (this.hoverEffects) {
        path.on("mouseover", this._onMouseOver(path)).on("mouseout", this._onMouseOut(path));
      }

      return this._addSectionLabel(group, index);
    },
    _getSectionPath: function _getSectionPath(group, index) {
      var path = group.append("path");

      if (this.animation) {
        this._addBeforeTransition(path, index);
      }

      return path;
    },
    _addBeforeTransition: function _addBeforeTransition(path, index) {
      var beforeFill;
      var beforePath;
      var paths = this.paths[index];
      beforePath = "";
      beforeFill = "";

      if (!this.isCurved) {
        beforePath = "M" + paths[0][0] + "," + paths[0][1] + " L" + paths[1][0] + "," + paths[1][1] + " L" + paths[1][0] + "," + paths[1][1] + " L" + paths[0][0] + "," + paths[0][1];
      } else {
        beforePath = "M" + paths[0][0] + "," + paths[0][1] + " Q" + paths[1][0] + "," + paths[1][1] + " " + paths[2][0] + "," + paths[2][1] + " L" + paths[2][0] + "," + paths[2][1] + " M" + paths[2][0] + "," + paths[2][1] + " Q" + paths[1][0] + "," + paths[1][1] + " " + paths[0][0] + "," + paths[0][1];
      }

      if (this.fillType === "solid") {
        beforeFill = index > 0 ? this._getColor(index - 1) : this._getColor(index);
      } else {
        beforeFill = this._getColor(index);
      }

      path.attr("d", beforePath).attr("fill", beforeFill);
    },
    _getSectionData: function _getSectionData(index) {
      return [{
        index: index,
        label: this.data[index][0],
        value: this.data[index][1],
        baseColor: this.data[index][2],
        fill: this._getColor(index)
      }];
    },
    _getColor: function _getColor(index) {
      if (this.fillType === "solid") {
        return this.data[index][2];
      }

      return "url(#gradient-" + index + ")";
    },
    _defineColorGradients: function _defineColorGradients(svg) {
      var _this = this;

      var defs = svg.append("defs");
      this.data.forEach(function (data, index) {
        var color = data[2];

        var shade = _this.shadeColor(color, -0.25);

        var gradient = defs.append("linearGradient").attr({
          id: "gradient-" + index
        });
        var stops = [[0, shade], [40, color], [60, color], [100, shade]];
        stops.forEach(function (stop) {
          gradient.append("stop").attr({
            offset: stop[0] + "%",
            style: "stop-color:" + stop[1]
          });
        });
      });
    },
    _getPathDefinition: function _getPathDefinition(index) {
      return this.paths[index].map(function (path) {
        return path[2] + path[0] + "," + path[1] + " ";
      }).join("");
    },
    _addSectionLabel: function _addSectionLabel(group, index) {
      var paths = this.paths[index];
      var textStr = this.data[index]["label"] + ": " + this.data[index]["value"].toLocaleString();
      var textFill = this.label.fill;
      var textX = this.width / 2; // Center the text

      var textY = !this.isCurved ? (paths[1][1] + paths[2][1]) / 2 : (paths[2][1] + paths[3][1]) / 2 + this.curveHeight / this.data.length;
      group.append("text").text(textStr).attr({
        x: textX,
        y: textY,
        "text-anchor": "middle",
        "domisant-baseline": "middle",
        fill: textFill,
        "pointer-events": "none"
      }).style("font-size", this.label.fontSize);
    },
    _drawTopOval: function _drawTopOval(svg, sectionPaths) {
      var leftX;
      var rightX;
      leftX = 0;
      rightX = this.width;
      var centerX = this.width / 2;

      if (this.isInverted) {
        leftX = this.bottomLeftX;
        rightX = this.width - this.bottomLeftX;
      }

      var paths = sectionPaths[0];
      var path = "M".concat(leftX, ",").concat(paths[0][1], " Q").concat(centerX, ",").concat(parseFloat(paths[1][1] + this.curveHeight - 10), " ").concat(rightX, ",").concat(paths[2][1], " M").concat(rightX, ",10 Q").concat(centerX, ",0 ").concat(leftX, ",10");
      return svg.append("path").attr("fill", this.shadeColor(this.data[0][2], -0.4)).attr("d", path);
    },
    _onMouseOver: function _onMouseOver(data) {},
    _onMouseOut: function _onMouseOut(data) {},
    shadeColor: function shadeColor(color, shade) {
      var f = parseInt(color.slice(1), 16);
      var t = shade < 0 ? 0 : 255;
      var p = shade < 0 ? shade * -1 : shade;
      var R = f >> 16;
      var G = f >> 8 & 0x00ff;
      var B = f & 0x0000ff;
      var converted = 0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + Math.round((t - B) * p) + B;
      return "#" + converted.toString(16).slice(1);
    }
  });

  _exports.default = _default;
});