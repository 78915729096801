define("innowaze-ember2/models/user-module-field", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    field: _emberData.default.attr("string"),
    field_type: _emberData.default.attr(),
    visible_on_signup: _emberData.default.attr("boolean"),
    position: _emberData.default.attr("number"),
    user_module_id: _emberData.default.attr("number"),
    user_module_name: _emberData.default.attr("number"),
    locale_path: _emberData.default.attr("string")
  });

  _exports.default = _default;
});