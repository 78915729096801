define("innowaze-ember2/components/modules/scale-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    actions: {
      selectScale: function selectScale(scale) {
        this.model.set("scale", scale);
      }
    },
    scaleIsGreaterThanOne: Ember.computed.gte("model.scale", 1),
    scaleIsGreaterThanTwo: Ember.computed.gte("model.scale", 2),
    scaleIsGreaterThanThree: Ember.computed.gte("model.scale", 3),
    scaleIsOne: Ember.computed.equal("model.scale", 1),
    scaleIsTwo: Ember.computed.equal("model.scale", 2),
    scaleIsThree: Ember.computed.equal("model.scale", 3)
  });

  _exports.default = _default;
});