define("innowaze-ember2/components/modules/replica-module", ["exports", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/components/modules/module"], function (_exports, _selectAutocomplete, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend(_selectAutocomplete.default, {
    availableMasters: [],
    completedDidChange: Ember.on("init", Ember.observer("model.master", function () {
      this.set("module.isNotCompleted", !this.get("model.master"));
      this.set("availableMasters", []);
    })),
    actions: {
      setMaster: function setMaster(master) {
        if (master.id !== this.model.id) {
          this.model.set("master", master);
        }
      },
      clearMaster: function clearMaster() {
        this.model.set("master", null);
      }
    }
  });

  _exports.default = _default;
});