define("innowaze-ember2/serializers/upload", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      badges: {
        deserialize: "records",
        serialize: false
      }
    }
  });

  _exports.default = _default;
});