define("innowaze-ember2/templates/challenges/modules/-knowledges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uXXah3M+",
    "block": "{\"symbols\":[\"knowledge\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"knowledges\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"challenges.knowledges.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n    \"],[7,\"main\"],[11,\"class\",\"leaderboard__profiles\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"knowledges\"]]],null,{\"statements\":[[4,\"link-to\",[\"knowledges.show\",[22,1,[\"id\"]]],null,{\"statements\":[[0,\"          \"],[7,\"article\"],[11,\"class\",\"leaderboard__profile\"],[9],[0,\"\\n            \"],[7,\"img\"],[12,\"src\",[22,1,[\"banner\"]]],[12,\"alt\",[22,1,[\"name\"]]],[11,\"class\",\"leaderboard__picture\"],[9],[10],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"leaderboard__name\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"knowledges\",[27,\"query-params\",null,[[\"domain\"],[null]]]],[[\"class\"],[\"display-all-elements\"]],{\"statements\":[[0,\"      \"],[1,[27,\"i18n\",[\"challenges.show.all_knowledges\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-knowledges.hbs"
    }
  });

  _exports.default = _default;
});