define("innowaze-ember2/initializers/i18n", ["exports", "fetch", "innowaze-ember2/configuration"], function (_exports, _fetch, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    (0, _fetch.default)(_configuration.default.apiUrl + "/api/translations").then(function (response) {
      return response.json();
    }).then(function (translations) {
      window.I18n.fallbacks = true;
      window.I18n.translations = translations;
      application.register("i18n:main", window.I18n, {
        instantiate: false
      });
      application.inject("service:locale", "I18n", "i18n:main");
      application.inject("route", "I18n", "i18n:main");
      application.inject("controller", "I18n", "i18n:main");
    }).catch(console.error);
  }

  var _default = {
    name: "i18n",
    initialize: initialize
  };
  _exports.default = _default;
});