define("innowaze-ember2/serializers/jury", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      domains: {
        deserialize: "records",
        serialize: false
      },
      users: {
        deserialize: "records",
        serialize: false
      },
      tags: {
        deserialize: "records",
        serialize: false
      },
      scopes: {
        deserialize: "records",
        serialize: false
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      if (snapshot.record.get("users")) {
        json.users = snapshot.record.get("users").map(function (user) {
          return {
            id: user.id
          };
        });
      }

      if (snapshot.record.get("tags")) {
        json.tags = snapshot.record.get("tags").map(function (tag) {
          return {
            name: Ember.get(tag, "name")
          };
        });
      }

      if (snapshot.record.get("domains")) {
        json.domains = snapshot.record.get("domains").map(function (domain) {
          return {
            id: domain.id
          };
        });
      }

      if (snapshot.record.get("scopes")) {
        json.scopes = snapshot.record.get("scopes").map(function (scope) {
          return {
            id: scope.id
          };
        });
      }

      return {
        jury: json
      };
    }
  });

  _exports.default = _default;
});