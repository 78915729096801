define("innowaze-ember2/services/assetMap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    map: {},
    resolve: function resolve(name) {
      var map = this.map;
      name = name.replace(/^\/+/, "");
      return "/" + (name in map ? map[name].replace(/^\/+/, "") : name);
    },
    loadThemeStyleSheet: function loadThemeStyleSheet(theme) {
      $("link[data-role='theme']").attr("href", this.resolve("assets/".concat(theme, ".css")));
    },
    loadFavicon: function loadFavicon(folderName) {
      var $link = $("<link />");
      $link.attr({
        rel: "shortcut icon",
        href: this.resolve("assets/images/".concat(folderName, "/favicon.png"))
      });
      $("head").append($link);
    }
  });

  _exports.default = _default;
});