define("innowaze-ember2/templates/ideas/-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HuAI+bCR",
    "block": "{\"symbols\":[\"s\"],\"statements\":[[7,\"span\"],[11,\"class\",\"idea-state-selector\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[1,[27,\"i18n\",[\"ideas.tools.idea_state.title\"],null],false],[0,\" :\"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"current-state\"],[9],[1,[23,[\"model\",\"idea\",\"state_name\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"model\",\"idea\",\"all_states\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[12,\"title\",[22,1,[\"to_name\"]]],[12,\"style\",[27,\"html-safe\",[[22,1,[\"color\"]]],null]],[12,\"class\",[28,[\"circle has-tip \",[27,\"if\",[[22,1,[\"current\"]],\"current\"],null]]]],[9],[0,\"\\n      \"],[1,[27,\"font-icon\",[[22,1,[\"icon\"]]],null],false],[0,\"\\n      \"],[4,\"if\",[[22,1,[\"admin\"]]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"admin\"],[9],[0,\"▲\"],[10]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"if\",[[22,1,[\"current\"]]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"current\"],[9],[0,\"▼\"],[10]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,1,[\"left_days\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-clock-o current \",[27,\"if\",[[22,1,[\"late\"]],\"late\"],null]]]],[9],[0,\" \"],[1,[22,1,[\"left_days\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/ideas/-state.hbs"
    }
  });

  _exports.default = _default;
});