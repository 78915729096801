define("innowaze-ember2/components/color-picker", ["exports", "ember-spectrum-color-picker/components/spectrum-color-picker"], function (_exports, _spectrumColorPicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-line import/no-unresolved
  var _default = _spectrumColorPicker.default.extend({
    // DOC : https://github.com/rsschermer/ember-spectrum-color-picker
    classNames: ["spectrum-color-picker"],
    tagName: "input",
    color: "#777777",
    showPalette: true,
    showPaletteOnly: true,
    palette: [["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e"], ["#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50"], ["#f1c40f", "#e67e22", "#e74c3c", "#4066c4", "#95a5a6"], ["#f39c12", "#d35400", "#c0392b", "#264aa3", "#7f8c8d"]],
    togglePaletteOnly: true,
    showSelectionPalette: true,
    hideAfterPaletteSelect: true,
    preferredFormat: "hex",
    moveFiresChange: false,
    clickoutFiresChange: true,
    chooseText: "Selectionner",
    cancelText: "Annuler",
    togglePaletteMoreText: "Plus",
    togglePaletteLessText: "Moins",
    localStorageKey: "spectrum_color_picker",
    updatePicker: Ember.observer("color", function () {
      this.$().spectrum("set", this.color);
    })
  });

  _exports.default = _default;
});