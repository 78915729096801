define("innowaze-ember2/templates/users/modules/-organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GrDWdPyH",
    "block": "{\"symbols\":[\"department\"],\"statements\":[[7,\"hr\"],[9],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"user-profile-contact\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"user-profile__organization-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"users.modules.organization.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"fields\",\"unit\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-cube\"],[9],[10],[0,\"\\n      \"],[1,[23,[\"model\",\"fields\",\"unit\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"fields\",\"site\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-flag\"],[9],[10],[0,\"\\n      \"],[1,[23,[\"model\",\"fields\",\"site\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"model\",\"modules\",\"departments\",\"private\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"model\",\"departments\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"\\n      World Business Lines\\n    \"],[10],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"user-profile-contact\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"departments\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"fa fa-globe\"],[9],[10],[0,\"\\n          \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/modules/-organization.hbs"
    }
  });

  _exports.default = _default;
});