define("innowaze-ember2/templates/users/modules/-skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lXXnPqE9",
    "block": "{\"symbols\":[\"skill\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"skills\"]]],null,{\"statements\":[[0,\"  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"user-profile__skills-title\"],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"users.modules.skills.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"user-profile__skills keyword-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"skills\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[1,[22,1,[\"name\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/modules/-skills.hbs"
    }
  });

  _exports.default = _default;
});