define("innowaze-ember2/routes/challenges/new-from-template", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel"], function (_exports, _cleanCurrentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    controllerName: "challenges/new",
    model: function model(params) {
      var _this = this;

      return this.store.adapterFor("challenge").getChallenge(params.template_id).then(function (template) {
        delete template.id;
        ["pathways", "debates", "knowledges", "surveys"].forEach(function (attribute) {
          template[attribute] = template[attribute].map(function (item) {
            delete item.id;
            return _this.store.createRecord(attribute.slice(0, -1), item);
          });
        });
        ["documents", "pictures"].forEach(function (attribute) {
          template[attribute] = template[attribute].map(function (item) {
            delete item.id;
            return _this.store.createRecord("upload", item);
          });
        });
        return _this.store.createRecord("challenge", template);
      });
    },
    afterModel: function afterModel(model) {
      model.setProperties({
        is_template: false,
        description: "",
        name: "",
        id: null,
        logo_id: null,
        logo_url: null,
        banner_id: null,
        banner_url: null,
        showcase_banner_id: null,
        showcase_banner_url: null,
        milestones: model.get("milestones").map(function (milestone) {
          delete milestone.id;
          return Ember.Object.create(milestone);
        }),
        active_user_modules: model.get("active_user_modules").map(function (module) {
          delete module.id;
          module.validity = true;
          return Ember.Object.create(module);
        })
      });
      ["modules", "actions", "rules", "ideas", "tools", "concept_formats", "concept_tools", "concept_analyses"].forEach(function (attribute) {
        model.set(attribute, model.get(attribute).map(function (item) {
          return Ember.Object.create(item);
        }));
      });
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      willTransition: function willTransition() {
        // we delete the new challenge record if the creation is aborted
        if (this.currentModel.get("isNew")) {
          this.currentModel.deleteRecord();
        }

        return true;
      },
      save: function save() {
        var _this2 = this;

        this.controller.set("saveDisabled", true);
        var mask = this.currentModel.get("stepMask");
        var new_mask = mask.substring(0, 2) + "1" + mask.substring(3);
        this.currentModel.setProperties({
          stepMask: new_mask,
          step: parseInt(new_mask, 2)
        });
        this.currentModel.save().then(function () {
          return _this2._success();
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      deleteLogo: function deleteLogo() {
        this.currentModel.setProperties({
          logo_id: null,
          logo_url: null
        });
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      },
      setDepartment: function setDepartment(department) {
        this.currentModel.set("department", department);
      }
    },
    _success: function _success() {
      this.controller.set("saveDisabled", false);
      this.transitionTo("challenges.edit_main", this.currentModel.id);
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("creation_error"), errors);
    }
  });

  _exports.default = _default;
});