define("innowaze-ember2/templates/users/edit-modules/-uses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JUGM7WDH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"modules\",\"uses\",\"private\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title has-tip\"],[12,\"title\",[28,[[27,\"i18n\",[\"users.modules.is_private\"],null]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-eye-slash\"],[9],[10],[0,\"\\n     \\n    \"],[1,[27,\"i18n\",[\"users.modules.uses.title\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"displayRequired\"]]],null,{\"statements\":[[0,\"      \"],[7,\"sup\"],[11,\"class\",\"red\"],[9],[0,\" *\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title has-tip\"],[12,\"title\",[28,[[27,\"i18n\",[\"users.modules.is_public\"],null]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-eye\"],[9],[10],[0,\"\\n     \\n    \"],[1,[27,\"i18n\",[\"users.modules.uses.title\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"displayRequired\"]]],null,{\"statements\":[[0,\"      \"],[7,\"sup\"],[11,\"class\",\"red\"],[9],[0,\" *\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\"],[12,\"class\",[28,[\"page-module \",[27,\"if\",[[23,[\"displayRequired\"]],\"validity\"],null]]]],[9],[0,\"\\n  \"],[1,[27,\"component\",[\"modules/uses-module\"],[[\"module\",\"model\",\"editMode\"],[[23,[\"module\"]],[23,[\"model\"]],true]]],false],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"displayRequired\"]]],null,{\"statements\":[[0,\"      \"],[7,\"sup\"],[11,\"class\",\"red\"],[9],[0,\" *\"],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"form-explanation\"],[9],[1,[27,\"i18n\",[\"users.modules.uses.validation\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/edit-modules/-uses.hbs"
    }
  });

  _exports.default = _default;
});