define("innowaze-ember2/templates/users/modules/-career", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEKo1Dbq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hr\"],[9],[10],[0,\"\\n\"],[7,\"h3\"],[11,\"class\",\"user-profile__skills-title\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"users.modules.career.title\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"medium-6 columns\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"users.modules.personal.study\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[23,[\"model\",\"fields\",\"study\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"medium-6 columns\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"users.modules.personal.job\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[23,[\"model\",\"fields\",\"occupation\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/users/modules/-career.hbs"
    }
  });

  _exports.default = _default;
});