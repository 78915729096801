define("innowaze-ember2/routes/challenges/experts", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _toConsumableArray2, _setCurrentChallenge, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        experts: this.store.query("expert", {
          challenge_id: params.challenge_id
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var users = model.experts.reduce(function (acc, e) {
        return acc.concat([e.primary_expert, e.secondary_expert, e.level2_expert, e.security_expert]);
      }, []).filter(function (u) {
        return !!u;
      }).uniqBy("id");
      controller.setProperties({
        usersToDisplay: (0, _toConsumableArray2.default)(users),
        availableOrganizations: model.experts.mapBy("organization").uniq(),
        availableActivities: model.experts.mapBy("activity").uniq(),
        availableSolutions: model.experts.mapBy("name").uniq(),
        organizationFilter: null,
        activityFilter: null,
        solutionFilter: null,
        onlyUnassignedFilter: false
      });
    },
    actions: {
      saveExpert: function saveExpert(solution) {
        var _this = this;

        this.set("controller.isSavingExpert", true);
        solution.save({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id
          }
        }).finally(function () {
          return _this.set("controller.isSavingExpert", false);
        });
      }
    }
  });

  _exports.default = _default;
});