define("innowaze-ember2/helpers/font-icon", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fontIcon = fontIcon;
  _exports.default = void 0;

  function fontIcon(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        name = _ref2[0];

    if (!name) {
      name = "check";
    }

    return Ember.String.htmlSafe("<i class='fa fa-".concat(name, "'></i>"));
  }

  var _default = Ember.Helper.helper(fontIcon);

  _exports.default = _default;
});