define("innowaze-ember2/helpers/k-euro", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.kEuro = kEuro;
  _exports.default = void 0;

  function kEuro(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref2[0];

    if (!value) {
      return "";
    }

    return String(Math.round(value * 100 / 1000) / 100);
  }

  var _default = Ember.Helper.helper(kEuro);

  _exports.default = _default;
});