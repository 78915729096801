define("innowaze-ember2/routes/challenges/show", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/parallax", "innowaze-ember2/mixins/routes/linkedDebateBadge"], function (_exports, _setCurrentChallenge, _parallax, _linkedDebateBadge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _parallax.default, _linkedDebateBadge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id, {
        reload: true
      });
    },
    redirect: function redirect(model, transition) {
      if (!model.get("current_profile_completion")) {
        if (model.get("current_user_id")) {
          this.transitionTo("users.editForChallenge", model.id, model.get("current_user_id"));
        } else {
          this.transitionTo("challenges");
        }
      } else if (model.get("awaiting")) {
        this.transitionTo("challenges");
      } else if (model.get("no_homepage")) {
        this.transitionTo("ideas", model.id);
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        moreHighestSavings: false,
        moreHigherScoreUsers: false,
        badgeModalVisible: false
      });

      if (model.get("activeModules").findBy("name", "concepts")) {
        this.store.query("concept", {
          challenge_id: model.id
        }).then(function (concepts) {
          controller.setProperties({
            concepts: concepts.filterBy("visible", true),
            current_user_given_points: concepts.get("firstObject.current_user_given_points"),
            conceptsAreLoaded: true
          });
        });
      }

      if (model.get("activeModules").findBy("name", "domains") || model.get("activeModules").findBy("name", "global_domains")) {
        this.store.query("domain", {
          challenge_id: model.id,
          with_globals: true
        }).then(function (domains) {
          domains.forEach(function (domain) {
            var d = model.get("domains_cloud").find(function (dc) {
              return dc.id === Number(domain.id);
            });
            domain.set("count", d ? d.get("count") : undefined);
          });
          controller.set("availableDomains", domains.filter(function (domain) {
            return domain.get("challenge_id") === Number(model.id);
          }));
          controller.set("availableGlobalDomains", domains.filter(function (domain) {
            return !domain.get("challenge_id");
          }));
          controller.set("globalDomainsListIsLoaded", true);
        });
      }

      if (!model.get("blind")) {
        if (model.get("activeModules").findBy("name", "ideas_per_department")) {
          this.store.adapterFor("challenge").reportingIdeasPer(model.id, {
            type: "department"
          }).then(function (departments) {
            controller.setProperties({
              departments: departments,
              departmentsIsLoaded: true
            });
          });
        }

        if (model.get("activeModules").findBy("name", "ideas_per_domain")) {
          this.store.adapterFor("challenge").reportingIdeasPer(model.id, {
            type: "domain"
          }).then(function (domains) {
            controller.setProperties({
              domains: domains,
              domainsIsLoaded: true
            });
          });
        }

        if (model.get("activeModules").findBy("name", "ideas_per_state")) {
          this.store.adapterFor("challenge").reportingIdeasPer(model.id, {
            type: "state"
          }).then(function (states) {
            controller.setProperties({
              statesFunnel: states,
              statesIsLoaded: true
            });
          });
        }

        if (model.get("activeModules").findBy("name", "hot_ideas")) {
          this.currentModel.getHotIdeas().then(function (data) {
            controller.set("selectedStateInWidget", model.get("state_machine_rules").findBy("to", data.selected_state));
            controller.set("hot_ideas", data.hot_ideas);
          });
        }

        if (model.get("activeModules").findBy("name", "news")) {
          var options = {
            newsable_type: ["Challenge"],
            newsable_id: model.id,
            order: "created_at"
          };
          this.store.query("news", options).then(function (news) {
            controller.set("news", news);
          });
          this.store.query("news", options).then(function (news) {
            controller.set("news", news);
          });
        }
      }
    },
    actions: {
      didTransition: function didTransition() {
        this.tick();
      },
      reloadChallenge: function reloadChallenge() {
        this.store.findRecord("challenge", this.currentModel.get("id"), {
          reload: true
        });
      },
      toggleDocumentForm: function toggleDocumentForm() {
        this.controller.toggleProperty("documentFormVisible");
      },
      toggleDocumentEditForm: function toggleDocumentEditForm() {
        this.controller.toggleProperty("documentEditFormVisible");
        this.controller.set("currentDocument", null);
      },
      toggleModuleExplanation: function toggleModuleExplanation(module) {
        module = $("#".concat(module.name)).first();
        var module_input = module.next();
        module.toggleClass("hide");
        module_input.toggleClass("hide");
      },
      changeModuleExplanation: function changeModuleExplanation(module) {
        var _this = this;

        if (this.get("session.isModerator")) {
          this.send("toggleModuleExplanation", module);
          this.currentModel.updateModuleExplanation(module).then(function () {
            return _this._onUpdateModuleSuccess();
          }, function (errors) {
            return _this._onError(errors);
          });
        }
      },
      toggleHighestSavings: function toggleHighestSavings() {
        this.toggleProperty("controller.moreHighestSavings");
      },
      toggleHigherScoreUsers: function toggleHigherScoreUsers() {
        this.toggleProperty("controller.moreHigherScoreUsers");
      },
      saveStateInWidget: function saveStateInWidget(module) {
        var _this2 = this;

        if (this.get("session.isModerator")) {
          var state = this.get("controller.selectedStateInWidget.to");
          return this.currentModel.updateStateInWidget(module, state).then(function () {
            return _this2._onUpdateModuleSuccess();
          }, function (errors) {
            return _this2._onError(errors);
          });
        }
      },
      documentUploaded: function documentUploaded(file) {
        var doc = this.store.createRecord("upload", file);
        doc.set("visible_in_widget", true);
        this.currentModel.get("documents").addObject(doc);
        this.controller.toggleProperty("documentFormVisible");
      },
      editDocument: function editDocument(file) {
        this.send("toggleDocumentEditForm");
        this.set("controller.currentDocument", file);
      },
      saveDocument: function saveDocument() {
        var _this3 = this;

        this.get("controller.currentDocument").save().then(function () {
          _this3.send("toggleDocumentEditForm");
        });
      },
      deleteDocument: function deleteDocument(file) {
        var _this4 = this;

        if (!confirm(this.locale.t("challenges.show.confirm_delete_document"))) {
          return;
        }

        file.destroyRecord().then(function () {
          _this4.get("controller.challengeImages").removeObject(file);

          _this4.get("controller.challengeVideos").removeObject(file);

          _this4.get("controller.challengeAudios").removeObject(file);

          _this4.get("controller.otherDocuments").removeObject(file);

          _this4.notifications.success(_this4.locale.t("challenges.show.document_deleted"));
        });
      },
      picturesUploaded: function picturesUploaded(files) {
        for (var _i = 0, _Array$from = Array.from(files); _i < _Array$from.length; _i++) {
          var file = _Array$from[_i];
          this.currentModel.get("pictures").pushObject(this.store.push({
            data: {
              id: file.id,
              type: "upload",
              attributes: {
                name: file.file_name,
                url: file.file_url,
                badges: []
              }
            }
          }));
        }

        this.currentModel.save();
      }
    },
    _onUpdateModuleSuccess: function _onUpdateModuleSuccess() {
      this.notifications.success(this.locale.t("challenges.show.explanation_changed"));
    },
    _onError: function _onError(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList(this.locale.t("error"), errors);
    }
  });

  _exports.default = _default;
});