define("innowaze-ember2/components/box-plot", ["exports", "ramda/src/prop", "innowaze-ember2/utils/formatEvaluation"], function (_exports, _prop, _formatEvaluation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["box-plot"],
    attributeBindings: ["width", "height", "color"],
    minValue: Ember.computed("session.currentChallenge.evaluation_min", function () {
      return this.get("session.currentChallenge.evaluation_min") || 0;
    }),
    maxValue: Ember.computed("session.currentChallenge.evaluation_max", function () {
      return this.get("session.currentChallenge.evaluation_max") || 100;
    }),
    didInsertElement: function didInsertElement() {
      this.addBoxPlot();
    },
    addBoxPlot: function addBoxPlot() {
      var _this = this;

      var margin = {
        top: 10,
        right: 50,
        bottom: 40,
        left: 50
      };
      var width = this.width - margin.left - margin.right;
      var height = this.height - margin.top - margin.bottom;
      var chart = d3.box().whiskers(iqr(1.5)).width(width).height(height);
      var axis = this.idea_data.map((0, _prop.default)("id"));
      var data = this.idea_data.map(function (x) {
        return [];
      });
      var tool = this.idea_data.map((0, _prop.default)("title"));
      var evaluations = this.data;
      evaluations.forEach(function (x) {
        var index = axis.indexOf(x.tool_id);

        if (data[index]) {
          data[index].push(Math.floor((0, _formatEvaluation.percentToValue)(x.value, _this.minValue, _this.maxValue)));
        }
      });
      chart.domain([this.minValue, this.maxValue]);
      var cleared_tool = tool.filter(Boolean);
      d3.select("#".concat(this.elementId)).selectAll("svg").data(data).enter().append("svg").attr("class", "box").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(".concat(margin.left, ", ").concat(margin.top, ")")).call(chart).append("text").attr("x", width / 2).attr("y", height + margin.top + margin.bottom - 8).attr("style", "font-size: 9px; text-anchor: middle;").text(function (d) {
        return "[ ".concat(d.length, " eval. ]");
      });
      d3.select("#".concat(this.elementId)).selectAll("svg").append("text").data(cleared_tool).attr("x", (width + margin.left + margin.right) / 2).attr("y", height + margin.top + margin.bottom - 20).attr("style", "font-size: 12; text-anchor: middle;").text(function (d) {
        return d.substring(0, 24);
      });
      this.updateIdea();
    },
    updateIdea: function updateIdea() {
      var margin = {
        top: 10,
        right: 50,
        bottom: 40,
        left: 50
      };
      var width = this.width - margin.left - margin.right;
      var height = this.height - margin.top - margin.bottom;
      var min = this.minValue;
      var linearScale = d3.scale.linear().domain([0, 100]).range([height, 0]);

      var fillCircleColor = function fillCircleColor(value) {
        if (value <= 25) {
          return "#E05309";
        }

        if (value <= 50) {
          return "#E0A305";
        }

        if (value <= 75) {
          return "gold";
        }

        if (value <= 100) {
          return "#96C219";
        }

        return "white";
      };

      var idea_evaluations = this.idea_data;
      d3.select("#".concat(this.elementId)).selectAll("svg").selectAll("circle").remove();
      d3.select("#".concat(this.elementId)).selectAll("svg").append("circle").data(idea_evaluations).attr("r", 5).attr("style", "stroke-width: 0px").style("fill", function (d) {
        return fillCircleColor(d.value);
      }).attr("cx", (width + margin.left + margin.right) / 2).attr("cy", function (d) {
        return margin.top + (d.value ? linearScale(d.value) : min);
      });
    },
    draw: Ember.observer("idea_data.@each.value", function () {
      this.updateIdea();
    }),
    boxDraw: Ember.observer("data", "tool-id", function () {
      if (this.data) {
        d3.select("#".concat(this.elementId)).selectAll("svg").remove();
        this.addBoxPlot();
      }
    })
  });

  _exports.default = _default;
});