define("innowaze-ember2/components/concept-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["idea-card-container"],
    classNameBindings: ["card_with_actions"],
    voteIsEnabled: true,
    card_with_actions: Ember.computed("concept.isAuthor", "challenge.use_concept_vote", function () {
      return this.get("challenge.use_concept_vote") && !this.get("concept.isAuthor");
    })
  });

  _exports.default = _default;
});