define("innowaze-ember2/controllers/challenges/edit-gaming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    displayLikersHasChanged: Ember.observer("model.display_likers", function () {
      if (!this.get("model.display_likers")) {
        this.set("model.likers_site_percentage", false);
      }
    })
  });

  _exports.default = _default;
});