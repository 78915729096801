define("innowaze-ember2/components/modules/scope-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.query("scope", this.get("module.params")).then(function (scopes) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.set("availableScopeHubs", scopes.filterBy("level", "Hub"));

        _this.set("availableScopeClusters", scopes.filterBy("level", "Cluster"));

        _this.set("availableScopeCountries", scopes.filterBy("level", "Country"));

        _this.set("availableScopeEntities", scopes.filterBy("level", "Entity"));

        _this.set("availableScopeBLs", scopes.filterBy("level", "Bl"));
      });
    },
    showAllScopeClusters: false,
    showAllScopeCountries: false,
    showAllScopeEntities: false,
    completedDidChange: Ember.on("init", Ember.observer("model.scope_entities.[]", "model.scope_clusters.[]", "model.scope_countries.[]", "model.scope_hubs.[]", "model.scope_BLs.[]", "model.owner_scope", function () {
      var isNotCompleted = this.get("model.scope_clusters").length === 0 || this.get("model.owner_scope") === null;
      this.set("module.isNotCompleted", isNotCompleted);
    })),
    // remove cluster from scope_clusters when there is no more entities of this cluster
    clearCluster: Ember.observer("model.scope_entities.[]", function () {
      var _this2 = this;

      if (this.get("model.scope_clusters")) {
        this.get("model.scope_clusters").forEach(function (cluster) {
          var entities = _this2.get("model.scope_entities").filter(function (entity) {
            return cluster.get("children").includes(parseInt(entity.id));
          });

          if (entities.length === 0 && cluster.children.length !== 0) {
            _this2.get("model.scope_clusters").removeObject(cluster);
          }
        });
      }
    }),
    populateHubs: Ember.observer("model.scope_clusters.[]", "availableScopeHubs", function () {
      var _this3 = this;

      this.model.set("scope_hubs", []);

      if (this.availableScopeHubs) {
        this.get("model.scope_clusters").forEach(function (cluster) {
          var hub = _this3.availableScopeHubs.findBy("id", cluster.parent);

          var existing_hub = _this3.get("model.scope_hubs").findBy("id", cluster.parent);

          if (hub && !existing_hub) {
            _this3.get("model.scope_hubs").pushObject(hub);
          }
        });
      }
    }),
    populateCountries: Ember.observer("model.scope_entities.[]", "availableScopeCountries", function () {
      var _this4 = this;

      this.model.set("scope_countries", []);

      if (this.availableScopeCountries) {
        this.get("model.scope_entities").forEach(function (entity) {
          var country = _this4.availableScopeCountries.findBy("id", entity.parent);

          var existing_country = _this4.get("model.scope_countries").findBy("id", entity.parent);

          if (country && !existing_country) {
            _this4.get("model.scope_countries").pushObject(country);
          }
        });
      }
    }),
    ownerCluster: Ember.computed("model.owner_scope", "availableScopeClusters", function () {
      var _this5 = this;

      if (this.model.owner_scope && this.availableScopeClusters) {
        return this.availableScopeClusters.filter(function (cluster) {
          return cluster.children.includes(parseInt(_this5.model.owner_scope.id));
        }).get("firstObject");
      }
    }),
    ownerHub: Ember.computed("ownerCluster", function () {
      if (this.ownerCluster && this.availableScopeHubs) {
        return this.availableScopeHubs.findBy("id", this.ownerCluster.parent);
      }
    }),
    ownerCountry: Ember.computed("model.owner_scope", "availableScopeCountries", function () {
      if (this.model.owner_scope && this.availableScopeCountries) {
        return this.availableScopeCountries.findBy("id", this.model.owner_scope.parent);
      }
    }),
    firstScopeClusters: Ember.computed("model.scope_clusters.[]", function () {
      return this.get("model.scope_clusters").slice(0, 10);
    }),
    firstScopeCountries: Ember.computed("model.scope_countries.[]", function () {
      return this.get("model.scope_countries").slice(0, 10);
    }),
    firstScopeEntities: Ember.computed("model.scope_entities.[]", function () {
      return this.get("model.scope_entities").slice(0, 10);
    }),
    hasMoreClusters: Ember.computed("model.scope_clusters.[]", function () {
      return this.get("model.scope_clusters").length > 10;
    }),
    hasMoreCountries: Ember.computed("model.scope_countries.[]", function () {
      return this.get("model.scope_countries").length > 10;
    }),
    hasMoreEntities: Ember.computed("model.scope_entities.[]", function () {
      return this.get("model.scope_entities").length > 10;
    }),
    actions: {
      clearHubs: function clearHubs() {
        this.model.set("scope_hubs", []);
      },
      clearClusters: function clearClusters() {
        this.model.set("scope_clusters", []);
      },
      clearCountries: function clearCountries() {
        this.model.set("scope_countries", []);
        this.model.set("scope_entities", []);
      },
      clearBLs: function clearBLs() {
        this.model.set("scope_BLs", []);
      },
      addCluster: function addCluster(cluster) {
        var _this6 = this;

        if (cluster.children.length !== 0) {
          this.get("model.scope_entities").pushObjects(this.availableScopeEntities.filter(function (entity) {
            return cluster.get("children").includes(parseInt(entity.id)) && !_this6.get("model.scope_entities").findBy("id", entity.id);
          }));
        }
      },
      // add related country if it does not exist
      addEntity: function addEntity(entity) {
        var _this7 = this;

        var country = this.availableScopeCountries.findBy("id", entity.get("parent"));

        if (country) {
          this.get("model.scope_countries").pushObject(country);
        }

        var clusters = this.availableScopeClusters.filter(function (cluster) {
          return cluster.children.includes(parseInt(entity.id));
        });
        clusters.forEach(function (cluster) {
          if (!_this7.model.scope_clusters.findBy("id", entity.id)) {
            _this7.model.scope_clusters.pushObject(cluster);
          }
        });
      },
      removeCluster: function removeCluster(cluster) {
        // remove all entities of this cluster
        this.get("model.scope_entities").removeObjects(this.get("model.scope_entities").filter(function (entity) {
          return cluster.get("children").includes(parseInt(entity.id));
        })); // remove the cluster itself

        var e = this.get("model.scope_clusters").findBy("id", cluster.id);

        if (e) {
          this.get("model.scope_clusters").removeObject(e);
        }
      },
      removeHub: function removeHub(hub) {
        var e = this.get("model.scope_hubs").findBy("id", parseInt(hub.id));

        if (e) {
          this.get("model.scope_hubs").removeObject(e);
        }
      },
      removeCountry: function removeCountry(country) {
        var e = this.get("model.scope_countries").findBy("id", parseInt(country.id));

        if (e) {
          this.get("model.scope_countries").removeObject(e);
        }
      },
      removeEntity: function removeEntity(entity) {
        var e = this.get("model.scope_entities").findBy("id", entity.id);

        if (e) {
          this.get("model.scope_entities").removeObject(e);
        }
      },
      removeBL: function removeBL(bl) {
        var e = this.get("model.scope_BLs").findBy("id", parseInt(bl.id));

        if (e) {
          this.get("model.scope_BLs").removeObject(e);
        }
      },
      moreScopeClusters: function moreScopeClusters() {
        this.toggleProperty("showAllScopeClusters");
      },
      moreScopeCountries: function moreScopeCountries() {
        this.toggleProperty("showAllScopeCountries");
      },
      moreScopeEntities: function moreScopeEntities() {
        this.toggleProperty("showAllScopeEntities");
      },
      toggleDeployed: function toggleDeployed(scope) {
        this.model.toggleDeployed(scope).then(function () {
          scope.toggleProperty("deployed");
        });
      }
    }
  });

  _exports.default = _default;
});