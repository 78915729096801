define("innowaze-ember2/routes/challenges/invitations", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/utils/emailPattern"], function (_exports, _setCurrentChallenge, _emailPattern) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        invitation: this.store.adapterFor("challenge").findInvitations(params.challenge_id)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        saveDisabled: false,
        role: controller.get("availableRoles.firstObject"),
        emails: [],
        message: ""
      });
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      add: function add() {
        var _this = this;

        if (!Ember.isEmpty(this.get("controller.commaSeparatedEmails"))) {
          this.get("controller.commaSeparatedEmails").split(",").uniq().map(function (email) {
            return email.trim();
          }).forEach(function (email) {
            if (email.match(_emailPattern.default)) {
              _this.get("controller.emails").addObject(email);
            } else {
              _this.notifications.error("L'email ".concat(email, " est invalide"));
            }
          });
          this.set("controller.commaSeparatedEmails", "");
        }
      },
      addPendingEmail: function addPendingEmail(email) {
        this.get("controller.emails").addObject(email);
      },
      remove: function remove(email) {
        this.get("controller.emails").removeObject(email);
      },
      send: function send() {
        var _this2 = this;

        if (this.get("controller.emails.length") === 0) {
          this.notifications.error("Vous devez ajouter une adresse email au moins");
        } else {
          this.controller.set("saveDisabled", true);
          this.currentModel.challenge.saveInvitation(this.get("controller.emails"), this.get("controller.message"), this.get("controller.role.id")).then(function () {
            _this2.notifications.success("Les invitations ont été envoyées");

            _this2.transitionTo("challenges.show", _this2.currentModel.challenge.id);
          });
        }
      },
      cancel: function cancel() {
        this.transitionTo("challenges.show", this.currentModel.challenge.id);
      }
    }
  });

  _exports.default = _default;
});