define("innowaze-ember2/controllers/application", ["exports", "innowaze-ember2/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias("session.currentUser"),
    configuration: Ember.computed.alias("session.configuration"),
    locale: Ember.inject.service(),
    currentLanguage: Ember.computed("configuration.languages", "locale.currentLocale", function () {
      return this.get("configuration.languages").findBy("name", this.get("locale.currentLocale"));
    }),
    localeSwitch: Ember.computed.gt("configuration.languages.length", 1),
    nbRequest: 0,
    isLoading: Ember.computed("nbRequest", function () {
      return this.nbRequest > 0;
    }),
    displayHeader: Ember.computed("currentRouteName", function () {
      return !_configuration.default.subscriptionTargets.includes(this.currentRouteName);
    }),
    changeBodyClass: Ember.observer("currentPath", function () {
      var path = "page-".concat(this.currentPath.replace(".", "-"));
      var $body = $("body");
      var newClass = $body.attr("class").split(" ").map(function (className) {
        return className.includes("page-") ? "" : className;
      });
      newClass.push(path);
      newClass.push(this.get("userAgent.os.isWindows") ? "WindowsOS" : this.get("userAgent.os.info.name").replace(/ /g, ""));
      $body.attr("class", newClass.join(" "));
    }),
    setValidationCounter: Ember.observer("session.currentChallenge.awaiting_members", function () {
      var currentChallenge = this.get("session.currentChallenge");

      if (currentChallenge && currentChallenge.get("requires_validation") && currentChallenge.get("awaiting_members").length > 0) {
        this.set("validationCounter", currentChallenge.get("awaiting_members").length);
      } else {
        this.set("validationCounter", null);
      }
    })
  });

  _exports.default = _default;
});