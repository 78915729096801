define("innowaze-ember2/components/modules/documents-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    completedDidChange: Ember.on("init", Ember.observer("model.documents.[]", function () {
      this.set("module.isNotCompleted", !this.get("model.documents") || this.get("model.documents").length === 0);
    })),
    actions: {
      documentsUploaded: function documentsUploaded(files) {
        var documents = this.get("model.documents");
        files.forEach(function (file) {
          documents.pushObject({
            id: file.id,
            name: file.file_name,
            file_name: file.file_name,
            url: file.file_url
          });
        });
        this.model.send("becomeDirty");
      },
      removeDocument: function removeDocument(doc) {
        this.get("model.documents").removeObject(doc);
      },
      updateDocument: function updateDocument(id) {
        var _this$get$findBy = this.get("model.documents").findBy("id", id),
            name = _this$get$findBy.name;

        if (!name) {
          return;
        }

        var adapter = this.store.adapterFor("application");
        adapter.ajax("".concat(adapter.host, "/").concat(adapter.namespace, "/uploads/").concat(id, ".json"), "PUT", {
          data: {
            name: name
          }
        });
      }
    }
  });

  _exports.default = _default;
});