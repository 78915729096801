define("innowaze-ember2/templates/components/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lSGQqCiw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"pictureName\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"document-list\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[1,[21,\"pictureName\"],false],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"delete\"],[3,\"action\",[[22,0,[]],\"removeUpload\"]],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-times\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"file-upload-module\"],[12,\"style\",[21,\"backgroundStyle\"]],[9],[0,\"\\n  \"],[7,\"input\"],[12,\"multiple\",[21,\"multiple\"]],[11,\"type\",\"file\"],[9],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"filename\"],[9],[1,[27,\"i18n\",[\"file_upload\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"loading \",[27,\"if\",[[23,[\"hide\"]],\"hide\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"buffer\"],[12,\"style\",[21,\"progressStyle\"]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/file-upload.hbs"
    }
  });

  _exports.default = _default;
});