define("innowaze-ember2/adapters/blueocean", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      if (query.challenge_id && query.concept_id) {
        return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(query.challenge_id, "/concepts/").concat(query.concept_id, "/blue_ocean.json"), "GET");
      }

      return this._super.apply(this, arguments);
    },
    // Overridden from JSONAPIAdapter
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/challenges/").concat(snapshot.adapterOptions.challenge_id, "/concepts/").concat(snapshot.adapterOptions.concept_id, "/blue_ocean.json"), "POST", {
        data: {
          data: data
        }
      });
    }
  });

  _exports.default = _default;
});