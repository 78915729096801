define("innowaze-ember2/templates/tag-triggers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F3tJs+J1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"Modification du mot clé automatique \"],[1,[21,\"tag\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,\"tag-triggers/form\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/tag-triggers/edit.hbs"
    }
  });

  _exports.default = _default;
});