define("innowaze-ember2/helpers/format-percentage", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPercentage = formatPercentage;
  _exports.default = void 0;

  function formatPercentage(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        param = _ref2[0];

    if (!param) {
      return "0%";
    }

    return String(Math.trunc(param)).concat("%");
  }

  var _default = Ember.Helper.helper(formatPercentage);

  _exports.default = _default;
});