define("innowaze-ember2/models/upload", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    uploadable_type: _emberData.default.attr("string"),
    file_uid: _emberData.default.attr("string"),
    parent_name: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    typology: _emberData.default.attr("string"),
    is_image: _emberData.default.attr("boolean"),
    file_name: _emberData.default.attr("string"),
    file_url: _emberData.default.attr("string"),
    remote_url: _emberData.default.attr("string"),
    url: _emberData.default.attr("string"),
    attached_to_challenge: _emberData.default.attr("boolean"),
    visible_in_widget: _emberData.default.attr("boolean"),
    fileType: _emberData.default.attr("string"),
    size: _emberData.default.attr("string"),
    badges: _emberData.default.hasMany("linked-debate-badge", {
      async: false
    }),
    toggleVisibleInWidget: function toggleVisibleInWidget() {
      return this._callAdapter("toggleVisibleInWidget");
    }
  });

  _exports.default = _default;
});