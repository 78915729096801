define("innowaze-ember2/controllers/pathways/common", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    members: Ember.computed("model.author", "model.teammates", function () {
      return this.get("model.author") ? [].concat((0, _toConsumableArray2.default)(this.get("model.teammates").toArray()), [this.get("model.author")]) : this.get("model.teammates").toArray();
    }),
    membersIncludingAuthor: Ember.computed.alias("members"),
    membersExcludingAuthor: Ember.computed("members.[]", "model.author", function () {
      return this.get("model.author") ? this.members.rejectBy("id", this.get("model.author.id")) : this.members;
    }),
    logoBackground: Ember.computed("model.logo_url", function () {
      var url = this.get("model.logo_url");

      if (url) {
        return Ember.String.htmlSafe("background: url(".concat(this.get("session.apiUrl")).concat(url, ")\n      no-repeat; border: none;"));
      }

      return null;
    })
  });

  _exports.default = _default;
});