define("innowaze-ember2/components/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "svg",
    classNames: ["pie-chart"],
    attributeBindins: ["width", "height", "color"],
    didInsertElement: function didInsertElement() {
      // Define default values
      var width = this.width || 105;
      var height = this.height || 105;
      var radius = Math.min(width, height) / 2; // Prepare chart

      this.arc = d3.svg.arc().outerRadius(radius - 10).innerRadius(0);
      this.pie = d3.layout.pie().sort(null).value(function (d) {
        return d.value || 0;
      });
      this.svg = d3.select(this.$().get(0)).attr("width", width).attr("height", height).append("g").attr("transform", "translate(".concat(width / 2, ", ").concat(height / 2, ")")); // Update arc's values

      this.updateChart();
    },
    draw: Ember.observer("debate.comments.@each.answer_id", "debate.mixedPercentage", function () {
      this.updateChart();
    }),
    updateChart: function updateChart() {
      var _this = this;

      // Stop processing if there's no answer yet
      if (Ember.isEmpty(this.get("debate.comments"))) {
        return;
      } // Compute data value according to debate type


      var data;

      if (this.get("debate.type") === "ClaimDebate") {
        data = [{
          name: "agree",
          value: this.get("debate.agreedPercentage")
        }, {
          name: "disagree",
          value: this.get("debate.disagreedPercentage")
        }, {
          name: "mixed",
          value: this.get("debate.mixedPercentage")
        }];
      } else if (this.get("debate.type") === "SurveyDebate") {
        data = this.get("debate.available_answers").map(function (answer) {
          var percentage = _this.get("debate.comments").filterBy("answer_id", answer.id).length / _this.get("debate.comments_count") * 100;
          return {
            name: answer.text,
            value: percentage || 0,
            color: answer.color
          };
        });
      } // Create / update chart arcs


      var nodes = this.svg.selectAll(".arc").data(this.pie(data)); // On enter

      nodes.enter().append("g").attr("class", "arc").append("path").style("fill", function (d) {
        if (d.data.color) {
          return d.data.color;
        }

        switch (d.data.name) {
          case "agree":
            return "#96C219";

          case "disagree":
            return "#E05309";

          default:
          case "mixed":
            return "#E0A305";
        }
      }); // On enter / update

      nodes.select("g.arc path").attr("d", this.arc);
    }
  });

  _exports.default = _default;
});