define("innowaze-ember2/serializers/label", ["exports", "innowaze-ember2/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      if (snapshot.record.get("formula.selectedValueProposal")) {
        var selectedValueProposal = snapshot.record.get("formula.selectedValueProposal");
        delete json.formula.selectedValueProposal;
        json.formula.selectedValueProposal = selectedValueProposal.getProperties("analysis", "canevas_id", "color", "concept_id", "description", "exploration", "formula", "id", "location", "name", "notes", "object_id", "questions", "scheduled_at", "table_data_id", "updated_at", "value");
      }

      delete json.ready;
      delete json.updated_by;
      delete json.created_at;
      delete json.created_by;
      delete json.kind;
      return {
        label: json
      };
    }
  });

  _exports.default = _default;
});