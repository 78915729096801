define("innowaze-ember2/controllers/challenges/show", ["exports", "ramda/src/findLastIndex", "innowaze-ember2/utils/fileType"], function (_exports, _findLastIndex, _fileType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locale: Ember.inject.service(),
    isShowcase: false,
    concepts: [],
    canEdit: Ember.computed("session.isModerator", function () {
      return this.get("session.isModerator");
    }),
    conceptWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "concepts");
      return module && [3, 4, 5, 6].includes(module.get("mapping"));
    }),
    debateWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "active_debates");
      return module && [3, 4, 5, 6].includes(module.get("mapping"));
    }),
    largeDebateWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "active_debates");
      return module && module.get("mapping") === 1;
    }),
    challengeDebates: Ember.computed.filterBy("model.debates", "debatable_type", "Challenge"),
    domainWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "domains");
      return module && [3, 4, 5, 6].includes(module.get("mapping"));
    }),
    largeDomainWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "domains");
      return module && module.get("mapping") === 1;
    }),
    globalDomainWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "global_domains");
      return module && [3, 4, 5, 6].includes(module.get("mapping"));
    }),
    largeGlobalDomainWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "global_domains");
      return module && module.get("mapping") === 1;
    }),
    smallLikedIdeasWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "liked_ideas");
      return module && [3, 4, 5, 6].includes(module.get("mapping"));
    }),
    smallPathwayWidget: Ember.computed("model.modules.[]", function () {
      var module = this.get("model.modules").findBy("name", "pathways");
      return module && [3, 4, 5, 6].includes(module.get("mapping"));
    }),
    challengeImages: Ember.computed("model.documents", function () {
      return this.get("model.documents").filter(function (document) {
        return (0, _fileType.default)(document.get("remote_url")) === "image";
      });
    }),
    challengeVideos: Ember.computed("model.documents", function () {
      return this.get("model.documents").filter(function (document) {
        return (0, _fileType.default)(document.get("remote_url")) === "video";
      });
    }),
    challengeAudios: Ember.computed("model.documents", function () {
      return this.get("model.documents").filter(function (document) {
        return (0, _fileType.default)(document.get("remote_url")) === "audio";
      });
    }),
    otherDocuments: Ember.computed("model.documents", function () {
      return this.get("model.documents").filter(function (document) {
        return !["image", "video", "audio"].includes((0, _fileType.default)(document.get("remote_url")));
      });
    }),
    displayedHigherScoreUsers: Ember.computed("model.higher_score_users", "moreHigherScoreUsers", function () {
      return this.moreHigherScoreUsers ? this.get("model.higher_score_users") : this.get("model.higher_score_users").slice(0, 4);
    }),
    displayedHighestSavings: Ember.computed("model.highest_savings", "moreHighestSavings", function () {
      return this.moreHighestSavings ? this.get("model.highest_savings") : this.get("model.highest_savings").slice(0, 4);
    }),
    currentUserPerformance: Ember.observer("model.current_user_score", function () {
      this.set("quartiles", [Ember.Object.create({
        id: 1,
        name: this.locale.t("very_low"),
        current: false,
        icon: "fa-frown-o"
      }), Ember.Object.create({
        id: 2,
        name: this.locale.t("low"),
        current: false,
        icon: "fa-meh-o"
      }), Ember.Object.create({
        id: 3,
        name: this.locale.t("high"),
        current: false,
        icon: "fa-smile-o"
      }), Ember.Object.create({
        id: 4,
        name: this.locale.t("excellent"),
        current: false,
        icon: "fa-trophy"
      })]);
      var score = this.get("model.current_user_score");
      var array = [this.get("model.score_min"), this.get("model.score_q25"), this.get("model.score_q50"), this.get("model.score_q75"), this.get("model.score_max")];
      var index = (0, _findLastIndex.default)(function (value) {
        return value >= score;
      }, array);

      if (index === -1) {
        this.get("quartiles.firstObject").set("current", true);
      } else {
        this.quartiles.findBy("id", index).set("current", true);
      }
    }),
    currentUserDebatesContribution: Ember.computed("model.current_user_debates_contributions.[]", function () {
      return this.get("model.current_user_debates_contributions").isEvery("active", true);
    }),
    currentUserHasPostedIdeas: Ember.computed("model.current_user_posted_ideas", function () {
      return this.get("model.current_user_posted_ideas") > 0;
    }),
    currentUserHasNoAction: Ember.computed.and("currentUserDebatesContribution", "currentUserHasPostedIdeas")
  });

  _exports.default = _default;
});