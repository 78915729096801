define("innowaze-ember2/adapters/solution", ["exports", "innowaze-ember2/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    toggleFollow: function toggleFollow(id, idea) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("solution"), "/").concat(id, "/toggle_follow.json"), "POST");
    }
  });

  _exports.default = _default;
});