define("innowaze-ember2/models/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    _callAdapter: function _callAdapter(func) {
      var modelName = this.constructor.modelName || this._internalModel.modelName;
      var adapter = this.store.adapterFor(modelName);

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return adapter[func].apply(adapter, [this.id, this].concat(args));
    }
  });

  _exports.default = _default;
});