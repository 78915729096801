define("innowaze-ember2/templates/challenges/modules/-most-actives", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UYuCP+eR",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"unless\",[[23,[\"session\",\"isCompetitor\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dashboard-module\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"dashboard-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"challenges.most_actives.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[15,\"challenges/modules/explanation\",[]],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"most-active-users\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"most_active_users\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[7,\"a\"],[11,\"class\",\"picture\"],[9],[0,\"\\n            \"],[1,[27,\"avatar-img\",null,[[\"src\",\"size\"],[[22,1,[\"avatar_url\"]],\"small\"]]],false],[0,\"\\n          \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"users.show\",[22,1,[\"id\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[22,1,[\"is_ninja\"]]],null,{\"statements\":[[0,\"            \"],[7,\"i\"],[11,\"class\",\"fa fa-trophy ninja\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"div\"],[11,\"class\",\"idea-account\"],[9],[0,\"\\n            \"],[1,[22,1,[\"ideas_count\"]],false],[0,\"\\n            \"],[1,[27,\"capitalize\",[[27,\"name-for\",[[23,[\"model\"]],\"ideas\",\"plural_idea\"],null]],null],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"comment-account\"],[9],[0,\"\\n            \"],[1,[22,1,[\"comments_count\"]],false],[0,\" \"],[1,[27,\"i18n\",[\"challenges.show.comments\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/-most-actives.hbs"
    }
  });

  _exports.default = _default;
});