define("innowaze-ember2/templates/concepts/edit-modules/-pictures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XVaKUFru",
    "block": "{\"symbols\":[\"picture\"],\"statements\":[[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n  \"],[1,[27,\"i18n\",[\"concepts.modules.pictures\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"white-form\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"large-3 columns\"],[9],[0,\"\\n      \"],[1,[27,\"file-upload\",null,[[\"picture\",\"multiple\",\"filesUploaded\"],[[23,[\"newPicture\"]],true,\"picturesUploaded\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"model\",\"concept\",\"pictures\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n        \"],[7,\"img\"],[12,\"src\",[27,\"concat\",[[23,[\"session\",\"apiUrl\"]],[22,1,[\"url\"]]],null]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/edit-modules/-pictures.hbs"
    }
  });

  _exports.default = _default;
});