define("innowaze-ember2/mixins/routes/isAdmin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel: function beforeModel(transition) {
      if (!this.session.get("currentUser.admin")) {
        if (this.notifications && this.locale) {
          this.notifications.error(this.locale.t("access_denied"));
        }

        this.transitionTo("challenges");
      } else {
        this._super(transition);
      }
    }
  });

  _exports.default = _default;
});