define("innowaze-ember2/templates/user-modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wHLaBHQn",
    "block": "{\"symbols\":[\"module\"],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"creation-form\"],[3,\"action\",[[22,0,[]],\"save\",[23,[\"model\"]]],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"medium-12 columns\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"main-modules\"],[9],[0,\"\\n\"],[4,\"sortable-objects\",null,[[\"tagName\",\"class\",\"sortableObjectList\",\"sortEndAction\"],[\"ul\",\"sortable tiny-widget-list\",[23,[\"sortedModules\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"reorder\"],null]]],{\"statements\":[[4,\"each\",[[23,[\"sortedModules\"]]],null,{\"statements\":[[4,\"draggable-object\",null,[[\"tagName\",\"content\",\"isSortable\"],[\"li\",[22,1,[]],true]],{\"statements\":[[0,\"                \"],[7,\"span\"],[11,\"class\",\"sortable-handler\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-ellipsis-v\"],[9],[10],[10],[0,\"\\n                \"],[1,[22,1,[\"title\"]],false],[0,\"\\n                \"],[7,\"i\"],[12,\"class\",[28,[\"fa right \",[27,\"if\",[[22,1,[\"private\"]],\"fa-eye-slash\",\"fa-eye\"],null]]]],[3,\"action\",[[22,0,[]],\"togglePrivate\",[22,1,[]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"form-actions\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"next-step\"],[11,\"type\",\"submit\"],[9],[1,[27,\"i18n\",[\"save\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/user-modules.hbs"
    }
  });

  _exports.default = _default;
});