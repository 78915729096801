define("innowaze-ember2/templates/concepts/edit-modules/-main-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f3GfgsCL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"white-form\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"large-3 columns\"],[9],[0,\"\\n      \"],[1,[27,\"file-upload\",null,[[\"background\",\"filesUploaded\"],[[23,[\"model\",\"concept\",\"logo_url\"]],\"filesUploaded\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"large-9 columns\"],[9],[0,\"\\n      \"],[1,[27,\"textarea\",null,[[\"value\",\"placeholder\",\"class\"],[[23,[\"model\",\"concept\",\"name\"]],[27,\"i18n\",[\"concepts.modules.main_info.title_placeholder\"],null],\"title\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"placeholder\",\"onchange\"],[[23,[\"model\",\"concept\",\"description\"]],[27,\"i18n\",[\"concepts.modules.main_info.description_placeholder\"],null],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.concept.description\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/edit-modules/-main-info.hbs"
    }
  });

  _exports.default = _default;
});