define("innowaze-ember2/templates/email-templates/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sI3um8mS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n  \"],[7,\"form\"],[11,\"class\",\"creation-form email_template\"],[3,\"action\",[[22,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"white-form\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"class\"],[[23,[\"emailTemplate\",\"name\"]],[27,\"i18n\",[\"domains.form.name_placeholder\"],null],\"title\"]]],false],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"class\"],[[23,[\"emailTemplate\",\"subject\"]],[27,\"i18n\",[\"domains.form.subject_placeholder\"],null],\"title\"]]],false],[0,\"\\n      \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"placeholder\",\"onchange\",\"isPublic\"],[[23,[\"emailTemplate\",\"description\"]],[27,\"i18n\",[\"domains.form.description_placeholder\"],null],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"emailTemplate.description\"],null],true]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-actions\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[9],[1,[27,\"i18n\",[\"cancel\"],null],false],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"next-step\"],[11,\"type\",\"submit\"],[9],[1,[27,\"i18n\",[\"save\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/email-templates/form.hbs"
    }
  });

  _exports.default = _default;
});