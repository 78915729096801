define("innowaze-ember2/models/jury", ["exports", "ember-data", "innowaze-ember2/models/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    name: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    challenge_id: _emberData.default.attr("number"),
    query: _emberData.default.attr(),
    users: _emberData.default.hasMany("user", {
      async: false
    }),
    tags: _emberData.default.hasMany("tag", {
      async: false
    }),
    domains: _emberData.default.hasMany("domain", {
      async: false
    }),
    scopes: _emberData.default.hasMany("scope", {
      async: false
    }),
    evaluation_completion: _emberData.default.attr("number"),
    ideas_to_be_evaluated_count: _emberData.default.attr("number"),
    ideas_not_evaluated_count: _emberData.default.attr("number"),
    total_ideas_count: _emberData.default.attr("number"),
    _scopes: Ember.computed("scopes", function () {
      return this.scopes.mapBy("id");
    }),
    _tags: Ember.computed("tags", function () {
      return this.tags.mapBy("id");
    }),
    _domains: Ember.computed("domains", function () {
      return this.domains.mapBy("id");
    }),
    usersIds: Ember.computed("users.[]", function () {
      return this.users.mapBy("id");
    }),
    evaluationCompletionPercent: Ember.computed("evaluation_completion", function () {
      return Math.floor(this.evaluation_completion * 100);
    })
  });

  _exports.default = _default;
});