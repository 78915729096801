define("innowaze-ember2/templates/challenges/modules/pathway-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L3fQkVaa",
    "block": "{\"symbols\":[\"debate\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pathway-card\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"pathways.show\",[23,[\"model\",\"id\"]],[23,[\"pathway\",\"id\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"style\",[23,[\"pathway\",\"backgroundImage\"]]],[11,\"class\",\"image\"],[9],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"h2\"],[9],[1,[23,[\"pathway\",\"pathway_debates\"]],false],[10],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"title\"],[9],[1,[23,[\"pathway\",\"name\"]],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"comments\"],[9],[0,\" \"],[7,\"span\"],[9],[1,[23,[\"pathway\",\"comments_count\"]],false],[10],[0,\" \"],[7,\"i\"],[11,\"class\",\"fa fa-comment\"],[9],[10],[10],[0,\" \\n    \"],[7,\"div\"],[11,\"class\",\"text\"],[9],[1,[27,\"truncate\",[[23,[\"pathway\",\"description\"]],80],null],true],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"action-bar\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"action explore\"],[9],[0,\"\\n            \"],[7,\"span\"],[9],[1,[27,\"i18n\",[\"explore\"],null],false],[10],[0,\"\\n            \"],[7,\"span\"],[9],[0,\"  \"],[10],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"fa fa-search\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"infos\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"pathway\",\"debates\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[9],[1,[22,1,[\"comments_count\"]],false],[10],[0,\"\\n        \"],[1,[27,\"debate-icon\",null,[[\"type\"],[[22,1,[\"type\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/challenges/modules/pathway-card.hbs"
    }
  });

  _exports.default = _default;
});