define("innowaze-ember2/routes/pathways/common", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _setCurrentChallenge, _cleanCurrentModel, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _setCurrentChallenge.default, _selectAutocomplete.default, {
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        tagsToDisplay: []
      });
      this.store.query("tag", {
        supertags: true,
        challenge_id: model.get("challenge_id")
      }).then(function (supertag) {
        controller.set("tagsToDisplay", model.get("tags").toArray().concat(supertag.toArray()).uniqBy("name"));
      });
    },
    actions: {
      filesUploaded: function filesUploaded(files) {
        this.set("controller.model.logo_id", files.get("firstObject").id);
        this.set("controller.model.logo_url", files.get("firstObject").file_url);
      },
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      documentsUploaded: function documentsUploaded(files) {
        var _this = this;

        Array.from(files).forEach(function (file) {
          _this.currentModel.get("documents").pushObject({
            id: file.id,
            name: file.file_name,
            file_name: file.file_name,
            url: file.file_url
          });
        });
      },
      removeDocument: function removeDocument(doc) {
        this.currentModel.get("documents").removeObject(doc);
      },
      updateDocument: function updateDocument(id) {
        var name = this.currentModel.get("documents").findBy("id", id).name.name;

        if (!name) {
          return;
        }

        var adapter = this.store.adapterFor("application");
        adapter.ajax("".concat(adapter.host, "/").concat(adapter.namespace, "/uploads/").concat(id, ".json"), "PUT", {
          data: {
            name: name
          }
        });
      },
      save: function save() {
        var _this2 = this;

        this.currentModel.save({
          adapterOptions: {
            challenge_id: this.currentModel.get("challenge_id")
          }
        }).then(function () {
          return _this2._success();
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("pathways.show", this.currentModel.get("challenge_id"), this.currentModel.get("id"));
      },
      newTag: function newTag(name) {
        var newTag = this.store.createRecord("tag", {
          id: name,
          name: name
        });
        this.currentModel.get("tags").pushObject(newTag);
        this.get("controller.tagsToDisplay").pushObject(newTag);
      },
      willTransition: function willTransition() {
        this.get("controller.tagsToDisplay").forEach(function (item) {
          item.deleteRecord();
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});