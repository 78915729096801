define("innowaze-ember2/templates/components/formula-value-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AtU71p71",
    "block": "{\"symbols\":[\"label\",\"item\"],\"statements\":[[4,\"each\",[[23,[\"relatedLabels\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"number\"],[9],[0,\"\\n      \"],[1,[22,1,[\"humanValue\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"formula\",\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[1,[22,2,[\"label\",\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/formula-value-details.hbs"
    }
  });

  _exports.default = _default;
});