define("innowaze-ember2/components/news-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    classNames: ["news-banner"],
    classNameBindings: ["newsType", "isDraft"],
    isDraft: Ember.computed("news_item.published", function () {
      if (!this.get("news_item.published")) {
        return "news-banner--draft";
      }
    }),
    newsType: Ember.computed("news_item.newsable_type", function () {
      if (this.get("news_item.newsable_type")) {
        return "news-banner--".concat(this.get("news_item.newsable_type").toLowerCase());
      }
    }),
    actions: {
      deleteNews: function deleteNews(news) {
        if (!confirm(this.locale.t("challenges.show.confirm_delete_news"))) {
          return;
        }

        this.sendAction("deleteNews", news);
      },
      togglePublish: function togglePublish(news) {
        var _this = this;

        news.togglePublish().then(function (state) {
          news.toggleProperty("published");

          _this.notifications.success(news.get("publishNotificationText"));
        });
      },
      toggleAdvertize: function toggleAdvertize(news) {
        var _this2 = this;

        news.toggleAdvertize().then(function (state) {
          news.toggleProperty("is_ad");

          _this2.notifications.success(news.get("adNotificationText"));
        });
      },
      requestToPublish: function requestToPublish(news) {
        var _this3 = this;

        news.requestToPublish().then(function (state) {
          if (state.requested) {
            news.set("requested", true);

            _this3.notifications.success(_this3.locale.t("news.has_been_requested"));
          }
        });
      }
    }
  });

  _exports.default = _default;
});