define("innowaze-ember2/adapters/debate", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/adapters/application"], function (_exports, _defineProperty2, _objectSpread3, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    toggleVisibleInWidget: function toggleVisibleInWidget(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(id, "/toggle_visible_in_widget.json"), "PUT");
    },
    translate: function translate(id, debate, language) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(id, "/translate.json"), "GET", {
        data: {
          debatable_id: debate.get("debatable_id"),
          debatable_type: debate.get("debatable_type"),
          language: language
        }
      });
    },
    tagsCloud: function tagsCloud(id, debate, selectedMethod, selectedLocale) {
      if (selectedMethod === "hashtag") {
        return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(id, "/tags_cloud.json"), "GET", {
          data: {
            method: selectedMethod,
            locale: selectedLocale
          }
        });
      }

      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(id, "/words_cloud.json"), "GET", {
        data: {
          method: selectedMethod,
          locale: selectedLocale
        }
      });
    },
    answersNetwork: function answersNetwork(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(id, "/answers_network.json"), "GET");
    },
    wordsCooccurrences: function wordsCooccurrences(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(id, "/words_cooccurrences.json"), "GET");
    },
    addHashtags: function addHashtags(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(id, "/add_hashtags.json"), "GET");
    },
    clearVerbatims: function clearVerbatims(id) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/debates/").concat(id, "/clear_verbatims.json"), "GET");
    },
    reorder: function reorder(debates) {
      return this.ajax("".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType("debate"), "/set_order.json"), "POST", {
        data: {
          debates: debates.reduce(function (acc, debate) {
            return (0, _objectSpread3.default)({}, acc, (0, _defineProperty2.default)({}, debate.id, debate.get("position")));
          }, {})
        }
      });
    }
  });

  _exports.default = _default;
});