define("innowaze-ember2/serializers/expert", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _objectSpread2, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      primary_expert: {
        deserialize: "records",
        serialize: false
      },
      secondary_expert: {
        deserialize: "records",
        serialize: false
      },
      level2_expert: {
        deserialize: "records",
        serialize: false
      },
      security_expert: {
        deserialize: "records",
        serialize: false
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      return (0, _objectSpread2.default)({}, json, {
        primary_expert_id: snapshot.record.get("primary_expert.id"),
        secondary_expert_id: snapshot.record.get("secondary_expert.id"),
        level2_expert_id: snapshot.record.get("level2_expert.id"),
        security_expert_id: snapshot.record.get("security_expert.id")
      });
    }
  });

  _exports.default = _default;
});