define("innowaze-ember2/mixins/routes/polling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      togglePolling: function togglePolling() {
        if (this.get("controller.loopIsRunning")) {
          this._stopPoll();
        } else {
          this._startPoll();
        }
      }
    },
    // polling
    _schedule: function _schedule(f) {
      var _this = this;

      this.controller.decrementProperty("pollTime");
      var interval = 5000;
      return Ember.run.later(this, function () {
        if (_this.get("controller.pollTime") > 0) {
          f.apply(_this);

          _this.controller.setProperties({
            loopIsRunning: true,
            timer: _this._schedule(f)
          });
        } else {
          _this.controller.setProperties({
            loopIsRunning: false,
            pollTime: null
          });
        }
      }, interval);
    },
    _startPoll: function _startPoll() {
      this.controller.setProperties({
        pollTime: 100,
        loopIsRunning: true,
        timer: this._schedule(this._modelRefresh)
      });
    },
    _stopPoll: function _stopPoll() {
      this.controller.setProperties({
        pollTime: null,
        loopIsRunning: false
      });
      Ember.run.cancel(this.get("controller.timer"));
    }
  });

  _exports.default = _default;
});