define("innowaze-ember2/routes/challenge-badges/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    deactivate: function deactivate() {
      if (this.currentModel.badge.get("hasDirtyAttributes")) {
        this.currentModel.badge.rollbackAttributes();
      }
    },
    actions: {
      filesUploaded: function filesUploaded(files) {
        this.currentModel.badge.setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      },
      save: function save() {
        var _this = this;

        this.currentModel.badge.save({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id
          }
        }).then(function () {
          return _this._success();
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challengeBadges", this.currentModel.badge.get("challenge_id"));
      }
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.locale.t("creation_error"), response.errors);
    }
  });

  _exports.default = _default;
});