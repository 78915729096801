define("innowaze-ember2/templates/departments/modules/-previous-partnerships", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2rK5ivm7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"columns\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n      \"],[1,[27,\"i18n\",[\"departments.previous_contact\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-module\"],[9],[0,\"\\n      \"],[1,[27,\"textarea-wysiwyg\",null,[[\"value\",\"onchange\",\"placeholder\"],[[23,[\"model\",\"previous_partnership_description\"]],[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"updateTextareaField\",\"model.previous_partnership_description\"],null],[27,\"i18n\",[\"departments.previous_contact_placeholder\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"model\",\"company_contacts\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/departments/modules/-previous-partnerships.hbs"
    }
  });

  _exports.default = _default;
});