define("innowaze-ember2/components/modules/custom-module", ["exports", "innowaze-ember2/utils/normalizeTemplate", "innowaze-ember2/components/modules/module"], function (_exports, _normalizeTemplate, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      this._super.apply(this, arguments); // Initialize content if template exists


      var template = this.get("module.params.template");

      if (!this.get("module.description") && template) {
        Ember.set(this.module, "description", template);
        this.get("model.extra_modules").pushObject({
          id: this.module.id,
          description: template
        });
      }
    },
    completedDidChange: Ember.on("init", Ember.observer("module.description", function () {
      var description = this.get("module.description");

      if (this.get("module.params.template")) {
        // Compare actual value against template
        // We need to normalize the html strings beforehand: remove blank spaces between tags and replace single quotes with double quotes
        var normalizedTemplate = (0, _normalizeTemplate.default)(this.get("module.params.template"));
        var normalizedDescription = description ? (0, _normalizeTemplate.default)(description) : "";

        if (normalizedTemplate === normalizedDescription) {
          this.set("module.isNotCompleted", true);
          return;
        }
      }

      this.set("module.isNotCompleted", !description);
    })),
    translationDidChange: Ember.observer("translatedExtraModules", function () {
      if (!this.translatedExtraModules) {
        return;
      }

      var translatedModule = this.translatedExtraModules.findBy("id", parseInt(this.get("module.id")));

      if (translatedModule) {
        this.set("module.translatedDescription", translatedModule.content);
        this.set("module.language", translatedModule.language);
      }
    }),
    actions: {
      toggleOrigin: function toggleOrigin() {
        this.toggleProperty("module.origin");
      },
      updateCustomTextareaField: function updateCustomTextareaField(module, newContent) {
        var customs;

        if (this.get("model.modules")) {
          customs = this.get("model.modules");
        } else {
          customs = this.get("model.extra_modules");
        }

        var m = customs.findBy("id", module.id);

        if (m) {
          Ember.set(m, "description", newContent);
        } else {
          customs.pushObject({
            id: module.id,
            description: newContent || module.description
          });
        }

        this.set("module.description", newContent);
        this.model.send("becomeDirty");
      }
    }
  });

  _exports.default = _default;
});