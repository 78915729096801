define("innowaze-ember2/utils/normalizeTemplate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeTemplate;

  function normalizeTemplate(string) {
    return string.replace(/>\s+|\s+</g, function (m) {
      return m.trim();
    }).replace(/'/g, "\"").replace(/&nbsp;/g, "");
  }
});