define("innowaze-ember2/templates/components/news-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0hdBIfZ9",
    "block": "{\"symbols\":[\"news_item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"news-module__header\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"news-module__title\"],[9],[0,\"News\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"canEdit\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"Challenge\"],null]],null,{\"statements\":[[4,\"link-to\",[\"news.new\",[23,[\"newsable_id\"]]],[[\"class\"],[\"button-primary news-module__add button-small right\"]],{\"statements\":[[0,\"        \"],[1,[27,\"i18n\",[\"challenges.news.add\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"Department\"],null]],null,{\"statements\":[[4,\"link-to\",[\"newsDepartment.new\",[23,[\"newsable_id\"]]],[[\"class\"],[\"button-primary news-module__add button-small right\"]],{\"statements\":[[0,\"        \"],[1,[27,\"i18n\",[\"challenges.news.add\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"news-module__news-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"visibleNews\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"news-banner\",null,[[\"news_item\",\"canEdit\",\"canPublish\",\"deleteNews\"],[[22,1,[]],[23,[\"canEdit\"]],[23,[\"canPublish\"]],\"deleteNews\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[4,\"unless\",[[27,\"or\",[[23,[\"allNewsVisible\"]],[27,\"not\",[[23,[\"visibleNews\",\"length\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"news-module__all\"],[3,\"action\",[[22,0,[]],\"showAllNews\"]],[9],[0,\"\\n    \"],[1,[27,\"i18n\",[\"news.all\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/news-module.hbs"
    }
  });

  _exports.default = _default;
});