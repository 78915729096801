define("innowaze-ember2/routes/tag-triggers/common", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel"], function (_exports, _cleanCurrentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      controller.set("model", model);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.set("keywords", this.get("controller.keywords").map(function (obj) {
          return obj.get("name");
        }));
        this.currentModel.save().then(function () {
          return _this._success();
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this.transitionTo("tagTriggers");
      },
      newKeyword: function newKeyword(name) {
        this.get("controller.keywords").pushObject(Ember.Object.create({
          id: this.get("controller.keywords").length,
          name: name
        }));
      }
    },
    _success: function _success() {
      this.transitionTo("tagTriggers");
      this.notifications.success(this.successMessage);
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.errorMessage, response.errors);
    }
  });

  _exports.default = _default;
});