define("innowaze-ember2/routes/news-instance/common", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _cleanCurrentModel, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, _selectAutocomplete.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("tagsToDisplay", this.currentModel.get("tags").toArray());
      this.store.query("domain", {}).then(function (domains) {
        controller.set("availableDomains", domains);
      });
    },
    actions: {
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function () {
          return _this._success();
        }, function (response) {
          return _this._error(response);
        });
      },
      cancel: function cancel() {
        this._transitionTo();
      },
      filesUploaded: function filesUploaded(files) {
        var file = files.get("firstObject");
        this.currentModel.set("picture_id", file.id);
        this.currentModel.set("picture_url", file.file_url);
      },
      newTag: function newTag(name) {
        var newTag = this.store.createRecord("tag", {
          id: name,
          name: name
        });
        this.currentModel.get("tags").pushObject(newTag);
        this.get("controller.tagsToDisplay").pushObject(newTag);
      }
    },
    _transitionTo: function _transitionTo() {
      if (this.currentModel.get("newsable_type") === "Challenge") {
        this.transitionTo("challenges.show", this.currentModel.get("newsable_id"));
      } else if (this.currentModel.get("newsable_type") === "Department") {
        this.transitionTo("departments.show", this.currentModel.get("newsable_id"));
      } else {
        this.transitionTo("newsInstance");
      }
    },
    _success: function _success() {
      this._transitionTo();

      this.notifications.success(this.successMessage);
    },
    _error: function _error(response) {
      this.notifications.errorWithList(this.errorMessage, response.errors);
    }
  });

  _exports.default = _default;
});