define("innowaze-ember2/helpers/event-hours", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    locale: Ember.inject.service(),
    handleLocaleChange: Ember.observer("locale.currentLocale", function () {
      this.recompute();
    }),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          event = _ref2[0];

      var text;
      var currentLocale = this.locale.currentLocale || "fr";
      var eventStart = moment(event.get("starts_at")).locale(currentLocale);
      var eventEnd = moment(event.get("ends_at")).locale(currentLocale);

      if (moment(event.get("starts_at")).isSame(moment(event.get("ends_at")), "day")) {
        text = "".concat(eventStart.format("LT"), " - ").concat(eventEnd.format("LT"));
      } else {
        text = "".concat(this.locale.t("event.show.starts_at"), " ").concat(eventStart.format("LT"), "\n       ").concat(this.locale.t("event.show.ends_on"), " ").concat(eventEnd.format("LLL"));
      }

      return Ember.String.htmlSafe(text);
    }
  });

  _exports.default = _default;
});