define("innowaze-ember2/mixins/routes/injectTags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var timeout;
  var throttling = false;

  var _default = Ember.Mixin.create({
    actions: {
      initTags: function initTags() {
        this.set("controller.tagsToDisplay", this.currentModel.supertags.toArray());
      },
      filterForTagsChanged: function filterForTagsChanged(filter) {
        if (throttling) {
          window.clearTimeout(timeout);
        }

        throttling = true;
        var store = this.store;
        var collection = this.tagsToDisplay;

        if (!this.module) {
          store = this.store;
          collection = this.get("controller.tagsToDisplay");
        }

        timeout = window.setTimeout(function () {
          if (filter.length > 1) {
            return store.query("tag", {
              query: filter
            }).then(function (tags) {
              var filteredTags = tags.filter(function (tag) {
                return !collection.includes(tag);
              });
              collection.pushObjects(filteredTags);
            });
          }

          throttling = false;
        }, 500);
      },
      willTransition: function willTransition() {
        if (this.get("controller.tagsToDisplay")) {
          this.get("controller.tagsToDisplay").forEach(function (item) {
            item.deleteRecord();
          });
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});