define("innowaze-ember2/routes/concepts/canevas", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/configuration", "innowaze-ember2/mixins/routes/linkedDebateBadge", "innowaze-ember2/utils/prepareLabel", "innowaze-ember2/config/environment"], function (_exports, _setCurrentChallenge, _configuration, _linkedDebateBadge, _prepareLabel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _linkedDebateBadge.default, {
    notifications: Ember.inject.service(),
    model: function model(params) {
      this.set("kind", params.kind);
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        concept: this.store.findRecord("concept", params.concept_id, {
          adapterOptions: {
            challenge_id: params.challenge_id
          }
        })
      });
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.set("data", null);
        controller.set("newLabel", null);
        var labels = this.store.peekAll("label");

        if (labels) {
          labels.forEach(function (rec) {
            return rec.unloadRecord();
          });
        }

        var canevas = this.store.peekRecord("canevas", 1);

        if (canevas) {
          canevas.unloadRecord();
        }
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.set("kind", this.kind);
      this.store.queryRecord("canevas", {
        challenge_id: model.challenge.id,
        concept_id: model.concept.id,
        kind: this.kind
      }).then(function (data) {
        controller.setProperties({
          data: data,
          canvasPublished: data.get("canvasPublished"),
          linkedDebateBadges: data.get("badges")
        });

        try {
          if (_environment.default.environment === "development") {
            Faye.logger = window.console; // useful to debug wss
          }

          var fayeUrl = "".concat(_configuration.default.apiUrl, "/faye");
          var client = new Faye.Client(fayeUrl);
          client.on("transport:down", function () {
            controller.set("noWebsocket", true);

            _this.notifications.error("Connexion perdue");
          });
          client.on("transport:up", function () {
            controller.set("noWebsocket", false);

            _this.notifications.success("Connexion établie avec le serveur");
          });
          var subscription = client.subscribe("/concept/".concat(model.concept.id, "/").concat(data.get("canevas.id")), function (event) {
            return _this.handleWebSocketEvent(event, controller, model);
          });

          _this.set("subscription", subscription);
        } catch (error) {
          controller.set("noWebsocket", true);
        }
      });
    },
    actions: {
      willTransition: function willTransition() {
        if (this.subscription) {
          this.subscription.cancel();
        }

        return true;
      },
      linkBadge: function linkBadge() {
        var _this2 = this;

        var debate = this.currentModel.concept.get("debates").findBy("id", this.get("controller.currentBadge.debate_id"));
        var currentBadge = this.get("controller.currentBadge");
        currentBadge.setProperties({
          name: debate.get("question"),
          explanation: debate.get("explanation"),
          badgeable_type: "Canevas",
          badgeable_id: this.get("controller.data.canevas.id"),
          concept_id: this.currentModel.concept.id
        });
        currentBadge.save().then(function (data) {
          currentBadge.set("id", data.id);

          _this2.set("controller.currentBadge", null);

          _this2.send("toggleBadgeModal");
        });
      },
      addLabel: function addLabel() {
        var newLabel = this.get("controller.newLabel"); // Prevent to save label without name or color

        if (Ember.isBlank(newLabel.get("name"))) {
          return;
        }

        newLabel.set("ready", true);
        this.get("controller.data.labels").addObject(newLabel);

        this._saveLabel(newLabel); // Hide form


        this.controller.toggleProperty("labelModalVisible");
      },
      editLabel: function editLabel(label) {
        this.send("toggleLabelModal");
        this.set("controller.newLabel", label);
      },
      removeLabel: function removeLabel(label) {
        var _this3 = this;

        if (!confirm("Êtes-vous sûr de vouloir supprimer l'étiquette ?")) {
          return;
        }

        this._removeLabelFromCells(label);

        this._removeLabelFromFormulas(label);

        this.controller.set("data.labels", this.get("controller.data.labels").without(label));
        label.destroyRecord({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id
          }
        }).then(function () {
          return _this3._success();
        });
      },
      toggleLabelModal: function toggleLabelModal() {
        // Reset object / form
        this.controller.set("newLabel", this.store.createRecord("label", {
          object_id: Date.now(),
          concept_id: this.currentModel.concept.id,
          canevas_id: this.get("controller.data.canevas.id"),
          color: "#777777",
          formula: {
            items: [],
            markets: []
          }
        }));
        this.controller.toggleProperty("labelModalVisible");
      },
      labelDroppedToCell: function labelDroppedToCell(labelId, cell) {
        if (!labelId) {
          return;
        }

        var label = this.get("controller.data.labels").find(function (label) {
          return label.id === labelId;
        });

        if (!label || label.get("table_data_id") === cell.get("id")) {
          // Nothing changed
          return;
        }

        this._removeLabelFromCells(label); // Mark label as used, clean formula, and add it to the dropped cell


        label.setProperties({
          table_data_id: cell.get("id"),
          kind: cell.get("kind"),
          formula: {
            selectedFormula: null,
            selectedValueProposal: null,
            items: [],
            markets: []
          }
        });
        cell.get("labels").addObject(label);

        if (["market", "cost", "revenue", "investment", "driver"].includes(cell.get("kind"))) {
          label.set("value", 0);
        } else {
          label.set("value", null);
        }

        this._saveLabel(label);
      },
      selectForValueEdition: function selectForValueEdition(label) {
        this.controller.set("selectedLabel", label);
        this.controller.toggleProperty("valueModalVisible");
      },
      saveValue: function saveValue() {
        this._saveLabel(this.get("controller.selectedLabel"));

        this.controller.set("selectedLabel", null);
        this.controller.toggleProperty("valueModalVisible");
      },
      addFormulaItem: function addFormulaItem(host, label) {
        if (host.get("formula.items").findBy("label", label) === undefined) {
          host.get("formula.items").addObject(Ember.Object.create({
            label: label
          }));
        }
      },
      setFormulaItemValue: function setFormulaItemValue(host, label, value) {
        var item = host.get("formula.items").findBy("label", label);

        if (item) {
          item.set("value", value);
        }
      },
      setFormulaItemPeriod: function setFormulaItemPeriod(host, label, value) {
        var item = host.get("formula.items").findBy("label", label);

        if (item) {
          item.set("period", value);
        }
      },
      removeFormulaItem: function removeFormulaItem(host, label) {
        var item = host.get("formula.items").findBy("label", label);
        host.get("formula.items").removeObject(item);
      },
      addFormulaMarket: function addFormulaMarket(host, label) {
        if (!host.get("formula.markets").findBy("label", label)) {
          host.get("formula.markets").addObject(Ember.Object.create({
            label: label,
            elements: []
          }));
        }
      },
      removeFormulaMarket: function removeFormulaMarket(host, label) {
        host.get("formula.markets").removeObject(host.get("formula.markets").findBy("label", label));
        host.set("formula.markets", host.get("formula.markets").filter(function (market) {
          return market.get("label.id").toString() !== label.get("id").toString();
        }));
      },
      setFormulaMarketValue: function setFormulaMarketValue(host, label, item, value) {
        label = host.get("formula.markets").findBy("label", label);
        var element = label.get("elements").findBy("item", item);

        if (element) {
          element.set("value", value);
        } else {
          label.get("elements").addObject(Ember.Object.create({
            item: item,
            value: value
          }));
        }

        host.set("formula.touched", Date.now());
      },
      setFormulaMarketPeriod: function setFormulaMarketPeriod(host, label, value) {
        var item = host.get("formula.markets").findBy("label", label);

        if (item) {
          item.set("period", value);
        }
      },
      marketValueChanged: function marketValueChanged(label) {
        // Notify each formula using the label for which the value changed
        // so formula is computed again
        this.get("controller.data.labels").forEach(function (l) {
          if (l.get("formula.markets").isAny("label", label)) {
            l.set("formula.touched", Date.now());
          }
        });
      },
      setScheduledAt: function setScheduledAt(label, date) {
        label.set("scheduled_at", date);
      }
    },
    _saveLabel: function _saveLabel(label) {
      var _this4 = this;

      if (!this.get("controller.noWebsocket")) {
        label.set("ready", false);
        label.save({
          adapterOptions: {
            challenge_id: this.currentModel.challenge.id
          }
        }).then(function (data) {
          _this4._success(data);

          label.set("ready", true);
        });
      }
    },
    _removeLabelFromCells: function _removeLabelFromCells(label) {
      this.get("controller.data.table").forEach(function (row) {
        row.forEach(function (cell) {
          cell.set("labels", cell.get("labels").without(label));
        });
      });
    },
    _removeLabelFromFormulas: function _removeLabelFromFormulas(label) {
      this.get("controller.data.labels").forEach(function (elt) {
        var matchings = elt.get("formula.items").filterBy("label", label);
        elt.get("formula.items").removeObjects(matchings);
        matchings = elt.get("formula.markets").filterBy("label", label);
        elt.get("formula.markets").removeObjects(matchings);
      });
    },
    handleWebSocketEvent: function handleWebSocketEvent(event, controller, model) {
      var replaceLabelData = function replaceLabelData(label, newLabel, data) {
        if (label.get("value") !== newLabel.value) {
          label.set("value", newLabel.value);
        }

        if (label.get("name") !== newLabel.name) {
          label.set("name", newLabel.name);
        }

        if (label.get("description") !== newLabel.description) {
          label.set("description", newLabel.description);
        }

        if (label.get("updated_at") !== newLabel.updated_at) {
          label.set("updated_at", newLabel.updated_at);
        }

        if (label.get("created_at") !== newLabel.created_at) {
          label.set("created_at", newLabel.created_at);
        }

        if (label.get("table_data_id") !== newLabel.table_data_id) {
          label.set("table_data_id", newLabel.table_data_id);
        }

        if (label.get("kind") !== newLabel.kind) {
          label.set("kind", newLabel.kind);
        }

        if (label.get("color") !== newLabel.color) {
          label.set("color", newLabel.color);
        }

        if (!Ember.isEqual(label.get("formula"), newLabel.formula) && data.get("formulas")) {
          label.set("ready", false);
          label.set("formula", newLabel.formula);
          (0, _prepareLabel.default)(data, label);
          label.set("ready", true);
        }
      };

      var replaceLabel = function replaceLabel(container, newLabel) {
        var label = container.get("labels").findBy("id", newLabel.id.toString());

        if (label) {
          replaceLabelData(label, newLabel, container);
        }
      };

      if (event.newLabel) {
        if (this.get("session.currentUser.id") === event.newLabel.updated_by.id.toString()) {
          return;
        }

        this.notifications.info("".concat(event.newLabel.updated_by.display_name, " a mis \xE0 jour le canevas"));
        var data = controller.data;
        var newLabel = this.store.peekRecord("label", event.newLabel.id);

        if (!newLabel) {
          newLabel = this.store.push({
            data: {
              id: event.newLabel.id,
              type: "label",
              attributes: event.newLabel
            }
          });
        }

        (0, _prepareLabel.default)(data, newLabel);
        data.get("labels").popObject();
        data.get("labels").addObject(newLabel);
      }

      if (event.label) {
        if (this.get("session.currentUser.id") === event.label.updated_by.id.toString()) {
          return;
        }

        this.notifications.info("".concat(event.label.updated_by.display_name, " a mis \xE0 jour le canevas"));
        var _data = controller.data;

        var table = _data.get("table");

        if (event.destroy) {
          _data.set("labels", _data.get("labels").rejectBy("id", event.label.id.toString()));

          table.forEach(function (row) {
            var cell = row.findBy("id", event.label.table_data_id);

            if (cell) {
              cell.set("labels", cell.get("labels").rejectBy("id", event.label.id.toString()));
            }
          });
        } else {
          var currentLabelCellId = _data.get("labels").find(function (label) {
            return label.id.toString() === event.label.id.toString();
          }).get("table_data_id") || null;
          var hasMoved = currentLabelCellId !== event.label.table_data_id;

          if (hasMoved) {
            var label = false;
            table.forEach(function (row) {
              var oldCell = row.findBy("id", currentLabelCellId);

              if (oldCell) {
                label = oldCell.get("labels").find(function (label) {
                  return label.id.toString() === event.label.id.toString();
                });
                oldCell.set("labels", oldCell.get("labels").rejectBy("id", event.label.id.toString()));
              }
            });

            if (!label) {
              label = _data.get("labels").find(function (label) {
                return label.id.toString() === event.label.id.toString();
              });
            }

            table.forEach(function (row) {
              var cell = row.findBy("id", event.label.table_data_id);

              if (cell) {
                event.label.kind = cell.get("kind");
                var labelIds = cell.get("labels").mapBy("id");

                if (!labelIds.includes(event.label.id)) {
                  replaceLabelData(label, event.label, _data);
                  cell.get("labels").pushObject(label);
                } else {
                  replaceLabel(_data, event.label);
                  replaceLabel(cell, event.label);
                }
              }
            });
          } else {
            table.forEach(function (row) {
              var cell = row.findBy("id", event.label.table_data_id);

              if (cell) {
                event.label.kind = cell.get("kind");
                replaceLabel(cell, event.label);
              }
            });
            replaceLabel(_data, event.label);
          }

          _data.get("labels").forEach(function (label) {
            if (label.get("formula.markets")) {
              var markets = label.get("formula.markets").filter(function (market) {
                if (market.get("label.id") === event.label.id) {
                  if (event.label.value === null) {
                    return false;
                  }

                  label.set("formula.touched", Date.now());
                }

                return true;
              });

              if (markets.length !== label.get("formula.markets").length) {
                label.set("formula.markets", markets);
              }
            }
          });
        }
      }
    },
    _success: function _success(data) {
      if (data) {
        if (data.error) {
          this.notifications.error(data.error, {
            autoClear: false
          });
          var label = Ember.Object.create(data.label);
          (0, _prepareLabel.default)(this.get("controller.data"), label);
          this.get("controller.data").set("labels", this.get("controller.data.labels").map(function (l) {
            if (l.id === label.id) {
              label.set("ready", true);
              return label;
            }

            return l;
          }));
          this.get("controller.data.table").forEach(function (row) {
            var cell = row.findBy("id", label.table_data_id);

            if (cell) {
              label.set("kind", cell.get("kind"));
              cell.set("labels", cell.get("labels").map(function (l) {
                if (l.id === label.id) {
                  label.set("ready", true);
                  return label;
                }

                return l;
              }));
            }
          });
        } else {
          // Manually update the new label's id, if any
          var ctrlData = this.get("controller.data");
          (0, _prepareLabel.default)(ctrlData, data);
          ctrlData.set("labels", ctrlData.get("labels").map(function (label) {
            if (label.id === null && label.get("name") === data.name) {
              label.id = String(data.id);
            }

            return label;
          }));
        }
      }

      return data;
    }
  });

  _exports.default = _default;
});