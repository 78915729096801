define("innowaze-ember2/mixins/routes/setCurrentChallenge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    afterModel: function afterModel(model) {
      var _this = this;

      this._super.apply(this, arguments);

      if (model.get && model.get("challenge_id")) {
        return this.store.findRecord("challenge", model.get("challenge_id")).then(function (challenge) {
          _this.session.set("currentChallenge", challenge);
        });
      }

      var challenge = model.challenge ? model.challenge : model;
      this.session.set("currentChallenge", challenge);
    }
  });

  _exports.default = _default;
});