define("innowaze-ember2/utils/flattenArray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flattenArray;

  function flattenArray(a) {
    if (a === null) {
      return null;
    }

    if (a.length === 0) {
      return [];
    }

    return a.reduce(function (l, r) {
      return l.concat(r);
    }, []);
  }
});