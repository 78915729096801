define("innowaze-ember2/routes/ideas/edit", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/routes/ideas/common"], function (_exports, _setCurrentChallenge, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend(_setCurrentChallenge.default, {
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        idea: this.store.findRecord("idea", params.idea_id, {
          adapterOptions: {
            challenge_id: params.challenge_id
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("notRelatedIdeas", [model.idea.id]);
      controller.set("routeIsReadyForAction", true);
    },
    resetController: function resetController(controller) {
      controller.set("routeIsReadyForAction", false);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo("ideas.show", this.currentModel.challenge.id, this.currentModel.idea.id);
      }
    },
    _success: function _success() {
      this.controller.set("saveDisabled", false);
      this.notifications.success(this.locale.t("ideas.updated_idea"));
      this.set("controller.userHasSaved", true);
      this.transitionTo("ideas.show", this.currentModel.challenge.id, this.currentModel.idea.id);
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.set("controller.userHasSaved", false);
      this.notifications.errorWithList(this.locale.t("modification_error"), errors);
    }
  });

  _exports.default = _default;
});