define("innowaze-ember2/templates/knowledges/knowledges-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cuC4Ldfd",
    "block": "{\"symbols\":[\"knowledge\"],\"statements\":[[7,\"ul\"],[12,\"class\",[28,[\"fat-list no-background small-block-grid-1\\n    \",[27,\"if\",[[23,[\"filtersHidden\"]],\"medium-block-grid-2 large-block-grid-3\",\"medium-block-grid-1 large-block-grid-2\"],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"matchingKnowledges\"]]],null,{\"statements\":[[0,\"    \"],[15,\"knowledges/knowledge-card\",[1]],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[23,[\"isLoadingMore\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"loader\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[27,\"eq\",[[23,[\"matchingKnowledges\",\"length\"]],[23,[\"totalResults\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[1,[27,\"react-when-visible\",null,[[\"onVisible\"],[[27,\"action\",[[22,0,[]],[23,[\"send\"]],\"loadMore\"],null]]]],false],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[10]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/knowledges/knowledges-container.hbs"
    }
  });

  _exports.default = _default;
});