define("innowaze-ember2/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xdqDUqJh",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"head-layout\"],false],[0,\"\\n\"],[4,\"innowaze-application\",null,[[\"currentRouteName\"],[[23,[\"currentRouteName\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"notification-container\",null,[[\"position\"],[\"top-right\"]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"displayHeader\"]]],null,{\"statements\":[[0,\"    \"],[15,\"partials/header\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"id\",\"main\"],[11,\"class\",\"container fr-view\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[1,[21,\"outlet\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"bottom\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"displayHeader\"]]],null,{\"statements\":[[0,\"    \"],[15,\"partials/footer\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/application.hbs"
    }
  });

  _exports.default = _default;
});