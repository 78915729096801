define("innowaze-ember2/routes/users", ["exports", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/mixins/routes/userListFilters"], function (_exports, _selectAutocomplete, _userListFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_selectAutocomplete.default, _userListFilters.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    queryParams: {
      challenge: {
        refreshModel: true
      },
      department: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      // be sure that we have availableChallenges list if we need to filter by challenge on page load
      if (transition.queryParams.challenge) {
        return this.store.findAll("challenge").then(function (challenges) {
          return _this.session.set("availableChallenges", challenges);
        });
      }
    },
    model: function model(params) {
      var _this2 = this;

      var options = {
        order: "created_at",
        paginate: true,
        page: 1
      };
      this.set("preselectedDepartments", []);

      if (params.department) {
        var preselectedDepartments = params.department.split(",").map(Number);
        this.set("preselectedDepartments", preselectedDepartments);
        options.departments = preselectedDepartments;
      }

      this.set("preselectedChallenges", []);

      if (params.challenge) {
        var preselectedChallenges = params.challenge.split(",").map(Number);
        this.set("preselectedChallenges", preselectedChallenges);
        options.challenges = preselectedChallenges;
      }

      return this.store.query("user", options).then(function (result) {
        _this2.set("totalResults", result.get("meta.total_results"));

        return result;
      });
    },
    setupController: function setupController(controller, model) {
      var _this3 = this;

      this._super.apply(this, arguments);

      controller.setProperties({
        preselectedDepartments: this.preselectedDepartments,
        preselectedChallenges: this.preselectedChallenges,
        matchingUsers: model.toArray(),
        totalResults: this.totalResults,
        filtersHidden: this.preselectedChallenges.length === 0 && this.preselectedDepartments.length === 0,
        tagsToDisplay: [],
        departments: [],
        selectedRoles: [],
        availableKnowledges: [],
        departmentsToDisplay: [],
        userDepartmentsToDisplay: [],
        availableDepartments: this.store.findAll("department")
      });

      if (controller.preselectedDepartments) {
        controller.preselectedDepartments.forEach(function (id) {
          _this3.store.findRecord("department", id).then(function (department) {
            controller.departmentsToDisplay.addObject(department);
            controller.departments.addObject(department);
          });
        });
      }

      if (controller.preselectedChallenges.length) {
        controller.set("challenges", this.get("session.availableChallenges").filter(function (challenge) {
          return controller.preselectedChallenges.includes(parseInt(challenge.id));
        }));
      }

      controller.reopen({
        toggleHaschanged: Ember.observer("no_challenge", "never_connected", "anonymized", function () {
          if (this.no_challenge !== null || this.never_connected !== null || this.anonymized !== null) {
            this.send("search");
          }
        })
      });

      if (this.get("session.currentUser.admin")) {
        this.store.adapterFor("email-template").getTemplates().then(function (templates) {
          controller.set("availableEmailTemplates", templates);
        }).catch(function () {});
      }
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        page: 1,
        query: "",
        challenges: [],
        challenge: null,
        departments: [],
        department: null,
        selectedRoles: [],
        preselectedChallenges: [],
        no_challenge: null,
        never_connected: null
      });
      controller.matchingUsers.setEach("selected", false);
    },
    actions: {
      setDisplayMode: function setDisplayMode(mode) {
        this.controller.set("displayMode", mode);
      },
      delete: function _delete(user) {
        var _this4 = this;

        if (confirm(this.locale.t("users.index.remove_user"))) {
          user.destroyRecord().then(function () {
            _this4.controller.set("matchingUsers", _this4.get("controller.matchingUsers").without(user));
          });
          this.notifications.success("L'utilisateur a été supprimé.");
        }
      },
      toggleAdmin: function toggleAdmin(user) {
        var _this5 = this;

        if (user.id === this.get("session.currentUser.id")) {
          this.notifications.error("Vous ne pouvez pas permuter votre propre statut");
        } else {
          user.toggleAdmin().then(function () {
            user.toggleProperty("admin");

            _this5.notifications.success("Le statut a été modifié");
          }, function () {
            return _this5.notifications.error("Impossible de modifier le statut");
          });
        }
      },
      setTargetKnowledge: function setTargetKnowledge(knowledge) {
        this.set("controller.targetKnowledge", knowledge);
      },
      grantAccessToKnowledge: function grantAccessToKnowledge() {
        var _this6 = this;

        if (!this.get("controller.targetKnowledge")) {
          return;
        }

        this.get("controller.targetKnowledge").grantAccess(this.get("controller.selectedUsers").mapBy("id")).then(function () {
          _this6.notifications.success("Ces utilisateurs ont maintenant acc\xE8s \xE0 ".concat(_this6.get("controller.targetKnowledge.name")));

          _this6.get("controller.selectedUsers").setEach("selected", false);
        });
      },
      revokeAccessToKnowledge: function revokeAccessToKnowledge(user) {
        var _this7 = this;

        if (!this.get("controller.targetKnowledge")) {
          return;
        }

        this.get("controller.targetKnowledge").revokeAccess(this.get("controller.selectedUsers").mapBy("id")).then(function () {
          _this7.notifications.success("Ces utilisateurs n'ont plus acc\xE8s \xE0 ".concat(_this7.get("controller.targetKnowledge.name")));

          _this7.get("controller.selectedUsers").setEach("selected", false);
        });
      },
      addToEntity: function addToEntity() {
        var _this8 = this;

        if (!this.get("controller.targetDepartment")) {
          return;
        }

        this.get("controller.targetDepartment").addUser(this.get("controller.selectedUsers").mapBy("id")).then(function () {
          return _this8.notifications.success("Ces utilisateurs ont maintenant acc\xE8s \xE0 ".concat(_this8.get("controller.targetDepartment.name")));
        });
      },
      removeFromEntity: function removeFromEntity() {
        var _this9 = this;

        if (!this.get("controller.targetDepartment")) {
          return;
        }

        this.get("controller.targetDepartment").removeUser(this.get("controller.selectedUsers").mapBy("id")).then(function () {
          return _this9.notifications.success("Ces utilisateurs n'ont plus acc\xE8s \xE0 ".concat(_this9.get("controller.targetDepartment.name")));
        });
      },
      setTargetDepartment: function setTargetDepartment(department) {
        this.controller.set("targetDepartment", department);
      },
      setKnowledgerRole: function setKnowledgerRole() {
        var _this10 = this;

        if (this.get("controller.actionInProgress")) {
          return false;
        }

        this.set("controller.actionInProgress", true);
        var waitingPromises = this.get("controller.selectedUsers").map(function (user) {
          return user.setKnowledgerRole();
        });
        Promise.all(waitingPromises).then(function () {
          _this10.set("controller.actionInProgress", false);

          _this10.get("controller.matchingUsers").setEach("selected", false);

          _this10.notifications.success(_this10.locale.t("users.index.set_knowledger_message"));
        });
      },
      removeKnowledgerRole: function removeKnowledgerRole() {
        var _this11 = this;

        if (this.get("controller.actionInProgress")) {
          return false;
        }

        this.set("controller.actionInProgress", true);
        var waitingPromises = this.get("controller.selectedUsers").map(function (user) {
          return user.removeKnowledgerRole();
        });
        Promise.all(waitingPromises).then(function () {
          _this11.set("controller.actionInProgress", false);

          _this11.get("controller.matchingUsers").setEach("selected", false);

          _this11.notifications.success(_this11.locale.t("users.index.remove_knowledger_message"));
        });
      },
      setDepartmentScoutRole: function setDepartmentScoutRole() {
        var _this12 = this;

        if (this.get("controller.actionInProgress")) {
          return false;
        }

        this.set("controller.actionInProgress", true);
        var waitingPromises = this.get("controller.selectedUsers").map(function (user) {
          return user.setDepartmentScoutRole();
        });
        Promise.all(waitingPromises).then(function () {
          _this12.set("controller.actionInProgress", false);

          _this12.get("controller.matchingUsers").setEach("selected", false);

          _this12.notifications.success(_this12.locale.t("users.index.set_scout_message"));
        });
      },
      removeDepartmentScoutRole: function removeDepartmentScoutRole() {
        var _this13 = this;

        if (this.get("controller.actionInProgress")) {
          return false;
        }

        this.set("controller.actionInProgress", true);
        var waitingPromises = this.get("controller.selectedUsers").map(function (user) {
          return user.removeDepartmentScoutRole();
        });
        Promise.all(waitingPromises).then(function () {
          _this13.set("controller.actionInProgress", false);

          _this13.get("controller.matchingUsers").setEach("selected", false);

          _this13.notifications.success(_this13.locale.t("users.index.remove_scout_message"));
        });
      },
      deleteSelectedUsers: function deleteSelectedUsers() {
        var _this14 = this;

        if (!confirm(["destroy_alert1", "destroy_alert2", "destroy_alert3"].map(function (key) {
          return _this14.locale.t("users.index.".concat(key));
        }).join("\n"))) {
          return;
        }

        var selectedUserIds = this.get("controller.selectedUserIds");
        this.store.adapterFor("user").batchDelete(selectedUserIds).then(function () {
          // Filter out stale records
          var controller = _this14.controller;
          controller.set("matchingUsers", controller.matchingUsers.filter(function (user) {
            return !selectedUserIds.includes(user.get("id"));
          }));

          _this14.notifications.success(_this14.locale.t("users.index.remove_users_message"));
        });
      },
      sendEmail: function sendEmail(title, value) {
        var _this15 = this;

        var options = this._prepareOptions();

        this.store.adapterFor("user").sendEmail(null, title, value, null, null, options).then(function () {
          return _this15.notifications.success(_this15.locale.t("challenges.emailings.sent_message"));
        });
      }
    },
    _loadData: function _loadData() {
      var _this16 = this;

      var options = this._prepareOptions();

      return this.store.query("user", options).then(function (result) {
        _this16.set("controller.totalResults", result.get("meta.total_results"));

        _this16.get("controller.matchingUsers").pushObjects(result.toArray());

        _this16.controller.set("isLoadingMore", false);
      });
    }
  });

  _exports.default = _default;
});