define("innowaze-ember2/controllers/ideas/clustering", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["similarIdeas"],
    locale: Ember.inject.service(),
    chartWidth: Ember.computed(function () {
      return Math.min(window.innerWidth - 30, 1250);
    }),
    chartHeight: 600,
    colorVars: Ember.computed("model.tools", function () {
      return this.get("model.tools").filterBy("active", true).concat([Ember.Object.create({
        title: this.locale.t("ideas.types"),
        name: "typology",
        scale: 1
      }), Ember.Object.create({
        title: this.locale.t("ideas.state"),
        name: "state_name",
        scale: 2
      }), Ember.Object.create({
        title: this.locale.t("ideas.domains"),
        name: "first_domain",
        scale: 2
      })]);
    }),
    sizeVars: Ember.computed("model.tools", function () {
      return this.get("model.tools").filterBy("active", true);
    }),
    query: undefined,
    tags: [],
    creators: [],
    states: [],
    domain: undefined,
    typology: undefined
  });

  _exports.default = _default;
});