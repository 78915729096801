define("innowaze-ember2/controllers/universes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedUniverses: Ember.computed("model.[]", function () {
      return this.model.sortBy("position");
    })
  });

  _exports.default = _default;
});