define("innowaze-ember2/templates/concepts/modules/-slogan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kCwyEeye",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"concept\",\"slogan\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-module-title\"],[9],[0,\"\\n    \"],[1,[23,[\"module\",\"title\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-module main-info\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"idea-description\"],[9],[0,\"\\n      \"],[1,[23,[\"model\",\"concept\",\"slogan\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/concepts/modules/-slogan.hbs"
    }
  });

  _exports.default = _default;
});