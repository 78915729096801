define("innowaze-ember2/templates/components/related-knowledge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fRr0g3qB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"data-dropdown\",[28,[\"kdrop-\",[27,\"unbound\",[[23,[\"knowledge\",\"_source\",\"id\"]]],null]]]],[11,\"data-options\",\"align:left;is_hover:true;\"],[9],[0,\"\\n  \"],[1,[21,\"grade\"],true],[0,\" \"],[1,[23,[\"knowledge\",\"_source\",\"name\"]],false],[0,\" \\n  \"],[7,\"div\"],[11,\"class\",\"fa fa-plus\"],[3,\"action\",[[22,0,[]],\"addKnowledge\",[23,[\"knowledge\"]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"data-dropdown-content\",\"\"],[12,\"id\",[28,[\"kdrop-\",[27,\"unbound\",[[23,[\"knowledge\",\"_source\",\"id\"]]],null]]]],[11,\"class\",\"f-dropdown content large\"],[9],[0,\"\\n  \"],[1,[23,[\"knowledge\",\"_source\",\"abstract\"]],true],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/related-knowledge.hbs"
    }
  });

  _exports.default = _default;
});