define("innowaze-ember2/routes/challenges/edit-gaming", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/saveChallenge", "innowaze-ember2/mixins/routes/selectAutocomplete"], function (_exports, _cleanCurrentModel, _setCurrentChallenge, _saveChallenge, _selectAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setCurrentChallenge.default, _cleanCurrentModel.default, _saveChallenge.default, _selectAutocomplete.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("challenge", params.challenge_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        availableDepartments: []
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        this.saveCurrentChallenge().then(function () {
          return _this._success();
        }, function (errors) {
          return _this._error(errors);
        });
      },
      next: function next() {
        var _this2 = this;

        this.saveForNextStep(3).then(function () {
          return _this2._stepSuccess();
        }, function (errors) {
          return _this2._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      setLikerDepartments: function setLikerDepartments(departments) {
        this.currentModel.set("liker_departments", departments);
      },
      resetIdeasVotes: function resetIdeasVotes() {
        var _this3 = this;

        if (confirm(this.locale.t("challenges.gaming.confirm_deleting"))) {
          this.currentModel.resetIdeasVotes().then(function () {
            return _this3.notifications.success("Votes remis à zéro");
          }, function () {
            return _this3.notifications.error("Impossible de remettre les votes à zéro");
          });
        }
      },
      resetConceptsVotes: function resetConceptsVotes() {
        var _this4 = this;

        if (confirm(this.locale.t("challenges.gaming.confirm_deleting"))) {
          this.currentModel.resetConceptsVotes().then(function () {
            return _this4.notifications.success("Votes remis à zéro");
          }, function () {
            return _this4.notifications.error("Impossible de remettre les votes à zéro");
          });
        }
      }
    },
    _stepSuccess: function _stepSuccess() {
      this._success();

      this.transitionTo("challenges.edit_users_format", this.currentModel.id);
    }
  });

  _exports.default = _default;
});