define("innowaze-ember2/routes/challenges/new", ["exports", "innowaze-ember2/mixins/routes/cleanCurrentModel"], function (_exports, _cleanCurrentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cleanCurrentModel.default, {
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    templateName: "challenges/edit-main",
    model: function model(params) {
      return this.store.createRecord("challenge", {
        private: true,
        blind: false,
        blind_concept: false,
        requires_validation: false,
        no_timeline: false,
        no_countdown: false,
        no_homepage: false,
        has_showcase: true,
        allow_nicknames: false,
        banner_height: 100,
        display_likers: false,
        likers_site_percentage: false,
        display_remaining_points: false,
        display_user_score: false,
        voting_focus: false,
        viral_invitation: false,
        idea_author_can_evaluate: false,
        display_idea_evaluations: false,
        display_idea_evaluations_boxplot: false,
        display_radar_chart: false,
        concept_author_can_evaluate: false,
        display_concept_evaluations: false,
        display_concept_evaluations_boxplot: false,
        display_default_list: false,
        display_bubble: false,
        display_clustering: false,
        display_domain_filter: false,
        display_state_filter: false,
        display_published_at_filter: false,
        display_user_filters: false,
        display_tags_cloud: false,
        challenges_synchronization: false,
        use_concept_vote: false,
        wants_exploration: false,
        wants_ideation: false,
        wants_concept: false,
        wants_pathway: false,
        milestones: [],
        block_votes_ideas: false,
        block_votes_concepts: false,
        hide_votes_ideas: false,
        deepl_translation: false,
        translation_coupling: false,
        active_user_modules: [],
        evaluation_min: 0,
        evaluation_max: 100,
        evaluation_step: 1,
        // idea workflow
        moderate_ideas: false,
        priority_ideas: false,
        expert_validation: false,
        management_committee_validation: false,
        implementation_followup: false,
        // concept workflow
        priority_concepts: false,
        concept_expert_validation: false,
        concept_management_committee_validation: false,
        dict: {
          idea: "",
          ideas: "",
          the_idea: "",
          an_idea: "",
          draft_idea: "",
          formulated_idea: "",
          published_idea: "",
          documented_idea: "",
          evaluated_idea: "",
          completed_idea: "",
          selected_idea: "",
          rejected_idea: "",
          to_be_documented_idea: "",
          to_be_evaluated_idea: "",
          to_be_pitched_idea: "",
          formulate_idea: "",
          publish_idea: "",
          to_document_idea: "",
          document_idea: "",
          to_evaluate_idea: "",
          evaluate_idea: "",
          to_pitch_idea: "",
          complete_idea: "",
          select_idea: "",
          reject_idea: "",
          implemented_idea: "",
          concept: "",
          concepts: "",
          the_concept: "",
          a_concept: "",
          draft_concept: "",
          formulated_concept: "",
          published_concept: "",
          documented_concept: "",
          evaluated_concept: "",
          completed_concept: "",
          selected_concept: "",
          rejected_concept: "",
          to_be_documented_concept: "",
          to_be_evaluated_concept: "",
          to_be_pitched_concept: "",
          formulate_concept: "",
          publish_concept: "",
          to_document_concept: "",
          document_concept: "",
          to_evaluate_concept: "",
          evaluate_concept: "",
          to_pitch_concept: "",
          complete_concept: "",
          select_concept: "",
          reject_concept: ""
        },
        step: 0
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.setProperties({
        availableKnowledges: this.store.findAll("knowledge")
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        this.controller.set("saveDisabled", true);
        this.currentModel.set("step", 1);
        this.currentModel.save().then(function (challenge) {
          return _this._success(challenge);
        }, function (errors) {
          return _this._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("challenges");
      },
      filesUploaded: function filesUploaded(files) {
        this.currentModel.setProperties({
          logo_id: files[0].id,
          logo_url: files[0].file_url
        });
      },

      /**
       *
       * @param {*} field
       * @param {*} newContent
       */
      updateTextareaField: function updateTextareaField(field, newContent) {
        this.controller.set(field, newContent);
      },
      setKnowledges: function setKnowledges(knowledges) {
        this.currentModel.set("knowledges", knowledges);
      },
      setDepartment: function setDepartment(department) {
        this.currentModel.set("department", department);
      },
      setGroup: function setGroup(group) {
        this.currentModel.set("group", group);
      }
    },
    _success: function _success(challenge) {
      this.controller.set("saveDisabled", false);
      this.notifications.success("Le challenge a été créé.");
      this.transitionTo("challenges.edit_home", challenge.id);
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.controller.set("saveDisabled", false);
      this.notifications.errorWithList(this.locale.t("creation_error"), errors);
    }
  });

  _exports.default = _default;
});