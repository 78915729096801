define("innowaze-ember2/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    keyForRelationship: function keyForRelationship(key, typeClass, method) {
      // Add _id suffix for non-embedded relationships
      return this._super.apply(this, arguments) + (key in this.attrs ? "" : "_id");
    }
  });

  _exports.default = _default;
});