define("innowaze-ember2/components/modules/checkboxes-module", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/components/modules/module"], function (_exports, _objectSpread2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set("checkboxes-inline", this.get("module.params.inline"));
    },
    available_checkbox_types: Ember.computed("model.checkbox_type.[]", function () {
      var checkboxTypes = this.get("model.checkbox_type");
      return this.get("module.params.checkbox_type").map(function (type) {
        return (0, _objectSpread2.default)({}, type, {
          selected: checkboxTypes.includes(type.id)
        });
      });
    }),
    actions: {
      toggle: function toggle(type) {
        var model = this.model;
        var checkboxType = model.get("checkbox_type");
        checkboxType = checkboxType.includes(type.id) ? checkboxType.filter(function (id) {
          return id !== type.id;
        }) : checkboxType.concat(type.id);
        model.set("checkbox_type", checkboxType);
      }
    }
  });

  _exports.default = _default;
});