define("innowaze-ember2/templates/components/highlight-verbatim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kwhQEWp1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"translatedComments\"]],[23,[\"comment\",\"translatedContent\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,[\"comment\",\"origin\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"content-with-tags\",null,[[\"text\",\"flag\",\"origin\",\"tagClicked\",\"toggleOrigin\"],[[23,[\"comment\",\"content\"]],[23,[\"comment\",\"language\"]],[23,[\"comment\",\"origin\"]],\"tagClicked\",\"toggleOrigin\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"content-with-tags\",null,[[\"text\",\"flag\",\"origin\",\"tagClicked\",\"toggleOrigin\"],[[23,[\"comment\",\"translatedContent\"]],[23,[\"comment\",\"language\"]],[23,[\"comment\",\"origin\"]],\"tagClicked\",\"toggleOrigin\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"content-with-tags\",null,[[\"text\",\"tagClicked\"],[[23,[\"comment\",\"content\"]],\"tagClicked\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "innowaze-ember2/templates/components/highlight-verbatim.hbs"
    }
  });

  _exports.default = _default;
});