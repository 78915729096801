define("innowaze-ember2/controllers/challenge-juries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    allEvaluators: Ember.computed("model.evaluators.[]", "model.juries.@each.usersIds", function () {
      var _this = this;

      var evaluators = this.model.evaluators.map(function (evaluator) {
        evaluator.set("juryCount", _this.model.juries.reduce(function (acc, j) {
          return acc + (j.usersIds.includes(evaluator.id) ? 1 : 0);
        }, 0));
        return evaluator;
      });
      return evaluators.filter(function (e) {
        return e.juryCount === 0;
      }).concat(evaluators.filter(function (e) {
        return e.juryCount > 0;
      }).sortBy("fields.last_name"));
    }),
    juriesByStates: Ember.computed("model.juries.[]", "session.currentChallenge.availableEvaluationStates", function () {
      var _this2 = this;

      return this.session.currentChallenge.evaluation_states.reduce(function (acc, state) {
        var stateName = _this2.session.currentChallenge.state_machine_rules.find(function (rule) {
          return rule.to === state;
        }).to_name;

        acc[stateName] = _this2.model.juries.filterBy("state", state);
        return acc;
      }, {});
    })
  });

  _exports.default = _default;
});