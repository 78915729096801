define("innowaze-ember2/routes/personas/edit", ["exports", "innowaze-ember2/routes/personas/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("persona", params.persona_id);
    },
    errorMessage: Ember.computed(function () {
      return this.locale.t("modification_error");
    }),
    successMessage: "Le persona a été mis à jour."
  });

  _exports.default = _default;
});